import React, {useState, useEffect, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Drawer} from '@partssourceinc/react-ui-core';
import {useSelector} from 'react-redux';
import {logEvent} from 'utility';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {faPhoneAlt, faUserAlt} from '@fortawesome/free-solid-svg-icons';
import NavBar from 'components/NavBar';
import {responsive} from 'utils/styles';
import {useLocation} from 'react-router';
import FlyOutSubMenu from './FlyOutSubMenu';
import CmsFlyOutSubMenu from './CmsFlyOutSubMenu';
import _ from 'lodash';
import * as decodeJwt from 'jwt-decode';
import {BrComponent, BrPageContext} from '@bloomreach/react-sdk';
import useMenu from 'hooks/useMenu';
import * as PropTypes from 'prop-types';

const StyledHeader = styled.header`
    color: black;
    padding-top: 0;
    border-bottom: 2px solid #eeeeee;
`;

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    align-items: center;
    padding-left: 50px;

    @media (max-width: 1149px) {
        padding-left: 25px;
        padding-right: 25px;
    }
`;

const Left = styled.div`
    height: 55px;
    display: flex;
    align-items: center;
    margin-top: 2px;

    img {
        height: 15px;
    }

    @media (max-width: 819px) {
        width: 54vw;
    }
`;

const HamburgerIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const MobileMenu = styled.div`
    ${responsive({max: 'md'})};
    margin-right: 25px;

    @media (max-width: 1149px) {
        font-size: 10px;
    }
`;

const MobileMenuContainer = styled.div`
    padding-left:30px;
    padding-right: 30px;
    a {
        display: block;
        font-size: 16px;
        color: black;
        font-weight:600;
        text-decoration: none;
        margin-top:20px;
        &:hover {
            text-decoration: none;
        }
    }
`;

const PhoneLink = styled.a`
    display: initial !important;
    margin-top: 0;
`;

const MobileMenuTop = styled.div`
    top: 0;
    padding-top: 21px;
    padding-bottom: 12px;
    font-family: Source Sans Pro,sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: black;
    display: flex;
    align-items: center;
    a {
        margin: 0px !important;
        font-weight: 700;
    }
`;

const Right = styled.div`
    line-height: 48px;
    width: 300px;

    @media (max-width: 819px) {
        display: none;
    }
`;

const MenuWrapper = styled.span`
    cursor: default;
    float: right;
    padding: 7px 15px 0 15px;
    height: 51px;
    border: 1px solid #fff;
    border-bottom-color: #eee;
    width: 170px;
    position: relative;

    &:hover {
        border-color: #005DA6;
        transition-delay: .19s;
        transition: border-color .01s;
    }

    &:not(:hover) {
        border-color: transparent;
        transition-delay: .19s;
        transition: border-color .01s;
    }
`;

const Initials = styled.span`
    display: block;
    background-color: #005da6;
    width: 23px;
    height: 23px;
    color: white;
    font-weight: 600;
    line-height: 23px;
    border-radius: 50%;
    font-size: 9.75px;
    text-align: center;
    margin-top: 6px;
    position: absolute;
    margin-right:5px;
`;

const AccountLabel = styled.span`
    margin-left: 28px;
    font-weight: 700;
    color: black;
    font-size: 16px;
    font-family: Source Sans Pro;

    a {
        color: black;
        text-decoration: none;
    }
`;

const UserName = styled.span`
    position: absolute;
    display: block;
    top: 0;
    margin-left: 30px;
    margin-top: -8px;
    font-weight: 700;
    font-family: Source Sans Pro;
    font-size: 12px;
    color: black;
`;

const DownArrow = styled(FontAwesomeIcon)`
    width: 16px;
    height: 16px;
`;

const Icon = styled.span`
    position: absolute;
    right: 15px;
`;

const LogOutIcon = styled(FontAwesomeIcon)`
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: black;
`;

const Dropdown = styled.div`
    position: absolute;
    background-color: white;
    margin-top: 3px;
    z-index: 1000;
    width: 170px;
    top: 48px;
    right: 0px;
    box-shadow: 0px 6px 10px grey;
    padding: 5px 10px 15px 20px;
    display: block;

    ul {
        margin-bottom: 0;
        padding: 5px 15px;
        min-width: 130px;
    }

    ul li {
        line-height: 34px;
        list-style: none;

        :hover {
            cursor: pointer;
        }
    }

    @media (max-width: 819px) {
        right: 20px;
    }
`;

const MenuItem = styled(NavLink)`
    div {
        color: black;
        line-height: 35px;
        margin-left: 0;
        cursor: pointer;
        font-family: Source Sans Pro;
        font-weight: 400;
        font-size: 16px;
    }

    text-decoration: none !important;
    &:hover {
        text-decoration: none !important;
    }
`;

const MobileMenuItem = styled.div`
        color: black;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        padding-top: 20px;
`;

const MobileMenuLink = styled(NavLink)`
    div{
        color: black;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600 !important;
    }
    a{
        padding-top:25px;
    }
`;

const MobileMenuLogOut = styled.a`
    div{
        color: black;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600 !important;
    }
    a{
        padding-top:25px;
    }
`;
const Divider = styled.div`
    border: 1px solid #777777;
    height: 2px;
    width: 100%;
`;

const ImpersonationBar = styled.div`
    padding: 5px 0;
    border-bottom: 2px solid #eeeeee;
`;

const ImpersonationLeft = styled.span`
    margin-left: 60px;
`;

const RedLink = styled(NavLink)`
    color: red;
`;

const TitleLink = styled(NavLink)`
    ${responsive({min: 'lg'})};
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
`;

const RightArrowIcon = styled(FontAwesomeIcon)`
font-weight: 900;
font-style: normal;
margin: 0 0 0 5px;
font-size: 12px;
`;

const PhoneIcon = styled(FontAwesomeIcon)`
font-weight: 700;
text-decoration: none;
font-size: 12px;
margin-right:5px;
`;

const SignInIcon = styled(FontAwesomeIcon)`
font-weight: 700;
text-decoration: none;
font-size: 16px;
margin-right:5px;
`;

const LogOutSection = styled.div`
    background-color: #f3f3f3;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    padding-top: 10px;
    padding-bottom: 14px;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    margin-top:45px;
    a {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: black;
        font-weight:600;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
`;

const CustomerHeader = ({updateTriggerLogout}) => {
    const page = useContext(BrPageContext);
    const userLoggedIn = useSelector(state => state.user.tokenInfo);
    const newPolicyAcceptanceRequired = useSelector(state => state.user.policies?.newPolicyAcceptanceRequired || false);
    const beingImpersonated = useSelector(state => state.user.beingImpersonated);
    const initials = useSelector(state => state.user.initials);
    const firstName = useSelector(state => state.user.firstName);
    const impersonator = useSelector(state => state.user.impersonator);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showAccountMenu, setShowAccountMenu] = useState(false);
    const [showSubMenu, setShowSubMenu] = useState('');
    const {ordersMenu, performanceMenu, brandedEcommerceMenu, marketingInsightsMenu, resourcesMenu, myAccountMenu} = useMenu();

    const SubMenu = {
        None: '',
        MyAccount: 'My Account',
        Orders: 'Orders',
        Resources: 'Resources',
    };

    const location = useLocation();
    useEffect(() => {
        setShowMobileMenu(false);
        setShowSubMenu(false);
        setShowAccountMenu(false);
    }, [location])

    const getSubMenu = () => {
        switch (showSubMenu) {
            case SubMenu.MyAccount:
                return myAccountMenu.menu;
            case SubMenu.Orders:
                return ordersMenu.menu;
            case SubMenu.Resources:
                return null;
            default:
                return null;
        }
    }

    const hideMobileMenu = () => setShowMobileMenu(false);
    const toggleShowMobileMenu = () => setShowMobileMenu(!showMobileMenu);

    function logOut() { 
        if (updateTriggerLogout)
            updateTriggerLogout(true);

    }
    const sendLog = (event) => {
        logEvent('MY ACCOUNT', {
            'menu item': event.target.innerText,
        });
    }

    const sendPageLog = (page, tab) => {        
        if (tab) {
            logEvent('TAB ACCESS', {
                'Page': page === 'exchanges & returns' ? 'exchanges-returns' : page,
                'Tab': tab,
            })
        } else {
            logEvent('TAB ACCESS', {
                'Page': page === 'exchanges & returns' ? 'exchanges-returns' : page,
            })
        }
    }

    const clickPSLogo = (userLoggedIn) => {
        if (userLoggedIn) {
            logEvent('TAB ACCESS', {
                'Page': 'Home',
            })
        }
    }

    const clickHandler = (e) => {
        if (e.target.href && e.target.href.indexOf('vendor.partsfinder') > -1) {
            const decodedJwt = decodeJwt(userLoggedIn);
            const ak1 = decodedJwt.authenticationKey;
            const ak2 = decodedJwt.authorizationKey;

            const encodedAk1 = ak1.replace(/[!*'();:@&+$?%#]/g, function (c) {
                return '%' + c.charCodeAt(0).toString(16);
            })
            const encodedAk2 = ak2.replace(/[!*'();:@&+$?%#]/g, function (c) {
                return '%' + c.charCodeAt(0).toString(16);
            })

            if (e.target.href.indexOf('ak1') === -1 && ak1 && ak2)
                e.target.href = `${e.target.href.slice(0, -1)}?ak1=${encodedAk1}&ak2=${encodedAk2}`
        }
    }

    return (
        <StyledHeader style={{marginLeft: '0px', marginRight: '0px'}}>
            {impersonator && <ImpersonationBar className="col-12">
                <ImpersonationLeft>
                    Logged in as {_.capitalize(impersonator.firstName)} {_.capitalize(impersonator.lastName)}
                    &nbsp;-&nbsp;
                    <RedLink to="/sso/sisense/logout">Stop Impersonating</RedLink>
                </ImpersonationLeft>
            </ImpersonationBar>}
            <Content className="col-md-12">
                <Left>
                    <MobileMenu>
                        <HamburgerIcon icon="bars" size="2x" onClick={toggleShowMobileMenu} />
                        <Drawer show={showMobileMenu} onClose={hideMobileMenu} style={{paddingLeft: '0px', paddingRight: '0px'}}>
                            <MobileMenuContainer onClick={clickHandler}>
                                {!userLoggedIn &&
                                    <MobileMenuTop>
                                        <SignInIcon icon={faUserAlt} /> <MenuItem to="/login" onClick={() => sendPageLog('Sign In')}>
                                            Sign In
                                        </MenuItem>
                                        <span style={{marginLeft: 10, marginRight: 10}}>|</span>
                                        <MenuItem to="/registration" onClick={() => sendPageLog('Registration')}>Register</MenuItem>
                                    </MobileMenuTop>}
                                {userLoggedIn && <React.Fragment>
                                    <MobileMenuTop>
                                        <Initials style={{position: 'unset', marginTop: '0px'}}>{initials}</Initials>
                                        Hello {_.capitalize(firstName)}
                                    </MobileMenuTop>
                                    <Divider />
                                    <MobileMenuItem onClick={() => setShowSubMenu(SubMenu.MyAccount)}>
                                        {myAccountMenu?.title} <RightArrowIcon icon={faChevronRight} />
                                    </MobileMenuItem>
                                    {performanceMenu && <MobileMenuLink to={performanceMenu?.url} onClick={() => sendPageLog(performanceMenu?.title)}>
                                        {performanceMenu?.title}
                                    </MobileMenuLink>}
                                    {ordersMenu && <MobileMenuItem onClick={() => setShowSubMenu(SubMenu.Orders)}>
                                        {ordersMenu?.title} <RightArrowIcon icon={faChevronRight} />
                                    </MobileMenuItem>}
                                    {marketingInsightsMenu &&
                                        <MobileMenuLink to={marketingInsightsMenu?.url} onClick={() => sendPageLog(marketingInsightsMenu?.title)}>
                                            {marketingInsightsMenu?.title}
                                        </MobileMenuLink>}
                                    {brandedEcommerceMenu &&
                                        <MobileMenuLink to={brandedEcommerceMenu?.url} onClick={() => sendPageLog(brandedEcommerceMenu?.title)}>
                                            {brandedEcommerceMenu?.title}
                                        </MobileMenuLink>}
                                    <MobileMenuItem onClick={() => setShowSubMenu(SubMenu.Resources)}>
                                        {resourcesMenu?.title} <RightArrowIcon icon={faChevronRight} />
                                    </MobileMenuItem>
                                </React.Fragment>}
                                <Divider style={{marginTop: userLoggedIn && '25px'}} />

                                <MobileMenuItem>
                                    <PhoneIcon icon={faPhoneAlt} /> 
                                    <PhoneLink style={{color: '#007bff'}} href={'tel:+1 877-497-6412'}>
                                        877-497-6412
                                    </PhoneLink>
                                </MobileMenuItem>

                                <FlyOutSubMenu
                                    show={showSubMenu}
                                    subMenuClick={(res) => sendPageLog(res.toLowerCase())}
                                    goBack={() => setShowSubMenu('')}
                                    content={{
                                        title: showSubMenu,
                                        items: getSubMenu(),
                                    }}
                                />

                                <BrComponent path={'header/resources-menu'}>
                                    <CmsFlyOutSubMenu
                                        show={showSubMenu === SubMenu.Resources}
                                        subMenuClick={(res) => sendPageLog(resourcesMenu?.title.toLowerCase(), res)}
                                        goBack={() => setShowSubMenu('')}
                                        title={resourcesMenu?.title}
                                    />
                                </BrComponent>
                            </MobileMenuContainer>

                            {userLoggedIn && <LogOutSection>
                                <MobileMenuLogOut onClick={logOut}>
                                    <LogOutIcon icon="sign-out-alt" />
                                    Logout
                                </MobileMenuLogOut>
                            </LogOutSection>}
                        </Drawer>
                    </MobileMenu>
                    <TitleLink to={userLoggedIn ? '/overview' : '/login'}>
                        <img src="/logo300.png" alt="PartsSource Logo" onClick={() => clickPSLogo(userLoggedIn)} />
                    </TitleLink>
                    {(userLoggedIn && (beingImpersonated || !newPolicyAcceptanceRequired)) || (page?.isPreview() ?? false) ?
                        <NavBar onClick={clickHandler} /> : null}
                </Left>
                {userLoggedIn &&
                    <Right>
                        <MenuWrapper
                            id="lnkYourAccount"
                            onMouseEnter={() => setShowAccountMenu(true)}
                            onMouseLeave={() => setShowAccountMenu(false)}>
                            <span>
                                <Initials>{initials}</Initials>
                                <AccountLabel>
                                    My Account
                                    {(!newPolicyAcceptanceRequired || beingImpersonated) && <Icon>
                                        <DownArrow icon="angle-down" />
                                    </Icon>}
                                </AccountLabel>
                                <UserName>Hello, {_.capitalize(firstName)}</UserName>
                            </span>

                            {(!newPolicyAcceptanceRequired || beingImpersonated) && <Dropdown style={{display: showAccountMenu ? 'block' : 'none'}}>
                                {myAccountMenu.menu.filter(m => m.visible).map((i, index) =>
                                    <MenuItem key={`mi_${index}`} to={i.url} onClick={sendLog}><div>{i.title}</div></MenuItem>
                                )}
                                <Divider />
                                <MenuItem to="/sso/sisense/logout">
                                    <div>
                                        <LogOutIcon icon="sign-out-alt" />
                                        Logout
                                    </div>
                                </MenuItem>
                            </Dropdown>}
                        </MenuWrapper>

                    </Right>}
            </Content>
        </StyledHeader>
    );
};
CustomerHeader.propTypes = {
    updateTriggerLogout: PropTypes.func,
};

export default CustomerHeader;
