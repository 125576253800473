import React, {useEffect, useState} from 'react';
import {CustomGrid, Popup} from '@partssourceinc/react-ui-core';
import _ from 'lodash';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router-dom';
import {getPortals, deletePortal} from './../../reducers/user';
import {useDispatch, useSelector} from 'react-redux';
import 'components/css/adminLists.css';

const Icon = styled(FontAwesomeIcon)`
    height: 13px;
    padding-left: 1px;
    padding-right: 1px;
    margin-right: 2px;
`;

export default function RPPortalsManagement() {
    const history = useHistory();
    const {portals, portalsLoading} = useSelector((state) => state.user);
    const [sortColumn, setSortColumn] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedPortal, setSelectedPortal] = useState({});

    const headerColumns = [
        {fieldName: 'nameSort', displayName: 'Portal Name', canSort: true},
        {fieldName: 'description', displayName: 'Description', canSort: true},
        {
            fieldName: 'isDefault',
            displayName: 'Is Default?',
            canSort: true,
        },
        {fieldName: 'widgets', displayName: 'Widgets Enabled', canSort: false},
        {fieldName: 'companies', displayName: 'Assigned Suppliers', canSort: false},
        {fieldName: '', displayName: 'Actions', canSort: false},
    ];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPortals());
    }, []);

    useEffect(() => {
        let list = searchTerm ? portals.filter((x) => x.search.indexOf(searchTerm) > -1) : portals;
        list = _.orderBy(list, sortColumn, sortDirection);
        setFilteredList(list);
    }, [portals, sortColumn, sortDirection, searchTerm]);

    const companyPill = (c) => <div key={c.companyId} className="display-pill">{`${c.companyName} (${c.companyId})`}</div>;
    const widgetPill = (w) => <div key={w.id} className="display-pill">{w.name}</div>;
    const getCompaniesColumn = (gridItem) => {
        let companiesContent = (
            <div className="display-pill">This portal is currently applied to suppliers who have no portal assigned.</div>
        );

        if (gridItem.companies?.length) {
            if (gridItem.companies.length <= 3) {
                companiesContent = gridItem.companies.map(p => companyPill(p));
            } else {
                companiesContent = (
                    <>
                        {gridItem.companies.slice(0, 3).map(p => companyPill(p))}
                        <span style={{fontWeight: '700', fontStyle: 'italic', color: '#949494'}}> + {gridItem.companies.length - 3} more</span>
                    </>
                );
            }
        }

        return (
            <td>
                {companiesContent}
            </td>
        );
    };

    const getWidgetsColumn = (gridItem) => {
        let widgetsContent = null;

        if (gridItem.widgets?.length) {
            if (gridItem.widgets.length <= 3) {
                widgetsContent = gridItem.widgets.map(w => widgetPill(w));
            } else {
                widgetsContent = (
                    <>
                        {gridItem.widgets.slice(0, 3).map(w => widgetPill(w))}
                        <span style={{fontWeight: '700', fontStyle: 'italic', color: '#949494'}}> + {gridItem.widgets.length - 3} more</span>
                    </>
                );
            }
        }

        return <td className="no-wrap">{widgetsContent}</td>;
    };

    const tableRows = filteredList.map(x =>
        (<tr key={`${x.ravenId}-row`}>
            <td className="no-wrap">{x.name}</td>
            <td className="no-wrap">{x.description}</td>
            <td className="no-wrap">{x.isDefault ? 'Yes' : 'No'}</td>
            {getWidgetsColumn(x)}
            {getCompaniesColumn(x)}
            <td style={{display: 'flex'}}>
                <Icon 
                    className="edit" 
                    icon={faPencilAlt} 
                    size="xs" 
                    onClick={() => history.push(`/admin/reporting-portal/portals/${x.ravenId}`)}
                />
                <Icon 
                    className="edit" 
                    icon={faTrashAlt} 
                    size="xs" 
                    onClick={() => onConfirmDelete(x)}
                />
            </td>
        </tr>));

    const onSort = (columnName) => {
        setSortColumn(columnName.sortColumn);
        setSortDirection(columnName.sortDirection);
    };
    
    const onConfirmDelete = (portal) => {
        setSelectedPortal(portal);
        setShowDeleteConfirmation(true);
    };
    
    const onDeletePortal = () => {
        dispatch(deletePortal(selectedPortal.ravenId)).then(() => {
            dispatch(getPortals());
            setShowDeleteConfirmation(false);
        });
    };

    return (
        <div className="adv-admin-lists">
            <h1>SPro Portal Management</h1>
            <div className="add-item" onClick={() => history.push(`/admin/reporting-portal/portals/add`)}>+ Add Portal</div>
            <CustomGrid
                headerColumns={headerColumns}
                totalRecords={tableRows.length}
                showSearch={true}
                showPagination={true}
                defaultSortColumn={sortColumn}
                defaultSortDirection={sortDirection}
                onSort={onSort}
                onSearch={(searchTerm) => setSearchTerm(searchTerm.value.toLowerCase())}
                defaultPageSize={50}
                gridClassName="custom-grid"
                displayPages={10}>
                {tableRows}
            </CustomGrid>
            {showDeleteConfirmation ? <Popup confirmText="Yes"
                cancelText="No"
                show={true}
                hideButtons={false}
                onCancel={() => setShowDeleteConfirmation(false)}
                onConfirm={onDeletePortal}
                loading={portalsLoading}
                className="confirm-item-delete-dialog"
            >
                <div style={{fontSize: '16px'}}>
                    Are you sure you want to delete this portal?
                </div>
            </Popup> : null}
        </div>
    )
}
