import axios from 'axios';
import _ from 'lodash';

const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
const ERROR = 'ERROR';

export const defaultState = {
    company: null,
};

const reducer = (state = _.cloneDeep(defaultState), action) => {
    switch (action.type) {
        case GET_COMPANY_SUCCESS:
            return {...state, company: action.company};
        case ERROR:
        default:
            return state;
    }
}

export function getCompany(companyId) {
    return async (dispatch, getState) => {
        try {
            const response = await axios.get(`/company/${companyId}`);
            dispatch({type: GET_COMPANY_SUCCESS, company: response.data});
            return response.data;
        } catch (e) {
            console.error(e);
        }
    };
}

export default reducer;
