export const GridKeys = [
    {
        cardAction: 'Ordered',
        fieldName: 'loanerStatus',
        id: 'Ordered',
        tabDisplay: 'Ordered',
        value: 'ORDERED',
    }, {
        cardAction: 'ShippedOutbound',
        fieldName: 'loanerStatus',
        id: 'ShippedOutbound',
        tabDisplay: 'Shipped Outbound',
        value: 'SHIPPEDOUTBOUND',
    }, {
        cardAction: 'ReceivedInUse',
        fieldName: 'loanerStatus',
        id: 'ReceivedInUse',
        tabDisplay: 'Received & In Use',
        value: 'RECEIVEDINUSE',
    }, {
        cardAction: 'ShippedInbound',
        fieldName: 'loanerStatus',
        id: 'ShippedInbound',
        tabDisplay: 'Shipped Inbound',
        value: 'SHIPPEDINBOUND',
    }, {
        cardAction: 'Returned',
        fieldName: 'loanerStatus',
        id: 'Returned',
        tabDisplay: 'Returned',
        value: 'RETURNED',
    }, {
        cardAction: 'Inspected',
        fieldName: 'loanerStatus',
        id: 'Inspected',
        tabDisplay: 'Inspected',
        value: 'INSPECTED',
    },
];
