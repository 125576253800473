import React, {useState} from 'react';
import {TextField, Button, brandFont} from '@partssourceinc/react-ui-core';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {GenericAccountAccessMessage} from 'utils/enums';

const Container = styled.div`
    max-width: 300px;
    margin: 0 auto;
`;

const PasswordEye = styled(FontAwesomeIcon)`
    width: 19px;
    float: right;
    opacity: 0.5;
    margin-top: -30px;
    margin-right: 5px;
    cursor: pointer;
`;

const PasswordFieldWrapper = styled.div`
    width: 280px;
    margin-top: 20px;
`;

const Check = styled(FontAwesomeIcon)`
    color: green;
    margin-left: 5px;
`;

const PassWarning = styled.div`
    margin-top: 15px;
    max-width: 509px;
    ${brandFont('16px')}
`;

const ApiResult = {
    SUCCESS: 0,
    CONTACT_NOT_FOUND: 1,
    CURRENT_PASSWORD_INCORRECT: 2,
    TECHNICAL_PROBLEM: 3,
};

const ChangePassword = (props) => {
    const user = useSelector(state => state.user);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showRepeatPass, setShowRepeatPass] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [passError, setPassError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [newPass, setNewPass] = useState('');
    const [repeatPass, setRepeatPass] = useState('');
    const [passChangeResult, setPassChangeResult] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const passChange = ({target: {name, value}}) => {
        switch (name) {
            case 'newPass':
                setNewPass(value.trim());
                break;
            case 'repeatPass':
                setRepeatPass(value.trim());
                break;
        }
        setShowSuccessMessage(false);
    }

    const submitPassword = async (e) => {
        e.preventDefault();
        if (localStorage?.length === 0 || localStorage.equery?.length === 0) {
            setShowSuccessMessage(false);
            setPassChangeResult('The link on your email is expired. Please go to login page and click on "Forgot your password?"');            
        } else if (newPass.length < 5 || newPass.length > 17) {
            setPassError(true);
            setPassChangeResult('');
            setErrorMessage('New password must be between 5 and 17 characters long.');
        } else if (newPass !== repeatPass) {
            setPassError(true);
            setPassChangeResult('');
            setErrorMessage('The new password and password confirmation do not match');
        } else if (newPass && newPass !== '') {
            const info = {};
            info.newPassword = newPass;
            info.email = user.userId;
            setPassError(false);
            setIsLoading(true);
            setErrorMessage('');
            let equery = (localStorage.equery.indexOf('&') < 0) ? localStorage.equery :
                localStorage.equery.split('&')[0];
            try {
                const {data: {result}} =
                    await axios.post(`user/Password/${equery}/New/${newPass}`);                    
                localStorage.removeItem('equery');
                setIsLoading(false);

                switch (result) {
                    case ApiResult.SUCCESS: {
                        setShowSuccessMessage(true);
                        setNewPass('');
                        setRepeatPass('');
                        setPassChangeResult('Password changed successfully.');
                        break;
                    }
                    case ApiResult.CURRENT_PASSWORD_INCORRECT: {
                        showSuccessMessage(false);
                        setPassChangeResult('The link on your email is expired. Please go to login page and click on "Forgot your password?"');
                        break;
                    }
                    case ApiResult.TECHNICAL_PROBLEM: {
                        showSuccessMessage(false);
                        setPassError(false);
                        setPassChangeResult('Technical problem Password not changed.');
                        break;
                    }
                    case ApiResult.CONTACT_NOT_FOUND: {
                        setShowSuccessMessage(false);
                        setPassError(false);
                        setPassChangeResult('The User is not found.');
                        break;
                    }
                    default: {
                        setShowSuccessMessage(false);
                        setPassChangeResult(GenericAccountAccessMessage);
                        break;
                    }
                }
            } catch {
                setIsLoading(false);
                setShowSuccessMessage(false);
                setPassChangeResult(GenericAccountAccessMessage);
            }
        }
    }

    return (
        <Container>
            <div>
                <h2>Change Password</h2>
                <PasswordFieldWrapper>
                    <TextField
                        name="newPass"
                        type={showNewPass ? 'text' : 'password'}
                        placeholder="New Password"
                        label="New Password"
                        text={newPass}
                        showErrorMessage={passError}
                        onBlur={passChange}
                        tabIndex={2} />
                    {<PasswordEye aria-hidden="true" onClick={() => setShowNewPass(!showNewPass)} icon="eye-slash" />}
                </PasswordFieldWrapper>
                <PassWarning>{errorMessage}</PassWarning>
                <PasswordFieldWrapper>
                    <TextField
                        name="repeatPass"
                        type={showRepeatPass ? 'text' : 'password'}
                        placeholder="Confirm New Password"
                        label="Confirm New Password"
                        className="security-input"
                        text={repeatPass}
                        showErrorMessage={passError}
                        onBlur={passChange}
                        tabIndex={3} />
                    {<PasswordEye aria-hidden="true" onClick={() => setShowRepeatPass(!showRepeatPass)} icon="eye-slash" />
                    }
                </PasswordFieldWrapper>
                <PassWarning>
                    {passChangeResult}
                    <br />
                    {(showSuccessMessage) ? <Link to="/login">Return to Homepage</Link> : null}
                </PassWarning>
                <div style={{'marginTop': '20px'}}>
                    <Button secondary={true} tabIndex={4} loading={isLoading} type="submit" onClick={submitPassword}>CHANGE PASSWORD
                        {showSuccessMessage && <Check aria-hidden="true" icon={faCheck} />}
                    </Button>
                </div>                
            </div>
        </Container>);
};

export default ChangePassword;
