import React, {useState, useEffect, Fragment} from 'react';
import styled from 'styled-components';
import {TextField, brandFont, Button, TextFieldSelect,Dropdown} from '@partssourceinc/react-ui-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/pro-light-svg-icons';
// import {useHistory} from 'react-router-dom';
import DatePicker from 'components/DatePicker';
import {useSelector,useDispatch} from 'react-redux';
import axios from 'axios';
import {handleOnCurrencyKeyDown} from 'utility'
import {validateMoney, validatePhoneNumber} from 'utils/validation';
import {processFiles} from 'utils/FileHandler';
import {setSelectedFacility} from 'reducers/user';
import {getSupplier} from 'reducers/supplier';
const MAX_ALLOWED_CHARS = 900;
const MAX_CHARGE_AMOUT = 999999.99;
const PHONE_CHAR_LIMIT = 15;
const DESCRIPTION_CHAR_LIMIT = 450;
const MAX_FILE_SIZE_MB = 10;
// #region
const FileUploaderContainer = styled.div`
label {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 !important;
    padding: 0 !important;
}

`
const DropdownList = styled(Dropdown)`
    max-width: 28em;
    margin-bottom: 15px;
`;

const AttachmentHint = styled.p`
color: rgb(148, 148, 148);
${brandFont('12px')};
font-weight: normal;
height: 15px;
letter-spacing: 0px;
margin-top: 14px
`

const Label = styled.p`
font-size: 20px;
font-weight: normal;
margin-bottom: 15px;
margin-top: 38px;
clear: both;
`;

const TextBlock = styled(TextField)`
margin-bottom: 15px;

> div {
    width: 100%;
    height: 107px;
}
${brandFont('16px')}
`;

const Line = styled.hr`
border-top: 1px solid #949494;
height: 2px;
width: 100%;
float: left;
`;

const SaveButton = styled(Button)`
float: right;
`;

const CustomTextField = styled(TextField)`
max-width: 20em; 
min-width: 280px;
`;

const CustomTextFieldSelect = styled(TextFieldSelect)`
max-width: 20em; 
min-width: 280px;
margin-bottom: 16px;
`;

// const QuestionMarkIcon = styled(FontAwesomeIcon)`
// margin: 0 auto;
// display: flex;
// color: #009cf4;
// width: 39px !important;
// height: 39px !important;
// margin-bottom: 17px
// `;

const SuccessIcon = styled(FontAwesomeIcon)`
margin: 0 auto;
color: #03d0bf;
width: 29px !important;
height: 29px !important;
`;

// const ExclamationCircleIcon = styled(FontAwesomeIcon)`
// margin: 10px;
// display: flex;
// align-self: baseline;
// color: #ff9505;
// width: 24px !important;
// height: 24px !important;
// `;

const SpacedWrapper = styled.div`
margin: 0 auto;
width: 50%;
padding: 45px 0 75px 0;`

const PageTitle = styled.div`
color: rgb(0, 0, 0);
${brandFont('30px')}
font-weight: 300;
height: 38px;
letter-spacing: 0px;
text-align: center;
line-height: 1;
margin: 16px 0 22px 0;
`

// const WarningSection = styled.div`
// width: 100%;
// background: rgb(255, 228, 192);
// border-radius: 0px;
// display: flex;
// align-items: center;
// padding: 12px;
// margin-bottom: 31px;
// `

// const WarningSectionTitle = styled.span`
// color: rgb(0, 0, 0);
// ${brandFont('14px')}
// font-weight: bolder;
// letter-spacing: 0px;
// margin-left: 8px;

// `
// const WarningSectionText = styled.span`
// color: #777;
// ${brandFont('14px')}
// font-weight: 600;
// letter-spacing: 0px;
// text-decoration: underline;
// display: block;
// cursor: pointer;
// `
const Confirmation = styled.div`
display: flex;
justify-content:space-evenly;
flex-direction:column;
place-items: center;
height: 30vh;
svg {
    margin: 0 auto;
}
p {
    font-weight: 600 !important;
    color: rgb(0, 0, 0);
    ${brandFont('16px')}
    height: 80px;
    letter-spacing: 0px;
    text-align: center;
    width: 490px;
}

p > a {
    color: #cccccc;
    text-decoration: underline

}
h1 {
    color: rgb(0, 0, 0);
    ${brandFont('30px')}
    font-weight: 300;
    height: 38px;
    letter-spacing: 0px;
    text-align: center;
    width: 490px;
}
`
const ErrorMessageContainer = styled.div`
${brandFont('12px')};
color: red;
`;

const FileSizeError = styled.p`
    color: red;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
`;

const AttachmentsContainer = styled.div`
    margin-top: 5px;
    display: flex;
    width: 100%;
`;

const AttachmentName = styled.span`
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: normal;
    height: 22px;
    line-height: 22px;
    padding-left: 8px;
`;

const RemoveAttachment = styled.span`
    color: rgb(119, 119, 119);
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    text-decoration: underline;
    margin-left: 8px;
    cursor: pointer;
`;

const Limit = styled.p`
    color: #000000;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
`;

// #endregion
const NonReferenceVdf = () => {
    const [loading, setLoading] = useState(false);
    const [maxDate, setMaxDate] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [partsSourcePO, setPartsSourcePO] = useState();
    const [itemNumber, setItemNumber] = useState();
    const [itemDescription, setItemDescription] = useState();
    const [trackingNumber, setTrackingNumber] = useState();
    const [receivedOn, setReceivedOn] = useState();
    const [faultsFound, setFaultsFound] = useState();
    const [repairReplace, setRepairReplace] = useState();
    const [returnType, setReturnType] = useState();
    const [charges, setCharges] = useState();
    const [attachments, setAttachments] = useState([]);
    const [confirmation, setConfirmation] = useState(false);
    const [focusedFields, setFocusedFields] = useState([]);
    let companyId = useSelector(state => state.user.companyId);   
    const [selectedCompanyId, setSelectedCompanyId] = useState(companyId);
    const returnTypeList = [{id: 1, ReturnType: 'Warranty'},{id: 2, ReturnType: 'Restock Return'},{id: 3, ReturnType: 'Exchange (Core)'}]

    const [errorMessage, setErrorMessages] = useState();
    // const history = useHistory();
    const facilities = useSelector(state => state.settings.facilities);
    const dispatch = useDispatch();
    const loadSupplier = async() => {        
        await dispatch(getSupplier(companyId));        
    };
    const handleRepairsSave = async () => {
        setLoading(true)
        const data = {
            'TrackingNumber': trackingNumber,
            'ReturnTypeId': returnType?.id || 0,
            'ProblemNote': faultsFound,
            'ReplacementNote': repairReplace,
            'Cost': Number(charges.replace('$', '')),
            'ContactPhoneNumber': phoneNumber,
            'Description': itemDescription,
            'companyId': selectedCompanyId,
            'PartNumber': itemNumber,
            'PoNumber': partsSourcePO,
            'Attachments': attachments,
            'ReceivedDate': receivedOn,
        }
        await axios.post(`/common/vdf`, data)
            .then(() => {
                setFocusedFields([])
                setConfirmation(true)
                setAttachments([])
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })

    }

    const handleRemove = (attachmentId) => {
        setAttachments(attachments.filter(c => c.VdfHeaderId !== attachmentId));
    }

    const handleFiles = (e) => {

        e.preventDefault();
        const files = e.target.files;

        const display = e.target
            .value
            .split('/')
            .pop()
            .split('\\')
            .pop();
        processFiles(files, display).then(res => {
            if (res.uploadFile?.name !== '') {
                
                if (attachments.length === 0) {
                    setAttachments([
                        {
                            'VdfHeaderId': 1, 
                            'Data': res.uploadFile.file, 
                            'Description': 'Non-Reference Vdf attachment',
                            'Name': res.uploadFile.name,
                            'showMaxFileSizeError': (files[0].size > MAX_FILE_SIZE_MB * 1024000),
                        }])
                } else {
                    const lastAttachment = attachments.slice(-1);
                    setAttachments([...attachments].concat([{
                        'VdfHeaderId': lastAttachment[0].VdfHeaderId + 1, 
                        'Data': res.uploadFile.file, 
                        'Description': 'Non-Reference Vdf attachment',
                        'Name': res.uploadFile.name,
                        'showMaxFileSizeError': (files[0].size > MAX_FILE_SIZE_MB * 1024000),
                    }]))
                }
            }
        });
    }

    const handleUpdateCharge = (e) => {
        const value = e.target.value
        setFocusedFields([...new Set([...focusedFields, 'charges'])])
        let err = {...errorMessage};

        let realNumber = Number(value.replace(/[^0-9.-]+/g,''));
        
        if (realNumber > MAX_CHARGE_AMOUT) {
            err = 'Too long';
            setErrorMessages(err);
            return
        }
        if (Number(value) === '' || !validateMoney(value)) {
         
            if (value.trim() === '') {
                err = 'Invalid amount';
                setErrorMessages(err);
            } else if (!validateMoney(value)) {
                err = 'Invalid amount';
                setErrorMessages(err);
            } else {
                setCharges(value);
                err = null;
                setErrorMessages(err);
            }
            
        } else {
            if (!validateMoney(value)) {
                err = 'Invalid';
                setErrorMessages(err);

            } else {
                setCharges(value);
                err = null;
                setErrorMessages(err);
            }
        }
     
    }

    useEffect(() => {
        const today = new Date();
        const formattedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        setReceivedOn(formattedDate);
        setMaxDate(formattedDate);
        setSelectedCompanyId(companyId); 
    }, []);
    const handleChangeDefaultCompany = async (e) => {
        companyId = e.target.value;            
        setSelectedCompanyId(companyId);  
        dispatch(setSelectedFacility(companyId));
        loadSupplier();
        e.preventDefault();        
    };

    const renderAttachment = (attachment, key) => {
        return (<Fragment key={key}><AttachmentsContainer>
            <AttachmentName>{attachment.Name}</AttachmentName>
            <RemoveAttachment onClick={() => handleRemove(attachment.VdfHeaderId)}>Remove</RemoveAttachment>
        </AttachmentsContainer>
        <FileSizeError>
            {(attachment.showMaxFileSizeError && !loading) && `File exceeds max size of ${MAX_FILE_SIZE_MB} Mb.`}
        </FileSizeError></Fragment>)
    }

    const openDialogWindow = () => {
        const input = document.getElementById('file');
        input.click();
    }

    return (
        <SpacedWrapper>
            {!confirmation && <div>
                <PageTitle>Vendor Discrepancy Form</PageTitle>
                {/* <WarningSection>
                    <ExclamationCircleIcon icon={faExclamationCircle} />
                    <WarningSectionTitle>
                        If you have an existing Exchange or Return item with a discrepancy,
                        <WarningSectionText onClick={() => history.push('exchanges-returns?tab=Process')}>
                            please proceed to the Exchanges & Returns section to complete the proper form(s).
                        </WarningSectionText>
                    </WarningSectionTitle>

                </WarningSection>*/}
                {facilities?.length > 1 &&
                <div>
                    <DropdownList 
                        label="Company" 
                        onChange={handleChangeDefaultCompany}
                        options={facilities}
                        limit={55}
                        selectedValue={selectedCompanyId} 
                        suppressBlankOption={true} 
                        valueField="id" 
                        textField="name" />
                </div>}
                <CustomTextField
                    label={'Phone Number'}
                    type="text"
                    text={phoneNumber}
                    value={phoneNumber}
                    limit={PHONE_CHAR_LIMIT}
                    name={'phoneNumber'}
                    onChange={(e) => {
                        setPhoneNumber(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'phoneNumber'])])
                    }}
                    style={{paddingBottom: '15px'}}
                    errorMessage={'Invalid'}
                    showErrorMessage={(!phoneNumber || !validatePhoneNumber(phoneNumber)) && focusedFields.includes('phoneNumber')}
                />
                <CustomTextField
                    label={'PartsSource PO'}
                    type="text"
                    text={partsSourcePO}
                    value={partsSourcePO}
                    name={'PartsSourcePO'}
                    limit={55}
                    onChange={(e) => {
                        setPartsSourcePO(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'partsSourcePO'])])
                    }}
                    showErrorMessage={(!partsSourcePO) && focusedFields.includes('partsSourcePO')}
                    errorMessage={'Required field'}
                    style={{paddingBottom: '15px'}}
                />
                <CustomTextField
                    label={'Item Number'}
                    type="text"
                    text={itemNumber}
                    value={itemNumber}
                    name={'itemNumber'}
                    limit={55}
                    onChange={(e) => {
                        setItemNumber(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'itemNumber'])])
                    }}
                    showErrorMessage={(!itemNumber) && focusedFields.includes('itemNumber')}
                    errorMessage={'Required field'}
                    style={{paddingBottom: '15px'}}
                />
                <TextBlock
                    multiLine={true}
                    name="description"
                    id="description"
                    type="text"
                    placeholder="Please describe"
                    limit={DESCRIPTION_CHAR_LIMIT}
                    text={itemDescription}
                    value={itemDescription}
                    onChange={(e) => {
                        setItemDescription(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'itemDescription'])])
                    }}
                    showErrorMessage={!itemDescription && focusedFields.includes('itemDescription')}
                    errorMessage={'Required field'}
                    label="Item Description"
                />
                <Label>Tracking</Label>
                <CustomTextField
                    label={'Tracking Number'}
                    type="text"
                    name={'trackingNumber'}
                    text={trackingNumber}
                    limit={55}
                    value={trackingNumber}
                    onChange={(e) => {
                        setTrackingNumber(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'trackingNumber'])])
                    }}
                    showErrorMessage={(!trackingNumber) && focusedFields.includes('trackingNumber')}
                    errorMessage={'Required field'}
                    style={{paddingBottom: '15px'}}
                />
                <Label>Received On</Label>
                <DatePicker
                    value={receivedOn}
                    maxDate={maxDate}
                    text={receivedOn}
                    label="Received On"
                    onChange={(e) => {
                        setReceivedOn(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'receivedOn'])])
                    }}
                />
                {!receivedOn && focusedFields.includes('receivedOn') &&
                    <ErrorMessageContainer>
                        Required field
                    </ErrorMessageContainer>}
                <Label>Faults Found</Label>
                <TextBlock
                    multiLine={true}
                    name="faultsFound"
                    id="faultsFound"
                    type="text"
                    placeholder="Please describe"
                    limit={MAX_ALLOWED_CHARS}
                    text={faultsFound}
                    value={faultsFound}
                    onChange={(e) => {
                        setFaultsFound(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'faultsFound'])])
                    }}
                    label="Faults Found"
                    showErrorMessage={!faultsFound && focusedFields.includes('faultsFound')}
                    errorMessage={`${MAX_ALLOWED_CHARS} characters allowed`}
                />
                <Label>Repair/Replace</Label>
                <TextBlock
                    multiLine={true}
                    name="repairReplace"
                    id="repairReplace"
                    type="text"
                    placeholder="Please describe"
                    limit={MAX_ALLOWED_CHARS}
                    text={repairReplace}
                    value={repairReplace}
                    onChange={(e) => {
                        setRepairReplace(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'repairReplace'])])
                    }}
                    label="Description"
                    showErrorMessage={!repairReplace && focusedFields.includes('repairReplace')}
                    errorMessage={`${MAX_ALLOWED_CHARS} characters allowed`}
                />

                <CustomTextFieldSelect
                    keyField="id"
                    valueField="ReturnType"
                    onSelect={data => {
                        setReturnType(data)
                        setFocusedFields([...new Set([...focusedFields, 'returnType'])])
                    }}
                    listItems={returnTypeList}
                    selectedItem={returnType}
                    label="Return Type"
                    placeHolder="Return Type"
                    showErrorMessage={!returnType && focusedFields.includes('returnType')}
                    errorMessage={'Required field'}
                    disableClear={true}
                />

                <CustomTextField
                    label={'Charges'}
                    value={charges}
                    type="text"
                    errorMessage="Invalid amount!"
                    name={'charges'}
                    min={0}
                    max={MAX_CHARGE_AMOUT}
                    onKeyDown={handleOnCurrencyKeyDown}
                    onChange={handleUpdateCharge}
                    showErrorMessage={(!charges || charges < 0 || charges > MAX_CHARGE_AMOUT) && focusedFields.includes('charges') }
                    style={{paddingBottom: '15px'}} />
                {errorMessage && <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>}

                <FileUploaderContainer>
                    <Limit>10 MB max file size</Limit>
                    <Button onClick={() => openDialogWindow()} type="file" secondary={true}>
                        Upload File
                    </Button>
                    <input 
                        accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xls, .xlsx, .pdf" 
                        type="file" 
                        style={{display: 'none'}} 
                        id="file" 
                        onChange={handleFiles} />
                    {
                        attachments && attachments.length ? attachments.map((attachment, key) => {
                            return renderAttachment(attachment, key)
                        }) : <></>
                    }
                    
                </FileUploaderContainer>
                <AttachmentHint>
                    Please upload any pertinent documentation supporting the issue(s).
                </AttachmentHint>
                <Line />
                <SaveButton
                    disabled={(!phoneNumber || !validatePhoneNumber(phoneNumber)) 
                        || (!partsSourcePO) 
                        || (!itemNumber) 
                        || (!trackingNumber)
                        || !itemDescription 
                        || !returnType || (!charges || errorMessage) || charges < 0 || charges > MAX_CHARGE_AMOUT
                        || !validateMoney(charges) || !faultsFound || !receivedOn || !repairReplace
                        || (attachments.some(c => c.showMaxFileSizeError))
                    }
                    primary={true}
                    onClick={handleRepairsSave}
                    loading={loading}>
                    SUBMIT DISCREPANCY
                </SaveButton>
                <SaveButton
                    ternary={true}
                    onClick={() => {
                        window.history.back()
                    }}
                >
                    CANCEL
                </SaveButton>
            </div>}

            {confirmation &&
                <Confirmation>
                    <SuccessIcon icon={faCheckCircle} />
                    <h1>
                        Discrepancy Submitted
                    </h1>
                    <p>
                        The Non-reference VDF Form has been successfully submitted PartsSource for Suppliers.
                        If you have questions, please contact <a href="mailto:supplier_support@partsource.com">supplier_support@partsource.com </a> 
                        or call <a href="tel:877-497-6412">877-497-6412 </a>
                        and ask for the Supplier Pro representatives.
                    </p>
                </Confirmation>}
        </SpacedWrapper>
    )
}
export default NonReferenceVdf;
