import React from 'react';
import FlyOutSubMenu from './FlyOutSubMenu';
import PropTypes from 'prop-types';
import useCmsMenu from 'hooks/useCmsMenu';

const CmsFlyOutSubMenu = (props) => {
    const {title = '', subMenuClick, ...rest} = props;
    const [items] = useCmsMenu();

    const content = {
        title,
        items,
    }
    return (
        <FlyOutSubMenu content={content} subMenuClick={res => subMenuClick(res)} {...rest} />
    )
}

CmsFlyOutSubMenu.propTypes = {
    title: PropTypes.string.isRequired,
    subMenuClick: PropTypes.func.isRequired,
};

export default CmsFlyOutSubMenu;
