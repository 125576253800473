import React from 'react'
import * as PropTypes from 'prop-types';
import {Popup, Button} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';

const UnauthorizedPopup = styled(Popup)`
    max-width: 600px;
`;

const PopupBody = styled.div`
    font-family: Source Sans Pro,sans-serif;
`;

const Title = styled.h1`
    font-size: 20px;
`;

const BodyContent = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const BodyText = styled.p`
    font-size: 15px;
    font-weight: 600;
`

const Line = styled.hr`
    border-top: 1px solid #949494;
    height: 2px;
    width: 100%;
    float: left;
`;

const SaveButton = styled(Button)`
    float: right;
`;

const Link = styled.a`
    color: #a1a1a1; 
    font-weight: 400;
`;

const UnauthorizedUrlDialog = ({show, onRedirect}) => {

    return (
        <UnauthorizedPopup
            show={show}
            hideButtons={true}
        >
            <PopupBody>
                <Title>Access Has Not Yet Been Enabled For This Section</Title>
                <BodyContent>
                    <BodyText>
                        You are being redirected to the Home page. If you are not redirected in 10 seconds,
                        press the orange CONTINUE TO HOME button below.
                    </BodyText>
                    <BodyText>
                        If you feel you have reached this message in error, or to request access for Digital Order Management features, please call
                        <Link href="tel:+1877-497-6412"> (877) 497-6412</Link> or email
                        <Link href="mailto:supplierportalsupport@partssource.com"> supplierportalsupport@partssource.com</Link>.
                    </BodyText>
                </BodyContent>
                <Line />
                <SaveButton
                    primary={true}
                    onClick={onRedirect}
                    loading={false}>
                    CONTINUE TO HOME
                </SaveButton>
            </PopupBody>
        </UnauthorizedPopup>);
}

export default UnauthorizedUrlDialog;

UnauthorizedUrlDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onRedirect: PropTypes.func.isRequired,
};

