import React, {useEffect, useState, useRef, Fragment} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useHistory} from 'react-router';
import axios from 'axios';
import {faSearch, faCog, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import {Checkbox, SwitchCheckbox, Dropdown, Popup, theme, MobileView} from '@partssourceinc/react-ui-core';
import * as json2csv from 'json2csv';
import {responsive} from 'utils/styles';
import GridView from 'components/GridView';
import CardView from 'components/CardView/CardView';
import {logEvent, logEventVerbose, getCookie, getPageAndTab} from 'utility';
import SearchCriteria from 'components/SearchCriteria';
import {setGridPreferences} from 'reducers/user';
import {setCachedRgaFilters, getCachedRgaFilters} from 'reducers/orders';

import 'components/css/DataTable.css';
import 'components/css/grid.css';
import 'components/css/MultiSelect.css';
import 'components/css/primereact.css';
import 'components/css/theme.css';
import 'components/css/multiGrid.css';

const Lg = styled.div`
    ${responsive({min: 'lg'})};
`;

const CustomSwitchCheckbox = styled(SwitchCheckbox)`
    div {
        height: 10px;
        div {
            width: 15px;
            height: 15px;
        }
    }
`;
const CustomDropdown = styled(Dropdown)`
   max-width: 20em;
   margin-left:15px;
`;

const MinMd = styled.div`
    ${responsive({min: 'md'})};

    li {
        cursor: pointer;
    }
`;

const MaxSm = styled.div`
    ${responsive({max: 'sm'})};
`;

const MultiGridPage = styled.div`
    margin-top: 20px;
    min-height: 500px;
    width: 100%;
`;

const GridTabWrapper = styled.div`
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 20px !important;
    margin-bottom: 18px !important;

    ${({empty}) => empty && css`
        border-bottom: none;
    `}
`;

const ViewWrapper = styled.div`
    padding-top: 15px;
`;

const RightArrow = styled(FontAwesomeIcon)`
    width: 15px;
    height: 15px;
    vertical-align: middle;
    color: ${theme.navMenuArrowColor};

    @media (min-width: 820px) and (max-width: 1149px) {
        margin-right: 13px;
    }
`;

const GridTabWrapperLabel = styled.label`
    display: inline-block;
    margin: 0 0 -1px;
    padding: 15px 25px;
    font-weight: 600;
    text-align: center;
    color: black;
    border: 1px solid transparent;
    font-size: 16px;

    ${({checked}) => checked && css`
        color: black;
        border: 1px solid #949494;
        border-top: 3px solid #005da6 !important;
        border-bottom: 2px solid #fff;
    `}

    &:hover {
        //color: #789;
        cursor: pointer;
    }

    span {
    background-color: #dcdcdc;
    border-radius: 10px;
    display: inline-block;
    height: 20px;
    line-height: 10px;
    margin-left: 5px;
    min-width: 22px;
    padding: 5px;
    font-size: 12px;
    font-weight: 700;
    }
`;

const GridHeader = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1149px) {
        position: sticky;
        top: 0px;
        background-color: #ffffff;
        z-index: 800;
        margin: 0 -20px;
        padding: 0 20px 18px 20px;
        align-items: center;
    }

    @media (min-width: 820px) and (max-width: 1149px) {
        padding-top: 0px;
        display: flex;
        flex-wrap: nowrap;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    color: #4a4a4a;
    font-size: 27px;
    margin-top: 0px;
    cursor: pointer;
    margin-left: -2px;
`;

const Loader = styled.div`
    background-color: hsla(0, 0%, 100%, 0.8);
    position: absolute;
    text-align: center;
    width: 100%;
    height: 50%;
    z-index: 990;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    
    &.dashboard {
      height: auto;
      margin-top: -130px;
    }
  
    &.report {
      height: calc(100% - 130px);
      margin-top: -130px;
    }
  
    .message {
      font-size: 22px;
      font-weight: 300;
    }
  
    .loader {
        position: absolute;
        left: calc(50% - 37.5px) !important;
        border: 10px solid #f3f3f3;
        border-radius: 50%;
        border-top: 10px solid #FF9505;
        width: 75px;
        height: 75px;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
        margin-top: 30px;
    }
  
    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
  
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
`;

const GridHeaderNavLink = styled(NavLink)`
    color: #777;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22px;
    margin-left: 15px;
    text-decoration: underline;
`;

const SearchWrapper = styled.div`
    @media (max-width: 819px) {
        width: 100%;
    }
`;

export const ViewType = {
    Grid: 'Grid',
    Card: 'Card',
};

export const GridNames = {
    bids: {
        id: 'bids',
        name: 'Bids',
        companySettingsId: 122,
    },
    orders: {
        id: 'orders',
        name: 'Orders',
        companySettingsId: 123,
    },
    repairs: {
        id: 'repairs',
        name: 'Repairs',
        companySettingsId: 124,
    },
    rgaOrders: {
        id: 'rgaOrders',
        name: 'Returns',
        companySettingsId: 125,
    },
    loanersAndRentals: {
        id: 'loanersAndRentals',
        name: 'Loaners',
        companySettingsId: 134,
    },
    ordersHistory: {
        id: 'ordersHistory',
        name: 'Orders',
    },
};

export const rgaTypes = [
    {id: 0, name: 'All'},
    {id: 1, name: 'Exchanges'},
    {id: 2, name: 'Returns'},
]

const labelKeys = {
    'Repairs': 'repair',
    'Orders': 'order',
    'Loaners': 'loaner',
    'Exchanges & Returns': 'exchange-return',
}

const MultiView = (props) => {
    const {data,
        disableCardView,
        gridKeys = [],
        gridName,
        headerLink,
        label,
        onRenderLegend,
        onRenderRow,
        onSaveGridPreferences,
        loadingData,
        setLoadingData,
        onRefresh,
        onUpdate,
        onSelect,
        view,
        isMobile,
        isPhone,
        showFilters,
        getOems,
        oems,
        onApply,
        filters,
        defaultSorting} = props;

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const params = useParams();
    const defaultColumns = useSelector(state => state.user[gridName]);
    const tempRefreshRate = useSelector(state => state.user?.defaultRefreshRate)
    const defaultRefreshRate = tempRefreshRate ? parseInt(tempRefreshRate) * 60000 : 60000;
    const [id_ins, setIdIns] = useState();
    const [currentTab, setCurrentTab] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [tabCounts, setTabCounts] = useState([]);
    const [defaultTab, setDefaultTab] = useState(true);
    const [columns, setColumns] = useState([]);
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [returnTypeFilter, setReturnTypeFilter] = useState(0);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [currentView, setCurrentView] = useState(view === 'Grid' ? ViewType.Grid : ViewType.Card);
    const [searchText, setSearchText] = useState();
    const [columnSelectionColumns, setColumnSelectionColumns] = useState([]);
    const [showColumnSelection, setShowColumnSelection] = useState(false);
    const [gridItems, setGridItems] = useState([]);
    const [showMobileGrid, setShowMobileGrid] = useState(false);
    const [sorting, setSorting] = useState(false);
    const searchBox = useRef(null);
    const mobileSearchBox = useRef(null);

    useEffect(() => {
        initializeTabs();
        if (!loadingData && currentTab && defaultTab) sendLogTabClick(currentTab, true);
    }, [tabCounts]);

    useEffect(() => {
        setSorting(true);
        countTabs();
        const items = getGridItems();
        setGridItems(items);
        setCurrentPage(1);
        sendLogTabClick(currentTab, false);

        if (window.innerWidth < 819) {
            setShowMobileGrid(true);
        }
    }, [currentTab]);

    useEffect(() => {
        const urlParams = getLocationQuery(location);
        const tab = (urlParams && urlParams.tab) || (tabCounts.length && tabCounts[0].id) || null;

        if (tab && currentTab !== tab && !isMobile) {
            setCurrentTab(tab);
        }
    }, [params]);

    useEffect(() => {
        if (!loadingData) {
            countTabs();
            const items = getGridItems();
            setGridItems(items);
            loadColumns();
        }
    }, [loadingData]);

    useEffect(() => {
        const type = JSON.parse(getCachedRgaFilters());
       
        if (gridName === GridNames.rgaOrders.id && type && type !== returnTypeFilter) {            
            handleFilter(type);           
        }

        const items = getGridItems();
        setGridItems(items);
        
    }, [returnTypeFilter]);

    useEffect(() => {
        if (showSearchBox)
            searchBox.current.focus();
    }, [showSearchBox])

    useEffect(() => {
        const items = getGridItems();
        setGridItems(items);
    }, [searchText])

    useEffect(() => {
        if (!autoRefresh ||
            onRefresh === undefined ||
            gridName !== GridNames.bids.id) return;

        const timer = setInterval(() => {
            onRefresh();
        }, defaultRefreshRate);

        return () => clearInterval(timer);
    });

    const loadColumns = () => {
        let {columns: propsColumns} = props;
        if (propsColumns && propsColumns.length === 0) {
            propsColumns = defaultColumns;
        }

        propsColumns = propsColumns?.filter(c =>
            defaultColumns?.find(x => x.field === c.field && x.visible !== 'never'));
        if ((propsColumns || []).length > 0) {
            let tempColumns = [
                ...defaultColumns?.filter(c => c.visible !== 'never')
                    .concat(propsColumns)
                    .reduce((m, o) => m.set(o.field, Object.assign(m.get(o.field) || {}, o)), new Map())
                    .values(),
            ];

            setColumns(_.sortBy(tempColumns, 'order'));
        }
    }

    const initializeTabs = () => {
        setIdIns(getCookie('id_ins'));

        if (currentTab || gridName === 'ordersHistory') return;

        const urlParams = getLocationQuery(location);
        const tab = (urlParams && urlParams.tab) || (tabCounts.length && tabCounts[0].id) || null;

        if (!isMobile) {
            setCurrentTab(tab);

            if (tab && !urlParams.tab) {
                const url = location.search ? `${location.pathname}${location.search}&` : `${location.pathname}?`;
                window.history.replaceState(null, null, `${url}tab=${tab}`);
            }
        }
    }

    const countTabs = () => {
        if (!gridKeys || gridKeys.length === 0) return;
        const tabCounter = [];

        for (let i = 0; i < gridKeys.length; i++) {
            let key = gridKeys[i];
            tabCounter.push({id: key.id, count: getBadgeCount(key, props)});
        }

        const temp = [...tabCounter];
        setTabCounts(temp);
    }

    const getLocationQuery = () => {
        const search = (location.search || '').substring(1);
        let result = {};

        if (search) {
            const cleaned = search;

            _.forEach(_.split(cleaned, '&'), kvp => {
                const parts = _.split(kvp, '=');
                const key = parts[0];
                const value = decodeURIComponent(parts[1]);

                if (result[key]) {
                    let current = result[key];
                    if (_.isArray(current)) {
                        current.push(value);
                    } else {
                        result[key] = [current, value];
                    }
                } else {
                    result[key] = value;
                }
            });
        }
        return result;
    }

    const getBadgeCount = (gridKey) => {
        let keyValues = typeof gridKey.value === 'string' ? gridKey.value.split(',') : gridKey.value.toString();
        let items = _.filter(data, (v) => 
            _.includes(keyValues, v[gridKey.fieldName].toString().toUpperCase()))

        if (gridName === GridNames.rgaOrders.id && returnTypeFilter > 0) {
            items = filterRgaType(items);
        }

        items = filterSearchText(searchText, items);
        return items.length;
    }

    const getGridItems = () => {
        let items = [];

        if (gridKeys && gridKeys.length > 0) {
            const gridKey = gridKeys.filter((x) => x.id === currentTab)[0];
            if (!gridKey) return [];
            let keyValues = typeof gridKey.value === 'string' ? gridKey.value.split(',') : gridKey.value.toString();
            items = _.filter(data, (v) => _.includes(keyValues, v[gridKey.fieldName].toString().toUpperCase()));
         
            if (gridName === GridNames.rgaOrders.id && returnTypeFilter > 0) {
                items = filterRgaType(items);
            }

            items = filterSearchText(searchText, items);
            // Filter RGATypes if Returns
            items = _.cloneDeep(items).map((g) => {
                if (g.description) {
                    g.description = truncateDescriptionText(g.description);
                }
                return g
            })

            return items;
        } else {
            items = data;
            
            if (gridName === GridNames.rgaOrders.id && returnTypeFilter > 0)
                items = filterRgaType(items);

            if (searchText && items.length > 0) {
                items = items.filter((x) => {
                    let searchString = '';
                    columns.filter((c) => c.visible).forEach((qc) => (searchString += (x[qc.field] || '').toString().toLowerCase()));
                    return searchString.indexOf(searchText) > -1;
                });
            }

            items = _.cloneDeep(items).map((g) => {
                g.dateCreated = moment(g.dateCreated).format('MM/D/YYYY');
                g.description = truncateDescriptionText(g.description);
                if (g.rgaCreatedTimestamp && !g.canGenerateShippingLabel && (!g.vendorRmaRequired || (g.vendorRmaRequired && g.vendorRmaNumber))) {
                    g.hasRgaDocumentation = `${g.hasRgaDocumentation} | Label Created on ${moment(g.rgaCreatedTimestamp).format('MM/DD/YYYY')}`
                }
                return g
            });

            return items;
        }
    }

    const filterSearchText = (text, items) => {
        if (text && items.length > 0) {
            items = items.filter((x) => {
                let searchString = '';
                columns.forEach((qc) => (searchString += (x[qc.field] || '').toString().toLowerCase()));
                columns
                    .filter((c) => c.isNested)
                    .forEach((qc) => {
                        let nestedField = qc.field.split('.');
                        if (nestedField.length === 2) searchString += (x[nestedField[0]][nestedField[1]] || '').toString().toLowerCase();
                    });
                return searchString.indexOf(text) > -1;
            });
        }

        return items;
    }

    const filterRgaType = (items) => {
        if (items.length > 0) {
            if (parseInt(returnTypeFilter) === 0) {
                items = items.filter(x => x.rgaTypeId === 1 || x.rgaTypeId === 2);            
            } else {
                items = items.filter(x => x.rgaTypeId === parseInt(returnTypeFilter));  
            }
        }
        
        return items;
    }

    const tabClick = (tabInd) => {
        if (gridName === 'ordersHistory') return;
        setSorting(true);
        setCurrentTab(tabInd);

        if (window.innerWidth < 819) {
            setShowMobileGrid(true);
        }

        let url = `${location.pathname}?tab=${tabInd}`;
        history.push(url);
    }

    const truncateDescriptionText = (desc) => (desc && desc.length > 80) ? desc.substring(0, 80) + ' ...' : desc;

    const renderHeader = () => {
        let rgaFilter = JSON.parse(getCachedRgaFilters());
        let selectedValue = rgaTypes.filter(x => x.id === parseInt(rgaFilter ? rgaFilter : returnTypeFilter));
      
        return (
            <React.Fragment>
                <GridHeader className="grid-header grid-header--sticky">
                    <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}} className="mobile-display">
                        <div>
                            <span className="grid-label">{label}</span>
                        </div>
                        {
                            gridName === GridNames.bids.id ?
                                <div className="ps-hidden-xs ps-hidden-sm" style={{marginLeft: '20px', marginTop: '.5rem'}}>
                                    <CustomSwitchCheckbox
                                        class="switch-checkbox"
                                        label="Auto Refresh"
                                        className="audience-toggle"
                                        value={autoRefresh}
                                        onChange={(checked) => {
                                            const result = {
                                                value: {
                                                    selection: checked ? 'Left' : 'Right',
                                                },
                                            };
                                            handleRequesterToggle(checked, result);
                                        }}
                                    />                        
                                </div> :
                                <div>
                                    {
                                        gridName === GridNames.rgaOrders.id ?
                                            <div>
                                                <CustomDropdown
                                                    id="rgaTypeFilterDropdown"
                                                    name="rgaTypeFilterDropdown"
                                                    placeholder="View"
                                                    onChange={handleRgaTypeFilterChanged}
                                                    options={rgaTypes}
                                                    selectedValue={selectedValue[0].id}
                                                    suppressBlankOption={true}
                                                    valueField="id"
                                                    textField="name"
                                                    className="filter"                                                 
                                                 
                                                />
                                            </div> :          
                                            gridName !== GridNames.ordersHistory.id && headerLink &&
                                                <div>
                                                    <GridHeaderNavLink to={headerLink.url}>
                                                        {headerLink.text}
                                                    </GridHeaderNavLink>
                                                </div> 
                                    } 
                                </div>
                        }                   
                    </div>
                    {renderToolbar()}
                    {isMobile && <hr />}
                </GridHeader>
                {showFilters &&
                    <SearchCriteria
                        getOems={getOems}
                        oems={oems}
                        filters={filters}
                        onApply={(e) => onApply(e)} />
                }

            </React.Fragment>);
    }

    const renderMobileHeader = () => {
        return (
            <React.Fragment>
                <GridHeader className="grid-header grid-header--sticky">
                    <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', width: '100%'}}>
                        <div>
                            <span className="grid-label">{label}</span>
                        </div>
                        {gridName === GridNames.bids.id ?
                            <div style={{marginBottom: '-.6rem', marginLeft: '15px'}}>
                                <SwitchCheckbox
                                    label="Auto Refresh"
                                    className="audience-toggle"
                                    value={autoRefresh}
                                    onChange={(checked) => {
                                        const result = {
                                            value: {
                                                selection: checked ? 'Left' : 'Right',
                                            },
                                        };
                                        handleRequesterToggle(checked, result);
                                    }}
                                />
                            </div> :
                            gridName !== 'ordersHistory' && headerLink ?
                                <div>
                                    <GridHeaderNavLink to={headerLink.url}>
                                        {headerLink.text}
                                    </GridHeaderNavLink>
                                </div> :
                                null
                        }
                    </div>
                    {renderMobileToolbar()}
                </GridHeader>
                {showFilters &&
                    <SearchCriteria
                        getOems={getOems}
                        oems={oems}
                        filters={filters}
                        onApply={(e) => onApply(e)} />
                }

            </React.Fragment>);
    }

    const renderTabStrip = () => {
        return gridKeys.length ? (
            <React.Fragment>
                <div className="ps-hidden-xl ps-hidden-lg">
                    {loadingData 
                        ? <Loader>
                            <div className="loader" />
                        </Loader>
                        : <div className="tab-strip">{gridKeys.map((x, i) => (
                            <div key={i} className={x.id === currentTab ? 'active' : ''}>
                                <div className="tab-title" checked={currentTab === x.id} onClick={() => tabClick(x.id)} key={i}>
                                    {x.tabDisplay} <span>{getBadgeCount(x).toLocaleString()}</span>
                                </div>
                                <RightArrow className="tab-title-after" icon={faChevronRight} />
                            </div>
                        ))}
                        </div>}
                </div>
                <GridTabWrapper className="ps-hidden-xs ps-hidden-sm ps-hidden-md">
                    {gridKeys.map((x, i) => (
                        <Fragment key={i}>
                            <GridTabWrapperLabel checked={currentTab === x.id} onClick={() => tabClick(x.id)} key={i}>
                                {x.tabDisplay} <span>{getBadgeCount(x).toLocaleString()}</span>
                            </GridTabWrapperLabel>
                        </Fragment>
                    ))
                    }
                </GridTabWrapper>
            </React.Fragment>
        ) : (
            <Lg><div className="grid-tab-wrapper empty" /></Lg>
        );
    };

    const renderToolbar = () => {
        let cardViewClass = 'icon-view-selector';
        cardViewClass += disableCardView ? ' disable' : ' selected';

        const searchBoxClasses = {
            'form-control': true,
            'form-control hidden-search-box': true,
            'is-visible': showSearchBox,
        };

        return (
            <div className="grid-toolbar">
                {showSearchBox ? null : (
                    <div className="ps-hidden-xs ps-hidden-sm ps-hidden-md view-selector-wrapper">
                        <div className="viewText">View:</div>
                        {currentView === ViewType.Grid && (
                            <div>
                                <img
                                    src="/images/icon_card-view.svg"
                                    title={`Switch to Card View`}
                                    alt={`Switch to Card View`}
                                    onClick={() => onViewChange(ViewType.Card)}
                                    className={cardViewClass}
                                />
                            </div>
                        )}

                        {currentView === ViewType.Card && (
                            <div>
                                <img
                                    src="/images/icon_grid-view.svg"
                                    title={`Switch to Grid View`}
                                    alt={`Switch to Grid View`}
                                    onClick={() => onViewChange(ViewType.Grid)}
                                    className={cardViewClass}
                                />
                            </div>
                        )}
                        <div className="viewSeperator">
                            |
                        </div>
                    </div>
                )}
                <SearchWrapper>
                    {((view === ViewType.Card && window.innerWidth <= 1149) || (isMobile))
                        ? <div className="hidden-search-wrapper mobile-search">
                            <div className="search-textBox-wrapper">
                                <input
                                    ref={mobileSearchBox}
                                    type="search"
                                    className={classNames(searchBoxClasses)}
                                    onChange={(e) => onSearch(e)}
                                    autoComplete="grid-search"
                                />
                                <Icon className="icon searchIcon inlineIcon" icon={faSearch} />
                            </div>
                        </div>
                        : <div className="hidden-search-wrapper">
                            {showSearchBox && <div>
                                <div className="search-textBox-wrapper">
                                    <input
                                        ref={searchBox}
                                        type="search"
                                        className={classNames(searchBoxClasses)}
                                        onFocus={() => onSearchFocus()}
                                        onBlur={(e) => onSearchBlur(e)}
                                        onChange={(e) => onSearch(e)}
                                        disabled={loadingData}
                                        autoComplete="grid-search"
                                    />
                                </div>
                            </div>
                            }
                            <div style={{paddingTop: '10px'}}>
                                <Icon className="icon searchIcon" icon={faSearch}
                                    onClick={() => setShowSearchBox(true)} />
                            </div>
                            
                        </div>}
                </SearchWrapper>

                <div className="ps-hidden-xs ps-hidden-sm ps-hidden-md context-menu-wrapper">
                    <div>
                        <Icon className="icon" icon={faCog} />
                    </div>
                    {/* <i className="fa fa fa-cog" /> */}
                    <div className="context-menu">
                        <div className="context-item" onClick={() => onShowColumnSelection()}>
                            <span>Show / Hide Columns</span>
                        </div>
                        <div className="context-item" onClick={async () => await onExport(false)}>
                            <span>Export Current Tab</span>
                        </div>
                        {gridName === 'rgaOrders' && <div className="context-item" onClick={() => onExport(true)}>
                            <span>Export All Tabs</span>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }

    const renderMobileToolbar = () => {
        const searchBoxClasses = {
            'form-control': true,
            'form-control hidden-search-box': true,
            'is-visible': showSearchBox,
        };

        return (
            <div className="grid-toolbar" style={{display: 'inline-block', width: '100%'}}>
                <div className="hidden-search-wrapper mobile-search">
                    <div className="search-textBox-wrapper" style={{width: '100%'}}>
                        <input
                            ref={mobileSearchBox}
                            type="search"
                            className={classNames(searchBoxClasses)}
                            onChange={(e) => onSearch(e)}
                            autoComplete="grid-search"
                            style={{width: '95%'}}
                        />
                        <Icon className="icon searchIcon inlineIcon" icon={faSearch} />
                    </div>
                </div>
            </div>
        );
    }

    const onViewChange = (newView) => {
        // TODO: I beleive this will be added when we do All Orders
        // const {disableCardView } = this.props;
        // if (!disableCardView) {            
        setCurrentView(newView);
        saveGridPreferences(columnSelectionColumns, newView);
        sendLog(newView);
        // }
    }

    const sendLog = (viewType) => {
        if (!currentTab) return;
        const currentTabName = gridKeys.find((x) => x.id === currentTab).tabDisplay;

        if (tabCounts && tabCounts.length > 0) {
            const tabCount = tabCounts.find((x) => x.id === currentTab).count;
            logEvent('GRID CARD VIEW CHANGE', {
                Page: mapGridName(gridName),
                Tab: currentTabName,
                'View Setting': viewType,
                'Num Records on active tab': tabCount,
            });
        }
    }

    const mapGridName = (gridName) => {
        return gridName === 'loanersAndRentals' ? 'loaners-rentals' : gridName
    }

    const sendLogTabClick = (ind, dTab) => {                
        if (ind === null || gridKeys.length < 1) return;
        if (tabCounts.find((x) => x.id === ind)) {
            const tabCount = tabCounts.length > 0 ? (!ind ? tabCounts[0].count : tabCounts.find((x) => x.id === ind).count) : 0;

            const {page} = getPageAndTab();
            logEvent('TAB ACCESS', {
                Page: page,
                Tab: ind,
                Count: tabCount,
            });

            if (dTab)
                setDefaultTab(false);
        }
    }

    const onItemSelect = (row) => {
        onSelect(row);
        sendLogViewDetail(row);
    }

    const sendLogViewDetail = (row) => {
        logEvent('VIEW DETAILS', {
            'Order number': row.orderNumber || row.orderId,
            'Ref number': row.lineItemId,
            'Detail type': gridName,
            'Detail state': row.repairStatus || row.rgaType || row.returnExchangeStatus || row.status,
        });
    }

    const sendLogShowHideColumns = (newColumns, c) => {
        const newCols = newColumns.filter((x) => x.visible);
        const cols = columns.filter((x) => x.visible);
        let logDataDetails = [];
        const {tab, page} = getPageAndTab();
        logDataDetails.push({name: 'id_ins', value: id_ins});
        logDataDetails.push({name: 'Page', value: page});
        logDataDetails.push({name: 'Tab', value: tab});
        cols.forEach((oc) => {
            if (!newCols.some((nc) => nc.field === oc.field)) logDataDetails.push({name: 'Column Deleted', value: `${oc.header}`});
        });
        newCols.forEach((nc) => {
            if (!cols.some((oc) => nc.field === oc.field)) logDataDetails.push({name: 'Column Added', value: `${nc.header}`});
        });

        if (logDataDetails.length > 2) logEventVerbose('SHOW HIDE COLUMNS', logDataDetails);
    }

    const sendLogExportExcel = () => {
        const {page, tab} = getPageAndTab();

        logEvent('EXPORT EXCEL', {
            Page: page,
            Tab: tab,
        });
    }

    // TODO:  I beleive this is added for orders?
    // const sendLogViewHistory = (data) => {
    //     logEvent('VIEW HISTORY', {
    //         'View History': data.lineItemId,
    //         Age: data.closedDate ? moment(new Date().valueOf()).diff(moment(Date.parse(data.closedDate)).valueOf(), 'minutes') : 0,
    //         "Ship Priority": data.carrierPriority,
    //         Page: gridName,
    //         Tab: currentTab || gridName,
    //     });

    //     this.setState({showTrackingHistory: true, selectedLineItem: data});
    // }

    const classNames = (names) => {
        return _.join(_.keys(_.pickBy(names, function (value) {
            return value === true;
        })), ' ');
    }

    const saveGridPreferences = async (newColumns, newView) => {
        const newCols = (newColumns && newColumns.length > 0) ? newColumns : columns;

        if (!newCols || newCols.length === 0) return;

        const request = {
            gridName: gridName,
            view: newView || ViewType.Grid,
            columns: newCols,
        };

        await axios.post(`setting/grid`, request);
        await dispatch(setGridPreferences(request));

        onSaveGridPreferences(request);
        setColumnSelectionColumns([]);
        if (newColumns.length > 0 && newColumns !== columns)
            sendLogShowHideColumns(newColumns, columns);
    }

    const onSearchFocus = () => setShowSearchBox(true);

    const onSearchBlur = (e) => {
        setShowSearchBox(e.target.value !== '');
        let searchCount;
        if (gridKeys && gridKeys.length > 0) {
            searchCount = getBadgeCount(gridKeys.find((x) => x.id === currentTab)).toLocaleString();
        }

        const {page, tab} = getPageAndTab();

        logEvent('SEARCH', {
            'Search Phrase': searchText,
            'Search Results': searchCount,
            Page: page,
            Tab: tab,
        });
    }

    const onSearch = (e) => {
        setSearchText(e.target.value.toLowerCase());
        setCurrentPage(1);
    }

    const getTabData = (tabData) => {
        const currentBidTab = gridName && gridKeys.find(g => g.id.toUpperCase() === currentTab.toUpperCase());

        switch (gridName) {
            case GridNames.repairs.id:
                return tabData.filter(d => d.repairStatus.toUpperCase() === currentTab.toUpperCase());
            case GridNames.orders.id:
                return tabData.filter(d => d.status.toUpperCase() === currentTab.toUpperCase());
            case GridNames.bids.id:
                return tabData.filter(d => d.status.toUpperCase() === currentBidTab.value.toUpperCase());
            case GridNames.rgaOrders.id:
                return tabData.filter(d => d.returnExchangeStatus.toUpperCase() === currentTab.toUpperCase());
            case GridNames.loanersAndRentals.id:
                return tabData.filter(d => d.loanerStatus.toUpperCase() === currentTab.toUpperCase());
            default:
                return tabData;
        }
    }

    const onExport = (allData) => {
        if (!(gridName === GridNames.rgaOrders.id)) {
            if (gridItems.length === 0) return;
        }

        let tabData = allData 
            ? filterRgaType(data)
            : getTabData(gridItems);
        if (!(gridName === GridNames.rgaOrders.id)) {

            if (!tabData || tabData.length === 0)
                return;
        }

        const Json2csvParser = json2csv.Parser;
        Json2csvParser.flatten = true;
        const fields = [];

        if (gridName === GridNames.rgaOrders.id)
            fields.push({label: 'Status Code', value: 'statusCode'})

        columns.forEach((x) => {
            fields.push({label: x.header, value: x.field})
        });

        let exportData = gridName === GridNames.rgaOrders.id
            ? prepareExportDataRga(tabData, fields)
            : tabData;

        const json2csvParser = new Json2csvParser({fields});
        const csv = json2csvParser.parse(exportData);
        const csvFile = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]),csv], {type: 'text/csv'});

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(csvFile, 'DataExport.csv');
            return;
        }

        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(csvFile));
        link.setAttribute('download', 'DataExport.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
        sendLogExportExcel();
    }

    const prepareExportDataRga = (exportData, fields) => {
        let appendingFieldList = exportData.map(td => {
            let completeObj = {};
            if (td.trackingNumber)
                completeObj.trackingNumber = `=HYPERLINK("${td.trackingUrl}", "${td.trackingNumber}")`;
            completeObj.statusCode = gridKeys.find((x) => x.id === td.returnExchangeStatus).tabDisplay;

            return completeObj;
        })

        const merge = (exportData, appendingFieldList) => {
            let fullExportList = [];
            for (let i = 0; i < appendingFieldList.length; i++) {
                const mergedObj = Object.assign({}, exportData[i], appendingFieldList[i])
                fullExportList.push(mergedObj);
            }
            return fullExportList;
        }

        return merge(exportData, appendingFieldList);
    }

    const onShowColumnSelection = () => {
        const selectionColumns = _.cloneDeep(columns.filter(c => c.visible !== 'never'));
        setColumnSelectionColumns([...selectionColumns]);
        setShowColumnSelection(true);
    }

    const onSaveColumnSelection = () => {
        const {setColumnList} = props;
        saveGridPreferences(columnSelectionColumns, view);
        setColumns(columnSelectionColumns);
        setColumnList(columnSelectionColumns);
        setShowColumnSelection(false);
    }

    const onColumnCheckboxChanged = (e) => {
        const selectionColumns = columnSelectionColumns;
        selectionColumns.filter((x) => x.field === e.id)[0].visible = e.checked;
        setColumnSelectionColumns([...selectionColumns]);
    }

    const renderColumnCheckbox = (id, checkboxLabel, checked, i) => (
        <Checkbox id={id}
            key={id}
            label={checkboxLabel}
            checked={checked}
            tabIndex={i}
            className="column-checkbox"
            onChange={onColumnCheckboxChanged}
        />);

    const handleRequesterToggle = () => {
        const {onRequesterToggle} = props;

        setAutoRefresh(!autoRefresh);

        if (onRequesterToggle) onRequesterToggle(autoRefresh);
    }

    const handleRgaTypeFilterChanged = (event, filterData) => {
        setCachedRgaFilters(filterData?.value);
        setReturnTypeFilter(filterData?.value);   
    }
    const handleFilter = (type) => {

        setReturnTypeFilter(type);
    }
    
    const getGridName = (gridId) => {
        return gridKeys.find(x => x.id === gridId).tabDisplay;
    }

    const renderGrid = () => {
        return (
            <ViewWrapper>
                {loadingData ? (
                    <div className="ps-hidden-md">
                        <Loader>
                            <div className="loader" />
                        </Loader>
                    </div>
                ) : null}

                {(!loadingData && currentView === ViewType.Card) &&
                    <CardView
                        gridName={gridName}
                        label={label}
                        view="Grid"
                        data={gridItems}
                        loadingData={loadingData}
                        setLoadingData={setLoadingData}
                        gridKeys={gridKeys}
                        onRefresh={onRefresh}
                        onUpdate={onUpdate}
                        onSelect={(e) => onItemSelect(e)}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        tabCounts={tabCounts}
                        type={labelKeys[label]}
                        sortingData={sorting}
                        setSortingData={val => setSorting(val)}
                        currentTab={currentTab}
                        defaultSorting={defaultSorting} />
                }
                {(!loadingData && currentView === ViewType.Grid) &&
                    <GridView
                        gridName={gridName}
                        label={label}
                        view="Grid"
                        data={gridItems}
                        onRenderLegend={onRenderLegend}
                        onRenderRow={onRenderRow}
                        loadingData={loadingData}
                        setLoadingData={setLoadingData}
                        gridKeys={gridKeys}
                        columns={columns}
                        onRefresh={onRefresh}
                        onSelect={(e) => onItemSelect(e)}
                        currentPage={currentPage}
                        setColumns={setColumns}
                        setCurrentPage={setCurrentPage}
                        tabCounts={tabCounts}
                        saveGridPreferences={saveGridPreferences}
                        currentTab={currentTab}
                        defaultSorting={defaultSorting} />
                }
            </ViewWrapper>);
    }

    return (
        <div className="custom-primereact">
            <MultiGridPage className="multi-grid-page multi-grid-page--sticky">
                {isMobile ? renderMobileHeader() : renderHeader()}
                {isMobile && <hr />}
                {showColumnSelection ? (
                    <Popup
                        confirmText="Save Columns"
                        cancelText="Cancel"
                        onCancel={() => setShowColumnSelection(false)}
                        show={true}
                        onConfirm={() => onSaveColumnSelection()}
                        className="company-selection-popup"
                    >
                        <div className="selection-title">Show/Hide Columns</div>
                        {columnSelectionColumns.map((c, i) => renderColumnCheckbox(c.field, c.header, c.visible, i))}
                    </Popup>
                ) : null}

                <div className="grid-bottom">
                    {gridName !== 'ordersHistory' &&
                        <div>
                            {renderTabStrip()}
                        </div>}
                    {gridName === 'ordersHistory'
                        ? <div className="grid-wrapper">
                            {renderGrid()}
                        </div>
                        : <MinMd className="grid-wrapper" style={{'width': '100%'}}>
                            {renderGrid()}
                        </MinMd>}
                    {(gridName !== 'ordersHistory' && showMobileGrid && isPhone && currentTab) && (<MaxSm>
                        <MobileView
                            className="multi-grid-page"
                            style={{marginTop: '0px', zIndex: 1049}}
                            header={renderToolbar()}
                            title={getGridName(currentTab)}
                            onClose={() => setShowMobileGrid(false)}>
                            {renderGrid()}
                        </MobileView>
                    </MaxSm>)}
                </div>
            </MultiGridPage >
        </div>
    );
}

MultiView.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string,
            visible: PropTypes.bool,
            order: PropTypes.number,
        })
    ),
    data: PropTypes.arrayOf(
        PropTypes.shape()
    ),
    gridEntityName: PropTypes.string,
    gridKeys: PropTypes.arrayOf(
        PropTypes.shape({
            fieldName: PropTypes.string,
            id: PropTypes.string,
            tabDisplay: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    headerLink: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
    }),
    gridName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onRenderLegend: PropTypes.func,
    setColumnList: PropTypes.func.isRequired,
    onRenderRow: PropTypes.func,
    onSaveGridPreferences: PropTypes.func,
    loadingData: PropTypes.bool,
    setLoadingData: PropTypes.func,
    onRequesterToggle: PropTypes.func,
    onSelect: PropTypes.func,
    onRefresh: PropTypes.func,
    onUpdate: PropTypes.func,
    selectionMode: PropTypes.oneOf(['single', 'multiple', 'checkbox']),
    subLabel: PropTypes.string,
    disableCardView: PropTypes.bool,
    view: PropTypes.string,
    isMobile: PropTypes.bool,
    isPhone: PropTypes.bool,
    getOems: PropTypes.func,
    oems: PropTypes.arrayOf(
        PropTypes.shape({})),
    filters: PropTypes.arrayOf(PropTypes.shape({})),
    onApply: PropTypes.func,
    showFilters: PropTypes.bool,
    defaultSorting: PropTypes.func,
    onRgaTypeFilterChanged: PropTypes.func,
};

export default MultiView;
