import React from 'react';
import styled from 'styled-components';
import {BrComponent} from '@bloomreach/react-sdk';
import BottomMenu from 'components/BottomMenu';

const StyledFooter = styled.footer`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    bottom: 0;
    background-color: #002c51;
    color: #ffffff;
    z-index: 9;
`;

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 50px 20px 50px;

    @media (max-width: 819px) {
        padding: 20px 25px 20px 25px;
        flex-direction: column;
    }
`;

const Right = styled.div`
    float: right;

    a, a:hover {
        font-size: 14px;
        color: #ffffff;
        text-decoration: underline;
    }

    @media (max-width: 819px) {
        display: flex;
        flex-direction: column;

        a {
            margin-left: 0px;
        }
    }
`;

const Footer = () => {
    return (
        <StyledFooter className="row">
            <Content className="col-md-12">
                <div>© 2001-{new Date().getFullYear()} PartsSource, Inc.</div>
                <Right>
                    <BrComponent path="footer/footer-menu">
                        <BottomMenu />
                    </BrComponent>
                </Right>
            </Content>
        </StyledFooter>
    );
};

export default Footer;
