import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ShippingInfoCardV2 from 'components/ShippingInfoCardV2'

const RepairFooterContainer = styled.div`
    width: 100%;    
    padding-top: 10px;  
    height: 100px;
    background-color: rgb(241, 241, 241);
    margin-left: 0;     
    padding-left: 0; 

    @media (max-width: 818px) {
        
        margin-top: 0;
    } 
`;

const CardContainer = styled(RepairFooterContainer)`
    display: inline-block;
    div {
        position: relative;
        bottom: 0!Important;
    }    

    @media (max-width: 1149px) {
                
        ul {
            margin-top: 0!Important;
        }       
    }  
`;

const ShippingInfoCardDetails = styled(ShippingInfoCardV2)`
    @media (max-width: 1149px) {        
        margin-left: 0;
        margin-right: 0;        
    }
`;

const LoanerFooter = (props) => {
    const {
        loaner = {},
        lineItem = {},
    } = props;

    return (
        <React.Fragment>
            <CardContainer>
                {lineItem.loanerStatus === 'Ordered' &&
                    <ShippingInfoCardDetails showDateInfo={true} showTrackingInfo={true} title="Estimated Ship Date"
                        subText={lineItem.vendorToCustomerShippingAddress}
                        additionalText={lineItem.vendorToCustomerShippingAttention}
                        date={lineItem.vendorToCustomerDateShipped}
                        trackingNumber={lineItem.vendorToCustomerTrackingNumber}
                        trackingText={loaner.shipper}
                        noDateText="Pending"
                        noTimeText="End of Day" />}

                {lineItem.loanerStatus === 'ShippedOutbound' &&
                    <ShippingInfoCardDetails showDateInfo={true} showTrackingInfo={true} title="Shipped"
                        subText={lineItem.vendorToCustomerShippingAddress}
                        additionalText={lineItem.vendorToCustomerShippingAttention}
                        date={lineItem.vendorToCustomerDateShipped}
                        trackingNumber={lineItem.vendorToCustomerTrackingNumber}
                        trackingText={loaner.shipper}
                        noDateText="Pending" />}

                {lineItem.loanerStatus === 'ReceivedInUse' &&
                    <ShippingInfoCardDetails showDateInfo={true} showTrackingInfo={true} title="Delivered"
                        isComplete={true}
                        subText={lineItem.vendorToCustomerShippingAddress}
                        additionalText={lineItem.vendorToCustomershippingAttention}
                        date={lineItem.vendorToCustomerDateDelivered}
                        trackingNumber={lineItem.vendorToCustomerTrackingNumber}
                        trackingText={loaner.shipper}
                        noDateText="On Unknown Date" />}

                {lineItem.loanerStatus === 'ShippedInbound' &&
                    <ShippingInfoCardDetails showDateInfo={true} showTrackingInfo={true} title="Estimated Delivery Date"
                        subText={lineItem.customerToVendorShippingAddress}
                        additionalText={lineItem.customerToVendorShippingAttention}
                        date={lineItem.customerToVendorEstimatedDelivery}
                        trackingNumber={lineItem.customerToVendorTrackingNumber}
                        trackingText={loaner.shipper}
                        noDateText="Pending"
                        noTimeText="End of Day" />}

                {lineItem.loanerStatus === 'Returned' &&
                    <ShippingInfoCardDetails showDateInfo={true} showTrackingInfo={true} title="Delivered"
                        isComplete={true}
                        subText={lineItem.customerToVendorShippingAddress}
                        additionalText={lineItem.customerToVendorShippingAttention}
                        date={lineItem.customerToVendorDateDelivered}
                        trackingNumber={lineItem.customerToVendorTrackingNumber}
                        trackingText={loaner.shipper}
                        noDateText="On Unknown Date" />}

                {lineItem.loanerStatus === 'Inspected' &&
                    <ShippingInfoCardDetails showDateInfo={true} showTrackingInfo={true} title="Delivered"
                        isComplete={true}
                        subText={lineItem.customerToVendorShippingAddress}
                        additionalText={lineItem.customerToVendorShippingAttention}
                        date={lineItem.customerToVendorDateDelivered}
                        trackingNumber={lineItem.customerToVendorTrackingNumber}
                        trackingText={loaner.shipper}
                        noDateText="On Unknown Date" /> }
            </CardContainer>
           
        </React.Fragment>
    );
};

export default LoanerFooter;

LoanerFooter.propTypes = {
    loaner: PropTypes.shape({}),
    lineItem: PropTypes.shape({}),
};
