import React, {useState} from 'react';
import useForm from 'hooks/useForm';
import FormFooter from 'components/FormFooter';
import {TextField, FileUploader, Dropdown, CustomGrid, Popup, semiBoldBrandFont} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import states from 'data/States';
import {validateEmail, validatePhoneNumber} from 'utils/validation';
import {processFiles, convertToBlob} from 'utils/FileHandler';
import AccordionSection from 'components/AccordionSection';
import TradeReferenceDialog from 'components/TradeReferenceDialog';
import {useSelector, useDispatch} from 'react-redux';
import DatePicker from 'components/DatePicker';
import {
    saveGeneralInfo,
    addTradeReferenceToSet,
    deleteTradeReferenceFromSet,
    updateTradeReferenceToSet,
    getAddress,
    isAddressValid,
    AddressType,
} from 'reducers/supplier';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {faTrashAlt} from '@fortawesome/pro-regular-svg-icons';
import {SubTitle} from 'utils/styles';
import axios from 'axios';
import mime from 'mime-types';
import _ from 'lodash';
import {timeZones} from 'utils/TimezoneData';
import AddressSelectionDialog from 'components/AddressSelectionDialog';
import {useHistory} from 'react-router-dom';

const Label = styled.div`
    margin-bottom: 1rem;
    ${semiBoldBrandFont('16px')}
`;

const Wrapper = styled.div`
    h3 {
        margin-top: 3rem;
    }
`;

const RemoveIcon = styled(FontAwesomeIcon)`
    vertical-align: middle;
    margin-left: 8px;
    cursor: pointer;
`;

const Row = styled.div`
   margin-bottom: 2rem;
`;

const Link = styled.a`
    color: rgb(0, 93, 166) !important;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
`;

const CustomTextField = styled(TextField)`
   max-width: 20em;
`;

const CustomDropdown = styled(Dropdown)`
   max-width: 20em;
`;

const ErrorMessage = styled.span`
    height: 20px;
    display: block;
`;

const AddItem = styled.div`
    color: #949494;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 15px 0;
    position: relative;
    top: 10px;
`;

const ActionsColumn = styled.td`
    width: 8%;
    padding: 0 0 0 0.5em !important;
`;

const PencilIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    color: rgb(0, 0, 0);
    width: 14px !important;
    height: 14px;
    margin: 1px;
    margin-right: 7px;
`;

const DeleteIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    color: rgb(0, 0, 0);
    width: 14px !important;
    height: 14px;
    margin: 1px;
`;

function SettingsGeneralInformationForm() {
    const alternateAddress = useSelector(state => state.supplier.alternateAddress);
    const generalInfo = useSelector(state => state.supplier.generalInfo);
    const supplierId = useSelector(state => state.supplier.supplierId);
    const dispatch = useDispatch();
    const history = useHistory();
    const countries = [{value: 'US', text: 'United States'}, {value: 'CA', text: 'Canada'}];
    const [uploadError, setUploadError] = useState('');
    const [uploadHasErrors, setUploadHasErrors] = useState(false);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const isSaving = useSelector(state => state.supplier.isSaving);
    const [showTradeReferenceDialog, setShowTradeReferenceDialog] = useState(false);
    const [tradeRefRowIndex, setTradeRefRowIndex] = useState(null);
    const [showTradeReferenceDeleteDialog, setShowTradeReferenceDeleteDialog] = useState(false);
    const [isTradeReferencesOpen, setIsTradeReferencesOpen] = useState(false);
    const [addressVerificationType, setAddressVerificationType] = useState(AddressType.UNDEFINED);
    const [isSaved, setIsSaved] = useState(false);

    const fields = {
        companyWebsite: {
            required: false,
            validator: {
                func: value => /[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/.test(value),
            },
        },
        DUNSNumber: {
            required: false,
            validator: {
                func: value => /^[0-9]{9}$/.test(value),
            },
        },
        phone: {
            required: true,
            validator: {
                func: value => value.length <= 20 && validatePhoneNumber(value),
            },
        },
        fax: {
            required: false,
            validator: {
                func: value => value.length <= 20 && validatePhoneNumber(value),
            },
        },
        totalNumberEmployees: {
            required: false,
            validator: {
                func: value => /^[1-9]\d*$/.test(value),
            },
        },
        contactPersonName: {
            required: true,
        },
        contactPersonTitle: {
            required: true,
        },
        contactPersonEmail: {
            required: true,
            validator: {
                func: value => validateEmail(value),
            },
        },
        contactPersonPhone: {
            required: true,
            validator: {
                func: value => validatePhoneNumber(value),
            },
        },
        contactForPricingName: {
            required: true,
        },
        contactForPricingTitle: {
            required: true,
        },
        contactForPricingEmail: {
            required: true,
            validator: {
                func: value => validateEmail(value),
            },
        },
        contactForPricingPhone: {
            required: true,
            validator: {
                func: value => validatePhoneNumber(value),
            },
        },
        presidentEmail: {
            required: false,
            validator: {
                func: value => validateEmail(value),
            },
        },
        presidentPhone: {
            required: false,
            validator: {
                func: value => validatePhoneNumber(value),
            },
        },
        qualityManagerEmail: {
            required: false,
            validator: {
                func: value => validateEmail(value),
            },
        },
        qualityManagerPhone: {
            required: false,
            validator: {
                func: value => validatePhoneNumber(value),
            },
        },
        salesManagerEmail: {
            required: false,
            validator: {
                func: value => validateEmail(value),
            },
        },
        salesManagerPhone: {
            required: false,
            validator: {
                func: value => validatePhoneNumber(value),
            },
        },
        physicalAddressStreet: {
            required: true,
            validator: {
                func: value => value.match(/\S+/),
            },
        },
        physicalAddressStreet2: {
            validator: {
                func: value => value.match(/\S+/),
            },
        },
        physicalAddressCity: {
            required: true,
            validator: {
                func: value => /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i.test(value),
            },
        },
        physicalAddressState: {
            required: true,
        },
        physicalAddressZipcode: {
            required: true,
            validator: {
                func: value => /^[0-9]{5}(?:-[0-9]{4})?$/i.test(value),
            },
        },
        physicalAddressCountry: {
            required: true,
        },
        billingAddressStreet: {
            required: true,
            validator: {
                func: value => value.match(/\S+/),
            },
        },
        billingAddressStreet2: {
            validator: {
                func: value => value.match(/\S+/),
            },
        },
        billingAddressCity: {
            required: true,
            validator: {
                func: value => /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i.test(value),
            },
        },
        billingAddressState: {
            required: true,
        },
        billingAddressZipcode: {
            required: true,
            validator: {
                func: value => /^[0-9]{5}(?:-[0-9]{4})?$/i.test(value),
            },
        },
        billingAddressCountry: {
            required: true,
        },
        returnsAddressStreet: {
            required: true,
            validator: {
                func: value => value.match(/\S+/),
            },
        },
        returnsAddressStreet2: {
            validator: {
                func: value => value.match(/\S+/),
            },
        },
        returnsAddressCity: {
            required: true,
            validator: {
                func: value => /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i.test(value),
            },
        },
        returnsAddressState: {
            required: true,
        },
        returnsAddressZipcode: {
            required: true,
            validator: {
                func: value => /^[0-9]{5}(?:-[0-9]{4})?$/i.test(value),
            },
        },
        returnsAddressCountry: {
            required: true,
        },
        hoursOfOpTimeZone: {
            required: true,
        },
        hoursOfOperation: {
            required: true,
        },
    };

    const openOrgChart = () => {
        axios.get(`supplier/${supplierId}/document/${values.orgChartFileName}`).then(x => {
            let file = convertToBlob(x.data.supplierDocument, values.orgChartFileType);

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file);
                return;
            }

            const fileURL = URL.createObjectURL(file);
            const fileExtension = mime.extension(values.orgChartFileType);

            let link = document.createElement('a');
            link.type = 'hidden';
            link.href = fileURL;
            link.download = `${values.orgChartFileName}.${fileExtension}`;
            document.body.appendChild(link);
            link.click();

            setTimeout(function () {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(fileURL);
            }, 100);
        });
    }

    const onCloseAddressVerification = () => {
        setAddressVerificationType(AddressType.UNDEFINED);
    };

    const onConfirmAddressVerification = async (address, addressType) => {
        let updatedAddress = dispatch(getAddress(address, addressType));
        let newValues = _.assign({}, values, updatedAddress);
        setValues(newValues);

        let result = await onSubmitForm(newValues, dirty);

        if (result) {
            setIsSaved(true);
            history.push('/settings');
        }
    }

    const tradeReferencesHeaderColumns = [
        {fieldName: 'company', displayName: 'Company', canSort: true},
        {fieldName: 'name', displayName: 'Name', canSort: true},
        {fieldName: 'phone', displayName: 'Phone', canSort: true},
    ];

    const renderTradeReferencesRow = (item, i) => {
        return (
            <tr key={`tr_${i}`}>
                <td>{item.company}</td>
                <td>{item.name}</td>
                <td>{item.phone}</td>
                <ActionsColumn>
                    <PencilIcon
                        icon="pencil-alt"
                        title="Edit"
                        onClick={(e) => onEditTradeReferenceAction(i)}
                    />
                    <DeleteIcon
                        icon={faTrashAlt}
                        title="Delete"
                        onClick={(e) => onDeleteTradeReferenceAction(i)}
                    />
                </ActionsColumn>
            </tr>
        );
    };

    const listTradeReferencesRows = generalInfo.tradeReferences
        .map((x, i) => renderTradeReferencesRow(x, i));
    let defaultTradeReferencesSortColumn = 'company';
    let defaultTradeReferencesSortDirection = 'asc';

    const onTradeReferencesSort = (sortColumn, sortDirection) => {
        defaultTradeReferencesSortColumn = sortColumn;
        defaultTradeReferencesSortDirection = sortDirection;
    }

    const onAddTradeReferenceAction = () => {
        setTradeRefRowIndex(null);
        setShowTradeReferenceDialog(true)
    };

    const onEditTradeReferenceAction = (rowIndex) => {
        setTradeRefRowIndex(rowIndex);
        setShowTradeReferenceDialog(true);
    }

    const onSaveTradeReference = (tradeReference) => {
        setShowTradeReferenceDialog(false);

        if (tradeRefRowIndex !== null) {
            dispatch(updateTradeReferenceToSet(tradeReference, tradeRefRowIndex));
        } else {
            dispatch(addTradeReferenceToSet(tradeReference));
        }
    }

    const onDeleteTradeReferenceAction = (rowIndex) => {
        setTradeRefRowIndex(rowIndex);
        setShowTradeReferenceDeleteDialog(true);
    }

    const onDeleteTradeReference = (rowIndex) => {
        dispatch(deleteTradeReferenceFromSet(rowIndex));
        setShowTradeReferenceDeleteDialog(false);
    }

    const handleRemove = (e) => {
        e.preventDefault();
        setUploadError('');
        setUploadHasErrors(false);
        setIsFileSelected(false);
        setValue('orgChartFileName', '');
    }

    const handleUpload = async e => {
        e.preventDefault();
        const files = e.target.files;
        const display = e.target
            .value
            .split('/')
            .pop()
            .split('\\')
            .pop();
        const type = files[0].type;

        processFiles(files).then(res => {
            if (res.uploadFile?.name !== '') {
                let fileName = display.substring(0, display.lastIndexOf('.'));
                let base64File = res.uploadFile.file.split(',').pop();

                setValue('orgChartFileName', fileName);
                setValue('orgChartFileData', base64File);
                setValue('orgChartFileType', type);

                setUploadError('');
                setUploadHasErrors(false);
                setIsFileSelected(res.isFileSelected);
            } else {
                setUploadError(res.errorMessage);
                setUploadHasErrors(res.showErrorMessage);
                setIsFileSelected(false);
                setValue('orgChartFileName', '');
                setValue('orgChartFileData', '');
                setValue('orgChartFileType', '');
            }
        });
    }

    const onSubmitForm = async (values, dirtyValues) => {
        const {
            contactPersonName,
            contactPersonTitle,
            contactPersonEmail,
            contactPersonPhone,
            contactForPricingName,
            contactForPricingTitle,
            contactForPricingEmail,
            contactForPricingPhone,
            presidentName,
            presidentTitle,
            presidentEmail,
            presidentPhone,
            qualityManagerName,
            qualityManagerTitle,
            qualityManagerEmail,
            qualityManagerPhone,
            salesManagerName,
            salesManagerTitle,
            salesManagerEmail,
            salesManagerPhone,
            physicalAddressStreet,
            physicalAddressStreet2,
            physicalAddressCity,
            physicalAddressState,
            physicalAddressZipcode,
            physicalAddressCountry,
            returnsAddressStreet,
            returnsAddressStreet2,
            returnsAddressCity,
            returnsAddressState,
            returnsAddressZipcode,
            returnsAddressCountry,
            billingAddressStreet,
            billingAddressStreet2,
            billingAddressCity,
            billingAddressState,
            billingAddressZipcode,
            billingAddressCountry,
        } = dirtyValues;

        let updateStatus = {
            contactPersonUpdated: contactPersonName || contactPersonTitle || contactPersonEmail || contactPersonPhone,
            contactForPricingUpdated: contactForPricingName || contactForPricingTitle || contactForPricingEmail || contactForPricingPhone,
            presidentUpdated: presidentName || presidentTitle || presidentEmail || presidentPhone,
            qualityManagerUpdated: qualityManagerName || qualityManagerTitle || qualityManagerEmail || qualityManagerPhone,
            salesManagerUpdated: salesManagerName || salesManagerTitle || salesManagerEmail || salesManagerPhone,
            physicalAddressUpdated: physicalAddressStreet || physicalAddressStreet2 ||
                physicalAddressCity || physicalAddressState || physicalAddressZipcode || physicalAddressCountry,
            returnsAddressUpdated: returnsAddressStreet || returnsAddressStreet2 ||
                returnsAddressCity || returnsAddressState || returnsAddressZipcode || returnsAddressCountry,
            billingAddressUpdated: billingAddressStreet || billingAddressStreet2 ||
                billingAddressCity || billingAddressState || billingAddressZipcode || billingAddressCountry,
        };

        const {physicalAddressUpdated, billingAddressUpdated, returnsAddressUpdated} = updateStatus;

        if (physicalAddressUpdated && addressVerificationType < AddressType.PHYS) {
            const isPhysicalAddressValid = await dispatch(isAddressValid(AddressType.PHYS, values));

            if (!isPhysicalAddressValid) {
                setAddressVerificationType(AddressType.PHYS);
                return false;
            }
        }

        if (billingAddressUpdated && addressVerificationType < AddressType.BILL) {
            const isBillingAddressValid = await dispatch(isAddressValid(AddressType.BILL, values));

            if (!isBillingAddressValid) {
                setAddressVerificationType(AddressType.BILL);
                return false;
            }
        }

        if (returnsAddressUpdated && addressVerificationType < AddressType.RETURNS) {
            const isReturnsAddressValid = await dispatch(isAddressValid(AddressType.RETURNS, values));

            if (!isReturnsAddressValid) {
                setAddressVerificationType(AddressType.RETURNS);
                return false;
            }
        }

        setAddressVerificationType(AddressType.UNDEFINED);
        await dispatch(saveGeneralInfo(values, updateStatus));
        return true;
    }

    const {
        values,
        errors,
        dirty,
        handleOnChange,
        handleOnSubmit,
        disable,
        setValue,
        setValues,
    } = useForm(generalInfo, fields, onSubmitForm);

    const companyInfoErrors = [
        errors.companyWebsite,
        errors.DUNSNumber,
        errors.phone,
        errors.fax,
    ];

    const companyOrgErrors = [
        errors.totalNumberEmployees,
        errors.contactPersonName,
        errors.contactPersonTitle,
        errors.contactPersonEmail,
        errors.contactPersonPhone,
        errors.contactForPricingName,
        errors.contactForPricingTitle,
        errors.contactForPricingEmail,
        errors.contactForPricingPhone,
        errors.presidentEmail,
        errors.presidentPhone,
        errors.qualityManagerEmail,
        errors.qualityManagerPhone,
        errors.salesManagerEmail,
        errors.salesManagerPhone,
    ];

    const physicalAddressErrors = [
        errors.physicalAddressStreet,
        errors.physicalAddressCity,
        errors.physicalAddressState,
        errors.physicalAddressZipcode,
        errors.physicalAddressCountry,
    ];

    const billingAddressErrors = [
        errors.billingAddressStreet,
        errors.billingAddressCity,
        errors.billingAddressState,
        errors.billingAddressZipcode,
        errors.billingAddressCountry,
    ];

    const returnsAddressErrors = [
        errors.returnsAddressStreet,
        errors.returnsAddressCity,
        errors.returnsAddressState,
        errors.returnsAddressZipcode,
        errors.returnsAddressCountry,
    ];

    const hoursOfOperationErrors = [
        errors.hoursOfOpTimeZone,
        errors.hoursOfOperation,
    ]

    const {
        companyWebsite,
        DUNSNumber,
        phone,
        fax,
        totalNumberEmployees,
        contactPersonName,
        contactPersonTitle,
        contactPersonEmail,
        contactPersonPhone,
        contactForPricingName,
        contactForPricingTitle,
        contactForPricingEmail,
        contactForPricingPhone,
        presidentName,
        presidentTitle,
        presidentEmail,
        presidentPhone,
        qualityManagerName,
        qualityManagerTitle,
        qualityManagerEmail,
        qualityManagerPhone,
        salesManagerName,
        salesManagerTitle,
        salesManagerEmail,
        salesManagerPhone,
        coreBusiness,
        businessPreviousNames,
        businessDateEstablished,
        businessParentCompany,
        businessSubsidiary,
        physicalAddressStreet,
        physicalAddressStreet2,
        physicalAddressCity,
        physicalAddressState,
        physicalAddressZipcode,
        physicalAddressCountry,
        returnsAddressStreet,
        returnsAddressStreet2,
        returnsAddressCity,
        returnsAddressState,
        returnsAddressZipcode,
        returnsAddressCountry,
        billingAddressStreet,
        billingAddressStreet2,
        billingAddressCity,
        billingAddressState,
        billingAddressZipcode,
        billingAddressCountry,
        hoursOfOpTimeZone,
        hoursOfOperation,
    } = values;

    const physicalAddress = {
        street1: physicalAddressStreet,
        street2: physicalAddressStreet2,
        city: physicalAddressCity,
        state: physicalAddressState,
        postalCode: physicalAddressZipcode,
        country: physicalAddressCountry,
    };

    const billingAddress = {
        street1: billingAddressStreet,
        street2: billingAddressStreet2,
        city: billingAddressCity,
        state: billingAddressState,
        postalCode: billingAddressZipcode,
        country: billingAddressCountry,
    };

    const returnsAddress = {
        street1: returnsAddressStreet,
        street2: returnsAddressStreet2,
        city: returnsAddressCity,
        state: returnsAddressState,
        postalCode: returnsAddressZipcode,
        country: returnsAddressCountry,
    };

    return (
        <Wrapper>
            <AccordionSection title="Company Information" errors={companyInfoErrors}>
                <Row>
                    <CustomTextField
                        label={'Company Website'}
                        text={companyWebsite}
                        name={'companyWebsite'}
                        onChange={handleOnChange}
                        showErrorMessage={errors.companyWebsite}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'DUNS#'}
                        text={DUNSNumber}
                        name={'DUNSNumber'}
                        onChange={handleOnChange}
                        maxLength={9}
                        showErrorMessage={errors.DUNSNumber}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Phone'}
                        id={'phone'}
                        text={phone}
                        name={'phone'}
                        onChange={handleOnChange}
                        showErrorMessage={errors.phone}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Fax'}
                        text={fax}
                        name={'fax'}
                        onChange={handleOnChange}
                        showErrorMessage={errors.fax}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Core Business'}
                        text={coreBusiness}
                        name={'coreBusiness'}
                        maxLength={250}
                        onChange={handleOnChange}
                        showErrorMessage={errors.coreBusiness}
                    />
                </Row>
                <Row>
                    <DatePicker
                        appendTo={document.body}
                        placeholder="Date Established"
                        onChange={handleOnChange}
                        name={'businessDateEstablished'}
                        showIcon={true}
                        value={businessDateEstablished}
                        style={{width: '20em', height: '50px'}}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Previous Names'}
                        text={businessPreviousNames}
                        name={'businessPreviousNames'}
                        key={'businessPreviousNames'}
                        maxLength={250}
                        onChange={handleOnChange}
                        showErrorMessage={errors.businessPreviousNames}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Parent Company'}
                        text={businessParentCompany}
                        name={'businessParentCompany'}
                        maxLength={250}
                        onChange={handleOnChange}
                        showErrorMessage={errors.businessParentCompany}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Subsidiary'}
                        text={businessSubsidiary}
                        name={'businessSubsidiary'}
                        maxLength={250}
                        onChange={handleOnChange}
                        showErrorMessage={errors.businessSubsidiary}
                    />
                </Row>
            </AccordionSection>

            <AccordionSection title="Company Organization" errors={companyOrgErrors}>
                <SubTitle>Maintaining up to date personnel information ensures proper points of contact are established</SubTitle>
                <Label>Company Organization Chart</Label>
                <Row>
                    <div>
                        {/* TODO: check how to bind it to the company settings - not bind it and send on submit the data? */}
                        <FileUploader
                            label="CHOOSE FILE"
                            name="orgChartFileName"
                            infoCenter={false}
                            photosTab={false}
                            isFileSelected={isFileSelected}
                            fileDisplay={values.orgChartFileName}
                            imagePreviewUrl=""
                            acceptFiles="*"
                            handleRemove={handleRemove}
                            onChange={handleUpload}
                        />
                        {values.orgChartFileName &&
                            <span style={{display: 'block', marginTop: '5px'}}>
                                {!dirty.orgChartFileName ? <Link onClick={openOrgChart}>{values.orgChartFileName}</Link> : values.orgChartFileName}
                                <RemoveIcon icon={faTimesCircle} title="Remove file" onClick={handleRemove} />
                            </span>}
                        {uploadHasErrors && <ErrorMessage>{uploadError}</ErrorMessage>}
                    </div>
                </Row>
                <Row>
                    <CustomTextField
                        label={'Total Number of Employees'}
                        text={totalNumberEmployees}
                        name={'totalNumberEmployees'}
                        onChange={handleOnChange}
                        showErrorMessage={errors.totalNumberEmployees}
                    />
                </Row>
                <h3>Contact Person</h3>
                <Row>
                    <CustomTextField
                        label={'Contact Name'}
                        id={'contactPersonName'}
                        text={contactPersonName}
                        name={'contactPersonName'}
                        maxLength={75}
                        onChange={handleOnChange}
                        showErrorMessage={errors.contactPersonName}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Title'}
                        id={'contactPersonTitle'}
                        text={contactPersonTitle}
                        name={'contactPersonTitle'}
                        maxLength={18}
                        onChange={handleOnChange}
                        showErrorMessage={errors.contactPersonTitle}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Email'}
                        id={'contactPersonEmail'}
                        text={contactPersonEmail}
                        name={'contactPersonEmail'}
                        maxLength={75}
                        onChange={handleOnChange}
                        showErrorMessage={errors.contactPersonEmail}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Phone'}
                        id={'contactPersonPhone'}
                        text={contactPersonPhone}
                        name={'contactPersonPhone'}
                        maxLength={20}
                        onChange={handleOnChange}
                        showErrorMessage={errors.contactPersonPhone}
                    />
                </Row>
                <h3>Contact for Pricing/Order Status</h3>
                <Row>
                    <CustomTextField
                        label={'Contact Name'}
                        id={'contactForPricingName'}
                        text={contactForPricingName}
                        name={'contactForPricingName'}
                        maxLength={75}
                        onChange={handleOnChange}
                        showErrorMessage={errors.contactForPricingName}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Title'}
                        id={'contactForPricingTitle'}
                        text={contactForPricingTitle}
                        name={'contactForPricingTitle'}
                        maxLength={18}
                        onChange={handleOnChange}
                        showErrorMessage={errors.contactForPricingTitle}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Email'}
                        id={'contactForPricingEmail'}
                        text={contactForPricingEmail}
                        name={'contactForPricingEmail'}
                        maxLength={75}
                        onChange={handleOnChange}
                        showErrorMessage={errors.contactForPricingEmail}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Phone'}
                        id={'contactForPricingPhone'}
                        text={contactForPricingPhone}
                        name={'contactForPricingPhone'}
                        maxLength={20}
                        onChange={handleOnChange}
                        showErrorMessage={errors.contactForPricingPhone}
                    />
                </Row>
                <h3>President/GM</h3>
                <Row>
                    <CustomTextField
                        label={'Contact Name'}
                        id={'presidentName'}
                        text={presidentName}
                        name={'presidentName'}
                        maxLength={75}
                        onChange={handleOnChange}
                        showErrorMessage={errors.presidentName}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Title'}
                        id={'presidentTitle'}
                        text={presidentTitle}
                        name={'presidentTitle'}
                        maxLength={18}
                        onChange={handleOnChange}
                        showErrorMessage={errors.presidentTitle}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Email'}
                        id={'presidentEmail'}
                        text={presidentEmail}
                        name={'presidentEmail'}
                        maxLength={75}
                        onChange={handleOnChange}
                        showErrorMessage={errors.presidentEmail}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Phone'}
                        id={'presidentPhone'}
                        text={presidentPhone}
                        name={'presidentPhone'}
                        maxLength={20}
                        onChange={handleOnChange}
                        showErrorMessage={errors.presidentPhone}
                    />
                </Row>
                <h3>Quality Manager</h3>
                <Row>
                    <CustomTextField
                        label={'Contact Name'}
                        id={'qualityManagerName'}
                        text={qualityManagerName}
                        name={'qualityManagerName'}
                        maxLength={75}
                        onChange={handleOnChange}
                        showErrorMessage={errors.qualityManagerName}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Title'}
                        id={'qualityManagerTitle'}
                        text={qualityManagerTitle}
                        name={'qualityManagerTitle'}
                        maxLength={18}
                        onChange={handleOnChange}
                        showErrorMessage={errors.qualityManagerTitle}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Email'}
                        id={'qualityManagerEmail'}
                        text={qualityManagerEmail}
                        name={'qualityManagerEmail'}
                        maxLength={75}
                        onChange={handleOnChange}
                        showErrorMessage={errors.qualityManagerEmail}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Phone'}
                        id={'qualityManagerPhone'}
                        text={qualityManagerPhone}
                        name={'qualityManagerPhone'}
                        maxLength={20}
                        onChange={handleOnChange}
                        showErrorMessage={errors.qualityManagerPhone}
                    />
                </Row>
                <h3>Sales Manager</h3>
                <Row>
                    <CustomTextField
                        label={'Contact Name'}
                        id={'salesManagerName'}
                        text={salesManagerName}
                        name={'salesManagerName'}
                        maxLength={75}
                        onChange={handleOnChange}
                        showErrorMessage={errors.salesManagerName}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Title'}
                        id={'salesManagerTitle'}
                        text={salesManagerTitle}
                        name={'salesManagerTitle'}
                        maxLength={18}
                        onChange={handleOnChange}
                        showErrorMessage={errors.salesManagerTitle}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Email'}
                        id={'salesManagerEmail'}
                        text={salesManagerEmail}
                        name={'salesManagerEmail'}
                        maxLength={75}
                        onChange={handleOnChange}
                        showErrorMessage={errors.salesManagerEmail}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Contact Phone'}
                        id={'salesManagerPhone'}
                        text={salesManagerPhone}
                        name={'salesManagerPhone'}
                        maxLength={20}
                        onChange={handleOnChange}
                        showErrorMessage={errors.salesManagerPhone}
                    />
                </Row>
            </AccordionSection>

            <AccordionSection title="Hours of Operation" errors={hoursOfOperationErrors}>
                <SubTitle>
                    Please enter your standard hours of operation, for example &ldquo;Mon - Fri from 8am - 5pm&ldquo;
                </SubTitle>
                <Row>
                    <CustomTextField
                        name="hoursOfOperation"
                        text={hoursOfOperation}
                        onChange={handleOnChange}
                        label="Hours of Operation"
                        maxLength={60}
                        showErrorMessage={errors.hoursOfOperation}
                    />
                </Row>
                <Row>
                    <CustomDropdown
                        name="hoursOfOpTimeZone"
                        label="Time Zone"
                        onChange={handleOnChange}
                        options={timeZones}
                        selectedValue={hoursOfOpTimeZone}
                        suppressBlankOption={false}
                        valueField="zoneId"
                        textField="zoneName"
                        showErrorMessage={errors.hoursOfOpTimeZone}
                    />
                </Row>
            </AccordionSection>

            <AccordionSection title="Physical Address" errors={physicalAddressErrors}>
                <Row>
                    <CustomTextField
                        label={'Street Address 1'}
                        text={physicalAddressStreet}
                        name={'physicalAddressStreet'}
                        onChange={handleOnChange}
                        limit={50}
                        showErrorMessage={errors.physicalAddressStreet}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Street Address 2'}
                        text={physicalAddressStreet2}
                        name={'physicalAddressStreet2'}
                        onChange={handleOnChange}
                        limit={50}
                        showErrorMessage={errors.physicalAddressStreet2}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'City'}
                        id={'physicalAddressCity'}
                        text={physicalAddressCity}
                        name={'physicalAddressCity'}
                        onChange={handleOnChange}
                        limit={50}
                        showErrorMessage={errors.physicalAddressCity}
                    />
                </Row>
                <Row>
                    <CustomDropdown
                        id="physicalAddressState"
                        selectedValue={physicalAddressState}
                        name="physicalAddressState"
                        label={'State'}
                        valueField="value"
                        textField="text"
                        required={true}
                        options={states.US}
                        onChange={handleOnChange}
                        showErrorMessage={errors.physicalAddressState}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Zipcode'}
                        id={'physicalAddressZipcode'}
                        text={physicalAddressZipcode}
                        name={'physicalAddressZipcode'}
                        onChange={handleOnChange}
                        showErrorMessage={errors.physicalAddressZipcode}
                    />
                </Row>
                <Row>
                    <CustomDropdown
                        id="physicalAddressCountry"
                        selectedValue={physicalAddressCountry}
                        name="physicalAddressCountry"
                        label={'Country'}
                        valueField="value"
                        textField="text"
                        required={true}
                        options={countries}
                        onChange={handleOnChange}
                        showErrorMessage={errors.physicalAddressCountry}
                    />
                </Row>
            </AccordionSection>

            <AccordionSection title="Billing Address" errors={billingAddressErrors}>
                <Row>
                    <CustomTextField
                        label={'Street Address 1'}
                        id={'billingAddressStreet'}
                        text={billingAddressStreet}
                        name={'billingAddressStreet'}
                        onChange={handleOnChange}
                        limit={50}
                        showErrorMessage={errors.billingAddressStreet}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Street Address 2'}
                        id={'billingAddressStreet2'}
                        text={billingAddressStreet2}
                        name={'billingAddressStreet2'}
                        onChange={handleOnChange}
                        limit={50}
                        showErrorMessage={errors.billingAddressStreet2}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'City'}
                        id={'billingAddressCity'}
                        text={billingAddressCity}
                        name={'billingAddressCity'}
                        onChange={handleOnChange}
                        limit={50}
                        showErrorMessage={errors.billingAddressCity}
                    />
                </Row>
                <Row>
                    <CustomDropdown
                        id="billingAddressState"
                        selectedValue={billingAddressState}
                        name="billingAddressState"
                        label={'State'}
                        valueField="value"
                        textField="text"
                        required={true}
                        options={states.US}
                        onChange={handleOnChange}
                        showErrorMessage={errors.billingAddressState}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Zipcode'}
                        id={'billingAddressZipcode'}
                        text={billingAddressZipcode}
                        name={'billingAddressZipcode'}
                        onChange={handleOnChange}
                        showErrorMessage={errors.billingAddressZipcode}
                    />
                </Row>
                <Row>
                    <CustomDropdown
                        id="billingAddressCountry"
                        selectedValue={billingAddressCountry}
                        name="billingAddressCountry"
                        label={'Country'}
                        valueField="value"
                        textField="text"
                        required={true}
                        options={countries}
                        onChange={handleOnChange}
                        showErrorMessage={errors.billingAddressCountry}
                    />
                </Row>
            </AccordionSection>

            <AccordionSection title="Returns Address" errors={returnsAddressErrors}>
                <Row>
                    <CustomTextField
                        label={'Street Address 1'}
                        id={'returnsAddressStreet'}
                        text={returnsAddressStreet}
                        name={'returnsAddressStreet'}
                        onChange={handleOnChange}
                        limit={50}
                        showErrorMessage={errors.returnsAddressStreet}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Street Address 2'}
                        id={'returnsAddressStreet2'}
                        text={returnsAddressStreet2}
                        name={'returnsAddressStreet2'}
                        onChange={handleOnChange}
                        limit={50}
                        showErrorMessage={errors.returnsAddressStreet2}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'City'}
                        id={'returnsAddressCity'}
                        text={returnsAddressCity}
                        name={'returnsAddressCity'}
                        onChange={handleOnChange}
                        limit={50}
                        showErrorMessage={errors.returnsAddressCity}
                    />
                </Row>
                <Row>
                    <CustomDropdown
                        id="returnsAddressState"
                        selectedValue={returnsAddressState}
                        name="returnsAddressState"
                        label={'State'}
                        valueField="value"
                        textField="text"
                        required={true}
                        options={states.US}
                        onChange={handleOnChange}
                        showErrorMessage={errors.returnsAddressState}
                    />
                </Row>
                <Row>
                    <CustomTextField
                        label={'Zipcode'}
                        id={'returnsAddressZipcode'}
                        text={returnsAddressZipcode}
                        name={'returnsAddressZipcode'}
                        onChange={handleOnChange}
                        showErrorMessage={errors.returnsAddressZipcode}
                    />
                </Row>
                <Row>
                    <CustomDropdown
                        id="returnsAddressCountry"
                        selectedValue={returnsAddressCountry}
                        name="returnsAddressCountry"
                        label={'Country'}
                        valueField="value"
                        textField="text"
                        required={true}
                        options={countries}
                        onChange={handleOnChange}
                        showErrorMessage={errors.returnsAddressCountry}
                    />
                </Row>
            </AccordionSection>

            <AccordionSection
                title="Trade References"
                key={generalInfo.tradeReferences.length}
                open={isTradeReferencesOpen}
                onClick={() => setIsTradeReferencesOpen(!isTradeReferencesOpen)}>

                <SubTitle>Providing sources with whom you have past payment experience helps to establish a strong relationship
                    with PartsSource</SubTitle>

                <AddItem onClick={() => onAddTradeReferenceAction()}>+ ADD TRADE REFERENCE</AddItem>
                <CustomGrid
                    headerColumns={tradeReferencesHeaderColumns}
                    totalRecords={generalInfo.tradeReferences.length}
                    showPagination={false}
                    showSearch={false}
                    defaultSortColumn={defaultTradeReferencesSortColumn}
                    defaultSortDirection={defaultTradeReferencesSortDirection}
                    onSort={(data) => onTradeReferencesSort(data.sortColumn, data.sortDirection)}
                    defaultPageSize={100}
                    tableClassName="group-table">
                    {listTradeReferencesRows}
                </CustomGrid>
            </AccordionSection>

            {addressVerificationType !== AddressType.UNDEFINED && <AddressSelectionDialog
                key={addressVerificationType}
                originalAddress={
                    addressVerificationType === AddressType.PHYS ?
                        physicalAddress : addressVerificationType === AddressType.BILL ?
                            billingAddress : returnsAddress
                }
                alternateAddress={alternateAddress}
                type={addressVerificationType}
                onConfirm={onConfirmAddressVerification}
                onClose={onCloseAddressVerification}
            />}

            {showTradeReferenceDialog && <TradeReferenceDialog
                key={tradeRefRowIndex}
                handleOnSave={(e) => onSaveTradeReference(e)}
                show={showTradeReferenceDialog}
                onCancel={() => setShowTradeReferenceDialog(false)}
                tradeReference={tradeRefRowIndex !== null ? _.merge({}, generalInfo.tradeReferences[tradeRefRowIndex]) : {}}
            />}

            {showTradeReferenceDeleteDialog && <Popup
                confirmText="Yes"
                cancelText="No"
                show={showTradeReferenceDeleteDialog}
                onConfirm={() => onDeleteTradeReference(tradeRefRowIndex)}
                onCancel={() => setShowTradeReferenceDeleteDialog(false)}
            >
                <div>
                    <div>Are you sure you want to delete this trade reference?</div>
                </div>
            </Popup>}

            <FormFooter
                dirty={!isSaved ? dirty : {}}
                handleOnSubmit={handleOnSubmit}
                disabled={disable}
                loading={isSaving}
            />
        </Wrapper>
    );
}

export default SettingsGeneralInformationForm;
