import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import {useSelector} from 'react-redux';
import {Popup, brandFont, Button, TextField, Dropdown, Checkbox, theme} from '@partssourceinc/react-ui-core';
import ErrorModal from 'components/ErrorModal';
import styled from 'styled-components';
import {validateWholeNumbers, validateMoney} from 'utils/validation';

const CustomTextField = styled(TextField)`
   max-width: 20em;   
`;

const Row = styled.div`
    margin-bottom: 1rem;
`;

const FlexRow = styled.div`
    margin-bottom: 1rem;

    @media (max-width: 400px) {
        display: flex;
        flex-direction: column-reverse;

        div:nth-child(2) {
            position: relative;
            right: 0;

            div {
                padding-left: 0;
                margin-top: 0;
                margin-left: 0;
                margin-bottom: 5px;
            }
        }
    }
`;

const ButtonContainer = styled(Row)`
    width: 320px;        
    margin-left: auto;
    margin-right: auto;    
`;

const CancelLink = styled.a`
    float: right;
    margin-top: 15px;
    &&& {
        text-transform: uppercase;
        cursor: pointer;
        color: rgb(119, 119, 119);
        font-weight: bold;
        margin-right: 1em;

        &:hover {
            text-decoration: underline;
            outline: none;
        }

        &:focus {
            text-decoration: underline;
            outline: none;
        }

        &:active:focus {
            text-decoration: underline;
            outline: none;
        }
    }
`;

const BlueText = styled.div`
    color: #166BAD;
    padding-left: 0;
    margin-top: 15px;
    font-weight: bold;
    padding-left: 10px;
    cursor: pointer;
    font-size: 14px;
    float: left;
    margin-left: 5px;
`;

const TextBox = styled(TextField)`
    
    div {
        min-height: 110px;
        max-width: 40em;
    }
`;

const Container = styled.div`
    width: 100%;    
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const Subtitle = styled.div`
    font-size: 16px;
    float: left;
`;

const Guide = styled.div`
    font-size: 12px;
    float: left;
    color: #777777;
    margin-bottom: 10px;
`;

const BoldSubtitle = styled(Subtitle)`
    font-weight: bold;
    padding-left: 10px;
    cursor: pointer;
    color: #454545;
    margin-left: 4px;
`;

const Title = styled.h1`
    margin-bottom: 16px;
    font-size: 18px;
`;

const PopupContent = styled.p`
    ${brandFont('14px')};    
`;

const HeaderContainer = styled(Container)`
    width: 320px;        
    margin-left: auto;
    margin-right: auto;
     
    @media (max-width: ${theme.screenXsMax}) {
        padding-left: 20px;
    }
`;

const DropdownList = styled(Dropdown)`
    width: 20em;    

    div {
        span {
            font-weight: 700;
            font-size: 12px;
            color: #005DA6;
            margin: 4px 10px 4px 10px;
        }
    }
`;

const CosmeticDropdownWrapper = styled.div`
    float: left;
`;

const CosmeticGuideDropdownWrapper = styled(CosmeticDropdownWrapper)`
    position: absolute;
    right: -35px;
`;

const BidCondition = (props) => {

    const {
        condition = {},
        onAddCondition,
        onContinue,
        conditionCodes,
        conditionExists,
        canAddMore,
    } = props;

    const listData = useSelector(state => state.bids.listData);
    const [currentBidCondition, setCurrentBidCondition] = useState(condition);
    const [isValid, setIsValid] = useState(true);
    const [validMinOrderQty, setValidMinOrderQty] = useState(true);
    const [errors, setErrors] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const validCosmeticConditions = ['2', '3', '4', '38'];
    const invalidMeasureIds = ['8', '1', '9', '12'];
   
    useEffect(() => {
        if (condition?.isNew) {
            setErrors([]);
        } else {
            validateConditions(condition)
        }
        setCurrentBidCondition(condition);
    }, [condition]);

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (validateConditions(currentBidCondition)) {
            if (currentBidCondition.leadDays === 0)
                currentBidCondition.leadDays = null;
            if (currentBidCondition.weight === 0)
                currentBidCondition.weight = null;
            onContinue(currentBidCondition);
        }
    };

    const showCosmeticPopup = () => {
        setPopupTitle('Cosmetic Rating Guide');
        setPopupMessage('');
        setShowPopup(true);
    }

    const onClose = () => {
        setShowPopup(false);
    }

    const onPropertyChange = ({target: {name, value}}) => {
        let newCondition = _.clone(currentBidCondition);
        newCondition[name] = value;

        if (name === 'unitOfMeasureId') {
            switch (value) {
                case '1':
                    newCondition.qtyPerUnit = 1;
                    break;
                case '12':
                    newCondition.qtyPerUnit = 2;
                    break;
                case '8':
                    newCondition.qtyPerUnit = 0;
                    break;
                case '9':
                    newCondition.qtyPerUnit = 0;
                    break;
                default: 
                    newCondition.qtyPerUnit = '';
                    break;
            }
        }

        setCurrentBidCondition(newCondition);   
    }

    const onCheckBoxChange = (e) => {

        let newCondition = _.clone(currentBidCondition);
        newCondition[e.name] = e.checked;
        setCurrentBidCondition(newCondition);
    }

    function validateConditions(currentCondition) {
        let conditionsValid = true;
        let currentErrors = [...errors];

        currentErrors.forEach((item, index) => {
            currentErrors[index] = {};
        });

        if (Number(currentCondition.outrightCost) === '' ||
            Number(currentCondition.outrightCost) === 0 ||
            !validateMoney(currentCondition.outrightCost)) {
            conditionsValid = false;
            currentErrors.outrightCost = 'Invalid outright price';
        }

        if (!validateMoney(currentCondition.exchangeCost)) {
            conditionsValid = false;
            currentErrors.exchangeCost = 'Invalid exchange price';
        }

        if (Number(currentCondition.exchangeCost) > 0 &&
            Number(currentCondition.exchangeCost) > Number(currentCondition.outrightCost)) {
            conditionsValid = false;
            currentErrors.exchangeCost = 'Exchange price must be less than outright price';
        }

        if (isNaN(currentCondition.weight) || Number(currentCondition.weight) === '' ||
            Number(currentCondition.weight) === 0) {
            conditionsValid = false;
            currentErrors.weight = 'Invalid weight';
        }

        if (!validateWholeNumbers(currentCondition.warrantyId)) {
            conditionsValid = false;
            currentErrors.warrantyId = 'Invalid warranty';
        }

        if (currentCondition.hazmatTypeId === '') {
            conditionsValid = false;
            currentErrors.hazmatTypeId = 'Invalid Hazmat';
        }

        if (!validateWholeNumbers(currentCondition.leadDays, true)) {
            conditionsValid = false;
            currentErrors.leadDays = 'Invalid Lead Days';
        }

        if (currentCondition.leadDays < 0 || currentCondition.leadDays > 365) {
            conditionsValid = false;
            currentErrors.leadDays = 'Lead Days must be between 0 and 365';
        }

        if (currentCondition.conditionId === '') {
            conditionsValid = false;
            currentErrors.conditionId = 'Invalid condition type';
        } else {
            if (conditionExists(currentCondition.conditionId)) {
                conditionsValid = false;
                currentErrors.conditionId = 'Duplicated condition type';
                setPopupTitle('Warning');
                setPopupMessage('You may only submit one bid per condition type.');
                setShowErrorPopup(true);
            }
        }
        if (validCosmeticConditions.indexOf(currentCondition.conditionId) > -1 &&
            currentCondition.cosmeticRating === '') {
            conditionsValid = false;
            currentErrors.cosmeticRating = 'Invalid cosmetic rating';
        }
        const measureIdValidationRule = (!currentCondition.qtyPerUnit) || !(Number(currentCondition.qtyPerUnit) > 0);

        if (['2', '10'].includes(currentCondition.unitOfMeasureId)) {
            if (measureIdValidationRule) {
                conditionsValid = false;
                currentErrors.qtyPerUnit = 'Invalid Qty. Per Unit';
            }
        }

        if (['3', '4', '7'].includes(currentCondition.unitOfMeasureId)) {
            if (!isNaN(currentCondition.qtyPerUnit) && parseInt(currentCondition.qtyPerUnit) === 0) {
                conditionsValid = false;
                currentErrors.qtyPerUnit = 'Invalid Qty. Per Unit';
            }
        }

        if (!isMinimumOrderQuantityValid(currentCondition.minimumOrderQuantity)) {
            conditionsValid = false;
            setValidMinOrderQty(false);
        }

        setIsValid(conditionsValid);
        setErrors(currentErrors);

        return conditionsValid;
    }

    const hideErrorPopup = () => {
        setShowErrorPopup(false);
    }

    const handleOnCurrencyKeyDown = (event) => {

        const keyCode = event.keyCode;
        const value = event.target.value;
        const allowedKeyCodes = [8, 9, 37, 39, 46, 110, 188, 190]

        const isDecimal = (event.key.toString().match(/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/) ? true : false);
        const isMatch = (value.match(/(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*))?(\.[0-9]{1,2})?$/) ? true : false);

        const isDollarSign = keyCode === 52;
        const alreadyHasDollar = value.includes('$');
        const lastCharWasDollar = value[0] !== '$' && value[value.length - 1] === '$';
        const lastCharWasPointOrComma = value[value.length - 1] === '.' || value[value.length - 1] === ',';

        if (!isDecimal &&
            ((isDollarSign && alreadyHasDollar) || (isDollarSign && lastCharWasPointOrComma) ||
                (!isDollarSign && !allowedKeyCodes.includes(keyCode))) ||
            (isDecimal && lastCharWasDollar)) {
            event.preventDefault();
        }

        return allowedKeyCodes.includes(keyCode) ? true : (isNaN(Number(event.key)) || isMatch);
    }

    const handleOnWeightKeyDown = (event) => {

        const keyCode = event.keyCode;
        const allowedKeyCodes = [8, 9, 37, 39, 46];
        const notAllowedKeyCodes = [107, 108, 109, 111, 187, 188, 189];

        if (notAllowedKeyCodes.includes(keyCode)) {
            event.preventDefault();
        }

        return allowedKeyCodes.includes(keyCode) ? true : (isNaN(Number(event.key)));
    }

    const handleOnLeadDaysKeyDown = event => {
        const keyCode = event.keyCode;
        const allowedKeyCodes = [8, 9, 37, 39, 46];
        if ((keyCode >= 107 && keyCode <= 111) || (keyCode >= 187 && keyCode <= 190)) {
            event.preventDefault();
        }

        return allowedKeyCodes.includes(keyCode) ? true : (isNaN(Number(event.key)));
    }

    const addBidCondition = () => {

        if (validateConditions(currentBidCondition)) {
            onAddCondition(currentBidCondition);
            setCurrentBidCondition(null);
        }
    }

    const handleMinumumOrderQUantity = (e) => {
        const {name, value} = e.target;
        let currentCondition = _.clone(currentBidCondition);

        if (!isMinimumOrderQuantityValid(value)) {
            setValidMinOrderQty(false)
        } else {
            setValidMinOrderQty(true);
        }

        currentCondition[name] = value;
        setCurrentBidCondition(currentCondition);
    }

    const isMinimumOrderQuantityValid = (value) => {
        if (value !== '' && value !== null) {
            if (Number(value) < 1 || !validateWholeNumbers(value))
                return false;
        }

        return true;
    }

    const renderBidCondition = () => {
        return (
            <React.Fragment>
                <HeaderContainer>
                    <Row>
                        <DropdownList label="Condition Type" name={'conditionId'}
                            options={conditionCodes} selectedValue={currentBidCondition?.conditionId}
                            showErrorMessage={!isValid && errors.conditionId !== undefined}
                            errorMessage={errors.conditionId}
                            suppressBlankOption={false} valueField="id" textField="description"
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>
                    <Row>
                        <CustomTextField
                            label={'Replacement Item # (optional)'}
                            text={currentBidCondition?.replacemantPartNumber}
                            name={'replacemantPartNumber'}
                            onBlur={(e) => onPropertyChange(e)}
                        />
                    </Row>
                    <Row>
                        <DropdownList label="Hazmat" 
                            options={listData?.hazmatType} selectedValue={currentBidCondition?.hazmatTypeId}
                            showErrorMessage={!isValid && errors.hazmatTypeId !== undefined}
                            errorMessage={errors.hazmatTypeId}
                            name={'hazmatTypeId'}
                            suppressBlankOption={false} valueField="id" textField="description"
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>
                    <Row>
                        <CustomTextField
                            label={'Outright Price'}
                            type="text"
                            min="0.01"
                            text={currentBidCondition?.outrightCost}
                            showErrorMessage={!isValid && errors.outrightCost !== undefined}
                            errorMessage={errors.outrightCost}
                            name={'outrightCost'}
                            onKeyDown={handleOnCurrencyKeyDown}
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>
                    <Row>
                        <CustomTextField
                            label={'Exchange Price (optional)'}
                            type="text"
                            min="0.01"
                            text={currentBidCondition?.exchangeCost}
                            name={'exchangeCost'}
                            showErrorMessage={!isValid && errors.exchangeCost !== undefined}
                            errorMessage={errors.exchangeCost}
                            onKeyDown={handleOnCurrencyKeyDown}
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>
                    <Row>
                        <DropdownList label="Unit of Measure"
                            options={listData?.unitOfMeasures || []} selectedValue={currentBidCondition?.unitOfMeasureId || '1'}
                            showErrorMessage={!isValid && errors.unitOfMeasureId !== undefined}
                            errorMessage={errors.unitOfMeasureId}
                            name={'unitOfMeasureId'}
                            suppressBlankOption={true} valueField="id" textField="description"
                            onChange={(e) => onPropertyChange((e))}
                        />

                    </Row>   
                    <Row>
                        <CustomTextField
                            label={'Qty. Per Unit'}
                            text={currentBidCondition?.qtyPerUnit}
                            name={'qtyPerUnit'}
                            disabled={invalidMeasureIds.indexOf(currentBidCondition?.unitOfMeasureId) > -1}
                            showErrorMessage={!isValid && errors.qtyPerUnit !== undefined}
                            errorMessage={errors.qtyPerUnit}
                            onChange={(e) => onPropertyChange(e)}
                            min="0"
                            type="number"
                            onKeyDown={handleOnLeadDaysKeyDown}
                        />
                    </Row>
                    <Row>
                        <CustomTextField
                            label={'Minimum Order Qty.'}
                            type="number"
                            min="1"
                            text={currentBidCondition?.minimumOrderQuantity}
                            name={'minimumOrderQuantity'}
                            showErrorMessage={!validMinOrderQty}
                            errorMessage={'Invalid Minimum Order Qty.'}
                            onKeyDown={handleOnLeadDaysKeyDown}
                            onChange={(e) => handleMinumumOrderQUantity(e)}
                        />
                    </Row>      
                    <Row>
                        <DropdownList label="Warranty"
                            options={listData?.warranties} selectedValue={currentBidCondition?.warrantyId}
                            showErrorMessage={!isValid && errors.warrantyId !== undefined}
                            errorMessage={errors.warrantyId}
                            name={'warrantyId'}
                            suppressBlankOption={false} valueField="id" textField="description"
                            onChange={(e) => onPropertyChange(e)}
                        />

                    </Row>
                    <FlexRow className="row">
                        <CosmeticDropdownWrapper>
                            <DropdownList label="Cosmetic Rating"
                                disabled={validCosmeticConditions.indexOf(currentBidCondition?.conditionId) === -1}
                                options={listData?.cosmeticRatings}
                                showErrorMessage={!isValid && errors.cosmeticRating != null}
                                errorMessage={errors.cosmeticRating}
                                name={'cosmeticRating'}
                                selectedValue={currentBidCondition?.cosmeticRating}
                                suppressBlankOption={false} valueField="id" textField="code"
                                onChange={(e) => onPropertyChange(e)}
                            />
                        </CosmeticDropdownWrapper>
                        <CosmeticGuideDropdownWrapper>
                            <BlueText onClick={() => showCosmeticPopup()}>Guide </BlueText>
                        </CosmeticGuideDropdownWrapper>
                    </FlexRow>
                    <Row>
                        <CustomTextField
                            label={'Lead Days'}
                            text={currentBidCondition?.leadDays}
                            showErrorMessage={!isValid && errors.leadDays != null}
                            errorMessage={errors.leadDays}
                            type="number"
                            min="0"
                            max="365"
                            name={'leadDays'}
                            onKeyDown={handleOnLeadDaysKeyDown}
                            onBlur={(e) => onPropertyChange(e)}
                        />
                    </Row>
                    <Row>
                        <CustomTextField
                            label={'Weight (lbs)'}
                            type="number"
                            min="0.01"
                            text={currentBidCondition?.weight}
                            showErrorMessage={!isValid && errors.weight !== undefined}
                            errorMessage={errors.weight}
                            name={'weight'}
                            onKeyDown={handleOnWeightKeyDown}
                            onBlur={(e) => onPropertyChange(e)}
                        />
                    </Row>
                    <Row>
                        <Checkbox
                            label={'Loaner Available'}
                            name={'loanerAvailable'}
                            checked={!!currentBidCondition?.loanerAvailable}
                            onChange={(e) => onCheckBoxChange(e)}
                        />
                    </Row>
                    <Row>
                        <Checkbox
                            label={'Non-Returnable Item'}
                            name={'nonReturnable'}
                            checked={!!currentBidCondition?.nonReturnable}
                            onChange={(e) => onCheckBoxChange(e)}
                        />
                    </Row>
                    <Row style={{paddingRight: '10px'}}>
                        <TextBox
                            name={'notes'}
                            label="Notes (optional)"
                            placeholder="Notes (optional)"
                            value={currentBidCondition?.notes}
                            text={currentBidCondition?.notes}
                            multiLine={true}
                            onBlur={(e) => onPropertyChange(e)}
                        />
                    </Row>
                </HeaderContainer>
                <ButtonContainer>
                    <Button style={{float: 'right'}} className="primary" type="button" onClick={(e) => handleOnSubmit(e)}>Continue</Button>
                    {canAddMore() && <CancelLink onClick={() => addBidCondition()} >Add Another Condition</CancelLink>}
                </ButtonContainer>
            </React.Fragment>
        )
    }

    return (
        <Container>
            {renderBidCondition()}

            {showPopup && <Popup
                onCancel={onClose}
                show={showPopup}
                onConfirm={onClose}
                confirmText="Ok"
                cancelText="Cancel">
                <PopupContent>
                    <Title>{popupTitle}</Title>
                    {popupMessage !== '' ? (
                        popupMessage
                    ) :
                        <Row className="row">
                            <BoldSubtitle className="boldClass">Blemished</BoldSubtitle>
                            <Guide className="col-11">
                                Measurable visual deffects that are immediately noted by viewer such as holes, large scratches and discoloration
                            </Guide>
                            <BoldSubtitle>Below Average</BoldSubtitle>
                            <Guide className="col-11">
                                Small but visual defects that at quick glance are not evident but are evident on close inspection
                            </Guide>
                            <BoldSubtitle>Average</BoldSubtitle>
                            <Guide className="col-11">
                                Minor defects that at quick glance are not evident but are evident on close inspection
                            </Guide>
                            <BoldSubtitle>Nearly New</BoldSubtitle>
                            <Guide className="col-11">
                                At first glance appears like new, closer visual inspection may show some minor defects
                            </Guide>
                            <BoldSubtitle>Like New</BoldSubtitle>
                            <Guide className="col-11">
                                No visual defects. Indistinguishable from a new item
                            </Guide>
                        </Row>
                    }
                </PopupContent>
            </Popup>}
            <ErrorModal
                show={showErrorPopup}
                popupTitle={popupTitle}
                popupMessage={popupMessage}
                onCancel={() => hideErrorPopup()}
                hideCancel={true}
            />
        </Container>
    );
};

export default BidCondition;

BidCondition.propTypes = {
    bid: PropTypes.shape({}),
    condition: PropTypes.shape({}),
    conditions: PropTypes.shape([]),
    conditionCodes: PropTypes.oneOfType([PropTypes.shape([]), PropTypes.array]),
    conditionExists: PropTypes.func,
    canAddMore: PropTypes.func,
    onAddCondition: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
};
