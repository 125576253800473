import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'

import {Button, TextField, Dropdown, theme, RadioButton} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import {validateWholeNumbers, validateMoney} from 'utils/validation';

const CustomTextField = styled(TextField)`
   width: 100%;   
`;

const Row = styled.div`
    margin-bottom: 1rem;
`;

const InlineRow = styled.div`
    margin-bottom: 1rem;
    display:flex;
    align-items: center;
`;

const ButtonContainer = styled(Row)`
    width: 320px;        
    margin-left: auto;
    margin-right: auto;    
`;

const Container = styled.div`
    width: 100%;    
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const HeaderContainer = styled(Container)`
    width: 280px;        
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
     
    @media (max-width: ${theme.screenXsMax}) {
        padding-left: 20px;
    }
`;

const DropdownList = styled(Dropdown)`

    div {
        span {
            font-weight: 700;
            font-size: 12px;
            color: #005DA6;
            margin: 4px 10px 4px 10px;
        }
    }
`;

const Label = styled.label`
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-weight: normal;
    height: 25px;
    letter-spacing: 0px;
    width: 490px;
`;

const FeeTypeError = styled.div`
    margin-bottom: 10px;
    margin-left: 10px;
    color: #FF0000;
    font-weight: 400;
    font-size: 12px;
}`;

const RadioButtonSpaced = styled(RadioButton)`
    margin-bottom:0px;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;

    label {
        font-size: 16px;
        display: flex;
        align-items: center;
        padding-left: 40px;
        margin-left: -10px;
        margin-bottom: 0;
    }
`;

const BidLoanerDetails = (props) => {

    const {
        loaner = {
            rentalFeeCost: '0.00',
            loanerCancellationFee: '0',
        },        
        onContinue,
        saveProgress,
        conditionCodes,    
    } = props;
    
    const [currentLoaner, setCurrentLoaner] = useState(loaner);
    const [isValid, setIsValid] = useState(true);
    const [errors, setErrors] = useState([]);    
    // const [loanerFeeTypeList] = useState([  
    //     {
    //         id: 1, 
    //         value: 'Free',
    //     },
    //     {
    //         name: 2, 
    //         value: 'Rental',
    //     },
    // ]);

    const [hazMatTypeList] = useState([{id: 0, value: 'None'}, {id: 1, value: 'Hazmat'}, {id: 2, value: 'Li-Ion'}]);

    useEffect(() => {
        if (loaner?.isNew) {
            setErrors([]);
        } else {
            validateLoaner()
        }

        setCurrentLoaner(loaner);
    }, [loaner]);

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (validateLoaner()) {
            if (currentLoaner.leadDays === 0)
                currentLoaner.leadDays = null;
            
            if (currentLoaner.loanerFeeType === '2') {
                currentLoaner.repairCancelationFee = currentLoaner.rentalFeeCost
            }

            onContinue(currentLoaner);
        }
    };
    
    const onPropertyChange = ({target: {name, value}}) => {

        let newLoaner = _.clone(currentLoaner);
        newLoaner[name] = value;

        if (name === 'loanerFeeType') {
            if (newLoaner.loanerFeeType === '1') {
                newLoaner.rentalFeeCost = undefined
            } else if (newLoaner.loanerFeeType === '2') {
                newLoaner.loanerCancellationFee = undefined
            }
        }

        setCurrentLoaner(newLoaner);
        saveProgress(newLoaner);
    }
    
    function validateLoaner() {
        let conditionsValid = true;
        let currentErrors = [...errors];

        currentErrors.forEach((item, index) => {
            currentErrors[index] = {};
        });

        if (Number(currentLoaner?.replacementCost) === '' ||
            Number(currentLoaner?.replacementCost) === 0 ||
            !validateMoney(currentLoaner.replacementCost)) {
            conditionsValid = false;
            currentErrors.replacementCost = 'Invalid replacement cost';
        }

        // if (Number(currentLoaner?.repairCancelationFee) === '' ||
        //     (Number(currentLoaner?.repairCancelationFee) === 0 && Number(currentLoaner.loanerFeeType) === 2) ||
        //     !validateMoney(currentLoaner.repairCancelationFee)) {
        //     conditionsValid = false;
        //     currentErrors.repairCancelationFee = 'Invalid Loaner fee';
        // }
          
        if (!validateWholeNumbers(currentLoaner.leadDays, true)) {
            conditionsValid = false;
            currentErrors.leadDays = 'Invalid Lead Days';
        }

        if (currentLoaner.leadDays < 0 || currentLoaner.leadDays > 365) {
            conditionsValid = false;
            currentErrors.leadDays = 'Lead Days must be between 0 and 365';
        }

        if (currentLoaner.conditionId === '') {
            conditionsValid = false;
            currentErrors.conditionId = 'Invalid condition type';
        } 
        
        if (!currentLoaner.loanerFeeType) {
            conditionsValid = false;
            currentErrors.loanerFeeType = 'Invalid Fee Type';

        }
        
        if (!currentLoaner.hazmatTypeId) {
            conditionsValid = false;
            currentErrors.hazmatTypeId = 'Invalid Hazmat Type';
        }

        if (currentLoaner.loanerFeeType === '2' && (!currentLoaner.rentalFeeCost || !validateMoney(currentLoaner.rentalFeeCost) || 
            Number(currentLoaner?.rentalFeeCost) === 0)) {
            conditionsValid = false;
            currentErrors.rentalFeeCost = 'Invalid rental fee';
        }
        setIsValid(conditionsValid);
        setErrors(currentErrors);
        
        return conditionsValid;
    }
    
    const handleOnCurrencyKeyDown = (event) => {

        const keyCode = event.keyCode;
        const value = event.target.value;
        const allowedKeyCodes = [8, 9, 37, 39, 46, 110, 188, 190]

        const isDecimal = (event.key.toString().match(/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/) ? true : false);
        const isMatch = (value.match(/(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*))?(\.[0-9]{1,2})?$/) ? true : false);

        const isDollarSign = keyCode === 52;
        const alreadyHasDollar = value.includes('$');
        const lastCharWasDollar = value[0] !== '$' && value[value.length - 1] === '$';
        const lastCharWasPointOrComma = value[value.length - 1] === '.' || value[value.length - 1] === ',';

        if (!isDecimal &&
            ((isDollarSign && alreadyHasDollar) || (isDollarSign && lastCharWasPointOrComma) ||
                (!isDollarSign && !allowedKeyCodes.includes(keyCode))) ||
            (isDecimal && lastCharWasDollar)) {
            event.preventDefault();
        }

        return allowedKeyCodes.includes(keyCode) ? true : (isNaN(Number(event.key)) || isMatch);
    }
   
    const handleOnLeadDaysKeyDown = event => {
        const keyCode = event.keyCode;
        const allowedKeyCodes = [8, 9, 37, 39, 46];
        if ((keyCode >= 107 && keyCode <= 111) || (keyCode >= 187 && keyCode <= 190)) {
            event.preventDefault();
        }

        return allowedKeyCodes.includes(keyCode) ? true : (isNaN(Number(event.key)));
    }
    
    const renderDetails = () => {
        return (
            <React.Fragment>
                <HeaderContainer>
                    <Row>
                        <DropdownList label="Condition Type" name={'conditionId'}
                            options={conditionCodes} selectedValue={currentLoaner?.conditionId}
                            showErrorMessage={!isValid && errors.conditionId !== undefined}
                            errorMessage={errors.conditionId}
                            suppressBlankOption={false} valueField="id" textField="description"
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>     
                    <Row>
                        <DropdownList label="Hazmat Type" 
                            name={'hazmatTypeId'}
                            options={hazMatTypeList} 
                            selectedValue={currentLoaner?.hazmatTypeId}
                            suppressBlankOption={false} 
                            errorMessage={errors.hazmatTypeId}
                            showErrorMessage={!isValid && errors.hazmatTypeId !== undefined}
                            valueField="id" 
                            textField="value"
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>               
                    <Row>
                        <CustomTextField
                            label={'Replacement Cost'}
                            type="text"
                            min="0.01"
                            text={currentLoaner?.replacementCost}
                            showErrorMessage={!isValid && errors.replacementCost !== undefined}
                            errorMessage={errors.replacementCost}
                            name={'replacementCost'}
                            onKeyDown={handleOnCurrencyKeyDown}
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>
                    <Row>
                        <form>
                            <Label>Fee Type</Label>
                            {!isValid && errors.loanerFeeType !== undefined && <FeeTypeError>{ errors.loanerFeeType }</FeeTypeError>}
                            <InlineRow>
                                <RadioButtonSpaced
                                    checked={currentLoaner?.loanerFeeType === '1'}
                                    id="feetypeCancellation"
                                    name="loanerFeeType"
                                    label="Free"
                                    value={1}
                                    onChange={(e) => onPropertyChange(e)}
                                />

                                <CustomTextField
                                    label={'Cancellation Fee'}
                                    type="text"
                                    min="0.01"
                                    text={currentLoaner?.loanerCancellationFee}
                                    showErrorMessage={!isValid && errors.loanerCancellationFee !== undefined}
                                    errorMessage={errors.loanerCancellationFee}
                                    name={'loanerCancellationFee'}
                                    disabled={currentLoaner?.loanerFeeType !== '1'}
                                    onKeyDown={handleOnCurrencyKeyDown}
                                    onChange={(e) => onPropertyChange(e)}
                                />
                            </InlineRow>

                            <InlineRow>
                                <RadioButtonSpaced
                                    checked={currentLoaner?.loanerFeeType === '2'}
                                    id="feetypeRental"
                                    name="loanerFeeType"
                                    label="Rental"
                                    value={2}
                                    onChange={(e) => onPropertyChange(e)}
                                />

                                <CustomTextField
                                    label={'Rental Fee'}
                                    type="text"
                                    min="0.01"
                                    text={currentLoaner?.rentalFeeCost}
                                    showErrorMessage={!isValid && errors.rentalFeeCost !== undefined}
                                    errorMessage={errors.rentalFeeCost}
                                    name={'rentalFeeCost'}
                                    disabled={currentLoaner?.loanerFeeType !== '2'}
                                    onKeyDown={handleOnCurrencyKeyDown}
                                    onChange={(e) => onPropertyChange(e)}
                                />
                            </InlineRow>
                        </form>
                        {/* <DropdownList label="Loaner Fee Type" 
                            name={'loanerFeeType'}
                            options={loanerFeeTypeList} 
                            selectedValue={currentLoaner?.loanerFeeType}
                            suppressBlankOption={false} 
                            errorMessage={errors.loanerFeeType}
                            showErrorMessage={!isValid && errors.loanerFeeType !== undefined}
                            valueField="name" 
                            textField="value"
                            onChange={(e) => onPropertyChange(e)}
                        /> */}
                    </Row>  
                    {/* <Row>
                        <CustomTextField
                            label={'Loaner Fee'}
                            type="text"
                            text={currentLoaner?.repairCancelationFee}
                            name={'repairCancelationFee'}
                            showErrorMessage={!isValid && errors.repairCancelationFee !== undefined}
                            errorMessage={errors.repairCancelationFee}
                            onKeyDown={handleOnCurrencyKeyDown}
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>                     */}
                    <Row>
                        <CustomTextField
                            label={'Lead Days'}
                            text={currentLoaner?.leadDays}
                            showErrorMessage={!isValid && errors.leadDays != null}
                            errorMessage={errors.leadDays}
                            type="number"
                            min="0"
                            max="365"
                            name={'leadDays'}
                            onKeyDown={handleOnLeadDaysKeyDown}
                            onBlur={(e) => onPropertyChange(e)}
                        />
                    </Row>
                    <Row>
                        <CustomTextField
                            label={'Notes (optional)'}
                            multiLine={true}
                            rows={2}
                            text={currentLoaner?.notes}
                            placeholder="New Note"
                            name={'notes'}
                            limit={120}
                            onKeyDown={handleOnLeadDaysKeyDown}
                            onBlur={(e) => onPropertyChange(e)}
                        />
                    </Row>                                       
                </HeaderContainer>
                <ButtonContainer>
                    <Button style={{float: 'right'}} className="primary" type="button" 
                        onClick={(e) => handleOnSubmit(e)}>Continue</Button>                    
                </ButtonContainer>
            </React.Fragment>
        )
    }

    return (
        <Container>
            {renderDetails()}                        
        </Container>
    );
};

export default BidLoanerDetails;

BidLoanerDetails.propTypes = {
    bid: PropTypes.shape({}),
    loaner: PropTypes.shape({}),     
    conditionCodes: PropTypes.oneOfType([PropTypes.shape([]), PropTypes.array]),    
    onContinue: PropTypes.func.isRequired,
    saveProgress: PropTypes.func.isRequired,
};
