import _ from 'lodash';
import axios from 'axios';

const SET_SELECTED_ORDER_TAB = 'SET_SELECTED_ORDER_TAB';
const SET_SELECTED_ORDER_VIEW = 'SET_SELECTED_ORDER_VIEW';
const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER';
const SET_CACHED_ORDERS = 'SET_CACHED_ORDERS';

const SET_SELECTED_ORDER_FROM_ALL_ORDERS = 'SET_SELECTED_ORDER_FROM_ALL_ORDERS';
const SET_CACHED_ALL_ORDERS = 'SET_CACHED_ALL_ORDERS';
const SET_FILTERS_ALL_ORDERS = 'SET_FILTERS_ALL_ORDERS';

const SET_SELECTED_REPAIR_TAB = 'SET_SELECTED_REPAIR_TAB';
const SET_SELECTED_REPAIR_VIEW = 'SET_SELECTED_REPAIR_VIEW';
const SET_SELECTED_REPAIR = 'SET_SELECTED_REPAIR';
const SET_CACHED_REPAIRS = 'SET_CACHED_REPAIRS';
const UPDATE_REPAIR = 'UPDATE_REPAIR';

// RGA - Exchanges & Returns
const SET_SELECTED_RGA_TAB = 'SET_SELECTED_RGA_TAB';
const SET_SELECTED_RGA_VIEW = 'SET_SELECTED_RGA_VIEW';
const SET_SELECTED_RGA = 'SET_SELECTED_RGA';
const SET_CACHED_RGA = 'SET_CACHED_RGA';
const SET_SELECTED_RGA_FILTERS = 'SET_SELECTED_RGA_FILTERS';
const REQUEST_UPDATE = 'REQUEST_UPDATE';

// RGA - Loaners and Rentals
const SET_SELECTED_LOANERS_RENTALS_TAB = 'SET_SELECTED_LOANERS_RENTALS_TAB';
const SET_SELECTED_LOANERS_RENTALS_VIEW = 'SET_SELECTED_LOANERS_RENTALS_VIEW';
const SET_SELECTED_LOANERS_RENTALS = 'SET_SELECTED_LOANERS_RENTALS';
const SET_CACHED_LOANERS_RENTALS = 'SET_CACHED_LOANERS_RENTALS';

const ERROR = 'ERROR';

const SET_BREADCRUMBS = 'SET_BREADCRUMBS';

export const defaultState = {
    selectedOrderTab: '',
    selectedOrderView: '',
    selectedOrder: {},
    cachedOrders: [],
    selectedOrderFromAllOrders: {},
    cachedOrdersFromAllOrders: [],
    selectedRepairTab: '',
    selectedRepairView: '',
    selectedRepair: {},
    cachedRepairs: [],
    selectedRgaTab: '',
    selectedRgaView: '',
    selectedRga: {},
    cachedRga: [],
    selectedLoanersRentalsTab: '',
    selectedLoanersRentalsView: '',
    selectedLoanersRentals: {},
    cachedLoanersRentals: [],
    cachedCriteria: [],
    breadcrumbs: [],
};

const reducer = (state = _.cloneDeep(defaultState), action) => {

    switch (action.type) {
        // Orders
        case SET_SELECTED_ORDER_TAB: {
            const {selectedOrderTab} = action;
            return {...state, selectedOrderTab};
        }
        case SET_SELECTED_ORDER: {
            const {selectedOrder} = action;
            return {...state, selectedOrder};
        }
        case SET_CACHED_ORDERS: {
            const {cachedOrders} = action;
            return {...state, cachedOrders};
        }
        case SET_SELECTED_ORDER_VIEW: {
            const {selectedOrderView} = action;
            return {...state, selectedOrderView};
        }

        // All Orders
        case SET_SELECTED_ORDER_FROM_ALL_ORDERS: {
            const {selectedOrderFromAllOrders} = action;
            return {...state, selectedOrderFromAllOrders}
        }
        case SET_CACHED_ALL_ORDERS: {
            const {cachedOrdersFromAllOrders} = action;
            return {...state, cachedOrdersFromAllOrders}
        }
        case SET_FILTERS_ALL_ORDERS: {
            const {cachedCriteria} = action;
            return {...state, cachedCriteria}
        }

        // Repairs
        case SET_SELECTED_REPAIR_TAB: {
            const {selectedRepairTab} = action;
            return {...state, selectedRepairTab};
        }
        case SET_SELECTED_REPAIR: {
            const {selectedRepair} = action;
            return {...state, selectedRepair};
        }
        case UPDATE_REPAIR: {
            const {cachedRepairs, repair} = action;
            let repairs = [...cachedRepairs];
            const index = repairs.findIndex(i => i.lineItemId === repair.lineItemId);
            repairs[index] = {...repair};

            return {...state, cachedRepairs: [...repairs]};
        }
        case SET_CACHED_REPAIRS: {
            const {cachedRepairs} = action;
            return {...state, cachedRepairs};
        }
        case SET_SELECTED_REPAIR_VIEW: {
            const {selectedRepairView} = action;
            return {...state, selectedRepairView};
        }

        // Exchanges & Returns
        case SET_SELECTED_RGA_TAB: {
            const {selectedRgaTab} = action;
            return {...state, selectedRgaTab};
        }
        case SET_SELECTED_RGA: {
            const {selectedRga} = action;
            return {...state, selectedRga};
        }
        case SET_CACHED_RGA: {
            const {cachedRga} = action;
            return {...state, cachedRga};
        }
        case SET_SELECTED_RGA_VIEW: {
            const {selectedRgaView} = action;
            return {...state, selectedRgaView};
        }
        case SET_SELECTED_RGA_FILTERS: {
            const {cachedRgaFilters} = action;
            return {...state, cachedRgaFilters};
        }
        // Loaners and Rentals
        case SET_SELECTED_LOANERS_RENTALS_TAB: {
            const {selectedLoanersRentalsTab} = action;
            return {...state, selectedLoanersRentalsTab};
        }
        case SET_SELECTED_LOANERS_RENTALS: {
            const {selectedLoanersRentals} = action;
            return {...state, selectedLoanersRentals};
        }
        case SET_CACHED_LOANERS_RENTALS: {
            const {cachedLoanersRentals} = action;
            return {...state, cachedLoanersRentals};
        }
        case SET_SELECTED_LOANERS_RENTALS_VIEW: {
            const {selectedLoanersRentalsView} = action;
            return {...state, selectedLoanersRentalsView};
        }

        case SET_BREADCRUMBS: {
            const {breadcrumbs} = action;
            return {...state, breadcrumbs};
        }
        case REQUEST_UPDATE: {
            return {...state};
        }
        case ERROR:
        default:
            return state;
    }
}

// Orders
export function setSelectedOrderTab(selectedOrderTab) {
    return (dispatch) => dispatch({type: SET_SELECTED_ORDER_TAB, selectedOrderTab});
}

export function setSelectedOrderView(selectedOrderView) {
    return (dispatch) => dispatch({type: SET_SELECTED_ORDER_VIEW, selectedOrderView});
}

export function setSelectedOrder(selectedOrder, breadcrumbs) {
    return (dispatch) => {
        dispatch({type: SET_SELECTED_ORDER, selectedOrder})
        dispatch({type: SET_BREADCRUMBS, breadcrumbs})
    };
}

export function setBreadCrumbs(breadcrumbs) {
    return (dispatch) => {
        dispatch({type: SET_BREADCRUMBS, breadcrumbs})
    };
}

export function setCachedOrders(cachedOrders) {
    return (dispatch) => dispatch({type: SET_CACHED_ORDERS, cachedOrders});
}

// All Orders
export function setSelectedOrderFromAllOrders(selectedOrderFromAllOrders) {
    return (dispatch) => dispatch({type: SET_SELECTED_ORDER_FROM_ALL_ORDERS, selectedOrderFromAllOrders});
}

export function setCachedOrdersFromAllOrders(cachedOrdersFromAllOrders) {
    return (dispatch) => dispatch({type: SET_CACHED_ALL_ORDERS, cachedOrdersFromAllOrders});
}

export function setCachedCriteria(cachedCriteria) {
    return (dispatch) => dispatch({type: SET_FILTERS_ALL_ORDERS, cachedCriteria});
}

export function setCachedFilters(cachedCriteria) {    
    localStorage.setItem('cachedFilters', JSON.stringify(cachedCriteria));
}

export function getCachedFilters() {
    return localStorage.getItem('cachedFilters');
}

// Repairs
export function setSelectedRepairTab(selectedRepairTab) {
    return (dispatch) => dispatch({type: SET_SELECTED_REPAIR_TAB, selectedRepairTab});
}

export function setSelectedRepairView(selectedRepairView) {
    return (dispatch) => dispatch({type: SET_SELECTED_REPAIR_VIEW, selectedRepairView});
}

export function setSelectedRepair(selectedRepair, breadcrumbs) {
    return (dispatch) => {
        dispatch({type: SET_SELECTED_REPAIR, selectedRepair})
        dispatch({type: SET_BREADCRUMBS, breadcrumbs})
    };
}

export function setCachedRepairs(cachedRepairs) {
    return (dispatch) => dispatch({type: SET_CACHED_REPAIRS, cachedRepairs});
}

export function updateRepair(repair) {
    return (dispatch, getState) => {
        const {orders: {cachedRepairs}} = getState();
        dispatch({type: UPDATE_REPAIR, cachedRepairs, repair});
    }
}

// Exchanges & Returns
export function requestUpdate(lineItemId, rgaType) {
    return (dispatch, getState) => {
        try {
            return axios.post('/order/return-and-exchange/request-update', {
                lineItemId,
                rgaType,
            });
        } catch (e) {
            console.error(e);
        }
    };
}

export function setSelectedRgaTab(selectedRgaTab) {
    return (dispatch) => dispatch({type: SET_SELECTED_RGA_TAB, selectedRgaTab});
}

export function setSelectedRgaView(selectedRgaView) {
    return (dispatch) => dispatch({type: SET_SELECTED_RGA_VIEW, selectedRgaView});
}

export function setSelectedRga(selectedRga, breadcrumbs) {
    return (dispatch) => {
        dispatch({type: SET_SELECTED_RGA, selectedRga})
        dispatch({type: SET_BREADCRUMBS, breadcrumbs})
    };
}

export function setCachedRga(cachedRga) {
    return (dispatch) => dispatch({type: SET_CACHED_RGA, cachedRga});
}

export function setCachedRgaFilters(cachedRgaFilters) {
    localStorage.setItem('cachedRgaFilters', cachedRgaFilters);
    
}
export function getCachedRgaFilters() {
    return localStorage.getItem('cachedRgaFilters');
}
// Loaners and Rentals
export function setSelectedLoanersRentalsTab(selectedLoanersRentalsTab) {
    return (dispatch) => dispatch({type: SET_SELECTED_LOANERS_RENTALS_TAB, selectedLoanersRentalsTab});
}

export function setSelectedLoanersRentalsView(selectedLoanersRentalsView) {
    return (dispatch) => dispatch({type: SET_SELECTED_LOANERS_RENTALS_VIEW, selectedLoanersRentalsView});
}

export function setSelectedLoanersRentals(selectedLoanersRentals, breadcrumbs) {
    return (dispatch) => {
        dispatch({type: SET_SELECTED_LOANERS_RENTALS, selectedLoanersRentals})
        dispatch({type: SET_BREADCRUMBS, breadcrumbs})
    };
}

export function setCachedLoanersRentals(cachedLoanersRentals) {
    return (dispatch) => dispatch({type: SET_CACHED_LOANERS_RENTALS, cachedLoanersRentals});
}

export default reducer;
