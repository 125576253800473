import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {theme} from '@partssourceinc/react-ui-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import {useSelector} from 'react-redux';
import SupplierSelectionDialog from 'components/SupplierSelectionDialog';

const StyledHeader = styled.header`
    

    border-bottom: 2px solid #eee;
    background-color: white;
    min-width: 1004px;



`;

const Content = styled.div`
    display: flex;
    justify-content: space-between;

   @media (max-width: 819px) {

padding-bottom: 30px;
padding-top:5px;
    }
`;

const Left = styled.div`
    height: 55px;
    display: flex;
    align-items: center;
    flex: 2;
`;

const Img = styled.img`
    height: 16px;
    margin-left: 35px;

    @media (max-width: 819px) {
        margin-left: 20px;
        height: 12px;
    }
`;

const Title = styled.span`
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    color: rgb(119, 119, 119);
    line-height: 55px;
    vertical-align: middle;

    @media (max-width: 1040px) {
        font-size: 12px;
    }
@media (max-width: 819px) {
       white-space: pre-wrap;
       line-height: 10px;
   }

`;

const Right = styled.div`
    float: right;
    height: 55px;
    line-height: 55px;
    margin-right: 50px;
    border-left: 2px solid #eee;
    flex: 1;
    cursor: pointer;

   @media (max-width: 819px) {      
margin-right: 80%;
display: flex;
position: relative;
white-space: pre-wrap;
border-left: 2px solid #eee;
height: 150%;
}
`;

const IconContainer = styled.span`
    padding: 0 15px;

`;

const FacilityContainer = styled.div`
    display: inline-block;

`;

const FacilityName = styled.span`
    font-weight: bold;
    font-size: 16px;
    line-height: 1rem;
    display: block;
    ${props => props.facilitySelected && css`
        margin-top: -0.5rem;
        position: absolute;
    `}

`;

const FacilityTitle = styled.span`
    display: ${props => props.show ? 'block' : 'none'};
    color: ${theme.blue};
    font-weight: bold;
    position: absolute;
    margin-top: -2rem;
    line-height: 12px;
    font-size: 12px;
 @media (max-width: 800px) {
display:block;
}
`;

const AdminHeader = () => {
    const selectedFacility = useSelector(state => state.user.selectedFacility);
    const [facilityDialogVisible, setFacilityDialogVisible] = useState(false);

    const showFacilityDialog = () => {
        setFacilityDialogVisible(true)
    };

    const hideFacilityDialog = (e) => {
        if (e)
            e.stopPropagation()
            
        setFacilityDialogVisible(false);
    }

    return (<div>
        <div>
            <StyledHeader className="row" />
            <StyledHeader className="row">
                <Content className="col-md-12">
                    <Left>
                        <Img src="/logo300.png" alt="PartsSource Logo" />
                        <Title>PartsSource Supplier Portal Admin</Title>
                    </Left>
                    <Right onClick={showFacilityDialog}>
                        <IconContainer>
                            <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
                        </IconContainer>
                        <FacilityContainer>
                            <FacilityTitle show={selectedFacility != null}>Facility</FacilityTitle>
                    
                            <FacilityName facilitySelected={selectedFacility != null}>
                                    
                                {selectedFacility?.name ?? 'Select Supplier'}
                            </FacilityName>
                        </FacilityContainer>
                        <SupplierSelectionDialog
                            show={facilityDialogVisible}
                            onCancel={e => hideFacilityDialog(e)}
                            onSelectFacility={hideFacilityDialog}
                        />
                    </Right>
                </Content>
            </StyledHeader>
        </div>
    </div>
    );
};

export default AdminHeader;

