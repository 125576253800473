import React, {useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {Popup, TextFieldSelect, theme} from '@partssourceinc/react-ui-core';
import _ from 'lodash';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {impersonate} from 'reducers/user';
import {getRedirectUrl} from 'utility';
import init from 'site';
import * as PropTypes from 'prop-types';

const Container = styled.div`
    min-width: 225px;
    border-right: 2px solid #eee;
`;

const WelcomeBox = styled.div`
    border-bottom: 2px solid #eee;
    padding: 10px 0 10px 35px;
`;

const List = styled.ul`
    list-style: none;
    padding: 10px 0 10px 35px;
`;

const NavAction = styled.a`
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline !important;
    color: #000;
    cursor: pointer;
`;

const NameContainer = styled.span`
    display: block;
    padding-bottom: 1rem;
`;

const Greeting = styled.span`
    font-weight: bold;
    font-size: 12px;
`;

const LinkContainer = styled.li`
    margin-bottom: 15px;
    margin-left: ${props => props.indented ? '18px' : 0};
`;

const SidebarLink = styled(NavLink)`
    text-decoration: none;
    color: #000;
    font-weight: bold;
    

    &:hover {
        text-decoration: none;
    }
    
    &.active, &.active:hover {
        color: ${theme.blue};
    }
`;

const PopupBody = styled.div`
    height: 450px;

    .lazy-list {
        max-height: 410px !important;
    }
`;

const AdminNavBar = ({updateTriggerLogout}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const history = useHistory();

    const [userDialogVisible, setUserDialogVisible] = useState(false);
    const [userList, setUserList] = useState([]);

    function logOut() {
        if (updateTriggerLogout)
            updateTriggerLogout(true);
        // dispatch(logoutUser());
        // history.push('/login');
    }

    function openUserDialog() {
        setUserDialogVisible(true);
    }

    function onClose() {
        setUserDialogVisible(false);
    }

    async function handleSelectUser(selectedUser) {
        const response = await dispatch(impersonate(selectedUser.loginId));
        const {isPasswordExpired} = response.data;
        const [userDefaults] = await dispatch(init());

        const redirectUrl = 
            getRedirectUrl(userDefaults.defaultHomePage, isPasswordExpired, false, null);

        history.push(redirectUrl);
    }

    async function onSearch(query) {
        const {data: users} = await axios.get(`/user/search?q=${query}`);
        setUserList(users);
    }

    return (
        <Container>
            <WelcomeBox>
                <Greeting>Hello, </Greeting>
                <NameContainer>
                    {_.capitalize(user.firstName)} {_.capitalize(user.lastName)}
                </NameContainer>
                <NavAction onClick={openUserDialog}>Impersonate User</NavAction> |&nbsp;
                <NavAction onClick={logOut}>Sign Out</NavAction>
                <Popup show={userDialogVisible} onCancel={onClose} hideButtons={true}>
                    <PopupBody>
                        <h1>Select a User</h1>
                        <TextFieldSelect
                            label="User"
                            placeHolder="User"
                            keyField="id"
                            valueField="name"
                            autoExpand={true}
                            onSelect={handleSelectUser}
                            onSearch={onSearch}
                            listItems={userList}
                        />
                    </PopupBody>
                </Popup>
            </WelcomeBox>
            <List>
                <LinkContainer>
                    <SidebarLink exact={true} to="/admin/company">
                        Company Management
                    </SidebarLink>
                </LinkContainer>
                <LinkContainer indented={true}>
                    <SidebarLink to="/admin/company/settings">
                        Company Settings
                    </SidebarLink>
                </LinkContainer>
                <LinkContainer>
                    <SidebarLink to="/admin/site">
                        Site Settings
                    </SidebarLink>
                </LinkContainer>
                <LinkContainer>
                    <SidebarLink exact={true} to="/admin/reporting-portal">
                        Reporting Portal Management
                    </SidebarLink>
                </LinkContainer>
                <LinkContainer indented={true}>
                    <SidebarLink to="/admin/reporting-portal/widgets">
                        Widget Management
                    </SidebarLink>
                </LinkContainer>
                <LinkContainer indented={true}>
                    <SidebarLink to="/admin/reporting-portal/portals">
                        Portal Management
                    </SidebarLink>
                </LinkContainer>
                
            </List>
        </Container>
    );
};

AdminNavBar.propTypes = {
    updateTriggerLogout: PropTypes.func,
};

export default AdminNavBar;
