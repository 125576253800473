import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {setSelectedRga, setSelectedRgaTab, setSelectedRgaView, setCachedRga} from 'reducers/orders';
import axios from 'axios';
import styled from 'styled-components';
import withViewToggle from 'components/OrdersHOC';
import PropTypes from 'prop-types';
import {GridKeys} from 'utils/gridKeys/exchangesAndReturnsGridKeys';
import MultiView, {ViewType, GridNames} from 'components/MultiView';

const Container = styled.div`
    width: 100%;
    top: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const LegendSpan = styled.span`
    font-size: 14px;
    line-height: 18px;
    font-weight: '100';
    margin-left: 5px;
    padding-right: 20px;
`;

const LegendWrapper = styled.div`
    margin-top: 15px;
    position: relative;
`;

const LegendTitle = styled.span` 
    font-weight: 600;
    font-size: 14px;
`;

const PendingDueDate = styled.span`
    background-color: #ffca82;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-top: 2px;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: -2px;
`;

const ExchangesAndReturns = (props) => {
    const {setViewRef, isMobile, isPhone, view, setView} = props;
    const gridName = GridNames.rgaOrders.id;
    const history = useHistory();
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedRgaOrder, setGridSelectedRgaOrder] = useState(null);
    const cachedRgaOrders = useSelector(state => state.orders.cachedRga);
    const cachedView = useSelector(state => state.orders.selectedRgaView);
    const dispatch = useDispatch();

    if (selectedRgaOrder != null) {
        handleSelectedRgaOrder();
    }

    useEffect(() => {
        getCachedData();
    }, []);

    const getData = async () => {
        setLoading(true);
        getGridPreferences();
        const response = await axios.get(`order/return-and-exchange`);
        setData(response.data);
        dispatch(setCachedRga(response.data));
        setLoading(false);
    }

    const setColumnList = (columnList) => {
        setColumns(columnList);
    }

    const getCachedData = async () => {
        if (cachedRgaOrders.length === 0) {
            getData();
        } else {
            getGridPreferences();
            setView(cachedView);
            setViewRef(cachedView);
            setData(cachedRgaOrders);
            setLoading(false);
        }
    }

    const handleSelect = (rgaOrder) => {
        let currentRgaOrder = {...selectedRgaOrder};
        currentRgaOrder = rgaOrder;
        setGridSelectedRgaOrder(currentRgaOrder);
    }

    function getBreadcrumbText(status) {
        return GridKeys.find(x => x.value.toLowerCase() === status.toLowerCase()).tabDisplay;
    }

    function handleSelectedRgaOrder() {
        if (location.search != null)
            dispatch(setSelectedRgaTab(location.search.split('=')[1]));

        const selectedRga = selectedRgaOrder.data ?
            selectedRgaOrder.data : selectedRgaOrder;

        dispatch(setSelectedRga(selectedRga, {
            firstBreadcrumb: 'Exchanges & Returns',
            secondBreadcrumb: getBreadcrumbText(selectedRga.returnExchangeStatus.toUpperCase()),
            returnText: getBreadcrumbText(selectedRga.returnExchangeStatus.toUpperCase()),            
        }));
        const url = selectedRga.displayPo ? `/exchange-return-details/po/${selectedRga.displayPo}` : `/exchange-return-details/${selectedRga.orderId}`
        history.push(`${url}`);
    }

    const getGridPreferences = async () => {
        const response = await axios.get(`setting/grid/${gridName}`);

        setColumns(response.data.columns);
        dispatch(setSelectedRgaView(response.data.view));

        if (!response.data.view || window.innerWidth <= 1149) {
            const viewType = ViewType.Card;
            setView(viewType);
            setViewRef(viewType);
        } else {
            setView(response.data.view);
            setViewRef(response.data.view);
        }
    }

    const renderLegend = () => {
        return (
            <LegendWrapper>
                <LegendTitle>Legend:</LegendTitle>             
                <PendingDueDate />
                <LegendSpan>Past Due</LegendSpan>
            </LegendWrapper>
        );
    }

    const renderRow = (rowData) => {
        return {
            dueShortly: rowData.pastDue,
        };
    }

    const saveGridPreferences = (grid) => {
        dispatch(setSelectedRgaView(grid.view));
        setView(grid.view);
        setViewRef(grid.view);
        setColumns(grid.columns);
    }

    return (
        <Container>
            <MultiView
                gridName={gridName}
                label="Exchanges & Returns"
                view={view}
                selectionMode="single"
                data={data}
                onRenderLegend={renderLegend}
                onRenderRow={renderRow}
                onSaveGridPreferences={saveGridPreferences}
                loadingData={loading}
                setLoadingData={setLoading}
                gridKeys={GridKeys}
                columns={columns}
                setColumnList={setColumnList}
                onRefresh={getData}
                onSelect={handleSelect}
                isMobile={isMobile}
                isPhone={isPhone} />
        </Container>
    );
};

export default withViewToggle(ExchangesAndReturns);

ExchangesAndReturns.propTypes = {
    setViewRef: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isPhone: PropTypes.bool.isRequired,
    view: PropTypes.string,
    setView: PropTypes.func.isRequired,
};
