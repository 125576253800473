import styled from 'styled-components';
import {LeftArrow} from 'utils/styles';
import {NavLink} from 'react-router-dom';
import {theme} from '@partssourceinc/react-ui-core';

export const ImgContainer = styled.div `

    margin-top: -10px;
    display: inline-block;
    width: 125px;
    vertical-align: top;

    @media (min-width: 1685px) {
        max-width: 150px!Important;
    }
`;

export const OrderArrow = styled(LeftArrow)`
    margin-left: 5px;
    margin-right: 5px; 
    margin-top: 10px;
    color: rgb(0, 93, 166);   
`;

export const Container = styled.div`
    width: 100%;
    top: 1em;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;

    @media (max-width: 818px) {
        padding-left: 10px;
        margin-top: 10px;
    }
`;

export const BreadCrumbHeader = styled.span `
    font-weight: 300;
    font-size: 24px; 
    margin-left: 2px;
    top: 8px;
    position: relative;      
`;

export const BreadCrumbSecondRow = styled.span `
    position: relative;    
    margin-top: 18px;
    font-size: 14px;
    font-weight: 400;  
`;

export const SecondArrow = styled(LeftArrow)`
    font-size: 16px;
    color: rgb(74, 74, 74);
    margin-right: 8px;
`;

export const BlueHeader = styled.h1 `
    color: rgb(0, 93, 166);
    font-weight: 300;
    font-size: 24px;
    float:left;
    padding-right:5px;
    margin-top: 2px;
`;

export const BreadCrumbHeaderLink = styled(NavLink)`
    color: rgb(0, 93, 166);
    font-weight: 300;
    font-size: 24px;
    float:left;
    padding-right:5px;
    margin-top: 2px;
    margin-bottom: 20px;

    &:hover {        
        text-decoration: none;
    }
`;

export const BreadCrumbLink = styled(NavLink)`
    margin-left: 0;
    text-decoration: none;
    position: relative;
    top: 1px;
    color: ${theme.navMenuGoBackColor};
    &:hover {
        color: ${theme.navMenuGoBackColor};
        text-decoration: none;
    }
`;

export const HeaderRow = styled.div`
    line-height: 25px;
`;

export const SecurityErrorText = styled.h2`    
    font-size: 18px;
    font-weight: bold;
    color: red;
    margin-top: 10px;
`;
