import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useHistory, useLocation} from 'react-router-dom';
import axios from 'axios';
import {setCachedRga, setBreadCrumbs} from 'reducers/orders';
import {Button} from '@partssourceinc/react-ui-core';
import {Loader} from '@partssourceinc/react-ui-core';
import ErrorModal from 'components/ErrorModal';
import styled from 'styled-components';
import ExchangeReturnHeader from 'components/ExchangeReturnHeader';
import ExchangeReturnFooter from 'components/ExchangeReturnFooter';
import ReceivedDetails from 'components/Repairs/ReceivedDetails';
import {printPurchaseOrderPdf, formatMoney, getErrorMessage} from 'utility';
import ProductImage from '@partssourceinc/react-ui-core/components/ProductImage';
import {ImgContainer, Container, SecurityErrorText} from 'components/DetailsStyledComponents';
import moment from 'moment';
import PageBreadcrumbs from 'components/Breadcrumbs';
import {GridKeys} from 'utils/gridKeys/exchangesAndReturnsGridKeys';
import DownloadingDots from 'components/DownloadingDots';
import ReturnExchangeRmaInputModal from 'components/ReturnExchangeRmaInputModal';
import {faExclamation} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NotesModal from 'components/NotesModal';
import {Context} from 'utils/enums';
import RequestUpdateDialog from 'components/RequestUpdateDialog';
import {requestUpdate} from 'reducers/orders';

const YellowExclamation = styled(FontAwesomeIcon)`
    width: 1em !important;
    height: 1em !important;
    padding: 2px;
    color: #ffae3f;
    border: 1px solid;
    border-radius: 50%;
    font-size: 14px;
    margin-right: 3px;
`;

const YellowNotification = styled.span`
    color: #ffae3f;
    font-weight: 600;
    margin-top: 5px;
    font-size: 15px;
`;

const Row = styled.div`
    margin-bottom: 1rem;       
`;

const Subtitle = styled.div`
    font-size: 16px;
    float: left;
`;

const OrderHeaderText = styled.h2`
    margin-top: 14px;
    margin-bottom: 25px;
    font-size: 30px;
    font-weight: 300;

    @media (max-width: 818px) {
        
        margin-top: 20px;
    }
`;

const OrderActionMobileContainer = styled.div`
    display: none;

    @media (max-width: 818px) {
        width: 100%;
        display: block;
        margin-top: 20px;
    }
`;

const OrderActionMobileContent = styled.div`
    width: 200px;
    float: right;
`;

const FooterContainer = styled.div`
    
`;

const OrderGridHeader = styled.div`
    margin-top: 20px;    
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2px;
`;

const OrderPrintButton = styled.div`
    display: inline-block;
    margin-right: 22px;
    width: 180px;    
    float: right;
    position: relative;

    @media (max-width: 818px) {
        display: none;
    }
`;

const OrderGridContent = styled.div`
    width: 100%;    
    position: relative;
    display: flex;    
`;

const OrderButtonContainer = styled.div`    
      
    padding-right: 0;
    margin-left: -18px;
    z-index: 1000;
`;

const OrderDetailField = styled.div`
    line-height: 25px;
    width: 100%;
    padding-left: 15px;
`;

const OrderDetailFieldMobile = styled(OrderDetailField)`
    display: none;
    margin-top: 15px;

    @media (max-width: 1010px) {
        display: block;
        padding-right: 0;
    }
`;

const OrderDetailsHeader = styled(Subtitle)`
    font-size: 20px;
    padding-left: 24px;
    min-width: 200px;
    display: inline-block;
    width: calc(100% - 780px);

    @media (max-width: 1240px) {
        width: calc(100% - 680px);
    }

    @media (max-width: 1110px) {
        width: calc(100% - 580px);
    }

    @media (max-width: 1010px) {
        width: calc(100% - 480px);
    }

    @media (max-width: 910px) {
        width: calc(100% - 380px);
    }

    @media (max-width: 818px) {
        width: 100%;
        padding-bottom: 10px;
    }
`;

const OrderDetailsColumnQty = styled.div`
    display: inline-block;
    width: 140px;    
    position: relative;
    top: -6px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsColumnDate = styled(OrderDetailsColumnQty)`
    @media (max-width: 1240px) {
        display: none;
    }
`;

const OrderDetailsColumnPrice = styled.div`
    display: inline-block;
    width: 140px;    
    position: relative;
    top: -6px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsQty = styled(OrderDetailsColumnQty)`
    color: rgb(44, 58, 71);
    font-size: 16px;
    font-weight: 400;
    width: 140px;
    max-width: 140px;
    min-width: 140px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsDate = styled(OrderDetailsQty)`
    @media (max-width: 1240px) {
        display: none;
    }
`;

const OrderDetailsPrice = styled(OrderDetailsColumnPrice)`
    color: rgb(44, 58, 71);
    font-size: 16px;
    font-weight: 400;
    width: 140px;
    max-width: 140px;
    min-width: 140px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsContainer = styled.div`
    display: inline-block;
    margin-top: -10px;
    width: calc(100% - 900px);
    
    @media (max-width: 1240px) {
        width: calc(100% - 800px);
    }

    @media (max-width: 1110px) {
        width: calc(100% - 700px);
    }

    @media (max-width: 1010px) {
        width: calc(100% - 600px);
    }

    @media (max-width: 910px) {
        width: calc(100% - 500px);
    }

    @media (max-width: 818px) {
        width: 100%;
    }
`;

const OrderActionContainer = styled.div`    
    width: 200px;
    position: absolute;
    right: 0;
    top: -5px;

    @media (max-width: 818px) {
        display: none;
    }    
`;

const BoldSubtitleNoPadding = styled(Subtitle)`
    font-weight: 700;
    font-size: 16px;
    color: rgb(0, 93, 166);
    cursor: pointer;
`;

const BoldSubtitleNoLink = styled(Subtitle)`
    font-weight: 700;
    font-size: 16px;    
`;

const OrderButton = styled(Button)`
    border-radius: 0;   
    width: 200px;
    margin-bottom: 10px;
`;

const BoldSubtitle = styled(Subtitle)`
    font-weight: 600;
    padding-left: 10px;    
`;

const BlueText = styled(BoldSubtitle)`
    color: #166BAD;
    padding-left: 0;
    margin-top: 10px;
    cursor: pointer;
`;

const PrintBlueText = styled(BlueText)`  
    margin-top: 6px;    
    font-weight: 600;
    font-size: 14px;
`;

const UnderlineSubtitle = styled(Subtitle)`
    width: 100%;
    border-top-style: solid;
    border-width: 2px;    
    border-color: rgb(204, 204, 204);
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 0;
`;

const UnderlineSubtitleRow = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 0;      
`;

const UnderlineSubtitleBottom = styled(UnderlineSubtitle)`
    height: 5px;
    margin-bottom: 0;        
`;

const SmallTitle = styled.div`    
    
    color: rgb(144, 144, 144);  
    font-size: 18px;    
`;

const GraySubtitle = styled(Subtitle)`
    font-weight: 400;
    color: rgb(148, 148, 148);  
    font-size: 16px;  
    margin-top: 10px;
    width: 100%;
`;

const PartImage = styled.span`
    display: inline-block;
    vertical-align: top;
    cursor: ${props => props.isLink ? 'pointer' : null};
`;

const ExchangeReturnDetails = () => {
    const [showNotes, setShowNotes] = useState(false);
    const [showReceivedPopup, setShowReceivedPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showRmaInputPopup, setShowRmaInputPopup] = useState(false);
    const [showSecurityError, setShowSecurityError] = useState(false);
    const [selectedRga, setSelectedRga] = useState(null);
    const [selectedLineItem, setSelectedLineItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloadingPO, setIsDownloadingPO] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const breadcrumbs = useSelector(state => state.orders.breadcrumbs);
    const {id} = useParams();
    const [lastTab, setLastTab] = useState('');
    const [showRequestUpdatePopup, setShowRequestUpdatePopup] = useState(false);
    const location = useLocation();
    const detailUrl = location.pathname.indexOf('/po/') <= -1 ? '/order/return-and-exchange' : '/order/return-and-exchange/po';

    useEffect(() => {
        loadRga();
        document.querySelector('html').scrollTop = 0;
    }, [location]);

    async function loadRga() {
        setIsLoading(true);
        await axios.get(`${detailUrl}/${id}`)
            .then(res => {
                setSelectedRga(res.data);                
                const tab = getBreadcrumbText(res.data.lineItems[0].returnExchangeStatus)
                setLastTab(GridKeys.find(x => x.value.toLowerCase() === res.data?.lineItems[0].returnExchangeStatus.toLowerCase()).id);
                if (!breadcrumbs || breadcrumbs.length === 0)
                    dispatch(setBreadCrumbs({
                        firstBreadcrumb: 'Exchanges & Returns', secondBreadcrumb: 'Exchange & Return', returnText: tab,
                    }));
                    
                setIsLoading(false);
            })
            .catch(() => {

                if (!breadcrumbs || breadcrumbs.length === 0)
                    dispatch(setBreadCrumbs({
                        firstBreadcrumb: 'Exchanges & Returns', secondBreadcrumb: 'Exchange & Return', returnText: '',
                    }));

                setIsLoading(false);
                setShowSecurityError(true);
            })
    }

    function getBreadcrumbText(status) {
        return GridKeys.find(x => x.value.toLowerCase() === status.toLowerCase()).tabDisplay;
    }

    const hideErrorPopup = () => {
        setShowErrorPopup(false);
    }

    const printPurchaseOrder = async () => {

        if (selectedRga && selectedRga.lineItems.length > 0)
            await printPurchaseOrderPdf(selectedRga.lineItems[0].lineItemId, selectedRga.billingPo,
                selectedRga.lineItems[0].gsaItem, setIsDownloadingPO);
    }

    const showReceivedDialogPopup = (lineItem) => {
        setShowReceivedPopup(true);
        setSelectedLineItem(lineItem);
    }

    const showRmaInputDialogPopup = (lineItem) => {
        setSelectedLineItem(lineItem)
        setShowRmaInputPopup(true);
    }

    const onReceiveUpdate = async (e) => {
        setShowReceivedPopup(false);
        dispatch(setCachedRga([]));
        if (lastTab) {
            history.push(`/exchanges-returns?tab=${lastTab}`);
        } else {
            history.push('/exchanges-returns');
        }      
    }

    const onRequestUpdate = (lineItem) => {
        dispatch(requestUpdate(lineItem.lineItemId, lineItem.rgaType))
            .then(() => setShowRequestUpdatePopup(true));
    }

    const renderPastDue = (lineItem) => {
        let notification = 'Past Due';
        if (lineItem.isPastDue) {
            return (
                <div>
                    <YellowExclamation icon={faExclamation} />
                    <YellowNotification>{notification}</YellowNotification>
                </div>
            )
        }
    }
                
    const renderActionButtons = (lineItem) => {
        return (
            <React.Fragment>
                {(lineItem.returnExchangeStatus === 'Pending' || lineItem.returnExchangeStatus === 'ShippedInbound') &&
                    <OrderButtonContainer className="col-11">
                        <OrderButton onClick={() => showReceivedDialogPopup(lineItem)} className="primary">Receive</OrderButton>
                    </OrderButtonContainer>
                }

                {(lineItem.returnExchangeStatus === 'Received') &&
                    <OrderButtonContainer className="col-11">
                        <OrderButton onClick={() => showReceivedDialogPopup(lineItem)} className="primary">Inspect</OrderButton>
                    </OrderButtonContainer>
                }

                {(lineItem.returnExchangeStatus === 'Inspected') &&
                    <OrderButtonContainer className="col-11">
                        <OrderButton onClick={() => showReceivedDialogPopup(lineItem)} className="primary">VDF</OrderButton>
                    </OrderButtonContainer>
                }

                {(lineItem.returnExchangeStatus === 'Process' && lineItem.status === 'Exchange') &&
                    <OrderButtonContainer className="col-11">
                        <OrderButton onClick={() => showRmaInputDialogPopup(lineItem)} className="primary">Add RMA#</OrderButton>
                    </OrderButtonContainer>
                }

                {(lineItem.returnExchangeStatus === 'Process' && lineItem.status === 'Return' && !lineItem.dateReturnApprovedDenied) &&
                    <OrderButtonContainer className="col-11">
                        <OrderButton onClick={() => showRmaInputDialogPopup(lineItem)} className="primary">Process</OrderButton>
                    </OrderButtonContainer>
                }
                {(lineItem.returnExchangeStatus === 'PendingPartsSource') &&
                    <OrderButtonContainer className="col-11">
                        <OrderButton onClick={() => onRequestUpdate(lineItem)} className="primary">REQUEST UPDATE</OrderButton>
                    </OrderButtonContainer>
                }
            </React.Fragment>
        )
    }
    
    const renderLineItem = (lineItem, index) => {
        return (
            <React.Fragment key={index}>
                <OrderGridContent style={{top: index === 0 ? '-8px' : '20px'}} >
                    <ImgContainer>
                        <PartImage isLink={lineItem.imageDefaultUrl} onClick={() => (
                            lineItem.imageDefaultUrl ?
                                window.open(`${process.env.REACT_APP_PARTSSOURCE_URL}${lineItem.imageDefaultUrl}`, '_blank') : null)}>

                            <ProductImage style={{width: '100px'}} url={lineItem.imagePath} altText={lineItem.imageAlt} />
                        </PartImage>
                    </ImgContainer>
                    <OrderDetailsContainer>
                        <Row className="row">
                            <OrderDetailField className="col-11">
                                {lineItem.imageDefaultUrl ?
                                    <BoldSubtitleNoPadding
                                        onClick={() => window.open(`${process.env.REACT_APP_PARTSSOURCE_URL}${lineItem.imageDefaultUrl}`, '_blank')}>
                                        {lineItem?.description}</BoldSubtitleNoPadding>
                                    :
                                    <BoldSubtitleNoLink>
                                        {lineItem?.description}</BoldSubtitleNoLink>
                                }
                            </OrderDetailField>                            
                            <OrderDetailField className="col-11">
                                {renderPastDue(lineItem)}
                            </OrderDetailField>
                            <OrderDetailField className="col-11">
                                <Subtitle>Request Date: </Subtitle>
                                <BoldSubtitle>{moment(lineItem?.rgaDateEntered).format('MM/DD/YYYY')}</BoldSubtitle>
                            </OrderDetailField>
                            {lineItem.isApproved === 'Y' && lineItem.dateReturnApprovedDenied &&
                                <OrderDetailField className="col-11">
                                    <Subtitle>Approved Date: </Subtitle>
                                    <BoldSubtitle>{moment(lineItem.dateReturnApprovedDenied).format('MM/DD/YYYY')}</BoldSubtitle>
                                </OrderDetailField>
                            }
                            {lineItem.isApproved === 'N' && lineItem.dateReturnApprovedDenied &&
                                <>
                                    <OrderDetailField className="col-11">
                                        <Subtitle>Denied Date: </Subtitle>
                                        <BoldSubtitle>{moment(lineItem.dateReturnApprovedDenied).format('MM/DD/YYYY')}</BoldSubtitle>
                                    </OrderDetailField>
                                    <OrderDetailField className="col-11">
                                        <Subtitle>Denied Reason: </Subtitle><BoldSubtitle>{lineItem.returnDenyReason}</BoldSubtitle>
                                    </OrderDetailField>
                                </>
                            }
                            <OrderDetailField className="col-11">
                                <Subtitle>RGA Type: </Subtitle><BoldSubtitle>{lineItem?.status}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField className="col-11">
                                <Subtitle>RMA #: </Subtitle><BoldSubtitle>{lineItem?.rmaNumber}</BoldSubtitle>
                            </OrderDetailField> 
                            <OrderDetailField className="col-11">
                                <Subtitle>PO #: </Subtitle><BoldSubtitle>{lineItem?.displayPo}</BoldSubtitle>
                            </OrderDetailField>  
                            <OrderDetailField className="col-11">
                                <Subtitle>RGA #: </Subtitle><BoldSubtitle>{lineItem?.rgaNumber}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField className="col-11">
                                <Subtitle>Item #: </Subtitle><BoldSubtitle>{lineItem?.partNumber}</BoldSubtitle>
                            </OrderDetailField>                      
                            <OrderDetailField className="col-11">
                                <Subtitle>Condition: </Subtitle><BoldSubtitle>{lineItem?.conditionDescription}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Return Status: </Subtitle>
                                <BoldSubtitle>{lineItem?.nonReturnable ? 'Not Returnable' : 'Returnable'}</BoldSubtitle>
                            </OrderDetailField>
                            {(lineItem.status === 'Return') &&
                                <>
                                    <OrderDetailField className="col-11">
                                        <Subtitle>Return Reason: </Subtitle><BoldSubtitle>{lineItem.rgaReason}</BoldSubtitle>
                                    </OrderDetailField>
                                    <OrderDetailField className="col-11">
                                        <Subtitle>Box Opened: </Subtitle><BoldSubtitle>{lineItem.boxOpened ?? 'N/A'}</BoldSubtitle>
                                    </OrderDetailField>
                                    <OrderDetailField className="col-11">
                                        <Subtitle>Package Opened: </Subtitle><BoldSubtitle>{lineItem.packageOpened ?? 'N/A'}</BoldSubtitle>
                                    </OrderDetailField>
                                    <OrderDetailField className="col-11">
                                        <Subtitle>Is It Working: </Subtitle><BoldSubtitle>{lineItem.isItWorking ?? 'N/A'}</BoldSubtitle>
                                    </OrderDetailField>
                                </>
                            }
                            <OrderDetailField>
                                <Subtitle>Ref #: </Subtitle><BoldSubtitle>{lineItem?.lineItemId}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <BlueText onClick={() => handleShowNotes(lineItem)}>Notes: {`(${lineItem?.rgaNotes.length})`}</BlueText>
                            </OrderDetailField>
                            <OrderDetailFieldMobile className="col-11">
                                <Subtitle>Qty: </Subtitle>
                                <BoldSubtitle>{lineItem?.quantity} </BoldSubtitle>

                                <BoldSubtitle>{formatMoney(lineItem?.unitPrice)}/ea </BoldSubtitle>
                                <BoldSubtitle style={{fontSize: '27px'}}>{formatMoney(lineItem?.unitPrice * lineItem?.quantity)}</BoldSubtitle>
                            </OrderDetailFieldMobile>

                            <OrderActionMobileContainer>
                                <OrderActionMobileContent>
                                    {renderActionButtons(lineItem)}
                                </OrderActionMobileContent>
                            </OrderActionMobileContainer>
                        </Row>
                    </OrderDetailsContainer>
                    <OrderDetailsDate>
                        <SmallTitle>{moment(lineItem?.rgaDueDate).format('MM/D/YYYY')}</SmallTitle>
                    </OrderDetailsDate>
                    <OrderDetailsQty>
                        <SmallTitle>{lineItem?.quantity}</SmallTitle>
                    </OrderDetailsQty>
                    <OrderDetailsPrice>
                        <SmallTitle>{formatMoney(lineItem?.unitPrice)}</SmallTitle>
                    </OrderDetailsPrice>
                    <OrderDetailsPrice>
                        <SmallTitle>{formatMoney(lineItem?.unitPrice * lineItem?.quantity)}</SmallTitle>
                    </OrderDetailsPrice>

                    <OrderActionContainer>
                        {renderActionButtons(lineItem)}
                    </OrderActionContainer>

                </OrderGridContent>
                <FooterContainer style={{marginTop: index === 0 ? '-5px' : '20px'}}>
                    <ExchangeReturnFooter lineItem={lineItem} />
                </FooterContainer>
                <UnderlineSubtitleRow>
                    <UnderlineSubtitleBottom>&nbsp;</UnderlineSubtitleBottom>
                </UnderlineSubtitleRow>
            </React.Fragment>
        )
    }

    const handleShowNotes = (lineItem) => {
        setSelectedLineItem(lineItem);
        setShowNotes(true);
    }

    const handleCloseNotes = () => {
        setSelectedLineItem(null);
        setShowNotes(false);
    }

    return (
        <Container>
            <PageBreadcrumbs />
            {(!showSecurityError && !isLoading) &&
                <OrderHeaderText>PO #: {selectedRga?.displayPo}</OrderHeaderText>
            }
            {showSecurityError &&
                <SecurityErrorText>Sorry, you do not have access to this exchange/return.</SecurityErrorText>
            }
            {(!showSecurityError && !isLoading) &&
                <ExchangeReturnHeader exchangeReturn={selectedRga} />
            }

            {(!showSecurityError && isLoading) &&
                <Loader style={{marginLeft: 'auto', marginRight: 'auto'}} />
            }
            {(!showSecurityError && !isLoading) &&
                <div>
                    <OrderGridHeader>
                        <OrderDetailsHeader>Summary</OrderDetailsHeader>

                        <OrderDetailsColumnDate>
                            <GraySubtitle>Due Date</GraySubtitle>
                        </OrderDetailsColumnDate>
                        <OrderDetailsColumnQty>
                            <GraySubtitle>Quantity</GraySubtitle>
                        </OrderDetailsColumnQty>

                        <OrderDetailsColumnPrice>
                            <GraySubtitle>Unit Price</GraySubtitle>
                        </OrderDetailsColumnPrice>
                        <OrderDetailsColumnPrice>
                            <GraySubtitle>Ext. Price</GraySubtitle>
                        </OrderDetailsColumnPrice>

                        <OrderPrintButton>
                            {isDownloadingPO ? <DownloadingDots style={{marginTop: 6}} /> :
                                <PrintBlueText
                                    onClick={printPurchaseOrder}>Print Purchase Order</PrintBlueText>}
                        </OrderPrintButton>
                    </OrderGridHeader>
                    <Row>
                        <UnderlineSubtitle>&nbsp;</UnderlineSubtitle>
                    </Row>

                    {selectedRga &&
                        (selectedRga.lineItems).map((lineItem, index) => renderLineItem(lineItem, index))}
                </div>
            }

            <ErrorModal
                show={showErrorPopup}
                popupTitle={'Order Save'}
                popupMessage={getErrorMessage('exchangesReturns')}
                onCancel={() => hideErrorPopup()} />

            {showReceivedPopup && <ReceivedDetails
                show={showReceivedPopup}
                orderId={id}
                type="exchange-return"
                orderType={selectedLineItem?.orderType}
                lineItemId={selectedLineItem?.lineItemId}
                trackingNumber={selectedLineItem?.trackingNumber}
                tab={breadcrumbs.returnText}
                rgaType={selectedLineItem?.status}
                onSubmit={(e) => onReceiveUpdate(e)}
                rgaId={selectedLineItem?.rgaId}
                onCancel={() => setShowReceivedPopup(false)}
                rgaNumber={selectedLineItem?.rgaNumber}
                isRgaOrder={selectedLineItem?.status === 'Return' || selectedLineItem?.status === 'Exchange'}
                itemDescription={selectedLineItem.description}
                poNumber={selectedLineItem.displayPo} 
                partNumber={selectedLineItem.partNumber} />
            }

            {showRmaInputPopup && <ReturnExchangeRmaInputModal
                show={showRmaInputPopup}
                orderId={id}
                lineItemId={selectedLineItem?.lineItemId}
                rgaId={selectedLineItem?.rgaId}
                onSubmit={(e) => onReceiveUpdate(e)}
                onCancel={() => setShowRmaInputPopup(false)}
                type={selectedLineItem?.status}
                isDirectShip={selectedLineItem?.isDirectShip}
                previousRmaNumber={selectedLineItem?.rmaNumber}
                rmaNumberRequired={selectedLineItem?.returnRmaRequired}
                poNum={selectedLineItem.displayPo} 
                returnQty={selectedLineItem?.quantity} 
                rgaNumber={selectedLineItem?.rgaNumber} />
            }
            <NotesModal notes={selectedLineItem?.rgaNotes} 
                lineItemId={selectedLineItem?.lineItemId} 
                show={showNotes} 
                onCancel={handleCloseNotes} 
                onSubmit={() => {}}
                isReadOnly={true} 
                context={Context.RETURN} />
            <RequestUpdateDialog show={showRequestUpdatePopup} onClose={() => setShowRequestUpdatePopup(false)} />
        </Container>
    );
};

export default ExchangeReturnDetails;
