import React, {useEffect} from 'react';
import Cookies from 'js-cookie';
import {Redirect, useLocation} from 'react-router';
import MasterLayout from 'pages/MasterLayout';
import {useSelector} from 'react-redux';
import {getSupplier} from 'reducers/supplier';
import {useDispatch} from 'react-redux';
import {appInsights} from 'utils/appInsights'
import {Route, Switch} from 'react-router-dom';

const App = () => {
    const location = useLocation();
    const isEmployee = useSelector(state => state.user.isEmployee);
    const companyId = useSelector(state => state.user.companyId);
    const isLoggedIn = useSelector(state => state.user.tokenInfo); 
    const firstName = useSelector(state => state.user.firstName);
    const lastName = useSelector(state => state.user.lastName);
    const userId = useSelector(state => state.user.userId);
    const dispatch = useDispatch();  

    const loadSupplier = async () => {
        if (companyId !== 0)
            dispatch(getSupplier(companyId));
    };

    const sendGTM = () => {

        dataLayer.push({
            event: 'fsPageView',
        });
        if (isLoggedIn && window.FS) {
  
            window.FS.identify(userId.toString(), {
                displayName: `${firstName} ${lastName}`,
                userId_int: userId,
            })
        }
        // check cookie for prevPath
        // if (typeof gtag !== 'function') {
        //    return;
        // }
        const prevPathCookie = Cookies.get('prevPath');
        const currentPath = location.pathname.replace(/^[^:]+:\/\/[^/]+/, '')?.replace(/#.*/, '');

        // if no prevpath cookie exists, set prevPath cookie to current location and send a pageview
        if (!prevPathCookie || (prevPathCookie && prevPathCookie !== currentPath)) {
            if (typeof (ga) !== 'undefined') {
                // eslint-disable-next-line no-undef
                ga('gtm2.set', 'page', currentPath);
                // eslint-disable-next-line no-undef
                ga('gtm2.send', 'pageview');
            }

            // eslint-disable-next-line no-undef
            dataLayer.push({
                // event: 'page_view',
                page_title: document.title,
                page_path: currentPath,
            });

            Cookies.set('prevPath', currentPath)
        }    
    }   
    
    useEffect(() => {
        if (isLoggedIn) {
            loadSupplier()
        }
    }, []);

    useEffect(() => {
        const accountId = isEmployee ? 'Employee' : companyId.toString();
        if (isLoggedIn) {
            appInsights.context.user.id = userId.toString();
            appInsights.context.user.authenticatedId = userId.toString();
            appInsights.context.user.accountId = accountId;
        }
    }, [isLoggedIn, isEmployee, userId, companyId]);

    useEffect(() => {
        sendGTM();        
    }, [location]);

    if (isEmployee && location.pathname === '/') return <Redirect to="/admin/company" />;
     
    return (
        <Switch>
            <Route path="*" component={MasterLayout} />
        </Switch>
    );
};

export default App;
