import React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import {Button} from '@partssourceinc/react-ui-core';
import {
    CardAction,
    CardStatusDetails,
    CardStatusSection,
    CardStatusTitle,
} from 'components/CardView/StyledComponents';

const NoBidButton = styled(Button)`
    color: #777777;
`;

const BidsCardFooter = (props) => {
    const {cardData,
        onSelectCard,
        setSelectedCard,
        setShowCancelPopup,
    } = props;

    const showNoBidPopup = (card) => {
        setSelectedCard(card);
        setShowCancelPopup(true);
    }

    switch (cardData.status) {
        case 'QUOTED':
            return (
                <div>
                    <React.Fragment>
                        <CardStatusSection>
                            <div>
                                <CardStatusTitle>
                                    Submitted {cardData.isNoBid ? '\'No Bid\'' : ''} {moment(cardData.dateSubmitted).format('MM/D/YYYY h:mm A')}
                                </CardStatusTitle>
                                <CardStatusDetails>
                                    Awaiting customer acceptance
                                </CardStatusDetails>
                            </div>
                        </CardStatusSection>
                    </React.Fragment>
                    <React.Fragment>
                        <CardAction>
                            <Button secondary={true} onClick={() => onSelectCard(cardData)}>
                                REVIEW BID</Button>
                        </CardAction>
                    </React.Fragment>
                </div>
            );
        case 'REQUESTED':
            return (
                <div>
                    <React.Fragment>
                        <CardStatusSection>
                            <div>
                                <CardStatusTitle>
                                    Ready for Bid
                                </CardStatusTitle>
                                <CardStatusDetails>
                                    Customer awaiting bid
                                </CardStatusDetails>
                            </div>
                        </CardStatusSection>
                    </React.Fragment>
                    <React.Fragment>
                        <CardAction>
                            <NoBidButton ternary={true} onClick={() => showNoBidPopup(cardData)}>NO BID</NoBidButton>
                            <Button secondary={true} onClick={() => onSelectCard(cardData)}>
                                PLACE BID</Button>
                        </CardAction>
                    </React.Fragment>
                </div>
            );
        default:
            return null;
    }
}

BidsCardFooter.propTypes = {
    cardData: PropTypes.shape({
        dateSubmitted: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        repairStatus: PropTypes.string,
        rgaNumber: PropTypes.string,
        status: PropTypes.string,
        trackingNumber: PropTypes.string,
        vendorOrderNumber: PropTypes.string,
        isNoBid: PropTypes.bool,
    }),
    onSelectCard: PropTypes.func.isRequired,
    setSelectedCard: PropTypes.func.isRequired,
    setShowCancelPopup: PropTypes.func.isRequired,
}

export default BidsCardFooter;
