import {useSelector, useDispatch} from 'react-redux';
import React from 'react';
import * as PropTypes from 'prop-types';
import {convertToBlob} from 'utils/FileHandler';
import {theme} from '@partssourceinc/react-ui-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/pro-regular-svg-icons';
import {removeAttachmentFromCache} from 'reducers/attachments';

import styled from 'styled-components';

const Wrapper = styled.div`
    max-width: 500px;
    margin-bottom: 10px;
`;

const Icon = styled(FontAwesomeIcon)`
    float: left;
    color: rgb(119, 119, 119);
    margin-top: 4px;
    margin-right: 5px;
    margin-left: 5px;    
    cursor: pointer;
    font-size: 16px;
`;

const GraySubtitle = styled.div`
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    color: rgb(119, 119, 119);  
    float: left;  
    overflow-wrap: anywhere;
`;

const DocDetails = styled.div`
    
    margin-left: 0;
    margin-right: 0;
    padding-left: 0!Important;
    &:first-child {
    margin-top: 5px;
    }
`;

const DocName = styled.a`
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-weight: bold;
    color: ${theme.blue};
    height: 22px;    
    float: left;
`;

const UploadedDocsWrapper = styled.div`

    margin-left: 15px;    
    line-height: 22px;
    font-size: 12px;
    font-weight: normal;
`;

const AttachmentViewer = (props) => {
    const {
        serverAttachments = [],
        lineItem,
    } = props;
                     
    const cachedAttachments = useSelector(state => state.attachments.cachedAttachments);

    const dispatch = useDispatch();
     
    const removeAddedAttachment = (index) => {
        dispatch(removeAttachmentFromCache(index));
    }
         
    const saveDocForIE = (e, blob, fileName) => {
        e.preventDefault();
        if (navigator.userAgent.toLowerCase().includes('.net')) {
            window.navigator.msSaveBlob(blob, fileName);
        } else {
            return false;
        }
    }

    const renderAttachment = (attachment, index, isReadOnly) => {
        let extension = attachment.name.split('.').pop();
        let blob = new Blob();

        switch (extension) {
            case 'pdf' || 'csv':
                blob = convertToBlob(attachment.attachment, 'application/' + extension);
                break;
            case 'doc':
                blob = convertToBlob(attachment.attachment, 'application/msword');
                break;
            case 'docx':
                blob = convertToBlob(attachment.attachment, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
                break;
            case 'xls':
                blob = convertToBlob(attachment.attachment, 'application/vnd.ms-excel');
                break;
            case 'xlsx':
                blob = convertToBlob(attachment.attachment, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                break;
            case 'txt':
                blob = convertToBlob(attachment.attachment, 'text/plain');
                break;
            case 'html':
                blob = convertToBlob(attachment.attachment, 'text/html');
                break;
        }

        let blobUrl = '';
        let isIE = false;
        if (!navigator.userAgent.toLowerCase().includes('.net')) {
            blobUrl = URL.createObjectURL(blob);
        } else {
            isIE = true;
        } 

        return (
            <DocDetails key={index} className="row">
                {isIE
                    ?
                    <DocName id="downloadAttachment"
                        onClick={(e) => saveDocForIE(e, blob, attachment.name)}
                        style={{'cursor': 'pointer'}}
                        download={attachment.name}>
                        {attachment.name}
                    </DocName>
                    :
                    <DocName id="downloadAttachment"
                        href={blobUrl}
                        download={attachment.name}>
                        {attachment.name}
                    </DocName>
                } 
                {(!isReadOnly ?
                    <Icon onClick={() => removeAddedAttachment(index)} aria-hidden="true" icon={faTimesCircle} />
                    :
                    <div style={{width: '26px'}} />)
                }
                <GraySubtitle>{attachment.description}</GraySubtitle>
            </DocDetails>
        );
    }

    return (    
        <Wrapper>            
            <UploadedDocsWrapper>
                {cachedAttachments && cachedAttachments.length > 0 &&
                    cachedAttachments.filter(c => c.lineItemId === lineItem).map((item, index) => {
                        return renderAttachment(item, index, false);
                    })
                }
                {serverAttachments && serverAttachments.length > 0 &&
                    serverAttachments.map((item, index) => {
                        return renderAttachment(item, index, true);
                    })
                }
            </UploadedDocsWrapper>
        </Wrapper>
    );
}

export default AttachmentViewer;

AttachmentViewer.propTypes = {
    serverAttachments: PropTypes.arrayOf(
        PropTypes.shape()
    ), 
    lineItem: PropTypes.number,
};
