import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {setSelectedRepair, setSelectedRepairTab, setSelectedRepairView, setCachedRepairs, updateRepair} from 'reducers/orders';
import axios from 'axios';
import styled from 'styled-components';
import withViewToggle from 'components/OrdersHOC';
import PropTypes from 'prop-types';
import {GridKeys} from 'utils/gridKeys/repairsGridKeys';
import MultiView, {ViewType, GridNames} from 'components/MultiView';

const Container = styled.div`
    width: 100%;
    top: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const LegendSpan = styled.span`
    font-size: 14px;
    line-height: 18px;
    font-weight: '100';
    margin-left: 5px;
    padding-right: 20px;
`;

const LegendWrapper = styled.div`
    margin-top: 15px;
    position: relative;
`;

const UrgentLegend = styled.div`
    background-color: #fbcbcb;
    width: 15px;
    height: 15px;
    margin-left: 15px;
    margin-top: 2px;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: -2px;
`;

const LegendTitle = styled.span` 
    font-weight: 600;
    font-size: 14px;
`;

const PastDueLegend = styled.span`
    background-color: #ffca82;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-top: 2px;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: -2px;
`;

const Repairs = (props) => {
    const {setViewRef, isMobile, isPhone, view, setView} = props;
    const gridName = GridNames.repairs.id;
    const history = useHistory();
    const [columns, setColumns] = useState([]);
    const [repairTabs, setRepairTabs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedRepair, setGridSelectedRepair] = useState(null);
    const cachedRepairs = useSelector(state => state.orders.cachedRepairs);
    const cachedView = useSelector(state => state.orders.selectedRepairView);
    const isRMARequired = useSelector(state => state.settings.isRMARequired);
    const dispatch = useDispatch();

    if (selectedRepair != null) {
        handleSelectedRepair();
    }

    useEffect(() => {
        const getCachedData = async () => {
            if (cachedRepairs.length === 0) {
                setLoading(true);
                getGridPreferences();
                const response = await axios.get(`/order/repair`);
                dispatch(setCachedRepairs(response.data));
                setLoading(false);
            } else {
                getGridPreferences();
                setView(cachedView);
                setViewRef(cachedView);
                setLoading(false);
            }
        }

        getCachedData();

        if (isRMARequired) {
            setRepairTabs(GridKeys);
        } else {
            setRepairTabs(_.clone(GridKeys).splice(1, GridKeys.length - 1));
        }
    }, []);

    const getData = async () => {
        setLoading(true);
        getGridPreferences();
        const response = await axios.get(`/order/repair`);
        dispatch(setCachedRepairs(response.data));
        setLoading(false);
    }

    const updateData = async (lineItemId) => {
        setLoading(true);

        const {data = []} = await axios.get(`/order/repair?lineItemId=${lineItemId}`);
        const [repair] = data;

        if (repair) {
            dispatch(updateRepair(repair));
        }
        
        setLoading(false);
    }

    const handleSelect = (repair) => {
        let currentRepair = {...selectedRepair};
        currentRepair = repair;
        setGridSelectedRepair(currentRepair);
    }

    function getBreadcrumbText(status) {
        return GridKeys.find(x => x.value.toLowerCase() === status.toLowerCase()).tabDisplay;
    }

    function handleSelectedRepair() {

        if (location.search != null) {
            dispatch(setSelectedRepairTab(location.search.split('=')[1]));
        }

        const tempSelectedRepair = selectedRepair.data ?
            selectedRepair.data : selectedRepair;

        dispatch(setSelectedRepair(tempSelectedRepair, {
            firstBreadcrumb: 'Repairs',
            secondBreadcrumb: 'Repair',
            returnText: getBreadcrumbText(tempSelectedRepair.repairStatus.toUpperCase()),
        }));

        const repairDetailsUrl = tempSelectedRepair.displayPo 
            ? `/repair-details/PO/${tempSelectedRepair.displayPo}` 
            : `/repair-details/${tempSelectedRepair.orderNumber}`;
        
        history.push(repairDetailsUrl);
    }

    const setColumnList = (columnList) => {
        setColumns(columnList);
    }

    const getGridPreferences = async () => {
        const response = await axios.get(`setting/grid/${gridName}`);
        setColumns(response.data.columns);
        dispatch(setSelectedRepairView(response.data.view));

        if (!response.data.view || window.innerWidth <= 1149) {
            const viewType = ViewType.Card;
            setView(viewType);
            setViewRef(viewType);
        } else {
            setView(response.data.view);
            setViewRef(response.data.view);
        }
    }

    const renderLegend = () => {
        return (
            <LegendWrapper>
                <LegendTitle>Legend:</LegendTitle>
                <UrgentLegend />
                <LegendSpan>Critical Items</LegendSpan>
                <PastDueLegend />
                <LegendSpan>Past Due</LegendSpan>
            </LegendWrapper>
        );
    }

    const renderRow = (rowData) => {
        const isUrgent = rowData.urgencyDescription === 'CHD' || rowData.urgencyDescription === 'STAT';
        const isPastDue = rowData.isPastDue;
        return {
            gridhighlightedRow: isUrgent,
            isPastDue: !isUrgent && isPastDue,
        };
    }

    const saveGridPreferences = (grid) => {
        dispatch(setSelectedRepairView(grid.view));
        setView(grid.view);
        setViewRef(grid.view);
        setColumns(grid.columns);
    }

    const defaultSorting = (response, currentTab) => {
        if (currentTab === 'PendingShipment' ||
            currentTab === 'ShippedInbound' ||
            currentTab === 'Evaluating' ||
            currentTab === 'Quoted') {
            return _.orderBy(response, ['dateCreated'], ['desc']);
        } else if (currentTab === 'Repairing' ||
            currentTab === 'Backordered') {
            return _.orderBy(response, ['followUpDate'], ['asc']);
        } else if (currentTab === 'ShippedOutbound') {
            return _.orderBy(response, ['vendorToCustomerDateShipped'], ['desc']);
        } else if (currentTab === 'Delivered') {
            return _.orderBy(response, ['dateDelivered'], ['desc']);
        }

        return response;
    }

    return (
        <Container>
            <MultiView
                gridName={gridName}
                label="Repairs"
                view={view}
                selectionMode="single"
                data={cachedRepairs}
                onRenderLegend={renderLegend}
                onRenderRow={renderRow}
                onSaveGridPreferences={saveGridPreferences}
                loadingData={loading}
                setLoadingData={setLoading}
                gridKeys={repairTabs}
                columns={columns}
                setColumnList={setColumnList}
                headerLink={{text: 'View All Orders', url: '/orders/history'}}
                onUpdate={updateData}
                onRefresh={getData}
                onSelect={handleSelect}
                isMobile={isMobile}
                isPhone={isPhone}
                cardAction={'OpenBids'}
                defaultSorting={defaultSorting} />
        </Container>
    );
};

export default withViewToggle(Repairs);

Repairs.propTypes = {
    setViewRef: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isPhone: PropTypes.bool.isRequired,
    view: PropTypes.string,
    setView: PropTypes.func.isRequired,
};

