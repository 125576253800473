import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {setSelectedBid, setSelectedBidTab, setSelectedBidView, setCachedBids} from 'reducers/bids';
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import withViewToggle from 'components/OrdersHOC';
import PropTypes from 'prop-types';
import {GridKeys} from 'utils/gridKeys/bidsGridKeys';
import MultiView, {ViewType, GridNames} from 'components/MultiView';

const Container = styled.div`
    width: 100%;
    top: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const LegendSpan = styled.span`
    font-size: 14px;
    line-height: 18px;
    font-weight: '100';
    margin-left: 5px;
    padding-right: 20px;
`;

const LegendWrapper = styled.div`
    margin-top: 15px;
    position: relative;
`;

const UrgentLegend = styled.div`
    background-color: #fbcbcb;
    width: 15px;
    height: 15px;
    margin-left: 15px;
    margin-top: 2px;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: -2px;
`;

const IsPast30MinutesLegend = styled.div`
    background-color: #ffca82;
    width: 15px;
    height: 15px;
    margin-left: 15px;
    margin-top: 2px;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: -2px;
`;

const LegendTitle = styled.span` 
    font-weight: 600;
    font-size: 14px;
`;

const Bids = (props) => {
    const {setViewRef, isMobile, isPhone, view, setView} = props;
    const gridName = GridNames.bids.id;
    const history = useHistory();
    const menuSettings = useSelector(state => state.settings.menuSettings);
    const facilities = useSelector(state => state.settings.facilities);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedBid, setGridSelectedBid] = useState(null);
    const cachedBids = useSelector(state => state.bids.cachedBids);
    const cachedView = useSelector(state => state.bids.selectedBidView);
    const showBids = (menuSettings?.find(c => c.id === '122' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const dispatch = useDispatch();

    useEffect(() => {
        getCachedData();
        getGridPreferences();
    }, []);

    useEffect(() => {
        if (!showBids) {
            history.push('/');
        }
    }, [showBids]);

    useEffect(() => {
        if (selectedBid != null) {
            handleSelectedBid();
        }
    }, [selectedBid]);

    const getData = async () => {
        setLoading(true);
        const response = await axios.get(`bid/`);
        setData(response.data);
        dispatch(setCachedBids(response.data));
        setLoading(false);
    }

    const setColumnList = (columnList) => {
        setColumns(columnList);
    }

    const getCachedData = async () => {
        if (cachedBids.length === 0) {
            await getData();
        } else {
            getGridPreferences();
            setView(cachedView);
            setViewRef(cachedView);
            setData(cachedBids);
            setLoading(false);
        }
    }

    const handleSelect = (bid) => {
        let currentBid = {...selectedBid};
        currentBid = bid;
        setGridSelectedBid(currentBid);
    }

    function handleSelectedBid() {

        if (location.search != null) {
            dispatch(setSelectedBidTab(location.search.split('=')[1]));
        }

        if (selectedBid.data !== undefined) {
            dispatch(setSelectedBid(selectedBid.data));
            history.push(`/bid-details/${selectedBid.data.lineItemId}`);
        } else {
            dispatch(setSelectedBid(selectedBid));
            history.push(`/bid-details/${selectedBid.lineItemId}`);
        }
    }

    const getGridPreferences = async () => {
        const response = await axios.get(`setting/grid/${gridName}`);
        setColumns(response.data.columns);
        dispatch(setSelectedBidView(response.data.view));

        if (!response.data.view || window.innerWidth <= 1149) {
            const viewType = ViewType.Card;
            setView(viewType);
            setViewRef(viewType);
        } else {
            setView(response.data.view);
            setViewRef(response.data.view);
        }
    }

    const renderLegend = () => {
        return (
            <LegendWrapper>
                <LegendTitle>Legend:</LegendTitle>
                <UrgentLegend />
                <LegendSpan>Critical Items</LegendSpan>
                <IsPast30MinutesLegend />
                <LegendSpan>Past 30 Minutes</LegendSpan>
            </LegendWrapper>
        );
    }

    const renderRow = (rowData, currentTab) => {
        const dateEntered = new moment.utc(rowData.dateEnteredUTC).toDate();
        const dateNow = moment().utc().toDate();
        const diff = (dateNow - dateEntered);
        const diffMins = Math.floor((diff / 1000) / 60);
        const isPast30Minutes = diffMins > 30;

        const isUrgent = rowData.urgencyDescription === 'CHD' || rowData.urgencyDescription === 'STAT';
        return {
            isPast30Minutes: !isUrgent && currentTab !== 'MyBids' && isPast30Minutes,
            gridhighlightedRow: isUrgent,
        };
    }

    const saveGridPreferences = (grid) => {
        dispatch(setSelectedBidView(grid.view));
        setView(grid.view);
        setViewRef(grid.view);
        setColumns(grid.columns);
    }

    return (
        <Container>
            <MultiView
                gridName={gridName}
                label="Bids"
                view={view}
                selectionMode="single"
                data={data}
                onRenderLegend={renderLegend}
                onRenderRow={renderRow}
                onSaveGridPreferences={saveGridPreferences}
                loadingData={loading}
                setLoadingData={setLoading}
                gridKeys={GridKeys}
                setColumnList={setColumnList}
                columns={columns}
                onRefresh={getData}
                onSelect={handleSelect}
                isMobile={isMobile}
                isPhone={isPhone} />
        </Container>
    );
}

export default withViewToggle(Bids);

Bids.propTypes = {
    setViewRef: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isPhone: PropTypes.bool.isRequired,
    view: PropTypes.string,
    setView: PropTypes.func.isRequired,
};
