import React from 'react';
import * as PropTypes from 'prop-types';
import {Popup, Button} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';

const Dialog = styled(Popup)`
    max-width: 600px;
    margin-bottom: 60px;
    height: 300px;

    @media (min-width:320px) {
        min-height: calc(100% - 31.5rem);
    }

    @media (max-width: 549px)
    {
        box-shadow: none;
        border: none;
        border-radius: 0px;
        height: auto;
        padding: 20px;
    }
`;

const PopupBody = styled.div`
    font-family: Source Sans Pro,sans-serif;
`;

const Title = styled.h1`
    font-size: 20px;
`;

const BodyContent = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const BodyText = styled.p`
    font-size: 15px;
    font-weight: 600;
`

const Line = styled.hr`
    border-top: 1px solid #949494;
    height: 2px;
    width: 100%;
    float: left;
`;

const SaveButton = styled(Button)`
    float: right;
`;

const PasswordExpiredDialog = ({show, onCallback}) => {

    return (
        <Dialog
            show={show}
            hideButtons={true}
            onCancel={onCallback}
        >
            <PopupBody>
                <Title>Reset Your Password</Title>
                <BodyContent>
                    <BodyText>
                        The password for your account requires an update due to being expired. Please change your password.
                    </BodyText>
                </BodyContent>
                <Line />
                <SaveButton
                    primary={true}
                    onClick={onCallback}>
                    OK
                </SaveButton>
            </PopupBody>
        </Dialog>);
}

export default PasswordExpiredDialog;

PasswordExpiredDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onCallback: PropTypes.func.isRequired,
};
