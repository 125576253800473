import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StandardInfoCard from 'components/StandardInfoCard';
import ShippingInfoCardV2 from 'components/ShippingInfoCardV2';

const RepairFooterContainer = styled.div`
    width: 100%;    
    padding-top: 10px;  
    height: 100px;
    background-color: rgb(241, 241, 241);
    margin-left: 0;     
    padding-left: 0; 

    @media (max-width: 818px) {
        
        margin-top: 0;
    } 
`;

const CardContainer = styled(RepairFooterContainer)`
    display: inline-block;
    div {
        position: relative;
        bottom: 0!Important;
    }    

    @media (max-width: 1149px) {
                
        ul {
            margin-top: 0!Important;
        }       
    }  
`;

const ShippingInfoCardDetails = styled(ShippingInfoCardV2)`
    @media (max-width: 1149px) {
        
        margin-left: 0;
        margin-right: 0;        
    }
`;

const StandardInfoCardDetails = styled(StandardInfoCard)`
    @media (max-width: 1149px) {
        
        margin-left: 0;
        margin-right: 0;        
    }
`;

const RepairFooter = (props) => {
    const {
        lineItem = {},
    } = props;
   
    return (
        <React.Fragment>
            <CardContainer>
                {lineItem.status === 'PendingShipment' &&
                    <StandardInfoCardDetails title="Repair Initiated" subText="Awaiting customer shipment" />}

                {lineItem.status === 'ShippedInbound' &&
                    <ShippingInfoCardDetails showDateInfo={true} showTrackingInfo={true} 
                        title="Scheduled Delivery Date" subText={lineItem.customerToVendorShippingAddress} 
                        additionalText={lineItem.customerToVendorShippingAttention} 
                        date={lineItem.customerToVendorEstimatedDelivery} 
                        trackingNumber={lineItem.customerToVendorTrackingNumber} 
                        trackingText={lineItem?.customerToVendorShippingMethod ? lineItem?.customerToVendorShippingMethod : 
                        lineItem?.customerToVendorShipper} noDateText="Pending" noTimeText="End of Day" />}

                {(lineItem.status === 'Quoted' ||
                    lineItem.status === 'Evaluating' ||
                    lineItem.status === 'Repairing' || 
                    lineItem.status === 'Backordered') &&
                    <ShippingInfoCardDetails showDateInfo={true} showTrackingInfo={true} 
                        title="Estimated Ship Date" subText={lineItem.vendorToCustomerShippingAddress} 
                        additionalText={lineItem.vendorToCustomerShippingAttention} 
                        date={lineItem.estimatedShipDate} 
                        trackingNumber={lineItem.vendorToCustomerTrackingNumber} 
                        trackingText={lineItem?.vendorToCustomerShippingMethod ? lineItem?.vendorToCustomerShippingMethod : 
                        lineItem?.vendorToCustomerShipper} noDateText="Pending" noTimeText="End of Day" />}

                {(lineItem.status === 'ShippedOutbound' &&
                    <ShippingInfoCardDetails showDateInfo={true} showTrackingInfo={true} 
                        title="Shipped" subText={lineItem.vendorToCustomerShippingAddress} 
                        additionalText={lineItem.vendorToCustomerShippingAttention} 
                        date={lineItem.vendorToCustomerDateShipped} 
                        trackingNumber={lineItem.vendorToCustomerTrackingNumber} 
                        trackingText={lineItem?.vendorToCustomerShippingMethod ? lineItem?.vendorToCustomerShippingMethod : 
                        lineItem?.vendorToCustomerShipper} noDateText="Pending" />)}

                {lineItem.status === 'Delivered' &&
                    <ShippingInfoCardDetails showDateInfo={true} showTrackingInfo={true} isComplete={true} 
                        title="Delivered" subText={lineItem.vendorToCustomerShippingAddress} 
                        additionalText={lineItem.vendorToCustomerShippingAttention} 
                        date={lineItem.vendorToCustomerDateDelivered} 
                        trackingNumber={lineItem.vendorToCustomerTrackingNumber} 
                        trackingText={lineItem?.vendorToCustomerShippingMethod ? lineItem?.vendorToCustomerShippingMethod : 
                        lineItem?.vendorToCustomerShipper} noDateText="On Unknown Date" />}

                {lineItem.status === 'Rma' &&
                    <StandardInfoCardDetails title="Pending" subText="Awaiting Supplier RMA #" />}
            </CardContainer>
        </React.Fragment>
    );
};

export default RepairFooter;

RepairFooter.propTypes = {
    repair: PropTypes.shape({}),
    lineItem: PropTypes.shape({}),
};
