import React, {useRef, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Loader, theme} from '@partssourceinc/react-ui-core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {getCmsImageUrlByPath} from 'utility';

const CenteredChildren = styled.div`
    padding-top: 96px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    top: 0;
`;

const CenteredDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Image = styled.img`
    margin-bottom: 24px;
`;

const Text = styled.span`
    max-width: 768px;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 32px;

    @media (max-width: ${theme.screenSMMin}) {
        font-size: 14px;
        max-width: 510px;
    }

    @media (max-width: 320px) {
        max-width: 280px;
    }
`;

const SisenseDashboardSdk = (props) => {
    const SISENSE_URL = process.env.REACT_APP_SISENSE_DOMAIN;

    const {dashboardId, clearFiltersId, isError} = props;

    const [showDashboard, setShowDashboard] = useState(false);
    const dashboardLoaded = useRef(showDashboard);
    const [defaultFilters, setDefaultFilters] = useState(null);
    const [isTimeout, setIsTimeout] = useState(false);

    const contentRef = useRef(null);
    const content1Ref = useRef(null);

    const loadingStrings = useSelector(state => state.configuration.cmsResources.loadingStrings);
    const randomIndex = Math.floor(Math.random() * loadingStrings.length);

    const sisenseTimeout = useSelector(state => state.settings.siteSettings.sisenseDashboardTimeoutSetting || 0);

    const [mySisenseFrame, setMySisenseFrame] = useState(null);

    useEffect(() => {
        if (isTimeout && !showDashboard) {
            throw new Error('Sisense Dashboard Loading Timeout');
        }
    }, [isTimeout]);

    useEffect(() => {
        let timeout = parseInt(sisenseTimeout);
        if (timeout) {
            const timeoutMS = timeout * 1000;
            const checkForTimeout = setTimeout(() => {
                if (!dashboardLoaded.current) {
                    setIsTimeout(true);
                }
            }, timeoutMS);
            return () => clearTimeout(checkForTimeout);
        }
    }, []);

    useEffect(() => {
        const embed = window['sisense.embed'];
        const SisenseFrame = embed ? embed.SisenseFrame : null;
        const enums = embed ? embed.enums : {};

        if (embed) {
            const myFrame = new SisenseFrame({
                id: dashboardId,
                // Sisense application URL, including protocol and port if required
                url: SISENSE_URL,
                // OID of dashboard to load initially
                dashboard: dashboardId,
                // Which panels to show in the iFrame
                settings: {
                    showLeftPane: false,
                    showToolbar: false,
                    showRightPane: false,
                },
                // You can define existing <iframe/> DOM element here or
                // pass some <div/> as a parameter to render function below
                // and <iframe/> will be rendered inside that <div/> dynamically
                element: document.getElementById('sisense-frame-dashboard'),
            });

            setMySisenseFrame(myFrame);

            myFrame.render().then(() => {
                myFrame.dashboard.on(enums.DashboardEventType.LOADED, () => {
                    setShowDashboard(true);
                    dashboardLoaded.current = true;

                    myFrame.dashboard.getCurrent().then((res) => {
                        setDefaultFilters(res.filters)
                    })
                });

                myFrame.dashboard.on(enums.DashboardEventType.SIZECHANGED, () => {
                    myFrame.getSize().then((res) => {
                        if (res.content.height > 0) {
                            content1Ref.current.style.height = res.content.height + 'px'; 
                            contentRef.current.style.height = res.content.height + 'px';
                        }
                    })
                });
            }).catch(c => console.log(c));
        }
    }, []);

    useEffect(() => {
        if (mySisenseFrame && clearFiltersId !== 0) {
            clearAllFilters()
        }
    }, [clearFiltersId]);

    const clearAllFilters = () => {
        mySisenseFrame.dashboard.getCurrent().then((response) => {
            let filtersToRemove = [];
            let filtersToAdd = [];
            response.filters.forEach(el => {
                let obj = {
                    jaql: {
                        dim: el.jaql.dim,
                    },
                }

                if (el.jaql.level)
                    obj.jaql.level = el.jaql.level;

                filtersToRemove.push(obj);
                filtersToAdd.push(el);

            });

            mySisenseFrame.dashboard.removeFilters(filtersToRemove).then(() => {
                mySisenseFrame.dashboard.applyFilters(defaultFilters);
            });
        })
    }

    return (
        <CenteredDiv>
            {!showDashboard && !isError && <CenteredChildren>
                <Image src={getCmsImageUrlByPath('/content/gallery/supplierpro/insights/info-visual_please-wait.png')} alt="" />
                <Text>
                    <strong>Please wait, your dashboards are loading...</strong>
                    <br />
                    {loadingStrings[randomIndex]}
                </Text>
                <Loader />
            </CenteredChildren>}
            {isError && <CenteredChildren>
                <Text>An issue has been identified, please contact support at supplierportalsupport@partssource.com.</Text>
            </CenteredChildren>}
            {
                !isError && <div id="sisense-container-dashboard"
                    style={{
                        visibility: showDashboard ? 'visible' : 'hidden',
                        width: '100%',
                        maxWidth: '1500px',
                        minHeight: '750px',
                        height: '100%',
                        display: 'inline-block',
                        position: 'relative',
                    }}
                    ref={content1Ref}>
                    <iframe id="sisense-frame-dashboard" style={{height: '100vh'}} ref={contentRef} frameBorder="0" />
                </div>
            }

        </CenteredDiv>
    );
}

SisenseDashboardSdk.propTypes = {
    dashboardId: PropTypes.string,
    clearFiltersId: PropTypes.number,
    isError: PropTypes.bool,
};

export default SisenseDashboardSdk;
