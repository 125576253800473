import _ from 'lodash';

export default function useQueryString(search) {
    return search.length
        ? _.fromPairs(search
            .substr(1)
            .split('&')
            .map(pair => {
                const eqIndex = pair.indexOf('=');
                return [pair.substr(0, eqIndex), pair.substr(eqIndex + 1)];
            }))
        : {};
}
