import React from 'react'
import styled, {keyframes} from 'styled-components'
import PropTypes from 'prop-types';

const DownloadingDots = ({text = 'Downloading', style}) => {
    const ellipseDots = keyframes`
    25% {
        content: "";
      }
      50% {
        content: ".";
      }
      75% {
        content: "..";
      }
      100% {
        content: "...";
      }
    `;
    
    const DownloadingSpinner = styled.div`
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 10px;
    color: #005BA6;
    text-align:left;
    :after {
        content: "...";
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ${ellipseDots} 1.5s infinite .3s;
        animation-fill-mode: fowards;
        width: 1.25em;
    }
`;

    return (<DownloadingSpinner style={style}>{text}</DownloadingSpinner>)
}

export default DownloadingDots;

DownloadingDots.propTypes = {
    text: PropTypes.string,    
    style: PropTypes.shape,
};
