import React, {useState} from 'react';
import styled from 'styled-components';
import {TextField, brandFont, theme, Popup, Button} from '@partssourceinc/react-ui-core';
import PropTypes from 'prop-types';
import axios from 'axios';
import {logEvent, getErrorMessage} from 'utility';
import ErrorModal from 'components/ErrorModal';

const Label = styled.p`
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 10px;
    clear: both;
`;

const TextBlock = styled(TextField)`
    margin-bottom: 15px;

    > div {
        width: 100%;
        height: 107px;
    }

    ${brandFont('16px')}
`;

const Reference = styled.div`
    font-family: "Source Sans Pro", sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 15px;

    a {
        font-size: 16px;
        text-decoration: underline;
        color: ${theme.grey1};
    }
`;

const Line = styled.hr`
    border-top: 1px solid #949494;
    height: 2px;
    width: 100%;
    float: left;
`;

const Modal = styled(Popup)`
    max-width: 700px;
`;

const SaveButton = styled(Button)`
    float: right;
`;

const CustomTextField = styled(TextField)`
   max-width: 20em; 
   min-width: 280px;
   float: left;
`;

const WarningMessageHandler = styled.p`
    color: red;
`;

const RmaInputModal = ({rmaNumber, orderId, rgaId, lineItemId, type, show, onCancel, onSubmit}) => {
    const [note, setNote] = useState('');
    const [receivedRmaNumber, setRmaNumber] = useState(rmaNumber);
    const [loading, setLoading] = useState(false);
    const [missingNotesError, setMissingNotesError] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const handleRmaSave = async () => {
        if (!receivedRmaNumber) {
            setMissingNotesError('Please document how the part is not acceptable');
            return;
        }

        const request = {
            note: note,
            lineItemId,
            rgaId,
            orderId,
            rmaNumber: receivedRmaNumber,
            rgaType: 'repair'
        };

        logEvent('Repair Update', {
            'Ref Number': lineItemId,
            'Page': 'repairs',
            'Tab': 'RMA',     
            'Order State': 'AddRMA',
            'Action': 'Confirm',
        });

        setLoading(true);
        await axios.post(`/order/rma`, request)
            .then((resp) => {
                onSubmit(resp);
            })
            .catch(() => {
                setLoading(false);
                setShowErrorPopup(true);
            });
    }

    const hideErrorPopup = () => {
        setShowErrorPopup(false);
    }

    const handleUpdateNote = (e) => {
        const content = e.target.value;

        if (/^[\s]+$/i.test(content)) {
            setWarningMessage(' ');
            return;
        } else {
            setWarningMessage(null);
        }

        const isLongText = content.length > 120;
        setNote(isLongText ? content.substring(0, 120) : content);
        setWarningMessage(isLongText ? 'Please limit your note to 120 characters.' : null);
    }

    return (
        <Modal
            show={show}
            onCancel={onCancel}
            hideButtons={true}>
            <Reference>
                <span>Update for Reference #{lineItemId}</span> <a href={`/${type}-details/${orderId}`}>View Details</a>
            </Reference>
            <div>
                <Label style={{marginBottom: 10}}>RMA #</Label>
                <CustomTextField
                    label={'Enter RMA #'}
                    name={'rmaNumber'}
                    onChange={(e) => setRmaNumber(e.target.value)}
                    text={receivedRmaNumber}
                    value={receivedRmaNumber}
                    style={{marginBottom: 10}} />
            </div>
            <Label>Note</Label>
            <TextBlock
                multiLine={true}
                name="note"
                id="notes"
                type="text"
                placeholder="Note (max 120 characters)"
                limit={121}
                text={note}
                onChange={handleUpdateNote}
                label="Add a Comment"
                showErrorMessage={false}
                errorMessage={missingNotesError} />
            {warningMessage && <WarningMessageHandler>{warningMessage}</WarningMessageHandler>}
            <Line />
            <SaveButton
                disabled={!receivedRmaNumber || warningMessage}
                primary={true}
                onClick={handleRmaSave}
                loading={loading}>
                CONFIRM
            </SaveButton>
            <ErrorModal
                show={showErrorPopup}
                popupTitle={'Repair Receive'}
                popupMessage={getErrorMessage('repair')}
                onCancel={() => hideErrorPopup()} />
        </Modal>
    );
}

export default RmaInputModal;

RmaInputModal.propTypes = {
    lineItemId: PropTypes.number,
    rgaId: PropTypes.number,
    type: PropTypes.string,
    orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rmaNumber: PropTypes.number,
    show: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
};
