import styled, {keyframes} from 'styled-components';

const primary = '#FF9505';
const grey = '#F1F1F1';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

export default styled.div`
    border: 10px solid ${grey};
    border-radius: 50%;
    border-top: 10px solid ${primary};
    width: 75px;
    height: 75px;
    animation: ${rotate} 2s linear infinite;
    margin-top: 30px;
`;
