import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const TooltipText = styled.span`
    float: left;
    background-color: #f1f1f1;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 50%);
    border-radius: 3px;
    padding: 2px 10px;
    font-size: 13px;
`;

const Icon = styled(FontAwesomeIcon)`
    float: left;
    color: black;
    margin-top: 6px;
    margin-right: 5px;
    margin-left: 5px;
    color: #4a4a4a;
    cursor: pointer;
`;

const Tooltip = (props) => {
    const {        
        message,
             
    } = props;
              
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => { 
                
        document.addEventListener('mousedown', handleClick, false);               
                    
    }, [location])

    const handleClick = async () => {
        setShowTooltip(false);        
    }

    return (
        <div>
            <Icon aria-hidden="true" onClick={() => setShowTooltip(true)} 
                className="fa fa-question-circle product_tooltip"
                icon={faQuestionCircle} />
            {showTooltip ? <TooltipText>{message}</TooltipText> : null} 
        </div>
    );
};

export default Tooltip;

Tooltip.propTypes = {    
    message: PropTypes.string,    
};
