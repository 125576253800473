import React, {useState, useEffect, useContext} from 'react';
import {TextField, Button, Checkbox, brandFont} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {BrComponent} from '@bloomreach/react-sdk';
import {Redirect, useHistory, useLocation} from 'react-router';
import {Link, NavLink} from 'react-router-dom';
import {validate} from 'utils/validation';
import {useSelector, useDispatch} from 'react-redux';
import {loginUser} from 'reducers/user';
import {getCompany} from 'reducers/companies';
import {logEvent, getRedirectUrl} from 'utility';
import {BrPageContext} from '@bloomreach/react-sdk';
import init from 'site';

const LoginFailedError = styled.div`
    margin-bottom: 20px;
    margin-top: 40px;

    h4 {
        font-size: 15px;
        color: red;
        font-weight: bold;
    }
`;

const LoginWrapper = styled.div`
    font-family: "Source Sans Pro";

    h1 {
        font-weight: 300;
    }

    button {
        margin-top: 20px;
    }
`;

const PasswordEye = styled(FontAwesomeIcon)`
    width: 19px;
    float: right;
    opacity: 0.5;
    margin-top: -30px;
    margin-right: 5px;
    cursor: pointer;
`;

const LoginContent = styled.div`
    margin-top: 12px;
    max-width: 300px;

    form {
        position: relative;
    }

    @media (min-width: 1200px) {
        float: right;
        margin-right: 70px;
    }

    @media (max-width: 819px) {
        max-width: none;
    }
`;

const ForgotItContainer = styled.div`
    margin-top: 1rem;
`;

const NotRegistered = styled(NavLink)`
    color: #005da6;
    display: block;
    ${brandFont('14px')}
    margin: 10px 0;

    &:hover {
        text-decoration: none;
    }
`;

const Login = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loginFailed, setFail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(!!Cookies.get('id'));
    const [passwordEyeVisible, setPasswordVisibility] = useState(true);
    const sproForgotPages = useSelector(state => state.configuration.featureFlags.sproForgotPages);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const page = useContext(BrPageContext);

    useEffect(() => {
        if (Cookies.get('id')) {
            setUserName(Cookies.get('id'));
            setRememberMe(true);
        }
    }, []);

    if (location.pathname === '/login' && location.search.startsWith('?e=')) {
        localStorage.setItem('equery', location.search.replace('?e=', ''));
        return <Redirect to="/change-password" />
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        const values = {
            username: userName,
            pass: password,
        };

        const returnedErrors = validate(values);

        if (!returnedErrors || Object.keys(returnedErrors).length === 0) {
            try {
                const {
                    isEmployee, 
                    firstName, 
                    lastName, 
                    userId, 
                    rootCompanyId,
                    isPasswordExpired,
                } = await dispatch(loginUser(userName, password));

                const prevPath = localStorage.getItem('prevPath');
                if (isEmployee) {
                    const adminPath = prevPath && prevPath.includes('admin') ? prevPath : '/admin/company';
                    dispatch(init());
                    setFail(false);
                    setLoading(false);
                    logRedirect(userId, adminPath);
                    history.push(adminPath);
                } else {
                    const {id: companyId, name: companyName} = await dispatch(getCompany(rootCompanyId));
                    const [userDefaults] = await dispatch(init());

                    window.dataLayer.push({
                        userId,
                        userName: `${firstName} ${lastName}`,
                        companyId,
                        companyName,
                    });

                    setFail(false);
                    setLoading(false);
                    
                    const redirectUrl = getRedirectUrl(userDefaults.defaultHomePage, isPasswordExpired, page.isPreview(), prevPath)

                    logRedirect(userId, redirectUrl);
                    history.push(redirectUrl);
                }
            } catch (ex) {
                console.log(ex);
                setLoading(false);
                setFail(true);
            }
        } else {
            setLoading(false);
            setErrors(returnedErrors);
        }
    };

    const logRedirect = (userId, path) => {
        logEvent('LOGIN', {
            login: userId ? true : false,
            redirect: `${window.location.origin}${path}`,
        })
    }

    const setChecked = () => {
        setRememberMe(!rememberMe);
        localStorage.rememberMe = !rememberMe;
    };

    const togglePasswordVisibility = () => {
        setPasswordVisibility(!passwordEyeVisible)
    };

    return (
        <div className="row">
            <div className="d-none d-md-block col-md-6">
                <BrComponent path="main/container" />
            </div>
            <LoginWrapper className="col-md-5">
                <LoginContent>
                    <h1>Sign In to your Supplier Account</h1>
                    <form onSubmit={handleLogin}>
                        <TextField
                            id="username"
                            type="text"
                            text={userName}
                            label="Username"
                            placeholder="Username"
                            tabIndex="1"
                            style={{marginTop: '20px'}}
                            showErrorMessage={errors?.username}
                            errorMessage={errors.username}
                            onChange={e => {
                                setUserName(e.target.value);
                                errors.username = '';
                            }}
                        />
                        <TextField
                            id="password"
                            type={!passwordEyeVisible ? 'text' : 'password'}
                            text={password}
                            label="Password"
                            placeholder="Password"
                            tabIndex="2"
                            style={{marginTop: '20px'}}
                            showErrorMessage={errors?.password}
                            errorMessage={errors.password}
                            onChange={e => {
                                setPassword(e.target.value);
                                errors.password = '';
                            }}
                        />
                        {passwordEyeVisible && <i>
                            <PasswordEye aria-hidden="true" onClick={togglePasswordVisibility} icon="eye-slash" />
                        </i>}
                        <Checkbox id="remember-me" label="Remember Me" checked={rememberMe} onClick={setChecked} />
                        <Button
                            tabIndex={3}
                            disabled={!userName || !password}
                            type="submit"
                            loading={loading}
                            secondary={true}
                        >
                            Sign In
                        </Button>
                        <NotRegistered to="registration">Not Registered?</NotRegistered>
                        {loginFailed && <LoginFailedError>
                            <h4>Invalid username or password.</h4>
                        </LoginFailedError>}
                        {sproForgotPages && <ForgotItContainer>
                            Forgot your <Link to="/forgot-username">username</Link>&nbsp;
                            or <Link to="/forgot-password">password</Link>?
                        </ForgotItContainer>}
                    </form>
                </LoginContent>
            </LoginWrapper>
        </div>
    );
};

export default Login;
