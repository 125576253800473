import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useOktaAuth} from '@okta/okta-react';
import {useLocation, useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
import {setOktaAuthState, setOktaAuth, logoutUser, oktaLoginUser, setIsLoggingIn, setIsImpersonating} from './../reducers/user';
import {oktaSignInConfig} from '../pages/oktaConfig';
import {getCompany} from 'reducers/companies';
const OKTA_OAUTH2_NAME = process.env.REACT_APP_OAUTH2_NAME;
import init from 'site';
import {logEvent} from 'utility';

const AuthHandler = ({triggerLogout, updateTriggerLogout}) => {
    const {oktaAuth, authState} = useOktaAuth();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const isImpersonating = useSelector(state => state.user.isImpersonating);
    const tokenInfoUserId = useSelector(state => state.user.userId);
    const adminIsLoggedIn = useSelector(state => state.user.isLoggedIn);
    const authStateStore = useSelector(state => state.user.authState);
    const oktaAuthentication = useSelector(state => state.settings.siteSettings?.oktaAuthentication === 'Y'); 
    const [localOktaAuthentication, setLocalOktaAuthentication] = useState(false);
    
    useEffect(() => {
        setLocalOktaAuthentication(oktaAuthentication);
    }, [oktaAuthentication]);

    useEffect(() => {
        const callLogin = async (accessToken, idToken, refreshToken) => {
            await login(accessToken, idToken, refreshToken)
            dispatch(setIsLoggingIn(false));
        }

        if (localOktaAuthentication && !Cookies.get('impersonate') && !Cookies.get('subscriptionId')) {
            let shouldTriggerLogin = false;
            if (!authStateStore && authState) {
                shouldTriggerLogin = true;
            }

            if (authState !== null) {
                dispatch(setOktaAuthState(authState));
            }

            const token = Cookies.get('tokenInfo'); // valid, but rest below are undefined

            let access_token = Cookies.get('access_token');
            let id_token = Cookies.get('id_token');
            let refresh_token = Cookies.get('refresh_token');
            let date = Cookies.get('expires_at');
            let expires_at = Date.parse(date);
            let scope = Cookies.get('scope');
            let token_type = Cookies.get('token_type');
            
            if (!authState && access_token && id_token && refresh_token && expires_at && scope) {
                let oToken = {expiresAt: expires_at, scope: scope.split(' ')};

                let accessToken = Object.assign({}, oToken);
                let decodedAccess = oktaAuth.token.decode(access_token);
                accessToken.claims = decodedAccess.payload;
                accessToken.accessToken = access_token;
                accessToken.tokenType = token_type;
                accessToken.userinfoUrl = `${oktaSignInConfig.baseUrl}/oauth2/${OKTA_OAUTH2_NAME}/v1/userinfo`;

                let idToken = Object.assign({}, oToken);
                let decodedId = oktaAuth.token.decode(id_token);
                idToken.claims = decodedId.payload;
                idToken.idToken = id_token;

                let refreshToken = Object.assign({}, oToken);
                refreshToken.refreshToken = refresh_token;

                let tokens = {
                    'accessToken': accessToken,
                    'idToken': idToken,
                    'refreshToken': refreshToken,
                };

                oktaAuth.tokenManager.setTokens(tokens);
            }

            if (authState && authState.isAuthenticated && (shouldTriggerLogin || !token || isImpersonating ||
                (tokenInfoUserId === 0 && adminIsLoggedIn === false))) {
                if (!authState.accessToken || !authState.accessToken.claims || !Number.isInteger(authState.accessToken.claims.status)) {
                    logUserOut();
                    return;
                }

                callLogin(authState.accessToken, authState.idToken, authState.refreshToken);
                if (isImpersonating)
                    dispatch(setIsImpersonating(false));
            } else if (authState && !authState.isAuthenticated) {
                dispatch(setIsLoggingIn(false));
            }
        }
    }, [dispatch, authState, localOktaAuthentication]);

    useEffect(() => {
        if (oktaAuth.authStateManager._authState != null) {
            dispatch(setOktaAuth(oktaAuth));
        }
    }, [dispatch, oktaAuth]);

    useEffect(() => {
        if (location.pathname === '/logout' && oktaAuth) {
            logUserOut();
        }
    }, [dispatch, oktaAuth, location.pathname]);

    useEffect(() => {
        if (triggerLogout) {
            performOktaLogout();
        }
    }, [triggerLogout]);

    const performOktaLogout = async() => {
        try {
            oktaAuth.tokenManager.clear();
            oktaAuth.signOut();
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(logoutUser());
            history.push('/login');
            // Remove Okta SSO cookies
            Cookies.remove('access_token');
            Cookies.remove('id_token');
            Cookies.remove('refresh_token');
            Cookies.remove('expires_at');
            Cookies.remove('scope');
            Cookies.remove('token_type');
            if (updateTriggerLogout) {
                updateTriggerLogout(false);
            }
        }
    }

    const login = async (accessToken, idToken, refreshToken) => {        
        const info = await oktaAuth.token.getUserInfo(accessToken, idToken);
        await dispatch(oktaLoginUser(info, accessToken, refreshToken));

        const isEmployee = accessToken.claims.isEmployee;
        const firstName = accessToken.claims.firstName;
        const lastName = accessToken.claims.lastName;
        const userId = accessToken.claims.userId;
        const rootCompanyId = accessToken.claims.rootCompanyId;

        dispatch(init());
        const {id: companyId, name: companyName} = await dispatch(getCompany(rootCompanyId)); 
        const prevPath = localStorage.getItem('prevPath');
        if (isEmployee) {
            const adminPath = prevPath && prevPath.includes('admin') ? prevPath : '/admin/company'; 
            logRedirect(userId, adminPath);
            history.push(adminPath);
        } else {
            window.dataLayer.push({
                userId,
                userName: `${firstName} ${lastName}`,
                companyId,
                companyName,
            });
        }        
    };   
    const logRedirect = (userId, path) => {
        logEvent('LOGIN', {
            login: userId ? true : false,
            redirect: `${window.location.origin}${path}`,
        })
    }

    const logUserOut = () => {
        oktaAuth.tokenManager.clear();
        oktaAuth.signOut().then(() => dispatch(logoutUser()));
        history.push('/login');
    }

    return null;
};

export default AuthHandler;
