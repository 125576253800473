import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {TextField, brandFont, theme, Popup, Button, Dropdown, RadioButtonList, RadioButton} from '@partssourceinc/react-ui-core';
import PropTypes from 'prop-types';
import axios from 'axios';
import {getPageAndTab, logEvent, getErrorMessage} from 'utility';
import ErrorModal from 'components/ErrorModal';

const Label = styled.p`
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 10px;
    clear: both;
`;

const BoldRadioButton = styled(RadioButton)`
    display: flex;

    label{
        padding-left: 25px;
        font-size: 20px;
    }
`;

const SelectionList = styled(RadioButtonList)`
    position: relative;

    > div {
        margin-right: 20px;
        display: flex;

        label{
            padding-left: 25px;
            min-width: auto;
            font-size: 16px;
        }
    }
`;

const TextBlock = styled(TextField)`
    margin-bottom: 15px;

    > div {
        width: 100%;
        height: 107px;
    }

    ${brandFont('16px')}
`;

const Reference = styled.div`
    font-family: "Source Sans Pro", sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 15px;

    a{
        font-size: 16px;
        text-decoration: underline;
        color: ${theme.grey1};
        margin-left: 15px;
   }
`;

const Line = styled.hr`
    border-top: 1px solid #949494;
    height: 2px;
    width: 100%;
`;

const Modal = styled(Popup)`
    max-width: 700px;

    @media (max-width: 549px) {
        display: block;
    }
`;

const SaveButton = styled(Button)`
    float: right;
`;

const CustomTextField = styled(TextField)`
   max-width: 20em; 
   min-width: 280px;
   float: left;
`;

const WarningMessageHandler = styled.p`
    color: red;
`;

const DropdownList = styled(Dropdown)`
    width: 280px;    

    div {
        span {
            font-weight: 700;
            font-size: 12px;
            color: #005DA6;
            margin: 4px 10px 4px 10px;
        }
    }
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const ReturnExchangeRmaInputModal = ({previousRmaNumber, orderId, poNum, rgaId, lineItemId, type,
    show, onCancel, onSubmit, isDirectShip, rmaNumberRequired, returnQty, rgaNumber}) => {

    const [note, setNote] = useState('');
    const [receivedRmaNumber, setRmaNumber] = useState(previousRmaNumber);
    const [loading, setLoading] = useState(false);
    const [missingRequiredDataError] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [returnAddressOptions, setReturnAddressOptions] = useState([]);
    const [isApproved, setIsApproved] = useState('');
    const [addressId, setAddressId] = useState();
    const [returnDenyReasons, setReturnDenyReasons] = useState([]);
    const [denyReason, setDenyReason] = useState('');
    const [denyReasonId, setDenyReasonId] = useState();

    useEffect(() => {
        async function getReturnAddresses() {
            setLoading(true);
            try {
                const resp = await axios.get(`order/rga/${rgaId}/returnaddresses`);
                setLoading(false);
                setReturnAddressOptions(resp.data);
                setDefaultAddressIfSingular(resp.data);
            } catch {
                setLoading(false);
            }
        }
        getReturnAddresses();
        return () => {
            setReturnAddressOptions({}); 
        };
    }, []);

    useEffect(() => {
        async function getReturnDenyReasons() {
            setLoading(true);
            try {
                const resp = await axios.get('common/returndenyreasons')
                setLoading(false);
                setReturnDenyReasons(resp.data);
            } catch {
                setLoading(false);
            }
        }
        getReturnDenyReasons();
        return () => {
            setReturnDenyReasons({});
        };
    }, []);

    const returnAddressRequired = ((type === 'Exchange') || (type === 'Return' && isApproved === 'Y'));
   
    const handleRmaSave = async () => {

        const {tab, page} = getPageAndTab();
      
        const validAddressId = addressId ? parseInt(addressId) : 0
       
        const request = {
            lineItemId,
            rmaNumber: receivedRmaNumber,
            note,
            addressId: validAddressId,
            isApproved: type === 'Exchange' ? 'Y' : isApproved,
            denyReasonId,
            denyReason,
            isDirectShip,
            rgaId,
            orderId,
            returnQty,
            rgaNumber,
            rgaType: type,
        };

        const approvalNote = () => {
            if (type === 'Return') {

                switch (isApproved) {
                    case 'Y':
                        return 'Approved';
                    case 'N':
                        return 'Denied';
                    case 'RI':
                        return 'Info Requested';
                    default:
                        break;
                }
            }

            return '';
        }

        const rmaChangeNote = () => {
            if (!previousRmaNumber && receivedRmaNumber) {
                return 'Add';
            }

            if (previousRmaNumber && !receivedRmaNumber) {
                return 'Delete';
            }

            if ((previousRmaNumber !== receivedRmaNumber) && (previousRmaNumber) && (receivedRmaNumber)) {
                return 'Modify';
            }

            return ''
        }

        logEvent('RETURN', {
            'Page': page,
            'Tab': tab,
            'Ref number': lineItemId,
            'Approval': approvalNote(),
            'RMA': rmaChangeNote(),
            'RGA ID': rgaId,
        });

        setLoading(true);
        try {
            const resp = await axios.post(`/order/rga/${rgaId}/rma`, request)
            onSubmit(resp);
        } catch {
            setLoading(false);
            setShowErrorPopup(true);
        }
    }

    const hideErrorPopup = () => {
        setShowErrorPopup(false);
    }

    const handleUpdateNote = (e) => {
        const content = e.target.value;

        if (/^[\s]+$/i.test(content)) {
            setWarningMessage(' ');
            return;
        } else {
            setWarningMessage(null);
        }

        const isLongText = content.length > 120;
        setNote(isLongText ? content.substring(0, 120) : content);
        setWarningMessage(isLongText ? 'Please limit your note to 120 characters.' : null);
    }

    const onApproveDeny = ({target: {value}}) => {
        setIsApproved(value);
        clearRmaValueIfNecessary();
        clearDenyReasonIfNecessary(value);
        setDefaultAddressIfSingular(returnAddressOptions);
        clearAddressIdIfNecessary(value);        
    } 

    const setDefaultAddressIfSingular = (addresses) => {
        return setAddressId(addresses.length === 1 ? addresses[0].addressId : null);
    }

    const clearAddressIdIfNecessary = (value) => {
        return value === 'N' ? setAddressId(null) : null;
    }

    const clearDenyReasonIfNecessary = (value) => {
        if (value === 'Y') {
            setDenyReasonId(0);
            setDenyReason('');
        }
    }

    const clearRmaValueIfNecessary = () => {
        if (isApproved === 'N') {
            setRmaNumber(null)
        }
        if (isApproved === 'Y') {
            setRmaNumber(previousRmaNumber)
        }
    }

    const handleRmaChange = ({target: {value}}) => {
        setRmaNumber(value);
    }

    const handleAddressChange = ({target: {value}}) => {
        setAddressId(parseInt(value));
    }

    const handleDenyReasonChange = ({target: {value}}) => {
        setDenyReasonId(parseInt(value));
        const reasonString = returnDenyReasons.filter(r => (r.id === parseInt(value)))[0].description;
        setDenyReason(reasonString);
    }

    const prettifyAddress = (x) => {
        const address1 = [x.address1.toUpperCase()].filter(Boolean).join(', ')
        const address2 = [x.city.toUpperCase(), x.state.toUpperCase(), x.zip.toUpperCase()].filter(Boolean).join(', ')
        const full = `${address1} 
                      ${address2}`;
        return full;
    }

    const assignReturnAddressOptions = () => {
        const addressOptions = [];
        if (returnAddressOptions) {
            returnAddressOptions.map((x) => (
                addressOptions.push({value: x.addressId, label: `${prettifyAddress(x)}`, key: x.addressId})
            ))
        }
        return addressOptions;       
    }

    const options = assignReturnAddressOptions();

    return (
        <Modal
            show={show}
            onCancel={onCancel}
            hideButtons={true}>
            <Reference>
                <span>Reference #{lineItemId}</span>
                <a href={`/exchange-return-details/po/${poNum}`}>View Details</a>                
            </Reference>
       
            {type === 'Return' &&
                <div>
                    <BoldRadioButton
                        checked={isApproved === 'Y'}
                        key="approve"
                        id={1}
                        value="Y"
                        label="Approve Return"
                        onChange={onApproveDeny}
                        style={{marginBottom: '20px'}}
                    />
                    <BoldRadioButton
                        checked={isApproved === 'N'}
                        key="deny"
                        id={0}
                        value="N"
                        label="Deny Return"
                        onChange={onApproveDeny}
                        style={{marginBottom: '20px'}}
                    />
                    <BoldRadioButton
                        checked={isApproved === 'RI'}
                        key="reqInfo"
                        id={2}
                        value="RI"
                        label="Request Additional Information"
                        onChange={onApproveDeny}
                        style={{marginBottom: '25px'}}
                    />
                </div>
            }

            {((type === 'Exchange') || (type === 'Return' && isApproved === 'Y')) &&
                <div>
                    <div>
                        If you require a Vendor Return Form to be completed with the returned part,
                        please email the PartsSource Returns team at Returns@partsssource.com.
                    </div>
                    <div>
                        <Label style={{marginBottom: 10}}>RMA #</Label>
                        <CustomTextField
                            label={'Enter RMA #'}
                            name={'rmaNumber'}
                            key="rmaNumber"
                            onChange={handleRmaChange}
                            text={previousRmaNumber}
                            value={receivedRmaNumber}
                            style={{marginBottom: '25px'}} />
                    </div>

                    <div>
                        <Label> Return Address</Label>
                        <SelectionList
                            name="returnAddress"
                            selectedValue={addressId}
                            options={options}
                            onChange={handleAddressChange}                            
                        />
                        {options.length === 0 && 
                            <span>No Return addresses on file. Please contact PartsSource Returns team at <a href="mailto:returns@partssource.com">
                                returns@partssource.com</a> to resolve.</span>}
                    </div>
                    
                </div>                
            }
           
            {isApproved === 'N' &&
                <DropdownList
                    name="reasons"
                    suppressBlankOption={false}
                    valueField="id"
                    textField="description"
                    label="Deny Reason"
                    placeholder="Deny Reason"
                    selectedValue={denyReason?.id}
                    options={returnDenyReasons}
                    onChange={handleDenyReasonChange}
                    errorMessage={missingRequiredDataError} />
            }

            <Label>Note</Label>
            <TextBlock
                multiLine={true}
                showErrorMessage={denyReason?.toLowerCase() === 'other' && !note}
                name="note"
                id="notes"
                type="text"
                placeholder={isApproved === 'RI' ? 'What additional information is needed?' : 'Note (max 120 characters)'}
                limit={120}
                text={note}
                onChange={handleUpdateNote}
                required={isApproved === 'RI'}
                label="Notes"
                errorMessage={missingRequiredDataError} />
            {warningMessage && <WarningMessageHandler>{warningMessage}</WarningMessageHandler>}
            <Line />
            <FlexContainer>
                {type?.toLowerCase() === 'exchange' && (<div>
                    If you need additional information to approve or deny, exit and email the PartsSource Returns team at Returns@partssource.com.
                </div>)}
                <SaveButton
                    disabled={(!receivedRmaNumber && !previousRmaNumber && rmaNumberRequired && isApproved === 'Y')
                        || ((isApproved === 'N') && (!denyReason)) 
                        || ((returnAddressRequired) && (!addressId))
                        || (type === 'Exchange' && !receivedRmaNumber)
                        || (denyReason?.toLowerCase() === 'other' && !note)
                        || (isApproved === 'RI' && !note)}
                    primary={true}
                    onClick={handleRmaSave}
                    loading={loading}>
                    CONFIRM
                </SaveButton>
            </FlexContainer>
            <ErrorModal
                show={showErrorPopup}
                popupTitle={'Return Receive'}
                popupMessage={getErrorMessage('repair')}
                onCancel={() => hideErrorPopup()} />
        </Modal>

    );
}

export default ReturnExchangeRmaInputModal;

ReturnExchangeRmaInputModal.propTypes = {
    lineItemId: PropTypes.number,
    rgaId: PropTypes.number,
    type: PropTypes.string,
    orderId: PropTypes.string,
    poNum: PropTypes.string,
    previousRmaNumber: PropTypes.string,
    show: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    isDirectShip: PropTypes.bool,
    rmaNumberRequired: PropTypes.bool,
    returnQty: PropTypes.number,
    rgaNumber: PropTypes.string,
};

