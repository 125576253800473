import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Loader} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import 'components/css/reportingportal.css';
import {useSelector} from 'react-redux';
import {logEvent} from 'utility';
import {models} from 'powerbi-client';
import {PowerBIEmbed} from 'powerbi-client-react';
import {getRootCompanyConditions, buildAdvancedFilter, buildBasicFilter, getFacilitiesName} from 'components/PowerBi/PowerBiUtils';
import PowerBiApiService from 'components/PowerBi/PowerBiApiService';
import _ from 'lodash';
import {BasicBIReportOperatorEnum} from 'utils/enums';

const LoaderContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 450px;
`;

const Error = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: default;

    h2 {
        text-align: center;
        font-weight: 300;
        font-size: 50px;
        font-family: 'Source Sans Pro';
    }

    h3 {
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        font-family: 'Source Sans Pro';
    }

    img {
        width: 100px;
        height: 100px;
    }
`

const orionPowerBiService = new PowerBiApiService();
const ReportingPortalDashboardItemPowerBi = (props) => {
    const {widget} = props;
    const [embedConfig, setembedConfig] = useState();
    const [embedData, setEmbedData] = useState();
    const [loading, setLoading] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const userId = useSelector(state => state.user.userId);
    const rootCompanyId = useSelector(state => state.user.companyId);
    const facilities = useSelector(state => state.settings.facilities);

    const embedConfigSettings = {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        layoutType: models.LayoutType.Custom,
        customLayout: {
            displayOption: models.DisplayOption.FitToWidth
        }
    }

    const getEmbedConfig = () => {
        return {
            type: 'report',
            tokenType: models.TokenType.Embed,
            accessToken: embedData?.token,
            embedUrl: embedData?.embedUrl,
            pageView: 'fitToWidth',
            settings: {...embedConfigSettings},
            pageName: widget.widgetPageId,
            filters: !_.isNil(rootCompanyId) ? getFacilityFilter() : []
        }
    }
    
    const goToWidget = () => {
        if (widget.widgetPageId) {
            logEvent('WIDGET CLICK', {
                UserId: userId,
                ReportId: widget.reportId,
                WidgetDrillDownId: widget.reportPageId,
                WidgetName: widget.name,
            });
            
            window.open('/portal-insights/' + `${widget.reportPageId}`, '_blank');
        }
    }

    const getFacilityFilter = () => {
        const facilityNames = getFacilitiesName(facilities);
        return rootCompanyId ? [
            buildAdvancedFilter('Vendors', 'COMPANY_ID', getRootCompanyConditions(rootCompanyId), 'And'),
            buildBasicFilter('Vendors', 'COMPANY_NAME', BasicBIReportOperatorEnum.IN, facilityNames)
        ] : [];
    }

    const widgetRenderedEvent = () => {
        setLoading(false);
    }

    useEffect(() => { 
        if (widget) {
            getWorkspaceByWidget();
        }
    }, [widget]);

    useEffect(() => {
        const embedConfig = !_.isNil(embedData) ? 
            {
                ...getEmbedConfig(),
            }
            : null;
  
        if (_.isNil(embedData)) {
            setLoading(true);
        } else if (!_.isNil(embedData) && embedData?.error) {
            setLoading(false)
            setShowErrorMessage(true);
        } else {
            setembedConfig(embedConfig ? {...embedConfig} : embedConfig);
            setLoading(false);
        }
    }, [embedData]);

    const getWorkspaceByWidget = async () => {
        try {
            const {data} = await orionPowerBiService.getWorkspaceEmbeddedData({
                reportId: widget.reportId, 
                workspaceId: widget.reportWorkspaceId, 
                datasetWorkspaceId: widget.datasetWorkspaceId
            });
            const reportData = data.reportData;
            setEmbedData({...reportData, error: data?.error});
        } catch (e) {
            console.error(e);
            setLoading(false);
            setShowErrorMessage(true);
        }
    }
      
    return (
        <>
            <div id="widget-powerbi-container">
                {
                    loading && <LoaderContainer>
                        <Loader />
                    </LoaderContainer>
                }
                {
                    !_.isNil(embedConfig) && !loading && (
                        <div id="widget-embed-container">
                            <PowerBIEmbed
                                embedConfig = {embedConfig}
                                cssClassName = "widget-power-bi-report"
                                eventHandlers = {
                                    new Map([
                                        ['buttonClicked', () => {
                                            goToWidget()
                                        }],
                                        ['visualClicked', () => {
                                            goToWidget()
                                        }],
                                        ['rendered', () => {
                                            widgetRenderedEvent()
                                        }]
                                    ])
                                }
                            />
                        </div>
                    )
                }
                {showErrorMessage && <Error>
                    <img src="/images/icn_exclamation@x2.png" />
                    <h2>Oops</h2>
                    <h3>Something went wrong with displaying this widget.</h3>
                </Error>}
            </div>
        </>
    );
}

ReportingPortalDashboardItemPowerBi.propTypes = {
    widget: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        reportId: PropTypes.string,
        reportWorkspaceId: PropTypes.string,
        widgetPageId: PropTypes.string,
        reportPageId: PropTypes.string,
        datasetWorkspaceId: PropTypes.string,
    }).isRequired
};

export default ReportingPortalDashboardItemPowerBi;
