import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash'

import {Button, TextField, Dropdown, theme} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import {validateWholeNumbers, validateMoney} from 'utils/validation';

const CustomTextField = styled(TextField)`
   max-width: 20em;   
`;

const Row = styled.div`
    margin-bottom: 1rem;
`;

const ButtonContainer = styled(Row)`
    width: 320px;        
    margin-left: auto;
    margin-right: auto;    
`;

const Container = styled.div`
    width: 100%;    
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const HeaderContainer = styled(Container)`
    width: 320px;        
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
     
    @media (max-width: ${theme.screenXsMax}) {
        padding-left: 20px;
    }
`;

const DropdownList = styled(Dropdown)`
    width: 20em;    

    div {
        span {
            font-weight: 700;
            font-size: 12px;
            color: #005DA6;
            margin: 4px 10px 4px 10px;
        }
    }
`;

const BidLoanerRepairDetails = (props) => {

    const {
        repair = {},        
        onContinue,
        conditionCodes, 
        saveProgress,   
    } = props;
    
    const listData = useSelector(state => state.bids.listData);    
    const [currentRepair, setCurrentRepair] = useState(repair);
    const [isValid, setIsValid] = useState(true);
    const [errors, setErrors] = useState([]);    
    const [hazMatTypeList] = useState([{id: 0, value: 'None'}, {id: 1, value: 'Hazmat'}, {id: 2, value: 'Li-Ion'}]);
    useEffect(() => {
        
        setCurrentRepair(repair);
    }, [repair]);

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (validateRepair()) {
            if (currentRepair.leadDays === 0)
                currentRepair.leadDays = null;            
            if (currentRepair.weight === 0)
                currentRepair.weight = null;
            onContinue(currentRepair);
        }
    };
    
    const onPropertyChange = ({target: {name, value}}) => {
        let newRepair = _.clone(currentRepair);
        newRepair[name] = value;
        setCurrentRepair(newRepair);
        saveProgress(newRepair);
    }

    function validateRepair() {
        
        let conditionsValid = true;
        let currentErrors = [...errors];

        currentErrors.forEach((item, index) => {
            currentErrors[index] = {};
        });
        
        if (Number(currentRepair?.maximumPrice) !== 0 && !validateMoney(currentRepair.maximumPrice)) {
            
            conditionsValid = false;
            currentErrors.maximumPrice = 'Invalid maximum fee';
           
        }

        if (!validateWholeNumbers(currentRepair.warrantyId)) {
            conditionsValid = false;
            currentErrors.warrantyId = 'Invalid warranty';
        }
                
        if (!validateWholeNumbers(currentRepair.leadDays, true)) {
            conditionsValid = false;
            currentErrors.leadDays = 'Invalid Lead Days';
        }

        if (currentRepair.leadDays < 0 || currentRepair.leadDays > 365) {
            conditionsValid = false;
            currentErrors.leadDays = 'Lead Days must be between 0 and 365';
        }

        if (isNaN(currentRepair.weight) || Number(currentRepair.weight) === '' ||
            Number(currentRepair.weight) === 0) {
            conditionsValid = false;
            currentErrors.weight = 'Invalid weight';
        }

        if (currentRepair.conditionId === '') {
            conditionsValid = false;
            currentErrors.conditionId = 'Invalid condition type';
        } 

        if (!currentRepair.hazmatTypeId) {
            conditionsValid = false;
            currentErrors.hazmatTypeId = 'Invalid hazmat type';
        } 
        
        setIsValid(conditionsValid);
        setErrors(currentErrors);
        
        return conditionsValid;
    }
    
    const handleOnCurrencyKeyDown = (event) => {

        const keyCode = event.keyCode;
        const value = event.target.value;
        const allowedKeyCodes = [8, 9, 37, 39, 46, 110, 188, 190]

        const isDecimal = (event.key.toString().match(/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/) ? true : false);
        const isMatch = (value.match(/(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*))?(\.[0-9]{1,2})?$/) ? true : false);

        const isDollarSign = keyCode === 52;
        const alreadyHasDollar = value.includes('$');
        const lastCharWasDollar = value[0] !== '$' && value[value.length - 1] === '$';
        const lastCharWasPointOrComma = value[value.length - 1] === '.' || value[value.length - 1] === ',';

        if (!isDecimal &&
            ((isDollarSign && alreadyHasDollar) || (isDollarSign && lastCharWasPointOrComma) ||
                (!isDollarSign && !allowedKeyCodes.includes(keyCode))) ||
            (isDecimal && lastCharWasDollar)) {
            event.preventDefault();
        }

        return allowedKeyCodes.includes(keyCode) ? true : (isNaN(Number(event.key)) || isMatch);
    }
   
    const handleOnLeadDaysKeyDown = event => {
        const keyCode = event.keyCode;
        const allowedKeyCodes = [8, 9, 37, 39, 46];
        if ((keyCode >= 107 && keyCode <= 111) || (keyCode >= 187 && keyCode <= 190)) {
            event.preventDefault();
        }

        return allowedKeyCodes.includes(keyCode) ? true : (isNaN(Number(event.key)));
    }

    const handleOnWeightKeyDown = (event) => {

        const keyCode = event.keyCode;
        const allowedKeyCodes = [8, 9, 37, 39, 46];
        const notAllowedKeyCodes = [107, 108, 109, 111, 187, 188, 189];

        if (notAllowedKeyCodes.includes(keyCode)) {
            event.preventDefault();
        }

        return allowedKeyCodes.includes(keyCode) ? true : (isNaN(Number(event.key)));
    }
    
    const renderDetails = () => {
        return (
            <React.Fragment>
                <HeaderContainer>
                    <Row>
                        <DropdownList label="Condition Type" name={'conditionId'}
                            options={conditionCodes} selectedValue={currentRepair?.conditionId}
                            showErrorMessage={!isValid && errors.conditionId !== undefined}
                            errorMessage={errors.conditionId}
                            suppressBlankOption={false} valueField="id" textField="description"
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>  
                    <Row>
                        <DropdownList label="Hazmat Type" 
                            name={'hazmatTypeId'}
                            options={hazMatTypeList} 
                            selectedValue={currentRepair?.hazmatTypeId}
                            suppressBlankOption={false} 
                            errorMessage={errors.hazmatTypeId}
                            showErrorMessage={!isValid && errors.hazmatTypeId !== undefined}
                            valueField="id" 
                            textField="value"
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>                  
                    <Row>
                        <CustomTextField
                            label={'Minimum Fee (optional)'}
                            type="text"
                            text={currentRepair?.minimumFee}
                            showErrorMessage={!isValid && errors.minimumFee !== undefined}
                            errorMessage={errors.minimumFee}
                            name={'minimumFee'}
                            onKeyDown={handleOnCurrencyKeyDown}
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>
                    <Row>
                        <CustomTextField
                            label={'Maximum Price (optional)'}
                            text={currentRepair?.maximumPrice}
                            name={'maximumPrice'}
                            showErrorMessage={!isValid && errors.maximumPrice !== undefined}
                            errorMessage={errors.maximumPrice}
                            onKeyDown={handleOnCurrencyKeyDown}
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>     
                    <Row>
                        <DropdownList label="Warranty"
                            options={listData?.warranties} selectedValue={currentRepair?.warrantyId}
                            showErrorMessage={!isValid && errors.warrantyId !== undefined}
                            errorMessage={errors.warrantyId}
                            name={'warrantyId'}
                            suppressBlankOption={false} valueField="id" textField="description"
                            onChange={(e) => onPropertyChange(e)}
                        />

                    </Row>               
                    <Row>
                        <CustomTextField
                            label={'Lead Days'}
                            text={currentRepair?.leadDays}
                            showErrorMessage={!isValid && errors.leadDays != null}
                            errorMessage={errors.leadDays}
                            type="number"
                            min="0"
                            max="365"
                            name={'leadDays'}
                            onKeyDown={handleOnLeadDaysKeyDown}
                            onBlur={(e) => onPropertyChange(e)}
                        />
                    </Row> 
                    <Row>
                        <CustomTextField
                            label={'Weight (lbs)'}
                            type="number"
                            min="0.01"
                            text={currentRepair?.weight}
                            showErrorMessage={!isValid && errors.weight !== undefined}
                            errorMessage={errors.weight}
                            name={'weight'}
                            onKeyDown={handleOnWeightKeyDown}
                            onBlur={(e) => onPropertyChange(e)}
                        />
                    </Row>
                    <Row>
                        <CustomTextField
                            label={'Notes (optional)'}
                            multiLine={true}
                            rows={2}
                            text={currentRepair?.notes}
                            placeholder="New Note"
                            name={'notes'}
                            limit={120}
                            onKeyDown={handleOnLeadDaysKeyDown}
                            onBlur={(e) => onPropertyChange(e)}
                        />
                    </Row>                                      
                </HeaderContainer>
                <ButtonContainer>
                    <Button style={{float: 'right'}} className="primary" type="button" 
                        onClick={(e) => handleOnSubmit(e)}>Continue</Button>                    
                </ButtonContainer>
            </React.Fragment>
        )
    }

    return (
        <Container>
            {renderDetails()}                        
        </Container>
    );
};

export default BidLoanerRepairDetails;

BidLoanerRepairDetails.propTypes = {
    bid: PropTypes.shape({}),
    repair: PropTypes.shape({}),    
    conditionCodes: PropTypes.oneOfType([PropTypes.shape([]), PropTypes.array]),    
    onContinue: PropTypes.func.isRequired,
    saveProgress: PropTypes.func.isRequired,
};
