import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ShippingHeader from 'components/ShippingHeader';
import {HeaderRow} from 'components/DetailsStyledComponents';
import moment from 'moment';

const Row = styled.div`
    margin-bottom: 1rem;
`;

const Subtitle = styled.div`
    font-size: 16px;
    float: left;
`;

const LeftColumn = styled.div`
    display: inline-block;
    vertical-align: text-top;
    width: 20%;
    margin-right: 20px;

    @media (min-width: 961px) and (max-width: 1136px) {
        width: 30%;
    }

    @media (min-width: 820px) and (max-width: 960px) {
        width: 40%;
    }

    @media (max-width: 819px) {
        width: 100%;
    }
`;

const RightColumn = styled.div`
    vertical-align: text-top;
    display: inline-flex;
    flex-direction: column;
    float: right;
    width: 70%;

    @media (min-width: 961px) and (max-width: 1136px) {
        width: 40%;
    }

    @media (min-width: 820px) and (max-width: 960px) {
        width: 50%;
    }

    @media (max-width: 819px) {
        width: 100%;
    }
`;

const BoldSubtitle = styled(Subtitle)`
    font-weight: 600;
    padding-left: 5px;
`;

const OrderHeader = (props) => {
    const {
        order = {},
    } = props;
        
    return (
        <div className="row" style={{paddingLeft: '20px'}}>               
            <LeftColumn>
                <Row className="row">                    
                    <HeaderRow className="col-11">
                        <Subtitle>Create Date: </Subtitle><BoldSubtitle>{moment(order?.dateEntered).format('MM/D/YYYY')}</BoldSubtitle>
                    </HeaderRow>                    
                    <HeaderRow className="col-11">
                        <Subtitle>Vendor Order #: </Subtitle><BoldSubtitle>{order?.vendorOrderNumber}</BoldSubtitle>
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>Company: </Subtitle><BoldSubtitle>{order?.company}</BoldSubtitle>
                    </HeaderRow>   
                </Row>
            </LeftColumn>
            <RightColumn>
                <ShippingHeader order={order} />                    
            </RightColumn>
        </div>
    );
};

export default OrderHeader;

OrderHeader.propTypes = {
    order: PropTypes.shape({}),
};
