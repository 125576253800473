import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import * as PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Security} from '@okta/okta-react';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {setIsLoggingOut} from './../reducers/user';
import {oktaSignInConfig, oktaAuthConfig} from './oktaConfig';
import AuthHandler from './../components/AuthHandler';
import '@okta/okta-auth-js/polyfill';
import CustomerLayout from '../components/CustomerLayout';
import AdminLayout from '../components/AdminLayout';
const newOktaAuth = new OktaAuth(oktaAuthConfig);

function Loading(props) {
    const {error} = props;

    if (error && location.hostname !== 'localhost') {
        location.reload(true);
    }
    return null;
}

Loading.propTypes = {
    error: PropTypes.string,
};

const MasterLayout = () => {
    const dispatch = useDispatch();
    const history = useHistory();        
    const isEmployee = useSelector(state => state.user.isEmployee);
    const [triggerLogout, setTriggerLogout] = useState(false);
    
    const customAuthHandler = () => {
        history.push('/login');
    };

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        if (originalUri)
            history.replace(toRelativeUrl(originalUri, window.location.origin))
    };

    const updateTriggerLogout = async (value) => {
        dispatch(setIsLoggingOut(value));
        setTriggerLogout(value);
    }    
    
    return (
        <Security   
            oktaAuth={newOktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
        >
            {/^\/admin/.test(location.pathname) && isEmployee ?
                <AdminLayout oktaSignInConfig={oktaSignInConfig} updateTriggerLogout={updateTriggerLogout} /> :
                <CustomerLayout oktaSignInConfig={oktaSignInConfig} updateTriggerLogout={updateTriggerLogout} />}

            <AuthHandler triggerLogout={triggerLogout} updateTriggerLogout={updateTriggerLogout} />
        </Security>
    );
};

export default MasterLayout;
