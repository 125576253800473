import React from 'react';
import * as PropTypes from 'prop-types';

const LoanersAndRentalsCardDescription = (props) => {
    const {cardData} = props;

    return (
        <>
            <span className="detail">
                Order Type: <span>{cardData.orderType}</span>
            </span>

            <span className="detail">
                Serial #: <span stype="word-wrap:break-word;">{cardData.serialNumber}</span>
            </span>

            <span className="detail">
                Ref #: <span>{cardData.lineItemId}</span>
            </span>

            <span className="detail">
                Item #: <span>{cardData.partNumber}</span>
            </span>

            <span className="detail">
                Facility: <span>{cardData.facility}</span>
            </span>

            <span className="detail">
                Requestor: <span>{cardData.requestor}</span>
            </span>
        </>);
}

LoanersAndRentalsCardDescription.propTypes = {
    cardData: PropTypes.shape({
        facility: PropTypes.string,
        lineItemId: PropTypes.number,
        manufacturer: PropTypes.string,
        nonReturnable: PropTypes.bool,
        orderType: PropTypes.string,
        partNumber: PropTypes.string,
        quantity: PropTypes.number,
        requestor: PropTypes.string,
        serialNumber: PropTypes.string,
        warranty: PropTypes.string,
    }),
    showCompanyInDescription: PropTypes.bool,
}

export default LoanersAndRentalsCardDescription;
