import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Popup} from '@partssourceinc/react-ui-core';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import styled from 'styled-components';

const AsyncMultiSelect = styled(AsyncSelect)`
    margin-top: 20px;
    .select__control {
        min-height: 50px;
        border: 1px solid #949494;
        border-radius: 3px;
   }
`;

function RPAssignCompanyDialog({onCancel, onConfirm}) {
    const [companies, setCompanies] = useState([]);

    const onSave = () => {
        const companyList = companies.map(company => ({
            companyId: parseInt(company.facilityId),
            companyName: company.facilityName.replace(`${company.facilityId} - `, '')
        }));

        onConfirm(companyList);
    };

    const getCompanies = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }

        axios.get(`${process.env.REACT_APP_ORION_API_ROUTE_URL}/SettingsService/api/v1/facility/search/${inputValue}/true`)
            .then(response => {
                return callback(response.data);
            })
            .catch(error => {
                console.error('Error fetching companies:', error);
                return callback([]);
            });
    };

    const onCompanyChange = (values) => {
        setCompanies(values);
    };

    return (
        <Popup
            className="privacy-pill-dialog"
            show={true}
            disableClose={false}
            onCancel={onCancel}
            onConfirm={onSave}
            cancelText="CANCEL"
            disableConfirm={!(companies && companies.length > 0)}
            confirmText="SAVE"
        >
            <div>
                <h1 style={{fontSize: '24px'}}>Assign Access By Supplier</h1>
                <div className="create-advanced-list" style={{alignItems: 'baseline', marginTop: '20px'}}>
                    <div>
                        <span className="list-item-description">Portal that can be seen by all users belonging to the selected root suppliers</span>
                    </div>
                </div>
                <AsyncMultiSelect 
                    isMulti={true}
                    cacheOptions={true}
                    loadOptions={getCompanies}
                    getOptionValue={option => option.facilityId}
                    getOptionLabel={option => option.facilityName}
                    noOptionsMessage={() => null}
                    placeholder="Suppliers"
                    isClearable={true}
                    classNamePrefix="select"
                    value={companies}
                    onChange={onCompanyChange}
                    className={'custom-multi-select'}
                />
            </div>
        </Popup>
    );
}

RPAssignCompanyDialog.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default RPAssignCompanyDialog;
