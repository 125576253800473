import React from 'react';
import * as PropTypes from 'prop-types';

const OrdersCardDescription = (props) => {
    const {cardData, showCompanyInDescription} = props;

    return (
        <>
            <span className="detail">
                Qty: <span>{cardData.quantity}</span>
            </span>

            {cardData.status === 'PENDINGSHIPMENT' ||
                cardData.status === 'SHIPPED' ||
                cardData.status === 'DELIVERED' ?
                null :
                <span className="detail">
                    OEM: <span>{cardData.manufacturer}</span>
                </span>
            }

            <span className="detail">
                Item #: <span>{cardData.partNumber}</span>
            </span>

            <span className="detail">
                Condition: <span>{cardData.conditionDescription}</span>
            </span>

            <span className="detail">
                PO#: <span>{cardData.displayPo}</span>
            </span>

            <span className="detail">
                Vendor Order #: <span>{cardData.vendorOrderNumber}</span>
            </span>

            <span className="detail">
                Notes: <span>{cardData.notesCount ?? 0}</span>
            </span>

            {showCompanyInDescription &&
                <span className="detail">
                    Company: <span>{cardData.company}</span>
                </span>
            }
        </>);
}

OrdersCardDescription.propTypes = {
    cardData: PropTypes.shape({
        manufacturer: PropTypes.string,
        partNumber: PropTypes.string,
        status: PropTypes.string,
        quantity: PropTypes.number,
        conditionDescription: PropTypes.string,
        displayPo: PropTypes.string,
        company: PropTypes.string,
        vendorOrderNumber: PropTypes.string,
        notesCount: PropTypes.number,
    }),
    showCompanyInDescription: PropTypes.bool,
}

export default OrdersCardDescription;
