import React, {useEffect, useState} from 'react'
import * as PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import UnauthorizedUrlDialog from './UnauthorizedUrlDialog';
import {useSelector} from 'react-redux';

const UnauthorizedWrapper = ({isUnauthorized, component}) => {
    const [redirectTimeout, setRedirectTimeout] = useState(null);
    const isLoading = useSelector(state => state.settings.loadingCompaniesSettings);
    const history = useHistory();

    useEffect(() => {
        redirectIfUnauthorized();
    }, [isUnauthorized && isLoading]);

    useEffect(() => {
        if (!isUnauthorized) {
            clearTimeout(redirectTimeout)
        }
    }, [isUnauthorized])

    const redirectIfUnauthorized = () => {
        let timeout = setTimeout(() => {
            if (isUnauthorized && !isLoading) {
                history.push('/overview');
            }
        }, 10000);
        if (!redirectTimeout)
            setRedirectTimeout(timeout);
    }

    const onRedirect = () => {
        clearTimeout(redirectTimeout);
        history.push('/overview');
    }

    return (
        <>
            <div>
                <UnauthorizedUrlDialog show={isUnauthorized && !isLoading}
                    onRedirect={onRedirect} />
            </div>
            {(!isUnauthorized && !isLoading) && (component)}
        </>);
}

export default UnauthorizedWrapper;

UnauthorizedWrapper.propTypes = {
    isUnauthorized: PropTypes.bool.isRequired,
    component: PropTypes.object.isRequired,
};

