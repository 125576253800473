import styled, {createGlobalStyle, css} from 'styled-components';
import {theme as baseTheme, brandFont, lightBrandFont} from '@partssourceinc/react-ui-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NavLink} from 'react-router-dom';

export const theme = {
    ...baseTheme,
    screenLGMin: '1200px',
    screenMDMax: '1199px',
}

export const SupplierProStyles = createGlobalStyle`
    * {
        font-family: 'Source Sans Pro', sans-serif;
    }

    html {
        overflow: hidden;
    }

    html, body {
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        font-size: 14px;
        line-height: 18px;
        color: #000;
        font-family: "Source Sans Pro,sans-serif";
        overscroll-behavior: none;
    }

    html {
        &.modal-popup{
            overflow: hidden !important;
        }
    }

    body {
        &.modal-popup {
            width: 100vw;
            overflow: hidden !important;
        }
    }

    main {
        flex: 1 0 auto !important;
    }

    #root {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    h1 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 30px;
        font-weight: 300;
    }

    h2 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 24px;
        font-weight: 300;
    }

    h3 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 20px;
        font-weight: 400;
    }

    .p-datepicker {
        z-index: 10001!Important;
        display: block!Important;
    }

    .p-button {
        background-color: #EC8000!Important;
        border: 1px solid #EC8000!Important;
    }

    @media (max-width: 1149px) {
        h1 {
            font-size: 26px;
        }

        h2 {
            font-size: 22px;
        }

        h3 {
            font-size: 18px;
            line-height: 22px;
            font-weight: 600;
        }
    }
`;

export const SubTitle = styled.span`
    ${brandFont('16px')}

    ${props => props.limitWidth && css`
        @media (min-width: 1150px) {
            max-width: 50%;
        }
    `}
`;

export const BackLink = styled(NavLink)`
    color: ${theme.navMenuGoBackColor};
    ${lightBrandFont('14px')};
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    margin-bottom: 15px;

    &:hover {
        color: ${theme.navMenuGoBackColor};
        text-decoration: none;
    }
`;

export const LeftArrow = styled(FontAwesomeIcon)`
    width: 15px;
    height: 15px;
    margin-right: 13px;
    vertical-align: middle;
    color: ${theme.navMenuArrowColor}
`;

function getMediaQuery(min, max) {
    let minWidth;
    switch (min) {
        case 'xl':
            minWidth = theme.screenXLMin;
            break;
        case 'lg':
            minWidth = theme.screenLGMin;
            break;
        case 'md':
            minWidth = theme.screenMDMin;
            break;
        case 'sm':
            minWidth = theme.screenSMMin;
            break;
    }

    let maxWidth;
    switch (max) {
        case 'lg':
            maxWidth = theme.screenLGMax;
            break;
        case 'md':
            maxWidth = theme.screenMDMax;
            break;
        case 'sm':
            maxWidth = theme.screenSMMax;
            break;
        case 'xs':
            maxWidth = theme.screenXsMax;
            break;
    }

    if (minWidth && maxWidth) {
        return css`
            @media (min-width: ${minWidth}) and (max-width: ${maxWidth}) {
                display: block;
            }
        `;
    } else if (minWidth) {
        return css`
            @media (min-width: ${minWidth}) {
                display: block;
            }
        `;
    } else if (maxWidth) {
        return css`
            @media (max-width: ${maxWidth}) {
                display: block;
            }
        `;
    } else {
        return css``;
    }
}

export const responsive = ({min, max}) => css`
    display: none;
    ${getMediaQuery(min, max)};
`;
