import companies from './companies';
import configuration from './configuration';
import settings from './settings';
import user from './user';
import supplier from './supplier';
import bids from './bids';
import orders from './orders';
import attachments from './attachments';
import common from './common';
import {combineReducers} from 'redux';

const appReducer = combineReducers({
    companies,
    configuration,
    settings,
    user,
    supplier,
    bids,
    orders,
    attachments,
    common,
});
  
const reducers = (state, action) => {
    if (action.type === 'LOGOUT_USER') {
        return appReducer({
            companies: undefined,
            configuration: state.configuration,
            settings: undefined,
            user: state.user,
            supplier: undefined,
            bids: undefined,
            orders: undefined,
            attachments: undefined,
            common: undefined,
        }, action);
    } else if (action.type === 'STOP_IMPERSONATION') {
        return appReducer({
            companies: undefined,
            configuration: state.configuration,
            settings: undefined,
            user: state.user,
            supplier: undefined,
            bids: undefined,
            orders: undefined,
            attachments: undefined,
            common: undefined,
        }, action);
    }
  
    return appReducer(state, action);
}

export default reducers;
