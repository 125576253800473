import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {TextField, Button, boldBrandFont} from '@partssourceinc/react-ui-core';
import axios from 'axios';
import {validateEmail, validatePhoneNumber} from 'utils/validation';
import HubSpotSubmitRequest from 'components/HubSpotSubmitRequest';
import ErrorModal from 'components/ErrorModal';
import {theme, Checkbox} from '@partssourceinc/react-ui-core';

const Wrapper = styled.div`
    max-width: 495px;
    padding: 48px 0 96px 0;
    margin: 0 auto;
`;

const TopWrapper = styled.div`
    text-align: center;
`;
const IconHolder = styled.div`
    margin-bottom: 16px;

    img {
        vertical-align: middle;
    }

    @media (max-width: ${theme.screenXsMax}) {
        display: none;
    }
`;

const Title = styled.h1`
    margin-bottom: 16px;
`;

const Subtitle = styled.div `
    font-size: 16px;
    margin-bottom: 30px;
`;

const SignInWrapper = styled.div `
    text-align: center;
    margin-top: 16px;
    font-size: 16px;

    span {
        margin-right: 5px;
    }

    a {
        ${boldBrandFont('16px')}
        color: #005da6;
    }
`;

const RegistrationForm = styled.form`
    display: flex;
    flex-wrap: wrap;
`;

const TextBox = styled(TextField)`
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 16px;

    span {
        ${boldBrandFont('10px')};
    }

    ${props => props.multiLine && css`
        > div {
            height: 200px;
        }
    `}

    @media (max-width: 505px) {
        flex: 0 0 100%;
        max-width: 100%;

        ${props => props.fieldType === 'number' && css`
            flex: 0 0 47%;
            max-width: 47%;
        `}
    }

    ${props => props.fieldType === 'number' && css`
        min-width: 130px;
    `}
`;

const ButtonBar = styled.div`
    padding: 16px 0 24px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 5px;
    position: relative;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    @media (max-width: ${theme.screenXsMax}) {
        button {
            width: 355px;
            max-width: 100%;
        }

        justify-content: center;
    }
`;

const Label = styled.p`
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 38px;
    clear: both;
`;

const AreaOfInterestContainer = styled.div`
    width: 100%;
    padding-bottom: 33px;
`;

const SupplierRegistration = () => {

    const [formValues, setFormValues] = useState({
        firstName: {
            required: true,
            value: '',
            errors: [],
            maxLenght: 25,
            wasTouched: false,
        },
        lastName: {
            required: true,
            value: '',
            errors: [],
            maxLenght: 30,
            wasTouched: false,
        },
        email: {
            required: true,
            value: '',
            errors: [],
            maxLenght: 65,
            wasTouched: false,
        },
        phoneNumber: {
            required: true,
            value: '',
            errors: [],
            maxLenght: 15,
            wasTouched: false,
        },
        ext: {
            required: false,
            value: '',
            errors: [],
            maxLenght: 10,
            wasTouched: false,
        },
        companyName: {
            required: true,
            value: '',
            errors: [],
            maxLenght: 70,
            wasTouched: false,
        },
        companyWebSite: {
            required: true,
            value: '',
            errors: [],
            maxLenght: 100,
            wasTouched: false,
        },
        comments: {
            required: false,
            value: '',
            errors: [],
            maxLenght: 1000,
            wasTouched: false,
        },
        partOrder: {
            required: false,
            value: false,
            errors: [],
            wasTouched: false,
        },
        repair: {
            required: false,
            value: false,
            errors: [],
            wasTouched: false,
        },
        bid: {
            required: false,
            value: false,
            errors: [],
            wasTouched: false,
        },
        vendorDiscrepancyForm: {
            required: false,
            value: false,
            errors: [],
            wasTouched: false,
        },
    });

    const [isLoading, setIsLoading] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    const submitForm = () => {
        setIsLoading(true);
        const registrationValues = {
            firstName: formValues.firstName.value,
            lastName: formValues.lastName.value,
            companyEmail: formValues.email.value,
            phoneNumber: formValues.phoneNumber.value,
            ext: formValues.ext.value,
            companyName: formValues.companyName.value,
            companyWebSite: formValues.companyWebSite.value,
            comments: formValues.comments.value,
            areaOfInterests: [
                {key: 'partorders', value: formValues.partOrder.value},
                {key: 'repairs', value: formValues.repair.value},
                {key: 'bids', value: formValues.bid.value},
                {key: 'vdf', value: formValues.vendorDiscrepancyForm.value},
            ],
        }

        axios.post(`/user/registration`,
            registrationValues)
            .then(() => {
                setIsLoading(false);
                setViewResponse(true)
            })
            .catch(() => {
                setIsLoading(false);
                setShowErrorPopup(true);
            })
    }

    const [viewResponse, setViewResponse] = useState(false);

    const handleOnChange = (e) => {
        const value = e.target.value;
        let clone = {...formValues};
        clone[e.target.name].value = value;
        clone[e.target.name].wasTouched = true;
        setFormValues(clone);
    }

    const showErrorMessage = (propertyName) => {
        if (formValues[propertyName].required && (formValues[propertyName].wasTouched && 
            (!formValues[propertyName].value || formValues[propertyName].errors.length)))
            return true;

        return false;
    }

    const handleOnExtKeyDown = event => {
        const key = event.key;
            
        if (!/^[a-zA-Z0-9 ]+|[\b]+$/.test(key)) {
            event.preventDefault();
        }
    }

    const handleCompanyWebSiteKeyDown = event => {
        const key = event.key;
            
        if (!/^[a-zA-Z0-9\\/._:=-]+|[\b]+$/.test(key)) {
            event.preventDefault();
        }
    }

    const handleCheckboxChange = (e) => {
        const checked = e.checked
        let clone = {...formValues};
        clone[e.name].value = checked;
        clone[e.name].wasTouched = true;
        setFormValues(clone);
    }

    const shouldDisableButton = () => {
        const array = Object.keys(formValues).map(function (key) { 
            return formValues[key]; 
        });

        return array.some(c => c.required && !c.value) || !validateEmail(formValues.email.value) || 
            !validatePhoneNumber(formValues.phoneNumber.value);
    }
    
    return (
        <Wrapper>
            {!viewResponse &&
            <div>
                <TopWrapper>
                    <IconHolder>
                        <img src={`images/graph_up.png`} />
                    </IconHolder>
                    <Title>Thank you for your interest</Title>
                    <Subtitle>{`Please complete the required fields below to request your supplier account,
                which will give you access to the PartsSource platform and supplier tools.`}</Subtitle>
                </TopWrapper>
                <RegistrationForm onSubmit={submitForm}>
                    <TextBox
                        label="First Name"
                        name="firstName"
                        placeholder="First Name"
                        text={formValues.firstName.value}
                        onChange={handleOnChange}
                        showErrorMessage={showErrorMessage('firstName')}
                        errorMessage="Please complete this required field."
                        className="col-5"
                        limit={formValues.firstName.maxLenght}
                    /><TextBox
                        label="Last Name"
                        name="lastName"
                        placeholder="Last Name"
                        text={formValues.lastName.value}
                        onChange={handleOnChange}
                        showErrorMessage={showErrorMessage('lastName')}
                        errorMessage="Please complete this required field."
                        className="col-7"
                        limit={formValues.lastName.maxLenght}
                    /><TextBox
                        label="Business Email Address"
                        name="email"
                        placeholder="Business Email Address"
                        text={formValues.email.value}
                        onChange={handleOnChange}
                        showErrorMessage={showErrorMessage('email') || (!validateEmail(formValues.email.value) && formValues.email.wasTouched)}
                        errorMessage= {(!validateEmail(formValues.email.value) && formValues.email.wasTouched)
                            ? 'Please enter a valid email address'
                            : 'Please complete this required field.'}
                        className="col-8"
                        limit={formValues.email.maxLenght}
                    /><TextBox
                        label="Phone Number"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        text={formValues.phoneNumber.value}
                        onChange={handleOnChange}
                        showErrorMessage={(formValues.phoneNumber.wasTouched && !validatePhoneNumber(formValues.phoneNumber.value))}
                        errorMessage= {!validatePhoneNumber(formValues.phoneNumber.value)
                            ? 'Please enter a valid phone number'
                            : 'Please complete this required field.'}
                        className="col-6"
                        limit={formValues.phoneNumber.maxLenght}
                    /><TextBox
                        label="Ext. (optional)"
                        name="ext"
                        placeholder="Ext. (optional)"
                        text={formValues.ext.value}
                        onChange={handleOnChange}
                        errorMessage="Please complete this required field."
                        fieldType="number"
                        className="col-3"
                        limit={formValues.ext.maxLenght}
                        onKeyDown={handleOnExtKeyDown}
                    /><TextBox
                        label="Company Name"
                        name="companyName"
                        placeholder="Company Name"
                        text={formValues.companyName.value}
                        onChange={handleOnChange}
                        showErrorMessage={showErrorMessage('companyName')}
                        errorMessage="Please complete this required field."
                        className="col-8"
                        limit={formValues.companyName.maxLenght}
                    /><TextBox
                        label="Company Website"
                        name="companyWebSite"
                        placeholder="Company Website"
                        text={formValues.companyWebSite.value}
                        onChange={handleOnChange}
                        showErrorMessage={showErrorMessage('companyWebSite')}
                        errorMessage="Please complete this required field."
                        className="col-12"
                        limit={formValues.companyWebSite.maxLenght}
                        onKeyDown={handleCompanyWebSiteKeyDown}
                    /><AreaOfInterestContainer>
                        <Label>Area of Interest (Optional):</Label>
                    
                        <Checkbox
                            name={'partOrder'}
                            label={'Part Orders'}
                            checked={formValues.partOrder.value}
                            className="col-8"
                            onChange={handleCheckboxChange}
                        />
                        <Checkbox
                            name={'repair'}
                            label={'Repairs'}
                            checked={formValues.repair.value}
                            className="col-8"
                            onChange={handleCheckboxChange}
                        />
                        <Checkbox
                            name={'bid'}
                            label={'Bids'}
                            checked={formValues.bid.value}
                            className="col-8"
                            onChange={handleCheckboxChange}
                        />
                        <Checkbox
                            name={'vendorDiscrepancyForm'}
                            label={'Vendor Discrepancy Form'}
                            checked={formValues.vendorDiscrepancyForm.value}
                            className="col-8"
                            onChange={handleCheckboxChange}
                        />
                    </AreaOfInterestContainer>
                    <TextBox
                        label="Comments / Questions / Additional Detail (optional)"
                        name="comments"
                        placeholder="Comments / Questions / Additional Detail"
                        text={formValues.comments.value}
                        onChange={handleOnChange}
                        multiLine={true}
                        errorMessage="Please complete this required field."
                        className="col-12"
                        limit={formValues.comments.maxLenght}
                    />
                </RegistrationForm>
                <ButtonBar>
                    <Button loading={isLoading} disabled={shouldDisableButton()} primary={true} 
                        tabIndex={4} type="submit" onClick={submitForm}>
                    Submit
                    </Button>
                </ButtonBar>
                <SignInWrapper>
                    <span>
                    Already have an account?
                    </span>
                    <a href="/login" target="_blank">Sign-In</a>
                </SignInWrapper>
            </div>}
            {viewResponse && <div>
                <HubSpotSubmitRequest />
            </div>}
            <ErrorModal
                show={showErrorPopup}
                popupTitle={'Registration'}
                popupMessage={'Encountered an error, please try again'}
                onCancel={() => setShowErrorPopup(false)} />
        </Wrapper>
    )
}

export default SupplierRegistration;
