import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faAngleDown,
    faSignOutAlt,
    faEyeSlash,
    faMapMarkerAlt,
    faBars,
    faUserAlt,
    faCity,
    faTruck,
    faCog,
    faEdit,
    faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';

export function initLibrary() {
    library.add(
        faAngleDown,
        faSignOutAlt,
        faEyeSlash,
        faMapMarkerAlt,
        faBars,
        faUserAlt,
        faCity,
        faTruck,
        faCog,
        faEdit,
        faPencilAlt,
    );
}
