import {ApplicationInsights} from '@microsoft/applicationinsights-web'
import {ReactPlugin} from '@microsoft/applicationinsights-react-js'

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPINSIGHTS,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        correlationHeaderDomains: ['partsfinder.com', 'partssource.com'],
    },
});
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((envelope) => {
    envelope.tags['ai.cloud.role'] = 'Supplier Pro Browser';
});
export {reactPlugin,appInsights}
