import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar, faExclamationCircle} from '@fortawesome/pro-regular-svg-icons';
import NotesModal from 'components/NotesModal';
import {HeaderRow} from 'components/DetailsStyledComponents';
import {formatMoney, formatUOM} from 'utility';
import {Context} from 'utils/enums';

const Row = styled.div`
    margin-bottom: 1rem;
`;

const Subtitle = styled.div`
    font-size: 16px;
    float: left;
`;

const UnderlineSubtitle = styled(Subtitle)`
    width: 100%;
    border-top-style: solid;
    border-width: 2px;    
    border-color: rgb(204, 204, 204);
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
`;

const BoldSubtitle = styled(Subtitle)`
    font-weight: 600;
    padding-left: 10px;
`;

const BoldSubtitleNoPadding = styled(Subtitle)`
    font-weight: bold;   
`;
const BlueText = styled(BoldSubtitle)`
    color: #166BAD;
    padding-left: 0;
    margin-top: 10px;
    cursor: pointer;
`;
const MOQLabel = styled.div`
    padding-left: 20px;
    margin-top: 5px;
    font-size: 13px;
    padding-top: 60px;
    position: absolute;
    font-weight: 500;
`;

const SmallTitle = styled.div`    
    
    color: #6C6C6C;  
    font-size: 18px;  
    line-height: 25px;  
`;

const LeadTimeLabel = styled.div`    
    
    color: #6C6C6C;    
    font-size: 14px;
    line-height: 75px;
    position:absolute;
`;
const UOMLabel = styled.div`    
    padding-left: 10px;
    font-size: 13px;
    float:right;
    font-weight: 500;
    padding-top:2px;
`;

const Icon = styled(FontAwesomeIcon)`
    float: left;
    color: black;
    margin-top: 14px;
    margin-right: 5px;
    margin-left: 5px;
`;
const ExclamationIcon = styled(FontAwesomeIcon)`
    float: left;
    color: black;
    margin-top: 44px;
    margin-right: 890px;
`;
const PriceTitle = styled.div`    
           
    font-size: 24px;
    font-weight: 400;
    line-height: 25px;
    float:left;
`;

const Container = styled.div`
    width: 100%;    
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const BidCompleted = (props) => {
    const {
        conditions = [],
    } = props;
    const [bidConditions, setBidConditions] = useState([]);
    const [showNotes, setShowNotes] = useState(false);
    const [currentLineItemId, setCurrentLineItemId] = useState(null);
    const [currentConditionId, setCurrentConditionId] = useState(null);

    if (conditions.length > 0 && bidConditions.length === 0)
        setBidConditions(conditions);

    const showNotePopup = (lineItemId, conditionId) => {
        setCurrentLineItemId(lineItemId);
        setCurrentConditionId(conditionId);
        setShowNotes(true);
    }

    const hideNotePopup = () => {
        setShowNotes(false);
        setCurrentLineItemId(null);
        setCurrentConditionId(null);
    }

    const onSubmitNote = (newNote) => {
        setShowNotes(false);

        let data = [...bidConditions];
        data.map((item) => {
            if (item.lineItemId === newNote.lineItemId && item.typeId === newNote.typeId) {
                item.notes.push(newNote);
                return item;
            }
        })

        setBidConditions(data);
    }

    const renderBidCondition = (bidCondition, index) => {
        return (
            <div className="row" key={index} id={`rowDetail${index}`}>
                <div className="col-xs-12 col-sm-6 col-lg-6">
                    <Row className="row">
                        <HeaderRow className="col-11">
                            <BoldSubtitleNoPadding id={`lblConditionType${index}`}>{bidCondition.type}</BoldSubtitleNoPadding>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Subtitle>Replacement Item #: </Subtitle><BoldSubtitle id={`lblReplacementItem${index}`}>
                                {bidCondition?.replacemantPartNumber}</BoldSubtitle>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Subtitle>Warranty: </Subtitle><BoldSubtitle id={`lblWarranty${index}`}>{bidCondition.warrantyDescription}</BoldSubtitle>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Subtitle>Cosmetic Rating: </Subtitle><BoldSubtitle id={`lblCosmeticRating${index}`}>
                                {bidCondition.ratingDescription}</BoldSubtitle>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Subtitle>Weight: </Subtitle><BoldSubtitle id={`lblWeight${index}`}>{bidCondition?.weight}</BoldSubtitle>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Subtitle>Return Status: </Subtitle><BoldSubtitle id={`lblReturnStatus${index}`}>
                                {bidCondition.nonReturnable}</BoldSubtitle>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Icon aria-hidden="true" icon={faCalendar} />
                            <BlueText id={`lblNotes${index}`} onClick={() => showNotePopup(bidCondition?.lineItemId, bidCondition?.conditionId)}>Notes
                                ({bidCondition?.notes?.length}) </BlueText>
                        </HeaderRow>
                    </Row>
                </div>
                <div className="col-xs-12 col-sm-6 col-lg-6">
                    <div className="col-11">
                        <SmallTitle>Outright Price:</SmallTitle>
                    </div>
                    <div className="col-11">
                        <PriceTitle id={`lblOutrightPrice${index}`}>{bidCondition?.isNoBid && bidCondition?.outrightCost === 0 ? 'No Bid' :
                            formatMoney(bidCondition?.outrightCost)} 
                        <UOMLabel id={`lblUOM${index}`}>{formatUOM(bidCondition?.uom,bidCondition?.quantity)} </UOMLabel>                        
                        </PriceTitle>
                    </div>
                    <div className="col-11">
                        <LeadTimeLabel id={`lblLeadTime${index}`}>{bidCondition?.leadDays} Day Lead Time</LeadTimeLabel>
                    </div>
                    {bidCondition?.exchangeCost > 0 &&
                        <React.Fragment>
                            <div className="col-11" style={{marginTop: '70px'}}>
                                <SmallTitle>Exchange Price:</SmallTitle>
                            </div>
                            <div className="col-11">
                                <PriceTitle id={`lblExchangePrice${index}`}>{formatMoney(bidCondition?.exchangeCost)}</PriceTitle>
                            </div>
                        </React.Fragment>
                    }
                    {bidCondition?.moq != null &&
                        <HeaderRow className="col-11">
                            <ExclamationIcon aria-hidden="true" icon={faExclamationCircle} />
                            <MOQLabel id={`lblMOQ${index}`}> This item has a minimum order qty. of {bidCondition?.moq}.</MOQLabel>
                        </HeaderRow>
                    }
                </div>
                <UnderlineSubtitle>&nbsp;</UnderlineSubtitle>
            </div>
        )
    }

    return (
        <Container>
            {bidConditions.length > 0 &&
                (bidConditions).map((bc, index) => renderBidCondition(bc, index))}

            <NotesModal
                show={showNotes}
                context={Context.BID}
                notes={bidConditions.find(x => x.lineItemId === currentLineItemId && x.conditionId === currentConditionId)?.notes}
                conditionId={currentConditionId}
                lineItemId={currentLineItemId}
                onSubmit={onSubmitNote}
                onCancel={() => hideNotePopup()} />

        </Container>
    );
};

export default BidCompleted;

BidCompleted.propTypes = {
    conditions: PropTypes.array,
};
