import React, {useState} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import {TextField, Button} from '@partssourceinc/react-ui-core';
import {Link} from 'react-router-dom';

const Container = styled.div`
    max-width: 300px;
    margin: 0 auto;
`;

const EmailTextField = styled(TextField)`
    margin: 2rem 0 1rem;
`;

const Header = styled.h1`
    margin-bottom: 3rem;
`;

const View = {
    FORM: 0,
    SUCCESS: 1,
    ERROR: 2,
};

const ApiResult = {
    SUCCESS: 0,
    EMAIL_NOT_FOUND: 1,
    MISC_ERROR: 2,
    MULTIPLE_CONTACTS_FOUND: 3,
};

const ForgotUsername = () => {
    const [{view, message}, setView] = useState({view: View.FORM});
    const [email, setEmail] = useState('');
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function handleEmailChange(e) {
        setEmail(e.target.value);
        setIsError(false);
    }

    async function handleFormSubmit(e) {
        e.preventDefault();
        if (!email) {
            setIsError(true);
            return;
        }

        setIsLoading(true);
        try {
            const {data: {result}} = await axios.post(
                `${process.env.REACT_APP_ORION_API_ROUTE_URL}/ShoppingService/api/v1/account/ForgotUsername`,
                {email}
            );
            setIsLoading(false);

            switch (result) {
                case ApiResult.SUCCESS:
                    setView({view: View.SUCCESS});
                    break;
                case ApiResult.EMAIL_NOT_FOUND:
                    setView({
                        view: View.ERROR,
                        message: 'We are sorry, but we do not have that email address on file.',
                    });
                    break;
                case ApiResult.MULTIPLE_CONTACTS_FOUND:
                    setView({
                        view: View.ERROR,
                        message: 'Multiple contacts found for email',
                    });
                    break;
                case ApiResult.MISC_ERROR:
                default:
                    setView({
                        view: View.ERROR,
                        message: 'Something went wrong',
                    });
                    break;
            }
        } catch {
            setIsLoading(false);
            setView({view: View.ERROR, message: 'Something went wrong'});
        }

        return false;
    }

    function resetPage() {
        setEmail('');
        setIsLoading(false);
        setIsError(false);
        setView({view: View.FORM});
    }

    switch (view) {
        case View.SUCCESS:
            return (
                <Container>
                    <Header>We have sent your username to the specified email address.</Header>
                    <Link to="/">Return to Homepage</Link>
                </Container>
            );
        case View.ERROR:
            return (
                <Container>
                    <Header>{message}</Header>
                    <Link to="/forgot-username" onClick={resetPage}>Please try again</Link>
                </Container>
            );
        case View.FORM:
        default:
            return (
                <Container>
                    <Header>Forgot Username</Header>
                    <form onSubmit={handleFormSubmit}>
                        <EmailTextField
                            label="Email"
                            placeholder="Email"
                            showErrorMessage={isError}
                            errorMessage="Email is required"
                            onChange={handleEmailChange}
                        />
                        <Button type="submit" loading={isLoading} secondary={true}>Send</Button>
                    </form>
                </Container>
            );
    }
};

export default ForgotUsername;
