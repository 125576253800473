import React, {useState, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {TextField, Button} from '@partssourceinc/react-ui-core';
import axios from 'axios';
import {saveWidget, editWidget} from './../../reducers/user';
import {useHistory, useParams} from 'react-router-dom';
import 'components/css/adminLists.css';
import 'components/css/advancedLists.css';
import 'components/css/reportingportal.css';

export default function RPWidgetsAdd(props) {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [showErrors, setShowErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setWidgetName] = useState('');
    const [reportId, setReportId] = useState('');
    const [reportWorkspaceId, setReportWorkspaceId] = useState('');
    const [widgetPageId, setwidgetPageId] = useState('');
    const [description, setDescription] = useState('');
    const [reportPageId, setReportPageId] = useState('');
    const [isEditMode] = useState(id != null && id !== undefined);
    const [showDuplicateErrorMessage, setShowDuplicateErrorMessage] = useState(false);

    const [widget, setWidget] = useState({});

    const allWidgets = useSelector(state => state.user.widgets);

    useEffect(() => {
        getWidgetDetails();
    }, []);

    async function getWidgetDetails() {
        if (isEditMode) {
            const {data} = await axios.get(`reporting-portal/widgets/${id}`);
            const {name, reportId, reportWorkspaceId, widgetPageId, description, reportPageId} = data;
            setWidgetName(name);
            setReportId(reportId);
            setReportWorkspaceId(reportWorkspaceId);
            setwidgetPageId(widgetPageId)
            setDescription(description);
            setReportPageId(reportPageId);
            setWidget(data);
        }
    }

    const handleOnSave = useCallback(() => {
        if (!name || !reportId || !reportWorkspaceId || !widgetPageId || !reportPageId) {
            setShowErrors(true);
            return;
        }

        let duplicateRule = allWidgets.filter((x) => {
            if (x.widgetPageId.trim() === widgetPageId.trim() && (!widget.id || widget.id.trim() !== x.id.trim())) return x
        });

        if (duplicateRule && duplicateRule.length > 0) {
            setShowDuplicateErrorMessage(true);
            return;
        }

        setLoading(true);
        widget.name = name;
        widget.reportId = reportId.trim();
        widget.reportWorkspaceId = reportWorkspaceId.trim();
        widget.widgetPageId = widgetPageId.trim();
        widget.reportPageId = reportPageId.trim();
        widget.description = description;

        if (!isEditMode)
            dispatch(saveWidget(widget)).then((x) => {
                setLoading(false);
                if (x.response.data.success)
                    history.push('/admin/reporting-portal/widgets');
                if (x.response.data.duplicateWidget)
                    setShowDuplicateErrorMessage(true);
            });
        else            
            dispatch(editWidget(widget)).then((x) => {
                setLoading(false);
                if (x.response.data.success)
                    history.push('/admin/reporting-portal/widgets');
                if (x.response.data.duplicateWidget)
                    setShowDuplicateErrorMessage(true);

            });
    });

    return (
        <div className="create-advanced-list container adv-admin-list">
            <img src={`/images/${isEditMode ? 'icn_edit.png ' : 'icn_create.png'}`} className={`icon ${isEditMode ? 'icon_edit' : ''} hidden-xs`} />

            <h1 className="title" style={{textAlign: 'center'}}>
                {' '}
                {isEditMode ? 'Edit' : 'Create New'} Widget{' '}
            </h1>
            <TextField
                label="Name"
                placeholder="New Widget Name"
                onChange={(e) => setWidgetName(e.target.value)}
                className="list-name"
                text={name}
                showErrorMessage={showErrors && !name}
                maxLength={37}
                tabIndex={0} />
            <TextField
                id="description"
                style={{'marginBottom': '30px'}}
                className="list-name"
                multiLine={true}
                rows={4}
                text={description}
                label="Description"
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
                tabIndex={3} />
            <TextField
                label="Workspace ID"
                placeholder="New Workspace ID"
                onChange={(e) => setReportWorkspaceId(e.target.value)}
                className="list-name"
                text={reportWorkspaceId}
                showErrorMessage={showErrors && !reportWorkspaceId}
                tabIndex={2} />
            <TextField
                label="Report ID"
                placeholder="New Report ID"
                onChange={(e) => {
                    setReportId(e.target.value); setShowDuplicateErrorMessage(false);
                }}
                className="list-name"
                text={reportId}
                showErrorMessage={showErrors && !reportId}
                tabIndex={1} />
            <TextField
                label="Widget Page ID"
                placeholder="New Widget Page ID"
                onChange={(e) => setwidgetPageId(e.target.value)}
                className="list-name"
                text={widgetPageId}
                showErrorMessage={showErrors && !widgetPageId}
                tabIndex={2} />
            <TextField
                label="Widget Drill Down ID"
                placeholder="New Widget Drill Down ID"
                onChange={(e) => setReportPageId(e.target.value)}
                className="list-name"
                text={reportPageId}
                showErrorMessage={showErrors && !reportPageId}
                tabIndex={2} />

            <hr className="separator" />
            {showDuplicateErrorMessage && <React.Fragment>
                <div style={{color: 'red'}}>This widget already exists</div>
                <hr className="separator" />
            </React.Fragment>}
            <div>
                <div className="actions" style={{marginBottom: 30, width: 390}}>
                    <a className="cancel" onClick={() => history.push('/admin/reporting-portal/widgets')}>Cancel</a>
                    <div className="col-xs-12 d-block d-sm-none" style={{paddingLeft: '10%', paddingRight: '10%', marginTop: 30}}>
                        <div className="row">
                            <div className="col-xs-5">
                                <hr />
                            </div>
                            <div className="col-xs-2 or">OR</div>
                            <div className="col-xs-5">
                                <hr />
                            </div>
                        </div>
                    </div>
                    <Button
                        className="btnSave"
                        onClick={handleOnSave}
                        loading={loading}
                    >Save</Button>
                </div>
            </div>
        </div>
    );
}
