import axios from 'axios';
import _ from 'lodash';
import useQueryString from 'hooks/useQueryString';

export function getCmsImageUrlByPath(imagePath) {
    return process.env.REACT_APP_CMS_URL + `${imagePath}`;
}

export function clearTokenInfo() {
    return;
}

export function isSessionStorageAvailable() {
    const randomKey = 'test_random_key';
    try {
        sessionStorage.setItem(randomKey, randomKey);
        sessionStorage.removeItem(randomKey);
        return true;
    } catch (e) {
        return false;
    }
}

export function logEvent(eventTypeCode, payload) {
    const info = _.merge({}, {id_ins: getCookie('id_ins')}, payload);

    return axios.post(`/common/log`,
        {
            'eventTypeCode': eventTypeCode,
            'details': Object.keys(info).map(function (key) {
                return {name: key, value: info[key]};
            }),
        });
}

export function logEventVerbose(eventTypeCode, payloadArray) {
    return axios.post(`/common/log`,
        {
            'eventTypeCode': eventTypeCode,
            'details': payloadArray,
        });
}

export function getCookie(cname) {
    try {
        let name = cname + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    } catch (e) {
        return '';
    }
}
export function formatUOM(uom,qty) {
    switch (uom) {
        case 'EA': {
            return '/ each';
        }
        case 'PKG': {
            return `/ per package of ${qty}`;
        }
        case 'ASSY': {
            return '/ per assembly';
        }
        case 'BX': {
            return qty ? `/ per box of ${qty}` : '/ per box';
        }
        case 'CS': {
            return `/ per case of ${qty}`;
        }
        case 'FT': {
            return '/ per foot';
        }
        case 'KT': {
            return qty ? `/ per kit of ${qty}` : '/ per kit';
        }
        case 'PR': {
            return '/ per pair';
        }
        case 'SET': {
            return qty ? `/ per set of ${qty}` : '/ per set';
        }
    }
}
export function getRedirectUrl(defaultHomePage, isPasswordExpired, isCmsPreview, prevPath) {
    const clientPath = prevPath && !prevPath.includes('admin') ? 
        prevPath : '/overview';
    
    if (isPasswordExpired)
        return '/settings/profile';
    
    if (!isCmsPreview) {
        if (prevPath) {
            return prevPath;
        } else if (defaultHomePage != null) {
            switch (defaultHomePage) {
                case '98': {
                    return '/insights';
                }
                case '99': {
                    return '/settings';
                }
                case '122': {
                    return '/bids';
                }
                case '123': {
                    return '/orders';
                }
                case '124': {
                    return '/repairs';
                }
                case '125': {
                    return '/exchanges-returns';
                }
                case '134': {
                    return '/loaners-rentals';
                }
                case '157': {
                    return '/marketing-insights';
                }
                case '158': {
                    return '/branded-ecommerce';
                }
                case '159': {
                    return '/resources';
                }
                default: {
                    return '/overview';
                }
            }
        } else if (!isCmsPreview) {
            return '/overview';
        }
    } else {
        return clientPath;
    }
}

const sendLogPrintDocument = (fileName) => {
    const {tab, page} = getPageAndTab();
    const docType = fileName.split('.')[0];

    logEvent('PRINT DOCUMENT', {
        'Page': page,
        'Tab': tab ?? '',
        'Document Type': docType,
    });
}

async function printPdf(url, fileName, setLoading) {
    if (setLoading) {
        setLoading(true);
    }

    sendLogPrintDocument(fileName);
    axios({
        url,
        method: 'GET',
        responseType: 'arraybuffer',
    }).then(response => {

        const data = response.data;
        const file = new Blob([data], {type: 'application/pdf'});

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file);
            if (setLoading) {
                setLoading(false);
            }
            return;
        }

        const fileURL = URL.createObjectURL(file);
        let link = document.createElement('a');
        link.type = 'hidden';
        link.href = fileURL;
        link.download = `${fileName}.pdf`;
        document.body.appendChild(link);
        link.click();

        if (setLoading) {
            setLoading(false);
        }

        setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(fileURL);
        }, 100);
    }).catch(err => console.log(err))
}

export async function printPurchaseOrderPdf(lineItemId, billingPo, gsaItem, setLoading = null) {
    const request = `lineItemId=${lineItemId}&poNumber=${billingPo}&gsaItem=${gsaItem}`;
    const fileURL = `/report/purchase-order?` + request;

    await printPdf(fileURL, 'PurchaseOrder', setLoading);
}

export async function printPackingSlipPdf(searchParam, isNoSale, setLoading = null) {
    const fileURL = `/report/packing-slip?searchParam=${searchParam}&isNoSale=${isNoSale}`;
    await printPdf(fileURL, 'PackingSlip', setLoading);
}

export async function printShippingLabelPdf(linetItemId, setLoading = null) {
    const fileURL = `/report/shipping-label?lineItemId=` + linetItemId;
    await printPdf(fileURL, 'ShippingLabel', setLoading);
}

export function isURL(str) {
    if (!str) return false;
    return str.toLowerCase().indexOf('https:') >= 0 || str.toLowerCase().indexOf('http:') >= 0;
}

export function getPageAndTab() {
    const {tab} = useQueryString(window.location.search);
    const page = window.location.pathname.split('/')[1];

    return {
        tab: tab,
        page: page,
    }
}

export function formatMoney(number) {

    if (!number || number === 'NAN')
        return '$0.00';

    return number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export function getErrorMessage(type) {

    const generalMessage = 'An error was encountered updating the information, please retry. If the issue persists please contact ';

    switch (type) {
        case 'bid': {
            return generalMessage + 'sourcing@partssource.com';
        }
        case 'order': {
            return generalMessage + 'supplierportalsupport@partssource.com';
        }
        case 'repair': {
            return generalMessage + 'supplierportalsupport@partssource.com';
        }
        case 'exchangesReturns': {
            return generalMessage + 'supplierportalsupport@partssource.com';
        }
    }

    return generalMessage + 'PartsSource, Inc';
}

export function handleOnCurrencyKeyDown(event) {

    const keyCode = event.keyCode;
    const value = event.target.value;
    const allowedKeyCodes = [8, 9, 37, 39, 46, 110, 188, 190]

    const isDecimal = (event.key.toString().match(/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/) ? true : false);
    const isMatch = (value.match(/(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*))?(\.[0-9]{1,2})?$/) ? true : false);

    const isDollarSign = keyCode === 52;
    const alreadyHasDollar = value.includes('$');
    const lastCharWasDollar = value[0] !== '$' && value[value.length - 1] === '$';
    const lastCharWasPointOrComma = value[value.length - 1] === '.' || value[value.length - 1] === ',';

    if (!isDecimal &&
        ((isDollarSign && alreadyHasDollar) || (isDollarSign && lastCharWasPointOrComma) ||
            (!isDollarSign && !allowedKeyCodes.includes(keyCode))) ||
        (isDecimal && lastCharWasDollar)) {
        event.preventDefault();
    }

    return allowedKeyCodes.includes(keyCode) ? true : (isNaN(Number(event.key)) || isMatch);
}

const getValueLogEventData = (originalData, updatedData) => {
    if ((!originalData || originalData === '0001-01-01T00:00:00') && updatedData)
        return 'Add';
    else if (originalData && updatedData && (originalData !== updatedData))
        return 'Modify';
    else if (originalData && !updatedData)
        return 'Delete';
}

const checkIfDataWasModified = (originalData, updatedData) => {
    if (originalData !== updatedData)
        return true;
    
    return false;
}

export const getInstrumentationObject = (keyName, originalData, updatedData) => {   
    return checkIfDataWasModified(originalData, updatedData) && 
     {[keyName]: getValueLogEventData(originalData, updatedData)};
}

