import React from 'react';
import styled from 'styled-components';
import {semiBoldBrandFont} from '@partssourceinc/react-ui-core';
import {responsive, theme} from 'utils/styles';
import FlyoutMenu from 'components/FlyoutMenu';
import CmsFlyoutMenu from 'components/CmsFlyoutMenu';
import Link from 'components/Link';
import {BrComponent} from '@bloomreach/react-sdk';
import {logEvent} from 'utility';
import useMenu from 'hooks/useMenu';

const StyledNavBar = styled.div`
    ${responsive({min: 'lg'})};
    line-height: 55px;
    display: flex !important;
    align-items: center;
    justify-items: center;
    margin-left: 32px;

    > a {
        ${semiBoldBrandFont('14px')}
        color: #4a4a4a;
        z-index: 999;
        cursor: pointer;
        padding: 0px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid;
        position: relative;
        text-decoration: none;
        margin-left: 17px;

        &:hover {
            border-color: #005DA6;
            transition-delay: .19s;
            transition: border-color .01s;
            text-decoration: none;
        }

        &:not(:hover) {
            border-color: transparent;
            transition-delay: .19s;
            transition: border-color .01s;
        }

        &.active {
            border-bottom: 2px solid rgb(0, 93, 166) !important;
            color: #005da6 !important;
        }
    }

    @media (max-width: ${theme.screenMDMax}) {
        display: none !important;
    }
    @media (min-width: 1200px) and (max-width: 1360px) {
        line-height: 23px;
    }
`;

const NavBar = () => {
    const {ordersMenu, performanceMenu, brandedEcommerceMenu, marketingInsightsMenu, resourcesMenu} = useMenu();

    const sendLog = (page, tab) => {
        if (tab) {
            logEvent('TAB ACCESS', {
                'Page': page,
                'Tab': tab,
            })
        } else {
            logEvent('TAB ACCESS', {
                'Page': page,
            })
        }
    }

    return (
        <StyledNavBar>
            {performanceMenu && <Link
                href={performanceMenu?.url}
                style={{color: '#000000', fontSize: '16px'}}
                onClick={() => sendLog(performanceMenu?.title)}>{performanceMenu?.title}</Link>}
            {ordersMenu && <FlyoutMenu 
                menuId="mnuOrders" 
                menu={ordersMenu?.menu} 
                style={{marginLeft: '17px'}}>
                {ordersMenu?.title}
            </FlyoutMenu>}
            {marketingInsightsMenu && 
                <Link href={marketingInsightsMenu?.url} onClick={() => sendLog(marketingInsightsMenu?.title)}>{marketingInsightsMenu?.title}</Link>}
            {brandedEcommerceMenu && 
                <Link href={brandedEcommerceMenu?.url} onClick={() => sendLog(brandedEcommerceMenu?.title)}>{brandedEcommerceMenu?.title}</Link>}
            <BrComponent path={'header/resources-menu'}>
                <CmsFlyoutMenu
                    menuId="mnuResources"
                    onMenuItemClick={(tab) => sendLog(resourcesMenu?.title, tab)}
                    style={{marginLeft: '17px', color: '#4a4a4a', fontSize: '14px'}}>
                    {resourcesMenu?.title}
                </CmsFlyoutMenu>
            </BrComponent>
        </StyledNavBar>
    );
};

export default NavBar;
