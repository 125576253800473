import React, {useState, useEffect} from 'react';
import {Redirect, useParams} from 'react-router-dom';
import OktaSignInWidget from '../components/OktaSignInWidget';
import {useOktaAuth} from '@okta/okta-react';
import Spinner from './../components/Spinner';
import {useDispatch} from 'react-redux';
import {setIsLoggingIn} from './../reducers/user';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {BrComponent} from '@bloomreach/react-sdk';
import {useSelector} from 'react-redux';

const LoginWrapper = styled.div`
    font-family: "Source Sans Pro", Helvetica, sans-serif !important;

    h1 {
        font-weight: 300 !important;
    }

    button {
        margin-top: 20px;
    }
`;
const LoginContent = styled.div`
    margin-top: 80px;
    max-width: 300px;

    form {
        position: relative;
    }

    @media (min-width: 1200px) {
        float: left;
        margin-left: 100px;
    }

    @media (max-width: 819px) {
        max-width: none;
    }

    .button-primary {
        background: #ff9c05 !important;
        border: none !important;
        font-weight: 600 !important;
    }

    #okta-sign-in {
        margin: 0 !important;
        font-family: "Source Sans Pro", Helvetica, sans-serif !important;
    }

    #okta-sign-in.auth-container h2 {
        color: #000000 !important;
        font-family: "Source Sans Pro", sans-serif !important;
        font-weight: 300 !important;
        font-size: 27px !important;
        text-align: left !important;
    }

    #okta-sign-in.auth-container .okta-sign-in-header {
        display: none !important;
    }

    #okta-sign-in.auth-container.main-container {
        box-shadow: none !important;
    }

    #okta-sign-in .auth-content {
        padding-top: 110px !important;
    }

    #okta-sign-in .okta-form-infobox-error::before {
        background-color: rgba(0, 0, 0, 0) !important; /* transparent */
    }
    
    #okta-sign-in .okta-form-infobox-error {
        border-color: #FF0000 !important;
        color: #FF0000 !important;
        background-color: rgba(255, 0, 0, 0.1) !important; /* Red color with 10% opacity */
        .error-16::before {       
            color: #FF0000 !important;
        }
    }
    
    #okta-sign-in .o-form-has-errors {
        border-color: #FF0000 !important;
    }
`;

const OktaLogin = ({config, sessionStorageAvailable}) => {
    const {oktaAuth, authState} = useOktaAuth();
    const params = useParams();
    const isLoggedIn = useSelector(state => state.user.tokenInfo);

    let tConfig = {...config}

    // in case when browser don't support pkce switch to implicite flow (e.g IE11)
    if (!oktaAuth.features.isPKCESupported()) {
        tConfig.useInteractionCodeFlow = false;
        tConfig.authParams.pkce = false;
    }

    const [cfg, setCfg] = useState(tConfig);
    const [showOktaWidget, setShowOktaWidget] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const {e, ak1, ak2, referer} = params;

        const colorConfig = { 
            colors: {
                brand: '#FF9505',
            },
        };

        let tempConfig = config;

        if (sessionStorageAvailable) {
            sessionStorage.removeItem('wo_id');
            sessionStorage.removeItem('urlFields');
            sessionStorage.removeItem('fields');
        }

        if (e) {
            tempConfig = {
                ...tempConfig,
                ...colorConfig,
                username: ak1,
                password: ak2,
            };

            if (sessionStorageAvailable) 
                sessionStorage.removeItem('prevUrl');            
        }
        const email = referer ? referer.split('/')[2] : null;
        if (email)
            tempConfig = {
                ...tempConfig,
                ...colorConfig,
                username: email,
            };
        
        tempConfig = {
            ...cfg,
            ...colorConfig,
        };

        setCfg(tempConfig);
        setShowOktaWidget(true);

    }, [config]);

    const onSuccess = async (tokens) => {
        if (!isLoggedIn) {
            dispatch(setIsLoggingIn(true));            
        }   
        oktaAuth.handleLoginRedirect(tokens);
    };

    const onError = (err) => {
        console.log('error logging in', err);
    };

    const renderWidget = () => {
        if (showOktaWidget)
            return (
                <div className="row">
                    <div className="d-none d-md-block col-md-6">
                        <BrComponent path="main/container" />
                    </div>
                    <LoginWrapper className="col-md-5">
                        <LoginContent>
                            <OktaSignInWidget
                                config={cfg}
                                onSuccess={onSuccess}
                                onError={onError} />
                        </LoginContent>
                    </LoginWrapper>
                </div>                
            )
        else 
            return null;
    }

    if (authState && authState.isPending) return <Spinner />;    
    return authState && authState.isAuthenticated ?
        <Redirect to={{pathname: '/overview'}} /> : renderWidget();
    
};

OktaLogin.propTypes = {
    config: PropTypes.any,
    sessionStorageAvailable: PropTypes.bool,
};

export default OktaLogin;

