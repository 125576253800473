import React from 'react';
import * as PropTypes from 'prop-types';
import {Button} from '@partssourceinc/react-ui-core';
import {CardAction} from 'components/CardView/StyledComponents';
import ShippingInfoCardV2 from 'components/ShippingInfoCardV2';
import PrintingOptions from './PrintingOptions';

const LoanersAndRentalsCardFooter = (props) => {
    const {cardData,
        onPrintOptionChange,
        showReceivedDialogPopup,
        showProcessPopup,
        isPrinting,
    } = props;
  
    switch (cardData.loanerStatus) {
        case 'Ordered':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} title="Estimated Ship Date"
                        subText={cardData.vendorToCustomerShippingAddress}
                        date={cardData.vendorToCustomerDateShipped}
                        trackingNumber={cardData.vendorToCustomerTrackingNumber}
                        trackingText={cardData.shipper}
                        noDateText="Pending"
                        noTimeText="End of Day" />
                    <CardAction>
                        <Button secondary={true} onClick={() => showProcessPopup(cardData)}>
                            PROCESS</Button>
                    </CardAction>
                    <PrintingOptions isPrinting={isPrinting}
                        options={cardData.billingPo ? ['PURCHASEORDER', 'PACKINGSLIP'] : ['']}
                        cardData={cardData} onPrintOptionChange={onPrintOptionChange} />
                </div>
            );
        case 'ShippedOutbound':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} title="Shipped"
                        subText={cardData.vendorToCustomerShippingAddress}
                        date={cardData.vendorToCustomerDateShipped}
                        trackingNumber={cardData.vendorToCustomerTrackingNumber}
                        trackingText={cardData.shipper}
                        noDateText="Pending" />
                    <PrintingOptions isPrinting={isPrinting}
                        options={cardData.billingPo ? ['PURCHASEORDER', 'PACKINGSLIP'] : ['']}
                        cardData={cardData} onPrintOptionChange={onPrintOptionChange} />
                </div>
            );
        case 'ReceivedInUse':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} title="Delivered"
                        isComplete={true}
                        subText={cardData.vendorToCustomerShippingAddress}
                        date={cardData.vendorToCustomerDateDelivered}
                        trackingNumber={cardData.vendorToCustomerTrackingNumber}
                        trackingText={cardData.shipper}
                        noDateText="On Unknown Date" />
                    <CardAction>
                        <Button secondary={true} onClick={() => showReceivedDialogPopup(cardData)}>
                            RECEIVE</Button>
                    </CardAction>
                    <PrintingOptions isPrinting={isPrinting}
                        options={cardData.billingPo ? ['PURCHASEORDER', 'PACKINGSLIP'] : ['']}
                        cardData={cardData} onPrintOptionChange={onPrintOptionChange} />
                </div>
            );
        case 'ShippedInbound':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} title="Estimated Delivery Date"
                        subText={cardData.customerToVendorShippingAddress}
                        date={cardData.customerToVendorEstimatedDelivery}
                        trackingNumber={cardData.customerToVendorTrackingNumber}
                        trackingText={cardData.shipper}
                        noDateText="Pending"
                        noTimeText="End of Day" />
                    <CardAction>
                        <Button secondary={true} onClick={() => showReceivedDialogPopup(cardData)}>
                            RECEIVE</Button>
                    </CardAction>
                    <PrintingOptions isPrinting={isPrinting}
                        options={cardData.billingPo ? ['PURCHASEORDER', 'PACKINGSLIP'] : ['']}
                        cardData={cardData} onPrintOptionChange={onPrintOptionChange} />
                </div>
            );
        case 'Returned':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} title="Delivered"
                        isComplete={true}
                        subText={cardData.customerToVendorShippingAddress}
                        date={cardData.customerToVendorDateDelivered}
                        trackingNumber={cardData.customerToVendorTrackingNumber}
                        trackingText={cardData.shipper}
                        noDateText="On Unknown Date" />     
                    <CardAction>
                        <Button secondary={true} onClick={() => showReceivedDialogPopup(cardData)}>
                            RECEIVE</Button>
                    </CardAction>
                    <PrintingOptions isPrinting={isPrinting}
                        options={cardData.billingPo ? ['PURCHASEORDER', 'PACKINGSLIP'] : ['']}
                        cardData={cardData} onPrintOptionChange={onPrintOptionChange} />
                </div>
            );
        case 'Inspected':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} title="Delivered"
                        isComplete={true}
                        subText={cardData.customerToVendorShippingAddress}
                        date={cardData.customerToVendorDateDelivered}
                        trackingNumber={cardData.customerToVendorTrackingNumber}
                        trackingText={cardData.shipper}
                        noDateText="On Unknown Date" />
                    <CardAction>
                        <Button secondary={true} onClick={() => showReceivedDialogPopup(cardData)}>
                            VDF</Button>
                    </CardAction>
                    <PrintingOptions isPrinting={isPrinting}
                        options={cardData.billingPo ? ['PURCHASEORDER', 'PACKINGSLIP'] : ['']}
                        cardData={cardData} onPrintOptionChange={onPrintOptionChange} />
                </div>
            );
    }
}

LoanersAndRentalsCardFooter.propTypes = {
    isPrinting: PropTypes.bool,
    onPrintOptionChange: PropTypes.func,
    cardData: PropTypes.shape({
        vendorToCustomerShippingAttention: PropTypes.string,
        customerToVendorShippingAttention: PropTypes.string,
        vendorToCustomerTrackingNumber: PropTypes.string,
        customerToVendorShippingAddress: PropTypes.string,
        vendorToCustomerDateDelivered: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        vendorToCustomerDateShipped: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        vendorToCustomerEstimatedDelivery: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        vendorToCustomerShippingAddress: PropTypes.string,
        customerToVendorDateDelivered: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        customerToVendorDateShipped: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        customerToVendorEstimatedDelivery: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        customerToVendorTrackingNumber: PropTypes.string,
        dateCreated: PropTypes.string,
        shipper: PropTypes.string,
        status: PropTypes.string,
        loanerStatus: PropTypes.string,
        orderType: PropTypes.string,
        billingPo: PropTypes.string,
        estimatedShipDate: PropTypes.string,
    }),
    showReceivedDialogPopup: PropTypes.func.isRequired,
    showProcessPopup: PropTypes.func.isRequired,
}
export default LoanersAndRentalsCardFooter;
