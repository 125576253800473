import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {Loader} from '@partssourceinc/react-ui-core';
import {UserDashboardPortalType} from 'utils/enums';
import ReportingPortalDashboardSisense from '../pages/Sisense/ReportingPortalDashboardSisense';
import ReportingPortalDashboardPowerBi from '../pages/PowerBi/ReportingPortalDashboardPowerBi';

const Container = styled.div`
    width: 100%;
    top: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
`;

const LoaderContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 450px;
`;

const Insights = () => {
    const {loading, data: {dashboardPortalType, portal}} = useSelector(state => state.user.userDashboardSettings);

    if (loading) {
        return (
            <Container>
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            </Container>
        );
    }

    return (
        <Container>
            {dashboardPortalType === UserDashboardPortalType.Sisense && <ReportingPortalDashboardSisense />}
            {dashboardPortalType === UserDashboardPortalType.PowerBi && portal && <ReportingPortalDashboardPowerBi />}
        </Container>
    );
};

export default Insights;
