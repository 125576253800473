import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {CustomGrid, Loader, Popup} from '@partssourceinc/react-ui-core';
import _ from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {getWidgets, deleteWidget} from './../../reducers/user';
import 'components/css/adminLists.css';

const LoaderContainer = styled.div`
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Icon = styled(FontAwesomeIcon)`
    height: 13px;
    padding-left: 1px;
    padding-right: 1px;
    margin-right: 2px;
`;

export default function RPWidgetsManagement() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [sortColumn, setSortColumn] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');

    const {widgets, widgetsLoading} = useSelector((state) => state.user);
    const [filteredList, setFilteredList] = useState([]);
    const [selectedWidget, setSelectedWidget] = useState({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    useEffect(() => {
        dispatch(getWidgets());
    }, [dispatch]);

    useEffect(() => {
        let list = searchTerm ? widgets.filter((x) => x.search.indexOf(searchTerm) > -1) : widgets;
        list = _.orderBy(list, sortColumn, sortDirection);
        setFilteredList(list);
    }, [widgets, sortColumn, sortDirection, searchTerm]);

    const onSort = (columnName) => {
        setSortColumn(columnName.sortColumn);
        setSortDirection(columnName.sortDirection);
    };

    const onConfirmDelete = (widget) => {
        setSelectedWidget(widget);
        setShowDeleteConfirmation(true);
    };
    
    const onDeleteWidget = () => {
        dispatch(deleteWidget(selectedWidget.ravenId)).then(() => {
            setShowDeleteConfirmation(false);
            dispatch(getWidgets());
        });
    };

    const headerColumns = [
        {
            fieldName: 'nameSort',
            displayName: 'Name',
            canSort: true,
        }, {
            fieldName: 'description',
            displayName: 'Description',
            canSort: true,
        }, {
            fieldName: 'reportWorkspaceId',
            displayName: 'Report Workspace ID',
            canSort: true,
        }, {
            fieldName: 'reportId',
            displayName: 'Report ID',
            canSort: true,
        }, {
            fieldName: 'widgetPageId',
            displayName: 'Widget Page ID',
            canSort: true,
        }, {
            fieldName: 'reportPageId',
            displayName: 'Widget Drill Down ID',
            canSort: true,
        },
        {fieldName: '', displayName: 'Actions', canSort: false},
    ];

    const tableRows = filteredList.map(x =>
        (<tr key={`${x.ravenId}-row`}>
            <td className="no-wrap">{x.name}</td>
            <td className="no-wrap">{x.description}</td>
            <td className="no-wrap">{x.reportWorkspaceId}</td>
            <td className="no-wrap">{x.reportId}</td>
            <td className="no-wrap">{x.widgetPageId}</td>
            <td className="no-wrap">{x.reportPageId}</td>
            <td style={{display: 'flex'}}>
                <Icon 
                    className="edit" 
                    icon={faPencilAlt} 
                    size="xs" 
                    onClick={() => history.push(`/admin/reporting-portal/widgets/${x.ravenId}`)} 
                />
                <Icon 
                    className="edit" 
                    icon={faTrashAlt} 
                    size="xs" 
                    onClick={() => onConfirmDelete(x)}
                />
            </td>
        </tr>));

    return (
        <div className="adv-admin-lists">
            {widgetsLoading && <LoaderContainer>
                <Loader />
            </LoaderContainer>}
            <h1>SPro Portal Widget Management</h1>
            <div className="add-item" onClick={() => history.push(`/admin/reporting-portal/widgets/add`)}>+ Add Widget</div>
            <CustomGrid
                headerColumns={headerColumns}
                totalRecords={tableRows.length}
                showSearch={true}
                showPagination={true}
                defaultSortColumn={sortColumn}
                defaultSortDirection={sortDirection}
                onSort={onSort}
                onSearch={(searchTerm) => setSearchTerm(searchTerm.value.toLowerCase())}
                defaultPageSize={10}
                gridClassName="custom-grid"
                displayPages={10}>
                {tableRows}
            </CustomGrid>
            {showDeleteConfirmation ? <Popup confirmText="Yes"
                cancelText="No"
                show={true}
                hideButtons={false}
                onCancel={() => setShowDeleteConfirmation(false)}
                onConfirm={onDeleteWidget}
                loading={widgetsLoading}
                className="confirm-item-delete-dialog"
            >
                <div style={{fontSize: '16px'}}>
                Are you sure you want to delete this widget?
                </div>
            </Popup> : null}
        </div>
    );
}
