import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import ReorderWidgetsDialog from 'components/GenericReorderDialog';
import 'components/css/multiGrid.css';
import styled from 'styled-components';
import {theme} from '@partssourceinc/react-ui-core';
import _ from 'lodash';
import PageBreadcrumbs from 'components/Breadcrumbs';

const HeaderWrapper = styled.div`
    display: flex;
    height: 150px;
    background: white;
    margin: 0px -20px;
    padding: 0px 20px;
    flex-direction: column;
    @media screen and (min-width: ${theme.screenXLMin}) {
        margin: 0 -50px;
        padding: 0 50px;
    }
    @media screen and (max-width: ${theme.screenLGMax}) {
        margin: 0 -50px;
        padding: 0 50px;
    }
    @media screen and (max-width: ${theme.screenMDMax}) {
        margin: 0 -20px;
        padding: 0 20px;
    }
    @media screen and (max-width: ${theme.screenSMMax}) {
        margin: 0 -20px;
        padding: 0 20px;
    }
    @media screen and (max-width: ${theme.screenXsMax}) {
        margin: 0 -20px;
        padding: 0 20px;
    }
    @media screen and (max-width: 320px) {
        margin: 0 -20px;
        padding: 0 20px;
    }
`

const ReportingPortalHeaderPowerBi = (props) => {
    const {updateWidgetsOrder} = props;
    const portal = useSelector(state => state.user.userDashboardSettings.data.portal);
    const [widgets, setWidgets] = useState(portal.widgets);
    const [showReorderDialog, setShowReorderDialog] = useState(false);
    const onConfirmReorder = (items) => {
        setWidgets(items);
        updateWidgetsOrder(_.cloneDeep(items));
        setShowReorderDialog(false);
    }

    const onCancelReorder = () => {
        setShowReorderDialog(false);
    }

    return (
        <HeaderWrapper style={{marginTop: '20px', marginBottom: '20px'}}>
            <div style= {{display: 'flex', alignItems: 'center'}}>
                <PageBreadcrumbs style={{marginTop: '0px'}} />
                {updateWidgetsOrder && widgets.length > 1 && <div style={{position: 'absolute', right: '20px'}}>
                    <a 
                        className="text-uppercase font-weight-bold text-secondary" 
                        style={{marginRight: '10px', marginLeft: '10px'}} 
                        onClick={() => { 
                            setShowReorderDialog(true) 
                        }} 
                        role="button">Reorder</a>
                </div>}
            </div> 
            {
                showReorderDialog && <ReorderWidgetsDialog items={widgets} onConfirm={onConfirmReorder} onCancel={onCancelReorder} />    
            }
        </HeaderWrapper>
    );
}

ReportingPortalHeaderPowerBi.propTypes = {
    updateWidgetsOrder: PropTypes.func
};

export default ReportingPortalHeaderPowerBi;
