import axios from 'axios';
import * as PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {processFiles, convertToBlob} from 'utils/FileHandler';
import {TextField, Button, FileUploader, theme, Dropdown, Popup} from '@partssourceinc/react-ui-core';
import {addAttachmentToCache, removeAttachmentFromCache} from 'reducers/attachments';
import styled from 'styled-components';
import moment from 'moment';

const Wrapper = styled.div` 
    max-width: 500px;
`;

const DocDetails = styled.div`
    margin-bottom: 30px;
    min-width: 390px;
    float: left;
    &:first-child {
    margin-top: 15px;
    }

    @media (max-width: 450px) {
        min-width: auto;
    }
`;

const Subtitle = styled.span`
    font-size: 16px;
    float: left;
`;

const FileUploaderContainer = styled.div`
    
    label {
        color: rgb(255, 255, 255);    
        font-size: 14px;
        font-weight: bold;   
        height: 75px;
        letter-spacing: 0px;
        margin-top: 4px;
    }                     
`;

const BoldSubtitle = styled(Subtitle)`
    font-weight: bold;
    padding-left: 10px;
`;

const BoldNote = styled(BoldSubtitle)`
    overflow-wrap: anywhere;
`;

const DocName = styled.a`
    font-family: "Source Sans Pro";
    font-weight: bold;
    color: ${theme.blue};
    height: 22px;
    font-size: 16px;    
`;

const MultilineTextField = styled(TextField)`
    height: 100px;
    .text-field_input {
        height: 75px;
    }
`;

const ErrorMessage = styled.span`
    font-weight: normal;
    height: 20px;
    display: block;
`;

const BtnSave = styled(Button)`
    margin-top: 0;
    font-weight: bold;
    font-size: 14px;
`;

const UploadedDocsWrapper = styled.div`
    margin-top: 15px;
    border-top: #e2e1e1 solid 2px;
    line-height: 22px;
    font-size: 12px;
    font-weight: normal;
`;

const NoFile = styled.span`
    margin-top: 15px;
    color: #000000;
    font-size: 16px;
    font-weight: normal;
    height: 22px;
    line-height: 22px;
`;

const CancelLink = styled.a`
    &&& {
        text-transform: uppercase;
        cursor: pointer;
        color: rgb(119, 119, 119);
        font-weight: bold;
        margin-right: 1em;

        &:hover {
            text-decoration: underline;
            outline: none;
        }

        &:focus {
            text-decoration: underline;
            outline: none;
        }

        &:active:focus {
            text-decoration: underline;
            outline: none;
        }
    }
`;

const Title = styled.span`
    font-family: "Source Sans Pro";
    font-weight: 300;
    height: 30px;
    margin-bottom: 15px !important;
`;

const DocAttributes = styled.div`
    color: #000000;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    display: flex;
    width: 100%;
`;

const DropdownList = styled(Dropdown)`
    width: 280px;    
    margin-top:10px;
    margin-bottom:17px;

    div {
        span {
            font-weight: 700;
            font-size: 12px;
            color: #005DA6;
            margin: 4px 10px 4px 10px;
        }
    }
`;

const RemoveLink = styled.span`
    color: rgb(119, 119, 119);
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    text-decoration: underline;
    margin-left: 8px;
    cursor: pointer !important;
`

const Attachment = (props) => {
    const {lineItemId,
        handleOnSave,
        handleOnCancel,
        memorySave = false,
        isReadOnly = false,
        showCancel = false,
        allowMultipleFiles = false,
        showDocTypes = false} = props;

    let {attachments: initialAttachments} = props

    const [attachments, setAttachments] = useState([]);    
    const [description, setDescription] = useState('');
    const [uploadFile, setUploadFile] = useState({
        name: '',
        file: null,
    });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const cachedAttachments = useSelector(state => state.attachments.cachedAttachments);
    const user = useSelector(state => state.user);    
    const [selectedDocTypeId, setSelectedDocTypeId] = useState(0);
    const [notesEnabled, setNotesEnabled] = useState(true);
    const [showServiceReportWarning, setShowServiceReportWarning] = useState(false);
    const [docTypeData] = useState([  
        {
            name: 'Other', 
            value: 0,
        },
        {
            name: 'Field Service Report', 
            value: 1,
        },
    ]);

    const dispatch = useDispatch();
    useEffect(() => {
        if (initialAttachments && initialAttachments.length > 0) {
            setAttachments(initialAttachments)
            setSelectedDocTypeId(`0`);        
            setDescription('');
            setNotesEnabled(true);
        } else if (showDocTypes && (!initialAttachments || !initialAttachments.length)) {
            setSelectedDocTypeId(`1`);
            setDescription('SERVICE REPORT');
            setNotesEnabled(false);
        }
        
    }, [cachedAttachments])

    const removeAddedAttachment = (e) => {
        e.preventDefault();
        setErrorMessage('');
        setUploadFile({
            name: '',
            file: null,
        });
        setShowErrorMessage(false);
    }

    const dismissServiceReportWarning = () => {
        setShowServiceReportWarning(false)
    }

    const updateDescription = (e) => {
        let content = e.target.value;
        if (!warningMessage) {
            if (content.length > 120) {
                let trimmedContent = content.substring(0, 120);
                setDescription(trimmedContent);
                setWarningMessage('Please limit your note to 120 characters.');
            } else {
                setDescription(content)
            }
        } else {
            if (content.length < 120) {
                setWarningMessage('');
            }
        }
    }

    const handleUpload = (e) => {
        e.preventDefault();
        const files = e.target.files;
        const display = e.target
            .value
            .split('/')
            .pop()
            .split('\\')
            .pop();

        processFiles(files, display).then(res => {
            if (res.uploadFile?.name !== '') {
                setUploadFile(res.uploadFile);
                setErrorMessage('');
                setShowErrorMessage(false);
            } else {
                setErrorMessage(res.errorMessage);
                setShowErrorMessage(true);
            }
        });

        if (selectedDocTypeId === '1') {
            // Update note to match what will be saved            
            setDescription('SERVICE REPORT');

            // Disable Notes from being used
            setNotesEnabled(false);

            if ((cachedAttachments && cachedAttachments.length > 0
                && cachedAttachments.some(v => v.description === 'SERVICE REPORT')) || (initialAttachments && initialAttachments.length > 0
                && initialAttachments.some(v => v.description === 'SERVICE REPORT')) ||
                (attachments && attachments.length > 0 && attachments.some(v => v.description === 'SERVICE REPORT'))) {
                setShowServiceReportWarning(true)
            }
        } 
    }

    const saveAttachment = () => {
        const {name, file} = uploadFile;
        const alreadyExists = cachedAttachments.filter(x => x.lineItemId === lineItemId).some(x => x.name === name);
        if (alreadyExists) {
            alert('There\'s an existing attachment with this name!');
            return;
        }

        setLoading(true);       
        let request = {
            name: name,
            description: description === '' ? ' ' : description,
            data: file,
            attachment: file.split('base64,')[1],
            lineItemId: lineItemId,
        };

        if (!memorySave) {
            
            axios
                .post(`/order/${lineItemId}/attachment`, request)
                .then(resp => {
                    setAttachments(resp.data);
                    setLoading(false);                    
                    handleOnSave(resp.data);
                    setUploadFile({
                        name: '',
                        file: null,
                    });
                });
        } else {
            if (initialAttachments)
                initialAttachments = initialAttachments.map(
                    att => att.description === 'SERVICE REPORT' ? att.description = `Svc Rpt ${att.dateCreated.split('T').join(' ')}` : att)
           
            let currentAttachments = [...cachedAttachments];  
                         
            const newAtt = {
                attachment: request.data.split('base64,')[1],
                data: request.data,
                name: request.name,
                description: request.description,
                createdBy: user.firstName + ' ' + user.lastName,
                lineItemId: lineItemId,
            }
            
            currentAttachments.push(newAtt);
            let updatedAttachments = [...attachments];
            updatedAttachments.push(newAtt);
            dispatch(addAttachmentToCache(newAtt));                        
            setLoading(false);
            handleOnSave(request);
            setUploadFile(null);
        }

        setWarningMessage('');
        setDescription('');
    }

    const onCancel = () => handleOnCancel();

    const saveDocForIE = (e, blob, fileName) => {
        e.preventDefault();
        if (navigator.userAgent.toLowerCase().includes('.net')) {
            window.navigator.msSaveBlob(blob, fileName);
        } else {
            return false;
        }
    }

    const onPropertyChange = ({target: {name, value}}) => {     
        setSelectedDocTypeId(value);
        if (parseInt(value) === 1) {             
            // Update note to match what will be saved            
            setDescription('SERVICE REPORT');

            // Disable Notes from being used
            setNotesEnabled(false);

            if ((cachedAttachments && cachedAttachments.length > 0
                && cachedAttachments.some(v => v.description === 'SERVICE REPORT')) || (initialAttachments && initialAttachments.length > 0
                && initialAttachments.some(v => v.description === 'SERVICE REPORT')) ||
                (attachments && attachments.length > 0 && attachments.some(v => v.description === 'SERVICE REPORT'))) {
                setShowServiceReportWarning(true)
            }
        } else {
            // Disable Notes from being used
            setNotesEnabled(true)
            if (description === 'SERVICE REPORT') {        
                setDescription('');
            }        
        }
    }

    const renderAttachment = (attachment, i, showRemove) => {
        let extension = attachment.name.split('.').pop();
        let blob = new Blob();
        switch (extension.toLowerCase()) {
            case 'pdf' || 'csv':
                blob = convertToBlob(attachment.attachment, 'application/' + extension);
                break;
            case 'doc':
                blob = convertToBlob(attachment.attachment, 'application/msword');
                break;
            case 'docx':
                blob = convertToBlob(attachment.attachment, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
                break;
            case 'xls':
                blob = convertToBlob(attachment.attachment, 'application/vnd.ms-excel');
                break;
            case 'xlsx':
                blob = convertToBlob(attachment.attachment, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                break;
            case 'txt':
                blob = convertToBlob(attachment.attachment, 'text/plain');
                break;
            case 'html':
                blob = convertToBlob(attachment.attachment, 'text/html');
                break;
            default:
                blob = convertToBlob(attachment.attachment, extension);
                break;
        }

        let blobUrl = '';
        let isIE = false;
        if (!navigator.userAgent.toLowerCase().includes('.net')) {
            blobUrl = URL.createObjectURL(blob);
        } else {
            isIE = true;
        }

        return (
            <DocDetails key={i}>
                <React.Fragment>
                    {isIE
                        ?
                        <DocName id="downloadAttachment"
                            onClick={(e) => saveDocForIE(e, blob, attachment.name)}
                            style={{'cursor': 'pointer'}}
                            download={attachment.name}>
                            {attachment.name}
                        </DocName>
                        :
                        <DocName id="downloadAttachment"
                            href={blobUrl}
                            download={attachment.name}>
                            {attachment.name}
                        </DocName>
                    }
                    {showRemove && !isReadOnly &&
                        <RemoveLink onClick={() => dispatch(removeAttachmentFromCache(i))}>Remove</RemoveLink>}
                </React.Fragment>
                <DocAttributes>
                    <Subtitle>Uploaded:</Subtitle>
                    <BoldSubtitle>
                        {' '}
                        {attachment.createdBy} -{' '}
                        {moment(attachment.dateCreated).format('ddd, DD-MMM-YYYY')}
                    </BoldSubtitle>
                </DocAttributes>
                <DocAttributes>
                    <Subtitle>Note: </Subtitle>
                    <BoldNote>{attachment.description}</BoldNote>
                </DocAttributes>
            </DocDetails>
        );
    }

    const renderAttachments = () => {
        return (<UploadedDocsWrapper>
            {attachments && attachments.length > 0 ? attachments
                .map((item, i) => renderAttachment(item, i, false)) : (
                <React.Fragment>
                    {(!memorySave || !cachedAttachments.length) && <NoFile>No Files</NoFile>}
                    {memorySave && cachedAttachments.length > 0 &&
                        cachedAttachments.filter(x => x.lineItemId === lineItemId).map((item, i) => renderAttachment(item, i, true))}
                </React.Fragment>
            )}
        </UploadedDocsWrapper>)
    }
    
    return (
        <Wrapper>
            <Title>Attachments</Title>
            
            {!isReadOnly &&
                <div>

                    <FileUploaderContainer>
                        {(allowMultipleFiles || (!allowMultipleFiles && (!attachments || attachments.length === 0))) &&
                            <FileUploader
                                label="CHOOSE FILE"
                                maxMb="10"
                                restriction="Max file size 10MB"
                                isFileSelected={uploadFile?.name ? true : false}
                                fileDisplay={uploadFile?.name}
                                handleRemove={removeAddedAttachment}
                                onChange={handleUpload}
                                photosTab={true}
                                showUpload={true}
                                infoCenter={true}
                            />
                        }
                    </FileUploaderContainer>                    
                    {
                        showDocTypes && <DropdownList
                            key={selectedDocTypeId}
                            name="ddlDocTypes"
                            suppressBlankOption={true}
                            valueField="value"
                            textField="name"
                            label="Document Type"
                            selectedValue={selectedDocTypeId}
                            options={docTypeData} 
                            onChange={(e) => onPropertyChange(e)} />
                    }
                    <div>
                        {showErrorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                        <MultilineTextField
                            id="Note"
                            multiLine={true}
                            rows={2}
                            text={description}
                            label="Note"
                            placeholder="Note (max 120 characters)"
                            required={true}
                            onChange={updateDescription}
                            limit={121}
                            disabled={!notesEnabled}
                        />
                        
                        <p style={{'color': 'red'}}>{warningMessage}</p>
                    </div>
                    {showCancel && <CancelLink className="cancel" onClick={() => onCancel()}>Cancel</CancelLink>}
                    <BtnSave
                        disabled={!uploadFile?.name || !description}
                        secondary={true}
                        onClick={saveAttachment}
                        loading={loading}>
                        ATTACH
                    </BtnSave>
                </div>
            }

            {renderAttachments()}
            
            <Popup
                show={showServiceReportWarning}
                onCancel={dismissServiceReportWarning}
                hideButtons={true}>
                <section className="notes">
                    <aside>
                        <article>
                            <p>
                                Maximum of 1 Field Service Report allowed. Continue to replace document.
                            </p>
                        </article>

                        <article style={{display: 'flex', justifyContent: 'end'}}>
                            <Button
                                style={{marginTop: !warningMessage && 15}}
                                className="save"
                                secondary={true}
                                onClick={dismissServiceReportWarning}>
                                OK
                            </Button>
                        </article>
                    </aside>
                </section>
            </Popup>
        </Wrapper>
    );
}

export default Attachment;

Attachment.propTypes = {
    attachments: PropTypes.arrayOf(
        PropTypes.shape()
    ),
    lineItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handleOnSave: PropTypes.func,
    handleOnCancel: PropTypes.func,
    memorySave: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    showCancel: PropTypes.bool,
    allowMultipleFiles: PropTypes.bool,
    showDocTypes: PropTypes.bool,
};
