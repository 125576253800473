import styled from 'styled-components';
import {Dropdown} from '@partssourceinc/react-ui-core';

export const CardStatusSection = styled.div`
    background-color: #f1f1f1;
    overflow: hidden;
    padding: 20px 29px;
    position: absolute;
    margin-top: 20px;
    left: 0;
    right: 0;
    bottom: 95px;

    @media (max-width: 1149px) {
        position: static;
        margin-left: -30px;
        margin-right: -30px;
    }
`;

export const CardStatusTitle = styled.div`
    font-weight: 700;
    font-size: 16px;
`;

export const CardStatusDetails = styled.div`
    font-weight: 400;
    font-size: 16px;
`;

export const CardAction = styled.div`
    position: absolute;
    bottom: 30px;
    display: inline-block;
    right: 29px;

    @media (max-width: 1149px) {
        position: static;
        float: right;
        margin-top: 20px;
    }

    @media (max-width: 450px) {
        width: 100%;

        button {
            width: 100%;
        }
    }
`;

export const PrintAction = styled(Dropdown)`
    max-width: 18em;
    position: absolute;
    bottom: 25px;
    display: inline-block;
    left: 29px;
    width: 185px;

    span {
        font-weight: 700;
        font-size: 14px;
        margin: 14px 10px 4px 10px;
    }

    @media (max-width: 1149px) {
        position: static;
        float: left;
        margin-top: 20px;
    }

    @media (max-width: 450px) {
        width: 100%;
        max-width: none;
    }

    & select option:hover {
        background-color: red !important;
    }
`;

