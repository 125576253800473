import _ from 'lodash';

export default function diffObject(oldObject, newObject, rootProperty = '') {
    if (!_.isPlainObject(oldObject) || !_.isPlainObject(newObject)) {
        throw new Error(`
            A diff can only be made of 2 plain objects. 
            You tried to diff ${oldObject?.toString()} and ${newObject?.toString()}
        `);
    }

    // Because this method is recursive, the property path of a changed property
    // might need prefixed with all the properties that got to this recursive call
    const pathPrefix = rootProperty ? `${rootProperty}.` : '';

    // If any properties were added in newObject, they need to be explicitly added with null values
    // to the old object to show up in the diff.
    const newProperties = Object
        .entries(newObject)
        .filter(([key]) => !_.has(oldObject, key))
        .map(([key, value]) => [key, _.isPlainObject(value) ? {} : null]);

    // We actually don't want to look at properties that are removed in newObject. Ordinarily, a
    // method like this would, but in our case, information about the supplier is split up over several
    // pages, so newObject will only ever be a partial diff. Diffing every property in oldObject would
    // result in a lot of incorrect db entries saying properties were removed when they weren't
    const oldPropertiesInNewObject = Object
        .entries(oldObject)
        .filter(([key]) => _.has(newObject, key));
    const [
        oldEntriesWithObjectValues,
        oldEntriesWithComparableValues,
    ] = _.partition([...oldPropertiesInNewObject, ...newProperties], ([, value]) => _.isPlainObject(value));

    const diffOfProperties = oldEntriesWithComparableValues
        .filter(([property, value]) => !_.isEqual(value, newObject[property]))
        .map(([property, value]) => ({
            propertyPath: pathPrefix + property,
            oldValue: _.isArray(value) ? JSON.stringify(value) : value?.toString() ?? '',
            newValue: _.isArray(newObject[property]) ? JSON.stringify(newObject[property]) : newObject[property]?.toString() ?? '',
        }));
    const diffOfChildObjects = oldEntriesWithObjectValues
        .map(([property, value]) => diffObject(value, newObject[property], pathPrefix + property));
    return _.flattenDeep([...diffOfProperties, ...diffOfChildObjects]);
}
