import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {TextField, brandFont, Button, TextFieldSelect} from '@partssourceinc/react-ui-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/pro-light-svg-icons';
// import {useHistory} from 'react-router-dom';
import DatePicker from 'components/DatePicker';
import axios from 'axios';
import {handleOnCurrencyKeyDown} from 'utility'
import {validateMoney, validatePhoneNumber, validateEmail} from 'utils/validation';
const MAX_ALLOWED_CHARS = 900;
const MAX_CHARGE_AMOUT = 999999.99;
const PHONE_CHAR_LIMIT = 15;
const DESCRIPTION_CHAR_LIMIT = 450

// #region

const Label = styled.p`
font-size: 20px;
font-weight: normal;
margin-bottom: 15px;
margin-top: 38px;
clear: both;
`;

const TextBlock = styled(TextField)`
margin-bottom: 15px;

> div {
    width: 100%;
    height: 107px;
}
${brandFont('16px')}
`;

const Line = styled.hr`
border-top: 1px solid #949494;
height: 2px;
width: 100%;
float: left;
`;

const SaveButton = styled(Button)`
float: right;
`;

const CustomTextField = styled(TextField)`
max-width: 20em; 
min-width: 280px;
`;

const CustomTextFieldSelect = styled(TextFieldSelect)`
max-width: 20em; 
min-width: 280px;
margin-bottom: 16px;
`;

// const QuestionMarkIcon = styled(FontAwesomeIcon)`
// margin: 0 auto;
// display: flex;
// color: #009cf4;
// width: 39px !important;
// height: 39px !important;
// margin-bottom: 17px
// `;

const SuccessIcon = styled(FontAwesomeIcon)`
margin: 0 auto;
color: #03d0bf;
width: 29px !important;
height: 29px !important;
`;

// const ExclamationCircleIcon = styled(FontAwesomeIcon)`
// margin: 10px;
// display: flex;
// align-self: baseline;
// color: #ff9505;
// width: 24px !important;
// height: 24px !important;
// `;

const SpacedWrapper = styled.div`
margin: 0 auto;
width: 50%;
padding: 45px 0 75px 0;`

const PageTitle = styled.div`
color: rgb(0, 0, 0);
${brandFont('30px')}
font-weight: 300;
height: 38px;
letter-spacing: 0px;
text-align: center;
line-height: 1;
margin: 16px 0 22px 0;
`

const Confirmation = styled.div`
display: flex;
justify-content:space-evenly;
flex-direction:column;
place-items: center;
height: 30vh;
svg {
    margin: 0 auto;
}
p {
    font-weight: 600 !important;
    color: rgb(0, 0, 0);
    ${brandFont('16px')}
    height: 80px;
    letter-spacing: 0px;
    text-align: center;
    width: 490px;
}

p > a {
    color: #cccccc;
    text-decoration: underline

}
h1 {
    color: rgb(0, 0, 0);
    ${brandFont('30px')}
    font-weight: 300;
    height: 38px;
    letter-spacing: 0px;
    text-align: center;
    width: 490px;
}
`
const ErrorMessageContainer = styled.div`
${brandFont('12px')};
color: red;
`;

// #endregion
const NonAuthVdf = () => {
    const [loading, setLoading] = useState(false);
    const [maxDate, setMaxDate] = useState();
    const [company, setCompany] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [partsSourcePO, setPartsSourcePO] = useState();
    const [itemNumber, setItemNumber] = useState();
    const [itemDescription, setItemDescription] = useState();
    const [trackingNumber, setTrackingNumber] = useState();
    const [receivedOn, setReceivedOn] = useState();
    const [faultsFound, setFaultsFound] = useState();
    const [repairReplace, setRepairReplace] = useState();
    const [returnType, setReturnType] = useState();
    const [charges, setCharges] = useState();
    const [confirmation, setConfirmation] = useState(false);
    const [focusedFields, setFocusedFields] = useState([]);
    const returnTypeList = [{id: 1, ReturnType: 'Warranty'},{id: 2, ReturnType: 'Restock Return'},{id: 3, ReturnType: 'Exchange (Core)'}]

    const [errorMessage, setErrorMessages] = useState();

    const handleRepairsSave = () => {
        setLoading(true)
        const data = {
            'TrackingNumber': trackingNumber,
            'ReturnTypeId': returnType?.id || 0,
            'ProblemNote': faultsFound,
            'ReplacementNote': repairReplace,
            'Cost': Number(charges.replace('$', '')),
            'ContactPhoneNumber': phoneNumber,
            'Description': itemDescription,
            'PartNumber': itemNumber,
            'PoNumber': partsSourcePO,
            'ReceivedDate': receivedOn,
            'FirstName': firstName,
            'Company': company,
            'LastName': lastName,
            'Email': email,
            'IsNonAuthVdf': true,
        }
        axios.post(`/common/non-auth-vdf`, data)
            .then(() => {
                setFocusedFields([])
                setConfirmation(true)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            });

    }

    const handleUpdateCharge = (e) => {
        const value = e.target.value
        setFocusedFields([...new Set([...focusedFields, 'charges'])])
        let err = {...errorMessage};

        let realNumber = Number(value.replace(/[^0-9.-]+/g,''));
        
        if (realNumber > MAX_CHARGE_AMOUT) {
            err = 'Too long';
            setErrorMessages(err);
            return
        }
        if (Number(value) === '' || !validateMoney(value)) {
         
            if (value.trim() === '') {
                err = 'Invalid amount';
                setErrorMessages(err);
            } else if (!validateMoney(value)) {
                err = 'Invalid amount';
                setErrorMessages(err);
            } else {
                setCharges(value);
                err = null;
                setErrorMessages(err);
            }
            
        } else {
            if (!validateMoney(value)) {
                err = 'Invalid';
                setErrorMessages(err);

            } else {
                setCharges(value);
                err = null;
                setErrorMessages(err);
            }
        }
     
    }

    useEffect(() => {
        const today = new Date();
        const formattedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        setReceivedOn(formattedDate);
        setMaxDate(formattedDate); 
    }, []);

    return (
        <SpacedWrapper>
            {!confirmation && <div>
                <PageTitle>Vendor Discrepancy Form</PageTitle>
                <CustomTextField
                    label={'Company'}
                    type="text"
                    text={company}
                    value={company}
                    limit={70}
                    name={'Company'}
                    onChange={(e) => {
                        setCompany(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'company'])])
                    }}
                    showErrorMessage={(!company) && focusedFields.includes('company')}
                    errorMessage={'Required field'}
                    style={{paddingBottom: '15px'}}
                />
                <CustomTextField
                    label={'First Name'}
                    type="text"
                    text={firstName}
                    limit={25}
                    value={firstName}
                    name={'FirstName'}
                    onChange={(e) => {
                        setFirstName(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'firstName'])])
                    }}
                    showErrorMessage={(!firstName) && focusedFields.includes('firstName')}
                    errorMessage={'Required field'}
                    style={{paddingBottom: '15px'}}
                />
                <CustomTextField
                    label={'Last Name'}
                    type="text"
                    text={lastName}
                    limit={30}
                    value={lastName}
                    name={'LastName'}
                    onChange={(e) => {
                        setLastName(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'lastName'])])
                    }}
                    showErrorMessage={(!lastName) && focusedFields.includes('lastName')}
                    errorMessage={'Required field'}
                    style={{paddingBottom: '15px'}}
                />
                <CustomTextField
                    label={'Email'}
                    type="email"
                    text={email}
                    limit={65}
                    value={email}
                    name={'Email'}
                    onChange={(e) => {
                        setEmail(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'email'])])
                    }}
                    showErrorMessage={(!validateEmail(email) || !email) && focusedFields.includes('email')}
                    errorMessage={!email ? 'Required Field' : 'Invalid Email'}
                    style={{paddingBottom: '15px'}}
                />
                <CustomTextField
                    label={'Phone Number'}
                    type="text"
                    text={phoneNumber}
                    value={phoneNumber}
                    limit={PHONE_CHAR_LIMIT}
                    name={'phoneNumber'}
                    onChange={(e) => {
                        setPhoneNumber(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'phoneNumber'])])
                    }}
                    style={{paddingBottom: '15px'}}
                    errorMessage={'Invalid'}
                    showErrorMessage={(!phoneNumber || !validatePhoneNumber(phoneNumber)) && focusedFields.includes('phoneNumber')}
                />
                <CustomTextField
                    label={'PartsSource PO'}
                    type="text"
                    text={partsSourcePO}
                    value={partsSourcePO}
                    limit={55}
                    name={'PartsSourcePO'}
                    onChange={(e) => {
                        setPartsSourcePO(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'partsSourcePO'])])
                    }}
                    showErrorMessage={(!partsSourcePO) && focusedFields.includes('partsSourcePO')}
                    errorMessage={'Required field'}
                    style={{paddingBottom: '15px'}}
                />
                <CustomTextField
                    label={'Item Number'}
                    type="text"
                    text={itemNumber}
                    value={itemNumber}
                    name={'itemNumber'}
                    limit={55}
                    onChange={(e) => {
                        setItemNumber(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'itemNumber'])])
                    }}
                    showErrorMessage={(!itemNumber) && focusedFields.includes('itemNumber')}
                    errorMessage={'Required field'}
                    style={{paddingBottom: '15px'}}
                />
                <TextBlock
                    multiLine={true}
                    name="description"
                    id="description"
                    type="text"
                    placeholder="Please describe"
                    limit={DESCRIPTION_CHAR_LIMIT}
                    text={itemDescription}
                    value={itemDescription}
                    onChange={(e) => {
                        setItemDescription(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'itemDescription'])])
                    }}
                    showErrorMessage={!itemDescription && focusedFields.includes('itemDescription')}
                    errorMessage={'Required field'}
                    label="Item Description"
                />
                <Label>Tracking</Label>
                <CustomTextField
                    label={'Tracking Number'}
                    type="text"
                    name={'trackingNumber'}
                    text={trackingNumber}
                    limit={55}
                    value={trackingNumber}
                    onChange={(e) => {
                        setTrackingNumber(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'trackingNumber'])])
                    }}
                    showErrorMessage={(!trackingNumber) && focusedFields.includes('trackingNumber')}
                    errorMessage={'Required field'}
                    style={{paddingBottom: '15px'}}
                />
                <Label>Received On</Label>
                <DatePicker
                    value={receivedOn}
                    maxDate={maxDate}
                    text={receivedOn}
                    label="Received On"
                    onChange={(e) => {
                        setReceivedOn(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'receivedOn'])])
                    }}
                />
                {!receivedOn && focusedFields.includes('receivedOn') &&
                    <ErrorMessageContainer>
                        Required field
                    </ErrorMessageContainer>}
                <Label>Faults Found</Label>
                <TextBlock
                    multiLine={true}
                    name="faultsFound"
                    id="faultsFound"
                    type="text"
                    placeholder="Please describe"
                    limit={MAX_ALLOWED_CHARS}
                    text={faultsFound}
                    value={faultsFound}
                    onChange={(e) => {
                        setFaultsFound(e.target.value)
                        setFocusedFields([...new Set([...focusedFields, 'faultsFound'])])
                    }}
                    label="Faults Found"
                    showErrorMessage={!faultsFound && focusedFields.includes('faultsFound')}
                    errorMessage={`Required field`}
                />
                <Label>Repair/Replace</Label>
                <TextBlock
                    multiLine={true}
                    name="repairReplace"
                    id="repairReplace"
                    type="text"
                    placeholder="Please describe"
                    limit={MAX_ALLOWED_CHARS}
                    text={repairReplace}
                    value={repairReplace}
                    onChange={(e) => setRepairReplace(e.target.value)}
                    label="Description"
                    showErrorMessage={faultsFound && faultsFound.length > MAX_ALLOWED_CHARS}
                    errorMessage={`Only ${MAX_ALLOWED_CHARS} characters allowed`}
                />

                <CustomTextFieldSelect
                    keyField="id"
                    valueField="ReturnType"
                    onSelect={data => {
                        setReturnType(data)
                        setFocusedFields([...new Set([...focusedFields, 'returnType'])])
                    }}
                    listItems={returnTypeList}
                    selectedItem={returnType}
                    label="Return Type"
                    placeHolder="Return Type"
                    showErrorMessage={!returnType && focusedFields.includes('returnType')}
                    errorMessage={'Required field'}
                    disableClear={true}
                />
                
                <CustomTextField
                    label={'Charges'}
                    value={charges}
                    type="text"
                    errorMessage={errorMessage}
                    name={'charges'}
                    min={0}
                    limit={MAX_CHARGE_AMOUT}
                    onKeyDown={handleOnCurrencyKeyDown}
                    onChange={(e) => {
                        handleUpdateCharge(e)
                        setFocusedFields([...new Set([...focusedFields, 'charges'])])
                    }}
                    showErrorMessage={errorMessage && focusedFields.includes('charges') }
                    style={{paddingBottom: '10px'}} 
                />
                <Line />
                <SaveButton
                    disabled={(!phoneNumber || !validatePhoneNumber(phoneNumber)) 
                        || (!partsSourcePO) 
                        || (!itemNumber) 
                        || (!trackingNumber)
                        || !itemDescription 
                        || !returnType || (!charges || errorMessage) || charges < 0 || charges > MAX_CHARGE_AMOUT
                        || !validateMoney(charges) || !faultsFound || !receivedOn
                        || !validateEmail(email) || !company || !firstName || !lastName
                    }
                    primary={true}
                    onClick={() => handleRepairsSave()}
                    loading={loading}>
                    SUBMIT DISCREPANCY
                </SaveButton>
                <SaveButton
                    ternary={true}
                    onClick={() => {
                        window.history.back()
                    }}
                >
                    CANCEL
                </SaveButton>
            </div>}

            {confirmation && !loading &&
                <Confirmation>
                    <SuccessIcon icon={faCheckCircle} />
                    <h1>
                        Discrepancy Submitted
                    </h1>
                    <p>
                        The Non-reference VDF Form has been successfully submitted PartsSource for Suppliers.
                        If you have questions, please contact <a href="mailto:supplier_support@partsource.com">supplier_support@partsource.com </a> 
                        or call <a href="tel:877-497-6412">877-497-6412 </a>
                        and ask for the Supplier Pro representatives.
                    </p>
                </Confirmation>}
        </SpacedWrapper>
    )
}
export default NonAuthVdf;
