import React from 'react';
import styled from 'styled-components';
import SettingsGeneralInformationForm from './SettingsGeneralInformationForm';
import {useSelector} from 'react-redux';
import {BackLink, LeftArrow} from 'utils/styles';
import {faAngleLeft} from '@fortawesome/pro-regular-svg-icons';

const Container = styled.div`
    width: 100%;
    top: 1em;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const SettingsGeneralInformation = () => {
    const supplierName = useSelector(state => state.supplier.generalInfo.supplierName);
    const isLoading = useSelector(state => state.supplier.isLoading);

    return (
        <Container>
            <h1>General Information</h1>
            <span>
                <LeftArrow icon={faAngleLeft} />
                <BackLink to="/settings">Back to Company Profile</BackLink>
            </span>
            <h2>{supplierName}</h2>
            {!isLoading && <SettingsGeneralInformationForm />}
        </Container>
    );
};

export default SettingsGeneralInformation;
