/* eslint-disable indent */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import DatePicker from 'components/DatePicker';
import {TextField, brandFont, theme, Popup, Button, RadioButton, TextFieldSelect} from '@partssourceinc/react-ui-core';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    getPageAndTab,
    logEvent,
    getErrorMessage,
     handleOnCurrencyKeyDown,
   
} from 'utility';
import {validateMoney, validatePhoneNumber} from 'utils/validation';
import ErrorModal from 'components/ErrorModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/pro-regular-svg-icons';
import Attachments from 'components/AttachFile';
import {useSelector} from 'react-redux';
const MAX_CHARGE_AMOUT = 999999.99;
const Label = styled.p`
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 10px;
    clear: both;
`;

const TextBlock = styled(TextField)`
    margin-bottom: 15px;

    > div {
        width: 100%;
        height: 107px;
    }

    ${brandFont('16px')}
`;

const Reference = styled.div`
    font-family: "Source Sans Pro", sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 15px;

    a {
        font-size: 16px;
        text-decoration: underline;
        color: ${theme.grey1};
        margin-left: 15px;
    }
`;

 const Line = styled.hr`
    border-top: 1px solid #949494;
    height: 2px;
    width: 100%;
    float: left;
 `;

const RepairsModal = styled(Popup)`
    max-width: 700px;

    @media (max-width: 549px) {
        display: block;
    }
`;

const SaveButton = styled(Button)`
    float: right;
`;

const CenteredRadioButton = styled(RadioButton)`
    display: flex;

    label{
        padding-top: 3px;
        margin-bottom: 0px;
        padding-bottom: 3px;
        padding-left: 30px;
    }
`;

const CustomTextFieldSelect = styled(TextFieldSelect)`
    max-width: 20em; 
    min-width: 280px;
    margin-bottom: 16px;
`;

const CustomTextField = styled(TextField)`
   max-width: 20em; 
   min-width: 280px;
   float: left;
`;

const WarningMessageHandler = styled.p`
    color: red;
`;

 const Icon = styled(FontAwesomeIcon)`
    float: left;
    color: black;
    margin-top: 4px;
    margin-right: 5px;
    margin-left: 5px;
 `;

 const BlueText = styled.div`
    color: #166BAD;
    font-weight: 400;
    padding-left: 0;
    cursor: pointer;
    font-size: 16px;
    float: left;
    font-weight: 600;
    padding-left: 10px;
 `;

const PopupContent = styled.div`
    ${brandFont('16px')}
`;

const ReceivedDetails = ({isRgaOrder, rgaNumber, rgaId, orderId,
    lineItemId, trackingNumber, show, type, orderType, rgaType, tab, onCancel, onSubmit, poNumber = '', 
    itemDescription = '', partNumber = ''}) => {
    const [note, setNote] = useState('');
    const [faultsFound, setFaultsFound] = useState('');
     const [repairReplace, setRepairReplace] = useState('');
     const [contactPhoneNumber, setContactPhoneNumber] = useState('');
     const [charges, setCharges] = useState('0.00');
    const [receivedTrackingNumber, setTrackingNumber] = useState();
    const [date, setDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const [loading, setLoading] = useState(false);
    const [isAcceptable, setIsAcceptable] = useState();
    const [missingNotesError] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [faultsFoundTouched, setFaultsFoundTouched] = useState(false);
    const [notesTouched, setNotesTouched] = useState(false);
    const [repairReplaceTouched, setRepairReplaceTouched] = useState(false);
    const [trackingTouched, setTrackingTouched] = useState(false);
    const [returnTypeTouched, setReturnTypeTouched] = useState(false);
    const [phoneNumTouched, setPhoneNumTouched] = useState(false);
    const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [returnType, setReturnType] = useState();
    const [errorMessages, setErrorMessages] = useState();
    const cachedAttachments = useSelector(x => x.attachments.cachedAttachments);
    const rootUrl = process.env.REACT_APP_SPRO_SERVER_URL.replace('/api', '');
    const returnTypeList = [{id: 1, ReturnType: 'Warranty'},{id: 2, ReturnType: 'Restock Return'},{id: 3, ReturnType: 'Exchange (Core)'}]
    useEffect(() => {
        const today = new Date();
        const formattedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        setMaxDate(formattedDate);

        if (attachments.length === 0 && cachedAttachments.length > 0)
            setAttachments(cachedAttachments.filter(x => x.lineItemId === lineItemId));
    }, []);

    const loadVdfData = () => {
        setLoading(true);
         axios.get(`/order/${lineItemId}/vdf`)
            .then((resp) => {
                setDate(resp.data.receivedDate ? new Date(resp.data.receivedDate) : new Date());
                setIsAcceptable(mapAcceptable(resp.data.partAcceptableTypeId));
                setFaultsFound(resp.data.problemNote);
                setRepairReplace(resp.data.replacementNote);
                setContactPhoneNumber(resp.data.contactPhoneNumber);
                setCharges(resp.data.cost > 0 ? resp.data.cost : charges);
                setTrackingNumber(resp.data.trackingNumber || trackingNumber);
                if (resp.data.vdfHeaderId > 0)
                    setReturnType(returnTypeList.find(c => c.id === resp.data.returnTypeId));

                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setShowErrorPopup(true);
            });
    }

    useEffect(() => {
        loadVdfData();
    }, []);

    const mapAcceptable = (id) => {
        return id === 1 ? 'Yes' : id === 2 ? 'No' :
            id === 3 ? 'InspectLater' : null
    }

    const handleRepairsSave = async () => {
        const request = {
            note,
            dateReceived: date,
            lineItemId,
            isPartAcceptable: isAcceptable === 'Yes' ?
                true :
                isAcceptable === 'No' ?
                    false :
                    null,
            rgaNumber,
            ReturnType: returnType?.id,
            trackingNumber: receivedTrackingNumber,
            rgaType,
            tab: tab.id,
            rgaId,
            problemNote: isAcceptable === 'No' ? faultsFound : '',
            replacementNote: isAcceptable === 'No' ? repairReplace : '',
            orderType: orderType,
            attachments: attachments,
            cost: isAcceptable === 'No' ? Number(charges.toString().replace('$', '')) : 0,
            contactPhoneNumber: isAcceptable === 'No' ? contactPhoneNumber : '',
            poNumber: poNumber,
            itemDescription: itemDescription,
            partNumber: partNumber,
        };

        setLoading(true);

        await axios.post(`/order/receive`, request)
            .then((resp) => {             
                onSubmit(resp);

                const {page} = getPageAndTab();

                logEvent('RECEIVE ITEM', {
                    'Page': page,
                    'Tab': getPageAndTab().tab,
                    'Ref number': lineItemId,
                    'RGA ID': rgaId,
                    'Acceptable condition': isAcceptable === 'InspectLater' ? 'Inspect Later' : isAcceptable,
                });

            })
            .catch(() => {
                setLoading(false);
                setShowErrorPopup(true);
            });
    }

    const onAcceptabilityChange = ({target: {value}}) => {
        
        setIsAcceptable(value);
    }

    const setFocusToReasons = () => {
        document.getElementById(isRgaOrder ? 'acceptable' : 'notes').focus();
    }

    const hideErrorPopup = () => {
        setShowErrorPopup(false);
    }

    const handleUpdateNote = (e) => {
        const content = e.target.value;

        const isLongText = content.length > 900;
        setNote(isLongText ? content.substring(0, 1024) : content);
        setWarningMessage(isLongText ? '900 characters allowed' : null);
    }

    const handleUpdateFaultsFound = (e) => {
       const content = e.target.value;
       const isLongText = content.length > 900;
       setFaultsFound(isLongText ? content.substring(0, 1024) : content);
    }

    const handleUpdateRepairReplace = (e) => {
       const content = e.target.value;
       const isLongText = content.length > 900;
       setRepairReplace(isLongText ? content.substring(0, 1024) : content);
    }

    const handleUpdateCharge = (value) => {
       let err = {...errorMessages};    

       if (Number(value) === ' ' || Number(value) === 0 || !validateMoney(value)) {
         
           if (Number(value) === '0' && type !== 'exchange-return') {
               err = 'Invalid amount!';
               setErrorMessages(err);
           } else if (value.trim() === '') {
               err = 'Invalid amount!';
               setErrorMessages(err);
           } else if (!validateMoney(value)) {
               err = 'Invalid amount!';
               setErrorMessages(err);
           } else {
               setCharges(value);
               err = null;
               setErrorMessages(err);
           }
            
       } else {
           if (!validateMoney(value)) {
               err = 'Invalid!';
               setErrorMessages(err);

           } else {
               setCharges(value);
               err = null;
               setErrorMessages(err);
           }
       }
     
    }

    const handleUpdateContactPhoneNumber = (e) => {
       const content = e.target.value;
       setContactPhoneNumber(content);
    }

    const showAttachmentsPopup = () => {
       document.body.classList.add('modal-popup');
       document.getElementsByTagName('html')[0].className = 'modal-popup';
       setShowAttachmentPopup(true);
    }

    const onClose = () => {
        setShowAttachmentPopup(false);
        document.body.classList.remove('modal-popup');
        document.getElementsByTagName('html')[0].className = '';
    }

    const handleOnSave = (tempAttachment) => {
        let a = [...attachments];
        a.push(tempAttachment);
        setAttachments(a);
    }

    return (
        <RepairsModal
            show={show}
            onCancel={onCancel}
            hideButtons={true}>
            <Reference>
                <span>Update for Reference #{lineItemId}</span>
                <a href={`/${type === 'loaner' ? 'loaners-rentals' : type}-details/${poNumber ? `po/${poNumber}` : orderId}`}>View Details</a>
            </Reference>
            {(isRgaOrder || type === 'repair') && <div>
                <Label style={{marginBottom: 10}}>Shipping</Label>
                <CustomTextField
                    label={'Tracking Number'}
                    name={'trackingNumber'}
                    onChange={(e) => setTrackingNumber(e.target.value)}
                    text={receivedTrackingNumber}
                    value={receivedTrackingNumber}
                    limit={55}
                    onFocus={() => setTrackingTouched(true)}
                    showErrorMessage={(!receivedTrackingNumber && trackingTouched)}
                    errorMessage={'Required field'}
                    style={{marginBottom: 10}} />
            </div>}
            <><Label>Received On</Label></>
            <DatePicker style={{width: '285px', height: '50px'}} value={date} maxDate={maxDate}
                onHide={setFocusToReasons} onChange={(e) => setDate(e.target.value)} />
            {isRgaOrder && <div>
                <Label>Condition Acceptable</Label>
                <CenteredRadioButton
                    checked={isAcceptable === 'Yes'}
                    id="acceptable"
                    name="acceptable"
                    value="Yes"
                    label="Yes"     
                    onChange={onAcceptabilityChange} />
                <CenteredRadioButton
                    checked={isAcceptable === 'No'}
                    id="notAcceptable"
                    name="notAcceptable"
                    value="No"
                    label={`No (requires completion of the Vendor Discrepancy Form below)`}
                    onChange={onAcceptabilityChange} />
                <CenteredRadioButton
                    checked={isAcceptable === 'InspectLater'}
                    id="inspectLater"
                    name="inspectLater"
                    value="InspectLater"
                    label="Inspect Later"                        
                    onChange={onAcceptabilityChange} />
            </div>}
            <><Label>Note</Label></>
            <TextBlock
                multiLine={true}
                name="note"
                id="notes"
                type="text"
                onFocus={() => setNotesTouched(true)}
                placeholder="Notes"
                limit={900}
                text={note}
                onChange={handleUpdateNote}
                label="Note"
                showErrorMessage={isRgaOrder && isAcceptable === 'No' && warningMessage}
                errorMessage={warningMessage} />

            {isAcceptable === 'No' &&
                  <>
                   <Label>Your Contact Information</Label>
                   <CustomTextField
                    label={'Phone Number'}
                    type="text"
                    text={contactPhoneNumber}
                    value={contactPhoneNumber}
                    limit={15}
                    onFocus={() => setPhoneNumTouched(true)}
                    name={'phoneNumber'}
                    onChange={(e) => {
                        handleUpdateContactPhoneNumber(e)
                        setPhoneNumTouched(true)
                    }}
                    style={{paddingBottom: '15px'}}
                    errorMessage={'Invalid Phone Number'}
                    showErrorMessage={(!contactPhoneNumber || !validatePhoneNumber(contactPhoneNumber)) && phoneNumTouched}
                />
                   
                    <Label>Faults Found</Label>
                    <TextBlock
                        multiLine={true}
                        name="faultsFound"
                        id="faultsFound"
                       onFocus={() => setFaultsFoundTouched(true)}
                       type="text"
                        placeholder="Please describe"
                       limit={900}
                        text={faultsFound}
                        onChange={handleUpdateFaultsFound}
                        label="Faults Found"
                        showErrorMessage={isRgaOrder && isAcceptable === 'No' && !faultsFound && faultsFoundTouched}
                        errorMessage={`900 characters allowed`} />
                    
                    <Label>Repair / Replace</Label>
                    <TextBlock
                        multiLine={true}
                        name="repairReplace"
                       id="repairReplace"
                        onFocus={() => setRepairReplaceTouched(true)}
                        type="text"
                        placeholder="Please describe"
                        limit={900}
                        text={repairReplace}
                        onChange={handleUpdateRepairReplace}
                        label="Repair/Replace needed"
                        showErrorMessage={isRgaOrder && isAcceptable === 'No' && !repairReplace && repairReplaceTouched}
                        errorMessage={`900 characters allowed`} />
                    
                    <CustomTextFieldSelect
                        keyField="id"
                        valueField="ReturnType"
                        onSelect={data => {
                            setReturnType(data)
                            setReturnTypeTouched(true)
                        }}
                        listItems={returnTypeList}
                        selectedItem={returnType}
                        label="Return Type"
                        placeHolder="Return Type"
                        showErrorMessage={!returnType && returnTypeTouched}
                        errorMessage={'Required field'}
                        disableClear={true}
                    />
                    <CustomTextField
                        label={'Charges'}
                        value={charges}
                        text={charges}
                        showErrorMessage={errorMessages || (!charges || charges < 0 || charges > MAX_CHARGE_AMOUT)}
                        errorMessage="Invalid amount!"
                        name={'charges'}
                        max={MAX_CHARGE_AMOUT}
                        onKeyDown={handleOnCurrencyKeyDown}
                        onChange={(e) => handleUpdateCharge(e.target.value)}
                        style={{paddingBottom: '15px'}}
                    />
                    
                    <Label>Documentation</Label>
                    <Icon aria-hidden="true" icon={faCopy} />
                    <BlueText onClick={showAttachmentsPopup}>
                        Attachments ({attachments.filter(x => x.lineItemId === lineItemId).length})
                    </BlueText>
                    <Line />
                 </>
            }
            <SaveButton
                disabled={!date || (isRgaOrder && (!isAcceptable || !receivedTrackingNumber)) || (type === 'repair' && !receivedTrackingNumber) ||
                    (isAcceptable === 'No' && (!repairReplace || !faultsFound ||
                    !contactPhoneNumber || !validatePhoneNumber(contactPhoneNumber) ||
                    (Number(charges) < 0 && type !== 'exchange-return') || (!charges || errorMessages) || !returnType))
                }
                primary={true}
                onClick={handleRepairsSave}
                loading={loading}>
                CONFIRM
            </SaveButton>
            <ErrorModal
                show={showErrorPopup}
                popupTitle={'Receive'}
                popupMessage={getErrorMessage('repair')}
                onCancel={() => hideErrorPopup()} />
            {showAttachmentPopup && <Popup
                onCancel={onClose}
                show={showAttachmentPopup}
                onConfirm={onClose}
                confirmText="Ok"
                cancelText="Cancel">
                <PopupContent>
                    <Attachments
                        attachments={attachments}
                        isReadOnly={false}
                        memorySave={true}
                        lineItemId={lineItemId}
                        allowMultipleFiles={true}
                        handleOnSave={handleOnSave} />
                </PopupContent>
            </Popup>}
        </RepairsModal >
    );
}

export default ReceivedDetails;

ReceivedDetails.propTypes = {
    orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lineItemId: PropTypes.number,
    type: PropTypes.string,
    orderType: PropTypes.string,
    rgaType: PropTypes.string,
    tab: PropTypes.string,
    rgaNumber: PropTypes.string,
    trackingNumber: PropTypes.string,
    rgaId: PropTypes.number,
    show: PropTypes.bool,
    isRgaOrder: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    itemDescription: PropTypes.string,
    poNumber: PropTypes.string,
    partNumber: PropTypes.string,
};
