import axios from 'axios';
import _ from 'lodash';
import diffObject from 'utils/diffObject';

const GET_SUPPLIER = 'GET_SUPPLIER';
const GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS';
const SAVE_GENERAL_INFO = 'SAVE_GENERAL_INFO';
const SAVE_GENERAL_INFO_SUCCESS = 'SAVE_GENERAL_INFO_SUCCESS';
const SAVE_SERVICES_AND_INVENTORY = 'SAVE_SERVICES_AND_INVENTORY';
const SAVE_SERVICES_AND_INVENTORY_SUCCESS = 'SAVE_SERVICES_AND_INVENTORY_SUCCESS';
const SAVE_FEES_AND_PAYMENT = 'SAVE_FEES_AND_PAYMENT';
const SAVE_FEES_AND_PAYMENT_SUCCESS = 'SAVE_FEES_AND_PAYMENT_SUCCESS';
const SAVE_HOURS_OF_OPERATION = 'SAVE_HOURS_OF_OPERATION';
const SAVE_HOURS_OF_OPERATION_SUCCESS = 'SAVE_HOURS_OF_OPERATION_SUCCESS';
const SAVE_SHIPPING_AND_RETURNS = 'SAVE_SHIPPING_AND_RETURNS';
const SAVE_SHIPPING_AND_RETURNS_SUCCESS = 'SAVE_SHIPPING_AND_RETURNS_SUCCESS';
const SAVE_CERTIFICATIONS = 'SAVE_CERTIFICATIONS';
const SAVE_CERTIFICATIONS_SUCCESS = 'SAVE_CERTIFICATIONS_SUCCESS';
const UPDATE_TRADE_REFERENCE = 'UPDATE_TRADE_REFERENCE';
const UPDATE_TRADE_REFERENCE_SUCCESS = 'UPDATE_TRADE_REFERENCE_SUCCESS';
const DELETE_TRADE_REFERENCE_FROM_SET = 'DELETE_TRADE_REFERENCES_FROM_SET';
const ADD_TRADE_REFERENCE_TO_SET = 'ADD_TRADE_REFERENCE_TO_SET';
const ADD_TRADE_REFERENCE_TO_SET_SUCCESS = 'ADD_TRADE_REFERENCE_TO_SET_SUCCESS';
const VALIDATE_ADDRESS = 'VALIDATE_ADDRESS';
const ERROR = 'ERROR';

export const defaultState = {
    supplierId: 0,
    isLoading: false,
    isSaving: false,
    alternateAddress: null,
    generalInfo: {
        supplierName: '',
        companyWebsite: '',
        DUNSNumber: '',
        phone: '',
        fax: '',
        totalNumberEmployees: '',
        contactPersonId: null,
        orgChartFileData: null,
        orgChartFileType: '',
        orgChartFileName: '',
        contactPersonName: '',
        contactPersonTitle: '',
        contactPersonEmail: '',
        contactPersonPhone: '',
        contactForPricingId: null,
        contactForPricingName: '',
        contactForPricingTitle: '',
        contactForPricingEmail: '',
        contactForPricingPhone: '',
        presidentId: null,
        presidentName: '',
        presidentTitle: '',
        presidentEmail: '',
        presidentPhone: '',
        qualityManagerId: null,
        qualityManagerName: '',
        qualityManagerTitle: '',
        qualityManagerEmail: '',
        qualityManagerPhone: '',
        salesManagerId: null,
        salesManagerName: '',
        salesManagerTitle: '',
        salesManagerEmail: '',
        salesManagerPhone: '',
        coreBusiness: '',
        businessDateEstablished: '',
        businessPreviousNames: '',
        businessParentCompany: '',
        businessSubsidiary: '',
        physicalAddressId: 0,
        physicalAddressStreet: '',
        physicalAddressStreet2: '',
        physicalAddressCity: '',
        physicalAddressState: '',
        physicalAddressZipcode: '',
        physicalAddressCountry: '',
        returnsAddressId: 0,
        returnsAddressStreet: '',
        returnsAddressStreet2: '',
        returnsAddressCity: '',
        returnsAddressState: '',
        returnsAddressZipcode: '',
        returnsAddressCountry: '',
        billingAddressId: 0,
        billingAddressStreet: '',
        billingAddressStreet2: '',
        billingAddressCity: '',
        billingAddressState: '',
        billingAddressZipcode: '',
        billingAddressCountry: '',
        tradeReferences: [],
        hoursOfOperation: '',
        hoursOfOpTimeZone: '',
    },
    hoursOfOperation: {
        mondayHours: null,
        tuesdayHours: null,
        wednesdayHours: null,
        thursdayHours: null,
        fridayHours: null,
        saturdayHours: null,
        sundayHours: null,
        hoursTimeZone: null,
    },
    servicesAndInventory: {
        providesInvPricingInExcel: null,
        providesDimWeights: null,
        installOrTechSupport: null,
        testBeforeShipping: null,
        providesCertOfConformance: null,
        analysisOfFailuresDOA: null,
        sellsNewOemReplacement3rdParty: null,
        partMeetsOemSpecs: null,
        partMeetsOemFormFitFunction: null,
        provideFMEA: null,
        provideMTBF: null,
        servicesInventoryEdits: '',
        suspended: '',
    },
    shippingAndReturns: {
        afterHoursId: null,
        afterHoursName: '',
        afterHoursPhone: '',
        useFedEx: null,
        useUPS: null,
        blindShip: null,
        dropShip: null,
        thirdPartyAcctNumbers: null,
        shippingReturnsEdits: null,
        shipCutoffTimeUTC: null,
        supplierTimeZone: null,
        exchangeCoreDays: null,
        acceptsUnopenPackageReturns: null,
        percentRestockFee: null,
        acceptsOpenPackageReturns: null,
        returnPolicyDays: null,
        rgaNumberRequired: null,
        rgaContactId: null,
        rgaContactName: '',
        rgaContactEmail: '',
    },
    addCertifications: {
        name: null,
        obtainDate: null,
        expireDate: null,
        document: null,
        fileName: null,
        fileType: null,
    },
    certifications: [
        {
            name: null,
            logo: null,
            expireDate: null,
        },
    ],
    feesAndPayment: {
        hasEdiAbility: null,
        wantsEDIAssistance: null,
        contactId: null,
        contactName: '',
        contactEmail: '',
        eDIFlags: null,
        ediAcceptPo: null,
        ediAcceptInvoices: null,
        ediAcceptPriorityOrders: null,
        ediAcceptShippingMethods: null,
        ediAcknowledgePo: null,
        ediSendShipManifest: null,
        ediSendInvoices: null,
        minimumOrderFeeId: null,
        minimumOrderFee: 0,
        minimumOrderDetails: '',
        minimumThirdParty: null,
        orderProcessingFeeId: null,
        orderProcessingFee: 0,
        orderProcessingDetails: '',
        orderProcessingThirdParty: null,
        dropShipFeeId: null,
        dropShipFee: 0,
        dropShipDetails: '',
        dropShipThirdParty: null,
        otherFeesId: null,
        otherFeesFee: 0,
        otherFeesDetails: '',
        otherFeesThirdParty: null,
        feesPaymentEdits: null,
    },
};

export const ContactType = {
    ContactPerson: 0,
    ContactForPricing: 1,
    President: 2,
    QualityManager: 3,
    SalesManager: 4,
    EDI: 5,
    AfterHours: 6,
    RGA: 7,
};

export const AddressType = {
    UNDEFINED: 0,
    PHYS: 1,
    BILL: 2,
    REFUND: 3,
    COLL: 4,
    MAIL: 5,
    SHIP: 6,
    RETURNS: 7,
    REPAIRS: 8,
};

export const DimWeights = {
    No: 0,
    YesWithPictures: 1,
    YesWithoutPictures: 2,
};

const reducer = (state = _.cloneDeep(defaultState), action) => {
    switch (action.type) {
        case GET_SUPPLIER: {
            return {...state, isLoading: true};
        }
        case SAVE_CERTIFICATIONS:
        case SAVE_SHIPPING_AND_RETURNS:
        case SAVE_FEES_AND_PAYMENT:
        case SAVE_HOURS_OF_OPERATION:
        case SAVE_SERVICES_AND_INVENTORY:
        case SAVE_GENERAL_INFO: {
            return {...state, isSaving: true};
        }
        case VALIDATE_ADDRESS:
        {
            const {alternateAddress} = action;
            return {...state, alternateAddress};
        }
        case UPDATE_TRADE_REFERENCE: {
            const {tradeReference, rowIndex} = action;
            const {generalInfo} = state;
            let updatedGeneralInfo = _.merge({}, generalInfo);
            updatedGeneralInfo.tradeReferences[rowIndex] = tradeReference;

            return {...state, generalInfo: updatedGeneralInfo};
        }
        case DELETE_TRADE_REFERENCE_FROM_SET: {
            const {rowIndex} = action;
            const {generalInfo} = state;

            let updatedGeneralInfo = _.merge({}, generalInfo);
            updatedGeneralInfo.tradeReferences.splice(rowIndex, 1);

            return {...state, generalInfo: updatedGeneralInfo};
        }
        case ADD_TRADE_REFERENCE_TO_SET: {
            const {tradeReference} = action;
            const {generalInfo} = state;
            let updatedGeneralInfo = _.merge({}, generalInfo);

            tradeReference.tradeRefId = null;
            updatedGeneralInfo.tradeReferences.push(tradeReference);

            return {...state, generalInfo: updatedGeneralInfo};
        }
        case SAVE_SERVICES_AND_INVENTORY_SUCCESS:
        case SAVE_HOURS_OF_OPERATION_SUCCESS:
        case SAVE_CERTIFICATIONS_SUCCESS:
        case SAVE_GENERAL_INFO_SUCCESS:
        case SAVE_SHIPPING_AND_RETURNS_SUCCESS:
        case SAVE_FEES_AND_PAYMENT_SUCCESS:
        case ADD_TRADE_REFERENCE_TO_SET_SUCCESS:
        case UPDATE_TRADE_REFERENCE_SUCCESS: {
            return {...state, isSaving: false};
        }
        case GET_SUPPLIER_SUCCESS: {
            const {supplier} = action;
            let result = {
                hoursOfOpTimeZone: supplier.hoursOfOpTimeZone,
                hoursOfOperation: supplier.hoursOfOperation,
            };

            let contactPerson = supplier.contacts?.find(c => c.contactType === ContactType.ContactPerson);
            result.contactPersonId = contactPerson?.contactId ?? null;
            result.contactPersonName = contactPerson?.name ?? '';
            result.contactPersonTitle = contactPerson?.title ?? '';
            result.contactPersonEmail = contactPerson?.email ?? '';
            result.contactPersonPhone = contactPerson?.phone ?? '';

            let contactForPricing = supplier.contacts?.find(c => c.contactType === ContactType.ContactForPricing);
            result.contactForPricingId = contactForPricing?.contactId ?? null;
            result.contactForPricingName = contactForPricing?.name ?? '';
            result.contactForPricingTitle = contactForPricing?.title ?? '';
            result.contactForPricingEmail = contactForPricing?.email ?? '';
            result.contactForPricingPhone = contactForPricing?.phone ?? '';

            let president = supplier.contacts?.find(c => c.contactType === ContactType.President);
            result.presidentId = president?.contactId ?? null;
            result.presidentName = president?.name ?? '';
            result.presidentTitle = president?.title ?? '';
            result.presidentEmail = president?.email ?? '';
            result.presidentPhone = president?.phone ?? '';

            let qualityManager = supplier.contacts?.find(c => c.contactType === ContactType.QualityManager);
            result.qualityManagerId = qualityManager?.contactId ?? null;
            result.qualityManagerName = qualityManager?.name ?? '';
            result.qualityManagerTitle = qualityManager?.title ?? '';
            result.qualityManagerEmail = qualityManager?.email ?? '';
            result.qualityManagerPhone = qualityManager?.phone ?? '';

            let salesManager = supplier.contacts?.find(c => c.contactType === ContactType.SalesManager);
            result.salesManagerId = salesManager?.contactId ?? null;
            result.salesManagerName = salesManager?.name ?? '';
            result.salesManagerTitle = salesManager?.title ?? '';
            result.salesManagerEmail = salesManager?.email ?? '';
            result.salesManagerPhone = salesManager?.phone ?? '';

            result.supplierName = supplier.supplierName;
            result.companyWebsite = supplier.webSite;
            result.DUNSNumber = supplier.duns;
            result.phone = supplier.supplierPhone;
            result.fax = supplier.supplierFax;
            result.totalNumberEmployees = supplier.numberOfEmployees;
            result.coreBusiness = supplier.coreBusiness;
            result.businessDateEstablished = supplier.dateEstablished ? new Date(supplier.dateEstablished) : '';
            result.businessPreviousNames = supplier.previousNames;
            result.businessParentCompany = supplier.parentCompany;
            result.businessSubsidiary = supplier.subsidiary;
            result.orgChartFileData = supplier.orgChartFileData;
            result.orgChartFileType = supplier.orgChartFileType;
            result.orgChartFileName = supplier.orgChartFileName;

            let physicalAddress = supplier?.physicalAddress;
            result.physicalAddressId = physicalAddress?.addressId ?? 0;
            result.physicalAddressStreet = physicalAddress?.address1 ?? '';
            result.physicalAddressStreet2 = physicalAddress?.address2 ?? '';
            result.physicalAddressCity = physicalAddress?.city ?? '';
            result.physicalAddressState = physicalAddress?.state ?? '';
            result.physicalAddressZipcode = physicalAddress?.zip ?? '';
            result.physicalAddressCountry = physicalAddress?.country ?? '';

            let returnsAddress = supplier?.returnsAddress;
            result.returnsAddressId = returnsAddress?.addressId ?? 0;
            result.returnsAddressStreet = returnsAddress?.address1 ?? '';
            result.returnsAddressStreet2 = returnsAddress?.address2 ?? '';
            result.returnsAddressCity = returnsAddress?.city ?? '';
            result.returnsAddressState = returnsAddress?.state ?? '';
            result.returnsAddressZipcode = returnsAddress?.zip ?? '';
            result.returnsAddressCountry = returnsAddress?.country ?? '';

            let billingAddress = supplier?.billingAddress;
            result.billingAddressId = billingAddress?.addressId ?? 0;
            result.billingAddressStreet = billingAddress?.address1 ?? '';
            result.billingAddressStreet2 = billingAddress?.address2 ?? '';
            result.billingAddressCity = billingAddress?.city ?? '';
            result.billingAddressState = billingAddress?.state ?? '';
            result.billingAddressZipcode = billingAddress?.zip ?? '';
            result.billingAddressCountry = billingAddress?.country ?? '';

            result.tradeReferences = supplier?.tradeReferences ?? [];

            const servicesAndInventory = _.assign({}, _.pick(supplier, _.keys(defaultState.servicesAndInventory)));
            const hoursOfOperation = _.assign({}, _.pick(supplier, _.keys(defaultState.hoursOfOperation)));
            const shippingAndReturns = _.assign({}, _.pick(supplier, _.keys(defaultState.shippingAndReturns)));
            const addCertifications = _.assign({}, _.pick(supplier, _.keys(defaultState.addCertifications)));
            const {certifications} = supplier;

            let feesResult = {};
            const fees = supplier?.fees;
            feesResult.minimumOrderFeeId = fees?.find(f => f.typeDescription === 'MinimumOrder')?.feeId;
            feesResult.minimumOrderFee = fees?.find(f => f.typeDescription === 'MinimumOrder')?.amount;
            feesResult.minimumOrderDetails = fees?.find(f => f.typeDescription === 'MinimumOrder')?.details;
            feesResult.minimumThirdParty = fees?.find(f => f.typeDescription === 'MinimumOrder')?.thirdPartyOnly;
            feesResult.orderProcessingFeeId = fees?.find(f => f.typeDescription === 'OrderProcessing')?.feeId;
            feesResult.orderProcessingFee = fees?.find(f => f.typeDescription === 'OrderProcessing')?.amount;
            feesResult.orderProcessingDetails = fees?.find(f => f.typeDescription === 'OrderProcessing')?.details;
            feesResult.orderProcessingThirdParty = fees?.find(f => f.typeDescription === 'OrderProcessing')?.thirdPartyOnly;
            feesResult.dropShipFeeId = fees?.find(f => f.typeDescription === 'DropShip')?.feeId;
            feesResult.dropShipFee = fees?.find(f => f.typeDescription === 'DropShip')?.amount;
            feesResult.dropShipDetails = fees?.find(f => f.typeDescription === 'DropShip')?.details;
            feesResult.dropShipThirdParty = fees?.find(f => f.typeDescription === 'DropShip')?.thirdPartyOnly;
            feesResult.otherFeesId = fees?.find(f => f.typeDescription === 'Other')?.feeId;
            feesResult.otherFeesFee = fees?.find(f => f.typeDescription === 'Other')?.amount;
            feesResult.otherFeesDetails = fees?.find(f => f.typeDescription === 'Other')?.details;
            feesResult.otherFeesThirdParty = fees?.find(f => f.typeDescription === 'Other')?.thirdPartyOnly;

            let ediContact = supplier.contacts?.find(c => c.contactType === ContactType.EDI);
            feesResult.contactId = ediContact?.contactId ?? null;
            feesResult.contactName = ediContact?.name ?? '';
            feesResult.contactEmail = ediContact?.email ?? '';

            const feesAndPayment = _.assign({}, _.pick(supplier, _.keys(defaultState.feesAndPayment)), feesResult);

            let afterHours = supplier.contacts?.find(c => c.contactType === ContactType.AfterHours);
            shippingAndReturns.afterHoursId = afterHours?.contactId ?? null;
            shippingAndReturns.afterHoursName = afterHours?.name ?? '';
            shippingAndReturns.afterHoursPhone = afterHours?.phone ?? '';

            let rgaContact = supplier.contacts?.find(c => c.contactType === ContactType.RGA);
            shippingAndReturns.rgaContactId = rgaContact?.contactId ?? null;
            shippingAndReturns.rgaContactName = rgaContact?.name ?? '';
            shippingAndReturns.rgaContactEmail = rgaContact?.email ?? '';

            return {
                ...state,
                generalInfo: {...result},
                servicesAndInventory,
                hoursOfOperation,
                shippingAndReturns,
                certifications,
                addCertifications,
                feesAndPayment,
                supplierId: supplier?.supplierId,
                supplierName: supplier?.supplierName,
                isLoading: false,
            };
        }
        case ERROR:
        default:
            return {...state, isSaving: false};
    }
}

export function saveServicesAndInventory(values) {
    return async (dispatch, getState) => {
        dispatch({type: SAVE_SERVICES_AND_INVENTORY});
        
        const {supplier: {supplierId, supplierName, servicesAndInventory}, user: {userId}} = getState();

        let supplierInfo = {
            supplierId,
            supplierName,
            ...values,
        };

        supplierInfo.providesDimWeights = parseInt(supplierInfo.providesDimWeights);

        try {
            axios.post('/supplier/trackchanges', {
                supplierId,
                supplierName,
                userId,
                entityName: 'SupplierPro',
                changedProperties: diffObject(servicesAndInventory, values, 'servicesAndInventory'),
            });
            const {data: {supplier}} = await axios.post(`/supplier/services`, supplierInfo);
            return dispatch({type: SAVE_SERVICES_AND_INVENTORY_SUCCESS, supplier});
        } catch (e) {
            console.error(e);
        }
    };
}

export function saveFeesAndPayment(values, updateStatus) {
    return async (dispatch, getState) => {
        dispatch({type: SAVE_FEES_AND_PAYMENT});

        let feesAndPayment = {
            fees: [],
            contacts: [],
        };

        const {
            supplier: {
                supplierId,
                supplierName,
                feesAndPayment: oldFeesAndPayment,
                feesAndPayment: {
                    minimumOrderFeeId,
                    orderProcessingFeeId,
                    dropShipFeeId,
                    otherFeesId,
                },
            },
            user: {userId},
        } = getState();

        const {
            minimumOrderFeesUpdated,
            orderProcessingFeesUpdated,
            dropShipFeesUpdated,
            otherFeesUpdated,
            updateContact,
        } = updateStatus;

        const {
            hasEdiAbility,
            wantsEDIAssistance,
            ediFlags,
            minimumOrderFee,
            minimumOrderDetails,
            minimumThirdParty,
            orderProcessingFee,
            orderProcessingDetails,
            orderProcessingThirdParty,
            dropShipFee,
            dropShipDetails,
            dropShipThirdParty,
            otherFeesFee,
            otherFeesDetails,
            otherFeesThirdParty,
            feesPaymentEdits,
            contactName,
            contactEmail,
            contactId,
        } = values;

        if (minimumOrderFeesUpdated) {
            feesAndPayment.fees.push({
                feeId: minimumOrderFeeId,
                feeType: 'MinimumOrder',
                amount: minimumOrderFee || 0,
                details: minimumOrderDetails,
                thirdPartyOnly: minimumThirdParty,
            });
        }

        if (orderProcessingFeesUpdated) {
            feesAndPayment.fees.push({
                feeId: orderProcessingFeeId,
                feeType: 'OrderProcessing',
                amount: orderProcessingFee || 0,
                details: orderProcessingDetails,
                thirdPartyOnly: orderProcessingThirdParty,
            });
        }

        if (dropShipFeesUpdated) {
            feesAndPayment.fees.push({
                feeId: dropShipFeeId,
                feeType: 'DropShip',
                amount: dropShipFee || 0,
                details: dropShipDetails,
                thirdPartyOnly: dropShipThirdParty,
            });
        }

        if (otherFeesUpdated) {
            feesAndPayment.fees.push({
                feeId: otherFeesId,
                feeType: 'Other',
                amount: otherFeesFee || 0,
                details: otherFeesDetails,
                thirdPartyOnly: otherFeesThirdParty,
            });
        }

        if (updateContact) {
            feesAndPayment.contacts.push({
                contactType: ContactType.EDI,
                contactId: contactId,
                name: contactName,
                email: contactEmail,
            });
        }

        feesAndPayment.hasEdiAbility = hasEdiAbility;
        feesAndPayment.wantsEDIAssistance = wantsEDIAssistance;
        feesAndPayment.ediFlags = ediFlags;
        feesAndPayment.feesPaymentEdits = feesPaymentEdits;

        let supplierInfo = {
            supplierId,
            supplierName,
            ...feesAndPayment,
        };

        try {
            axios.post('/supplier/trackchanges', {
                supplierId,
                supplierName,
                userId,
                entityName: 'SupplierPro',
                changedProperties: diffObject(oldFeesAndPayment, values, 'feesAndPayment'),
            });
            const {data: {supplier}} = await axios.post(`/supplier/fees`, supplierInfo);
            return dispatch({type: SAVE_FEES_AND_PAYMENT_SUCCESS, supplier});
        } catch (e) {
            console.error(e);
        }
    };
}

export function saveHoursOfOperation(values) {
    return async (dispatch, getState) => {
        dispatch({type: SAVE_HOURS_OF_OPERATION});

        const {supplier: {supplierId}} = getState();

        let supplierInfo = {
            supplierId,
            ...values,
        };

        try {
            const {data: {supplier}} = await axios.post(`/supplier/hours`, supplierInfo);
            return dispatch({type: SAVE_HOURS_OF_OPERATION_SUCCESS, supplier});
        } catch (e) {
            console.error(e);
        }
    };
}

export function saveCertifications(values) {
    return async (dispatch, getState) => {
        dispatch({type: SAVE_CERTIFICATIONS});

        const {supplier: {supplierId, supplierName}} = getState();
        const {name, obtainDate, expireDate, document, fileName, fileType, requests} = values;

        let supplierInfo = {
            supplierId,
            supplierName,
            certificationsEdits: requests,
            certificationsToAdd: {
                supplierId,
                supplierName,
                cerificationId: 0,
                name,
                obtainDate,
                expireDate,
                documentData: document,
                documentName: fileName,
                documentType: fileType,
                isActive: false,
            },
        };

        try {
            const {data: {supplier}} = await axios.post(`/supplier/certification`, supplierInfo);
            return dispatch({type: SAVE_CERTIFICATIONS_SUCCESS, supplier});
        } catch (e) {
            console.error(e);
            const {supplier} = getState();
            return dispatch({type: ERROR, supplier});       

        }
    };
}

export function saveShippingAndReturns(values, updateAfterHoursContact, updateRGAContact) {
    return async (dispatch, getState) => {
        dispatch({type: SAVE_SHIPPING_AND_RETURNS});
        
        const {
            supplier: {supplierId, shippingAndReturns: oldSupplierInfo, shippingAndReturns: {afterHoursId, rgaContactId}},
            user: {userId},
        } = getState();
        const {afterHoursName, afterHoursPhone, rgaContactName, rgaContactEmail} = values;

        let supplierInfo = {
            supplierId,
            contacts: [],
            ...values,
        };

        if (updateAfterHoursContact) {
            supplierInfo.contacts.push({
                contactType: ContactType.AfterHours,
                contactId: afterHoursId,
                name: afterHoursName,
                phone: afterHoursPhone,
            });
        }

        if (updateRGAContact) {
            supplierInfo.contacts.push({
                contactType: ContactType.RGA,
                contactId: rgaContactId,
                name: rgaContactName,
                email: rgaContactEmail,
            });
        }

        let supplerToSave = _.omit(supplierInfo, ['afterHoursName', 'afterHoursPhone', 'rgaContactName', 'rgaContactEmail']);

        try {
            axios.post('/supplier/trackchanges', {
                supplierId,
                userId,
                entityName: 'SupplierPro',
                changedProperties: diffObject(oldSupplierInfo, values, 'shippingAndReturns'),
            });
            const {data: {supplier}} = await axios.post(`/supplier/shipping`, supplerToSave)
            return dispatch({type: SAVE_SHIPPING_AND_RETURNS_SUCCESS, supplier});
        } catch (e) {
            console.error(e);
        }
    };
}

export function saveGeneralInfo(values, updateStatus) {
    return async (dispatch, getState) => {

        dispatch({type: SAVE_GENERAL_INFO});

        let generalInfo = {
            contacts: [],
        };

        const {
            supplier: {
                supplierId,
                supplierName,
                generalInfo: oldSupplierInfo,
                generalInfo: {
                    contactPersonId,
                    contactForPricingId,
                    presidentId,
                    qualityManagerId,
                    salesManagerId,
                    physicalAddressId,
                    returnsAddressId,
                    billingAddressId,
                    tradeReferences,
                },
            },
            user: {userId},
        } = getState();

        const {
            contactPersonUpdated,
            contactForPricingUpdated,
            presidentUpdated,
            qualityManagerUpdated,
            salesManagerUpdated,
            physicalAddressUpdated,
            returnsAddressUpdated,
            billingAddressUpdated,
        } = updateStatus;

        const {
            contactPersonName,
            contactPersonTitle,
            contactPersonEmail,
            contactPersonPhone,
            contactForPricingName,
            contactForPricingTitle,
            contactForPricingEmail,
            contactForPricingPhone,
            presidentName,
            presidentTitle,
            presidentEmail,
            presidentPhone,
            qualityManagerName,
            qualityManagerTitle,
            qualityManagerEmail,
            qualityManagerPhone,
            salesManagerName,
            salesManagerTitle,
            salesManagerEmail,
            salesManagerPhone,
            companyWebsite,
            DUNSNumber,
            phone,
            fax,
            totalNumberEmployees,
            coreBusiness,
            businessDateEstablished,
            businessPreviousNames,
            businessParentCompany,
            businessSubsidiary,
            orgChartFileData,
            orgChartFileName,
            orgChartFileType,
            hoursOfOpTimeZone,
            hoursOfOperation,
            physicalAddressStreet,
            physicalAddressStreet2,
            physicalAddressCity,
            physicalAddressState,
            physicalAddressZipcode,
            physicalAddressCountry,
            returnsAddressStreet,
            returnsAddressStreet2,
            returnsAddressCity,
            returnsAddressState,
            returnsAddressZipcode,
            returnsAddressCountry,
            billingAddressStreet,
            billingAddressStreet2,
            billingAddressCity,
            billingAddressState,
            billingAddressZipcode,
            billingAddressCountry,
        } = values;

        if (contactPersonUpdated) {
            generalInfo.contacts.push({
                contactType: ContactType.ContactPerson,
                contactId: contactPersonId,
                name: contactPersonName,
                title: contactPersonTitle,
                email: contactPersonEmail,
                phone: contactPersonPhone,
            });
        }

        if (contactForPricingUpdated) {
            generalInfo.contacts.push({
                contactType: ContactType.ContactForPricing,
                contactId: contactForPricingId,
                name: contactForPricingName,
                title: contactForPricingTitle,
                email: contactForPricingEmail,
                phone: contactForPricingPhone,
            });
        }

        if (presidentUpdated) {
            generalInfo.contacts.push({
                contactType: ContactType.President,
                contactId: presidentId,
                name: presidentName,
                title: presidentTitle,
                email: presidentEmail,
                phone: presidentPhone,
            });
        }

        if (qualityManagerUpdated) {
            generalInfo.contacts.push({
                contactType: ContactType.QualityManager,
                contactId: qualityManagerId,
                name: qualityManagerName,
                title: qualityManagerTitle,
                email: qualityManagerEmail,
                phone: qualityManagerPhone,
            });
        }

        if (salesManagerUpdated) {
            generalInfo.contacts.push({
                contactType: ContactType.SalesManager,
                contactId: salesManagerId,
                name: salesManagerName,
                title: salesManagerTitle,
                email: salesManagerEmail,
                phone: salesManagerPhone,
            });
        }

        if (physicalAddressUpdated) {
            generalInfo.physicalAddress = {
                addressType: 'PHYSICAL',
                addressTypeId: AddressType.PHYS,
                addressId: physicalAddressId,
                address1: physicalAddressStreet,
                address2: physicalAddressStreet2,
                city: physicalAddressCity,
                state: physicalAddressState,
                zip: physicalAddressZipcode,
                country: physicalAddressCountry,
            };
        }

        if (returnsAddressUpdated) {
            generalInfo.returnsAddress = {
                addressType: 'PRODUCT RETURNS',
                addressTypeId: AddressType.RETURNS,
                addressId: returnsAddressId,
                address1: returnsAddressStreet,
                address2: returnsAddressStreet2,
                city: returnsAddressCity,
                state: returnsAddressState,
                zip: returnsAddressZipcode,
                country: returnsAddressCountry,
            };
        }

        if (billingAddressUpdated) {
            generalInfo.billingAddress = {
                addressType: 'BILLING',
                addressTypeId: AddressType.BILL,
                addressId: billingAddressId,
                address1: billingAddressStreet,
                address2: billingAddressStreet2,
                city: billingAddressCity,
                state: billingAddressState,
                zip: billingAddressZipcode,
                country: billingAddressCountry,
            };
        }

        generalInfo.orgChartFileName = orgChartFileName || '';
        generalInfo.orgChartFileData = orgChartFileData || '';
        generalInfo.orgChartFileType = orgChartFileType || '';
        generalInfo.tradeReferences = tradeReferences || '';
        generalInfo.webSite = companyWebsite || '';
        generalInfo.duns = DUNSNumber || '';
        generalInfo.supplierPhone = phone;
        generalInfo.supplierFax = fax || '';
        generalInfo.numberOfEmployees = totalNumberEmployees || null;
        generalInfo.coreBusiness = coreBusiness || '';
        generalInfo.dateEstablished = businessDateEstablished ?? null;
        generalInfo.previousNames = businessPreviousNames || '';
        generalInfo.parentCompany = businessParentCompany || '';
        generalInfo.subsidiary = businessSubsidiary || '';
        generalInfo.supplierId = supplierId;
        generalInfo.supplierName = supplierName;
        generalInfo.hoursOfOperation = hoursOfOperation;
        generalInfo.hoursOfOpTimeZone = hoursOfOpTimeZone;

        try {
            axios.post('/supplier/trackchanges', {
                supplierId,
                userId,
                entityName: 'SupplierPro',
                changedProperties: diffObject(oldSupplierInfo, values, 'generalInfo'),
            });
            const {data: {supplier}} = await axios.post(`/supplier/general`, generalInfo);
            return dispatch({type: SAVE_GENERAL_INFO_SUCCESS, supplier});
        } catch (e) {
            console.error(e);
        }
    };
}

export function getSupplier(supplierId) {
    return async (dispatch, getState) => {
        try {
            dispatch({type: GET_SUPPLIER});
            const {data: {supplier}} = await axios.get(`/supplier/${supplierId}`);
            return dispatch({type: GET_SUPPLIER_SUCCESS, supplier});
        } catch (e) {
            console.error(e);
        }
    };
}

export function isAddressValid(addressType, values) {
    return async (dispatch, getState) => {
        let address = {};

        switch (addressType) {
            case AddressType.PHYS:
                address.street1 = values.physicalAddressStreet;
                address.street2 = values.physicalAddressStreet2;
                address.city = values.physicalAddressCity;
                address.state = values.physicalAddressState;
                address.country = values.physicalAddressCountry;
                address.postalCode = values.physicalAddressZipcode;
                break;
            case AddressType.BILL:
                address.street1 = values.billingAddressStreet;
                address.street2 = values.billingAddressStreet2;
                address.city = values.billingAddressCity;
                address.state = values.billingAddressState;
                address.country = values.billingAddressCountry;
                address.postalCode = values.billingAddressZipcode;
                break;
            case AddressType.RETURNS:
                address.street1 = values.returnsAddressStreet;
                address.street2 = values.returnsAddressStreet2;
                address.city = values.returnsAddressCity;
                address.state = values.returnsAddressState;
                address.country = values.returnsAddressCountry;
                address.postalCode = values.returnsAddressZipcode;
                break;
            default:
                break;
        }

        return await dispatch(validateAddress(address));
    }
}

export function validateAddress(address) {
    return async (dispatch, getState) => {
        try {
            const {data: response} = await axios.post(`/user/address/validate`, address);
    
            if (response.isValid && response.isOriginalAddressValid) {
                dispatch({type: VALIDATE_ADDRESS, alternateAddress: null});
                return true;
            } else if (!response.isOriginalAddressValid && response.alternateAddress) {
                const alternateAddress = {
                    street1: response.alternateAddress.street1,
                    street2: response.alternateAddress.street2,
                    city: response.alternateAddress.city,
                    state: response.alternateAddress.state,
                    country: response.alternateAddress.country,
                    postalCode: response.alternateAddress.postalCode,
                }
                dispatch({type: VALIDATE_ADDRESS, alternateAddress});
            } else {
                dispatch({type: VALIDATE_ADDRESS, alternateAddress: address});
            }
    
            return false;
        } catch (e) {
            console.error(e);
        }
    };
}

export function getAddress(address, addressType) {
    return (dispatch, getState) => {
        let updatedAddress = {};
        switch (addressType) {
            case AddressType.PHYS:
                updatedAddress.physicalAddressStreet = address.street1;
                updatedAddress.physicalAddressStreet2 = address.street2;
                updatedAddress.physicalAddressCity = address.city;
                updatedAddress.physicalAddressState = address.state;
                updatedAddress.physicalAddressCountry = address.country;
                updatedAddress.physicalAddressZipcode = address.postalCode;
                break;
            case AddressType.BILL:
                updatedAddress.billingAddressStreet = address.street1;
                updatedAddress.billingAddressStreet2 = address.street2;
                updatedAddress.billingAddressCity = address.city;
                updatedAddress.billingAddressState = address.state;
                updatedAddress.billingAddressCountry = address.country;
                updatedAddress.billingAddressZipcode = address.postalCode;
                break;
            case AddressType.RETURNS:
                updatedAddress.returnsAddressStreet = address.street1;
                updatedAddress.returnsAddressStreet2 = address.street2;
                updatedAddress.returnsAddressCity = address.city;
                updatedAddress.returnsAddressState = address.state;
                updatedAddress.returnsAddressCountry = address.country;
                updatedAddress.returnsAddressZipcode = address.postalCode;
                break;
            default:
                break;
        }
        return updatedAddress;
    }
}

export function updateTradeReferenceToSet(tradeReference, rowIndex) {
    return (dispatch, getState) => {
        dispatch({type: UPDATE_TRADE_REFERENCE, tradeReference, rowIndex});
    };
}

export function addTradeReferenceToSet(tradeReference) {
    return (dispatch, getState) => {
        dispatch({type: ADD_TRADE_REFERENCE_TO_SET, tradeReference});
    };
}

export function deleteTradeReferenceFromSet(rowIndex) {
    return async (dispatch, getState) => {
        const {supplier: {generalInfo: {tradeReferences}}} = getState();
        const id = tradeReferences[rowIndex].tradeRefId;

        if (id) {
            try {
                await axios.delete(`/setting/tradereference/${id}`);
            } catch (e) {
                console.error(e);
            }
        }

        dispatch({type: DELETE_TRADE_REFERENCE_FROM_SET, rowIndex});
    };
}

export default reducer;
