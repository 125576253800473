import React, {useState, useRef, useEffect} from 'react';
import Slider from 'react-slick';
import * as PropTypes from 'prop-types';
import StepBox from './StepBox';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'primeicons/primeicons.css';
import 'components/css/Carousel/slick-theme.css';
import 'components/css/Carousel/stepsCarousel.css';
import 'components/css/Carousel/carousel.css';

const StepsCarousel = (props) => {
    const {steps, bid = {}, loaner, repair, currentStep, onSetSelectedStep, onDeleteStep, conditionCodes, loanerConditionCodes} = props;
    const [selectedStep, setSelectedStep] = useState(currentStep);
    const [wizzardSteps, setWizzardSteps] = useState([]);
    const [showArrows, setShowArrows] = useState(false);

    const sliderRef = useRef();

    useEffect(() => {
        
        setSelectedStep(currentStep);
        setShowArrows(true);
        if (currentStep !== 0 && sliderRef.current.props.arrows === false)
            setShowArrows(true);

        if (sliderRef && sliderRef.current && sliderRef.current.slickGoTo)
            sliderRef.current.slickGoTo(currentStep);
    }, [currentStep]);

    useEffect(() => {
        if (steps) {
            setWizzardSteps(steps);
        }
    }, [steps]);
    
    const beforeSlideChange = (current, next) => onSetSelectedStep(next);
    
    const onSelectedStepLocal = (event, index) => {
        
        if (onSetSelectedStep)
            onSetSelectedStep(index);
    };
     
    const onDeleteStepLocal = (index) => {
        onDeleteStep(index);
    }

    const calculateCondition = (index) => {
        if (!bid.loaner_LineItemId) {
            if (bid.orderType !== 'REPAIR') {
                return props.conditions[index]
            } else {
                return repair;
            }            
        } else {
            
            if (index === 0) {                
                return loaner
            } else {
                return repair
            }
        }
    }

    return (
        <div className="steps-carousel">
                                                
            <Slider
                ref={sliderRef}
                arrows={showArrows}
                autoplay={false}
                dots={false}
                initialSlide={0}
                centerMode={true}
                focusOnSelect={false}
                variableWidth={true}
                beforeChange={(current, next) => beforeSlideChange(current, next)}
                infinite={false}
                responsive={[
                    {
                        breakpoint: 820,
                        settings: {
                            arrows: true,
                            slidesToShow: 1,
                            centerMode: false,
                            slidesToScroll: 1,
                        },
                    },
                ]}
            >
                { 
                    wizzardSteps ? wizzardSteps.map((s ,i) => (
                        <StepBox                             
                            className={i === steps.length - 1 ? 'last' : 'not-last'} 
                            key={i} 
                            index={i} 
                            type={s.type} 
                            title={s.title} 
                            description={s.description}
                            bid={props.bid}
                            condition={calculateCondition(i - 1)}                         
                            completed={wizzardSteps[i].type === 'completed'}
                            inactive={wizzardSteps[i].type !== 'completed' && wizzardSteps[i].type !== 'inProgress'}
                            valid={s.valid}
                            isDeletable={s.isDeletable}
                            onSelectedStep={(e, j) => onSelectedStepLocal(e, j)} 
                            onDeleteStep={(e) => onDeleteStepLocal(e)} 
                            active={i === selectedStep}
                            loanerConditionCodes={loanerConditionCodes}
                            conditionCodes={conditionCodes}
                        />
                    )) : null
                }            
            </Slider>            
        </div>
    );
};

StepsCarousel.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.object).isRequired, 
    bid: PropTypes.shape({}),
    loaner: PropTypes.shape({}),
    repair: PropTypes.shape({}),
    conditions: PropTypes.arrayOf(
        PropTypes.shape({

        })
    ),
    onSelectedStep: PropTypes.func,    
    conditionCodes: PropTypes.arrayOf(PropTypes.object),
    loanerConditionCodes: PropTypes.arrayOf(PropTypes.object),
    onDeleteStep: PropTypes.func, 
    onSetSelectedStep: PropTypes.func,    
    currentStep: PropTypes.number.isRequired,    
};

export default StepsCarousel;
