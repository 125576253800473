import React, {useState} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {useSelector} from 'react-redux';
import SettingsEditor from 'components/SettingsEditor';
import {useDispatch} from 'react-redux';
import {getSiteSettings} from 'reducers/settings';

const Title = styled.h1`
    margin-bottom: 3rem;
`;

const SiteManagement = () => {
    const siteSettings = useSelector(state => state.settings.siteSettings);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    async function saveSettings(newSettings) {
        setIsLoading(true);
        await axios.post('/setting', newSettings);
        dispatch(getSiteSettings());
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            <Title>Site Management</Title>
            <SettingsEditor
                settings={siteSettings?.settings}
                loading={isLoading}
                onSave={saveSettings}
            />
        </React.Fragment>
    )
};

export default SiteManagement;
