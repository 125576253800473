import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import {Popup} from '@partssourceinc/react-ui-core/components';

const PopupContent = styled.p`    
    font-size: 14px;
`;

const Title = styled.h1`
    margin-bottom: 16px;
    font-size: 18px;
`;

const ErrorModal = ({popupTitle, popupMessage, show = false, onCancel, hideCancel}) => {
    
    return (
        <Popup
            onCancel={onCancel}
            onConfirm={onCancel}
            show={show}                
            confirmText="Ok"
            cancelText="Cancel"
            hideCancel={hideCancel} >                 
            <Title>{popupTitle}</Title>
            <PopupContent>{popupMessage}</PopupContent>            
        </Popup>)
};

ErrorModal.propTypes = {
    popupTitle: PropTypes.string,
    popupMessage: PropTypes.string, 
    show: PropTypes.bool,
    hideCancel: PropTypes.bool,
    onCancel: PropTypes.func,   
};

export default ErrorModal;
