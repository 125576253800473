import React from 'react';
import * as PropTypes from 'prop-types';

const RepairsCardDescription = (props) => {
    const {cardData, showCompanyInDescription} = props;

    return (
        <>
            <span className="detail">
                Qty: <span>{cardData.quantity}</span>
            </span>

            <span className="detail">
                OEM: <span>{cardData.manufacturer}</span>
            </span>

            <span className="detail">
                Item #: <span>{cardData.partNumber}</span>
            </span>

            <span className="detail">
                Condition: <span>{cardData.condition}</span>
            </span>

            <span className="detail">
                Serial Number: <span>{cardData.serialNumber}</span>
            </span>

            <span className="detail">
                PO#: <span>{cardData?.displayPo ?? 'Pending'}</span>
            </span>

            <span className="detail">
                Vendor Order #: <span>{cardData.vendorOrderNumber}</span>
            </span>

            <span className="detail">
                Notes: <span>{cardData.notesCount ?? 0}</span>
            </span>

            {showCompanyInDescription &&
                <span className="detail">
                    Company: <span>{cardData.company}</span>
                </span>
            }
        </>);
}

RepairsCardDescription.propTypes = {
    cardData: PropTypes.shape({
        company: PropTypes.string,
        condition: PropTypes.string,
        manufacturer: PropTypes.string,
        notesCount: PropTypes.number,
        partNumber: PropTypes.string,
        quantity: PropTypes.number,
        serialNumber: PropTypes.string,
        displayPo: PropTypes.string,
        vendorOrderNumber: PropTypes.string,
    }),
    showCompanyInDescription: PropTypes.bool,
}

export default RepairsCardDescription;
