import React, {useState} from 'react';
import styled from 'styled-components';
import {RadioButton, brandFont, Popup} from '@partssourceinc/react-ui-core';
import * as PropTypes from 'prop-types';
import {AddressType} from 'reducers/supplier';
import _ from 'lodash';

const Wrapper = styled.div`
    h1 {
        margin-bottom: 35px;
    }
`;

const Title = styled.span`
    ${brandFont('20px')}
`;

const SelectionItem = styled(RadioButton)`
    ${brandFont('16px')}
    overflow: auto;
    label {
        width: 230px;
    }
`;

const AddressSelectionDialog = (props) => {
    const {alternateAddress, originalAddress, type, onConfirm, onClose} = props;
    const [selectedAddress, setSelectedAddress] = useState('originalAddress');
    const hasAlternateAddress = !_.isEqual(originalAddress, alternateAddress);

    const formattedAlternateAddress = `${alternateAddress?.street1}, ${alternateAddress?.city}, 
        ${alternateAddress?.state}, ${alternateAddress?.postalCode}, ${alternateAddress?.country}`;

    const formatterOriginalAddress = `${originalAddress?.street1}, ${originalAddress?.city}, 
        ${originalAddress?.state}, ${originalAddress?.postalCode}, ${originalAddress?.country}`;

    const onAddressSelect = ({target: {name}}) => {
        setSelectedAddress(name);
    }

    const handleConfirm = () => {
        const address = selectedAddress === 'originalAddress' ? originalAddress : alternateAddress;
        onConfirm(address, type);
    }

    return (
        <Popup
            show={true}
            confirmText="USE THE SELECTED ADDRESS"
            cancelText="CANCEL AND EDIT"
            onCancel={onClose}
            onConfirm={() => handleConfirm()}>
            <Wrapper>
                <h1>
                    {
                        type === AddressType.PHYS ? 'Confirm Physical Address' :
                            type === AddressType.BILL ? 'Confirm Billing Address' :
                                'Confirm Return Address'
                    }
                </h1>
                {hasAlternateAddress && <p>A validated address was returned. Select an address and
                click the “Use The Selected Address” button or to go back and edit the address, click the
                    “Cancel And Edit” button.</p>}
                {!hasAlternateAddress && <p>
                    Click the “Use The Selected Address” button or to go back and edit the address, click the
                    “Cancel And Edit” button.
                </p>}
                <div>
                    <Title>Original Address</Title>
                    <SelectionItem
                        checked={selectedAddress === 'originalAddress'}
                        id="Original Address"
                        name="originalAddress"
                        label={formatterOriginalAddress}
                        value="originalAddress"
                        onChange={onAddressSelect} />
                    {hasAlternateAddress && <Title>Validated Address</Title>}
                    {hasAlternateAddress && <SelectionItem
                        checked={selectedAddress === 'alternateAddress'}
                        id="alternateAddress"
                        name="alternateAddress"
                        label={formattedAlternateAddress}
                        value="alternateAddress"
                        onChange={onAddressSelect} />}
                </div>
            </Wrapper>
        </Popup>
    );
}

AddressSelectionDialog.propTypes = {
    alternateAddress: PropTypes.shape({
        street1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        postalCode: PropTypes.string,
        country: PropTypes.string,
    }).isRequired,
    originalAddress: PropTypes.shape({
        street1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        postalCode: PropTypes.string,
        country: PropTypes.string,
    }).isRequired,
    type: PropTypes.oneOf([AddressType.BILL, AddressType.PHYS, AddressType.RETURNS]).isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default AddressSelectionDialog;
