import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamation} from '@fortawesome/free-solid-svg-icons';
import {faCopy} from '@fortawesome/pro-regular-svg-icons';
import {HeaderRow} from 'components/DetailsStyledComponents';
import {Popup, brandFont} from '@partssourceinc/react-ui-core';
import PhotoAttachment from 'components/AttachPhoto';

const Img = styled.img`
    width: 200px;        
`;

const Row = styled.div`
    margin-bottom: 1rem;
`;

const Subtitle = styled.div`
    font-size: 16px;
    float: left;
`;

const BoldSubtitle = styled(Subtitle)`
    font-weight: 600;
    padding-left: 10px;
`;

const CHDExclamation = styled(FontAwesomeIcon)`
    width: 1em !important;
    height: 1em !important;
    padding: 2px;
    color: #ff0000;
    border: 1px solid;
    border-radius: 50%;
    font-size: 16px;
    margin-right: 3px;
`;

const CHD = styled.span`
    color: #ff0000;
    font-weight: 600;
    margin-top: 5px;
`;

const Past30MinutesExclamation = styled(FontAwesomeIcon)`
    width: 1em !important;
    height: 1em !important;
    padding: 2px;
    color: #fbcbcb;
    border: 1px solid;
    border-radius: 50%;
    font-size: 16px;
    margin-right: 3px;
`;

const Icon = styled(FontAwesomeIcon)`
    float: left;
    color: black;
    margin-top: 4px;
    margin-right: 5px;
    margin-left: 5px;
`;

const BlueText = styled(BoldSubtitle)`
    color: #166BAD;
    font-weight: 400;
    padding-left: 0;
    cursor: pointer;
`;

const EmailLink = styled.a`
    color: #166BAD;
    font-weight: 400;  
`;

const PopupContent = styled.p`
    ${brandFont('16px')}
`;

const BidHeader = (props) => {
    const {
        bid = {},
        attachments = [],
    } = props;

    const dateEntered = new moment.utc(bid.dateEnteredUTC).toDate();
    const dateNow = moment().utc().toDate();
    const diff = (dateNow - dateEntered);
    const diffMins = Math.floor((diff / 1000) / 60);
    const isPast30Minutes = diffMins > 30;

    const cachedAttachments = useSelector(state => state.attachments.cachedAttachments.filter(x => x.lineItemId === bid?.lineItemId));
    const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
    const minDate = moment.utc('2000-01-01');

    const showAttachmentsPopup = () => {

        document.body.classList.add('modal-popup');
        document.getElementsByTagName('html')[0].className = 'modal-popup';

        setShowAttachmentPopup(true);
    }

    const onClose = () => {
        setShowAttachmentPopup(false);
        document.body.classList.remove('modal-popup');
        document.getElementsByTagName('html')[0].className = '';
    }

    const mailtoLink = 'mailto:' + bid.sourcerEmail;

    return (
        <div className="row" >
            <div className="col-xs-12 col-sm-2 col-lg-2">
                {bid?.imagePath !== '' &&
                    <Img src={bid?.imagePath} alt="Bid Image" />}
                {bid?.imagePath === '' &&
                    <Img src="https://dam.partssource.com/m/3ec994b8367d4a93/Web_447_w-Catalog-Fallback-Image.jpg"
                        alt="Bid Image" />}
            </div>
            <div className="col-xs-12 col-sm-5 col-lg-3">
                <Row className="row">
                    <HeaderRow className="col-11">
                        {(bid?.urgencyDescription === 'CHD' || bid?.urgencyDescription === 'STAT') ? (
                            <div>
                                <CHDExclamation icon={faExclamation} />
                                <CHD>Urgent</CHD>
                            </div>
                        ) : bid?.status === 'REQUESTED' && isPast30Minutes ?
                            <div>
                                <Past30MinutesExclamation icon={faExclamation} />
                                <span>Past 30 Minutes</span>
                            </div>
                            : <div>&nbsp;</div>}
                        <Subtitle>Reference #: </Subtitle><BoldSubtitle id="lblLineItemId">{bid?.lineItemId}</BoldSubtitle>
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>Qty: </Subtitle><BoldSubtitle id="lblQuantity">{bid?.quantity}</BoldSubtitle>
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>OEM: </Subtitle><BoldSubtitle id="lblOEM">{bid?.manufacturer}</BoldSubtitle>
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>Item #: </Subtitle><BoldSubtitle id="lblItem">{bid?.partNumber}</BoldSubtitle>
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>Model: </Subtitle><BoldSubtitle id="lblModel">{bid?.model}</BoldSubtitle>
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>Order Type: </Subtitle><BoldSubtitle id="lblOrderType">{bid?.orderType}</BoldSubtitle>
                    </HeaderRow>
                </Row>
            </div>
            <div className="col-xs-12 col-sm-5 col-lg-7">
                <Row className="row">
                    <HeaderRow className="col-11">
                        <div>&nbsp;</div>
                        <Subtitle>Requested: </Subtitle><BoldSubtitle id="lblRequested">
                            {bid?.dateEntered !== undefined &&
                                moment(bid?.dateEntered).format('MM/D/YYYY h:mm A')
                            }</BoldSubtitle>
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>Submitted: </Subtitle>
                        <BoldSubtitle id="lblSubmitted">{(bid?.dateSubmitted !== undefined &&
                            moment(bid?.dateSubmitted).isAfter(minDate))
                            ? moment(bid?.dateSubmitted).format('MM/D/YYYY h:mm A')
                            : ''
                        }</BoldSubtitle>
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>Sourcer: </Subtitle>
                        {bid.sourcer?.toUpperCase() !== 'SYSTEM USE ONLY' &&
                            <BoldSubtitle id="lblSourcer">{bid?.sourcer}</BoldSubtitle>
                        }
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>Sourcer Phone: </Subtitle><BoldSubtitle id="lblSourcerPhone">{bid?.sourcerPhone}</BoldSubtitle>
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>Sourcer Email: </Subtitle>
                        {bid.sourcerEmail?.toLowerCase() !== 'system@partssource.com' ?
                            <BoldSubtitle id="lblSourcerEmail">
                                <EmailLink href={mailtoLink}>{bid.sourcerEmail}</EmailLink>
                            </BoldSubtitle>
                            :
                            <BoldSubtitle id="lblSourcerEmail">
                                <EmailLink href="mailto:sourcing@partssource.com">sourcing@partssource.com</EmailLink>
                            </BoldSubtitle>
                        }                            
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>Company: </Subtitle><BoldSubtitle id="lblCompany">{bid?.company}</BoldSubtitle>
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Icon aria-hidden="true" icon={faCopy} />
                        <BlueText id="lblAttachments" onClick={() => showAttachmentsPopup()}>
                            Attachments ({(attachments && attachments.length > 0) ? attachments.length : cachedAttachments.length}) </BlueText>
                    </HeaderRow>
                </Row>
            </div>
            {showAttachmentPopup && <Popup
                onCancel={onClose}
                show={showAttachmentPopup}
                onConfirm={onClose}
                confirmText="Ok"
                cancelText="Cancel">
                <PopupContent>
                    <PhotoAttachment
                        memorySave={bid?.status === 'REQUESTED'}
                        images={attachments}
                        isReadOnly={bid?.status !== 'REQUESTED'}
                        lineItemId={bid?.lineItemId}
                        customSave={false}
                    />
                </PopupContent>
            </Popup>}
        </div>
    );
};

export default BidHeader;

BidHeader.propTypes = {
    bid: PropTypes.shape({}),
    attachments: PropTypes.arrayOf(
        PropTypes.shape()
    ),
};
