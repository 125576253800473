import {getFeatureFlags} from 'reducers/configuration';
import {getListData} from 'reducers/bids';
import {getUserDefaults, getUserPolicies, loadTimeZone, getUserDashboardSettings} from 'reducers/user'
import {
    getPartESDReasons,
    getRepairESDReasons,
    getLoanerESDReasons,
    getPartConditionCodes,
    getRepairConditionCodes,
    getLoanerConditionCodes,
} from 'reducers/common'
import {
    getCompanySettings, 
    getCompanyRMASetting, 
    getSiteSettings, 
    getCompanyFacilities, 
    getMenuSettings,
} from 'reducers/settings';

export default function init() {
    return async (dispatch, getState) => {
        try {
            const {isEmployee, companyId, tokenInfo, selectedFacility} = getState().user;

            let results = null;

            dispatch(getSiteSettings());
            dispatch(getFeatureFlags());

            if (!tokenInfo) return;
            if (!isEmployee) {
                results = await Promise.all([
                    dispatch(getUserDefaults()),
                    dispatch(getUserPolicies()),
                    dispatch(getMenuSettings()),
                    dispatch(getCompanySettings(companyId)),
                    dispatch(getCompanyRMASetting(companyId)),
                    dispatch(getCompanyFacilities()),
                    dispatch(getUserDashboardSettings()),
                ]);

                dispatch(getListData());
                dispatch(loadTimeZone());

                dispatch(getPartESDReasons());
                dispatch(getRepairESDReasons());
                dispatch(getLoanerESDReasons());
            
                dispatch(getPartConditionCodes());
                dispatch(getRepairConditionCodes());
                dispatch(getLoanerConditionCodes());
            }

            if (isEmployee && selectedFacility?.id) {
                dispatch(getCompanySettings(selectedFacility?.id));
                dispatch(getCompanyRMASetting(isEmployee ? selectedFacility?.id : companyId));
            }            

            return results;
        } catch (e) {
            console.error(e);
        }
    }
}
