import React, {useState} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';

import SettingsEditor from 'components/SettingsEditor';
import SupplierSelectionDialog from 'components/SupplierSelectionDialog';
import {useDispatch} from 'react-redux';
import {getCompanySettings} from 'reducers/settings';

const Title = styled.h1`
    margin-bottom: 3rem;
`;

const CompanySettings = () => {
    const selectedFacility = useSelector(state => state.user.selectedFacility);
    const companySettings = useSelector(state => state.settings.companySettings);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const [showSupplierSelectionDialog, setShowSupplierSelectionDialog] = useState(true);

    async function saveSettings(newSettings) {
        setIsLoading(true);
        await axios.post(`/setting/company/${selectedFacility.id}`, newSettings);
        dispatch(getCompanySettings(selectedFacility.id));
        setIsLoading(false);
    }

    if (!selectedFacility) {
        const onCancel = () => history.push('/admin/company');
        const onSelectFacility = () => setShowSupplierSelectionDialog(false);

        return (
            <SupplierSelectionDialog
                show={showSupplierSelectionDialog}
                onCancel={onCancel}
                onSelectFacility={onSelectFacility}
            />
        );
    }

    return (
        <React.Fragment>
            <Title>Company Settings</Title>
            <SettingsEditor
                settings={companySettings?.settings}
                loading={isLoading}
                onSave={saveSettings}
            />
        </React.Fragment>
    );
};

export default CompanySettings;
