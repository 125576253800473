import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {setSelectedOrder, setSelectedOrderTab, setSelectedOrderView, setCachedOrders} from 'reducers/orders';
import axios from 'axios';
import styled from 'styled-components';
import withViewToggle from 'components/OrdersHOC';
import PropTypes from 'prop-types';
import {GridKeys} from 'utils/gridKeys/orderGridKeys';
import MultiView, {ViewType, GridNames} from 'components/MultiView';

const Container = styled.div`
    width: 100%;
    top: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const LegendSpan = styled.span`
    font-size: 14px;
    line-height: 18px;
    font-weight: '100';
    margin-left: 5px;
    padding-right: 20px;
`;

const LegendWrapper = styled.div`
    margin-top: 15px;
    position: relative;
`;

const UrgentLegend = styled.div`
    background-color: #fbcbcb;
    width: 15px;
    height: 15px;
    margin-left: 15px;
    margin-top: 2px;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: -2px;
`;

const LegendTitle = styled.span` 
    font-weight: 600;
    font-size: 14px;
`;

const PendingDueDate = styled.span`
    background-color: #ffca82;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-top: 2px;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: -2px;
`;

const Orders = (props) => {
    const {setViewRef, isMobile, isPhone, view, setView} = props;
    const gridName = GridNames.orders.id;
    const history = useHistory();
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOrder, setGridSelectedOrder] = useState(null);
    const cachedOrders = useSelector(state => state.orders.cachedOrders);
    const cachedView = useSelector(state => state.orders.selectedOrderView);

    const dispatch = useDispatch();

    const today = moment().startOf('day');

    if (selectedOrder != null) {
        handleSelectedOrder();
    }

    useEffect(() => {
        getCachedData();
    }, []);

    const getData = async () => {
        setLoading(true);
        getGridPreferences();
        const response = await axios.get(`order/`);
        await dispatch(setCachedOrders(response.data));
        setData(response.data);
        setLoading(false);
    }

    const setColumnList = (columnList) => {
        setColumns(columnList);
    }

    const getCachedData = async () => {
        if (cachedOrders.length === 0) {
            getData();
        } else {
            getGridPreferences();
            setView(cachedView);
            setViewRef(cachedView);
            setData(cachedOrders);
            setLoading(false);
        }
    }

    const handleSelect = (order) => {
        let currentOrder = {...selectedOrder};
        currentOrder = order;
        setGridSelectedOrder(currentOrder);
    }

    function getBreadcrumbText(status) {
        return GridKeys.find(x => x.value === status).tabDisplay;
    }

    function handleSelectedOrder() {
        if (location.search != null)
            dispatch(setSelectedOrderTab(location.search.split('=')[1]));

        const tempSelectedOrder = selectedOrder.data ?
            selectedOrder.data : selectedOrder;
        const orderDetailsUrl = tempSelectedOrder.displayPo 
            ? `/order-details/po/${tempSelectedOrder.displayPo}` 
            : `/order-details/${tempSelectedOrder.orderNumber}`;

        dispatch(setSelectedOrder(tempSelectedOrder, {
            firstBreadcrumb: 'Orders',
            secondBreadcrumb: 'Order',
            returnText: getBreadcrumbText(tempSelectedOrder.status),
        }));

        history.push(orderDetailsUrl);
    }

    const getGridPreferences = async () => {
        const response = await axios.get(`setting/grid/${gridName}`);

        setColumns(response.data.columns);
        dispatch(setSelectedOrderView(response.data.view));

        if (!response.data.view || window.innerWidth <= 1149) {
            const viewType = ViewType.Card;
            setView(viewType);
            setViewRef(viewType);
        } else {
            setView(response.data.view);
            setViewRef(response.data.view);
        }
    }

    const renderLegend = () => {
        return (
            <LegendWrapper>
                <LegendTitle>Legend:</LegendTitle>
                <UrgentLegend />
                <LegendSpan>Critical Items</LegendSpan>
                <PendingDueDate />
                <LegendSpan>Past Due</LegendSpan>
            </LegendWrapper>
        );
    }

    const renderRow = (rowData) => {
        const isUrgent = rowData.urgencyDescription === 'CHD' || rowData.urgencyDescription === 'STAT';
        const isPastDue = rowData.isPastDue;
        return {
            gridhighlightedRow: isUrgent,
            isPastDue: !isUrgent && isPastDue,
        };
    }

    const saveGridPreferences = (grid) => {
        dispatch(setSelectedOrderView(grid.view));
        setView(grid.view);
        setViewRef(grid.view);
        setColumns(grid.columns);
    }

    const defaultSorting = (response, currentTab) => {
        if (currentTab === 'AwaitingReview') {
            return _.orderBy(response,
                ['isPastDue', 'isUrgent', 'hasMissingData', 'isUrgent', 'isFourteenDayCycle', 'isUrgent', ({followupDate}) => followupDate || today,
                    'transitDays', 'sortOrder', 'dateEntered'],
                ['desc', 'desc', 'desc', 'desc', 'desc', 'desc', 'asc', 'asc', 'asc', 'asc']);
        } else if (currentTab === 'PendingShipment') {
            return _.orderBy(response,
                ['isUrgent', ({followupDate}) => followupDate || today, 'transitDays', 'sortOrder', 'dateEntered'],
                ['desc', 'asc', 'asc', 'asc', 'asc']);
        } else if (currentTab === 'Shipped') {
            return _.orderBy(response, ['dateShipped'], ['desc']);
        } else if (currentTab === 'Delivered') {
            return _.orderBy(response, ['dateDelivered'], ['desc']);
        }

        return response;
    }

    return (
        <Container>
            <MultiView
                gridName={gridName}
                label="Orders"
                view={view}
                selectionMode="single"
                data={data}
                onRenderLegend={renderLegend}
                onRenderRow={renderRow}
                onSaveGridPreferences={saveGridPreferences}
                loadingData={loading}
                setLoadingData={setLoading}
                gridKeys={GridKeys}
                columns={columns}
                setColumnList={setColumnList}
                headerLink={{text: 'View All Orders', url: '/orders/history'}}
                onRefresh={getData}
                onSelect={handleSelect}
                isMobile={isMobile}
                isPhone={isPhone}
                defaultSorting={defaultSorting} />
        </Container>
    );
};

export default withViewToggle(Orders);

Orders.propTypes = {
    setViewRef: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isPhone: PropTypes.bool.isRequired,
    view: PropTypes.string,
    setView: PropTypes.func.isRequired,
};
