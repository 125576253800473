import {useState, useEffect} from 'react';
import _ from 'lodash';

export default function useStateFromProps(initialState) {
    const [state, setState] = useState(initialState);
    useEffect(() => {
        // This is necessary because when props change, it doesn't
        // actually change the state (or rerender) when we use the
        // default state api.
        if (!_.eq(state, initialState)) {
            setState(initialState);
        }
    }, [initialState]);

    return [state, setState];
}
