import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory, Prompt} from 'react-router-dom';
import {Popup, brandFont, Button} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSelector} from 'react-redux';

const Wrapper = styled.div`
    padding-top: 1em;
    border-top: 1px solid #d0d0d0;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const Title = styled.h1`
    margin-bottom: 16px;
`;

const PopupContent = styled.p`
    ${brandFont('16px')}
`;

const Check = styled(FontAwesomeIcon)`
    color: green;
    margin-left: 5px;
`;

const CancelLink = styled.a`
    &&& {
        text-transform: uppercase;
        cursor: pointer;
        color: rgb(119, 119, 119);
        font-weight: bold;
        margin-right: 1em;

        &:hover {
            text-decoration: underline;
            outline: none;
        }

        &:focus {
            text-decoration: underline;
            outline: none;
        }

        &:active:focus {
            text-decoration: underline;
            outline: none;
        }
    }
`;

const PopupBody = styled.div`
    ${brandFont('16px')}
`;

const FormFooter = (props) => {
    const {
        dirty = {}, 
        handleOnSubmit,
        handleOnCancel,
        redirect = true, 
        disabled = false, 
        loading, 
        hideCancelButton,
        cancelText = 'Cancel',
        cancelQuestion = 'Are you sure you want to cancel?',
        hideSaveButton,
    } = props;
    const [showConfirmLeave, setShowConfirmLeave] = useState(false);
    const [confirmLeave, setConfirmLeave] = useState(false);
    const [nextLocation, setNextLocation] = useState('');
    const history = useHistory();
    const [showCancelPopup, setShowCancelPopup] = useState('');
    const [showCheckMark, setShowCheckMark] = useState(false);
    const canEditSettings = useSelector(state => state.settings.companySettings?.vendorAccountInformation === 'Y');

    const onSave = async (e) => {
        let result = await handleOnSubmit(e);
        
        if (result !== false && window.location.href.includes('/settings/profile')) {
            setShowCheckMark(true);
            setShowConfirmLeave(false);
            setConfirmLeave(true);
        } else if (result !== false && redirect) {
            setShowConfirmLeave(false);
            setConfirmLeave(true);
            history.push('/settings');
        }
    }

    const onCancel = async (e) => {
        setShowCancelPopup(false);
        if (redirect) {
            if (canEditSettings)
                history.push('/settings')
            else
                history.goBack();
        } else {
            await handleOnCancel(e);     
        }                
    }

    useEffect(() => {
        if (confirmLeave && nextLocation) {
            navigate(nextLocation);
        }
    }, [confirmLeave, nextLocation]);

    const onPageLeave = (location) => {
        const isDirty = Object.keys(dirty).some(s => dirty[s] === true);

        if (!confirmLeave && isDirty && !showCancelPopup) {
            setShowConfirmLeave(true);
            setNextLocation(location.pathname);
            return false;
        } else {
            setShowConfirmLeave(false);
            return true;
        }       
    }

    const onConfirmLeave = () => {
        setConfirmLeave(true);
    }

    const onClose = () => {
        setShowConfirmLeave(false);
    }

    const navigate = (path) => {
        history.push(path);
    }

    return (
        <div>
            <Prompt
                when={!confirmLeave && Object.keys(dirty).length > 0 && !showCancelPopup} 
                message={(location) => onPageLeave(location)}
            />
            {showConfirmLeave && <Popup
                onCancel={onClose} 
                show={showConfirmLeave} 
                onConfirm={onConfirmLeave} 
                confirmText="Leave" 
                cancelText="Stay">
                <PopupContent>
                    <Title>Unsaved Changes</Title>
                    You have unsaved changes which will be lost if you navigate away.
                </PopupContent>
            </Popup>}
            <Wrapper>
                {!hideCancelButton && <CancelLink className="cancel" onClick={() => setShowCancelPopup(true)}>{cancelText}</CancelLink>}
                {!hideSaveButton && <Button className="primary" onClick={(e) => onSave(e)} disabled={disabled} loading={loading}>
                    SAVE
                    {showCheckMark && <Check aria-hidden="true" icon={faCheck} />}
                </Button>}
            </Wrapper>
            {showCancelPopup &&
            <Popup
                confirmText="Yes"
                show={showCancelPopup}
                onCancel={() => setShowCancelPopup(false)}
                onConfirm={() => onCancel()}
                hideButtons={false}>
                <PopupBody>
                    <div>
                        <span>{cancelQuestion}</span>
                    </div>
                </PopupBody>
            </Popup>}
        </div>
    );
};

export default FormFooter;

FormFooter.propTypes = {
    handleOnSubmit: PropTypes.func,
    handleOnCancel: PropTypes.func,
    redirect: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    cancelText: PropTypes.string,
    cancelQuestion: PropTypes.string,
    hideCancelButton: PropTypes.bool,
    hideSaveButton: PropTypes.bool,
    dirty: PropTypes.shape({}),
};
