import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {theme} from '@partssourceinc/react-ui-core';

const Title = styled.h1`
    margin-bottom: 3rem;
`;

const LinkTitle = styled(Link)`
    color: ${theme.blue};
    font-weight: 600;
    font-size: 18px;
    display: block;
    text-decoration: none;
    
    &:hover {
        text-decoration: none;
        color: ${theme.blue};
    }
`;

const LinkDescription = styled.span`
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
`;

export default function CompanyManagement() {
    return (
        <React.Fragment>
            <Title>Company Management</Title>
            <ul className="fa-ul">
                <li>
                    <FontAwesomeIcon icon="cog" size="2x" listItem={true} />
                    <div>
                        <LinkTitle to="/admin/company/settings">Company Settings</LinkTitle>
                        <LinkDescription>Modify company settings</LinkDescription>
                    </div>
                </li>
            </ul>
        </React.Fragment>
    )
}
