import axios from 'axios';
import _ from 'lodash';
import Cookies from 'js-cookie';
import {isSessionStorageAvailable} from '../utility';

const SET_SELECTED_FACILITY = 'SET_SELECTED_FACILITY';
const SET_DEFAULT_HOME_PAGE = 'SET_DEFAULT_HOME_PAGE';
const SET_DEFAULT_REFRESH_RATE = 'SET_DEFAULT_REFRESH_RATE';
const LOGIN_USER = 'LOGIN_USER';
const LOGOUT_USER = 'LOGOUT_USER';
const IMPERSONATE = 'IMPERSONATE';
const GET_USER_DASHBOARD_SETTINGS_RESP = 'GET_USER_DASHBOARD_SETTINGS_RESP';
const UPDATE_USER_PORTAL_WIDGETS_ORDER = 'UPDATE_USER_PORTAL_WIDGETS_ORDER';

export const AdminRole = {
    FullAdmin: 'Full Admin',
    ReadOnly: 'Read Only',
    Impersonation: 'Impersonation',
    RequestForQuoteManger: 'Request For Quote Manger',
    ContractOrderManager: 'ContractOrderManager',
    ContractManager: 'ContractManager',
    ReportingAdmin: 'ReportingAdmin',
    VendorReqAdmin_Dev: 'VendorReqAdmin_Dev',
    CustomerReqAdmin_Dev: 'CustomerReqAdmin_Dev',
    VendorReqAdmin_Prod: 'VendorReqAdmin_Prod',
    CustomerReqAdmin_Prod: 'CustomerReqAdmin_Prod',
};
export const AccountStatus = {
    Valid: 1,
    UnconfirmedEmail: 2,
    LockedOut: 4,
    Invalid: 8,
    Deactivated: 16,
    PasswordExpired: 32,
    EulaRequired: 64,
};
const SET_OKTA_AUTH = 'SET_OKTA_AUTH';
const SET_OKTA_AUTH_STATE = 'SET_OKTA_AUTH_STATE';
const SET_IS_LOGGING_IN = 'SET_IS_LOGGING_IN';
const SET_IS_LOGGING_OUT = 'SET_IS_LOGGING_OUT';
const SET_IS_IMPERSONATING = 'SET_IS_IMPERSONATING';
const SESSION_STORAGE_AVAILABLE = 'SESSION_STORAGE_AVAILABLE'
const OKTA_LOGIN_USER = 'OKTA_LOGIN_USER'

const LOAD_USER_TIME_ZONE = 'LOAD_USER_TIME_ZONE';
const STOP_IMPERSONATION = 'STOP_IMPERSONATION';
const SET_USER_POLICIES = 'SET_USER_POLICIES';
const SET_COOKIE_POLICY_ACCEPT = 'SET_COOKIE_POLICY_ACCEPT';
const SET_EULA_PRIVACY_POLICY_ACCEPT = 'SET_EULA_PRIVACY_POLICY_ACCEPT';
const GET_USER_DEFAULTS_SUCCESS = 'GET_USER_DEFAULTS_SUCCESS';
const LOAD_MFA_OPT_IN_SUCCESS = 'LOAD_MFA_OPT_IN_SUCCESS';
const SAVE_MFA_OPT_IN_SUCCESS = 'SAVE_MFA_OPT_IN_SUCCESS';
const ERROR = 'ERROR';
const SET_GRID_PREFERENCES = 'SET_GRID_PREFERENCES';

import moment from 'moment';

export const defaultState = {
    initials: '',
    tokenInfo: '',
    isEmployee: false,
    isPasswordExpired: false,
    isAdmin: false,
    firstName: '',
    lastName: '',
    impersonator: null,
    email: '',
    userId: 0, // reg login had this as '' but authhandler looks for 0. 
    selectedFacility: null,
    companyId: '',
    defaultHomePage: '',
    defaultRefreshRate: {
        value: 1,
        text: '1',
    },
    defaultViewType: 'Grid',
    addressIsValid: false,
    timeZoneId: null,
    beingImpersonated: false,
    policies: {
        cookiesPolicyAccepted: false,
        privacyPolicyAccepted: false,
        termsOfUsePolicyAccepted: false,
        termsAndConditionsPolicyAccepted: false,
        accessibilityPolicyAccepted: false,
        eulaAccepted: false,
        newPolicyAcceptanceRequired: true,
    },
    authState: null,
    oktaAuth: null,
    isLoggingIn: false,
    isLoggingOut: false,
    isImpersonating: false,
    isLoggedIn: false,
    sessionStorageAvailable: false,
    subscriptionId: false,
    bids: [
        {field: 'statusId', header: 'Status', sortable: true, filter: true, visible: 'never', order: 1, type: 'number'},
        {field: 'lineItemId', header: 'Ref #', sortable: true, filter: true, visible: true, order: 2, type: 'number'},
        {field: 'status', header: 'Status Code', sortable: true, filter: true, visible: false, order: 3},
        {field: 'urgencyDescription', header: 'Urgency', sortable: true, filter: true, visible: true, order: 4},
        {field: 'manufacturer', header: 'OEM', sortable: true, filter: true, visible: true, order: 5},
        {field: 'model', header: 'Model', sortable: true, filter: true, visible: true, order: 7},
        {field: 'partNumber', header: 'Item #', sortable: true, filter: true, visible: true, order: 8},
        {field: 'description', header: 'Description', sortable: true, filter: true, visible: true, order: 9},
        {field: 'orderType', header: 'Type', sortable: true, filter: true, visible: true, order: 11},
        {field: 'purchaseRestriction', header: 'Restrictions', sortable: true, filter: true, visible: true, order: 12},
        {field: 'quantity', header: 'Qty', sortable: true, filter: true, visible: true, order: 13, type: 'number'},
        {
            field: 'dateEntered', header: 'Request Date', sortable: true, filter: true,
            visible: true, order: 14, type: 'date', template: 'date-time',
        },
        {field: 'sourcerId', header: 'Sourcer #', sortable: true, filter: true, visible: 'never', order: 15, type: 'number'},
        {field: 'sourcer', header: 'Sourcer', sortable: true, filter: true, visible: true, order: 16},
        {field: 'sourcerPhone', header: 'Sourcer Phone', sortable: true, filter: true, visible: false, order: 17},
        {field: 'sourcerEmail', header: 'Sourcer Email', sortable: true, filter: true, visible: false, order: 18},
        {field: 'imagePath', header: 'Image Path', sortable: false, filter: false, visible: 'never', order: 19, allowedTabs: []},
        {field: 'imageAlt', header: 'Image Alt', sortable: false, filter: false, visible: 'never', order: 20, allowedTabs: []},
        {
            field: 'imageDefaultUrl', header: 'Image Default Url', sortable: false,
            filter: false, visible: 'never', order: 21, allowedTabs: [],
        },
        {field: 'companyId', header: 'Company #', sortable: true, filter: true, visible: 'never', order: 22, type: 'number'},
        {field: 'company', header: 'Company', sortable: true, filter: true, visible: true, order: 23},
        {field: 'loanerAvailable', header: 'Loaner', sortable: true, filter: true, visible: false, order: 24},
        {
            field: 'dateSubmitted', header: 'Submitted Date', sortable: true, filter: true,
            visible: true, order: 25, type: 'date', template: 'date-time',
        },
    ],
    orders: [
        {field: 'lineItemId', header: 'Ref #', sortable: true, filter: true, visible: true, order: 0, type: 'number'},
        {field: 'displayPo', header: 'PO #', sortable: true, filter: true, visible: false, order: 1, type: 'number'},
        {field: 'manufacturer', header: 'OEM', sortable: true, filter: true, visible: true, order: 2},
        {field: 'companyId', header: 'Company #', sortable: true, filter: true, visible: 'never', order: 22, type: 'number'},
        {field: 'company', header: 'Company', sortable: true, filter: true, visible: false, order: 23},
        {field: 'description', header: 'Description', sortable: true, filter: true, visible: true, order: 3},
        {field: 'trackingNumber', header: 'Tracking #', sortable: true, filter: true, visible: true, order: 4},
        {field: 'vendorOrderNumber', header: 'Vendor Order #', sortable: true, filter: true, visible: true, order: 5},
        {field: 'dateEntered', header: 'Created', sortable: true, filter: true, visible: true, order: 6, type: 'date', template: 'date-time'},
        {field: 'shipDateReason', header: 'Ship Date Reason', sortable: true, filter: true, visible: true, order: 7},
        {
            field: 'estimatedShipDate', header: 'Est. Ship Date', sortable: true, filter: true,
            visible: true, order: 9, type: 'date', template: 'date-long-year',
        },
        {field: 'quantity', header: 'Qty', sortable: true, filter: true, visible: true, order: 10, type: 'number'},
        {field: 'status', header: 'Status Code', sortable: true, filter: true, visible: false, order: 11},
        {field: 'urgencyDescription', header: 'Urgency', sortable: true, filter: true, visible: false, order: 12},
        {field: 'orderTypeId', header: 'Order Type Id', sortable: true, filter: true, visible: 'never', order: 14, type: 'number'},
        {field: 'orderType', header: 'Order Type', sortable: true, filter: true, visible: false, order: 15},
        {field: 'partNumber', header: 'Item #', sortable: true, filter: true, visible: true, order: 21},
        {field: 'isPassThrough', header: 'Is Pass Through', sortable: true, filter: true, visible: 'never', order: 23},
        {field: 'gsaItem', header: 'GSA Item', sortable: true, filter: true, visible: false, order: 24},
        {field: 'reasonId', header: 'Reason Id', sortable: true, filter: true, visible: 'never', order: 25, type: 'number'},
        {field: 'shipDateReasonId', header: 'Ship Date Reason Id', sortable: true, filter: true, visible: 'never', order: 26, type: 'number'},
        {
            field: 'dateShipped', header: 'Shipped Date', sortable: true, filter: true,
            visible: false, order: 27, type: 'date', template: 'date-long-year',
        },
        {field: 'shipper', header: 'Shipper', sortable: true, filter: true, visible: false, order: 28},
        {field: 'notesCount', header: 'Notes', sortable: true, filter: true, visible: true, order: 28, type: 'number'},
        {field: 'conditionDescription', header: 'Condition', sortable: true, filter: true, visible: false, order: 29},
        {field: 'conditionId', header: 'Condition Id', sortable: true, filter: true, visible: 'never', order: 30},
        {field: 'imagePath', header: 'Image Path', sortable: false, filter: false, visible: 'never', order: 31},
        {field: 'imageAlt', header: 'Image Alt', sortable: false, filter: false, visible: 'never', order: 32},
        {field: 'imageDefaultUrl', header: 'Image Default Url', sortable: false, filter: false, visible: 'never', order: 33},
    ],
    ordersHistory: [
        {field: 'lineItemId', header: 'Ref #', sortable: true, filter: true, visible: true, order: 0, type: 'number'},
        {field: 'displayPo', header: 'PO #', sortable: true, filter: true, visible: true, order: 1},
        {field: 'orderType', header: 'Order Type', sortable: true, filter: true, visible: true, order: 2},
        {field: 'manufacturer', header: 'Manufacturer', sortable: true, filter: true, visible: true, order: 2},
        {field: 'companyId', header: 'Company #', sortable: true, filter: true, visible: 'never', order: 22, type: 'number'},
        {field: 'company', header: 'Company', sortable: true, filter: true, visible: false, order: 23},
        {field: 'description', header: 'Description', sortable: true, filter: true, visible: true, order: 3},
        {field: 'trackingNumber', header: 'Tracking #', sortable: true, filter: true, visible: true, order: 4},
        {field: 'vendorOrderNumber', header: 'Vendor Order #', sortable: true, filter: true, visible: true, order: 5},
        {field: 'dateEntered', header: 'Requested', sortable: true, filter: true, visible: true, order: 6, type: 'date', template: 'date-time'},
        {field: 'notesCount', header: 'Notes', sortable: true, filter: true, visible: false, order: 8, type: 'number'},
        {
            field: 'estimatedShipDate', header: 'Est. Ship Date', sortable: true, filter: true,
            visible: false, order: 9, type: 'date', template: 'date-long-year',
        },
        {field: 'quantity', header: 'Qty', sortable: true, filter: true, visible: true, order: 10, type: 'number'},
        {field: 'status', header: 'Status', sortable: true, filter: true, visible: false, order: 11},
        {field: 'urgencyDescription', header: 'Urgency', sortable: true, filter: true, visible: false, order: 12},
        {field: 'orderNumber', header: 'Order #', sortable: true, filter: true, visible: false, order: 13, type: 'number'},
        {field: 'orderTypeId', header: 'Order Type Id', sortable: true, filter: true, visible: 'never', order: 14, type: 'number'},
        {field: 'partNumber', header: 'Item #', sortable: true, filter: true, visible: false, order: 21},
        {field: 'isPassThrough', header: 'Is Pass Through', sortable: true, filter: true, visible: 'never', order: 23},
        {field: 'gsaItem', header: 'GSA Item', sortable: true, filter: true, visible: false, order: 24},
        {field: 'reasonId', header: 'Reason Id', sortable: true, filter: true, visible: 'never', order: 25, type: 'number'},
        {field: 'shipDateReasonId', header: 'Ship Date Reason Id', sortable: true, filter: true, visible: 'never', order: 26, type: 'number'},
        {
            field: 'dateShipped', header: 'Shipped Date', sortable: true, filter: true,
            visible: false, order: 27, type: 'date', template: 'date-long-year',
        },
        {field: 'shipper', header: 'Shipping Priority', sortable: true, filter: true, visible: false, order: 28},
        {field: 'conditionDescription', header: 'Condition', sortable: true, filter: true, visible: false, order: 29},
        {field: 'conditionId', header: 'Condition Id', sortable: true, filter: true, visible: 'never', order: 30},
        {field: 'imagePath', header: 'Image Path', sortable: false, filter: false, visible: 'never', order: 31},
        {field: 'imageAlt', header: 'Image Alt', sortable: false, filter: false, visible: 'never', order: 32},
        {field: 'imageDefaultUrl', header: 'Image Default Url', sortable: false, filter: false, visible: 'never', order: 33},
        {field: 'orderStatus', header: 'Order Status', sortable: true, filter: true, visible: true, order: 34},
    ],
    repairs: [
        {field: 'lineItemId', header: 'Ref #', sortable: true, filter: true, visible: true, order: 0, type: 'number'},
        {field: 'manufacturer', header: 'Manufacturer', sortable: true, filter: true, visible: true, order: 1},
        {field: 'serialNumber', header: 'Serial #', sortable: true, filter: true, visible: true, order: 2},
        {field: 'description', header: 'Description', sortable: true, filter: true, visible: true, order: 3},
        {field: 'customerToVendorTrackingNumber', header: 'Inbound Tracking #', sortable: true, filter: true, visible: true, order: 4},
        {
            field: 'customerToVendorEstimatedDelivery', header: 'Inbound Est. Delivery Date', sortable: true, filter: true,
            visible: true, order: 5, type: 'date', template: 'date-long-year',
        },
        {field: 'vendorRmaNumber', header: 'Vendor RMA #', sortable: true, filter: true, visible: true, order: 6},
        {field: 'dateCreated', header: 'Created', sortable: true, filter: true, visible: true, order: 7, type: 'date', template: 'date-time'},
        {field: 'company', header: 'Company', sortable: true, filter: true, visible: true, order: 8},
        {field: 'quantity', header: 'Qty', sortable: true, filter: true, visible: true, order: 9, type: 'number'},
        {field: 'orderNumber', header: 'Order #', sortable: true, filter: true, visible: false, order: 10, type: 'number'},
        {field: 'displayPo', header: 'PO #', sortable: true, filter: true, visible: false, order: 11, type: 'number'},
        {field: 'rgaNumber', header: 'RGA #', sortable: true, filter: true, visible: false, order: 12},
        {field: 'partNumber', header: 'Item #', sortable: true, filter: true, visible: false, order: 13},
        {field: 'price', header: 'Price', sortable: true, filter: true, visible: false, order: 14, type: 'number'},
        {field: 'priority', header: 'Priority', sortable: true, filter: true, visible: false, order: 15},
        {field: 'statusDescription', header: 'Status Description', sortable: true, filter: true, visible: false, order: 16},
        {field: 'condition', header: 'Condition', sortable: true, filter: true, visible: false, order: 17},
        {field: 'warranty', header: 'Warranty', sortable: true, filter: true, visible: false, order: 18},
        {field: 'requestor', header: 'Requestor', sortable: true, filter: true, visible: false, order: 20},
        {
            field: 'estimatedShipDate', header: 'Est. Ship Date', sortable: true, filter: true,
            visible: true, order: 21, type: 'date', template: 'date-long-year',
        },
        {field: 'vendorToCustomerTrackingNumber', header: 'Outbound Tracking #', sortable: true, filter: true,visible: false, order: 22},
        {
            field: 'vendorToCustomerEstimatedDelivery', header: 'Outbound Est. Delivery Date', sortable: true, filter: true,
            visible: true, order: 23, type: 'date', template: 'date-long-year',
        },
        {field: 'vendorOrderNumber', header: 'Vendor Order Number', sortable: true, filter: true, visible: true, order: 23},

    ],
    rgaOrders: [
        {field: 'rgaNumber', header: 'RGA #', sortable: true, filter: true, visible: true, order: 0},
        {field: 'orderId', header: 'Order #', sortable: true, filter: true, visible: true, order: 1, type: 'number'},
        {field: 'displayPo', header: 'PO #', sortable: true, filter: true, visible: true, order: 2, type: 'number'},
        {field: 'lineItemId', header: 'Ref #', sortable: true, filter: true, visible: true, order: 3, type: 'number'},
        {field: 'description', header: 'Description', sortable: true, filter: true, visible: true, order: 4},
        {field: 'company', header: 'Company', sortable: true, filter: true, visible: true, order: 5},
        {
            field: 'rgaDateEntered', header: 'Entered On', sortable: true, filter: true,
            visible: true, order: 6, type: 'date', template: 'date-long-year',
        },
        {
            field: 'rgaDueDate', header: 'Due Date', sortable: 'true', filter: false, visible: true,
            order: 7, type: 'date', template: 'date-long-year',
        },
        {field: 'manufacturer', header: 'OEM', sortable: true, filter: false, visible: true, order: 8},
        {field: 'rgaReason', header: 'Return Reason', sortable: true, filter: false, visible: true, order: 9},
        {field: 'rgaType', header: 'Type', sortable: true, filter: true, visible: true, order: 10},
        {field: 'quantity', header: 'Qty', sortable: true, filter: false, visible: true, order: 11, type: 'number'},
        {field: 'rmaNumber', header: 'RMA #', sortable: true, filter: true, visible: true, order: 12},
        {field: 'trackingNumber', header: 'Tracking Number', sortable: true, filter: true, visible: true, order: 13, template: 'trackingUrl'},
        {field: 'shippingMethod', header: 'Shipping Method', sortable: true, filter: true, visible: true, order: 14},
        {field: 'boxOpened', header: 'Box Opened', sortable: true, filter: false, visible: true, order: 15},
        {field: 'packageOpened', header: 'Package Opened', sortable: true, filter: false, visible: true, order: 16},
        {field: 'isItWorking', header: 'Is It Working', sortable: true, filter: false, visible: true, order: 17},
    ],
    loanersAndRentals: [
        {field: 'serialNumber', header: 'Serial #', sortable: true, filter: true, visible: true, order: 0},
        {field: 'lineItemId', header: 'Ref #', sortable: true, filter: true, visible: true, order: 0, type: 'number'},
        {field: 'displayPo', header: 'PO #', sortable: true, filter: true, visible: false, order: 1, type: 'number'},
        {field: 'manufacturer', header: 'OEM', sortable: true, filter: true, visible: true, order: 2},
        {field: 'facility', header: 'Facility', sortable: true, filter: true, visible: true, order: 6},
        {field: 'description', header: 'Description', sortable: true, filter: true, visible: true, order: 3},
        {field: 'requestor', header: 'Requestor', sortable: true, filter: true, visible: true, order: 3},
        {field: 'orderNumber', header: 'Order #', sortable: true, filter: true, visible: true, order: 5},
        {field: 'dateCreated', header: 'Created', sortable: true, filter: true, visible: true, order: 6, type: 'date', template: 'date-time'},
        {
            field: 'rgaDueDate', header: 'Delivery Date', sortable: true, filter: true,
            visible: true, order: 9, type: 'date', template: 'date-long-year',
        },
        {field: 'quantity', header: 'Qty', sortable: true, filter: true, visible: true, order: 10, type: 'number'},
        {field: 'status', header: 'Status Code', sortable: true, filter: true, visible: false, order: 11},
        {field: 'orderTypeId', header: 'Order Type Id', sortable: true, filter: true, visible: 'never', order: 14, type: 'number'},
        {field: 'orderType', header: 'Order Type', sortable: true, filter: true, visible: false, order: 15},
        {field: 'isPassThrough', header: 'Is Pass Through', sortable: true, filter: true, visible: 'never', order: 23},
        {field: 'reasonId', header: 'Reason Id', sortable: true, filter: true, visible: 'never', order: 25, type: 'number'},
        {field: 'shipper', header: 'Shipper', sortable: true, filter: true, visible: false, order: 28},
        {field: 'notesCount', header: 'Notes', sortable: true, filter: true, visible: true, order: 28, type: 'number'},
        {field: 'conditionDescription', header: 'Condition', sortable: true, filter: true, visible: false, order: 29},
        {field: 'conditionId', header: 'Condition Id', sortable: true, filter: true, visible: 'never', order: 30},
        {field: 'imagePath', header: 'Image Path', sortable: false, filter: false, visible: 'never', order: 31},
        {field: 'imageAlt', header: 'Image Alt', sortable: false, filter: false, visible: 'never', order: 32},
        {field: 'imageDefaultUrl', header: 'Image Default Url', sortable: false, filter: false, visible: 'never', order: 33},
    ],
    userDashboardSettings: {
        loading: false,
        data: {},
        error: null
    },
    widgets: [],
    widgetsLoading: false,
    widgetsErrors: null,
    portals: [],
    portalsLoading: false,
    portalsErrors: null,
};

export const reducer = (state = _.cloneDeep(defaultState), action) => {
    switch (action.type) {
        case LOAD_USER_TIME_ZONE: {
            return {...state, timeZoneId: action.timeZoneId};
        }
        case LOGIN_USER: {
            const {
                firstName,
                lastName,
                isEmployee,
                isPasswordExpired,
                supplierProAdminRole,
                userId,
                email,
                rootCompanyId,
                access_token,
                expires_in,
                refresh_token,
                token_type,
            } = action;

            const user = {
                isEmployee,
                isPasswordExpired,
                isAdmin: supplierProAdminRole === 1,
                firstName,
                lastName,
                email,
                userId,
                beingImpersonated: false,
                companyId: rootCompanyId,
                policies: {
                    cookiesPolicyAccepted: false,
                    privacyPolicyAccepted: false,
                    termsOfUsePolicyAccepted: false,
                    termsAndConditionsPolicyAccepted: false,
                    accessibilityPolicyAccepted: false,
                    eulaAccepted: false,
                    newPolicyAcceptanceRequired: true,
                },
            };

            const bearerToken = `${token_type} ${access_token}`;
            setToken(refresh_token, expires_in, bearerToken);
            const initials = firstName.charAt(0) + lastName.charAt(0);

            if (localStorage.rememberMe === 'true') {
                Cookies.set('id', userId, {
                    secure: window.location.hostname !== 'localhost',
                    expires: 90,
                    domain: window.location.hostname,
                });
            } else {
                document.cookie = 'id=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            }

            const cookiesPolicy = Cookies.get('cookie-policy') ?? false;

            user.policies.cookiesPolicyAccepted = cookiesPolicy;

            return {...state, tokenInfo: bearerToken, ...user, initials};
        }
        case LOGOUT_USER: {
            localStorage.removeItem('sproState');
            localStorage.removeItem('prevPath');
            localStorage.removeItem('cachedFilters');
            localStorage.removeItem('cachedRgaFilters');

            clearToken();
            return defaultState;
        }
        case SET_OKTA_AUTH_STATE: {
            const {authState} = action;

            if (authState && !authState.isAuthenticated)
                Cookies.remove('tokenInfo');
            else if (authState && authState.isAuthenticated && authState.accessToken) {
                const {accessToken} = authState;
                const isNotLocalhost = window.location.hostname !== 'localhost';
                const tokenType = accessToken.tokenType;
                const token = accessToken.value || accessToken.accessToken;
                const in30Days = new Date(new Date().getTime() + 24 * 30 * 60 * 60 * 1000);
                Cookies.set('tokenInfo', `${tokenType} ${token}`, {
                    expires: in30Days,
                    secure: isNotLocalhost,
                    sameSite: isNotLocalhost ? 'None' : undefined,
                });
            }
            return {...state, authState};
        }
        case SET_OKTA_AUTH: {
            const {oktaAuth} = action;
            return {...state, oktaAuth};
        }
        case SET_IS_LOGGING_IN: {
            return {...state, isLoggingIn: action.isLoggingIn};
        }
        case SET_IS_LOGGING_OUT: {
            return {...state, isLoggingOut: action.isLoggingOut};
        }
        case SET_IS_IMPERSONATING: {
            return {...state, isImpersonating: action.isImpersonating};
        }
        case SESSION_STORAGE_AVAILABLE: {
            const isAvailable = isSessionStorageAvailable();
            return {...state, sessionStorageAvailable: isAvailable};
        }
        case OKTA_LOGIN_USER: {
            const {access_token} = action;
            const uid = access_token.claims.userId;
            const isEmp = access_token.claims.isEmployee;
            const first = access_token.claims.firstName;
            const last = access_token.claims.lastName;
            const compId = access_token.claims.rootCompanyId;             
            const roles = access_token.claims.Groups;
            const isAdmin = roles?.some(r => r === 'SupplierProAdmin');       
            const emailCopy = action.userInfo.email;
            const refresh = action.refresh_token.refreshToken;
            const type = access_token.tokenType;
            const tokenCopy = action.access_token.accessToken;
            const initialsCopy = first.charAt(0) + last.charAt(0);

            const userCopy = {
                userId: uid,
                isEmployee: isEmp,
                isAdmin: isAdmin,
                firstName: first,
                lastName: last,
                email: emailCopy,
                beingImpersonated: false,
                companyId: compId,
                initials: initialsCopy,
                policies: {
                    cookiesPolicyAccepted: false,
                    privacyPolicyAccepted: false,
                    termsOfUsePolicyAccepted: false,
                    termsAndConditionsPolicyAccepted: false,
                    accessibilityPolicyAccepted: false,
                    eulaAccepted: false,
                    newPolicyAcceptanceRequired: true,
                },
            }
            
            const bearerTokenCopy = `${type} ${tokenCopy}`;
            const tokensExpireAt = action.access_token.expiresAt;
            let isNotLocalhostCopy = (window.location.hostname !== 'localhost');
            
            Cookies.set('refreshToken', refresh, {
                expires: tokensExpireAt,
                secure: isNotLocalhostCopy,
                sameSite: isNotLocalhostCopy ? 'None' : undefined,
            });
            Cookies.set('tokenExpiration', tokensExpireAt, {
                expires: tokensExpireAt,
                secure: isNotLocalhostCopy,
                sameSite: isNotLocalhostCopy ? 'None' : undefined,
            });
            Cookies.set('tokenInfo', bearerTokenCopy, {
                expires: tokensExpireAt,
                secure: isNotLocalhostCopy,
                sameSite: isNotLocalhostCopy ? 'None' : undefined,
            });

            const cookiesPolicyCopy = Cookies.get('cookie-policy') ?? false;

            userCopy.policies.cookiesPolicyAccepted = cookiesPolicyCopy;

            return {...state, tokenInfo: bearerTokenCopy, ...userCopy, isLoggedIn: true};
        }
        case IMPERSONATE: {
            const {
                firstName,
                lastName,
                isEmployee,
                role,
                rootCompanyId,
                userId,
            } = action;

            const user = {
                ...defaultState,
                isEmployee,
                isAdmin: role === 1,
                firstName,
                lastName,
                impersonator: _.cloneDeep(state),
                beingImpersonated: true,
                companyId: rootCompanyId,
                policies: defaultState.policies,
                userId: userId,
            };

            const initials = firstName.charAt(0) + lastName.charAt(0);
            const tokenInfo = _.omit(action, ['psAuthenticationKey', 'psAuthorizationKey']);

            tokenInfo.userId = action.userId;
            tokenInfo.subscriptionId = false;

            return {...user, tokenInfo: tokenInfo, initials};
        }
        case STOP_IMPERSONATION: {
            const {firstName, lastName, tokenInfo} = state.impersonator;
            const initials = firstName.charAt(0) + lastName.charAt(0);

            Cookies.set('tokenInfo', tokenInfo);
            Cookies.remove('sisense');
            return {...state.impersonator, tokenInfo, initials};
        }
        case GET_USER_DEFAULTS_SUCCESS: {
            const defaultHomePage = action.settings && action.settings.defaultHomePage;
            const defaultRefreshRate = action.settings && action.settings.defaultRefreshRate;
            return {...state, defaultHomePage, defaultRefreshRate};
        }
        case 'GET_USER_DASHBOARD_SETTINGS_REQ':
            return {
                ...state,
                userDashboardSettings: {
                    ...state.userDashboardSettings,
                    loading: true,
                    error: null
                }
            };
        case GET_USER_DASHBOARD_SETTINGS_RESP:
            return {
                ...state,
                userDashboardSettings: {
                    loading: false,
                    data: action.settings,
                    error: null
                }
            };
        case 'GET_USER_DASHBOARD_SETTINGS_ERR':
            return {
                ...state,
                userDashboardSettings: {
                    ...state.userDashboardSettings,
                    loading: false,
                    error: action.response
                }
            };
        case SET_DEFAULT_HOME_PAGE: {
            const {defaultHomePage} = action;
            return {...state, defaultHomePage};
        }
        case SET_DEFAULT_REFRESH_RATE: {
            const {defaultRefreshRate} = action;
            return {...state, defaultRefreshRate};
        }        
        case SET_SELECTED_FACILITY: {
            const {selectedFacility} = action;
            return {...state, selectedFacility};
        }
        case SET_GRID_PREFERENCES: {
            const {gridPreferences} = action;
            return {...state, [gridPreferences.gridName]: gridPreferences.columns, defaultViewType: gridPreferences.view};
        }
        case SET_USER_POLICIES: {
            const {policies} = action;
            const cookiesPolicy = Cookies.get('cookie-policy') ?? false;
            const {cookiesPolicyAccepted, newCookiePolicyAcceptanceRequired} = policies;

            if (newCookiePolicyAcceptanceRequired) {
                Cookies.remove('cookie-policy');
                policies.cookiesPolicyAccepted = false;
            } else if (!cookiesPolicy && cookiesPolicyAccepted) {
                Cookies.set('cookie-policy', 'true', {expires: 180});
                acceptCookiesPolicy();
            }

            return {...state, policies};
        }
        case SET_COOKIE_POLICY_ACCEPT: {
            const {policies} = state;
            policies.cookiesPolicyAccepted = true;
            return {...state, policies};
        }
        case SET_EULA_PRIVACY_POLICY_ACCEPT: {
            const {policies} = state;
            policies.eulaAccepted = true;
            policies.privacyPolicyAccepted = true;
            return {...state, policies};
        }
        case SAVE_MFA_OPT_IN_SUCCESS:
        case LOAD_MFA_OPT_IN_SUCCESS: {
            const {data} = action;
            return {...state, mfaOptnIn: data};
        }
        case 'GET_WIDGETS': {
            const {loading} = action;
            return {
                ...state,
                widgetsLoading: loading,
                widgetsErrors: null,
            };
        }
        case 'GET_WIDGETS_RESP': {
            const {response: {data}} = action;
            const mappedWidgets = data.map((z) => {
                z.nameSort = z.name.toLowerCase(), z.search = z.name.toLowerCase() + ' ' + z.reportId; return z; 
            });
            return {
                ...state,
                widgets: mappedWidgets,
                widgetsLoading: false,
                widgetsErrors: null,
            };
        }
        case 'GET_WIDGETS_FAILED': {
            const {response} = action;
            return {
                ...state,
                widgets: [],
                widgetsLoading: false,
                widgetsErrors: response,
            };
        }
        case 'GET_PORTALS': {
            const {loading} = action;
            return {
                ...state,
                portalsLoading: loading,
                portalsErrors: null,
            };
        }
        case 'GET_PORTALS_RESP': {
            const {portals} = action;
            const mappedPortals = portals.map((portal) => {
                const searchableString = [
                    portal.name.toLowerCase(),
                    portal.description.toLowerCase(),
                    // Add group names to searchable string if companies exist
                    ...(portal.companies
                        ? portal.companies.map(c => `${c.companyName.toLowerCase()} ${c.companyId}`)
                        : []),
                    // Add widget names to searchable string if widgets exist
                    ...(portal.widgets 
                        ? portal.widgets.map(widget => `${widget.name.toLowerCase()}`)
                        : [])
                ].join(' ');

                return {
                    ...portal,
                    nameSort: portal.name.toLowerCase(),
                    search: searchableString
                };
            });

            return {
                ...state,
                portals: mappedPortals,
                portalsLoading: false,
                portalsErrors: null,
            };
        }
        case 'GET_PORTALS_FAILED': {
            const {response} = action;
            return {
                ...state,
                portals: [],
                portalsLoading: false,
                portalsErrors: response,
            };
        }
        case UPDATE_USER_PORTAL_WIDGETS_ORDER: {
            return {
                ...state,
                userDashboardSettings: {
                    ...state.userDashboardSettings,
                    portal: {
                        ...state.userDashboardSettings.portal,
                        widgets: action.widgets
                    }
                }
            };
        }
        case 'INIT_USER_REQ':
        case 'INIT_USER_RESP':
        case 'INIT_USER_ERR':
        default:
            return state;
    }
}

export function loadTimeZone() {
    return (dispatch, getState) => {
        return axios
            .get('/user/location')
            .then(({data}) => dispatch({type: LOAD_USER_TIME_ZONE, timeZoneId: data?.timeZoneId}))
            .catch(_.noop);
    }
}

export function getUserPolicies() {
    return (dispatch, getState) => {
        return axios
            .get(`/company/policies`)
            .then(({data}) => {
                const user = getState().user;
                const overPassPolicy = user.isEmployee || user.isAdmin || user.beingImpersonated;

                if (!overPassPolicy) {
                    const triggerPolicyAcceptance = (user.userId && data.newPolicyAcceptanceRequired)
                        && !window.location.href.includes('/policies-acceptance');

                    if (triggerPolicyAcceptance) {
                        window.location.replace('/policies-acceptance');
                    }

                    if (Cookies.get('cookie-policy') && !data.cookiesPolicyAccepted) {
                        dispatch(acceptCookiesPolicy());
                    }
                }
                dispatch({type: SET_USER_POLICIES, policies: data});
            })
            .catch(_.noop);
    }
}

export function getUserDefaults() {
    return async (dispatch) => {
        try {
            let result = await axios.get(`/setting/default`);
            const {data: settings} = result;
            await dispatch({type: GET_USER_DEFAULTS_SUCCESS, settings});
            return settings;
        } catch (error) {
            console.log(error);
        }
    };
}

export function getGridPreferences(gridName,) {
    return (dispatch) => {
        return axios.get(`setting/grid/${gridName}`)
            .then(({data}) => dispatch({type: SET_GRID_PREFERENCES, data}))
            .catch(error => dispatch({type: ERROR, error}));
    };
}

export function setDefaultHomePage(defaultHomePage) {
    return (dispatch) => dispatch({type: SET_DEFAULT_HOME_PAGE, defaultHomePage});
}

export function setDefaultRefreshRate(defaultRefreshRate) {
    return (dispatch) => dispatch({type: SET_DEFAULT_REFRESH_RATE, defaultRefreshRate});
}

export function logoutUser() {
    return (dispatch, getState) => {
        return dispatch({type: LOGOUT_USER});
    }
}

export async function refreshToken() {
    let hasToken = Cookies.get('tokenInfo')
    if (hasToken) {
        let tokenExpiration = Cookies.get('tokenExpiration');
        let refreshT = Cookies.get('refreshToken');

        if (!moment().isBefore(moment.unix(tokenExpiration))) {
            const tokenRequest =
                `client_id=supplier&client_secret=SupplierProSecret&grant_type=refresh_token&refresh_token=${refreshT}`;
            const header = {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            }

            const authenticationResp =
                await axios.post(`${process.env.REACT_APP_ORION_API_ROUTE_URL}/AuthenticationService/connect/token`,
                    tokenRequest,
                    header);
            const {status, data: {access_token, refresh_token, expires_in, token_type}} = authenticationResp;
            if (status === 200) {
                const bearerToken = `${token_type} ${access_token}`;
                setToken(refresh_token, expires_in, bearerToken);
            } else {
                clearToken();
            }
        }
    } else {
        clearToken();
    }
}

export function loadMfaOptin() {
    return (dispatch, getState) => {
        const {user} = getState();
        return axios.get(`${process.env.REACT_APP_ORION_API_ROUTE_URL}/OktaAdminService/api/v1/Users/${user.userId}/property?propertyName=MFA_OptIn`)
            .then(({data}) => dispatch({type: LOAD_MFA_OPT_IN_SUCCESS, data}))
            .catch(error => dispatch({type: ERROR, error}));
    };    
}

export function saveMfaOptin(optIn) {
    return (dispatch, getState) => {
        const {user} = getState();
        const updatePropertiesRequest = {
            contact: {
                id: user.userId,
            },
            properties: [
                {
                    name: 'MFA_OptIn',
                    value: optIn,
                    isCustom: true,
                },
            ],
        };
        return axios.put(`${process.env.REACT_APP_ORION_API_ROUTE_URL}/OktaAdminService/api/v1/Users/properties`, updatePropertiesRequest)
            .then(({data}) => dispatch({type: SAVE_MFA_OPT_IN_SUCCESS, data}))
            .catch(error => dispatch({type: ERROR, error}));
    };    
}

export function clearToken() {
    Cookies.remove('refreshToken');
    Cookies.remove('tokenExpiration');
    Cookies.remove('tokenInfo');
    Cookies.remove('sisense');
}

export function setToken(refresh_token, expires_in, bearerToken) {
    let expireNumericValue = parseInt(moment().add(expires_in, 'seconds').format('X'));
    const tokenExpiration = localStorage.rememberMe === 'true' ? 1 : undefined;
    const in8Hours = new Date(new Date().getTime() + 8 * 60 * 60 * 1000);
    const refreshTokenExpiration = localStorage.rememberMe === 'true' ? in8Hours : undefined;
    const isNotLocalhost = window.location.hostname !== 'localhost';

    Cookies.set('refreshToken', refresh_token, {
        expires: refreshTokenExpiration,
        secure: isNotLocalhost,
        sameSite: isNotLocalhost ? 'None' : undefined,
    });
    Cookies.set('tokenExpiration', expireNumericValue, {
        expires: tokenExpiration,
        secure: isNotLocalhost,
        sameSite: isNotLocalhost ? 'None' : undefined,
    });
    Cookies.set('tokenInfo', bearerToken, {
        expires: tokenExpiration,
        secure: isNotLocalhost,
        sameSite: isNotLocalhost ? 'None' : undefined,
    });
}

export function loginUser(username, password) {

    return async (dispatch, getState) => {
        try {
            const uname = encodeURIComponent(username);
            const pword = encodeURIComponent(password);
            const tokenRequest =
                `client_id=supplier&client_secret=SupplierProSecret&grant_type=password&username=${uname}&password=${pword}`;
            const response =
                await axios.post(`${process.env.REACT_APP_ORION_API_ROUTE_URL}/AuthenticationService/connect/token`, tokenRequest)
            if (response?.data.clientId === 'supplier') {
                if (response?.status === 200)
                    getUserDefaults();

                dispatch({type: LOGIN_USER, ...response.data});
                getUserPolicies();

                return response.data;
            }
        } catch (e) {
            console.error(e);
        }
    }
}

export function getUserDashboardSettings() {
    return async (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'GET_USER_DASHBOARD_SETTINGS_REQ'}),
            response: (response) => dispatch({type: GET_USER_DASHBOARD_SETTINGS_RESP, settings: response.data}),
            error: (error) => dispatch({type: 'GET_USER_DASHBOARD_SETTINGS_ERR', response: error}),
        };

        bound.request();
        try {
            const success = await axios.get(`setting/user-dashboard`);
            return bound.response(success);
        } catch (error) {
            return bound.error(error);
        }
    };
}

export function impersonate(contactId) {
    return async (dispatch, getState) => {
        try {
            const response = await axios
                .post(`${process.env.REACT_APP_ORION_API_ROUTE_URL}/SettingsService/api/v1/impersonate/${contactId}`, {}, 
                    {params: {clientId: 'supplier'}});
            const {data: {token_type, access_token, refresh_token, expires_in}} = response;
            const bearerToken = `${token_type} ${access_token}`;
            setToken(refresh_token, expires_in, bearerToken);

            await dispatch({type: IMPERSONATE, ...response.data});

            return response;
        } catch (e) {
            console.error(e);
        }
    }
}

export function stopImpersonation() {
    return {type: STOP_IMPERSONATION};
}

export function initiateSisense(host) {
    return async (dispatch, getState) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_ORION_API_ROUTE_URL}/AuthenticationService/api/v1.0/sisense/initiate?host=${host}`
            );
        } catch (e) {
            console.error(e);
        }
    }
}

export function loginSisense(host) {
    return async (dispatch, getState) => {
        try {
            const response = await axios.get(`
                ${process.env.REACT_APP_ORION_API_ROUTE_URL}
                /AuthenticationService/api/v1.0/sisense/login
                ?is_impersonated=false
                &host=${host}
                &userAgent=${window.navigator.userAgent}
                &windowWidth=${window.innerWidth}
            `.replace(/[ \n]/g, ''), `client_id=supplier`);
            return response.data.redirectUrl;
        } catch (e) {
            console.error(e);
        }
    }
}

export function setSelectedFacility(selectedFacility) {
    return (dispatch) => dispatch({type: SET_SELECTED_FACILITY, selectedFacility});
}

export function acceptCookiesPolicy() {
    return async (dispatch, getState) => {
        try {
            const response = axios.post(`/user/cookies`, {agree: true});
            const {status} = response;

            if (status?.success) {
                dispatch(getUserPolicies());
            } else {
                console.error('Unable to accept Cookie Policies');
            }

            return;
        } catch (e) {
            console.error(e);
        }
    }
}

export function setGridPreferences(gridPreferences) {
    return (dispatch) => dispatch({type: SET_GRID_PREFERENCES, gridPreferences});
}

export default reducer;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getSettings: () => getSettings(),
    setAcceptCookiePolicy: () => setAcceptCookiePolicy(),
    getFacilities: () => getFacilities(),
    saveUserReduxState: (user) => saveUserReduxState(user),
    initializeUser: () => initializeUser(),
    getLists: () => getLists(),       
    setOktaAuthState: (authState) => setOktaAuthState(authState),
    setOktaAuth: (oktaAuth) => setOktaAuth(oktaAuth),
    logout: () => logout(),
    setUserId: (userId, firstName, lastName, subscriptionId, passwordExpired, eulaRequired) =>
        setUserId(userId, firstName, lastName, subscriptionId, passwordExpired, eulaRequired),
    setIsLoggingIn: (isLoggingIn) => setIsLoggingIn(isLoggingIn),
    setIsLoggingOut: (isLoggingOut) => setIsLoggingOut(isLoggingOut),
    setIsImpersonating: (isImpersonating) => setIsImpersonating(isImpersonating),
    loadMfaOptin: () => loadMfaOptin(),
    saveMfaOptin: () => saveMfaOptin(),
    getWidgets: () => getWidgets(),
    saveWidget: (payload) => saveWidget(payload),
    editWidget: (payload) => editWidget(payload),
    deleteWidget: (id) => deleteWidget(id),
    getPortals: () => getPortals(),
    savePortal: (payload) => savePortal(payload),
    editPortal: (payload) => editPortal(payload),
    deletePortal: (payload) => deletePortal(payload),
    updateUserPortalWidgetsOrder: (widgets) => updateUserPortalWidgetsOrder(widgets),
};

export function setOktaAuthState(authState) {
    return (dispatch) => dispatch({type: 'SET_OKTA_AUTH_STATE', authState}); // be careful 
}

export function setOktaAuth(oktaAuth) {
    return (dispatch) => dispatch({type: 'SET_OKTA_AUTH', oktaAuth});
}

export function logout() {
    return (dispatch) => dispatch({type: 'LOGOUT_REQ'});
}
export function setUserId(userId, firstName, lastName, subscriptionId, passwordExpired, eulaRequired) {
    let tokenInfo = {
        userId,
        firstName,
        lastName,
        loginId: userId,
        passwordExpired,
        eulaRequired,
    };

    if (subscriptionId)
        tokenInfo.subscriptionId = subscriptionId;

    if (userId) {
        return (dispatch) => {
            dispatch({type: 'SET_USER_ID', tokenInfo});
        };
    } else {
        return (dispatch, getState) => {
            return getState();
        }
    }
}

export function setIsLoggingIn(isLoggingIn) {
    return (dispatch) => {
        dispatch({type: 'SET_IS_LOGGING_IN', isLoggingIn});
    };
}

export function setIsLoggingOut(isLoggingOut) {
    return (dispatch) => {
        dispatch({type: 'SET_IS_LOGGING_OUT', isLoggingOut});
    };
}

export function setIsImpersonating(isImpersonating) {
    return (dispatch) => {
        dispatch({type: 'SET_IS_IMPERSONATING', isImpersonating});
    };
}

export function oktaLoginUser(userInfo, access_token, refresh_token) {    
    return async (dispatch, getState) => {      
        dispatch({type: 'OKTA_LOGIN_USER', userInfo, access_token, refresh_token});
        return userInfo;
    }
}

export function getSettings() {
    return async (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'GET_CONTACT_SETTINGS_REQ'}),
            response: (response) => dispatch({type: 'GET_CONTACT_SETTINGS_RESP', response: response}),
            error: (error) => dispatch({type: 'GET_CONTACT_SETTINGS_ERR', response: error}),
        };

        bound.request();
        try {
            const success = await axios.get('/ShoppingService/api/v1/company/contact/settings');
            return bound.response(success);
        } catch (error) {
            return bound.error(error);
        }
    };
}

export function setAcceptCookiePolicy() {
    return (dispatch) => {
        const bound = {
            response: (response) => dispatch({type: 'COOKIEPOLICIY_ACCEPT_RESP', response: response}),
            error: (error) => dispatch({type: 'COOKIEPOLICIY_ACCEPT_ERR', response: error}),
        };

        return axios.post('/ShoppingService/api/v1/account/cookies')
            .then(bound.response)
            .catch(bound.error);
    };
}

export function getFacilities() {
    return async (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'GET_FACILITIES_REQ'}),
            response: (response) => dispatch({type: 'GET_FACILITIES_RESP', response: response}),
            error: (error) => dispatch({type: 'GET_FACILITIES_ERR', response: error}),
        };

        bound.request();
        try {
            const success = await axios.post('/ShoppingService/api/v1/cart/facilities');
            return bound.response(success);
        } catch (error) {
            return bound.error(error);
        }
    };
}

export function saveUserReduxState(changes) {
    return (dispatch, getState) => {
        const {user} = getState();
        let updatedUser = _.merge(user, changes);
        Cookies.set(
            'facilityId',
            updatedUser.facility.facilityId,
            {expires: 30, 'secure': window.location.hostname !== 'localhost', sameSite: 'None'});
        dispatch({type: 'SAVE_USER_REDUX', user: updatedUser});
    };
}

export function initializeUser() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'INIT_USER_REQ'}),
            response: (response) => dispatch({type: 'INIT_USER_RESP', response: response}),
            error: (error) => dispatch({type: 'INIT_USER_ERR', response: error}),
        };

        bound.request();
        return axios.get('/ShoppingService/api/v1/account')
            .then(bound.response)
            .catch(bound.error);
    };
}

export function getLists() {
    return (dispatch) => {

        const bound = {
            request: () => dispatch({type: 'GET_LISTS'}),
            response: (response) => dispatch({type: 'GET_LISTS_RESP', response: response}),
            error: (err) => dispatch({type: 'GET_LISTS_FAILED', response: err}),
        };
        bound.request();
        return axios.get('/ShoppingService/api/v1/lists').then(bound.response)
            .catch(bound.error);
    }
}

export function saveWidget(payload) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'CREATE_NEW_WIDGET'}),
            response: (response) => dispatch({type: 'GENERAL_RESPONSE', response: response}),
            error: (err) => dispatch({type: 'CREATE_NEW_WIDGET_FAILED', response: err}),
        };
        bound.request();

        return axios.post('reporting-portal/widgets', payload).then(bound.response)
            .catch(bound.error);

    }
}

export function getWidgets() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'GET_WIDGETS', loading: true}),
            response: (response) => dispatch({type: 'GET_WIDGETS_RESP', response: response}),
            error: (err) => dispatch({type: 'GET_WIDGETS_FAILED', response: err}),
        };
        bound.request();

        return axios.get('reporting-portal/widgets').then(bound.response)
            .catch(bound.error);

    }
}

export function editWidget(payload) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'EDIT_WIDGET'}),
            response: (response) => dispatch({type: 'GENERAL_RESPONSE', response: response}),
            error: (err) => dispatch({type: 'EDIT_WIDGET_FAILED', response: err}),
        };
        bound.request();

        return axios.put(`reporting-portal/widgets/${payload.ravenId}`, payload).then(bound.response)
            .catch(bound.error);

    }
}

export function deleteWidget(id) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'DELETE_WIDGET'}),
            response: (response) => dispatch({type: 'GENERAL_RESPONSE', response: response}),
            error: (err) => dispatch({type: 'DELETE_WIDGET_FAILED', response: err}),
        };
        bound.request();

        return axios.delete(`reporting-portal/widgets/${id}`).then(bound.response)
            .catch(bound.error);

    }
}

export function updateUserPortalWidgetsOrder(widgets) {
    return {
        type: UPDATE_USER_PORTAL_WIDGETS_ORDER,
        widgets
    };
}

export function getPortals() {
    return async (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'GET_PORTALS', loading: true}),
            response: (response) => dispatch({type: 'GET_PORTALS_RESP', portals: response.data}),
            error: (err) => dispatch({type: 'GET_PORTALS_FAILED', response: err}),
        };
        bound.request();

        try {
            const success = await axios.get('reporting-portal/portals');
            return bound.response(success);
        } catch (error) {
            return bound.error(error);
        }
    }
}

export function savePortal(payload) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'CREATE_NEW_PORTAL'}),
            response: (response) => dispatch({type: 'GENERAL_RESPONSE', response: response}),
            error: (err) => dispatch({type: 'CREATE_NEW_PORTAL_FAILED', response: err}),
        };
        bound.request();

        return axios.post('/reporting-portal/portals', payload).then(bound.response)
            .catch(bound.error);

    }
}

export function editPortal(payload) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'EDIT_PORTAL'}),
            response: (response) => dispatch({type: 'GENERAL_RESPONSE', response: response}),
            error: (err) => dispatch({type: 'EDIT_PORTAL_FAILED', response: err}),
        };
        bound.request();

        return axios.put(`/reporting-portal/portals/${payload.ravenId}`, payload).then(bound.response)
            .catch(bound.error);

    }
}

export function deletePortal(id) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'DELETE_PORTAL'}),
            response: (response) => dispatch({type: 'GENERAL_RESPONSE', response: response}),
            error: (err) => dispatch({type: 'DELETE_PORTAL_FAILED', response: err}),
        };
        bound.request();

        return axios.delete(`/reporting-portal/portals/${id}`).then(bound.response)
            .catch(bound.error);

    }
}
