import React, {useState, useRef} from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {GridPagination} from '@partssourceinc/react-ui-core';

import {responsive} from 'utils/styles';
import {GridNames} from 'components/MultiView';

const GridContainer = styled.div`
    overflow-x: auto;
    padding-bottom: 0px;
    padding-top: 10px;
    padding-left: 8px;
`;

const PhoneLandscape = styled.div`
    ${responsive({max: 'md'})};
`;

const EmptyMessage = styled.div`
    padding-top: 50px;
    text-align: center;
    padding-bottom: 100px;
`;

const EmptyMessageTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
`;

const EmptyMessageDescription = styled.div`
    font-size: 16px;
    margin-top: 6px;
`;

const EmptyMessageImg = styled.img`
    margin-bottom: 30px;
`;

const GridView = (props) => {
    const {
        columns,
        currentPage,
        currentTab,
        data,
        gridKeys,
        gridName,
        onRenderLegend,
        onRenderRow,
        loadingData,
        onSelect,
        saveGridPreferences,
        setColumns,
        setCurrentPage,
        subLabel,
        tabCounts,
        defaultSorting,
    } = props;

    const [selectedPageSize, setSelectedPageSize] = useState(10);
    const [notification, setNotification] = useState('');

    const gridContainer = useRef(null);

    const arrayMoveMultiGrid = (arr, fromIndex, toIndex) => {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    const columnTemplate = (rowData, column) => {
        const c = columns.find((x) => x.field === column.field);

        switch (c.template) {
            case 'date-long-year':
                return rowData[c.field] ? moment(rowData[c.field]).format('MM/DD/YYYY') : '';
            case 'date-time':
                return rowData[c.field] ? moment(rowData[c.field]).format('MM/DD/YY h:mm A') : '';
            case 'trackingUrl':
                return rowData[c.field] ? <a href={rowData.trackingUrl} target="_blank" rel="noopener noreferrer">{rowData[c.field]}</a> : '';
            default:
                return null;
        }
    }

    const columnStyle = (column) => {
        switch (column.field) {
            case 'description':
            case 'manufacturer':
                return {minWidth: '250px'};
            case 'serialNumber':
            case 'orderId':
                return {minWidth: '90px'};
            case 'trackingNumber':
                return {minWidth: '110px'};
            case 'rgaDueDate':
            case 'rgaDateEntered':
            case 'partNumber':
            case 'vendorOrderNumber':
            case 'estimatedShipDate':
            case 'rgaReason':
            case 'shipDateReason':
                return {minWidth: '150px'};
            default:
                return null;
        }
    }

    const onPageChange = (e) => {
        setCurrentPage(e);
    }

    const onPageSizeChange = (pageSize) => {
        setSelectedPageSize(pageSize);
        setCurrentPage(1);
    }

    const onColReorder = (e) => {
        const tempColumns = _.orderBy(columns, ['visible', 'bid'], ['desc', 'asc']);

        arrayMoveMultiGrid(tempColumns, e.dragIndex, e.dropIndex);

        for (let i = 0; i < tempColumns.length; i++) {
            tempColumns[i].order = i;
        }

        setColumns(tempColumns);
        saveGridPreferences(tempColumns);
    }

    const onRowSelect = (row) => {
        onSelect(row);
    }

    const tab = tabCounts.filter((x) => x.id === currentTab)[0];
    let showEmptyMessage = (tab || {}).count === 0;

    let gridKey = gridKeys.filter((x) => x.id === currentTab)[0];

    if (!gridKey && data.length === 0) {
        showEmptyMessage = true;
        gridKey = {tabDisplay: subLabel};
    }
    
    const displayColumns = columns
        .filter((c) => c.visible !== 'never' &&
            c.visible === true)
        .map((col, i) => (
            <Column
                visible={col.visible}
                className={'preformatted column-formatting'}
                key={col.field}
                field={col.field}
                header={col.header}
                sortable={Boolean(col.sortable)}
                body={col.template ? columnTemplate : null}
                style={columnStyle(col)}
            />
        ));

    const getGridName = (gridId) => {
        return GridNames[gridId].name;
    }

    return (
        <div>
            {notification ? (
                <div className="selection-bar">
                    <span>{notification}</span>
                    <span className="buttons">
                        <i className="fa fa-remove" onClick={() => setNotification('')} />
                    </span>
                </div>
            ) : null}
            {(<>
                <PhoneLandscape>
                    {showEmptyMessage && !loadingData ? (
                        <EmptyMessage>
                            <EmptyMessageImg src="/images/icon_empty-queue.svg" />
                            <EmptyMessageTitle>
                                {`No Active ${gridKey.tabDisplay ?? ''} ${getGridName(gridName)} Found`}
                            </EmptyMessageTitle>
                            <EmptyMessageDescription>
                                {`You currently have no active ${gridKey.tabDisplay ?? ''} orders`}
                            </EmptyMessageDescription>
                        </EmptyMessage>
                    ) : null}
                </PhoneLandscape>
                <div>
                    <React.Fragment>
                        {!loadingData && (showEmptyMessage && data.length === 0) ? (
                            <EmptyMessage>
                                <EmptyMessageImg src="/images/icon_empty-queue.svg" />
                                <EmptyMessageTitle>
                                    {`No Active ${gridKey.tabDisplay ?? ''} ${getGridName(gridName)} Found`}
                                </EmptyMessageTitle>
                                <EmptyMessageDescription>
                                    {`You currently have no active ${gridKey.tabDisplay ?? ''} orders`}
                                </EmptyMessageDescription>
                            </EmptyMessage>
                        ) : (
                            <React.Fragment>
                                <GridContainer ref={gridContainer} className="grid-container">
                                    <DataTable
                                        id="custom-grid"
                                        className="p-datatable-striped"
                                        value={defaultSorting ? defaultSorting(data, currentTab) : data}
                                        selectionMode="single"
                                        resizableColumns={false}
                                        columnResizeMode="expand"
                                        reorderableColumns={true}
                                        rowClassName={onRenderRow ? (e) => onRenderRow(e, currentTab) : null}
                                        paginator={false}
                                        rows={selectedPageSize}
                                        onRowSelect={(e) => onRowSelect(e)}
                                        onColReorder={(e) => onColReorder(e)}
                                        responsive={true}
                                        first={(currentPage - 1) * selectedPageSize}
                                        scrollable={false}
                                        autoLayout={true}
                                        onPage={(e) => onPageChange(e)}
                                    >
                                        {displayColumns}
                                    </DataTable>
                                </GridContainer>
                                <div style={{lineHeight: '1'}}>
                                    <GridPagination
                                        displayPages={10}
                                        totalRecords={data.length}
                                        pageSize={selectedPageSize}
                                        onPageSizeChange={(d) => onPageSizeChange(d.value)}
                                        onSelect={(d) => onPageChange(d.value)}
                                        currentPage={currentPage}
                                    />
                                </div>
                                {onRenderLegend ? onRenderLegend() : null}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </div>
            </>)}
        </div>);
}

GridView.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string,
            visible: PropTypes.bool,
            order: PropTypes.number,
        })
    ),
    data: PropTypes.arrayOf(
        PropTypes.shape()
    ),
    gridKeys: PropTypes.arrayOf(
        PropTypes.shape({
            cardAction: PropTypes.string,
            fieldName: PropTypes.string,
            id: PropTypes.string,
            tabDisplay: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    gridName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onRenderLegend: PropTypes.func,
    onRenderRow: PropTypes.func,
    loadingData: PropTypes.bool,
    onRequesterToggle: PropTypes.func,
    onSelect: PropTypes.func,
    onRefresh: PropTypes.func,
    subLabel: PropTypes.string,
    setColumns: PropTypes.func,
    setCurrentPage: PropTypes.func,
    tabCounts: PropTypes.arrayOf(
        PropTypes.shape()
    ),
    saveGridPreferences: PropTypes.func,
    currentTab: PropTypes.string,
    currentPage: PropTypes.number,
    defaultSorting: PropTypes.func,
};

export default GridView
