import axios from 'axios';
import * as PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {processFiles} from 'utils/FileHandler';
import {FileUploader, Button, theme} from '@partssourceinc/react-ui-core';
import {addAttachmentToCache} from 'reducers/attachments';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/pro-regular-svg-icons';
import {removeAttachmentFromCache} from 'reducers/attachments';
import styled from 'styled-components';
import moment from 'moment';

const Wrapper = styled.div`
    max-width: 500px;
`;

const DocDetails = styled.div`
    border: 1px solid #cccccc;
    display: inline-block;
    text-align: left;
    padding: 7px;
    margin-top: 15px;
    width: 48%;

    &:nth-child(even) {
        float: left;
    }

    &:nth-child(odd) {
        float: right;
    }

    img {
        height: 80px;
        width: 119px;
        margin-bottom: 7px;
    }
`;

const UploadedDocsWrapper = styled.div`
    margin-top: 15px;
    border-top: #e2e1e1 solid 2px;
    line-height: 22px;
    font-size: 12px;
    font-weight: normal;
`;

const NoFile = styled.p`
    margin-top: 15px;
    color: #000000;
    font-size: 16px;
    font-weight: normal;
    height: 22px;
    line-height: 22px;
`;

const Title = styled.h1`
    font-family: "Source Sans Pro";
    font-weight: 300;
    height: 30px;
    margin-bottom: 15px !important;
`;

const DocAttributes = styled.p`
    color: #000000;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
`;

const DocAttributeName = styled(DocAttributes)`
    float: left;    
    padding-right: 5px;
`;

const DocAttributeValue = styled(DocAttributes)`
    font-weight: bold;        
`;

const DocAttributeLink = styled.a`
    color: #166BAD;
    font-weight: bold;
    word-wrap: break-word;
`;

const FileSizeError = styled(DocAttributes)`
    font-weight: bold;        
    font-size: 14px;
    font-weight: normal;
    margin: 0;
`;

const RemoveImage = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: ${theme.grey2};
    letter-spacing: 0;
    margin-right: 20px;

    &:hover {
      cursor: pointer;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    float: right;
    color: rgb(119, 119, 119);       
    cursor: pointer;
    font-size: 16px;
`;

const PhotoAttachment = (props) => {
    const {lineItemId, images, memorySave = false, isReadOnly = false} = props;
    const [uploadFile, setUploadFile] = useState({
        name: '',
        file: null,
    });
    const maxFileSizeMb = 10;
    const [photos, setPhotos] = useState([]);
    const companyId = useSelector(state => state.user.companyId);
    const cachedAttachments = useSelector(state => state.attachments.cachedAttachments.filter(x => x.lineItemId === lineItemId));
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showUpploadButton, setShowUpploadButton] = useState(true);
    const [showMaxFileSizeError, setShowMaxFileSizeError] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (memorySave) {
            setPhotos(cachedAttachments);
        } else if (images && images.length > 0)
            setPhotos(images)
    }, [])

    const removeAddedAttachment = (e) => {
        e.preventDefault();
        setUploadFile({
            name: '',
            file: null,
        });
        setShowUpploadButton(true);
        setIsFileSelected(false);
        setErrorMessage('');
        setShowMaxFileSizeError(false);
    }

    const handleUpload = (e) => {
        e.preventDefault();
        const files = e.target.files;

        if (files[0].size > maxFileSizeMb * 1024000) {
            setShowMaxFileSizeError(true);
        }

        const display = e.target
            .value
            .split('/')
            .pop()
            .split('\\')
            .pop();

        processFiles(files, display).then(res => {
            setUploadFile(res.uploadFile);
            setShowUpploadButton(false);
            setIsFileSelected(true);
            setErrorMessage(res.errorMessage);
        });
    }

    const removeCachedAttachment = (index) => {
        dispatch(removeAttachmentFromCache(index));
        let currentPhotos = [...photos];
        currentPhotos.splice(index, 1);
        setPhotos(currentPhotos);
    }

    const savePhoto = () => {
        const {name, file} = uploadFile;
        setLoading(true);
        setIsFileSelected(false);

        let request = {
            companyId: companyId,
            imageName: name,
            data: file,
            lineItemId: lineItemId,
            imageTypeId: 4,
        };

        if (!memorySave) {

            axios
                .post(`/order/image`, request)
                .then(resp => {
                    getPhotos();
                    setShowUpploadButton(true);
                    setLoading(false);
                });
        } else {

            let currentPhotos = [...photos];
            currentPhotos.push(request);
            dispatch(addAttachmentToCache(request));
            setShowUpploadButton(true);
            setPhotos(currentPhotos);
        }

        setLoading(false);
    }

    const getPhotos = () => {
        setLoading(true);

        axios
            .get(`/order/${lineItemId}/images`)
            .then(resp => {
                setPhotos(resp.data);
                setLoading(false);
            });
    }

    const renderPhoto = (photo, index) => {
        return (<DocDetails>

            {(!memorySave) ?
                <img src={`data:image/jpeg;base64, ${photo.image}`} />
                : <img src={`${photo.data}`} />}
            {!isReadOnly &&
                <Icon onClick={() => removeCachedAttachment(index)} aria-hidden="true" icon={faTimesCircle} />}

            <DocAttributes>
                <DocAttributeName>Name: </DocAttributeName>
                <DocAttributeLink href={`data:image/png;base64,${photo.image}`} download={photo.imageName}>
                    {photo.imageName}
                </DocAttributeLink>
            </DocAttributes>
            <DocAttributes>
                <DocAttributeName>Uploaded By: </DocAttributeName>
                <DocAttributeValue>
                    {photo.uploadedBy}&nbsp;
                </DocAttributeValue>
            </DocAttributes>
            <DocAttributes>
                <DocAttributeName>Date: </DocAttributeName>
                <DocAttributeValue>
                    {moment(photo.updatedTimestamp).format('dddd, MMMM D, YYYY')}
                </DocAttributeValue>
            </DocAttributes>
        </DocDetails>);
    }

    return (
        <Wrapper>
            <div>
                <Title>Attachments</Title>
            </div>
            {!isReadOnly &&
                <div>
                    <div>
                        <FileUploader
                            label="CHOOSE FILE"
                            maxMb="10"
                            restriction="Max file size 10MB"
                            isFileSelected={isFileSelected}
                            fileDisplay={uploadFile.name}
                            handleRemove={removeAddedAttachment}
                            onChange={handleUpload}
                            imagePreviewUrl={uploadFile.file}
                            infoCenter={true}
                            photosTab={true}
                            showUpload={showUpploadButton}
                        />
                    </div>
                    <span>{errorMessage}</span>
                    {isFileSelected && <div>
                        {!loading && showMaxFileSizeError &&
                            <FileSizeError>
                                {`File exceeds max size of ${maxFileSizeMb} Mb.`}
                            </FileSizeError>}
                        {!loading && <RemoveImage onClick={removeAddedAttachment}>
                            REMOVE
                        </RemoveImage>}
                        <Button className="btnSave" secondary={true} onClick={savePhoto}
                            loading={loading} disabled={showMaxFileSizeError}>
                            SAVE PHOTO
                        </Button>
                    </div>}
                </div>
            }
            <UploadedDocsWrapper>
                {photos && photos.length > 0 ? (
                    photos.map((item, index) => {
                        return renderPhoto(item, index);
                    })
                ) : (
                    <NoFile>No Files</NoFile>
                )}
            </UploadedDocsWrapper>
        </Wrapper>
    );
}

export default PhotoAttachment;

PhotoAttachment.propTypes = {
    lineItemId: PropTypes.string.isRequired,
    memorySave: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    images: PropTypes.arrayOf(
        PropTypes.shape()
    ),
};
