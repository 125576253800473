import * as React from 'react';
import * as PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

const Link = (props) => {
    const {href, children, onClick, className, isActive, style} = props;
    const [,, openInNewTab] = /([^?]*)(\?newtab)?$/.exec(href);
    const isExternal = /^https?:\/\//.test(href);
    let to = href.indexOf('?newtab') > -1 ? href.replace('?newtab', '') : href;

    return isExternal
        ? (
            openInNewTab ?
                <a href={to}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    onClick={onClick}
                    className={className}
                >{children}</a> :
                <a href={to}
                    onClick={onClick}
                    className={className}
                >{children}</a>
        )
        : (
            openInNewTab ?
                <NavLink to={to}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    onClick={onClick}
                    exact={true}
                    isActive={isActive}
                    className={className}
                    activeClassName="active"
                >{children}</NavLink> :
                <NavLink to={to}
                    onClick={onClick}
                    style={style}
                    exact={true}
                    isActive={isActive}
                    className={className}
                    activeClassName="active"
                >{children}</NavLink>
        );
};

Link.propTypes = {
    href: PropTypes.string.isRequired,
    isActive: PropTypes.func,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default Link;
