import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Button, Popup, brandFont} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import axios from 'axios';
import {setCachedBids} from 'reducers/bids';
import ErrorModal from 'components/ErrorModal';
import StepsCarousel from 'components/Bids/StepsCarousel';
import StepBox from 'components/Bids/StepBox';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamation} from '@fortawesome/free-solid-svg-icons';
import {HeaderRow} from 'components/DetailsStyledComponents';
import BidCondition from 'components/Bids/BidCondition';
import BidLoanerDetails from 'components/Bids/BidLoanerDetails';
import BidLoanerRepairDetails from 'components/Bids/BidLoanerRepairDetails';
import Attachments from 'components/AttachFile';
import {setCachedAttachments} from 'reducers/attachments';
import {logEvent, getErrorMessage} from 'utility';
import {validateWholeNumbers, validateMoney} from 'utils/validation';
import moment from 'moment';

const Container = styled.div`
width: 100%;    
position: relative;
display: flex;
justify-content: center;
flex-direction: column;
`;

const NotificationSpan = styled.span`
    color: #ffae3f;
    font-size: 16px;
    font-weight: 600;
    height: 22px;
    letter-spacing: 0px;
    line-height: 22px;
`;

const HeaderContainer = styled(Container)`
    width: 270px;        
    margin-left: auto;
    margin-right: auto;
`;

const Row = styled.div`
    margin-bottom: 1rem;
`;

const ButtonContainer = styled(Row)`
    width: 270px;        
    margin-left: auto;
    margin-right: auto;    
`;

const PopupContent = styled.p`
    ${brandFont('14px')};    
`;

const CancelLink = styled.a`
    float: right;
    margin-top: 15px;
    &&& {
        text-transform: uppercase;
        cursor: pointer;
        color: rgb(119, 119, 119);
        font-weight: bold;
        margin-right: 1em;

        &:hover {
            text-decoration: underline;
            outline: none;
        }

        &:focus {
            text-decoration: underline;
            outline: none;
        }

        &:active:focus {
            text-decoration: underline;
            outline: none;
        }
    }
`;

const Subtitle = styled.div`
    font-size: 14px;
    float: left;
`;

const OEMSubtitle = styled(Subtitle)`
    width: 30px;
`;

const DescriptionSubtitle = styled(Subtitle)`
    width: 70px;
`;

const BoldSubtitle = styled(Subtitle)`
    font-weight: 600;
    padding-left: 10px;
`;

const OEMSubtitleValue = styled(BoldSubtitle)`
    width: calc(100% - 30px);
`;

const DescriptionSubtitleValue = styled(BoldSubtitle)`
    width: calc(100% - 70px);
`;

const EmailLink = styled.a`
    color: #166BAD;
    font-weight: 400;  
`;

const RedExclamation = styled(FontAwesomeIcon)`
    width: 0.9em !important;
    height: 0.9em !important;
    padding: 2px;
    color: #ff0000;
    border: 1px solid;
    font-weight: 600;
    border-radius: 50%;
    font-size: 16px;
    margin-right: 6px;
    margin-left: 15px;
`;

const CHD = styled.span`
    font-family: "Source Sans Pro";
    color: rgb(255, 0, 0);
    font-size: 16px;
    font-weight: 600;
    height: 22px;
    letter-spacing: 0px;
    line-height: 22px;
`;

const YellowExclamation = styled(FontAwesomeIcon)`
    width: 0.9em !important;
    height: 0.9em !important;
    padding: 2px;
    font-weight: 600;  
    color: #ffae3f;
    border: 1px solid;
    border-radius: 50%;
    margin-left:15px;
    margin-right: 6px;
`;

const UnderlineSubtitle = styled(HeaderContainer)`
    font-family: "Source Sans Pro";
    border-top-style: solid;
    border-width: 2px;    
    border-color: rgb(204, 204, 204);
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 0;
`;

const Title = styled.h1`
    margin-bottom: 16px;
    font-size: 18px;
`;

export const WizzardHeader = styled.div`
    font-weight: 300;
    font-size: 24px; 
    margin-left: 2px;
    top: 8px;
    position: relative;    
    margin-bottom: 10px; 
    float: left; 
`;

const BidWizzard = (props) => {
    const {
        bid = {},
    } = props;

    const [steps, setSteps] = useState([]);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [currentStatus, setCurrentStatus] = useState('');
    const [bidConditions, setBidConditions] = useState([]);
    const [bidAttachments, setBidAttachments] = useState([]);
    const [currentCondition, setCurrentCondition] = useState(null);
    const [currentLoanerDetail, setCurrentLoanerDetail] = useState(null);
    const [currentRepairDetail, setCurrentRepairDetail] = useState(null);
    const cachedAttachments = useSelector(state => state.attachments.cachedAttachments);
    const [showPopup, setShowPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [isPast30Minutes, setIsPast30Minutes] = useState(false);
    const validCosmeticConditions = ['2', '3', '4', '38'];
    const history = useHistory();
    const dispatch = useDispatch();

    const isPart = bid?.orderType === 'PART ORDER';
    const conditionCodes = useSelector(state => isPart ? state.common.partConditionCodes : state.common.repairConditionCodes);
    const loanerConditionCodes = useSelector(state => state.common.loanerConditionCodes);
    useEffect(() => {
       
        checkIfPast30Minutes();

        let st = [];
        dispatch(setCachedAttachments([]));

        st.push({
            title: 'Bid Request',
            description: 'Review the bid details',
            type: 'started',
            valid: true,
            isDeletable: false,
        });

        if (!bid.loaner_LineItemId) {

            if (bid.orderType === 'REPAIR') {
                st.push({
                    title: 'Repair Details',
                    description: 'Enter your bid information',
                    type: 'upcoming',
                    valid: true,
                    isDeletable: false,
                });
            } else {
                st.push({
                    title: 'Bid #1',
                    description: 'Enter your bid information',
                    type: 'inProgress',
                    valid: true,
                    isDeletable: false,
                });
            }
        } else {
            st.push({
                title: 'Loaner Details',
                description: 'Enter your loaner information',
                type: 'inProgress',
                valid: true,
                isDeletable: false,
            });

            st.push({
                title: 'Repair Details',
                description: 'Enter your bid information',
                type: 'upcoming',
                valid: true,
                isDeletable: false,
            });            
        }

        st.push({
            title: 'Attachments',
            description: 'Upload documents (optional)',
            type: 'attachments',
            valid: true,
            isDeletable: false,
        });

        setCurrentStatus('start');

        setSteps(st);
        setCurrentStepIndex(1);

    }, [location])

    let isDelete = false;

    const checkIfPast30Minutes = () => {
        const dateEntered = new moment.utc(bid.dateEnteredUTC ?? bid.dateEntered).toDate();
        const dateNow = moment().utc().toDate();
        const diff = (dateNow - dateEntered);
        const diffMins = Math.floor((diff / 1000) / 60);
        setIsPast30Minutes(diffMins > 30);
    }

    const onDeleteStep = (index) => {

        if (index === 1 && bidConditions.length <= 1) {
            alert('You should have at least one condition.');
        } else {
            let currentConditions = [...bidConditions];

            currentConditions.splice(index - 1, 1);

            let currentSteps = [...steps];
            currentSteps.splice(index, 1);

            currentSteps.map((step, i) => {

                if (step.title.indexOf('Bid') >= 0 && i > 0) {
                    step.title = 'Bid #' + i;
                }
                // if only one bid
                if (currentSteps.length === 3) {
                    step.isDeletable = false;
                }
            })

            setBidConditions(currentConditions);
            setSteps(currentSteps);

            setCurrentCondition(currentConditions[0]);
            setCurrentStepIndex(1);

            isDelete = true;
        }
    }

    const onSelectedStep = (index, length) => {
        
        if (!isDelete) {
            
            validateConditions();

            if (length === undefined) {

                length = steps.length;
            }

            setCurrentStepIndex(index);
            if (index === 0)
                setCurrentStatus('started');
            else if (index < length - 1) {
                setCurrentStatus('conditions');

                let currentConditions = [...bidConditions];
                setCurrentCondition(currentConditions[index - 1]);

            } else
                setCurrentStatus('attachments');
        }

        isDelete = false;
    };

    const onMoveStep = (index, length) => {
        
        setCurrentStepIndex(index);
        if (index === 0)
            setCurrentStatus('started');
        else if (index < length - 1) {
            setCurrentStatus('conditions');

        } else
            setCurrentStatus('attachments');
    };

    const onClose = () => {
        setShowPopup(false);
    }

    const cancelBid = () => {

        setPopupMessage('Are you sure you want to decline this bid?');
        setShowPopup(true);
    }

    const validateConditions = () => {
        
        let currentSteps = [...steps];
        let allValid = true;

        let currentConditions = [...bidConditions];
        if (!bid.loaner_LineItemId) {
            if (bid.orderType !== 'REPAIR') {

                currentConditions.map((condition, index) => {
                    condition.isNew = false;
                    if (validateCondition(condition)) {
                        currentSteps[index + 1].valid = true;
                    } else {
                        allValid = false;
                        currentSteps[index + 1].valid = false;
                    }
                })
            } else {
                currentSteps[1].valid = true;
                if (currentStepIndex !== 1) {
                    if (!validateCondition(currentRepairDetail)) {
                        currentSteps[1].valid = false;
                        allValid = false;                    
                    }
                }
            }
        } else {
            
            currentSteps[1].valid = true;
            currentSteps[2].valid = true;

            if (currentStepIndex !== 1) {                                
                if (!validateCondition(currentLoanerDetail)) {
                    currentSteps[1].valid = false;
                    allValid = false;                    
                }
            }
            if (currentStepIndex !== 2 && currentSteps.length >= 4) {
                if (currentSteps[2].type !== 'upcoming' && !validateCondition(currentRepairDetail)) {
                    currentSteps[2].valid = false;
                    allValid = false;
                }
            }  
            
            if (currentStepIndex === 2 && !currentSteps[2].valid) {
                currentSteps[2].type = 'inProgress';
            }
        }

        setBidConditions(currentConditions);        
        setSteps(currentSteps);
        return allValid;
    }

    function createDefaultCondition() {
        return {
            replacemantPartNumber: null,
            exchangeCost: (0.00).toFixed(2),
            outrightCost: (0.00).toFixed(2),
            repairCost: (0.00).toFixed(2),
            leadDays: 0,
            conditionId: '',
            cosmeticRating: '',
            warrantyId: 0,
            warrantyCode: 'OTH',
            weight: 0,
            nonReturnable: false,
            loanerAvailable: false,
            attachments: [],
            orderType: bid.orderType,
            notes: '',
            lineItemId: bid.lineItemId,
            isNew: true,
            unitOfMeasureId: '1',
            qtyPerUnit: 1,
            minimumOrderQuantity: '',
            hazmatTypeId: '',
        }
    }

    function createDefaultLoanerDetail() {
        return {            
            replacementCost: (0.00).toFixed(2),
            repairCancelationFee: (0.00).toFixed(2),            
            leadDays: 0,
            warrantyId: 0,
            conditionId: '',                        
            note: '',            
            isNew: true,
            loanerFeeType: '',
        }
    }

    function createDefaultRepairDetail() {
        return {            
            minimumFee: (0.00).toFixed(2),
            maximumPrice: (0.00).toFixed(2),             
            warrantyId: 0,
            warrantyCode: 'OTH',          
            leadDays: 0,
            conditionId: '',            
            note: '',            
            isNew: true,
        }
    }

    const startBid = () => {
        setCurrentStatus('conditions');

        let currentConditions = [...bidConditions];
        const newCondition = createDefaultCondition();
        currentConditions.push(newCondition);
        setBidConditions(currentConditions);

        if (bid.loaner_LineItemId) {        
            setCurrentLoanerDetail(createDefaultLoanerDetail);
            setCurrentRepairDetail(createDefaultRepairDetail);
        } else if (bid.orderType === 'REPAIR') {
            setCurrentRepairDetail(createDefaultRepairDetail);
        }

        setCurrentCondition(newCondition);
    }

    const renderBidHeader = (hasOffset) => {
        const minDate = moment.utc('2000-01-01');
        const mailtoLink = 'mailto:' + bid.sourcerEmail;

        return (
            <React.Fragment>
                <HeaderContainer>
                    <Row className="row" style={{paddingLeft: hasOffset ? '35px' : '25px', paddingRight: '0'}}>
                        <HeaderRow className="col-11">
                            <Subtitle>Reference #: </Subtitle><BoldSubtitle>{bid?.lineItemId}</BoldSubtitle>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Subtitle>Model: </Subtitle><BoldSubtitle>{bid?.model}</BoldSubtitle>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <OEMSubtitle>OEM: </OEMSubtitle><OEMSubtitleValue>{bid?.manufacturer}</OEMSubtitleValue>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Subtitle>Item #: </Subtitle><BoldSubtitle>{bid?.partNumber}</BoldSubtitle>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <DescriptionSubtitle>Description: </DescriptionSubtitle>
                            <DescriptionSubtitleValue>{bid?.description}</DescriptionSubtitleValue>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Subtitle>Order Type: </Subtitle><BoldSubtitle>{bid?.orderType}</BoldSubtitle>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Subtitle>Qty: </Subtitle><BoldSubtitle>{bid?.quantity}</BoldSubtitle>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Subtitle>Requested: </Subtitle>
                            <BoldSubtitle>{(bid?.dateEntered !== undefined &&
                                moment(bid?.dateEntered).isAfter(minDate))
                                ? moment(bid?.dateEntered).format('MM/D/YYYY h:mm A')
                                : ''
                            }</BoldSubtitle>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Subtitle>Sourcer: </Subtitle>
                            {bid.sourcer?.toUpperCase() !== 'SYSTEM USE ONLY' &&
                                <BoldSubtitle>{bid?.sourcer}</BoldSubtitle>
                            }
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Subtitle>Sourcer Phone: </Subtitle><BoldSubtitle>{bid?.sourcerPhone}</BoldSubtitle>
                        </HeaderRow>
                        <HeaderRow className="col-11">
                            <Subtitle>Sourcer Email: </Subtitle>
                            {bid.sourcerEmail?.toLowerCase() !== 'system@partssource.com' ?
                                <BoldSubtitle>
                                    <EmailLink href={mailtoLink}>{bid.sourcerEmail}</EmailLink>
                                </BoldSubtitle>
                                :
                                <BoldSubtitle>
                                    <EmailLink href="mailto:sourcing@partssource.com">sourcing@partssource.com</EmailLink>
                                </BoldSubtitle>
                            }
                        </HeaderRow>
                    </Row>
                </HeaderContainer>
                <Row style={{height: '5px', paddingLeft: hasOffset ? '15px' : '0'}}>
                    <UnderlineSubtitle>&nbsp;</UnderlineSubtitle>
                </Row>
                <ButtonContainer>
                    {hasOffset &&
                        <Button style={{float: 'right'}} className="primary" type="button" onClick={() => nextStep()} >Continue</Button>
                    }
                    {!hasOffset &&
                        <Button style={{float: 'right'}} className="primary" type="button" onClick={() => startBid()} >Start Bid</Button>
                    }
                    <CancelLink onClick={() => cancelBid()} >No Bid</CancelLink>
                </ButtonContainer>
            </React.Fragment>
        )
    }

    const nextStep = () => {
        setCurrentCondition(bidConditions[0]);
        onSelectedStep(currentStepIndex + 1, steps.length);
    }

    const saveProgress = (bidCondition) => {
        let currentConditions = [...bidConditions];
        if (!bid.loaner_LineItemId) {
            if (bid.orderType === 'REPAIR') {
                setCurrentRepairDetail(bidCondition);
            } else {
                currentConditions[currentStepIndex - 1] = bidCondition;
                setBidConditions(currentConditions);
            }            
        } else {            
            if (currentStepIndex === 1) {
                setCurrentLoanerDetail(bidCondition);
            } else if (currentStepIndex === 2) {
                setCurrentRepairDetail(bidCondition)
            }
        }
    }

    const continueBid = (bidCondition) => {
        let currentConditions = [...bidConditions];
        currentConditions[0].type = 'started'

        let currentSteps = [...steps];
        if (!bid.loaner_LineItemId) {
            if (bid.orderType === 'REPAIR') {
                setCurrentRepairDetail(bidCondition);
            } else {
                currentConditions[currentStepIndex - 1] = bidCondition;
                setBidConditions(currentConditions);
            }            
        } else {            
            if (currentStepIndex === 1) {
                setCurrentLoanerDetail(bidCondition);
            } else if (currentStepIndex === 2) {
                setCurrentRepairDetail(bidCondition)
            }
        }
        
        currentSteps[currentStepIndex].type = 'completed';          
        
        if (currentSteps.length === 3 && bid.loaner_LineItemId) {
            
            currentSteps = addAfter(currentSteps, currentSteps.length - 1,
                {
                    title: 'Repair Details',
                    description: 'Enter your bid information',
                    type: 'inProgress',
                    valid: true,
                    isDeletable: false,
                })
        }
        
        setSteps(currentSteps);                
        onMoveStep(currentStepIndex + 1, steps.length + 1);
    }

    const addCondtion = (bidCondition) => {

        let currentConditions = [...bidConditions];

        currentConditions[currentStepIndex - 1] = bidCondition;

        let newCondition = createDefaultCondition();
        setCurrentCondition(newCondition);

        currentConditions.push(newCondition);
        setBidConditions(currentConditions);
        setCurrentStepIndex(currentStepIndex + 1);

        let currentSteps = [...steps];

        currentSteps.map((step, index) => {
            if (step.title.indexOf('Bid') >= 0 && index > 0) {
                step.isDeletable = true;
            }

            if (index === currentStepIndex)
                step.type = 'completed';
        })
        
        currentSteps = addAfter(currentSteps, currentSteps.length - 1,
            {
                title: 'Bid #' + currentConditions.length,
                description: 'Enter your bid information',
                type: 'inProgress',
                valid: false,
                isDeletable: true,
            })

        setSteps(currentSteps);
        onMoveStep(steps.length - 1, steps.length + 1);
    }

    const canAddMore = () => {
        const notValidBidConditions = bidConditions.filter(x => !x.condtionId).length;
        const isRepairOrder = (bid.orderType.toUpperCase() === 'REPAIR' || bid.orderType.toUpperCase() === 'REPAIR WITH LOANER');
        return (!isRepairOrder && (notValidBidConditions <= 1 || currentStepIndex === bidConditions.length));
    }

    const addAttachmentCondtion = () => {
        if (canAddMore()) {
            let currentConditions = [...bidConditions];

            let newCondition = createDefaultCondition();
            setCurrentCondition(newCondition);

            currentConditions.push(newCondition);
            setBidConditions(currentConditions);
            setCurrentStepIndex(currentConditions.length - 1);

            let currentSteps = [...steps];

            currentSteps.map((step, index) => {
                if (step.title.indexOf('Bid') >= 0 && index > 0) {
                    step.isDeletable = true;
                }

                if (index === currentStepIndex && step.type !== 'attachments')
                    step.type = 'completed';
            })

            currentSteps = addAfter(currentSteps, currentSteps.length - 1,
                {
                    title: 'Bid #' + currentConditions.length,
                    description: 'Enter your bid information',
                    type: 'inProgress',
                    valid: false,
                    isDeletable: true,
                })

            setSteps(currentSteps);
            onMoveStep(steps.length - 1, steps.length + 1);
        }
    }

    function addAfter(array, index, newItem) {
        return [
            ...array.slice(0, index),
            newItem,
            ...array.slice(index),
        ];
    }

    function validateCondition(condtion) {

        let conditionsValid = true;
        let allConditionTypes = [];

        if (!bid.loaner_LineItemId && bid.orderType !== 'REPAIR') {

            if (condtion.outrightCost === '' || !validateMoney(condtion.outrightCost)) {
                conditionsValid = false;
            }
            if (isNaN(currentCondition.weight)) {
                conditionsValid = false;
            }

            if (!validateWholeNumbers(condtion.warrantyId)) {
                conditionsValid = false;
            }
            if (condtion.hazmatTypeId === '') {
                conditionsValid = false;
            }

            if (validCosmeticConditions.indexOf(condtion.conditionId) > -1 &&
                condtion.cosmeticRating === '') {
                conditionsValid = false;
            }
        } else {
                                    
            if (condtion?.replacementCost && (condtion?.replacementCost === '' || !validateMoney(condtion?.replacementCost))) {            
                conditionsValid = false;
            } 
            if (condtion?.repairCancelationFee && (condtion?.repairCancelationFee === '' || 
                !validateMoney(condtion?.repairCancelationFee))) {                
                conditionsValid = false;
            }  
            if (condtion?.warrantyId && !validateWholeNumbers(condtion.warrantyId)) {                
                conditionsValid = false;
            }           
        }
        
        if (!validateWholeNumbers(condtion.leadDays, true)) {
            conditionsValid = false;
        }

        if (condtion.conditionId === '') {
            conditionsValid = false;
        } else {
            if (allConditionTypes.indexOf(condtion.conditionId) > -1) {
                conditionsValid = false;
            }
        }
        
        allConditionTypes.push(condtion.conditionId);
        
        return conditionsValid;
    }

    const getCondition = (conditionId) => {
        let c = conditionCodes?.filter(f => f.id === conditionId);
        if (c && c.length > 0)
            return c[0].code;
        else
            return '';
    }

    const extractPostData = () => {
        return {
            'orderId': bid.orderId,
            'lineItemId': bid.lineItemId,
            'companyId': bid.companyId,
            'companyName': bid.company,
            'conditions': bidConditions.map(item => {
                item.unitOfMeasureId = Number(item.unitOfMeasureId);
                item.outrightCost = item.outrightCost.split('$').join('').split(',').join('');
                item.exchangeCost = item.exchangeCost?.split('$').join('').split(',').join('');
                item.hazmatTypeId = Number(item.hazmatTypeId);
                return item;
            }),
        }
    }

    const extractLoanerPostData = () => {

        let loanerConditions = [];
                
        let repairIndex = 0;
        if (bid.loaner_LineItemId) {
            loanerConditions.push(currentLoanerDetail);

            loanerConditions[0].replacementCost = loanerConditions[0].replacementCost.split('$').join('').split(',').join('');
            loanerConditions[0].repairCancelationFee = loanerConditions[0].repairCancelationFee.split('$').join('').split(',').join('');
            loanerConditions[0].orderType = 'LOANER'
            loanerConditions[0].loanerAvailable = true;
            loanerConditions[0].lineItemId = bid.loaner_LineItemId;
            
            repairIndex = 1;
        }

        loanerConditions.push(currentRepairDetail);

        loanerConditions[repairIndex].minimumFee = loanerConditions[repairIndex].minimumFee.split('$').join('').split(',').join('');
        loanerConditions[repairIndex].maximumPrice = loanerConditions[repairIndex].maximumPrice.split('$').join('').split(',').join('');
        loanerConditions[repairIndex].orderType = 'REPAIR'
        loanerConditions[repairIndex].loanerAvailable = true;
        loanerConditions[repairIndex].lineItemId = bid.lineItemId;
        
        return {
            'orderId': bid.orderId,
            'lineItemId': bid.loaner_LineItemId,
            'companyId': bid.companyId,
            'companyName': bid.company,
            'isSodexo': bid.isSodexo,
            'conditions': loanerConditions,                            
        }
    }

    const handleOnSubmit = async (e) => {

        e.preventDefault();

        let currentBid = null;

        if (!bid.loaner_LineItemId && bid.orderType !== 'REPAIR')
            currentBid = extractPostData();
        else
            currentBid = extractLoanerPostData();
                        
        if (validateConditions()) {
            setIsLoading(true);                        
            await axios.post(`/bid`, currentBid)
                .then(() => {

                    if (cachedAttachments.length > 0) {
                        cachedAttachments.map((attachment, index) => {

                            let request = {
                                companyId: bid?.companyId,
                                imageName: attachment.name,
                                data: attachment.data,
                                lineItemId: bid?.lineItemId,
                                imageTypeId: 4,
                            };

                            axios.post(`/order/image`, request)
                                .then(() => {
                                    setIsLoading(false);
                                });
                        })
                    }
                    currentBid.conditions.map((item) => {
                        logEvent('SUBMIT BID', {
                            'Ref number': bid.lineItemId,
                            'Vendor ID': bid.companyId,
                            'Bid State': 'Bid',
                            'Condition': getCondition(parseInt(item.conditionId)),
                        });
                    })

                    dispatch(setCachedBids([]));
                    history.push('/bids');
                    setPopupMessage('Bid saved successfully!');
                })
                .catch(() => {
                    setPopupTitle('Place Bid');
                    setPopupMessage(getErrorMessage('bid'));
                    setIsLoading(false);
                    setShowErrorPopup(true);
                });
        } else {

            let currentSteps = [...steps];
            currentSteps.map((step, index) => {
                if (!step.valid) {
                    setCurrentStepIndex(index);
                    setCurrentCondition(bidConditions[index - 1]);
                    return;
                }
            })
        }
    };

    const handleOnCancelBid = async (e) => {

        setIsLoading(true);

        const declineBid = {
            orderId: bid.orderId,
            lineItemId: bid.lineItemId,
            companyId: bid.companyId,
        }

        const response = await axios.post(`/bid/decline`, declineBid);

        if (response.status === 201) {
            dispatch(setCachedBids([]));
            history.push('/bids');
            logEvent('SUBMIT BID', {
                'Ref number': bid.lineItemId,
                'Vendor ID': bid.companyId,
                'Bid State': 'No Bid',
            });
        } else {
            setPopupTitle('Decline Bids');
            setPopupMessage('Server error. Please try again later.');
        }

        setIsLoading(false);
        setShowPopup(true);
    }

    const renderBidCondition = () => {

        return (
            <BidCondition
                bid={bid}
                condition={currentCondition}
                onContinue={continueBid}
                onAddCondition={addCondtion}
                conditionCodes={conditionCodes}
                conditionExists={conditionExists}
                canAddMore={canAddMore}
            />
        )
    }

    const renderBidLoanerDetails = () => {        
        return (
            <React.Fragment>                
                {currentStepIndex === 1 ? (

                    <BidLoanerDetails
                        bid={bid}
                        loaner={currentLoanerDetail}
                        onContinue={continueBid}                
                        conditionCodes={loanerConditionCodes}
                        saveProgress={saveProgress}
                    />
                )
                    :
                    <BidLoanerRepairDetails
                        bid={bid}
                        repair={currentRepairDetail}
                        onContinue={continueBid}                
                        conditionCodes={conditionCodes}    
                        saveProgress={saveProgress}            
                    />
                }
            </React.Fragment>
        )        
    }

    const renderBidRepairDetails = () => {
                
        return (
            <React.Fragment>                
                <BidLoanerRepairDetails
                    bid={bid}
                    repair={currentRepairDetail}
                    onContinue={continueBid}                
                    conditionCodes={conditionCodes}                
                />                              
            </React.Fragment>
        )        
    }

    const conditionExists = (conditionId) => {

        const condition = bidConditions.find(i => i.conditionId === conditionId);
        const indexOfCondition = bidConditions.indexOf(condition);

        if (!condition || (indexOfCondition + 1) === currentStepIndex) {
            return false
        }
        return bidConditions.filter(x => x.conditionId !== '').map(x => x.conditionId).some(i => i === conditionId);
    }

    const onAttachmentUpload = (attachment) => {}

    const onAttachmentCancel = () => {}

    const renderBidAttachments = () => {
        return (
            <React.Fragment>
                <HeaderContainer style={{paddingLeft: '20px'}}>

                    <Attachments
                        handleOnSave={(e) => onAttachmentUpload(e)}
                        handleOnCancel={() => onAttachmentCancel()}
                        memorySave={true}
                        showCancel={false}
                        allowMultipleFiles={true}
                        attachments={bidAttachments}
                        lineItemId={bid?.lineItemId}
                        customSave={false}
                    />

                </HeaderContainer>
                <Row style={{height: '5px', paddingLeft: '15px'}}>
                    <UnderlineSubtitle>&nbsp;</UnderlineSubtitle>
                </Row>
                <ButtonContainer style={{width: '320px', paddingLeft: '20px'}}>
                    <Button loading={isLoading} style={{float: 'right'}} className="primary" type="button"
                        onClick={(e) => handleOnSubmit(e)} >Submit Bid</Button>
                    {(canAddMore() && !bid.loaner_LineItemId) && 
                        <CancelLink onClick={() => addAttachmentCondtion()} >Add Another Condition</CancelLink>}
                </ButtonContainer>
            </React.Fragment>
        )
    }

    const onSelectedStepLocal = (event, index) => {
        setBidAttachments([]);
    }

    const hideErrorPopup = () => {
        setShowErrorPopup(false);
    }

    return (
        <Container>
            <HeaderContainer style={{width: 'auto'}}>
                <WizzardHeader>Reference #{bid?.lineItemId}
                    {(bid?.urgencyDescription === 'CHD' || bid?.urgencyDescription === 'STAT') ? (
                        <div style={{float: 'right', fontSize: '10px', paddingLeft: '10px'}}>
                            <CHD style={{float: 'left'}}><RedExclamation icon={faExclamation} />Urgent</CHD>

                        </div>
                    ) : bid?.status === 'REQUESTED' && isPast30Minutes ?
                        <div style={{float: 'right'}}>
                            <NotificationSpan><YellowExclamation icon={faExclamation} />Past 30 Minutes</NotificationSpan>
                        </div>
                        : <div>&nbsp;</div>}
                </WizzardHeader>
            </HeaderContainer>
            {currentStatus !== 'start' &&
                                    
                <StepsCarousel steps={steps}
                    bid={bid}
                    conditions={bidConditions}
                    loaner={currentLoanerDetail}
                    repair={currentRepairDetail}
                    currentStep={currentStepIndex}
                    conditionCodes={conditionCodes}
                    loanerConditionCodes={loanerConditionCodes}
                    onSetSelectedStep={onSelectedStep}
                    onDeleteStep={(i) => onDeleteStep(i)}
                />                                    
            }
            {currentStatus === 'start' &&
                <React.Fragment>
                    <HeaderContainer>
                        <StepBox
                            className={'last'}
                            key={0}
                            index={0}
                            type={'start'}
                            title={'Bid Request'}
                            description={'Review the bid details'}
                            completed={false}
                            inactive={false}
                            valid={true}
                            isDeletable={false}
                            onSelectedStep={(e, i) => onSelectedStepLocal(e, i)}
                            active={true}
                            conditionCodes={conditionCodes}
                            isBidRequestStep={true}
                        />
                    </HeaderContainer>
                    {renderBidHeader(false)}
                </React.Fragment>
            }

            {currentStatus === 'started' &&
                renderBidHeader(true)
            }
            
            {currentStatus === 'conditions' &&
                <React.Fragment>
                    {(!bid.loaner_LineItemId && bid.orderType !== 'REPAIR') ? (
                        renderBidCondition()
                    )
                        :
                        <React.Fragment>
                            {(bid.loaner_LineItemId) ? (                                     
                                renderBidLoanerDetails()                
                            )
                                :
                                renderBidRepairDetails()
                            }
                        </React.Fragment>
                    }
                </React.Fragment>
            }
                                                                                    
            {currentStatus === 'attachments' &&
                renderBidAttachments()
            }
            
            {showPopup && <Popup
                onCancel={onClose}
                show={showPopup}
                onConfirm={handleOnCancelBid}
                confirmText="Ok"
                cancelText="Cancel">
                <PopupContent>
                    <Title>{popupTitle}</Title>
                    {popupMessage}
                </PopupContent>
            </Popup>}

            <ErrorModal
                show={showErrorPopup}
                popupTitle={popupTitle}
                popupMessage={popupMessage}
                onCancel={() => hideErrorPopup()}
                hideCancel={true}
            />

        </Container>
    )
};

export default BidWizzard;

BidWizzard.propTypes = {
    bid: PropTypes.shape({}),
    conditions: PropTypes.shape([]),
};

