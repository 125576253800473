import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

const EmailLink = styled.a`
    color: #166BAD;
 word-break: break-all;
`;

const BidsCardDescription = (props) => {
    const {cardData, showCompanyInDescription} = props;
    const mailtoLink = 'mailto:' + cardData.sourcerEmail;
        
    return (
        <>
            <span className="detail">
                Qty: <span>{cardData.quantity}</span>
            </span>

            <span className="detail">
                OEM: <span>{cardData.manufacturer}</span>
            </span>

            <span className="detail">
                Item #: <span>{cardData.partNumber}</span>
            </span>

            <span className="detail">
                Model: <span>{cardData.model}</span>
            </span>

            <span className="detail">
                Order Type: <span>{cardData.orderType}</span>
            </span>

            <span className="detail">                
                Sourcer: 
                {cardData.sourcer?.toUpperCase() !== 'SYSTEM USE ONLY' &&
                    <span>{cardData.sourcer}</span>
                }
            </span>

            <span className="detail">
                Sourcer Phone: <span>{cardData.sourcerPhone}</span>
            </span>

            <span className="detail">
                Sourcer Email: 
                {cardData.sourcerEmail?.toLowerCase() !== 'system@partssource.com' ?                            
                    <EmailLink href={mailtoLink}>&nbsp;{cardData.sourcerEmail}</EmailLink>
                    :
                    <EmailLink href="mailto:sourcing@partssource.com">&nbsp;sourcing@partssource.com</EmailLink>
                }                                                   
            </span>

            {showCompanyInDescription &&
                <span className="detail">
                    Company: <span>{cardData.company}</span>
                </span>
            }
        </>);
}

BidsCardDescription.propTypes = {
    cardData: PropTypes.shape({
        company: PropTypes.string,
        manufacturer: PropTypes.string,
        orderType: PropTypes.string,
        partNumber: PropTypes.string,
        quantity: PropTypes.number,
        sourcer: PropTypes.string,
        sourcerEmail: PropTypes.string,
        sourcerPhone: PropTypes.string,
        model: PropTypes.string,
    }),
    showCompanyInDescription: PropTypes.bool,
}

export default BidsCardDescription;
