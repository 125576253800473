import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash'
import {setCachedRepairs, setBreadCrumbs} from 'reducers/orders';
import {setCachedAttachments} from 'reducers/attachments';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy, faCalendar} from '@fortawesome/pro-regular-svg-icons';
import {Loader} from '@partssourceinc/react-ui-core';
import {Popup, brandFont, Button} from '@partssourceinc/react-ui-core';
import NotesModal from 'components/NotesModal';
import Attachments from 'components/AttachFile';
import styled from 'styled-components';
import RepairHeader from 'components/Repairs/RepairHeader';
import OrderLine from 'components/OrderLine';
import ReceivedDetails from 'components/Repairs/ReceivedDetails';
import RmaInputModal from 'components/RmaInputModal';
import RepairFooter from 'components/Repairs/RepairFooter';
import {Context, STATUS_ENUM} from 'utils/enums';
import {logEvent, printPackingSlipPdf, printPurchaseOrderPdf, printShippingLabelPdf, formatMoney, getErrorMessage} from 'utility';
import ProductImage from '@partssourceinc/react-ui-core/components/ProductImage';
import {ImgContainer, Container, SecurityErrorText} from 'components/DetailsStyledComponents';
import ErrorModal from 'components/ErrorModal';
import DownloadingDots from 'components/DownloadingDots';
import PageBreadcrumbs from 'components/Breadcrumbs';
import QuotedRepairModal from 'components/Repairs/QuotedRepairModal';
import moment from 'moment';
import {GridKeys} from 'utils/gridKeys/repairsGridKeys';
import {faExclamation} from '@fortawesome/free-solid-svg-icons';

const OrderActionMobileContainer = styled.div`
    display: none;

    @media (max-width: 818px) {
        width: 100%;
        display: block;
        margin-top: 20px;
    }
`;

const OrderActionMobileContent = styled.div`
    width: 200px;
    float: right;
`;

const FooterContainer = styled.div`
    
`;

const OrderGridContent = styled.div`
    width: 100%;    
    position: relative;
    display: flex;    
`;

const OrderDetailField = styled.div`
    line-height: 25px;
    width: 100%;
    padding-left: 15px;
`;

const OrderDetailFieldMobile = styled(OrderDetailField)`
    display: none;
    margin-top: 15px;

    p {
        width: 100%;
        float: left;
        margin-bottom: 0;
    }

    @media (max-width: 1010px) {
        display: block;
        padding-right: 0;
    }
`;
const Row = styled.div`
    margin-bottom: 1rem;       
`;

const Subtitle = styled.div`
    font-size: 16px;
    float: left;
`;

const PopupContent = styled.div`
    ${brandFont('16px')}
`;

const Icon = styled(FontAwesomeIcon)`
    float: left;
    color: black;
    margin-top: 14px;
    margin-right: 5px;
    margin-left: 5px;
`;

const OrderHeaderText = styled.h2`
    margin-top: 14px;
    margin-bottom: 25px;
    font-size: 30px;
    font-weight: 300;

    @media (max-width: 818px) {
        
        margin-top: 20px;
    }
`;

const BoldSubtitle = styled(Subtitle)`
    font-weight: 600;
    padding-left: 10px;    
`;

const OrderGridRow = styled.div`
    margin-top: 20px;    
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2px;

    @media (max-width: 818px) {
        height: 50px !important;
    }
`;
const OrderGridRowExpanded = styled.div`
    margin-top: 0;    
    padding-left: 125px;
    padding-right: 0;
    padding-bottom: 2px;
    width: 100%;
`;

const OrderGridHeader = styled.div`
    margin-top: 20px;    
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2px;

    @media (max-width: 818px) {
        height: 25px;
    }
`;

const OrderDetailsHeader = styled(Subtitle)`
    font-size: 20px;
    padding-left: 24px;
    min-width: 200px;
    display: inline-block;
    width: calc(100% - 625px);

    @media (max-width: 1110px) {
        width: calc(100% - 525px);
    }

    @media (max-width: 1010px) {
        width: calc(100% - 425px);
    }

    @media (max-width: 910px) {
        width: calc(100% - 325px);
    }

    @media (max-width: 818px) {
        min-width: 215px;
        padding-bottom: 10px;
        width: 100%;
    }
`;

const QuoteDetailsHeader = styled(OrderDetailsHeader)`
    font-size: 14px;
    padding-top: 5px;
`;

const OrderDetailsColumnQty = styled.div`
    display: inline-block;
    width: 140px;    
    position: relative;
    top: -6px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsColumnPrice = styled.div`
    display: inline-block;
    width: 140px;    
    position: relative;
    top: -6px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 818px) {
        text-align: left;
    }
`;

const ViewDetailsColumn = styled.div`
    display: inline-block;
    width: 140px;    
    position: relative;
    top: -6px;
    text-align: center;
    float: right;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }
`;

const ViewDetailsText = styled(Subtitle)`
    font-weight: 400;
    color: rgb(148, 148, 148);  
    font-size: 16px;  
    margin-top: 6px;
    width: 100%;
    cursor: pointer; 
    text-decoration: underline;
`;

const OrderDetailsQty = styled(OrderDetailsColumnQty)`
    color: rgb(44, 58, 71);
    font-size: 16px;
    font-weight: 400;
    width: 140px;
    max-width: 140px;
    min-width: 140px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsPrice = styled(OrderDetailsColumnPrice)`
    color: rgb(44, 58, 71);
    font-size: 16px;
    font-weight: 400;
    width: 140px;
    max-width: 140px;
    min-width: 140px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsContainer = styled.div`
    display: inline-block;
    margin-top: -10px;
    width: calc(100% - 750px);
    
    @media (max-width: 1110px) {
        width: calc(100% - 650px);
    }

    @media (max-width: 1010px) {
        width: calc(100% - 550px);
    }

    @media (max-width: 910px) {
        width: calc(100% - 450px);
    }

    @media (max-width: 818px) {
        width: 100%;
    }
`;

const OrderActionContainer = styled.div`    
    width: 200px;
    position: absolute;
    right: 0;
    top: -5px;

    @media (max-width: 818px) {
        display: none;
    }    
`;

const OrderPrintButton = styled.div`
    display: inline-block;
    margin-right: 22px;
    width: 180px;    
    float: right;
    position: relative;

    @media (max-width: 818px) {
        display: none;
    }
`;

const BoldSubtitleNoPadding = styled(Subtitle)`
    font-weight: 700;
    font-size: 16px;
    color: rgb(0, 93, 166);
    cursor: pointer;
`;

const BoldSubtitleNoLink = styled(Subtitle)`
    font-weight: 700;
    font-size: 16px;    
`;

const OrderButtonContainer = styled.div`    
    margin-bottom: 15px;    
    padding-right: 0;
    float: right;
    z-index: 1000;
`;

const OrderButton = styled(Button)`
    border-radius: 0;   
    width: 200px;    
    float: right;
`;

const BlueText = styled(BoldSubtitle)`
    color: #166BAD;
    padding-left: 0;
    font-weight: 400;
    margin-top: 10px;
    cursor: pointer;
`;

const PrintBlueText = styled(BlueText)`  
    margin-top: 6px;    
    font-weight: 600;
    font-size: 14px;
`;

const UnderlineSubtitle = styled(Subtitle)`
    width: 100%;
    border-top-style: solid;
    border-width: 2px;    
    border-color: rgb(204, 204, 204);
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 0;
`;

const UnderlineSubtitleRow = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 0;      
`;

const UnderlineSubtitleBottom = styled(UnderlineSubtitle)`
    height: 5px;
    margin-bottom: 0;          
`;

const SmallTitle = styled.div`    
    
    color: rgb(144, 144, 144);  
    font-size: 18px;    
`;

const GraySubtitle = styled(Subtitle)`
    font-weight: 400;
    color: rgb(148, 148, 148);  
    font-size: 16px;  
    margin-top: 6px;
    width: 100%;
`;

const HeaderLabels = styled(GraySubtitle)`
    @media (max-width: 818px) {
        display: none;
    }
`;

const GraySubtitleHeader = styled(Subtitle)`
    font-weight: 400;
    color: rgb(148, 148, 148);  
    font-size: 16px;  
    margin-top: 6px;
    width: 100%;

    @media (max-width: 818px) {
        display: none;
    }
`;

const OrderLinkWrapper = styled.div`
    width: 100%;    
    padding-bottom: 10px;     
    padding-right: 0!Important;
    margin-left: -15px;
`;

const OrderLink = styled.a`
      
    margin-top: 10px;    

    &&& {
        text-transform: uppercase;
        cursor: ${props => props.isDisabled === true ? 'not-allowed' : 'pointer'};
        color: ${props => props.isDisabled === true ? '#B7B8BB' : '#005BA6'};
        font-weight: 600;

        &:hover {
            text-decoration: none;
            outline: none;
            color: ${props => props.isDisabled === true ? '#B7B8BB' : '#005BA6'};
        }

        &:focus {
            text-decoration: none;
            outline: none;
        }

        &:active:focus {
            text-decoration: none;
            outline: none;
        }
    }
`;

const OrderGrayLink = styled(Subtitle)`
    color: #777777;
    font-weight: bold;
    cursor: pointer;
`;

const PartImage = styled.span`
    display: inline-block;
    vertical-align: top;
    cursor: ${props => props.isLink ? 'pointer' : null};
`;

const RedExclamation = styled(FontAwesomeIcon)`
    width: 1em !important;
    height: 1em !important;
    padding: 2px;
    color: #ff0000;
    border: 1px solid;
    border-radius: 50%;
    font-size: 14px;
    margin-right: 3px;
`;

const RedNotification = styled.span`
    color: #ff0000;
    font-weight: 600;
    margin-top: 5px;
    font-size: 15px;
`;

const YellowExclamation = styled(FontAwesomeIcon)`
    width: 1em !important;
    height: 1em !important;
    padding: 2px;
    color: #ffae3f;
    border: 1px solid;
    border-radius: 50%;
    font-size: 14px;
    margin-right: 3px;
`;

const YellowNotification = styled.span`
    color: #ffae3f;
    font-weight: 600;
    margin-top: 5px;
    font-size: 15px;
`;

const RepairDetails = () => {

    const [currentLineItemId, setCurrentLineItemId] = useState(null);
    const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
    const [showQuoteAttachmentPopup, setShowQuoteAttachmentPopup] = useState(null);
    const [currentQuoteAttachments, setSurrentQuoteAttachments] = useState(null);
    const [showOrderLinePopup, setShowOrderLinePopup] = useState(false);
    const [showQuoteLinePopup, setShowQuoteLinePopup] = useState(false);
    const [isRequoteQuotePopup, setIsRequoteQuotePopup] = useState(false);
    const [showOrderLineReceivePopup, setShowOrderLineReceivePopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showSecurityError, setShowSecurityError] = useState(false);
    const [selectedRepair, setSelectedRepair] = useState(null);
    const cachedAttachments = useSelector(state => state.attachments.cachedAttachments);
    const [isLoading, setIsLoading] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isDownloadingPO, setIsDownloadingPO] = useState(false);
    const [isDownloadingPackingSlip, setIsDownloadingPackingSlip] = useState(false);
    const [isDownloadingShippingLabel, setIsDownloadingShippingLabel] = useState(false);
    const [showRmaInputPopup, setShowRmaInputPopup] = useState(false);
    const breadcrumbs = useSelector(state => state.orders.breadcrumbs);
    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(-1);
    const {id, poNumber} = useParams();
    const [lastTab, setLastTab] = useState('');

    useEffect(() => {
        loadRepair();
        dispatch(setCachedAttachments([]));
        document.querySelector('html').scrollTop = 0;

    }, [location]);

    async function loadRepair() {

        setIsLoading(true);

        const repairDetailsUrl = poNumber ? `/order/repair/po/${poNumber}` : `/order/repair/${id}`;

        await axios.get(repairDetailsUrl)
            .then(res => {
                setSelectedRepair(res.data);
                const tab = getBreadcrumbText(res.data.lineItems[0].status);
                setLastTab(GridKeys.find(x => x.value.toLowerCase() === res.data?.lineItems[0].status.toLowerCase()).id);
                if (!breadcrumbs || breadcrumbs.length === 0)
                    dispatch(setBreadCrumbs({
                        firstBreadcrumb: 'Repairs', secondBreadcrumb: 'Repair', returnText: tab,
                    }));

                setIsLoading(false);
            })
            .catch(() => {

                if (!breadcrumbs || breadcrumbs.length === 0)
                    dispatch(setBreadCrumbs({
                        firstBreadcrumb: 'Repairs', secondBreadcrumb: 'Repair', returnText: '',
                    }));

                setIsLoading(false);
                setShowSecurityError(true);
            })
    }

    function getBreadcrumbText(status) {
        return GridKeys.find(x => x.value.toLowerCase() === status.toLowerCase()).tabDisplay;
    }

    const showNotePopup = (lineItemId) => {

        document.body.classList.add('modal-popup');
        document.getElementsByTagName('html')[0].className = 'modal-popup';

        setCurrentLineItemId(lineItemId)
        setShowNotes(true);
    }

    const hideNotePopup = () => {
        setShowNotes(false);
        setCurrentLineItemId(null);

        document.body.classList.remove('modal-popup');
        document.getElementsByTagName('html')[0].className = '';
    }

    const onDismissModal = () => {
        document.body.classList.remove('modal-popup');
        document.getElementsByTagName('html')[0].className = '';

        setShowQuoteAttachmentPopup(false)
    }

    const onSubmitNote = (newNote) => {
        const currentRepair = {...selectedRepair};
        let lineItem = currentRepair.lineItems.find(x => x.lineItemId === newNote.lineItemId);
        lineItem.notes.unshift(newNote);
        setSelectedRepair(currentRepair);
        setShowNotes(false);
        dispatch(setCachedRepairs([]));
    }

    const showAttachmentsPopup = (lineItemId) => {

        document.body.classList.add('modal-popup');
        document.getElementsByTagName('html')[0].className = 'modal-popup';

        setCurrentLineItemId(lineItemId);
        setShowAttachmentPopup(true);
    }

    const showQuoteAttachmentsPopup = (quote) => {

        document.body.classList.add('modal-popup');
        document.getElementsByTagName('html')[0].className = 'modal-popup';

        setCurrentLineItemId(quote.lineItemId);
        setShowQuoteAttachmentPopup(true);
        setSurrentQuoteAttachments(quote?.attachments);
    }

    const onConfirm = () => {
        setShowAttachmentPopup(false);
        document.body.classList.remove('modal-popup');
        document.getElementsByTagName('html')[0].className = '';
        window.location.reload();
    }
    const onClose = () => {
        setShowAttachmentPopup(false);
        document.body.classList.remove('modal-popup');
        document.getElementsByTagName('html')[0].className = '';
    }
    const showOrderLineProcessPopup = (lineItemId) => {
        setCurrentLineItemId(lineItemId);
        setShowOrderLinePopup(true);
    }

    const showQuotePopup = (lineItemId, isRequote) => {
        setCurrentLineItemId(lineItemId);
        setShowQuoteLinePopup(true);
        setIsRequoteQuotePopup(isRequote);
    }

    const printPurchaseOrder = async () => {
        if (selectedRepair && selectedRepair.lineItems.length > 0) {
            await printPurchaseOrderPdf(selectedRepair.lineItems[0].lineItemId, selectedRepair.billingPo,
                selectedRepair.lineItems[0].gsaItem, setIsDownloadingPO);
        }
    }

    const printPackingSlip = async (lineItemId) => {
        setCurrentLineItemId(lineItemId);
        const lineItem = selectedRepair.lineItems.find(lineitem => lineitem.lineItemId === lineItemId)
        if (lineItem.statusId === STATUS_ENUM.NOSALE) {
            await printPackingSlipPdf(selectedRepair.orderNumber, true, setIsDownloadingPackingSlip);
        } else {
            await printPackingSlipPdf(selectedRepair.billingPo, false, setIsDownloadingPackingSlip);
        }
        
    }

    const printShippingLabel = async (lineItemId) => {
        setCurrentLineItemId(lineItemId);
        await printShippingLabelPdf(lineItemId, setIsDownloadingShippingLabel);
    }

    const onRepairLineSave = async (repairLine, lineItem) => {

        setIsLoading(true);

        let request = {
            lineItemId: lineItem.lineItemId,
            companyId: selectedRepair.companyId,
            lineItems: [],
            orderType: 'REPAIR',
        };

        setShowOrderLinePopup(false);
        request.lineItems.push({
            lineItemId: repairLine.lineItemId,
            trackingNumber: repairLine.vendorToCustomerTrackingNumber,
            vendorOrderNumber: repairLine.vendorOrderNumber,
            noteType: 'Repair',
            note: repairLine.note,
            estimatedShipDate: repairLine.estimatedShipDate,
            shipDateReasonId: repairLine.shipDateReasonId,
        });

        await axios.post(`/order`, request)
            .then(() => {

                axios.post(`/order/${lineItem.lineItemId}/attachments`, cachedAttachments);

                setIsLoading(false);
                dispatch(setCachedRepairs([]));
                dispatch(setCachedAttachments([]));
                logEvent('Repair Update', {
                    'Order Number': selectedRepair?.orderNumber,
                    'Ref number': lineItem.lineItemId,
                    'Repair Reason': lineItem?.repairReason,
                    'RgaId': lineItem?.rgaId,
                    'RgaNumber': lineItem?.rgaNumber,
                    'Tab': repairLine.status,
                    'Action': 'OK',
                });
                history.goBack(2);
            })
            .catch(() => {
                setIsLoading(false);
                setShowErrorPopup(true);
            })

    }

    const hideErrorPopup = () => {
        setShowErrorPopup(false);
    }

    const showOrderLineReceivePopupHandle = (lineItemId) => {
        setCurrentLineItemId(lineItemId);
        setShowOrderLineReceivePopup(true);
    }

    const showRmaInputDialogPopup = (lineItemId) => {
        setCurrentLineItemId(lineItemId);
        setShowRmaInputPopup(true);
    }

    const onReceiveUpdate = async () => {
        setShowOrderLineReceivePopup(false);
        dispatch(setCachedRepairs([]));
        if (lastTab) {
            history.push(`/repairs?tab=${lastTab}`);
        } else {
            history.push('/repairs');
        }       
    }
    const onAttachmentUpload = (attachment) => {

    }

    const onSubmitQuote = async () => {
        setShowQuoteLinePopup(false);
        dispatch(setCachedRepairs([]));
        history.goBack(2);
    }

    const renderActionButtons = (lineItem, index) => {
        let availableOptions = [];

        switch (lineItem.status) {
            case 'Evaluating':
                availableOptions = ['PACKINGSLIP'];
                break;
            case 'PendingShipment':
            case 'ShippedInbound':
            case 'Quoted':
                availableOptions = ['PURCHASEORDER', 'PACKINGSLIP'];
                break;
            case 'Backordered':
            case 'Repairing':
            case 'ShippedOutbound':
            case 'Delivered':
                availableOptions = ['PURCHASEORDER', 'PACKINGSLIP', 'SHIPPINGLABEL'];
                break;
            default:
                break;
        }
        
        return (
            <React.Fragment>
                {(lineItem.status === 'Rma') &&
                    <OrderButtonContainer className="col-11">
                        <OrderButton onClick={() => showRmaInputDialogPopup(lineItem?.lineItemId)} className="primary">Provide RMA#</OrderButton>
                    </OrderButtonContainer>
                }
                {(lineItem.status === 'Evaluating') &&
                    <OrderButtonContainer className="col-11">
                        <OrderButton onClick={() => showQuotePopup(lineItem?.lineItemId, false)} className="primary">QUOTE</OrderButton>
                    </OrderButtonContainer>
                }
                {lineItem.status === 'Quoted' && !lineItem?.isRepairDeclined &&
                    <OrderButtonContainer className="col-11">
                        <OrderButton onClick={() => showQuotePopup(lineItem?.lineItemId, true)} className="primary">REQUOTE</OrderButton>
                    </OrderButtonContainer>
                }
                {lineItem.status === 'Backordered' &&
                    <React.Fragment>
                        <OrderButtonContainer className="col-11">
                            <OrderButton onClick={() => showOrderLineProcessPopup(lineItem?.lineItemId)} className="primary">UPDATE</OrderButton>
                        </OrderButtonContainer>
                        <OrderButtonContainer className="col-11" style={{float: 'left', paddingLeft: '0'}}>
                            <OrderGrayLink onClick={() => showQuotePopup(lineItem?.lineItemId, true)}>REQUOTE</OrderGrayLink>
                        </OrderButtonContainer>
                    </React.Fragment>
                }
                {lineItem.status === 'Repairing' &&
                    <React.Fragment>
                        <OrderButtonContainer className="col-11">
                            <OrderButton onClick={() => showOrderLineProcessPopup(lineItem?.lineItemId)} className="primary">UPDATE</OrderButton>
                        </OrderButtonContainer>
                        {!lineItem?.isRepairDeclined ? <OrderButtonContainer className="col-11" style={{float: 'left', paddingLeft: '0'}}>
                            <OrderGrayLink onClick={() => showQuotePopup(lineItem?.lineItemId, true)}>REQUOTE</OrderGrayLink>
                        </OrderButtonContainer> : null}
                    </React.Fragment>
                }
                {(lineItem.status === 'PendingShipment' ||
                    lineItem.status === 'ShippedInbound') &&
                    <OrderButtonContainer className="col-11">
                        <OrderButton onClick={() => showOrderLineReceivePopupHandle(lineItem?.lineItemId)} className="primary">RECEIVE</OrderButton>
                    </OrderButtonContainer>
                }
                {(selectedRepair.billingPo || lineItem.statusId === STATUS_ENUM.NOSALE) && lineItem.status !== 'Rma'
                    && availableOptions && availableOptions.includes('PACKINGSLIP') &&
                    <React.Fragment>
                        {(isDownloadingPackingSlip && currentLineItemId === lineItem?.lineItemId) ?
                            <DownloadingDots />
                            : <OrderLinkWrapper style={{marginRight: '55px'}} className="col-11">
                                <OrderLink onClick={() => printPackingSlip(lineItem?.lineItemId)}>PRINT PACKING SLIP</OrderLink>
                            </OrderLinkWrapper>}
                    </React.Fragment>}

                {(selectedRepair.billingPo || lineItem.statusId === STATUS_ENUM.NOSALE) && lineItem.status !== 'Rma'
                    && availableOptions && availableOptions.includes('SHIPPINGLABEL') &&
                    <React.Fragment>
                        {(isDownloadingShippingLabel && currentLineItemId === lineItem?.lineItemId) ?
                            <DownloadingDots />
                            : <OrderLinkWrapper style={{marginRight: '36px'}} className="col-11">
                                <OrderLink isDisabled={!lineItem?.hasShippingLabel}
                                    onClick={lineItem?.hasShippingLabel ? () => printShippingLabel(lineItem?.lineItemId) : () => false}>
                                    PRINT SHIPPING LABEL
                                </OrderLink>
                            </OrderLinkWrapper>}
                    </React.Fragment>}
            </React.Fragment>
        )
    }

    const renderLineItem = (lineItem, index) => {
        let copyLineItem = _.clone(lineItem);
        copyLineItem.vendorOrderNumber = selectedRepair.vendorOrderNumber;
        copyLineItem.vendorToCustomerTrackingNumber = lineItem.customerTrackingNumber ?? lineItem.vendorToCustomerTrackingNumber;

        return (
            <React.Fragment key={index}>
                <OrderGridContent style={{top: index === 0 ? '-8px' : '20px'}} >
                    <ImgContainer>
                        <PartImage isLink={lineItem.imageDefaultUrl} onClick={() => (
                            lineItem.imageDefaultUrl ?
                                window.open(`${process.env.REACT_APP_PARTSSOURCE_URL}${lineItem.imageDefaultUrl}`, '_blank')
                                :
                                null)
                        }>
                            <ProductImage style={{width: '100px'}} url={lineItem.imagePath} altText={lineItem.imageAlt} />
                        </PartImage>
                    </ImgContainer>
                    <OrderDetailsContainer>
                        <Row className="row">
                            <OrderDetailField>
                                {lineItem.imageDefaultUrl ?
                                    <BoldSubtitleNoPadding
                                        onClick={() => window.open(`${process.env.REACT_APP_PARTSSOURCE_URL}${lineItem.imageDefaultUrl}`, '_blank')}>
                                        {lineItem?.description}</BoldSubtitleNoPadding>
                                    :
                                    <BoldSubtitleNoLink>
                                        {lineItem?.description}</BoldSubtitleNoLink>
                                }
                            </OrderDetailField>
                            <OrderDetailField>
                                {renderNotification(lineItem)}
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>OEM: </Subtitle>
                                <BoldSubtitle>{lineItem?.manufacturer}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Item # </Subtitle><BoldSubtitle>{lineItem?.partNumber}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Condition: </Subtitle>
                                <BoldSubtitle>{lineItem?.conditionDescription}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Repair Reason: </Subtitle>
                                <BoldSubtitle>{lineItem?.repairReason}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Ref #: </Subtitle><BoldSubtitle>{lineItem?.lineItemId}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Serial Number: </Subtitle>
                                <BoldSubtitle>{lineItem?.serialNumber}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Icon aria-hidden="true" icon={faCopy} />
                                <BlueText onClick={() => showAttachmentsPopup(lineItem?.lineItemId)}>
                                    Attachments ({lineItem?.attachments ? lineItem?.attachments.length : 0}) </BlueText>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Icon aria-hidden="true" icon={faCalendar} />
                                <BlueText onClick={() => showNotePopup(lineItem?.lineItemId)}>
                                    Notes ({lineItem?.notes ? lineItem?.notes.length : 0}) </BlueText>
                            </OrderDetailField>

                            <OrderDetailFieldMobile className="col-11">
                                <span>
                                    <Subtitle>Qty: </Subtitle>
                                    <BoldSubtitle>{lineItem?.quantity} </BoldSubtitle>
                                </span>
                                <span>
                                    <Subtitle>Unit Price: </Subtitle>
                                    <BoldSubtitle>
                                        {lineItem?.statusId === 12 ? 'Pending Evaluation' : lineItem?.statusId === 4 ?
                                            'Pending Customer Acceptance' : `${formatMoney(lineItem?.unitPrice)}/ea `}
                                    </BoldSubtitle>
                                </span>
                                <span>
                                    <Subtitle>Ext. Price: </Subtitle>
                                    <BoldSubtitle>
                                        {lineItem?.statusId === 12 ? 'Pending Evaluation' : lineItem?.statusId === 4 ?
                                            'Pending Customer Acceptance' : formatMoney(lineItem?.unitPrice * lineItem?.quantity)}
                                    </BoldSubtitle>
                                </span>
                            </OrderDetailFieldMobile>

                            <OrderActionMobileContainer>
                                <OrderActionMobileContent>
                                    {renderActionButtons(lineItem, index)}
                                </OrderActionMobileContent>
                            </OrderActionMobileContainer>

                        </Row>
                    </OrderDetailsContainer>
                    <OrderDetailsQty>
                        <SmallTitle>{lineItem?.quantity}</SmallTitle>
                    </OrderDetailsQty>
                    <OrderDetailsPrice>
                        <SmallTitle>
                            {lineItem?.statusId === 12 ? 'Pending Evaluation' : lineItem?.statusId === 4 ?
                                'Pending Customer Acceptance' : formatMoney(lineItem?.unitPrice)}
                        </SmallTitle>
                    </OrderDetailsPrice>
                    <OrderDetailsPrice>
                        <SmallTitle>
                            {lineItem?.statusId === 12 ? 'Pending Evaluation' : lineItem?.statusId === 4 ?
                                'Pending Customer Acceptance' : formatMoney(lineItem?.unitPrice * lineItem?.quantity)}
                        </SmallTitle>
                    </OrderDetailsPrice>

                    <OrderActionContainer>
                        {renderActionButtons(lineItem, index)}
                    </OrderActionContainer>

                    <Popup
                        onCancel={onClose}
                        show={showAttachmentPopup && currentLineItemId === lineItem?.lineItemId}
                        onConfirm={onConfirm}
                        confirmText="Ok"
                        cancelText="Cancel">
                        <PopupContent>
                            <Attachments attachments={lineItem?.attachments} isReadOnly={false}
                                allowMultipleFiles={true} handleOnSave={(e) => onAttachmentUpload(e)}
                                lineItemId={lineItem?.lineItemId} showDocTypes={true} />
                        </PopupContent>
                    </Popup>

                    <Popup
                        onCancel={onDismissModal}
                        show={showQuoteAttachmentPopup && currentLineItemId === lineItem?.lineItemId}
                        onConfirm={onDismissModal}
                        confirmText="Ok"
                        cancelText="Cancel">
                        <PopupContent>
                            <Attachments attachments={currentQuoteAttachments} isReadOnly={true}
                                lineItemId={currentLineItemId} showDocTypes={true} />
                        </PopupContent>
                    </Popup>

                    {(showOrderLinePopup && currentLineItemId === lineItem?.lineItemId) &&
                        <OrderLine
                            attachmentsLabel="Attachments"
                            attachmentAddButton="Add Attachment"
                            handleOnCancel={() => setShowOrderLinePopup(false)}
                            handleOnSave={(e) => onRepairLineSave(e, lineItem)}
                            order={{id, ...copyLineItem}}
                            type="repair" />
                    }

                    <QuotedRepairModal
                        orderId={selectedRepair?.orderNumber}
                        lineItemId={lineItem?.lineItemId}
                        rgaHeaderId={lineItem?.rgaId}
                        onSubmit={onSubmitQuote}
                        isRequote={isRequoteQuotePopup}
                        statusId={lineItem?.statusId}
                        poNumber={selectedRepair?.displayPo}
                        onCancel={() => setShowQuoteLinePopup(false)}
                        show={showQuoteLinePopup && currentLineItemId === lineItem?.lineItemId} />

                    <ReceivedDetails
                        show={showOrderLineReceivePopup && currentLineItemId === lineItem?.lineItemId}
                        orderId={id}
                        type="repair"
                        orderType={lineItem?.orderType}
                        lineItemId={lineItem?.lineItemId}
                        rgaId={lineItem?.rgaId}
                        trackingNumber={lineItem?.vendorTrackingNumber}
                        onSubmit={(e) => onReceiveUpdate(e)}
                        onCancel={() => setShowOrderLineReceivePopup(false)}
                        rgaNumber={lineItem?.rgaNumber}
                        rgaType="REPAIR"
                        tab={breadcrumbs.returnText}
                        poNumber={selectedRepair.displayPo}
                        isRgaOrder={false} />

                    <RmaInputModal
                        show={showRmaInputPopup && currentLineItemId === lineItem?.lineItemId}
                        orderId={id}
                        lineItemId={lineItem?.lineItemId}
                        rgaId={lineItem?.rgaId}
                        onSubmit={(e) => onReceiveUpdate(e)}
                        onCancel={() => setShowRmaInputPopup(false)}
                        type="repair" />

                </OrderGridContent>

                {(lineItem?.quotes && lineItem.quotes.length > 0) &&
                    <div style={{width: '100%', marginBottom: '10px'}}>
                        <OrderGridHeader style={{paddingLeft: '125px', width: '100%'}}>
                            <OrderDetailsHeader>Quote History</OrderDetailsHeader>
                            <OrderDetailsColumnPrice>
                                <GraySubtitleHeader>Total Price</GraySubtitleHeader>
                            </OrderDetailsColumnPrice>
                        </OrderGridHeader>
                        <UnderlineSubtitleRow style={{width: '100%', marginTop: '0', paddingLeft: '125px'}}>
                            <UnderlineSubtitleBottom>&nbsp;</UnderlineSubtitleBottom>
                        </UnderlineSubtitleRow>
                        {(lineItem.quotes).map((quote, quoteIndex) => renderLineItemQuote(quote, quoteIndex))}
                    </div>
                }

                <FooterContainer style={{marginTop: index === 0 ? '-5px' : '20px'}}>
                    <RepairFooter repair={selectedRepair} lineItem={lineItem} />
                </FooterContainer>
                <UnderlineSubtitleRow>
                    <UnderlineSubtitleBottom>&nbsp;</UnderlineSubtitleBottom>
                </UnderlineSubtitleRow>
                <NotesModal
                    show={showNotes && currentLineItemId === lineItem?.lineItemId}
                    context={Context.REPAIR}
                    notes={lineItem?.notes}
                    lineItemId={currentLineItemId}
                    onSubmit={onSubmitNote}
                    onCancel={() => hideNotePopup()} />

            </React.Fragment>
        )
    }
    const renderNotification = (lineItem) => {
        let notification = '';
        if (lineItem.isPastDue) {
            notification += 'Past Due';
            return (
                <div>
                    <YellowExclamation icon={faExclamation} />
                    <YellowNotification> {notification}</YellowNotification>
                </div>
            )
        } else if (lineItem.isUrgent) {
            notification += notification ? ', Urgent' : 'Urgent';
            return (
                <div>
                    <RedExclamation icon={faExclamation} />
                    <RedNotification> {notification}</RedNotification>
                </div>
            )
        }
    }

    const expandQuote = (index) => {
        setCurrentQuoteIndex(index);
    }

    const collapseQuote = () => {
        setCurrentQuoteIndex(-1);
    }

    const renderLineItemQuote = (quote, quoteIndex) => {
        return (
            <Row className="row" style={{marginBottom: '0'}} key={quoteIndex}>
                {currentQuoteIndex !== quoteIndex ?
                    <React.Fragment>
                        <OrderGridRow style={{paddingLeft: '125px', width: '100%', marginTop: '0', height: '25px'}}>
                            <OrderDetailsHeader style={{fontSize: '14px', paddingLeft: '0'}}>
                                Current Quote: {moment(quote?.createdAt).format('MM/D/YYYY h:mm A')}
                            </OrderDetailsHeader>
                            <OrderDetailsColumnPrice>
                                <GraySubtitle>{formatMoney(quote.amount)}</GraySubtitle>
                            </OrderDetailsColumnPrice>
                            <ViewDetailsColumn>
                                <ViewDetailsText onClick={() => expandQuote(quoteIndex)}>View Details</ViewDetailsText>
                            </ViewDetailsColumn>
                        </OrderGridRow>
                        <UnderlineSubtitleRow style={{width: '100%', marginTop: '0', paddingLeft: '125px'}}>
                            <UnderlineSubtitleBottom style={{borderWidth: '1px'}}>&nbsp;</UnderlineSubtitleBottom>
                        </UnderlineSubtitleRow>
                    </React.Fragment>
                    :
                    <OrderGridRowExpanded style={{paddingLeft: '125px', width: '100%', marginTop: '0'}}>
                        <OrderDetailsColumnPrice style={{float: 'right'}}>
                            <GraySubtitle style={{cursor: 'pointer', textDecorationLine: 'underline'}}
                                onClick={() => collapseQuote()}>Hide Details</GraySubtitle>
                        </OrderDetailsColumnPrice>
                        <div>
                            <QuoteDetailsHeader>
                                <Subtitle>Quote Date: </Subtitle>
                                <BoldSubtitle>{moment(quote?.createdAt).format('MM/D/YYYY h:mm A')}</BoldSubtitle>
                            </QuoteDetailsHeader>
                            <QuoteDetailsHeader>
                                <Subtitle>Quote By: </Subtitle>
                                <BoldSubtitle>{quote?.createdByName}</BoldSubtitle>
                            </QuoteDetailsHeader>
                            <QuoteDetailsHeader>
                                <Subtitle>Diagnosis: </Subtitle>
                                <BoldSubtitle>{quote?.diagnosisNote}</BoldSubtitle>
                            </QuoteDetailsHeader>
                            <QuoteDetailsHeader>
                                <Subtitle>Repair: </Subtitle>
                                <BoldSubtitle>{quote?.repairNote}</BoldSubtitle>
                            </QuoteDetailsHeader>
                            <QuoteDetailsHeader>
                                <Subtitle>Lead Days: </Subtitle>
                                <BoldSubtitle>{quote?.leadDays}</BoldSubtitle>
                            </QuoteDetailsHeader>
                            <QuoteDetailsHeader>
                                <Subtitle>Amount: </Subtitle>
                                <BoldSubtitle>{formatMoney(quote.amount)}</BoldSubtitle>
                            </QuoteDetailsHeader>
                            <QuoteDetailsHeader>
                                <Subtitle>Comment: </Subtitle>
                                <BoldSubtitle>{quote?.note}</BoldSubtitle>
                            </QuoteDetailsHeader>
                            <QuoteDetailsHeader style={{marginBottom: '10px'}}>
                                <Icon aria-hidden="true" icon={faCopy} />
                                <BlueText onClick={() => showQuoteAttachmentsPopup(quote)}>
                                    Attachments ({quote?.attachments ? quote?.attachments.length : 0}) </BlueText>
                            </QuoteDetailsHeader>
                            <UnderlineSubtitleRow style={{width: '100%', marginTop: '0'}}>
                                <UnderlineSubtitleBottom style={{borderWidth: '1px'}}>&nbsp;</UnderlineSubtitleBottom>
                            </UnderlineSubtitleRow>
                        </div>
                    </OrderGridRowExpanded>
                }
            </Row>
        )
    }

    return (
        <Container>
            <PageBreadcrumbs />
            {(!showSecurityError && !isLoading) &&
                <OrderHeaderText>PO #: {selectedRepair?.displayPo ?? 'Pending'}</OrderHeaderText>
            }
            {showSecurityError &&
                <SecurityErrorText>Sorry, you do not have access to this repair.</SecurityErrorText>
            }
            {(!showSecurityError && !isLoading) &&
                <RepairHeader repair={selectedRepair} />
            }
            {(!showSecurityError && isLoading) &&
                <Loader style={{marginLeft: 'auto', marginRight: 'auto'}} />
            }
            {(!showSecurityError && !isLoading) &&
                <div>
                    <OrderGridRow>
                        <OrderDetailsHeader>Repair Details</OrderDetailsHeader>
                        <OrderDetailsColumnQty>
                            <GraySubtitle>Quantity</GraySubtitle>
                        </OrderDetailsColumnQty>
                        <OrderDetailsColumnPrice>
                            <HeaderLabels>Unit Price</HeaderLabels>
                        </OrderDetailsColumnPrice>
                        <OrderDetailsColumnPrice>
                            <HeaderLabels>Ext. Price</HeaderLabels>
                        </OrderDetailsColumnPrice>
                        {selectedRepair && selectedRepair.billingPo && selectedRepair?.lineItems.length > 0
                            && selectedRepair?.lineItems[0].status !== 'Rma' &&
                            <OrderPrintButton>
                                {isDownloadingPO ? <DownloadingDots style={{marginTop: 6}} /> :
                                    <PrintBlueText onClick={printPurchaseOrder}>Print Purchase Order</PrintBlueText>}
                            </OrderPrintButton>
                        }
                    </OrderGridRow>
                    <Row>
                        <UnderlineSubtitle>&nbsp;</UnderlineSubtitle>
                    </Row>

                    {selectedRepair &&
                        (selectedRepair.lineItems).map((lineItem, index) => renderLineItem(lineItem, index))}

                </div>
            }
            <ErrorModal
                show={showErrorPopup}
                popupTitle={'Order Save'}
                popupMessage={getErrorMessage('repair')}
                onCancel={() => hideErrorPopup()} />
        </Container>
    );
};

export default RepairDetails;
