export const GridKeys = [
    {
        cardAction: 'OpenBids',
        fieldName: 'status',
        id: 'OpenBids',
        tabDisplay: 'Open Bids',
        value: 'REQUESTED',
    }, {
        cardAction: 'MyBids',
        fieldName: 'status',
        id: 'MyBids',
        tabDisplay: 'My Bids',
        value: 'QUOTED',
    },
];
