import React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import {Button} from '@partssourceinc/react-ui-core';
import {CardAction} from 'components/CardView/StyledComponents';
import {STATUS_ENUM} from 'utils/enums';
import StandardInfoCard from 'components/StandardInfoCard';
import ShippingInfoCardV2 from 'components/ShippingInfoCardV2';
import WarningInfoCard from 'components/WarningInfoCard';
import PrintingOptions from './PrintingOptions';

const RequoteButton = styled(Button)`
    color: #777777;
`;

const RepairsCardFooter = (props) => {
    const {cardData,
        onPrintOptionChange,
        setSelectedCard,
        showReceivedDialogPopup,
        showQuoteDialogPopup,
        showRequoteDialogPopup,
        showUpdatePopup,
        showEsdConfirmationDialogPopup,
        showRmaInputPopup,
        isPrinting,
    } = props;

    const today = moment().startOf('day');

    const showReceivedDetailsPopup = (card) => {
        setSelectedCard(card);
        showReceivedDialogPopup(true);
    }

    const showQuotePopup = (card) => {
        setSelectedCard(card);
        showQuoteDialogPopup(true);
    }

    const showRequotePopup = (card) => {
        setSelectedCard(card);
        showRequoteDialogPopup(true);
    }

    switch (cardData.repairStatus) {
        case 'PendingShipment':
            return (
                <div>
                    <StandardInfoCard title="Repair Initiated" subText="Awaiting customer shipment" />                   
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting}
                            options={cardData.billingPo ? ['PURCHASEORDER', 'PACKINGSLIP'] : ['']}
                            cardData={cardData} onPrintOptionChange={onPrintOptionChange} />
                        <CardAction>
                            <Button secondary={true} onClick={() => showReceivedDetailsPopup(cardData)}>
                                RECEIVE</Button>
                        </CardAction>
                    </React.Fragment>
                </div>
            );
        case 'ShippedInbound':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} 
                        title="Scheduled Delivery Date" subText={cardData.customerToVendorShippingAddress} 
                        date={cardData.customerToVendorEstimatedDelivery} 
                        trackingNumber={cardData.customerToVendorTrackingNumber} 
                        trackingText={cardData?.customerToVendorShippingMethod ? cardData?.customerToVendorShippingMethod : 
                            cardData?.customerToVendorShipper} noDateText="Pending" noTimeText="End of Day" />
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting}
                            options={cardData.billingPo ? ['PURCHASEORDER', 'PACKINGSLIP'] : ['']}
                            cardData={cardData} onPrintOptionChange={onPrintOptionChange} />
                        <CardAction>
                            <Button secondary={true} onClick={() => showReceivedDetailsPopup(cardData)}>
                                RECEIVE</Button>
                        </CardAction>
                    </React.Fragment>
                </div>
            );
        case 'Evaluating':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} 
                        title="Estimated Ship Date" subText={cardData.vendorToCustomerShippingAddress} 
                        date={cardData.estimatedShipDate} 
                        trackingNumber={cardData.vendorToCustomerTrackingNumber} 
                        trackingText={cardData?.vendorToCustomerShippingMethod ? cardData?.vendorToCustomerShippingMethod : 
                            cardData?.vendorToCustomerShipper} noDateText="Pending" noTimeText="End of Day" />
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting}
                            options={cardData.billingPo ? ['PACKINGSLIP'] : ['']} cardData={cardData}
                            onPrintOptionChange={onPrintOptionChange} />
                        <CardAction>
                            <Button secondary={true} onClick={() => showQuotePopup(cardData)}>
                                QUOTE</Button>
                        </CardAction>
                    </React.Fragment>
                </div>
            );
        case 'Quoted':
            return (
                <div>
                    {cardData.isRepairDeclined ? 
                        <WarningInfoCard title="Repair Quote Declined" 
                            subText="Repair Quote declined by Customer. Please return the item to the customer."
                        /> : null}
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} 
                        title="Estimated Ship Date" subText={cardData.vendorToCustomerShippingAddress} 
                        date={cardData.estimatedShipDate} 
                        trackingNumber={cardData.vendorToCustomerTrackingNumber} 
                        trackingText={cardData?.vendorToCustomerShippingMethod ? cardData?.vendorToCustomerShippingMethod : 
                            cardData?.vendorToCustomerShipper} noDateText="Pending" noTimeText="End of Day" />
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting}
                            options={cardData.billingPo ? ['PURCHASEORDER', 'PACKINGSLIP'] : ['']}
                            cardData={cardData} onPrintOptionChange={onPrintOptionChange} />
                        <CardAction>
                            {!cardData.isRepairDeclined ? <Button secondary={true} onClick={() => showRequotePopup(cardData)}>
                                REQUOTE</Button> : null}
                        </CardAction>
                    </React.Fragment>
                </div>
            );
        case 'Backordered':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true}
                        title="Estimated Ship Date" subText={cardData.vendorToCustomerShippingAddress}
                        additionalText={cardData.vendorToCustomerShippingAttention}
                        date={cardData.estimatedShipDate}
                        trackingNumber={cardData.vendorToCustomerTrackingNumber}
                        trackingText={cardData?.vendorToCustomerShippingMethod ? cardData?.vendorToCustomerShippingMethod :
                            cardData?.vendorToCustomerShipper} noDateText="Pending" noTimeText="End of Day" />
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting}
                            options={cardData.billingPo ? ['PURCHASEORDER', 'PACKINGSLIP', 'SHIPPINGLABEL'] : ['']}
                            cardData={cardData} onPrintOptionChange={onPrintOptionChange} />
                        {((cardData.estimatedShipDate && cardData.estimatedShipDate <= today) ||
                            (cardData.followUpDate && cardData.followUpDate <= today)) ?
                            <CardAction>
                                <Button secondary={true} onClick={() => showEsdConfirmationDialogPopup(cardData)}>
                                    REVIEW SHIPPING</Button>
                            </CardAction> :
                            <CardAction>
                                <RequoteButton ternary={true} onClick={() => showRequotePopup(cardData)}>REQUOTE</RequoteButton>
                                <Button secondary={true} onClick={() => showUpdatePopup(cardData)}>
                                    UPDATE</Button>
                            </CardAction>
                        }
                    </React.Fragment>
                </div>
            );
        case 'Repairing':
            return (
                <div>
                    {cardData.isRepairDeclined ? 
                        <WarningInfoCard title="Repair Quote Declined" 
                            subText="Repair Quote declined by Customer. Please return the item to the customer."
                        /> : null}
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} 
                        title="Estimated Ship Date" subText={cardData.vendorToCustomerShippingAddress} 
                        additionalText={cardData.vendorToCustomerShippingAttention} 
                        date={cardData.estimatedShipDate} 
                        trackingNumber={cardData.vendorToCustomerTrackingNumber} 
                        trackingText={cardData?.vendorToCustomerShippingMethod ? cardData?.vendorToCustomerShippingMethod : 
                            cardData?.vendorToCustomerShipper} noDateText="Pending" noTimeText="End of Day" />
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting}
                            options={cardData.billingPo ? ['PURCHASEORDER', 'PACKINGSLIP', 'SHIPPINGLABEL'] : 
                                cardData.statusId === STATUS_ENUM.NOSALE ? ['PACKINGSLIP', 'SHIPPINGLABEL'] : ['']}
                            cardData={cardData} onPrintOptionChange={onPrintOptionChange} />
                        {((cardData.estimatedShipDate && cardData.estimatedShipDate <= today) ||
                            (cardData.followUpDate && cardData.followUpDate <= today)) ?
                            <CardAction>
                                <Button secondary={true} onClick={() => showEsdConfirmationDialogPopup(cardData)}>
                                    REVIEW SHIPPING</Button>
                            </CardAction> :
                            <CardAction>
                                {!cardData.isRepairDeclined ?
                                    <RequoteButton ternary={true} onClick={() => showRequotePopup(cardData)}>REQUOTE</RequoteButton> : null}
                                <Button secondary={true} onClick={() => showUpdatePopup(cardData)}>
                                    UPDATE</Button>
                            </CardAction>
                        }
                    </React.Fragment>
                </div>
            );
        case 'ShippedOutbound':
            return (
                <div>
                    {cardData.isRepairDeclined ? 
                        <WarningInfoCard title="Repair Quote Declined" 
                            subText="Repair Quote declined by Customer. Unrepaied item has been shipped back to the customer."
                        /> : null}
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true}
                        title="Shipped" subText={cardData.vendorToCustomerShippingAddress} 
                        additionalText={cardData.vendorToCustomerShippingAttention} 
                        date={cardData.vendorToCustomerDateShipped} 
                        trackingNumber={cardData.vendorToCustomerTrackingNumber} 
                        trackingText={cardData?.vendorToCustomerShippingMethod ? cardData?.vendorToCustomerShippingMethod : 
                        cardData?.vendorToCustomerShipper} noDateText="Pending" />
                    <PrintingOptions options={cardData.billingPo ? ['PURCHASEORDER', 'PACKINGSLIP', 'SHIPPINGLABEL'] : 
                        cardData.statusId === STATUS_ENUM.NOSALE ? ['PACKINGSLIP', 'SHIPPINGLABEL'] : ['']}
                    isPrinting={isPrinting} cardData={cardData} onPrintOptionChange={onPrintOptionChange} />
                </div>
            );
        case 'Delivered':
            return (
                <div>
                    {cardData.isRepairDeclined ? 
                        <WarningInfoCard title="Repair Quote Declined" 
                            subText="Repair Quote declined by Customer. Unrepaied item has been delivered to the customer."
                        /> : null}
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true} isComplete={true}
                        title="Delivered" subText={cardData.vendorToCustomerShippingAddress} 
                        additionalText={cardData.vendorToCustomerShippingAttention} 
                        date={cardData.vendorToCustomerDateDelivered} 
                        trackingNumber={cardData.vendorToCustomerTrackingNumber} 
                        trackingText={cardData?.vendorToCustomerShippingMethod ? cardData?.vendorToCustomerShippingMethod : 
                        cardData?.vendorToCustomerShipper} noDateText="On Unknown Date" />
                    <PrintingOptions isPrinting={isPrinting} cardData={cardData} onPrintOptionChange={onPrintOptionChange}
                        options={cardData.billingPo ? ['PURCHASEORDER', 'PACKINGSLIP', 'SHIPPINGLABEL'] : 
                            cardData.statusId === STATUS_ENUM.NOSALE ? ['PACKINGSLIP', 'SHIPPINGLABEL'] : ['']} />
                </div>
            );
        case 'Rma':
            return (
                <div>
                    <StandardInfoCard title="Pending" subText="Awaiting Supplier RMA #" />
                    <React.Fragment>
                        <CardAction>
                            <Button secondary={true} onClick={() => showRmaInputPopup(cardData)}>
                                PROVIDE RMA #</Button>
                        </CardAction>
                    </React.Fragment>
                </div>
            );
        default:
            return null;
    }
}

RepairsCardFooter.propTypes = {
    cardData: PropTypes.shape({
        addressInfo: PropTypes.string,
        vendorToCustomerDateShipped: PropTypes.instanceOf(Date),
        vendorToCustomerTrackingNumber: PropTypes.string,
        dateCreated: PropTypes.string,
        estimatedDelivery: PropTypes.instanceOf(Date),
        dueDate: PropTypes.string,
        estimatedShipDate: PropTypes.string,
        customerToVendorEstimatedDelivery: PropTypes.string,
        vendorToCustomerDateDelivered: PropTypes.instanceOf(Date),
        followUpDate: PropTypes.string,
        repairStatus: PropTypes.string,
        rgaId: PropTypes.number,
        rgaNumber: PropTypes.string,
        shipper: PropTypes.string,
        vendorToCustomerShippingAddress: PropTypes.string,
        customerToVendorShippingAddress: PropTypes.string,
        vendorToCustomerShippingAttention: PropTypes.string,
        customerToVendorShippingAttention: PropTypes.string,
        status: PropTypes.string,
        statusId: PropTypes.number,
        trackingNumber: PropTypes.string,
        customerToVendorDateShipped: PropTypes.string,
        vendorOrderNumber: PropTypes.string,
        customerToVendorTrackingNumber: PropTypes.string,
        billingPo: PropTypes.string,
        vendorToCustomerShippingMethod: PropTypes.string,
        vendorToCustomerShipper: PropTypes.string,
        customerToVendorShippingMethod: PropTypes.string,
        customerToVendorShipper: PropTypes.string,
        isRepairDeclined: PropTypes.bool,
    }),
    isPrinting: PropTypes.bool,
    onPrintOptionChange: PropTypes.func.isRequired,
    setSelectedCard: PropTypes.func.isRequired,
    showReceivedDialogPopup: PropTypes.func.isRequired,
    showUpdatePopup: PropTypes.func.isRequired,
    showRmaInputPopup: PropTypes.func.isRequired,
    showEsdConfirmationDialogPopup: PropTypes.func.isRequired,
    showQuoteDialogPopup: PropTypes.func.isRequired,
    showRequoteDialogPopup: PropTypes.func.isRequired,
}

export default RepairsCardFooter;
