import React from 'react';
import {useSelector} from 'react-redux';
import * as PropTypes from 'prop-types';
import {faAngleLeft, faAngleRight} from '@fortawesome/pro-regular-svg-icons';
import {
    OrderArrow, BreadCrumbHeader, BreadCrumbSecondRow, SecondArrow, BreadCrumbLink, BlueHeader,
} from 'components/DetailsStyledComponents';

const PageBreadcrumbs = () => {
    const breadcrumbs = useSelector(state => state.orders.breadcrumbs);
            
    return (
        <>
            {(breadcrumbs && breadcrumbs.firstBreadcrumb) &&
            <React.Fragment>
                <div>                    
                    <React.Fragment>
                        <BlueHeader>{breadcrumbs.firstBreadcrumb}</BlueHeader>
                        <OrderArrow icon={faAngleRight} />
                        <BreadCrumbHeader>{breadcrumbs.secondBreadcrumb}</BreadCrumbHeader>
                    </React.Fragment>                                                         
                </div>                
                {(breadcrumbs.returnText) ?                           
                    <BreadCrumbSecondRow>
                        <SecondArrow icon={faAngleLeft} />
                        <React.Fragment>
                            {/* Orders */}
                            {(breadcrumbs?.firstBreadcrumb?.toUpperCase() === 'ORDERS' &&
                            (breadcrumbs?.returnText?.toUpperCase() === 'SHIPPED' ||
                                breadcrumbs?.returnText?.toUpperCase() === 'DELIVERED' ||
                                breadcrumbs?.returnText?.toUpperCase() === 'AWAITING REVIEW' ||
                                breadcrumbs?.returnText?.toUpperCase() === 'PENDING SHIPMENT')) &&
                            <BreadCrumbLink
                                to={`/orders?tab=${breadcrumbs.returnText?.replace(' ', '')}`}
                                exact={true}
                                activeClassName="active"
                            >
                                {breadcrumbs.returnText}
                            </BreadCrumbLink>
                            }
                            {/* All Orders */}
                            {breadcrumbs.returnText.toUpperCase() === 'ALL ORDERS' &&
                            <BreadCrumbLink
                                to={'/orders/history'}
                                exact={true}
                                activeClassName="active"
                            >
                                {breadcrumbs.returnText}
                            </BreadCrumbLink>
                            }
                            {/* Exchanges and Returns */}
                            {(breadcrumbs.firstBreadcrumb.toUpperCase() === 'EXCHANGES & RETURNS') &&
                            <BreadCrumbLink
                                to={`/exchanges-returns?tab=${breadcrumbs.returnText?.replace(' ', '')}`}
                                exact={true}
                                activeClassName="active"
                            >
                                {breadcrumbs.returnText}
                            </BreadCrumbLink>
                            }
                            {/* Repairs */}
                            {(breadcrumbs.firstBreadcrumb.toUpperCase() === 'REPAIRS') &&
                            <BreadCrumbLink
                                to={`/repairs?tab=${breadcrumbs.returnText?.replace(' ', '')}`}
                                exact={true}
                                activeClassName="active"
                            >
                                {breadcrumbs.returnText}
                            </BreadCrumbLink>
                            }
                            {breadcrumbs.firstBreadcrumb === 'Loaners & Rentals' &&
                                <BreadCrumbLink
                                    to={`/loaners-rentals?tab=${breadcrumbs.returnText?.replace(' & ', '').replace(' ', '')}`}
                                    exact={true}
                                    activeClassName="active"
                                >
                                    {breadcrumbs.returnText}
                                </BreadCrumbLink>
                            }
                        </React.Fragment>
                    </BreadCrumbSecondRow>
                    :
                    <BreadCrumbSecondRow>
                        <SecondArrow icon={faAngleLeft} />
                        <BreadCrumbLink
                            to={`/${breadcrumbs.firstBreadcrumb.replace(' ', '').replace('&', '-').toLowerCase()}`}
                            exact={true}
                            activeClassName="active"
                        >
                            Back to {breadcrumbs.firstBreadcrumb}
                        </BreadCrumbLink>
                    </BreadCrumbSecondRow>
                }            
            </React.Fragment>
            }
        </>
    )
}

PageBreadcrumbs.propTypes = {
    firstBreadcrumb: PropTypes.string,
    secondBreadcrumb: PropTypes.string,    
    returnText: PropTypes.string,
};

export default PageBreadcrumbs;
