import React, {useState} from 'react';
import {TextField, FileUploader, semiBoldBrandFont, boldBrandFont, brandFont} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import {processFiles} from 'utils/FileHandler';
import useForm from 'hooks/useForm';
import {useSelector, useDispatch} from 'react-redux';
import {saveCertifications} from 'reducers/supplier';
import FormFooter from 'components/FormFooter';
import {getCertificationLogo} from 'utils/FileHandler';
import {SubTitle, BackLink, LeftArrow} from 'utils/styles';
import {faAngleLeft} from '@fortawesome/pro-regular-svg-icons';
import DatePicker from 'components/DatePicker';

const Wrapper = styled.div`
`;

const CertificationGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`;

const CertificationItem = styled.div`
    width: 360px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    margin-right: 30px;
    margin-top: 30px;
    padding: 20px;

    @media (max-width: 549px) {
        margin-right: 0px;
    }

    img {
        height: 30px;
    }

    p {
        margin-top: 15px;
    }

    span {
        margin-right: 15px;
    }
`;

const ItemAdding = styled.div`
    margin-bottom: 50px;
    h3 {
        margin-top: 35px;
    }
`;

const ErrorMessage = styled.span`
    height: 20px;
    display: block;
`;

const Info = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${semiBoldBrandFont('16px')};
`;

const Expiration = styled.p`
    ${brandFont('16px')}
    span:nth-child(2){
        font-weight: bold;
        margin-left: 10px;
    }
`;

const NoCertifications = styled.p`
    ${boldBrandFont('16px')}
`;

const TextBox = styled(TextField)`
    max-width: 20em;
    margin-top: 20px;
`;

const DocUploader = styled.div`
    margin-top: 20px;
    margin-bottom: 40px;
`;

const validationSchema = {
    name: {
        required: true,
    },
    obtainDate: {
        required: true,
    },
    expireDate: {
        required: true,
    },
}

const MultilineTextBox = styled(TextField)`
    margin-bottom: 20px;

    div {
        min-height: 110px;
        max-width: 40em;
    }
`;

const Certifications = () => {
    const dispatch = useDispatch();
    const isSaving = useSelector(state => state.supplier.isSaving);
    const certifications = useSelector(state => state.supplier.certifications);
    const addCertifications = useSelector(state => state.supplier.addCertifications);
    const [uploadError, setUploadError] = useState('');
    const [uploadHasErrors, setUploadHasErrors] = useState(false);

    const handleFormSubmit = async (values) => {
        await dispatch(saveCertifications(values));
    }

    const {
        values,
        handleOnChange,
        handleOnSubmit,
        errors,
        dirty,
        setValue,
    } = useForm(addCertifications, validationSchema, handleFormSubmit);

    const certificateIsInvalid = !(values.name && values.obtainDate && values.expireDate);

    const renderCertification = (cert) => {
        const date = new Date(cert.expireDate);
        const certLogo = getCertificationLogo(cert.certificationId);

        return (
            <CertificationItem key={cert.name}>
                <Info>
                    <span>{cert.name}</span>
                    {certLogo && <img src={`/images/certifications/${certLogo}`} />}
                </Info>
                <Expiration>
                    <span>
                        Certification Expiration Date:
                    </span>
                    <span>
                        {formatDate(date)}
                    </span>
                </Expiration>
            </CertificationItem>
        )
    }

    const handleUpload = e => {
        e.preventDefault();
        const files = e.target.files;
        const display = e.target
            .value
            .split('/')
            .pop()
            .split('\\')
            .pop();
        const type = files[0].type;

        processFiles(files, display).then(res => {
            if (res.uploadFile?.name !== '') {
                setValue('document', res.uploadFile.file);
                setValue('fileType', type);
                setValue('fileName', display.substring(0, display.lastIndexOf('.')));
                setUploadError('');
                setUploadHasErrors(false);
            } else {
                setUploadError(res.errorMessage);
                setUploadHasErrors(res.showErrorMessage);
            }
        });
    }

    const onChange = (e, name) => {
        const {target: {value}} = e;
        e.target.name = name;

        if (name === 'obtainDate' || name === 'expireDate') {
            const data = {
                value: value ? formatDate(value).toString() : null,
            }

            handleOnChange(e, data)
        } else
            handleOnChange(e);
    }

    const formatDate = (date) => `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(-2)}`;

    const handleRemove = () => {
        setValue('document', null);
        setValue('fileType', null);
        setValue('fileName', null);
        setUploadError('');
        setUploadHasErrors(false);
    }

    return (
        <Wrapper>
            <h1>Certifications</h1>
            <span>
                <LeftArrow icon={faAngleLeft} />
                <BackLink to="/settings">Back to Company Profile</BackLink>
            </span>
            <SubTitle limitWidth={true}>
                <div>Product certification status is displayed to end customers when purchasing products and can influence a customer purchase
                    decision. Accurate certification information from supplier partners ensures optimal placement in the PartsSource catalog.</div>
            </SubTitle>
            <SubTitle limitWidth={true}>
                <div>Accepted Certifications:</div>
                <ul>
                    <li>SBA: Minority Owned, Women Owned, Veteran Owned (VOB), Disabled Veteran Owned (SDVOB), LGBT Owned,
                        Historically Underutilized Business</li>
                    <li>Green Certification: LEED Certification, US Green Building Council Certification, Zero Energy Building Certification</li>
                    <li>ISO Certification: ISO 9001 - Quality Management, ISO 13485 - Medical Devices, ISO 17025 - Testing and Calibration</li>
                    <li>FDA Certification</li>
                </ul>
            </SubTitle>
            <CertificationGrid>
                {(certifications || []).map(c => renderCertification(c))}
                {(!certifications || certifications.length === 0) && <NoCertifications>No Certifications</NoCertifications>}
            </CertificationGrid>
            <ItemAdding>
                <h3>Add Certification</h3>
                <form onSubmit={handleFormSubmit}>
                    <TextBox id="name"
                        name="name"
                        type="text"
                        placeholder="Certification Name"
                        text={values.name}
                        onChange={(e) => onChange(e, 'name')}
                        tabIndex={1}
                        limit={100}
                        label="Certification Name"
                        showErrorMessage={dirty.name && errors.name} />
                    <DatePicker
                        appendTo={document.body}
                        name="obtainDate"
                        placeholder="Certification Obtain Date"
                        error={dirty.obtainDate && errors.obtainDate}
                        onChange={(e) => onChange(e, 'obtainDate')}
                        value={values.obtainDate}
                        showIcon={true}
                        style={{width: '20.30em', height: '50px', marginTop: '20px'}}
                    />
                    <br />
                    <DatePicker
                        appendTo={document.body}
                        name="expireDate"
                        placeholder="Certification Expiration Date"
                        error={dirty.expireDate && errors.expireDate}
                        onChange={(e) => onChange(e, 'expireDate')}
                        value={values.expireDate}
                        showIcon={true}
                        style={{width: '20.30em', height: '50px', marginTop: '20px'}}
                    />
                    <DocUploader>
                        <FileUploader
                            name="FileUploader"
                            label="Upload Certification Document"
                            maxMb="2"
                            restriction="Max file size 2MB"
                            infoCenter={false}
                            photosTab={false}
                            isFileSelected={values.fileName ? true : false}
                            fileDisplay={values.fileName}
                            imagePreviewUrl={values.document}
                            handleRemove={handleRemove}
                            onChange={handleUpload}
                            acceptFiles="*"
                            tabIndex={4} />
                    </DocUploader>
                    {uploadHasErrors && <ErrorMessage>{uploadError}</ErrorMessage>}
                    <h3>Edits or Requests</h3>
                    <SubTitle limitWidth={true}>Please provide any additional information that needs to be denoted in your profile</SubTitle>
                    <MultilineTextBox
                        value={values.requests}
                        name="requests"
                        onChange={onChange}
                        multiLine={true}
                        label={`Edits or Requests`}
                        placeholder={`Edit and/or requests to settings information`}
                    />
                    <FormFooter
                        dirty={dirty}
                        handleOnSubmit={handleOnSubmit}
                        loading={isSaving}
                        disabled={certificateIsInvalid}
                    />
                </form>
            </ItemAdding>
        </Wrapper >
    )
}

export default Certifications;
