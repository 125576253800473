import {useState, useEffect, useCallback, useRef} from 'react';
import _ from 'lodash';

const VALUE = 'value';
const ERROR = 'error';
const DIRTY = 'dirty';

export const ErrorType = {
    None: '',
    Invalid: 'Invalid',
    Required: 'Required',
};

// Determines a value if it's an object
const isObject = (value) => {
    return typeof value === 'object' && value !== null;
}

const isRequired = (value, required) => {
    if ((value === '' || value === null || value === -1 || value === undefined) && required)
        return ErrorType.Required;

    return '';
}

const getPropValues = (stateSchema, prop) => {
    return Object.keys(stateSchema).reduce((accumulator, curr) => {
        switch (prop) {
            case VALUE:
                accumulator[curr] = stateSchema[curr];
                break;
            case ERROR:
                accumulator[curr] = '';
                break;
            case DIRTY:
                accumulator[curr] = false;
                break;
            default:
                accumulator[curr] = false;
        }
        return accumulator;
    }, {});
}

// Custom hooks to validate the form...
function useForm(
    state = {},
    stateValidatorSchema = {}, // model for the validation
    submitFormCallback // function to be executed during form submission.
) {
    const initialState = useRef(null);
    const [values, setValues] = useState(getPropValues(state, VALUE));
    const [errors, setErrors] = useState(getPropValues(state, ERROR));
    const [dirty, setDirty] = useState(getPropValues(state));
    const [disable, setDisable] = useState(true);

    // Get a local copy of stateSchema
    useEffect(() => {
        if (_.isEqual(initialState.current, state)) return;

        initialState.current = state;
        setValues(getPropValues(state, VALUE));
        initialValidation(state);
    }, [state]);

    // For every changed in our state this will be fired
    // To be able to disable the button
    useEffect(() => {
        setDisable(validateErrorState());
    }, [errors, dirty]);

    // Validate fields in forms
    const validateFormFields = useCallback(
        (name, value) => {
            const validator = stateValidatorSchema;

            // Making sure that stateValidatorSchema name is same in
            // stateSchema
            if (!validator[name]) return;

            const field = validator[name];
            let error = ErrorType.None;
            error = isRequired(value, field.required);

            if (isObject(field.validator) && error === '' && value) {
                const fieldValidator = field.validator;

                // Test the function callback if the value meets the criteria
                const testFunc = fieldValidator.func;

                if (!testFunc(value, values)) {
                    error = ErrorType.Invalid;
                }
            }
            return error;
        },
        [stateValidatorSchema, values]
    );

    // Used to disable submit button if there's a value in errors
    // or the required field in state has no value.
    // Wrapped in useCallback to cached the function to avoid intensive memory leaked
    // in every re-render in component
    const validateErrorState = useCallback(
        () => Object.values(errors).some(error => error),
        [errors]
    );

    const initialValidation = (initialValues) => {
        const validator = stateValidatorSchema;
        Object.keys(validator).filter(v => validator[v].required).map(v => {
            const error = validateFormFields(v, initialValues[v]);
            setErrors(prevState => ({...prevState, [v]: error}));
        })
    }

    // Event handler for handling changes in input.
    const handleOnChange = useCallback(
        (event, data) => {
            const name = event.target?.name ?? event.name;
            const value = (data?.value && data?.value.trim()) ?? event.checked ?? event.target?.value?.trim?.() ?? event.target?.value;
            const error = validateFormFields(name, value);
            setValues(prevState => ({...prevState, [name]: value}));
            setErrors(prevState => ({...prevState, [name]: error}));
            setDirty(prevState => ({...prevState, [name]: true}));
        },
        [validateFormFields]
    );

    const setValue = useCallback((name, value) => {
        handleOnChange({name: name}, {value: value});
    });

    const handleOnSubmit = useCallback(
        async (event) => {
            event.preventDefault();
            const result = await submitFormCallback(values, dirty);

            if (result === false) {
                return false;
            }
        },
        [validateErrorState, submitFormCallback, values, dirty]
    );

    return {
        handleOnChange,
        handleOnSubmit,
        values,
        errors,
        disable,
        setValue,
        setValues,
        setErrors,
        dirty,
    };
}

export default useForm;
