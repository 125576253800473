import React, {useContext} from 'react';
import {NavLink} from 'react-router-dom';
import {isURL} from 'utility';
import {BrManageMenuButton, BrPageContext, BrComponentContext} from '@bloomreach/react-sdk';
import _ from 'lodash';

const BottomMenu = () => {
    const page = useContext(BrPageContext);
    const component = useContext(BrComponentContext);
    const menuRef = component.getModels().menu || null;
    const menu = menuRef ? page.getContent(menuRef) : null;
    const [[leftMenuItem], menuItems] = _.partition(menu.items, 'model.parameters.left');

    const renderItem = (item, index) => {
        const params = item.getParameters();
        const linkUrl = item.getLink() && item.getLink().href || '#';
        const linkText = item.getName() || '';
        const {newTab} = params;

        return (<span key={`bm_${index}`}>
            {newTab || isURL(linkUrl) ? <a
                href={linkUrl}
                target={'_blank'}
                rel="noreferrer noopener">{linkText}</a>
                : <NavLink to={linkUrl}>{linkText}</NavLink>}
        </span>);
    }

    return menu ? <div id="global_footer" className="global-footer-bottom full-row">
        {page.isPreview() && <BrManageMenuButton menu={menu} />}
        {leftMenuItem && <span dangerouslySetInnerHTML={{__html: leftMenuItem.getName()}} />}
        <span className="links">
            {(menuItems || []).map((i, index) => renderItem(i, index))}
        </span>
    </div> : null;
}

export default BottomMenu;
