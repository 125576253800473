import * as React from 'react';
import styled from 'styled-components';
import {GlobalStyle} from '@partssourceinc/react-ui-core';
import {Redirect, Switch, Route, useLocation} from 'react-router';
import {SupplierProStyles} from 'utils/styles';
import renderComponent from 'utils/renderComponent';
import AdminHeader from 'components/AdminHeader';
import AdminNavBar from 'components/AdminNavBar';
import CompanySettings from 'pages/CompanySettings';
import SiteManagement from 'pages/SiteManagement';
import ReportingPortalManagement from 'pages/ReportingPortal/ReportingPortalManagement';
import RPPortalsManagement from 'pages/ReportingPortal/RPPortalsManagement';
import RPPortalsAdd from 'pages/ReportingPortal/RPPortalsAdd';
import RPWidgetsManagement from 'pages/ReportingPortal/RPWidgetsManagement';
import RPWidgetsAdd from 'pages/ReportingPortal/RPWidgetsAdd';
import CompanyManagement from 'pages/CompanyManagement';
import * as PropTypes from 'prop-types';

const Container = styled.div`
    display: flex;
    height: calc(100vh - 55px);
`;

const Page = styled.div`
    flex: 1;
    padding: 0 40px;
    ${({shouldScroll}) => shouldScroll && `
        overflow: auto;
    `}
`;

const AdminLayout = ({updateTriggerLogout}) => {
    const location = useLocation();
    
    const shouldScroll = [
        '/admin/reporting-portal/portals',
        '/admin/reporting-portal/widgets'
    ].includes(location.pathname);

    if (location.pathname === '/admin') {
        return <Redirect to="/admin/company" />
    }

    return (
        <React.Fragment>
            <GlobalStyle />
            <SupplierProStyles />
            <AdminHeader />
            <Container>
                <AdminNavBar updateTriggerLogout={updateTriggerLogout} />
                <Page shouldScroll={shouldScroll}>
                    <Switch>
                        <Route
                            path="/admin/company"
                            exact={true}
                            render={() => renderComponent(<CompanyManagement />, 'Company Management')}
                        />
                        <Route
                            path="/admin/company/settings"
                            render={() => renderComponent(<CompanySettings />, 'Company Settings')}
                        />
                        <Route
                            path="/admin/site"
                            render={() => renderComponent(<SiteManagement />, 'Site Management')}
                        />
                        <Route
                            path="/admin/reporting-portal"
                            exact={true}
                            render={() => renderComponent(<ReportingPortalManagement />, 'Reporting Portal Management')}
                        />
                        <Route
                            path="/admin/reporting-portal/widgets"
                            exact={true}
                            render={() => renderComponent(<RPWidgetsManagement />, 'Widgets Management')}
                        />
                        <Route
                            path="/admin/reporting-portal/widgets/add"
                            exact={true}
                            render={() => renderComponent(<RPWidgetsAdd />, 'Add Reporting Widget')}
                        />
                        <Route
                            path="/admin/reporting-portal/widgets/:id"
                            exact={true}
                            render={() => renderComponent(<RPWidgetsAdd />, 'Edit Reporting Widget')}
                        />
                        <Route
                            path="/admin/reporting-portal/portals"
                            exact={true}
                            render={() => renderComponent(<RPPortalsManagement />, 'Portal Management')}
                        />
                        <Route
                            path="/admin/reporting-portal/portals/add"
                            exact={true}
                            render={() => renderComponent(<RPPortalsAdd />, 'Add Reporting Portal')}
                        />
                        <Route
                            path="/admin/reporting-portal/portals/:id"
                            exact={true}
                            render={() => renderComponent(<RPPortalsAdd />, 'Edit Reporting Portal')}
                        />
                    </Switch>
                </Page>
            </Container>
        </React.Fragment>
    )
};

AdminLayout.propTypes = {
    updateTriggerLogout: PropTypes.func,
};

export default AdminLayout;
