import React, {useState, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {TextField, Button, Popup, Checkbox} from '@partssourceinc/react-ui-core';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getPortals, savePortal, editPortal, getWidgets} from './../../reducers/user';
import {useHistory, useParams} from 'react-router-dom';
import RPAssignCompanyDialog from 'components/RPAssignCompanyDialog';
import Select from 'react-select';
import styled from 'styled-components';
import ReorderDialog from 'components/GenericReorderDialog';
import 'components/css/adminLists.css';
import 'components/css/advancedLists.css';
import 'components/css/reportingportal.css';
import {faTimesCircle, faSort} from '@fortawesome/free-solid-svg-icons';

const MultiSelect = styled(Select)`
    .select__control {
        min-height: 50px;
        border: 1px solid #949494;
        border-radius: 3px;
   }
`;

const Icon = styled(FontAwesomeIcon)`
    float: right;
    color: black;
    margin-right: 5px;
    margin-left: 5px;
`;

export default function RPPortalAdd() {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [showErrors, setShowErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isEditMode] = useState(id != null && id !== undefined);
    const [showNoCompanyConfirmation, setShowNoCompanyConfirmation] = useState(false);
    const [showWidgetsRequiredError, setShowWidgetsRequiredError] = useState(false);
    const [showAccessPillDialog, setShowAccessPillDialog] = useState(false);
    const [portal, setPortal] = useState({});
    const [selectedWidgets, setSelectedWidgets] = useState([]);
    const [noCompanyError, setNoCompanyError] = useState(false);
    const [duplicateCompanyError, setDuplicateCompanyError] = useState(false);
    const {portals: allPortals, widgets: widgetOptions} = useSelector(state => state.user);
    const [duplicatePortal, setDuplicatePortal] = useState({});
    const [showReorderDialog, setShowReorderDialog] = useState(false);
    const [isDefault, setIsDefault] = useState(false);
    const [showDefaultConfirmation, setShowDefaultConfirmation] = useState(false);

    useEffect(() => {
        dispatch(getWidgets());
        dispatch(getPortals());
        getPortalDetails();
    }, []);

    async function getPortalDetails() {
        if (isEditMode) {
            const {data} = await axios.get(`reporting-portal/portals/${id}`);
            const {name, description, widgets, isDefault} = data;
            setName(name);
            setDescription(description);
            if (widgets) setSelectedWidgets(widgets);
            setIsDefault(isDefault);
            setPortal(data);
        }
    }

    const handleOnSave = useCallback(() => {
        let isValid = true;
        if (!name) {
            setShowErrors(true);
            isValid = false;
        }

        if (!selectedWidgets || selectedWidgets.length === 0) {
            setShowWidgetsRequiredError(true);
            isValid = false;
        }

        if (!isValid) return;

        if (!portal.companies || portal.companies.length === 0) {
            let portalWithNoCompanies = allPortals.filter((x) => 
                (!portal.ravenId || portal.ravenId !== x.ravenId) && (!x.companies || x.companies.length === 0)
            );

            if (portalWithNoCompanies.length > 0) {
                setNoCompanyError(true);
                return;
            } else
                setShowNoCompanyConfirmation(!isDefault);
                
            if (!isDefault) return;
        } else {
            let portalWithSameCompany = allPortals.filter((x) => {
                const hasSameCompany = (!portal.ravenId || portal.ravenId !== x.ravenId) 
                    && x.companies && x.companies.length > 0 
                    && x.companies.some((z) => portal.companies.some((t) => t.companyId === z.companyId));
                if (hasSameCompany) {
                    return x;
                }
            });

            if (portalWithSameCompany.length > 0) {
                setDuplicatePortal(portalWithSameCompany[0]);
                setDuplicateCompanyError(true);
                return;
            }
        }

        if (isDefault && allPortals.some(p => p.isDefault && p.ravenId !== portal.ravenId)) {
            setShowDefaultConfirmation(true);
        } else {
            addEditPortal();
        }
    }, [name, description, selectedWidgets, isDefault, portal, allPortals]);

    const addEditPortal = () => {
        setLoading(true);
        portal.name = name;
        portal.description = description;
        portal.widgets = selectedWidgets;
        portal.isDefault = isDefault;

        if (!isEditMode)
            dispatch(savePortal(portal)).then((x) => {
                setLoading(false);
                if (x.response.data.success)
                    history.push('/admin/reporting-portal/portals');
            });
        else            
            dispatch(editPortal(portal)).then((x) => {
                setLoading(false);
                if (x.response.data.success)
                    history.push('/admin/reporting-portal/portals');
            });
    };

    const onRemoveAccessPill = (company) => {
        let currentCompanies = portal.companies;
        const deletedIndex = portal.companies.findIndex(i => i.companyId === company.companyId);
        if (deletedIndex > -1) {
            currentCompanies.splice(deletedIndex, 1);
        }
        setPortal({...portal, companies: currentCompanies});
        setDuplicateCompanyError(false);
        setNoCompanyError(false);
    };

    const onAddAccessPills = (response) => {
        portal.companies = _.uniqWith([...new Set([...portal.companies || [], ...response])], _.isEqual);
        setShowAccessPillDialog(false);
        setDuplicateCompanyError(false);
        setNoCompanyError(false);
    };

    const onWidgetChange = (values) => {
        setShowWidgetsRequiredError(false);
        setSelectedWidgets(values);
    };

    const onNoCompanyConfirm = () => {
        setShowNoCompanyConfirmation(false);
        addEditPortal();
    }

    const onConfirmReorder = (items) => {
        setSelectedWidgets(items);
        setShowReorderDialog(false);
    }

    const onDefaultConfirm = () => {
        setShowDefaultConfirmation(false);
        addEditPortal();
    };

    return (
        <div className="create-advanced-list container adv-admin-list">
            <img src={`/images/${isEditMode ? 'icn_edit.png ' : 'icn_create.png'}`} className={`icon ${isEditMode ? 'icon_edit' : ''} hidden-xs`} />

            <h1 className="title" style={{textAlign: 'center'}}>
                {isEditMode ? 'Edit' : 'Create New'} Portal
            </h1>
            <TextField
                label="Name"
                placeholder="New Portal Name"
                onChange={(e) => setName(e.target.value)}
                className="list-name"
                text={name}
                showErrorMessage={showErrors && !name}
                maxLength={200}
                tabIndex={0} />
            <TextField
                id="description"
                style={{'marginBottom': '30px'}}
                className="list-name"
                maxLength={200}
                text={description}
                label="Description"
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
                tabIndex={1} />

            <div style={{width: '390px'}}>
                <Checkbox
                    label="Set as default portal"
                    checked={isDefault}
                    tabIndex={2}
                    onChange={(e) => setIsDefault(e.checked)}
                />
            </div>

            <hr className="separator" />
            <div style={{width: '390px'}}>
                <h1 className="subtitle" style={{width: '390px'}}>
                    Availability:<span className="add-availability-pill" onClick={() => setShowAccessPillDialog(true)}>+ Assign Access</span>
                </h1>
                <div className="availability-wrapper">
                    {portal.companies && portal.companies.map((company) => (
                        <div key={company.companyId} className="display-pill">
                            {company.companyName}
                            <Icon aria-hidden="true" icon={faTimesCircle} onClick={() => onRemoveAccessPill(company)} />
                        </div>
                    ))}
                    {(!portal.companies || portal.companies.length === 0) && 
                        <div className="no-pills">This portal is currently not assigned to any suppliers.</div>}
                </div>
            </div>

            <hr className="separator" />

            <div style={{width: '390px'}}>
                <MultiSelect isMulti={true} cacheOptions={true} options={widgetOptions} getOptionValue={option => option.id}
                    getOptionLabel={option => option.name} noOptionsMessage={() => null} placeholder="Widgets" isClearable={true}
                    classNamePrefix="select" 
                    value={selectedWidgets} 
                    onChange={onWidgetChange} 
                    tabIndex={3}
                    className={showWidgetsRequiredError ? 'custom-multi-select error' : 'custom-multi-select'} />
            </div>
            <div style={{marginTop: '20px'}}>
                <span className="add-availability-pill" onClick={() => setShowReorderDialog(true)}> 
                    <Icon aria-hidden="true" icon={faSort} /> Reorder
                </span>
            </div>

            <hr className="separator" />

            {noCompanyError && <React.Fragment>
                <div style={{color: 'red'}}>You already have a portal with no companies assigned</div>
                <hr className="separator" />
            </React.Fragment>}
            {duplicateCompanyError && <React.Fragment>
                <div style={{color: 'red'}}>
                    A portal &apos;{duplicatePortal.name}&apos; already exists for this company, please edit that portal to make changes
                </div>
                <hr className="separator" />
            </React.Fragment>}
            <div>
                <div className="actions" style={{marginBottom: 30, width: 390}}>
                    <a className="cancel" onClick={() => history.push('/admin/reporting-portal/portals')}>Cancel</a>
                    <div className="col-xs-12 d-block d-sm-none" style={{paddingLeft: '10%', paddingRight: '10%', marginTop: 30}}>
                        <div className="row">
                            <div className="col-xs-5">
                                <hr />
                            </div>
                            <div className="col-xs-2 or">OR</div>
                            <div className="col-xs-5">
                                <hr />
                            </div>
                        </div>
                    </div>
                    <Button
                        className="btnSave"
                        onClick={handleOnSave}
                        loading={loading}
                    >Save</Button>
                </div>
            </div>
            {showAccessPillDialog && <RPAssignCompanyDialog onCancel={() => setShowAccessPillDialog(false)} onConfirm={onAddAccessPills} />}
            {showNoCompanyConfirmation ? <Popup confirmText="Yes"
                cancelText="No"
                show={true}
                hideButtons={false}
                onCancel={() => setShowNoCompanyConfirmation(false)}
                onConfirm={onNoCompanyConfirm}
                loading={loading}
                className="confirm-item-delete-dialog"
            >
                <div style={{fontSize: '16px'}}>
                    <b>Warning</b>: This portal will be available to all companies with Advanced Analytics turned on
                </div>
            </Popup> : null}

            {showDefaultConfirmation ? <Popup 
                confirmText="Yes"
                cancelText="No"
                show={true}
                hideButtons={false}
                onCancel={() => setShowDefaultConfirmation(false)}
                onConfirm={onDefaultConfirm}
                loading={loading}
                className="confirm-item-delete-dialog"
            >
                <div style={{fontSize: '16px'}}>
                    Another portal is currently set as default. Are you sure you want to make this portal the new default?
                </div>
            </Popup> : null}

            {showReorderDialog 
                ? <ReorderDialog items={selectedWidgets} onConfirm={onConfirmReorder} onCancel={() => setShowReorderDialog(false)} /> 
                : null
            }
        </div>
    );
}
