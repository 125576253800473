import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

const CardStatusSection = styled.div`
    background-color: #f1f1f1;
    overflow: hidden;
    padding: 0 29px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 95px;
    @media (max-width: 1149px) {
        margin-top: 20px;
        flex: 1 0 100%;
        position: static;
        margin-left: -30px;
        margin-right: -30px;
    }
`;

const CardStatusTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
`;

const CardStatusDetails = styled.div`
    font-size: 16px;
    font-weight: 400;
`;

const ShippingInfoCardV2 = (props) => {
    const {
        title,
        showDateInfo,
        date,
        noDateText,
        subText,
        additionalText,
        showTrackingInfo,  
        trackingNumber,  
        trackingText,  
        isComplete,
        className,
        noTimeText,
    } = props;  

    const renderStatus = () => {    
        return (
            <>
                <ul className="StepProgress">
                    <li className={getTitleProgressClassName()}>
                        <CardStatusTitle>
                            {title} {getDateInfo()}
                        </CardStatusTitle>
                        <CardStatusDetails style={{minHeight: '54px'}}>
                            {subText}
                            {renderAdditionalText()}
                        </CardStatusDetails>
                    </li>
                    {renderTrackingInfo()}
                </ul>
            </>
        );
    }
 
    const getTitleProgressClassName = () => {    
        if (isComplete) 
            return 'StepProgress-item is-next complete';
        else
            return 'StepProgress-item is-next';
    } 

    const hasValidTime = (dateCheck) => {
        let time = dateCheck?.split('T')[1];
        return time !== '00:00:00' && moment(time, 'HH:mm:ss', true).isValid();
    }

    const getDateInfo = () => {    
        if (!showDateInfo) 
            return '';
        let hasTime = hasValidTime(date)
        let strDateInfo = '';
        if (date && moment.utc(date).isAfter(moment.utc('0001-01-01'))) {   
            strDateInfo = moment(date).format(`MM/D/YYYY${hasTime ? ' hh:mm A' : ''}`);
            strDateInfo += hasTime ? '' : noTimeText ? (', ' + noTimeText) : '';
        } else {
            strDateInfo = noDateText;
        }
        return strDateInfo;
    }
    
    const renderTrackingInfo = () => {    
        if (showTrackingInfo) { 
            return (           
                <li className="StepProgress-item is-done current">
                    <CardStatusTitle>
                        Tracking # {trackingNumber === 'null' ? '' : trackingNumber}
                    </CardStatusTitle>
                    <CardStatusDetails style={{minHeight: '18px'}}>
                        {trackingText}
                    </CardStatusDetails>
                </li>
            );
        }
    }

    const renderAdditionalText = () => {    
        if (additionalText === '')
            return '';

        return (
            <>
                <br />
                {additionalText}
            </>
        );
    }

    return (
        <CardStatusSection className={className}>
            <div>
                {renderStatus()}
            </div>
        </CardStatusSection>
    )
}

ShippingInfoCardV2.propTypes = {  
    title: PropTypes.string,
    showDateInfo: PropTypes.bool,
    date: PropTypes.string,
    noDateText: PropTypes.string,
    subText: PropTypes.string,
    additionalText: PropTypes.string,
    showTrackingInfo: PropTypes.bool,
    trackingNumber: PropTypes.string,  
    trackingText: PropTypes.string,  
    isComplete: PropTypes.bool,
    className: PropTypes.string,
    noTimeText: PropTypes.string,
};

export default ShippingInfoCardV2;
