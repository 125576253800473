export function processFiles (files) {
    let result = {
        errorMessage: '',
        showErrorMessage: false,
        isFileSelected: false,
        uploadFile: {},
    }
    if (!files) {
        result.errorMessage = 'Your file selected was not uploaded. Please try again.';
        result.showErrorMessage = true;
        return result;
    }

    result.isFileSelected = true;

    let reader = new window.FileReader();
    let file = files[0];

    reader.onload = upload => {
        result.uploadFile = {
            name: file.name,
            file: upload.target.result,
        };
    };

    return new Promise((resolve, reject) => {
        reader.onerror = () => {
            reader.abort();
            reject('Problem parsing input file.');
        };

        reader.onload = (upload) => {
            result.uploadFile = {
                name: file.name,
                file: upload.target.result,
            };
            resolve(result);
        };
        reader.readAsDataURL(file)
    });
}

export function getCertificationLogo(attributeId) {
    const certLogo = {
        1: 'vendor-attributes-minority-owned@2x.png',
        2: 'vendor-attributes-women-owned@2x.png',
        3: 'vendor-attributes-veteran-owned@2x.png',
        4: 'vendor-attributes-service-disabled-veteran-owned@2x.png',
        5: 'vendor-attributes-lgbt-owned@2x.png',
        6: 'vendor-attributes-historically-underutilized-business@2x.png',
        9: 'vendor-attributes-leed@2x.png',
        10: 'vendor-attributes-green-building-council-certification@2x.png',
        11: 'vendor-attributes-zero-energy@2x.png',
        12: 'vendor-attributes-fda-certified@2x.png',
        13: 'vendor-attributes-iso-9000-quality-management@2x.png',
        14: 'vendor-attributes-iso-13485-medical-devices@2x.png',
        15: 'vendor-attributes-iso-17025-calibration@2x.png',
        17: 'vendor-attributes-sba@2x.png',
    };
    return certLogo[attributeId];
}

export const convertToBlob = (base64Data, contentType) => {
    contentType = contentType || '';
    let sliceSize = 512;

    let byteCharacters = window.atob(base64Data);
    let byteArrays = [];

    for (let currentSlice = 0; currentSlice < byteCharacters.length; currentSlice += sliceSize) {
        let slice = byteCharacters.slice(currentSlice, currentSlice + sliceSize);

        let bytesContainer = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            bytesContainer[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(bytesContainer);

        byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, {type: contentType});
    return blob;
}
