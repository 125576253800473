import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ShippingInfoCardV2 from 'components/ShippingInfoCardV2';

const OrderFooterContainer = styled.div`
    width: 100%;
    padding-top: 10px;
    height: 100px;
    background-color: rgb(241, 241, 241);
    margin-left: 0;
    padding-left: 0;

    @media (max-width: 818px) {

        margin-top: 0;
    }
`;

const CardContainer = styled(OrderFooterContainer)`

    display: inline-block;

    div {
        position: relative;
        bottom: 0!Important;
    }

    @media (max-width: 1149px) {

        ul {
            margin-top: 0!Important;
        }
    }
`;

const OrderFooter = (props) => {
    const {
        order = {},
        lineItem = {},
    } = props;

    return (
        <CardContainer>
            {lineItem.status === 'AWAITINGREVIEW' &&

                <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true}
                    title="Estimated Ship Date" subText={order.addressInfo}
                    date={lineItem.estimatedShipDate}
                    trackingNumber={lineItem.trackingNumber}
                    additionalText={order.shippingAttention}
                    trackingText={order?.shipper} noDateText="Pending" noTimeText="End of Day" />}

            {lineItem.status === 'PENDINGSHIPMENT' &&

            <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true}
                title="Estimated Ship Date" subText={order.addressInfo}
                date={lineItem.estimatedShipDate}
                trackingNumber={lineItem.trackingNumber}
                additionalText={order.shippingAttention}
                trackingText={order?.shipper} noDateText="Pending" noTimeText="End of Day" />}

            {lineItem.status === 'SHIPPED' &&

                <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true}
                    title="Shipped Date" subText={order.addressInfo}
                    date={lineItem.dateShipped}
                    trackingNumber={lineItem.trackingNumber}
                    additionalText={order.shippingAttention}
                    trackingText={order?.shipper} />}

            {lineItem.status === 'DELIVERED' &&

            <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true}
                title="Delivered" subText={order.addressInfo}
                date={lineItem.dateDelivered}
                trackingNumber={lineItem.trackingNumber}
                additionalText={order.shippingAttention}
                trackingText={order?.shipper} />}
        </CardContainer>
    );
};

export default OrderFooter;

OrderFooter.propTypes = {
    order: PropTypes.shape({}),
    lineItem: PropTypes.shape({}),
};
