export function validate(values) {
    let errors = {};

    if (!values.username) {
        errors.username = 'Username is required';
    }

    if (!values.pass) {
        errors.password = 'Password is required';
    }

    return errors;
}

export const validateEmail = (email) => {
    const match = /\S+@\S+\.\S+/;
    return match.test(email);
}

export const validatePhoneNumber = (number) => {
    const clearedNumber = number.replace('(', '').replace(')', '').replace('-', '').replace(/\s/g, '').trim();
    const phoneRegex = /^(1\s|1|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/;
    return phoneRegex.test(clearedNumber);
}

export const validateWholeNumbers = (number, includeZero = false) => Number.isInteger(Number(number)) && (includeZero ? number >= 0 : number > 0);

/* eslint-disable */
export const validateMoney = (money) => money.match(/^\$*(?=.*[0-9])\d*(?:\.\d{2})?\s*$/) ? true : false;
/* eslint-enable */
