import React, {useState} from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import {Popup} from '@partssourceinc/react-ui-core/components';
import Button from '@partssourceinc/react-ui-core/components/Button';
import TextField from '@partssourceinc/react-ui-core/components/TextField';
import {useParams} from 'react-router-dom';
import styled from 'styled-components'
import {Context} from 'utils/enums';
import {useSelector} from 'react-redux';

const NotesModal = ({notes, lineItemId, conditionId = null, show, onCancel, onSubmit, context = Context.ORDER, isReadOnly = false}) => {

    const [warningMessage, setWarningMessage] = useState('');
    const [note, setNote] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const isBid = context === Context.BID;
    const preventForBid = (isBid && (notes && notes.length > 0));

    const user = useSelector(state => state.user); 
    const {id} = useParams();

    const WarningMessageHandler = styled.p`
    color: red
    `;

    const NoteTitle = styled.strong`
    font-size: 12px,
    `;

    const Article = styled.article`
        margin-top: 5px
    `;

    const handleUpdateNote = (e) => {
        const content = e.target.value;

        if (/^[\s]+$/i.test(content)) {
            setWarningMessage(' ');
            return;
        } else {
            setWarningMessage(null);
        }

        const isLongText = content.length > 120;
        setNote(isLongText ? content.substring(0, 120) : content);
        setWarningMessage(isLongText ? 'Please limit your note to 120 characters.' : null);
    }

    const handleSubmitNote = async () => {
        setIsLoading(true);

        const request = {note, lineItemId};
        if (isBid) {
            request.orderId = id;
            request.conditionId = conditionId;            
        } else {
            request.noteType = context;
        }

        const response = await axios.post((isBid ? 'bid' : 'order') + '/note', request);
        if (response.status === 201 || response.status === 200) {
            const newNote = {text: note, dateCreated: Date.now(), 
                createdUserName: user.firstName + ' ' + user.lastName, lineItemId, typeId: conditionId};
            onSubmit(newNote);
            setIsLoading(false);
            setNote('');
        }
    }

    const renderNote = (item, i) => {
        return (
            <Article className="render-note" key={`${item.createdTimestamp}-${i}`}>
                {!isBid && <NoteTitle style={{textTransform: 'uppercase'}}>
                    {item?.createdUserName} - {moment(item.createdTimestamp).format('MM/DD/YYYY hh:mm A')}
                </NoteTitle>}
                <div dangerouslySetInnerHTML={{__html: item.text?.replaceAll('\n','<br />')}} />
            </Article>
        );
    }

    const renderExistingNotes = () => {
        return (
            <section>
                {
                    (notes && notes.length > 0) ?
                        notes.map((item, i) => renderNote(item, i)) :
                        <p>No Notes</p>
                }
            </section>
        )
    }

    return (
        <Popup
            show={show}
            onCancel={onCancel}
            hideButtons={true}>
            <section className="notes">
                <header><h1>Notes</h1></header>
                <aside>
                    {!isReadOnly && 
                    <>
                        <article>
                            <TextField
                                id="note"
                                multiLine={true}
                                rows={2}
                                text={note}
                                label="New Note"
                                placeholder="New Note"
                                required={true}
                                disabled={preventForBid}
                                onChange={handleUpdateNote}
                                limit={121}
                            />
                            {warningMessage && <WarningMessageHandler>{warningMessage}</WarningMessageHandler>}
                        </article>

                        <article>
                            <Button
                                style={{marginTop: !warningMessage && 15}}
                                disabled={!note || warningMessage || preventForBid}
                                className="save"
                                secondary={true}
                                onClick={handleSubmitNote}
                                loading={isLoading}>
                                SUBMIT
                            </Button>
                        </article>
                        <hr />
                    </>}
                  
                    <article>
                        {renderExistingNotes()}
                    </article>
                </aside>
            </section>
        </Popup>)
};

NotesModal.propTypes = {
    notes: PropTypes.arrayOf(PropTypes.shape({})),
    show: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    lineItemId: PropTypes.number,
    context: PropTypes.string,
    conditionId: PropTypes.shape({}),
    isReadOnly: PropTypes.bool,
};

export default NotesModal;
