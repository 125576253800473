import {useEffect, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {setCmsResources} from 'reducers/configuration';
import {BrComponentContext} from '@bloomreach/react-sdk';
import _ from 'lodash';

const CmsResources = () => {
    const component = useContext(BrComponentContext);
    const models = component.getModels() || {};
    const bundle = models && models.bundle || null;
    const dispatch = useDispatch();

    useEffect(() => {
        const newBundle = Object.entries(bundle).reduce((resources, [key, value]) => _.set(resources, key, value), {});
        dispatch(setCmsResources(newBundle));
    }, [bundle]);

    return null;
}

export default CmsResources;
