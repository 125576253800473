import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/pro-regular-svg-icons';
import Link from 'components/Link';

const SubMenuTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 14px;
    padding-top: 20px;
    cursor: pointer;
`;

const SelectedItemTitle = styled.div`
    color: #949494;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 44px;
    text-transform: capitalize;
    margin-bottom: -10px;
    margin-top: 5px;
`;

const MenuItem = styled(Link)`
    color: #000;
    cursor: pointer;
    font-size: 12px;
    font-size: 16px;
    font-weight: 600;
    transition: none !important;
    margin-left:20px;
`;

const Divider = styled.div`
    border: 1px solid #777777;
    height: 2px;
    width: 100%;
`;

const Icon = styled(FontAwesomeIcon)`
    float: left;
    color: black;
    margin-top: 6px;
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 900;
    font-style: normal;
    font-size: 12px;
`;

const MenuContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    left: ${props => props.show ? '0px' : '325px'};
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    overflow: hidden;
    border-left: 1px solid #F1F1F1;
    padding-bottom: 20px;
    padding-left:25px;
    padding-right: 25px;
    overflow-y: auto;
`;

const FlyOutSubMenu = ({show, content, goBack, subMenuClick}) => {
    const {title, items} = content;

    return (
        <MenuContainer show={show}>
            <SubMenuTitle onClick={goBack}> <Icon icon={faChevronLeft} /> Main Menu</SubMenuTitle>
            <Divider />
            <SelectedItemTitle>{title}</SelectedItemTitle>
            {items?.map((item, i) =>
                item?.visible && <MenuItem key={i} href={item?.url} onClick={() => subMenuClick(item?.title.toLowerCase())}>{item?.title}</MenuItem>
            )}
        </MenuContainer>
    )
}

export default FlyOutSubMenu;

FlyOutSubMenu.propTypes = {
    show: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.string]),
    content: PropTypes.shape(),
    goBack: PropTypes.func.isRequired,
    subMenuClick: PropTypes.func.isRequired,
};
