import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/string/repeat';
import 'core-js/features/string/match';
import Cookies from 'js-cookie';

import React from 'react';
import {render} from 'react-dom';
import {ThemeProvider} from 'styled-components';
import axios from 'axios';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {theme} from '@partssourceinc/react-ui-core';

import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import reducers from 'reducers';
import {Provider} from 'react-redux';
import init from 'site';
import {logoutUser, refreshToken} from 'reducers/user';
import CmsWrapper from 'components/CmsWrapper';
import App from 'App';
import * as fontAwesome from 'utils/fontAwesome';

// IE11 Polyfill
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
}

axios.defaults.headers.common.Pragma = 'no-cache';
axios.defaults.baseURL = process.env.REACT_APP_SPRO_SERVER_URL;
axios.interceptors.request.use(function (config) {
    let id_ins = Cookies.get('id_ins');
    if (!id_ins) {
        const uuidv4 = require('uuid/v4');
        id_ins = uuidv4();
        Cookies.set('id_ins', id_ins, {expires: 364, secure: window.location.hostname !== 'localhost', sameSite: 'None'});
    }

    const token = Cookies.get('tokenInfo');
    if (token) {
        config.headers.authorization = `${token}`;
    }

    return config;
}, function (error) {
    return Promise.reject(error);
})

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    let refreshT = Cookies.get('refreshToken');
    if (error.response && error.response.status === 401 && !refreshT) {
        store.dispatch(logoutUser());
        window.location.href = '/login';
    } else if (error.response && error.response.status === 401 && refreshT) {
        refreshToken().then(() => {
            let token = Cookies.get('tokenInfo');
            if (token) {
                return new Promise((resolve) => {
                    resolve(axios(error.config));
                });
            } else {
                store.dispatch(logoutUser());
                window.location.reload(true);
            }
        });
    } else if (error.response &&
        error.response.status === 400 &&
        (error.config.url.indexOf('/token') > 0
            || error.config.url.indexOf('/settings') >= 0
            || error.config.url.indexOf('/location') >= 0)) {
        store.dispatch(logoutUser());
        if (window.location.href.indexOf('/login') === -1)
            window.location.reload(true);
    } else {
        return Promise.reject(error);
    }
});

const middleware = getDefaultMiddleware({
    serializableCheck: false,
});

const persistedState = localStorage.getItem('sproState') 
    ? JSON.parse(localStorage.getItem('sproState')) : {};

const store = configureStore({
    reducer: reducers,
    middleware: middleware,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: {
        companies: undefined,
        configuration: persistedState?.configuration ?? undefined,
        settings: persistedState?.settings ?? undefined,
        user: persistedState?.user ?? undefined,
        supplier: undefined,
        bids: undefined,
        orders: undefined,
        attachments: undefined,
        common: undefined,
    },
});

store.subscribe(() => {
    const {user, configuration, settings} = store.getState();
    localStorage.setItem('sproState', JSON.stringify({
        user: user, 
        configuration: configuration,
        settings: settings,
    }));
});

const loadData = async () => {
    await store.dispatch(init());
}

loadData();
fontAwesome.initLibrary();

render((
    <ThemeProvider theme={theme}>
        <Router history={createBrowserHistory({})}>
            <Provider store={store}>
                <CmsWrapper>
                    <App />
                </CmsWrapper>
            </Provider>
        </Router>
    </ThemeProvider>
), document.getElementById('root'));
