export const Context = {
    BID: 'bid',
    ORDER: 'order',
    REPAIR: 'repair',
    RETURN: 'returnexchange',
}

export const STATUS_ENUM = {
    SOURCED: 3,
    REPAIRING: 12,
    NOSALE: 5,
    QUOTED: 4,
    EQUOTED: 26,
    ORDERED: 25,
    PO: 8
}

export const SETTINGS_VALUES_ENUM = {
    SisenseDashboardTimeoutSetting: 154,
    ShowResourcesPage: 159,
    PowerBIActivationLevel: 181
}

export const GenericAccountAccessMessage = 
    'We are having difficulty accessing your Supplier Portal account. Please email SupplierPortalSupport@partssource.com for assistance.';

export const UserDashboardPortalType = {
    Sisense: 0,
    PowerBi: 1,
}

export const BasicBIReportOperatorEnum = {
    IN: 'In',
    NOT_IN: 'NotIn',
    ALL: 'All'
}
