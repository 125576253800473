import React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import {Button,TextField,Checkbox, Dropdown} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';

import Spinner from 'components/Spinner';
import {useSelector} from 'react-redux';
import useStateFromProps from 'hooks/useStateFromProps';
import {SETTINGS_VALUES_ENUM} from 'utils/enums';

const SubmitButton = styled(Button)`
    margin-top: 3rem;
    margin-bottom: 1rem;
`;

const Row = styled.div`
    margin-bottom: ${props => props.hasMargin ? '3rem' : '0'};
`;

const DropdownList = styled(Dropdown)`
    div {
        span {
            font-weight: 700;
            font-size: 12px;
            color: #005DA6;
            margin: 4px 10px 4px 10px;
        }
    }
`;

function SettingsEditor({
    settings: initialSettings,
    loading,
    onSave,
}) {
    const [settings, setSettings] = useStateFromProps(initialSettings);
    const isAdmin = useSelector(state => state.user.isAdmin);

    const tabSettings = settings?.filter(s => s.settingCode !== 'PolicyExempt');
    const otherSettings = settings?.filter(s => !tabSettings.some(t => t.settingCode === s.settingCode));

    async function saveSettings(e) {
        e.preventDefault();
        onSave(settings);
        return false;
    }

    function renderValue(setting) {
        if (!setting) return;
        const isSisense = setting.settingId === SETTINGS_VALUES_ENUM.SisenseDashboardTimeoutSetting;
        const isPowerBiActivationLevel = setting.settingId === SETTINGS_VALUES_ENUM.PowerBIActivationLevel;
        function onChange(e, {value}) {
            const newSettings = _.cloneDeep(settings);
            const settingIndex = _.findIndex(newSettings, {settingCode: setting.settingCode});

            newSettings[settingIndex].value = value;
            setSettings(newSettings);
        }

        if (isPowerBiActivationLevel) {
            const powerBiOptions = [
                {value: 'off', text: 'Off'},
                {value: 'assigned', text: 'On for assigned suppliers only'},
                {value: 'all', text: 'On for all suppliers'}
            ];

            return (
                <div className="col-6">
                    <DropdownList
                        label={setting.settingDescription}
                        onChange={onChange}
                        id={`setting_${setting.settingId}`}
                        options={powerBiOptions}
                        selectedValue={setting.value}
                        suppressBlankOption={true}
                        valueField="value"
                        textField="text"
                    />
                </div>
            );
        }
        
        return (
            <div className="col-6">
                {isSisense ?
                    <TextField
                        label={setting.settingDescription}
                        text={setting.value}
                        type={'number'}
                        max={60}
                        min={0}
                        onChange={onChange}
                    />
                    :
                    <TextField
                        label={setting.settingDescription}
                        text={setting.value}
                        onChange={onChange}
                    />}
            </div>
        );
    }

    function renderField(setting) {
        function onChange(e) {
            const newSettings = _.cloneDeep(settings);
            const settingIndex = _.findIndex(newSettings, {settingCode: setting.settingCode});
            newSettings[settingIndex].value = e.checked ? 'Y' : 'N';
            setSettings(newSettings);
        }

        return (
            <div className="col-6">
                <Checkbox
                    label={setting.settingDescription}
                    checked={setting.value === 'Y'}
                    onChange={onChange}
                />
            </div>
        );
    }

    const renderSettings = (settingsArray, type) => {
        const filteredSettings = settingsArray.filter(s => s.settingType === type);
        if (filteredSettings.length < 1) return;

        if (type === 'V') {
            // Move PowerBI setting to the end
            const powerBiSetting = filteredSettings.find(s => s.settingId === SETTINGS_VALUES_ENUM.PowerBIActivationLevel);
            const otherSettings = filteredSettings.filter(s => s.settingId !== SETTINGS_VALUES_ENUM.PowerBIActivationLevel);
            return [...otherSettings, powerBiSetting].map(renderValue);
        }
        
        return filteredSettings.map(renderField);
    };

    return !settings
        ? <Spinner />
        : (
            <form onSubmit={saveSettings}>
                <Row className="row">
                    {renderSettings(otherSettings, 'V')}
                </Row>
                <Row className="row" hasMargin="true">
                    {renderSettings(otherSettings, 'F')}
                </Row>
                <h2>Set Feature Availability</h2>
                <Row className="row" hasMargin="true">
                    {renderSettings(tabSettings, 'V')}
                </Row>
                <Row className="row">
                    {tabSettings
                        .filter(s => s.settingType === 'F' && s.settingId !== SETTINGS_VALUES_ENUM.ShowResourcesPage)
                        .map(renderField)}
                </Row>
                {isAdmin && <Row className="row float-right">
                    <SubmitButton type="submit" loading={loading}>
                        Save
                    </SubmitButton>
                </Row>}
            </form>
        );
}

SettingsEditor.propTypes = {
    settings: PropTypes.arrayOf(PropTypes.shape({
        settingId: PropTypes.number.isRequired,
        settingType: PropTypes.oneOf(['V', 'F']).isRequired,
        settingCode: PropTypes.string.isRequired,
        settingDescription: PropTypes.string.isRequired,
        value: PropTypes.string,
    })),
    onSave: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default SettingsEditor;
