import * as PropTypes from 'prop-types';
import React from 'react';
import {Popup, TextField} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import {validatePhoneNumber} from 'utils/validation';
import useForm from 'hooks/useForm';

const PopupBody = styled.div`
    font-family: Source Sans Pro,sans-serif;
    font-size: 16px;
`;

const Row = styled.div`
    margin-bottom: 2rem;
`;

const formFields = {
    company: {
        required: true,
    },
    name: {
        required: true,
    },
    phone: {
        required: true,
        validator: {
            func: value => value.length <= 20 && validatePhoneNumber(value),
        },
    },
};

const TradeReferenceDialog = (props) => {
    const {tradeReference, handleOnSave, show, onCancel} = props;

    const onSubmitForm = (values) => {
        handleOnSave(values);
    }

    const {
        values,
        errors,
        handleOnChange,
        handleOnSubmit,
        disable,
        dirty,
    } = useForm(tradeReference, formFields, onSubmitForm);

    return (
        <React.Fragment>
            <Popup
                show={show}
                confirmText="Save"
                cancelText="Cancel"
                hideButtons={false}
                onCancel={onCancel}
                onConfirm={handleOnSubmit}
                disableConfirm={disable}
            >
                <PopupBody>
                    <h1>{tradeReference.name ? 'Edit Trade Reference' : 'Add Trade Reference'}</h1>

                    <Row>
                        <TextField
                            name="company"
                            onChange={handleOnChange}
                            label="Company"
                            placeholder="Company"
                            text={values.company}
                            maxLength={75}
                            showErrorMessage={errors.company && dirty.company}
                        />
                    </Row>
                    <Row>
                        <TextField
                            name="name"
                            onChange={handleOnChange}
                            label="Name"
                            placeholder="Name"
                            text={values.name}
                            maxLength={75}
                            showErrorMessage={errors.name && dirty.name}
                        />
                    </Row>
                    <TextField
                        name="phone"
                        onChange={handleOnChange}
                        label="Phone"
                        placeholder="Phone"
                        text={values.phone}
                        showErrorMessage={errors.phone && dirty.phone}
                    />
                </PopupBody>
            </Popup>
        </React.Fragment>
    );
}

export default TradeReferenceDialog;

TradeReferenceDialog.propTypes = {
    tradeReference: PropTypes.oneOfType([PropTypes.object]).isRequired,
    handleOnSave: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
};
