import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash'
import {setCachedOrders, setBreadCrumbs} from 'reducers/orders';
import {setCachedAttachments} from 'reducers/attachments';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy, faCalendar} from '@fortawesome/pro-regular-svg-icons';
import {Loader} from '@partssourceinc/react-ui-core';
import {Popup, brandFont, Button} from '@partssourceinc/react-ui-core';
import NotesModal from 'components/NotesModal';
import ErrorModal from 'components/ErrorModal';
import Attachments from 'components/AttachFile';
import styled from 'styled-components';
import OrderHeader from 'components/OrderHeader';
import OrderLine from 'components/OrderLine';
import EsdConfirmationDialog from 'components/EsdConfirmationDialog';
import OrderFooter from 'components/OrderFooter';
import PageBreadcrumbs from 'components/Breadcrumbs';
import {ImgContainer, Container, SecurityErrorText} from 'components/DetailsStyledComponents';
import {Context} from 'utils/enums';
import {logEvent, printPackingSlipPdf, printPurchaseOrderPdf, printShippingLabelPdf, formatMoney, getErrorMessage} from 'utility';
import ProductImage from '@partssourceinc/react-ui-core/components/ProductImage';
import {GridKeys} from 'utils/gridKeys/orderGridKeys';
import DownloadingDots from 'components/DownloadingDots';
import {faExclamation} from '@fortawesome/free-solid-svg-icons';

const OrderActionMobileContainer = styled.div`
    display: none;

    @media (max-width: 818px) {
        width: 100%;
        display: block;
        margin-top: 20px;
    }
`;

const OrderActionMobileContent = styled.div`
    width: 200px;
    float: right;
`;

const FooterContainer = styled.div`
    
`;

const OrderGridContent = styled.div`
    width: 100%;    
    position: relative;
    display: flex;    
`;

const OrderDetailField = styled.div`
    line-height: 25px;
    width: 100%;
    padding-left: 15px;
`;

const OrderDetailFieldMobile = styled(OrderDetailField)`
    display: none;
    margin-top: 15px;

    @media (max-width: 1010px) {
        display: block;
        padding-right: 0;
    }
`;
const Row = styled.div`
    margin-bottom: 1rem;       
`;

const Subtitle = styled.div`
    font-size: 16px;
    float: left;
`;

const PopupContent = styled.div`
    ${brandFont('16px')}
`;

const Icon = styled(FontAwesomeIcon)`
    float: left;
    color: black;
    margin-top: 14px;
    margin-right: 5px;
    margin-left: 5px;
`;

const OrderHeaderText = styled.h2`
    margin-top: 14px;
    margin-bottom: 25px;
    font-size: 30px;
    font-weight: 300;

    @media (max-width: 818px) {
        
        margin-top: 20px;
    }
`;

const BoldSubtitle = styled(Subtitle)`
    font-weight: 600;
    padding-left: 10px;    
`;

const OrderGridHeader = styled.div`
    margin-top: 20px;    
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2px;
`;

const OrderDetailsHeader = styled(Subtitle)`
    font-size: 20px;
    padding-left: 24px;
    min-width: 200px;
    display: inline-block;
    width: calc(100% - 625px);

    @media (max-width: 1110px) {
        width: calc(100% - 525px);
    }

    @media (max-width: 1010px) {
        width: calc(100% - 425px);
    }

    @media (max-width: 910px) {
        width: calc(100% - 325px);
    }

    @media (max-width: 818px) {
        width: 100%;
        padding-bottom: 10px;
    }
`;

const OrderDetailsColumnQty = styled.div`
    display: inline-block;
    width: 140px;    
    position: relative;
    top: -6px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsColumnPrice = styled.div`
    display: inline-block;
    width: 140px;    
    position: relative;
    top: -6px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsQty = styled(OrderDetailsColumnQty)`
    color: rgb(44, 58, 71);
    font-size: 16px;
    font-weight: 400;
    width: 140px;
    max-width: 140px;
    min-width: 140px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsPrice = styled(OrderDetailsColumnPrice)`
    color: rgb(44, 58, 71);
    font-size: 16px;
    font-weight: 400;
    width: 140px;
    max-width: 140px;
    min-width: 140px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsContainer = styled.div`
    display: inline-block;
    margin-top: -10px;
    width: calc(100% - 750px);
    
    @media (max-width: 1110px) {
        width: calc(100% - 650px);
    }

    @media (max-width: 1010px) {
        width: calc(100% - 550px);
    }

    @media (max-width: 910px) {
        width: calc(100% - 450px);
    }

    @media (max-width: 818px) {
        width: 100%;
    }
`;

const OrderActionContainer = styled.div`    
    width: 200px;
    position: absolute;
    right: 0;
    top: -5px;

    @media (max-width: 818px) {
        display: none;
    }    
`;

const OrderPrintButton = styled.div`
    display: inline-block;
    margin-right: 22px;
    width: 180px;    
    float: right;
    position: relative;

    @media (max-width: 818px) {
        display: none;
    }
`;

const BoldSubtitleNoPadding = styled(Subtitle)`
    font-weight: 700;
    font-size: 16px;
    color: rgb(0, 93, 166);
    cursor: pointer;
`;

const BoldSubtitleNoLink = styled(Subtitle)`
    font-weight: 700;
    font-size: 16px;    
`;

const OrderButtonContainer = styled.div`    
    margin-bottom: 15px;    
    padding-right: 0;
    float: right;
    z-index: 1000;
`;

const OrderButton = styled(Button)`
    border-radius: 0;   
    width: 200px;    
    float: right;
`;

const BlueText = styled(BoldSubtitle)`
    color: #166BAD;
    padding-left: 0;
    font-weight: 400;
    margin-top: 10px;
    cursor: pointer;
`;

const PrintBlueText = styled(BlueText)`  
    margin-top: 6px;    
    font-weight: 600;
    font-size: 14px;
`;

const UnderlineSubtitle = styled(Subtitle)`
    width: 100%;
    border-top-style: solid;
    border-width: 2px;    
    border-color: rgb(204, 204, 204);
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 0;
`;

const UnderlineSubtitleRow = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 0;        
`;

const UnderlineSubtitleBottom = styled(UnderlineSubtitle)`
    height: 5px;
    margin-bottom: 0;      
`;

const SmallTitle = styled.div`    
    
    color: rgb(144, 144, 144);  
    font-size: 18px;    
`;

const GraySubtitle = styled(Subtitle)`
    font-weight: 400;
    color: rgb(148, 148, 148);  
    font-size: 16px;  
    margin-top: 10px;
    width: 100%;
`;

const OrderLinkWrapper = styled.div`
    width: 100%;    
    padding-bottom: 10px;     
    padding-right: 0!Important;
    margin-left: -15px;
`;

const OrderLink = styled.a`
      
    margin-top: 10px;    

    &&& {
        text-transform: uppercase;
        cursor: ${props => props.isDisabled === true ? 'not-allowed' : 'pointer'};
        color: ${props => props.isDisabled === true ? '#B7B8BB' : '#005BA6'};
        font-weight: 600;

        &:hover {
            text-decoration: none;
            outline: none;
            color: ${props => props.isDisabled === true ? '#B7B8BB' : '#005BA6'};
        }

        &:focus {
            text-decoration: none;
            outline: none;
        }

        &:active:focus {
            text-decoration: none;
            outline: none;
        }
    }
`;

const PartImage = styled.span`
    display: inline-block;
    vertical-align: top;
    cursor: ${props => props.isLink ? 'pointer' : null};
`;

const RedExclamation = styled(FontAwesomeIcon)`
    width: 1em !important;
    height: 1em !important;
    padding: 2px;
    color: #ff0000;
    border: 1px solid;
    border-radius: 50%;
    font-size: 14px;
    margin-right: 3px;
`;

const RedNotification = styled.span`
    color: #ff0000;
    font-weight: 600;
    margin-top: 5px;
    font-size: 15px;
`;

const YellowExclamation = styled(FontAwesomeIcon)`
    width: 1em !important;
    height: 1em !important;
    padding: 2px;
    color: #ffae3f;
    border: 1px solid;
    border-radius: 50%;
    font-size: 14px;
    margin-right: 3px;
`;

const YellowNotification = styled.span`
    color: #ffae3f;
    font-weight: 600;
    margin-top: 5px;
    font-size: 15px;
`;

const OrderDetails = () => {
    const cachedAttachments = useSelector(state => state.attachments.cachedAttachments);
    const [currentLineItemId, setCurrentLineItemId] = useState(null);
    const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
    const [showOrderLinePopup, setShowOrderLinePopup] = useState(false);
    const [showEsdConfirmationPopup, setShowEsdConfirmationPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showSecurityError, setShowSecurityError] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const [isDownloadingPO, setIsDownloadingPO] = useState(false);
    const [isDownloadingPackingSlip, setIsDownloadingPackingSlip] = useState(false);
    const [isDownloadingShippingLabel, setIsDownloadingShippingLabel] = useState(false);
    const [currentTab, setCurrentTab] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    const breadcrumbs = useSelector(state => state.orders.breadcrumbs);
    const {id, poNum} = useParams();

    useEffect(() => {
        loadOrder();
        dispatch(setCachedAttachments([]));
        document.querySelector('html').scrollTop = 0;

    }, [location]);

    async function loadOrder() {
        setIsLoading(true);
        const orderDetailsUrl = poNum ? `/order/po/${poNum}` : `/order/${id}`;

        await axios.get(orderDetailsUrl)
            .then(res => {
                const tab = getBreadcrumbText(res.data.lineItems[0].status)
                setCurrentTab(tab);
                setSelectedOrder(res.data);

                if (!breadcrumbs || breadcrumbs.length === 0)
                    dispatch(setBreadCrumbs({
                        firstBreadcrumb: 'Orders', secondBreadcrumb: 'Order', returnText: tab,
                    }));

                setIsLoading(false);
            })
            .catch(() => {

                if (!breadcrumbs || breadcrumbs.length === 0)
                    dispatch(setBreadCrumbs({
                        firstBreadcrumb: 'Orders', secondBreadcrumb: 'Order', returnText: '',
                    }));

                setIsLoading(false);
                setShowSecurityError(true);
            })
    }

    function getBreadcrumbText(status) {
        return GridKeys.find(x => x.value.toLowerCase() === status.toLowerCase()).tabDisplay;
    }

    const showNotePopup = (lineItemId) => {

        document.body.classList.add('modal-popup');
        document.getElementsByTagName('html')[0].className = 'modal-popup';

        setCurrentLineItemId(lineItemId)
        setShowNotes(true);
    }

    const hideNotePopup = () => {
        setShowNotes(false);
        setCurrentLineItemId(null);

        document.body.classList.remove('modal-popup');
        document.getElementsByTagName('html')[0].className = '';
    }

    const hideErrorPopup = () => {
        setShowErrorPopup(false);
    }

    const onSubmitNote = (newNote) => {
        const _currentOrder = {...selectedOrder};
        let lineItem = _currentOrder.lineItems.find(x => x.lineItemId === newNote.lineItemId);
        lineItem.notes.unshift(newNote);
        setSelectedOrder(_currentOrder);
        setShowNotes(false);
        dispatch(setCachedOrders([]));
    }

    const showAttachmentsPopup = (lineItemId) => {
        document.body.classList.add('modal-popup');
        document.getElementsByTagName('html')[0].className = 'modal-popup';

        setCurrentLineItemId(lineItemId);
        setShowAttachmentPopup(true);
    }

    const onClose = () => {
        setShowAttachmentPopup(false);
        document.body.classList.remove('modal-popup');
        document.getElementsByTagName('html')[0].className = '';
    }

    const onConfirm = () => {
        setShowAttachmentPopup(false);
        document.body.classList.remove('modal-popup');
        document.getElementsByTagName('html')[0].className = '';
        window.location.reload();
    }

    const showOrderLineProcessPopup = (lineItemId) => {
        setCurrentLineItemId(lineItemId);
        setShowOrderLinePopup(true);
    }

    const showOrderLineEsdPopup = (lineItemId) => {
        setCurrentLineItemId(lineItemId);
        setShowEsdConfirmationPopup(true);
    }

    const onEsdConfirmationUpdate = async () => {
        setShowEsdConfirmationPopup(false);
        window.location.reload();
    }

    const onAttachmentUpload = (attachment) => {

    }

    const printPurchaseOrder = async () => {
        if (selectedOrder && selectedOrder.lineItems.length > 0) {
            await printPurchaseOrderPdf(selectedOrder.lineItems[0].lineItemId, selectedOrder.billingPo,
                selectedOrder.lineItems[0].gsaItem, setIsDownloadingPO);
        }

    }

    const printPackingSlip = async (lineItemId) => {
        setCurrentLineItemId(lineItemId);
        await printPackingSlipPdf(selectedOrder.billingPo, setIsDownloadingPackingSlip);
    }

    const printShippingLabel = async (lineItemId) => {
        setCurrentLineItemId(lineItemId);
        await printShippingLabelPdf(lineItemId, setIsDownloadingShippingLabel);
    }

    const onOrderLineSave = async (orderLine, lineItem) => {

        setIsLoading(true);

        let request = {
            orderId: selectedOrder.orderNumber,
            companyId: selectedOrder.companyId,
            orderType: orderLine.orderType,
            lineItems: [],
        };

        let requestOrderLine = {
            lineItemId: lineItem.lineItemId,
            trackingNumber: orderLine.vendorToCustomerTrackingNumber,
            vendorOrderNumber: orderLine.vendorOrderNumber,
            noteType: orderLine.noteType,
            note: orderLine.note,
            vendorRmaNumber: orderLine.vendorRmaNumber,
            shipDateReasonId: orderLine.shipDateReasonId === '' ? 0 : orderLine.shipDateReasonId,
            estimatedShipDate: orderLine.estimatedShipDate,
            serialNumber: orderLine.serialNumber,
        }

        setShowOrderLinePopup(false);
        requestOrderLine.noteType = 'Order';
        request.lineItems.push(requestOrderLine);

        await axios.post(`/order`, request)
            .then(() => {
                axios.post(`/order/${lineItem.lineItemId}/attachments`, cachedAttachments);
                setIsLoading(false);
                dispatch(setCachedOrders([]));
                dispatch(setCachedAttachments([]));
                logEvent('Order Update', {
                    'Order Number': request.orderId,
                    'Ref number': lineItem.lineItemId,
                    'Order State': 'Process',
                    'Tab': currentTab,
                    'Action': 'OK',
                });
                history.goBack(2);
            })
            .catch(() => {
                setIsLoading(false);
                setShowErrorPopup(true);
            })
    }

    const renderNotification = (lineItem) => {
        let notification = '';
        if (lineItem.isPastDue) {
            notification += 'Past Due';
        } else if (lineItem.hasMissingData) {
            notification += 'Missing Info';
        } else if (currentTab === 'AwaitingReview') {
            notification += '14 Day Review';
        }

        if (lineItem.isUrgent) {
            notification += notification ? ', Urgent' : 'Urgent';
            return (
                <div>
                    <RedExclamation icon={faExclamation} />
                    <RedNotification> {notification}</RedNotification>
                </div>
            )
        } else if (notification && currentTab !== 'Shipped' && currentTab !== 'Delivered') {
            return (
                <div>
                    <YellowExclamation icon={faExclamation} />
                    <YellowNotification> {notification}</YellowNotification>
                </div>
            )
        }
    }

    const renderActionButtons = (lineItem) => {

        let showReviewShipping = false;
        if (lineItem && !lineItem?.isPastDue && !lineItem?.hasMissingData) {
            showReviewShipping = true;
        }

        switch (lineItem.status) {
            case 'AWAITINGREVIEW':
                return (
                    <React.Fragment>
                        {showReviewShipping ?
                            <OrderButtonContainer className="col-11">
                                <OrderButton onClick={() => showOrderLineEsdPopup(lineItem?.lineItemId)}
                                    className="primary">REVIEW SHIPPING</OrderButton>
                            </OrderButtonContainer> :
                            <OrderButtonContainer className="col-11">
                                <OrderButton onClick={() => showOrderLineProcessPopup(lineItem?.lineItemId)}
                                    className="primary">PROCESS</OrderButton>
                            </OrderButtonContainer>
                        }
                    </React.Fragment>
                );
            case 'PENDINGSHIPMENT':
                return (
                    <React.Fragment>
                        <OrderButtonContainer className="col-11">
                            <OrderButton onClick={() => showOrderLineProcessPopup(lineItem?.lineItemId)}
                                className="primary">PROCESS</OrderButton>
                        </OrderButtonContainer>
                    </React.Fragment>
                );
            case 'SHIPPED':
            case 'DELIVERED':
                return (
                    renderPrinting(lineItem)
                   
                );
            default:
                return null;
        }
    }

    const renderPrinting = (lineItem) => {
        return (
            <React.Fragment>
                {(isDownloadingPackingSlip && currentLineItemId === lineItem?.lineItemId) ?
                    <DownloadingDots />
                    : <OrderLinkWrapper style={{marginRight: '55px'}} className="col-11">
                        <OrderLink onClick={() => printPackingSlip(lineItem?.lineItemId)}>PRINT PACKING SLIP</OrderLink>
                    </OrderLinkWrapper>}
                {(isDownloadingShippingLabel && currentLineItemId === lineItem?.lineItemId) ?
                    <DownloadingDots />
                    : <OrderLinkWrapper style={{marginRight: '36px'}} className="col-11">
                        <OrderLink isDisabled={!lineItem?.hasShippingLabel}
                            onClick={lineItem?.hasShippingLabel ? () => printShippingLabel(lineItem?.lineItemId) : () => false}>
                            PRINT SHIPPING LABEL
                        </OrderLink>
                    </OrderLinkWrapper>}
            </React.Fragment>
        )
    }

    const renderLineItem = (lineItem, index) => {

        let copyLineItem = _.clone(lineItem);
        copyLineItem.vendorOrderNumber = selectedOrder.vendorOrderNumber;
        copyLineItem.displayPo = selectedOrder.displayPo;
        copyLineItem.vendorToCustomerTrackingNumber = lineItem.trackingNumber;
        return (
            <React.Fragment key={index}>
                <OrderGridContent style={{top: index === 0 ? '-8px' : '20px'}} >
                    <ImgContainer>
                        <PartImage isLink={lineItem.imageDefaultUrl} onClick={() => (
                            lineItem.imageDefaultUrl ?
                                window.open(`${process.env.REACT_APP_PARTSSOURCE_URL}${lineItem.imageDefaultUrl}`, '_blank') : null)}>

                            <ProductImage style={{width: '100px'}} url={lineItem.imagePath} altText={lineItem.imageAlt} />
                        </PartImage>
                    </ImgContainer>
                    <OrderDetailsContainer>
                        <Row className="row">
                            <OrderDetailField>
                                {lineItem.imageDefaultUrl ?
                                    <BoldSubtitleNoPadding
                                        onClick={() => window.open(`${process.env.REACT_APP_PARTSSOURCE_URL}${lineItem.imageDefaultUrl}`, '_blank')}>
                                        {lineItem?.description}</BoldSubtitleNoPadding>
                                    :
                                    <BoldSubtitleNoLink>
                                        {lineItem?.description}</BoldSubtitleNoLink>
                                }
                            </OrderDetailField>
                            <OrderDetailField>
                                {renderNotification(lineItem)}
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Order Type: </Subtitle><BoldSubtitle>{lineItem?.orderType}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Item #: </Subtitle><BoldSubtitle>{lineItem?.partNumber}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Condition: </Subtitle><BoldSubtitle>{lineItem?.conditionDescription}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Warranty: </Subtitle><BoldSubtitle>{lineItem?.warrantyCode}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Return Status: </Subtitle>
                                <BoldSubtitle>{lineItem?.nonReturnable ? 'Not Returnable' : 'Returnable'}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Ref #: </Subtitle><BoldSubtitle>{lineItem?.lineItemId}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Icon aria-hidden="true" icon={faCopy} />
                                <BlueText onClick={() => showAttachmentsPopup(lineItem?.lineItemId)}>
                                    Attachments ({lineItem?.attachments ? lineItem?.attachments.length : 0}) </BlueText>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Icon aria-hidden="true" icon={faCalendar} />
                                <BlueText onClick={() => showNotePopup(lineItem?.lineItemId)}>
                                    Notes ({lineItem?.notes ? lineItem?.notes.length : 0}) </BlueText>
                            </OrderDetailField>
                            <OrderDetailFieldMobile className="col-11">
                                <Subtitle>Qty: </Subtitle>
                                <BoldSubtitle>{lineItem?.quantity} </BoldSubtitle>

                                <BoldSubtitle>{formatMoney(lineItem?.unitPrice)}/ea </BoldSubtitle>
                                <BoldSubtitle style={{fontSize: '27px'}}>{formatMoney(lineItem?.unitPrice * lineItem?.quantity)}</BoldSubtitle>
                            </OrderDetailFieldMobile>

                            <OrderActionMobileContainer>
                                <OrderActionMobileContent>
                                    {renderActionButtons(lineItem, index)}
                                </OrderActionMobileContent>
                            </OrderActionMobileContainer>

                        </Row>
                    </OrderDetailsContainer>

                    <OrderDetailsQty>
                        <SmallTitle>{lineItem?.quantity}</SmallTitle>
                    </OrderDetailsQty>
                    <OrderDetailsPrice className="col-xs-12 col-sm-2 col-lg-1">
                        <SmallTitle>{formatMoney(lineItem?.unitPrice)}</SmallTitle>
                    </OrderDetailsPrice>
                    <OrderDetailsPrice>
                        <SmallTitle>{formatMoney(lineItem?.unitPrice * lineItem?.quantity)}</SmallTitle>
                    </OrderDetailsPrice>

                    <OrderActionContainer>
                        {renderActionButtons(lineItem, index)}
                    </OrderActionContainer>

                    <Popup
                        onCancel={onClose}
                        show={showAttachmentPopup && currentLineItemId === lineItem?.lineItemId}
                        onConfirm={onConfirm}
                        confirmText="Ok"
                        cancelText="Cancel">
                        <PopupContent>
                            <Attachments attachments={lineItem?.attachments} isReadOnly={false}
                                handleOnSave={(e) => onAttachmentUpload(e)}
                                allowMultipleFiles={true}
                                lineItemId={lineItem?.lineItemId} />
                        </PopupContent>
                    </Popup>

                    <NotesModal
                        show={showNotes && currentLineItemId === lineItem?.lineItemId}
                        context={Context.ORDER}
                        notes={lineItem?.notes}
                        lineItemId={currentLineItemId}
                        onSubmit={onSubmitNote}
                        onCancel={() => hideNotePopup()} />

                    {(showOrderLinePopup && currentLineItemId === lineItem?.lineItemId) &&
                        <OrderLine
                            handleOnCancel={() => setShowOrderLinePopup(false)}
                            handleOnSave={(e) => onOrderLineSave(e, lineItem)}
                            order={{id, ...copyLineItem}}
                            type="part" />
                    }

                    <EsdConfirmationDialog
                        show={showEsdConfirmationPopup && currentLineItemId === lineItem?.lineItemId}
                        onCancel={() => setShowEsdConfirmationPopup(false)}
                        onSubmit={(e) => onEsdConfirmationUpdate(e)}
                        orderId={id}
                        lineItemId={lineItem.lineItemId}
                        date={lineItem.estimatedShipDate}
                        shipDateReasonId={lineItem.shipDateReasonId}
                        trackingNumber={lineItem.trackingNumber}
                        type={selectedOrder.orderType} />

                </OrderGridContent>
                <FooterContainer style={{marginTop: index === 0 ? '-5px' : '20px'}}>
                    <OrderFooter order={selectedOrder} lineItem={lineItem} />
                </FooterContainer>
                <UnderlineSubtitleRow>
                    <UnderlineSubtitleBottom>&nbsp;</UnderlineSubtitleBottom>
                </UnderlineSubtitleRow>
            </React.Fragment>
        )
    }

    return (
        <Container>
            <PageBreadcrumbs />
            {!showSecurityError && !isLoading &&
                <OrderHeaderText>PO #: {selectedOrder?.displayPo}</OrderHeaderText>
            }
            {showSecurityError &&
                <SecurityErrorText>Sorry, you do not have access to this order.</SecurityErrorText>
            }
            {(!showSecurityError && !isLoading) &&
                <OrderHeader order={selectedOrder} />
            }
            {(!showSecurityError && isLoading) &&
                <Loader style={{margin: '0 auto'}} />
            }
            {(!showSecurityError && !isLoading) &&
                <div>
                    <OrderGridHeader>
                        <OrderDetailsHeader>Order Details</OrderDetailsHeader>
                        <OrderDetailsColumnQty>
                            <GraySubtitle>Quantity</GraySubtitle>
                        </OrderDetailsColumnQty>
                        <OrderDetailsColumnPrice>
                            <GraySubtitle>Unit Price</GraySubtitle>
                        </OrderDetailsColumnPrice>
                        <OrderDetailsColumnPrice>
                            <GraySubtitle>Ext. Price</GraySubtitle>
                        </OrderDetailsColumnPrice>
                        <OrderPrintButton>
                            {isDownloadingPO ? <DownloadingDots style={{marginTop: 6}} /> :
                                <PrintBlueText
                                    onClick={printPurchaseOrder}>Print Purchase Order</PrintBlueText>}
                        </OrderPrintButton>
                    </OrderGridHeader>
                    <Row>
                        <UnderlineSubtitle>&nbsp;</UnderlineSubtitle>
                    </Row>

                    {selectedOrder &&
                        (selectedOrder.lineItems).map((lineItem, index) => renderLineItem(lineItem, index))}
                </div>
            }

            <ErrorModal
                show={showErrorPopup}
                popupTitle={'Order Save'}
                popupMessage={getErrorMessage('order')}
                onCancel={() => hideErrorPopup()} />

        </Container>
    );
};

export default OrderDetails;
