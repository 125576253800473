import axios from 'axios';
import _ from 'lodash';

const GET_FEATURE_FLAGS_SUCCESS = 'GET_FEATURE_FLAGS_SUCCESS';
const SET_CMS_RESOURCES = 'SET_CMS_RESOURCES';
const ERROR = 'ERROR';

export const defaultState = {
    featureFlags: {},
    cmsResources: {
        loadingStrings: [],
    },
};

const reducer = (state = _.cloneDeep(defaultState), action) => {
    switch (action.type) {
        case GET_FEATURE_FLAGS_SUCCESS:
            return {...state, featureFlags: action.features};
        case SET_CMS_RESOURCES:
            return {...state, cmsResources: action.resources};
        case ERROR:
        default:
            return state;
    }
}

export function getFeatureFlags() {
    return (dispatch, getState) => {
        return axios
            .get('/setting/features')
            .then(({data: features}) => dispatch({type: GET_FEATURE_FLAGS_SUCCESS, features}))
            .catch(_.noop);

    };
}

export function setCmsResources(resources) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_CMS_RESOURCES,
            resources,
        });
    }
}

export default reducer;
