import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import {theme} from '@partssourceinc/react-ui-core';
import SingleReportingPortalHeader from 'components/PowerBi/SingleReportingPortalHeader';
import {models} from 'powerbi-client';
import {PowerBIEmbed} from 'powerbi-client-react';
import 'components/css/singleReportsPowerbi.css';
import 'components/css/multiGrid.css';
import {useLocation} from 'react-router';
import {Loader} from '@partssourceinc/react-ui-core';
import {useSelector} from 'react-redux';
import {getRootCompanyConditions, buildAdvancedFilter, buildBasicFilter, getFacilitiesName} from 'components/PowerBi/PowerBiUtils';
import {BasicBIReportOperatorEnum} from 'utils/enums';
import PowerBiApiService from 'components/PowerBi/PowerBiApiService';

const MultiGridPage = styled.div.attrs({className: 'multi-grid-page'})`
    width:100%;
    background: #f1f1f1;
    margin: 0 -20px; 
    padding: 0 20px;
    min-height: calc(100vh - 592px);
    @media screen and (min-width: ${theme.screenXLMin}) {
        margin: 0 -50px; 
        padding: 0 50px;
   }
    @media screen and (max-width: ${theme.screenLGMax}) {
        margin: 0 -50px; 
        padding: 0 50px;
   }
    @media screen and (max-width: ${theme.screenMDMax}) {
        margin: 0 -20px; 
        padding: 0 20px;
   }
    @media screen and (max-width: ${theme.screenSMMax}) {
        margin: 0 -20px; 
        padding: 0 20px;
   }
    @media screen and (max-width: ${theme.screenXsMax}) {
        margin: 0 -20px; 
        padding: 0 20px;
   }
    @media screen and (max-width: 320px) {
        margin: 0 -20px; 
        padding: 0 20px;
   }

   iframe {
    border: 0;
   }
`;

const LoaderContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 450px;
`;

const orionPowerBiService = new PowerBiApiService();

const SingleReportsDashboardPowerBi = () => {
    const {reportId} = useParams();
    const reportContainerRef = useRef();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [embedData, setEmbedData] = useState();
    const [embedPageName, setembedPageName] = useState();
    const [embedConfig, setembedConfig] = useState();
    const rootCompanyId = useSelector(state => state.user.companyId);
    const facilities = useSelector(state => state.settings.facilities);
    const mobileMaxWidth = 496;
    const location = useLocation();

    const isMobile = window.screen.width <= mobileMaxWidth;
    const embedConfigSettings = {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        persistentFiltersEnabled: true,
        layoutType: isMobile ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
        customLayout: {
            displayOption: models.DisplayOption.ActualSize
        }
    };

    const getFacilityFilter = () => {
        const facilityNames = getFacilitiesName(facilities);
        return rootCompanyId ? [
            buildAdvancedFilter('Vendors', 'COMPANY_ID', getRootCompanyConditions(rootCompanyId), 'And'),
            buildBasicFilter('Vendors', 'COMPANY_NAME', BasicBIReportOperatorEnum.IN, facilityNames)
        ] : [];
    };

    const getEmbedConfig = () => {
        return {
            type: 'report',
            tokenType: models.TokenType.Embed,
            accessToken: embedData?.token,
            embedUrl: embedData?.embedUrl,
            pageView: 'actualSize',
            settings: {...embedConfigSettings},
            pageName: embedPageName,
            filters: rootCompanyId ? getFacilityFilter() : []
        };
    };
    
    const getMobileRatio = (screenSize) => {
        const mobileRatio = screenSize / mobileMaxWidth;

        return mobileRatio >= 0.95 ? mobileRatio + 0.015
            : mobileRatio >= 0.80 ? mobileRatio + 0.01
                : mobileRatio >= 0.75 ? mobileRatio - 0.005
                    : mobileRatio >= 0.70 ? mobileRatio - 0.015
                        : mobileRatio >= 0.65 ? mobileRatio - 0.025
                            : mobileRatio >= 0.60 ? mobileRatio - 0.03
                                : mobileRatio;
    };

    const getMobileScreenHeight = (mobileHeight) => {
        const screenSize = window.screen.width;
        const mobileRatio = getMobileRatio(screenSize);

        return screenSize >= 300 && screenSize <= mobileMaxWidth ? mobileHeight * 1.37 * mobileRatio : mobileHeight * 1.37;
    };

    const reportRenderedEvent = () => {
        if (reportContainerRef.current) {
            reportContainerRef.current.getPageByName(embedPageName).then((page) => {
                const pageSize = page.defaultSize;
                const mobileSize = page.mobileSize;

                if (isMobile && mobileSize) {
                    window.document.getElementsByClassName('power-bi-single-report')[0].style.height = 
                      `${getMobileScreenHeight(mobileSize.height) + 50}px`;
                } else {
                    window.document.getElementsByClassName('power-bi-single-report')[0].style.height = `${pageSize.height + 50}px`;
                }
            });
            reportContainerRef.current.iframe.style.border = 'unset';
        }
    };
    
    useEffect(() => {  
        setLoading(true);
        getReportEmbeddedData();
        setLoading(false);
    }, [location]);

    useEffect(() => {  
        reportRenderedEvent();
    }, [reportContainerRef.current]);

    useEffect(() => {  
        if (reportContainerRef.current) {
            reportContainerRef.current.getPageByName(embedPageName).then(page => {
                page.getFilters().then(() => {
                    reportContainerRef.current.updateFilters(models.FiltersOperations.Replace, getFacilityFilter()).then(() => {
                        reportContainerRef.current.refresh();
                    });
                });
            });
        }
    }, [embedData]);

    useEffect(() => {  
        const embedConfig = !_.isNil(embedData) ? 
            {
                ...getEmbedConfig(),
            }
            : null;
        if (_.isNil(embedData)) {
            setLoading(true);
        } else if (!_.isNil(embedData) && embedData?.error) {
            setLoading(false);
            setShowErrorMessage(true);
        } else {
            setLoading(false);
            setembedConfig(embedConfig ? {...embedConfig} : embedConfig);
        }
    }, [embedData]);

    const getReportEmbeddedData = async () => {
        const {data: powerBiTokenData} = await axios.get(`reporting-portal/workspace/${reportId}/powerbi-token-request`)
        const {data} = await orionPowerBiService.getWorkspaceEmbeddedData(powerBiTokenData);
        const reportData = data.reportData;
        const pageName = powerBiTokenData?.reportPageId ? powerBiTokenData.reportPageId : null;
        setembedPageName(pageName);
        setEmbedData({...reportData, error: data?.error});
    };

    return (
        <MultiGridPage>
            <SingleReportingPortalHeader />
            {
                loading ? (
                    <LoaderContainer>
                        <Loader />
                    </LoaderContainer>
                ) : (
                    <div className="report-container" style={{marginTop: '-200px'}}>
                        {!_.isNil(embedConfig) && (
                            <div className="embed-report-container" id="embed-container">    
                                <PowerBIEmbed
                                    data-class="powerbi-iframe"
                                    embedConfig={embedConfig}
                                    cssClassName="power-bi-single-report"
                                    eventHandlers={
                                        new Map([
                                            ['rendered', () => {
                                                reportRenderedEvent();
                                            }]
                                        ])
                                    }
                                    getEmbeddedComponent={(report) => {
                                        reportContainerRef.current = report;
                                    }}
                                />
                            </div>
                        )}
                        {showErrorMessage && (
                            <div className="embedContainer-container" id="embedContainer">
                                <h1 style={{textAlign: 'center'}}>
                                    There was an issue retrieving the reports. Please contact our support service for further assistance.
                                </h1>
                            </div>
                        )}
                    </div>
                )
            }
        </MultiGridPage>
    );
};

export default SingleReportsDashboardPowerBi;
