import * as React from 'react';
import {theme} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

const Container = styled.div`
    width: 100%;
    top: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
`;

const CenteredChildren = styled.div`
    padding-top: 96px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    top: 0;
`;

const CenteredDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Text = styled.span`
    max-width: 768px;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 32px;

    @media (max-width: ${theme.screenSMMin}) {
        font-size: 14px;
        max-width: 510px;
    }

    @media (max-width: 320px) {
        max-width: 280px;
    }
`;

// `componentDidCatch` is currently not available as a hook, so this
// component must be a class
export class ErrorBoundary extends React.Component {
    /* eslint-disable */
    static propTypes = {
        children: PropTypes.element,
    };
    /* eslint-enable */

    state = {
        hasError: false,
        error: {message: '', stack: ''},
        info: {componentStack: ''},
    };
  
    static getDerivedStateFromError = error => {
        
        return {hasError: true};
    };
  
    render() {
        const {hasError} = this.state;
        const {children} = this.props;
        
        return hasError ? <Container>
            <CenteredDiv>
                <CenteredChildren>
                    <Text>An issue has been identified, please contact support at supplierportalsupport@partssource.com.</Text>
                </CenteredChildren>
            </CenteredDiv>
        </Container> : children;
    }
}
