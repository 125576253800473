import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux';
import _ from 'lodash'
import moment from 'moment';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import {faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DatePicker from 'components/DatePicker';
import {TextField, Button, TextFieldTags, Dropdown} from '@partssourceinc/react-ui-core';
import {GridKeys as orderKeys} from 'utils/gridKeys/orderGridKeys';
import {GridKeys as repairKeys} from 'utils/gridKeys/repairsGridKeys';
import {setCachedFilters, getCachedFilters} from 'reducers/orders';
import 'components/css/searchcriteria.css';
import axios from 'axios';

const ApplyFiltersButton = styled(Button)`
    background-color: #f1f1f1!important;
    color: #000!important;
`;

const AddFilterButton = styled.a`
    font-size: 14px;
    cursor: pointer;
`;

const AddFilterIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    margin-right: 5px;
    font-size: 18px;
`;

const DeleteFilterIcon = styled(FontAwesomeIcon)`
`;

const SearchFilter = {
    BillingPo: 'BillingPo',
    CompanyId: 'CompanyId',
    ConditionDescription: 'ConditionDescription',
    ConditionId: 'ConditionId',
    StartDate: 'StartDate',
    EndDate: 'EndDate',
    IsBackordered: 'IsBackordered',
    IsReceived: 'IsReceived',
    IsRepair: 'IsRepair',
    LineItemId: 'LineItemId',
    ManufacturerId: 'ManufacturerId',
    OrderId: 'OrderId',
    OrderType: 'OrderTypeId',
    OrigReference: 'OrigReference',
    PartNumber: 'PartNumber',
    ShipDateReasonId: 'ShipDateReasonId',
    ShippingPo: 'ShippingPo',
    PriorityId: 'PriorityId',
    Status: 'Status',
    OrderStatus: 'OrderStatus',
    TrackingNumber: 'TrackingNumber',
    VendorOrderNumber: 'VendorOrderNumber',
    VendorPartNumber: 'VendorPartNumber',
};

const SearchCriteria = (props) => {
    const {
        getOems,
        oems,
        onApply,
    } = props;

    // const cachedCriteria = useSelector(state => state.orders.cachedCriteria);
    const listData = useSelector(state => state.bids.listData);
    const [criteria, setCriteria] = useState([]);
    const menuSettings = useSelector(state => state.settings.menuSettings);
    const facilities = useSelector(state => state.settings.facilities);
    const canSeeOrders = (menuSettings?.find(c => c.id === '123' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const canSeeRepairs = (menuSettings?.find(c => c.id === '124' && c.name === 'Y') && facilities.length > 0) ? true : false;
    
    const [types, setTypes] = useState([
        {name: 'Billing Po #', type: 'text', field: SearchFilter.BillingPo},
        {name: 'Company', type: 'text', field: SearchFilter.CompanyId, values: [], keyField: 'companyId', valueField: 'companyName'},
        {name: 'Condition', type: 'select', field: SearchFilter.ConditionId, keyField: 'value', valueField: 'text', values: []},
        {name: 'Order Date', type: 'dateRange', field: SearchFilter.StartDate},
        {name: 'Backordered', type: 'select', field: SearchFilter.IsBackordered, values: [{text: 'Yes', value: 1}, {text: 'No', value: 0}]},
        {name: 'Repair', type: 'select', field: SearchFilter.IsRepair, values: [{text: 'Yes', value: 1}, {text: 'No', value: 0}]},
        {name: 'Orig Reference', type: 'text', field: SearchFilter.OrigReference},
        {name: 'Item Received', type: 'select', field: SearchFilter.IsReceived, values: [{text: 'Yes', value: 1}, {text: 'No', value: 0}]},
        {name: 'Manufacturer', type: 'select', field: SearchFilter.ManufacturerId, values: []},
        {name: 'Model', type: 'text', field: SearchFilter.PartNumber},
        {name: 'Order #', type: 'text', field: SearchFilter.OrderId},
        {name: 'Order Type', type: 'select', field: SearchFilter.OrderType},
        {name: 'PO #', type: 'text', field: SearchFilter.ShippingPo},
        {name: 'Ref #', type: 'text', field: SearchFilter.LineItemId},
        {name: 'Status', type: 'tags', field: SearchFilter.Status, keyField: 'status', valueField: 'statusText', values: status},
        {name: 'Order Status', type: 'tags', field: SearchFilter.OrderStatus, keyField: 'status', valueField: 'status', values: []},
        {name: 'Tracking #', type: 'text', field: SearchFilter.TrackingNumber},
        {name: 'Vendor Order #', type: 'text', field: SearchFilter.VendorOrderNumber},
        {name: 'Vendor Part #', type: 'text', field: SearchFilter.VendorPartNumber},
    ]);

    // const dispatch = useDispatch();

    useEffect(() => {
        loadSelectionLists(props);
    }, [props]);

    useEffect(() => {
        const tempTypes = [...types];

        const orderTypePosition = tempTypes.indexOf(_.find(tempTypes, {name: 'Order Type'}))
        const orderType = tempTypes[orderTypePosition];

        if (listData && Object.keys(listData).length === 0) return;

        orderType.values = listData.orderTypes.map((x) => {
            return {value: x.id, text: x.code};
        });
        tempTypes[orderTypePosition] = orderType;

        setTypes(tempTypes);
    }, [listData]);
    
    useEffect(() => {
        const tempTypes = [...types];

        const conditionTypePosition = tempTypes.indexOf(_.find(tempTypes, {name: 'Condition'}))
        const conditionTypeList = tempTypes[conditionTypePosition];

        if (listData && Object.keys(listData).length === 0) return;

        conditionTypeList.values = listData.conditions.map((x) => {
            return {value: x.id, text: x.description};
        });
        tempTypes[conditionTypePosition] = conditionTypeList;

        setTypes(tempTypes);
    }, [listData]);

    useEffect(() => {
        const tempTypes = [...types];

        const statusPosition = tempTypes.indexOf(_.find(tempTypes, {name: 'Status'}))
        const status = tempTypes[statusPosition];

        status.values = [];
        status.values = getAvailableStatuses();

        tempTypes[statusPosition] = status;

        getAvailableOrderStatuses();

        setTypes(tempTypes);

        let tempCriteria;  
        const cachedCriteria = JSON.parse(getCachedFilters());
                
        if (cachedCriteria && cachedCriteria.length > 0) {
            tempCriteria = [...cachedCriteria];

            tempCriteria.map((item) => {
                
                if (item.fieldInfo.type === 'dateRange') {
                    item.value.start = moment(item.value.start).toDate();
                    item.value.end = moment(item.value.end).toDate();
                }                
            });

        } else {
            const orderDateField = _.clone(_.find(types, {name: 'Order Date'}));
            let newCriteriaValue = setupNewFieldData(orderDateField);
            const newCriteria = {
                showSelection: true,
                name: orderDateField.name,
                fieldInfo: orderDateField,
                value: newCriteriaValue,
            };
            tempCriteria = _.clone(criteria);
            tempCriteria.push(newCriteria);
        }

        setCriteria(tempCriteria);

        // Ensure that the oems are loaded
        if ((oems || []).length === 0) {
            getOems();
        }

        loadSelectionLists();
        applyFilter(tempCriteria);
    }, []);

    const getAvailableStatuses = () => {

        const repairStatuses = buildStatuses(repairKeys);
        const orderStatuses = buildStatuses(orderKeys);

        let statuses = [];
        if (canSeeOrders) {
            statuses = _.unionBy(statuses, orderStatuses, x => x.status);
        }
        if (canSeeRepairs) {
            statuses = _.unionBy(statuses, repairStatuses, x => x.status);
        }

        return _.sortBy(statuses, x => x.status);
    }

    const getAvailableOrderStatuses = () => {
        const tempTypes = [...types];

        const orderStatusPosition = tempTypes.indexOf(_.find(tempTypes, {name: 'Order Status'}))
        const orderStatus = tempTypes[orderStatusPosition];

        orderStatus.values = [];

        axios.get(`order/statuses`)
            .then((x) => {
                orderStatus.values = x.data;
                tempTypes[orderStatusPosition] = orderStatus;
                setTypes(tempTypes);
            })
            .catch((x) => {
                // console.log('Get order statuses error')
            });
    }

    const loadSelectionLists = () => {
        let manufacturerList = _.find(types, {name: 'Manufacturer'});
        if (manufacturerList && manufacturerList.values && manufacturerList.values.length === 0) {
            manufacturerList.values = (_.sortBy(oems, x => x.name) || []).map(f => {
                return {text: f.name, value: f.id};
            });
        }
    }

    const deleteFilter = (filter) => {
        let newCriteria = _.clone(criteria);
        _.remove(newCriteria, {name: filter.name});
        setCriteria(newCriteria);
    }

    const updateFilterDateValue = (value, filter, name) => {
        const f = [...criteria];

        if (filter.fieldInfo.type === 'dateRange') {
            value = moment(value.value).toDate();
        }

        const elementsIndex = f.findIndex(x => x.fieldInfo.field === 'StartDate');

        if (name === 'start')
            f[elementsIndex].value.start = value;
        else if (name === 'end')
            f[elementsIndex].value.end = value;

        setCriteria([...f]);
    }

    const buildStatuses = (statuses) => statuses.map(x => {
        return {
            status: x.value,
            statusText: x.tabDisplay,
        }
    })

    const updateFilterValue = (value, filter) => {
        const f = [...criteria];

        const elementsIndex = f.findIndex(x => x.fieldInfo.field === filter.fieldInfo.field);

        f[elementsIndex].value = value;

        setCriteria([...f]);
    }

    const getCriteriaValue = (c) => {
        if (c.fieldInfo.type === 'tags') {
            let keys = Object.keys(c.value);

            if (c.value.All) {
                delete c.value.All;
                if (keys.length === 1) {
                    return null;
                }
            } else if (c.fieldInfo.field === 'RequesterId' && keys.length === 0) {
                return null;
            }
            return Object.keys(c.value).join('|');
        } else {
            return c.value;
        }
    }

    const applyFilter = (tempCriteria) => {
        const c = tempCriteria ? tempCriteria : criteria;

        if (!tempCriteria) {
            setCachedFilters(c);
        }

        let terms = c.filter(x => x.name && x.value)
            .map(z => ({field: z.fieldInfo.field, value: getCriteriaValue(z)}));

        let orderDateItems = _.remove(terms, {field: SearchFilter.StartDate});

        if (orderDateItems && orderDateItems.length > 0) {
            const {start, end} = orderDateItems[0].value;
            if (start) {
                terms.push({field: SearchFilter.StartDate, value: moment(start).format('yyyy-M-D')});
            }
            if (end) {
                terms.push({field: SearchFilter.EndDate, value: moment(end).format('yyyy-M-D')});
            }
        }

        let deliveredFilter = _.remove(terms, {field: SearchFilter.Status, value: '100'});
        if (deliveredFilter && deliveredFilter.length > 0) {
            terms.push({field: SearchFilter.IsReceived, value: true})
        }

        let backOrderedFilter = _.remove(terms, {field: SearchFilter.Status, value: '101'});
        if (backOrderedFilter && backOrderedFilter.length > 0) {
            terms.push({field: SearchFilter.IsBackordered, value: true})
        }

        onApply(terms);
    }

    const setupNewFieldData = (fieldInfo) => {
        if (fieldInfo.type === 'dateRange') {
            return {
                end: moment().toDate(),
                start: moment().subtract(1, 'month').toDate(),
            };
        } else if (fieldInfo.type === 'tags') {
            return {};
        } else if (fieldInfo.type === 'select') {
            return fieldInfo.values[0].value;
        }
        return null;
    }

    const onFilterTypeChange = (event, filter, index) => {
        const fieldName = event.target.value;
        const fieldInfo = _.find(types, {name: fieldName});

        let newCriteria = _.clone(criteria);
        let newFilter = _.clone(filter);

        newFilter.name = fieldName;
        newFilter.fieldInfo = fieldInfo;
        newFilter.value = setupNewFieldData(fieldInfo);

        newCriteria[index] = newFilter;
        setCriteria(newCriteria);
    }

    const addNewFilterRow = () => {
        const newField = {
            showSelection: true,
            name: null,
            fieldInfo: null,
            value: null,
        };

        let newCriteria = _.clone(criteria);
        newCriteria.push(newField);

        setCriteria(newCriteria);
    }

    const renderTypesDropdown = (filter, index) => {
        const options = types
            .filter(t => t.name === filter.name || !_.find(criteria, {name: t.name}))
            .map(f => ({text: f.name, value: f.name}));

        let selectedValue = filter && filter.name === null ? '' : filter && filter.name;

        return (<Dropdown
            id="filterTypes"
            name="filterTypes"
            placeholder="Filter"
            label="Filter"
            options={options}
            onChange={e => onFilterTypeChange(e, filter, index)}
            selectedValue={selectedValue}
            required={true}
        />);
    }

    const renderView = (c) => {
        const elementIndex = criteria.indexOf(x => x.name === c.name)
        let filter = c;
        if (elementIndex >= 0)
            filter = c[elementIndex];

        if (filter && filter.fieldInfo && filter.fieldInfo.type) {
            switch (filter.fieldInfo.type) {
                case 'tags':
                    return (<div className="row">
                        <div className="col-md-12">
                            <TextFieldTags
                                placeHolder={`Add a ${filter.name}`}
                                value={filter.value}
                                listItems={filter.fieldInfo.values}
                                keyField={filter.fieldInfo.keyField || 'text'}
                                valueField={filter.fieldInfo.valueField || 'value'}
                                onChange={value => updateFilterValue(value, filter)}
                                useMultiSelect={true}
                            />
                        </div>
                    </div>);

                case 'text':
                    return (<div className="row">
                        <div className="col-md-12">
                            <TextField
                                key={filter.fieldInfo.name}
                                text={filter.value}
                                onChange={event => updateFilterValue(event.target.value, filter)}
                                label={filter.name}
                                placeholder={filter.name} />
                        </div>
                    </div>);

                case 'select':
                    return (<div className="row">
                        <div className="col-md-12">
                            <Dropdown key={filter.fieldInfo.name}
                                label={filter.name}
                                onChange={event => updateFilterValue(event.target.value, filter)}
                                options={filter.fieldInfo.values}
                                selectedValue={filter.value}
                                required={true} />
                        </div>
                    </div>);

                case 'dateRange':
                    return (<div className="row mobile-row">
                        <div className="col-md-6">
                            <DatePicker onChange={(date) => updateFilterDateValue(date, filter, 'start')}
                                value={filter.value.start} placeholder="From" />
                        </div>
                        <div className="col-md-6">
                            <DatePicker onChange={(date) => updateFilterDateValue(date, filter, 'end')}
                                value={filter.value.end} placeholder="To" />
                        </div>
                    </div>)
            }
        }
        return null;
    }

    const renderFilter = (filter, index) => {
        return (
            <div key={index} className="search-criteria_row row">
                <div className="search-crteria_row_types col-xs-12 col-sm-12 col-md-3">{renderTypesDropdown(filter, index)}</div>

                <div className="search-crteria_row_view col-xs-12 col-sm-12 col-md-7">
                    {renderView(filter)}
                </div>

                <div className="search-criteria_row--delete">
                    <span style={{cursor: 'pointer'}}
                        onClick={() => deleteFilter(filter)}>
                        <DeleteFilterIcon icon={faTrash} />
                    </span>
                </div>
            </div>
        );
    }

    return (<div className="search-criteria">
        <div className="container-fluid">
            {criteria.map((item, index) => renderFilter(item, index))}
        </div>
        <div className="container-fluid search-criteria_buttons">
            <AddFilterButton onClick={addNewFilterRow} className="button button-secondary add-filter">
                <AddFilterIcon icon={faPlus} />
                ADD FILTER
            </AddFilterButton>
            <ApplyFiltersButton onClick={() => applyFilter()} secondary={true}>Apply Filters</ApplyFiltersButton>
        </div>
    </div>)
}

export default SearchCriteria;

SearchCriteria.propTypes = {
    onApply: PropTypes.func.isRequired,
    getOems: PropTypes.func,
    params: PropTypes.shape({
        wo: PropTypes.string,
        wo_id: PropTypes.string,
    }),
    filters: PropTypes.arrayOf(PropTypes.shape({})),
    oems: PropTypes.arrayOf(PropTypes.shape({})),
    network: PropTypes.shape({
        initialPage: PropTypes.shape({}),
        tokenInfo: PropTypes.string,
    }),
}
