export const GridKeys = [
    {
        cardAction: 'AwaitingReview',
        fieldName: 'status',
        id: 'AwaitingReview',
        tabDisplay: 'Awaiting Review',
        value: 'AWAITINGREVIEW',
    }, {
        cardAction: 'PendingShipment',
        fieldName: 'status',
        id: 'PendingShipment',
        tabDisplay: 'Pending Shipment',
        value: 'PENDINGSHIPMENT',
    }, {
        cardAction: 'Shipped',
        fieldName: 'status',
        id: 'Shipped',
        tabDisplay: 'Shipped',
        value: 'SHIPPED',
    }, {
        cardAction: 'Delivered',
        fieldName: 'status',
        id: 'Delivered',
        tabDisplay: 'Delivered',
        value: 'DELIVERED',
    },
];
