import React from 'react';
import * as PropTypes from 'prop-types';
import {faExclamationCircle} from '@fortawesome/pro-regular-svg-icons';
import {Accordion} from '@partssourceinc/react-ui-core';
import styled, {css} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ErrorType} from 'hooks/useForm';
import _ from 'lodash';

const StyledAccordion = styled(Accordion)`
    @media (min-width: 1150px) {
        max-width: 50%;
    }
`;

const StyledTitle = styled.span`
    display: inline-flex;
    align-items: center;
`;

const Error = styled.span`
    display: inline-flex;
    align-items: center;
    
    ${props => props.errorType === ErrorType.Required && css`
        color: #ff9505;
    `}

    ${props => props.errorType === ErrorType.Invalid && css`
        color: #ff0000;
    `}
`;

const ErrorIcon = styled(FontAwesomeIcon)`
    width: 24px;
    height: 24px;
    margin-left: 5px;
    margin-right: 5px;
`;

const AccordionSection = (props) => {
    const {title, children, errors, ...rest} = props;
    const compactErrors = _.uniq(_.compact(errors));
    let errorType = ErrorType.None;

    if (compactErrors.length)
        errorType = compactErrors.some(e => e === ErrorType.Invalid) ? ErrorType.Invalid : ErrorType.Required;

    const renderTitle = () => {
        return (<StyledTitle>
            {title}
            {errorType && <Error errorType={errorType}>
                <ErrorIcon icon={faExclamationCircle} />
                <span>{errorType === ErrorType.Required ? `Incomplete` : `Error`}</span>
            </Error>}
        </StyledTitle>);
    };

    return (
        <StyledAccordion title={renderTitle()} {...rest}>
            {children}
        </StyledAccordion>
    );
};

AccordionSection.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    children: PropTypes.node.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
};

export default AccordionSection;
