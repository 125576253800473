import React from 'react';
import PropTypes from 'prop-types';
import {Calendar} from 'primereact/calendar';
import styled from 'styled-components';
import 'components/css/primereact.css';
import 'components/css/theme.css';
import 'components/css/Calendar.css';
import 'components/css/multiGrid.css';
import 'components/css/MultiSelect.css';
import 'components/css/grid.css';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const CalendarComponent = styled(Calendar)`
    width: 280px;
    height: 50px;
`;

const DatePicker = (props) => {
    const {
        placeholder,
        maxDate,
        minDate,
        value,
        onChange,
        onHide,
        style,
    } = props;

    const handleDateChange = async (e) => {
        await onChange(e);
    }

    return (
        <CalendarComponent style={style}
            dateFormat="mm/dd/yy"
            placeholder={placeholder}
            onChange={(e) => handleDateChange(e)}
            minDate={minDate}
            maxDate={maxDate}
            onHide={onHide}
            value={value}
            showIcon={true}
        />
    );
};

export default DatePicker;

DatePicker.propTypes = {
    placeholder: PropTypes.string,
    style: PropTypes.object,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.bool]),
    onChange: PropTypes.func,
    onHide: PropTypes.func,
};
