import React, {useState} from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import NotesModal from 'components/NotesModal';
import {Context} from 'utils/enums';

const ExchangesReturnsCardDescription = (props) => {
    const {cardData} = props;
    const [showNotes, setShowNotes] = useState(false);

    return (
        <>
            <span className="detail">
                Request Date: <span>{moment(cardData.rgaDateEntered).format('MM/DD/YYYY')}</span>
            </span>

            {cardData.isApproved === 'Y' && cardData.dateReturnApprovedDenied &&
                <span className="detail">
                    Approved Date: <span>{moment(cardData.dateReturnApprovedDenied).format('MM/DD/YYYY')}</span>
                </span>
            }

            {cardData.isApproved === 'N' && cardData.dateReturnApprovedDenied && 
                <>
                    <span className="detail">
                    Denied Date: <span>{moment(cardData.dateReturnApprovedDenied).format('MM/DD/YYYY')}</span>
                    </span>

                    <span className="detail">
                    Denied Reason: <span>{cardData.returnDenyReason}</span>
                    </span>
                </>
            }
              
            <span className="detail">
                RGA Type: <span>{cardData.rgaType === 'RETURN' ? 'Return' : 'Exchange'}</span>
            </span>
           
            <span className="detail">
                Qty: <span>{cardData.quantity}</span>
            </span>

            <span className="detail">
                Item #: <span>{cardData.partNumber}</span>
            </span>

            <span className="detail">
                RMA #: <span>{cardData.rmaNumber}</span>
            </span>

            <span className="detail">
                PO #: <span>{cardData.displayPo}</span>
            </span>

            <span className="detail">
                Condition: <span>{cardData.condition}</span>
            </span>

            {cardData.rgaType === 'RETURN' &&
                <>
                    <span className="detail">
                        Return Status: <span>{cardData.nonReturnable ? 'Not Returnable' : 'Returnable'}</span>
                    </span>
                    <span className="detail">
                        Return Reason: <span>{cardData.rgaReason}</span>
                    </span>
                    <span className="detail">
                        Box Opened: <span>{cardData.boxOpened ?? 'N/A'}</span>
                    </span>
                    <span className="detail">
                        Package Opened: <span>{cardData.packageOpened ?? 'N/A'}</span>
                    </span>
                    <span className="detail">
                        Is It Working: <span>{cardData.isItWorking ?? 'N/A'}</span>
                    </span>
                </>
                 
            }
            <span className="detail link" onClick={() => setShowNotes(true)}>
                Notes{`(${cardData.rgaNotes.length})`}
            </span>
            <NotesModal notes={cardData.rgaNotes} 
                lineItemId={cardData.lineItemId} 
                show={showNotes} 
                onCancel={() => setShowNotes(false)} 
                onSubmit={() => {}}
                isReadOnly={true} 
                context={Context.RETURN} />
        </>);
}

ExchangesReturnsCardDescription.propTypes = {
    cardData: PropTypes.shape({
        company: PropTypes.string,
        condition: PropTypes.string,
        displayPo: PropTypes.string,
        manufacturer: PropTypes.string,
        nonReturnable: PropTypes.bool,
        partNumber: PropTypes.string,
        quantity: PropTypes.number,
        rgaId: PropTypes.number,
        rgaNumber: PropTypes.string,
        rgaType: PropTypes.string,
        warranty: PropTypes.string,
        rgaReason: PropTypes.string,
        rmaNumber: PropTypes.string,
        lineItemId: PropTypes.number,
        rgaDateEntered: PropTypes.string,
        returnDenyReason: PropTypes.string,
        isApproved: PropTypes.string,
        dateReturnApprovedDenied: PropTypes.datetime,
        boxOpened: PropTypes.string,
        packageOpened: PropTypes.string,
        isItWorking: PropTypes.string,
        rgaNotes: PropTypes.array,
    }),
}

export default ExchangesReturnsCardDescription;
