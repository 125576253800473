import React, {useState, useEffect} from 'react';
import {TextField, Button, brandFont, Dropdown, Checkbox} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import _ from 'lodash';
import {useSelector, useDispatch} from 'react-redux';
import {setDefaultHomePage, setDefaultRefreshRate, loadMfaOptin, saveMfaOptin} from 'reducers/user';
import FormFooter from 'components/FormFooter';
import PasswordExpiredDialog from 'components/PasswordExpiredDialog';
import {GenericAccountAccessMessage} from 'utils/enums';

const SettingsLanding = styled.div`
    h1 {
        margin-bottom: 3rem;
    }
`;

const MFAWrapper = styled.div`
    margin: 30px 0 20px 0;        

    .mfa-desc {
        font-size: 16px;
        font-weight: normal;
        margin: 15px 0 5px 0;        
        display: block;
    }

    .mfa-error {
        display: block;
        font-size: 16px;
        color: #FF0000;
        font-weight: 600;
    }
`;

const PasswordEye = styled(FontAwesomeIcon)`
    width: 19px;
    float: right;
    opacity: 0.5;
    margin-top: -30px;
    margin-right: 5px;
    cursor: pointer;
`;

const PasswordFieldWrapper = styled.div`
    width: 280px;
    margin-top: 20px;
`;

const PersonalInfo = styled.div`
    ${brandFont('16px')}
    margin-bottom: 10px;
`;

const PersonalData = styled.span`
    padding-left: 5px;
    font-weight: bolder;
`;

const Check = styled(FontAwesomeIcon)`
    color: green;
    margin-left: 5px;
`;

const PassWarning = styled.div`
    margin-top: 15px;
    max-width: 509px;
    ${brandFont('16px')}
`;

const DropdownList = styled(Dropdown)`
    max-width: 28em;
    margin-bottom: 10px;
`;

const ApiResult = {
    SUCCESS: 0,
    CONTACT_NOT_FOUND: 1,
    CURRENT_PASSWORD_INCORRECT: 2,
    TECHNICAL_PROBLEM: 3,
};

const Profile = (props) => {
    const user = useSelector(state => state.user);
    const companySettings = useSelector(state => state.settings.companySettings);
    const menuSettings = useSelector(state => state.settings.menuSettings);
    const facilities = useSelector(state => state.settings.facilities);
    const oktaAuthentication = useSelector(state => state.settings.siteSettings?.oktaAuthentication === 'Y');
    const [defaultHomePage, setHomePage] = useState(user.defaultHomePage);
    const [defaultRefreshRate, setRefreshRate] = useState(user.defaultRefreshRate);
    const [showCurrPass, setShowCurrPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showRepeatPass, setShowRepeatPass] = useState(false);
    const [currentPass, setCurrPass] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [currentPassEmpty, setCurrentEmpty] = useState(false);
    const [passError, setPassError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [newPass, setNewPass] = useState('');
    const [repeatPass, setRepeatPass] = useState('');
    const [passChangeResult, setPassChangeResult] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [showPasswordExpiredDialog, setShowPasswordExpiredDialog] = useState(user.isPasswordExpired);
    const [optInErrorMsg, setOptInErrorMsg] = useState('');
    const [initialMfaOptInEnabled, setInitialMfaOptInEnabled] = useState(null);
    const [mfaOptInEnabled, setMfaOptInEnabled] = useState(false);

    const dispatch = useDispatch();
    const landingPages = [];

    useEffect(() => {
        loadMfaOptinSetting();
    }, []);

    landingPages.push({
        value: 0,
        text: 'Home',
    });

    if (menuSettings?.find(c => c.id === '122' && c.name === 'Y') && facilities.length > 0)
        landingPages.push({
            value: 122,
            text: 'Bids',
        });
    if (companySettings.vendorAccountInformation === 'Y')
        landingPages.push({
            value: 99,
            text: 'Company Profile',
        }); 
    if (menuSettings?.find(c => c.id === '123' && c.name === 'Y') && facilities.length > 0)
        landingPages.push({
            value: 123,
            text: 'Orders',
        });            
    if (menuSettings?.find(c => c.id === '124' && c.name === 'Y') && facilities.length > 0)
        landingPages.push({
            value: 124,
            text: 'Repairs',
        }); 
    if (menuSettings?.find(c => c.id === '125' && c.name === 'Y') && facilities.length > 0)
        landingPages.push({
            value: 125,
            text: 'Exchanges & Returns',
        });
    if (menuSettings?.find(c => c.id === '134' && c.name === 'Y') && facilities.length > 0)
        landingPages.push({
            value: 134,
            text: 'Loaners',
        });                 
    if (menuSettings?.find(c => c.id === '98' && c.name === 'Y') && facilities.length > 0)
        landingPages.push({
            value: 98,
            text: 'Performance Dashboard',
        });
    if (companySettings.allowAccessBrandedECommDashboard === 'Y')
        landingPages.push({
            value: 158,
            text: 'Branded eCommerce Dashboard',
        });
    if (companySettings.allowAccessMktInsightsDashboard === 'Y')
        landingPages.push({
            value: 157,
            text: 'Marketing Insights Dashboard ',
        });
    if (menuSettings?.find(c => c.id === '159' && c.name === 'Y') && facilities.length > 0)
        landingPages.push({
            value: 159,
            text: 'Resources Overview',
        });
                          
    const refreshRates = [
        {
            value: 1,
            text: '1',
        }, 
        {
            value: 2,
            text: '2',
        }, 
        {
            value: 3,
            text: '3',
        }, 
        {
            value: 4,
            text: '4',
        }, 
        {
            value: 5,
            text: '5',
        }, 
        {
            value: 10,
            text: '10',
        },  
        {
            value: 15,
            text: '15',
        },
        {
            value: 20,
            text: '20',
        },
        {
            value: 25,
            text: '25',
        },  
        {
            value: 30,
            text: '30',
        },    
    ]

    if (isSaved) {
        updateReduxStates();
    }
    
    const passChange = ({target: {name, value}}) => {
        switch (name) {
            case 'currentPass':
                setCurrPass(value.trim());
                break;
            case 'newPass':
                setNewPass(value.trim());
                break;
            case 'repeatPass':
                setRepeatPass(value.trim());
                break;
        }
        setShowSuccessMessage(false);
    }
        
    const submitPassword = async (e) => {
        e.preventDefault();

        if (currentPass === null || currentPass === '') {
            setCurrentEmpty(true);
            return;
        }
        if (newPass.length < 5 || newPass.length > 17) {
            setPassError(true);
            setCurrentEmpty(false);
            setPassChangeResult('');
            setErrorMessage('New password must be between 5 and 17 characters long.');
        } else if (newPass !== repeatPass) {
            setPassError(true);
            setCurrentEmpty(false);
            setPassChangeResult('');
            setErrorMessage('The new password and password confirmation do not match');
        } else if (currentPass === newPass) {
            setPassError(true);
            setCurrentEmpty(false);
            setPassChangeResult('');
            setErrorMessage('The new password cannot match the current password');
        } else if (newPass && newPass !== '') {
            const info = {};
            info.newPassword = newPass;
            info.email = user.userId;

            setPassError(false);
            setIsLoading(true);
            setErrorMessage('');

            try {
                const {data: {result}} =
                    await axios.post(`user/Password/ChangePassword`, {oldPassword: currentPass, newPassword: newPass});
                setIsLoading(false);

                switch (result) {
                    case ApiResult.SUCCESS: {
                        setShowSuccessMessage(true);
                        setCurrentEmpty(false);
                        setCurrPass('');
                        setNewPass('');
                        setRepeatPass('');
                        setPassChangeResult('Password changed successfully.');
                        break;
                    }
                    case ApiResult.CURRENT_PASSWORD_INCORRECT: {
                        showSuccessMessage(false);
                        setCurrentEmpty(true);
                        setPassChangeResult('The current password is incorrect.');
                        break;
                    }
                    case ApiResult.TECHNICAL_PROBLEM: {
                        showSuccessMessage(false);
                        setCurrentEmpty(false);
                        setPassError(false);
                        setPassChangeResult('Technical problem Password not changed.');
                        break;
                    }
                    case ApiResult.CONTACT_NOT_FOUND: {
                        setShowSuccessMessage(false);
                        setCurrentEmpty(false);
                        setPassError(false);
                        setPassChangeResult('The User is not found.');
                        break;
                    }
                    default: {
                        setShowSuccessMessage(false);
                        setPassChangeResult(GenericAccountAccessMessage);
                        break;
                    }
                }
            } catch {
                setIsLoading(false);
                setShowSuccessMessage(false);
                setPassChangeResult(GenericAccountAccessMessage);
            }
        }
    }

    const handleChangeDefaultPage = (event) => { 
        if (event.target.value !== '') {              
            setHomePage(parseInt(event.target.value))  
        } else {
            setHomePage(null)
        }       
    };

    const handleChangeDefaultRefreshRate = (event) => {        
        setRefreshRate(event.target.value)            
    };
    
    const handleOnSubmit = async (e) => {
        e.preventDefault();
                             
        setIsLoading(true);

        const userDefaults = {
            defaultHomePage: defaultHomePage,
            defaultRefreshRate: defaultRefreshRate,
        }
        if (initialMfaOptInEnabled !== mfaOptInEnabled) {
            try {
                const response = await dispatch(saveMfaOptin(mfaOptInEnabled));
                const {data: {messages, result}} = response;
    
                if (result !== 'Success') {
                    setOptInErrorMsg(messages.join());
                } else {
                    setMfaOptInEnabled(mfaOptInEnabled);
                }
            } catch {
                setOptInErrorMsg('An error ocurred while retrieving setting.');
                setMfaOptInEnabled(false);
            }
        }

        await axios.post(`/setting/default`, userDefaults);
            
        setIsSaved(true);
        setIsLoading(false);    
    };

    function updateReduxStates() {
        setIsSaved(false);
        
        dispatch(setDefaultHomePage(defaultHomePage));
        dispatch(setDefaultRefreshRate(defaultRefreshRate));
    }

    const loadMfaOptinSetting = async () => {        
        try {
            const response = await dispatch(loadMfaOptin());
            const {data: {messages, result, value}} = response;

            if (result !== 'Success') {
                setOptInErrorMsg(messages.join());
            } else {
                setMfaOptInEnabled(value);
                setInitialMfaOptInEnabled(value);
            }
        } catch {
            setOptInErrorMsg('An error ocurred while retrieving setting.');
            setMfaOptInEnabled(false);
        }
    }
        
    return (
        <SettingsLanding>
            <h1>My Profile</h1>
            <PersonalInfo>
                Name:
                <PersonalData>
                    {_.capitalize(user.firstName)} {_.capitalize(user.lastName)}
                </PersonalData>
                <br />
                Email:
                <PersonalData>
                    {user.email}
                </PersonalData>
            </PersonalInfo>
            <div>
                <h2>Change Password</h2>
                <PasswordFieldWrapper>
                    <TextField id="persSettingCurrPw"
                        name="currentPass"
                        type={showCurrPass ? 'text' : 'password'}
                        placeholder="Current Password"
                        label="Current Password"
                        text={currentPass}
                        showErrorMessage={currentPassEmpty}
                        onBlur={passChange}
                        tabIndex={1}
                        autocomplete="new-password" />
                    {<PasswordEye aria-hidden="true" onClick={() => setShowCurrPass(!showCurrPass)} icon="eye-slash" />}
                </PasswordFieldWrapper>
                <PasswordFieldWrapper>
                    <TextField
                        name="newPass"
                        type={showNewPass ? 'text' : 'password'}
                        placeholder="New Password"
                        label="New Password"
                        text={newPass}
                        showErrorMessage={passError}
                        onBlur={passChange}
                        tabIndex={2} />
                    {<PasswordEye aria-hidden="true" onClick={() => setShowNewPass(!showNewPass)} icon="eye-slash" />}
                </PasswordFieldWrapper>
                <PassWarning>{errorMessage}</PassWarning>
                <PasswordFieldWrapper>
                    <TextField
                        name="repeatPass"
                        type={showRepeatPass ? 'text' : 'password'}
                        placeholder="Confirm New Password"
                        label="Confirm New Password"
                        className="security-input"
                        text={repeatPass}
                        showErrorMessage={passError}
                        onBlur={passChange}
                        tabIndex={3} />
                    {<PasswordEye aria-hidden="true" onClick={() => setShowRepeatPass(!showRepeatPass)} icon="eye-slash" />
                    }
                </PasswordFieldWrapper >

                <PassWarning>
                    {passChangeResult}
                </PassWarning>

                <div style={{'marginTop': '20px'}}>
                    <Button secondary={true} tabIndex={4} loading={isLoading} type="submit" onClick={submitPassword}>CHANGE PASSWORD
                        {showSuccessMessage && <Check aria-hidden="true" icon={faCheck} />}
                    </Button >
                </div>  
                {oktaAuthentication && <MFAWrapper>
                    <h2>Multi-Factor Authentication</h2>
                    {optInErrorMsg && <span className="mfa-error">An Error Occurred: {optInErrorMsg}</span>}
                    <span className="mfa-desc">Checking the box below will enable your account to be secured by an additional factor. 
                        You’ll be prompted for enrollment during your next login.</span>
                    <Checkbox
                        label="Turn on MFA for My Account"
                        checked={mfaOptInEnabled}
                        onChange={(e) => setMfaOptInEnabled(e.checked)}
                    />
                </MFAWrapper>}                           
            </div> 
            <div>
                <br />
                <h2>Default Preferences</h2>
                {landingPages.length > 0 &&
                    <DropdownList label="Default Landing Page" onChange={handleChangeDefaultPage}
                        id="defaultHomePage"
                        options={landingPages.sort((a, b) => (a.text > b.text) ? 1 : -1)} selectedValue={defaultHomePage}
                        suppressBlankOption={false} valueField="value" textField="text" />
                }
                <DropdownList label="Refresh Timer" onChange={handleChangeDefaultRefreshRate}
                    id="defaultRefreshRate"
                    options={refreshRates} selectedValue={defaultRefreshRate} suppressBlankOption={false} valueField="value" textField="text" />
            </div>
            <PasswordExpiredDialog show={showPasswordExpiredDialog} onCallback={() => setShowPasswordExpiredDialog(false)} />
            <FormFooter handleOnSubmit={handleOnSubmit} loading={isLoading} />          
        </SettingsLanding>);
};

export default Profile;
