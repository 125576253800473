import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StandardInfoCard from 'components/StandardInfoCard';
import ShippingInfoCardV2 from 'components/ShippingInfoCardV2';
import moment from 'moment';
const OrderFooterContainer = styled.div`
    width: 100%;    
    padding-top: 10px;  
    height: 100px;
    background-color: rgb(241, 241, 241);
    margin-left: 0;     
    padding-left: 0;  
`;

const CardContainer = styled(OrderFooterContainer)`
    display: inline-block;
    div {
        position: relative;
        bottom: 0!Important;
    }  

    @media (max-width: 1149px) {
                
        ul {
            margin-top: 0!Important;
        }       
    }   
`;

const ShippingInfoCardDetails = styled(ShippingInfoCardV2)`
    @media (max-width: 1149px) {
        
        margin-left: 0;
        margin-right: 0;        
    }
`;

const StandardInfoCardDetails = styled(StandardInfoCard)`
    @media (max-width: 1149px) {
        
        margin-left: 0;
        margin-right: 0;        
    }
`;

const ExchangeReturnFooter = (props) => {
    const {
        lineItem = {},
    } = props;
    let infoTitle = '';
    let infoSubtext = '';
    if (lineItem?.rgaType === 'RETURN') {
        infoTitle = lineItem.dateReturnApprovedDenied ?
            `Return ${lineItem.isApproved === 'N' ? 'Denied' : 'Approved'} ${moment(lineItem.dateReturnApprovedDenied).format('MM/DD/YYYY')}`
            : 'Return Requested';
        infoSubtext = lineItem.dateReturnApprovedDenied ? 'Pending PartsSource Review' : 'Awaiting Vendor Approval'
    } else {
        infoTitle = 'Exchange Requested';
        infoSubtext = 'Awaiting Customer Shipment';
    }

    return (
        <React.Fragment>
            <CardContainer>
                {lineItem.returnExchangeStatus === 'Process' &&                    
                    <StandardInfoCardDetails 
                        title={infoTitle} 
                        subText={infoSubtext} />}
                {lineItem.returnExchangeStatus === 'Pending' &&               
                    <ShippingInfoCardDetails showDateInfo={false} showTrackingInfo={true} 
                        title="Pending" 
                        subText="Awaiting Customer Shipment"
                        additionalText={lineItem.shippingAddress} 
                        trackingNumber={lineItem.trackingNumber} 
                        trackingText={lineItem.shippingMethod ? lineItem.shippingMethod : lineItem.shipper} />}
                {lineItem.returnExchangeStatus === 'ShippedInbound' &&               
                    <ShippingInfoCardDetails showDateInfo={true} showTrackingInfo={true} 
                        title="Scheduled Delivery Date"
                        subText={lineItem.shippingAddress} 
                        date={lineItem.estimatedDelivery} 
                        trackingNumber={lineItem.trackingNumber} 
                        trackingText={lineItem.shippingMethod ? lineItem.shippingMethod : lineItem.shipper} 
                        noDateText="Pending" noTimeText="End of Day" />}
                {(lineItem.returnExchangeStatus === 'Received' || lineItem.returnExchangeStatus === 'Inspected') &&               
                    <ShippingInfoCardDetails showDateInfo={true} showTrackingInfo={true} isComplete={true}
                        title="Delivered"
                        subText={lineItem.shippingAddress} 
                        date={lineItem.dateDelivered} 
                        trackingNumber={lineItem.trackingNumber} 
                        trackingText={lineItem.shippingMethod ? lineItem.shippingMethod : lineItem.shipper} 
                        noDateText="On Unknown Date" />}
                {(lineItem.returnExchangeStatus === 'PendingPartsSource') &&               
                    <ShippingInfoCardDetails showDateInfo={false} showTrackingInfo={false} isComplete={false}
                        title={`Supplier Requested Information 
                            ${moment(lineItem.statusUpdatedTimestamp ?? lineItem.updatedTimestamp).format('MM/DD/YYYY')}`} 
                        subText={'PartsSource is working on your requested information'} 
                        noDateText="On Unknown Date" />}
             
            </CardContainer>
        </React.Fragment>
    );
};

export default ExchangeReturnFooter;

ExchangeReturnFooter.propTypes = {
    rga: PropTypes.shape({}),
    lineItem: PropTypes.shape({}),
};
