import axios from 'axios';
import _ from 'lodash';

const GET_LIST_DATA_SUCCESS = 'GET_LIST_DATA_SUCCESS';
const SET_SELECTED_BID_TAB = 'SET_SELECTED_BID_TAB';
const SET_SELECTED_BID_VIEW = 'SET_SELECTED_BID_VIEW';
const SET_SELECTED_BID = 'SET_SELECTED_BID';
const SET_CACHED_BIDS = 'SET_CACHED_BIDS';

const ERROR = 'ERROR';

export const defaultState = {    
    listData: {},
    selectedBidTab: '',
    selectedBidView: '',
    selectedBid: {}, 
    cachedBids: [],   
};

const reducer = (state = _.cloneDeep(defaultState), action) => {
    
    switch (action.type) {      
                        
        case GET_LIST_DATA_SUCCESS: {             
            
            return {...state, listData: action.listData};            
        }
        case SET_SELECTED_BID_TAB: {
            
            const {selectedBidTab} = action;            
            return {...state, selectedBidTab};
        }
        case SET_SELECTED_BID: {
            
            const {selectedBid} = action;            
            return {...state, selectedBid};
        }
        case SET_CACHED_BIDS: {
            
            const {cachedBids} = action;            
            return {...state, cachedBids};
        }
        case SET_SELECTED_BID_VIEW: {
            
            const {selectedBidView} = action;            
            return {...state, selectedBidView};
        }
                            
        case ERROR:
        default:
            return state;
    }
}

export function getListData() {
    return (dispatch, getState) => {
        return axios
            .get('/common/listData')
            .then(({data: listData}) => dispatch({type: GET_LIST_DATA_SUCCESS, listData}))
            .catch(error => dispatch({type: ERROR, error}));
    };
}

export function setSelectedBidTab(selectedBidTab) {    
    return (dispatch) => dispatch({type: SET_SELECTED_BID_TAB, selectedBidTab});
}

export function setSelectedBidView(selectedBidView) {    
    return (dispatch) => dispatch({type: SET_SELECTED_BID_VIEW, selectedBidView});
}

export function setSelectedBid(selectedBid) {    
    return (dispatch) => dispatch({type: SET_SELECTED_BID, selectedBid});
} 

export function setCachedBids(cachedBids) {              
    return (dispatch) => dispatch({type: SET_CACHED_BIDS, cachedBids});
} 

export default reducer;
