import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    max-width: 495px;
    padding: 48px 0 96px 0;
    margin: 0 auto;
    text-align: center;
`;

const IconHolder = styled.div`
    margin-bottom: 16px;    

    img {
        vertical-align: middle;
    }
`;

const Title = styled.h1`
    margin-bottom: 16px;
`;

const ResponseText = styled.p`
    font-size: 16px;

    a {
        color: #4a4a4a;
        text-decoration: underline;
    }

    a:hover {
        color: #777;
    }
`;

const HubSpotSubmitRequest = () => {
    return (
        <Wrapper>
            <IconHolder>
                <img src={`/images/check_mark.png`} />
            </IconHolder>
            <Title>Your request has been submitted</Title>
            <ResponseText>
                Your account request has been sent to PartsSource for Suppliers. If you have questions, 
                please contact <a href="mailto:supplierportalsupport@partssource.com" rel="noopener">supplierportalsupport@partssource.com</a> or 
                call <a href="tel:+1877-497-6412">877-497-6412</a> and ask for the Supplier Pro representatives.
            </ResponseText>
        </Wrapper>
    );
} 

export default HubSpotSubmitRequest;
