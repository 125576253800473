import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import ReportingPortalHeaderPowerBi from './ReportingPortalHeaderPowerBi';
import {theme} from '@partssourceinc/react-ui-core';
import 'components/css/reportingportal.css';
import ReportingPortalDashboardItemPowerBi from './ReportingPortalDashboardItemPowerBi';
import axios from 'axios';
import {updateUserPortalWidgetsOrder} from './../../reducers/user';

const MultiGridPage = styled.div.attrs({className: 'multi-grid-page'})`
    width:100%;
    background: #f1f1f1;
    margin: 0 -20px; 
    padding: 0 20px;
    min-height: calc(100vh - 592px);
    @media screen and (min-width: ${theme.screenXLMin}) {
        margin: 0 -50px; 
        padding: 0 50px;
    }
    @media screen and (max-width: ${theme.screenLGMax}) {
        margin: 0 -50px; 
        padding: 0 50px;
    }
    @media screen and (max-width: ${theme.screenMDMax}) {
        margin: 0 -20px; 
        padding: 0 20px;
    }
    @media screen and (max-width: ${theme.screenSMMax}) {
        margin: 0 -20px; 
        padding: 0 20px;
    }
    @media screen and (max-width: ${theme.screenXsMax}) {
        margin: 0 -20px; 
        padding: 0 20px;
    }
    @media screen and (max-width: 320px) {
        margin: 0 -20px; 
        padding: 0 20px;
    }
`;

const ReportingPortalDashboardPowerBi = () => {
    const [widgets, setWidgets] = useState([]);
    const portal = useSelector(state => state.user.userDashboardSettings.data.portal);
    const rootCompanyId = useSelector(state => state.user.companyId);
    const dispatch = useDispatch();
    
    const saveWidgetOrder = (items) => {
        let req = _.cloneDeep(portal);
        req.widgets = items;
        axios.post('reporting-portal/user-portal-widgets/reorder', req)
    }

    const updateWidgetsOrder = async (items) => {
        setWidgets(items);
        saveWidgetOrder(items);
        dispatch(updateUserPortalWidgetsOrder(items));
    }
      
    useEffect(() => {
        setWidgets(portal.widgets);
    }, [portal, portal.widgets]);

    return (<MultiGridPage>
        {
            _.isNil(rootCompanyId) && (
                <div style={{height: '400px'}} className="loading-dashboard">
                    <div className="loader" />
                </div> 
            )
        }
        {
            !_.isNil(rootCompanyId) && (
                <>
                    <ReportingPortalHeaderPowerBi 
                        updateWidgetsOrder={updateWidgetsOrder} 
                        showDateRangeLabel={false} 
                    />
                    <div className="widget-container" style={{marginTop: '-110px'}}>
                        {widgets && widgets.map(widget => (
                            <div className="widget-item" key={widget.id}>
                                <ReportingPortalDashboardItemPowerBi widget={widget} />
                            </div>
                        ))}
                    </div>
                </>
            )
        }
    </MultiGridPage>);
}

export default ReportingPortalDashboardPowerBi;
