import {useContext} from 'react';
import {BrComponentContext, BrPageContext} from '@bloomreach/react-sdk';
import {useSelector} from 'react-redux';

export default function useCmsMenu() {
    const menuSettings = useSelector(state => state.settings.menuSettings);
    const facilities = useSelector(state => state.settings.facilities);
    const component = useContext(BrComponentContext);
    const page = useContext(BrPageContext);
    const menuRef = component.getModels().menu;
    const menu = menuRef ? page.getContent(menuRef) : null;
    const items = menu?.getItems() ?? [];
    const allowedRoles = (menuSettings?.filter(i => i.name === 'Y') || []).map(i => i.id);

    return [
        items.map((option) => {
            return {
                url: option.getUrl(),
                title: option.getName(),
                visible: option.getParameters()?.roleId ?
                    (facilities.length > 0 && allowedRoles.some(r => r === option.getParameters().roleId)) : true,
                params: option.getParameters(),
            }
        }),
        menu,
    ];
}
