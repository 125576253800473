import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Cookies from 'js-cookie';
import CookieBannerContent from './CookieBannerContent';

const CookieBanner = () => {
    const [isCookieAccepted, setIsCookieAccepted] = useState(false);
    const cookiesPolicyAccepted = useSelector(state => state.user.policies.cookiesPolicyAccepted) || isCookieAccepted;
    const isLoggedIn = useSelector(state => !!state.user.tokenInfo);
    const beingImpersonated = useSelector(state => state.user.beingImpersonated);

    // Check cookie exists on mount
    useEffect(() => {
        getCookiesPolicyValue();
    }, []);

    const getCookiesPolicyValue = () => {
        const cookie = Cookies.get('cookie-policy');
        if (cookie)
            setIsCookieAccepted(true);
    };

    const onAccept = () => {
        // set a very high expiration 
        Cookies.set('cookie-policy', 'true', {expires: 180});
        setIsCookieAccepted(true);

        if (isLoggedIn) {
            axios.post(`/user/cookies`, {agree: true})
        }
    };

    return (
        !beingImpersonated && !cookiesPolicyAccepted ?
            <div className="d-flex justify-content-center">
                <CookieBannerContent
                    useForAllPolicies={false}
                    onAccept={onAccept}
                    isLoggedIn={isLoggedIn}
                    cookiesPolicyAccepted={cookiesPolicyAccepted}
                />
            </div> :
            null
    );
}

export default CookieBanner;
