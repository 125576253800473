import axios from 'axios';
import _ from 'lodash';

const GET_SITE_SETTINGS_SUCCESS = 'GET_SITE_SETTINGS_SUCCESS';
const GET_COMPANY_SETTINGS_SUCCESS = 'GET_COMPANY_SETTINGS_SUCCESS';
const GET_COMPANY_RMA_SETTING_SUCCESS = 'GET_COMPANY_RMA_SETTING_SUCCESS';
const GET_COMPANY_FACILITIES_SUCCESS = 'GET_COMPANY_FACILITIES_SUCCESS';
const GET_MENU_SETTINGS_SUCCESS = 'GET_MENU_SETTINGS_SUCCESS';
const ERROR = 'ERROR';

export const defaultState = {
    siteSettings: {
        oktaAuthentication: 'N',
    },
    companySettings: {},
    facilities: [],   
    menuSettings: [],
    isRMARequired: undefined,
    loadingCompaniesSettings: true,
};

const reducer = (state = _.cloneDeep(defaultState), action) => {
    switch (action.type) {
        case GET_SITE_SETTINGS_SUCCESS: {
            let settings = {};

            action.settings.forEach(s => {
                const key = _.lowerFirst(s.settingCode);
                settings[key] = s.value;
            });

            settings.settings = action.settings;
            return {...state, siteSettings: settings};
        }
        case GET_COMPANY_SETTINGS_SUCCESS: {
            let settings = {};

            action.settings.forEach(s => {
                const key = _.lowerFirst(s.settingCode);
                settings[key] = s.value;
            });

            settings.settings = action.settings;
            return {...state, loadingCompaniesSettings: false, companySettings: settings};
        }
        case GET_COMPANY_RMA_SETTING_SUCCESS: {    
            if (action.settings.value === '1') {            
                return {...state, isRMARequired: true};
            } else {
                return {...state, isRMARequired: false};
            }                            
        }
        case GET_COMPANY_FACILITIES_SUCCESS: {            
            return {...state, facilities: action.facilities};            
        }
        case GET_MENU_SETTINGS_SUCCESS: {                              
            return {...state, menuSettings: action.menuSettings};                                    
        }
                
        case ERROR:
        default:
            return state;
    }
}

export function getSiteSettings() {
    return (dispatch, getState) => {
        return axios
            .get('/setting')
            .then(({data: settings}) => dispatch({type: GET_SITE_SETTINGS_SUCCESS, settings}))
            .catch(error => dispatch({type: ERROR, error}));
    };
}

export function getCompanySettings(companyId) {
    return async (dispatch, getState) => {
        return await axios
            .get(`/setting/company/${companyId}`)
            .then(({data: settings}) => dispatch({type: GET_COMPANY_SETTINGS_SUCCESS, settings}))
            .catch(error => dispatch({type: ERROR, error}));
    };
}

export function getCompanyRMASetting(companyId) {
    return async (dispatch, getState) => {        
        return await axios
            .get(`/setting/companyRMA/${companyId}`)
            .then(({data: settings}) => dispatch({type: GET_COMPANY_RMA_SETTING_SUCCESS, settings}))
            .catch(error => dispatch({type: ERROR, error}));
    };
}

export function getCompanyFacilities() {
    return (dispatch, getState) => {
        return axios
            .get(`/company`)
            .then(({data: facilities}) => dispatch({type: GET_COMPANY_FACILITIES_SUCCESS, facilities}))
            .catch(error => dispatch({type: ERROR, error}));
    };
}

export function getMenuSettings() {    
    return async (dispatch, getState) => {
        return await axios
            .get(`/setting/Menu`)
            .then(({data: menuSettings}) => dispatch({type: GET_MENU_SETTINGS_SUCCESS, menuSettings}))
            .catch(error => dispatch({type: ERROR, error}));
    };        
}

export default reducer;
