import axios from 'axios';

export default class PowerBiApiService {
    getUserAccounts() {
        return axios.post(`${process.env.REACT_APP_ORION_API_ROUTE_URL}/ReportGenService/api/powerBiReports/getUserAccounts`, {});
    }

    getWorkspaceEmbeddedData(request) {
        return axios.post(`${process.env.REACT_APP_ORION_API_ROUTE_URL}/ReportGenService/api/powerBiReports/getWorkspaceEmbeddedReportData`, request);
    }
}
