import Spinner from 'components/Spinner';
import useQueryString from 'hooks/useQueryString';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {loginSisense, stopImpersonation} from 'reducers/user';
import styled from 'styled-components';
import init from 'site';
import * as PropTypes from 'prop-types';

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const IFrame = styled.iframe`
    display: none;
    width: 0;
    height: 0;
    position: absolute;
`;

const Sso = ({updateTriggerLogout}) => {
    const impersonator = useSelector(state => state.user.impersonator);
    const dispatch = useDispatch();
    const {host = window.location.host} = useQueryString(window.location.search);
    const [, action] = /\/sso\/sisense\/(.*)/.exec(window.location.pathname) || [];
    const history = useHistory();

    useEffect(() => {
        async function handleAction() {
            if (action === 'login') {
                const redirectUrl = await dispatch(loginSisense(window.location.host));
                window.location.replace(redirectUrl);
            }
        }
        
        handleAction();
    });

    function finishLogout() {
        if (impersonator) {
            dispatch(stopImpersonation());
            dispatch(init());
            history.push('/admin/company');
        } else if (updateTriggerLogout) {
            updateTriggerLogout(true);
        }
    }

    if (host !== window.location.host) {
        window.location.replace(`https://${host}/sso/sisense/${action}`);
        return null;
    }

    return (
        <Container>
            {action !== 'logout' && <Spinner />}
            {action === 'logout' && <IFrame
                src={`${process.env.REACT_APP_SISENSE_DOMAIN}/api/auth/logout`}
                onLoad={finishLogout}
            />}
        </Container>
    );
};

Sso.propTypes = {
    updateTriggerLogout: PropTypes.func,
};

export default Sso;
