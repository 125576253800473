export const GridKeys = [
    {
        cardAction: 'RMA',
        fieldName: 'repairStatus',
        id: 'RMA',
        tabDisplay: 'RMA#',
        value: 'RMA',
    },
    {
        cardAction: 'PendingShipment',
        fieldName: 'repairStatus',
        id: 'PendingShipment',
        tabDisplay: 'Pending Shipment',
        value: 'PENDINGSHIPMENT', 
    }, {
        cardAction: 'ShippedInbound',
        fieldName: 'repairStatus',
        id: 'ShippedInbound',
        tabDisplay: 'Shipped Inbound',
        value: 'SHIPPEDINBOUND',
    }, {
        cardAction: 'Evaluating',
        fieldName: 'repairStatus',
        id: 'Evaluating',
        tabDisplay: 'Evaluating',
        value: 'EVALUATING',
    }, {
        cardAction: 'Quoted',
        fieldName: 'repairStatus',
        id: 'Quoted',
        tabDisplay: 'Quoted',
        value: 'QUOTED',
    }, {
        cardAction: 'Backordered',
        fieldName: 'repairStatus',
        id: 'Backordered',
        tabDisplay: 'Backordered',
        value: 'BACKORDERED',
    }, {
        cardAction: 'Repairing',
        fieldName: 'repairStatus',
        id: 'Repairing',
        tabDisplay: 'Repairing',
        value: 'REPAIRING',
    }, {
        cardAction: 'ShippedOutbound',
        fieldName: 'repairStatus',
        id: 'ShippedOutbound',
        tabDisplay: 'Shipped Outbound',
        value: 'SHIPPEDOUTBOUND',
    }, {
        cardAction: 'Delivered',
        fieldName: 'repairStatus',
        id: 'Delivered',
        tabDisplay: 'Delivered',
        value: 'DELIVERED',
    },    
];
