import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {LeftArrow} from 'utils/styles';
import {Popup} from '@partssourceinc/react-ui-core';
import {faAngleRight} from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';

const Row = styled.div`
    margin-bottom: 1rem;
`;

const Subtitle = styled.div`
    font-size: 16px;
    float: left;
`;

const ShippingInformation = styled.div`
    
    display: block;

    @media (max-width: 819px) {
        display: none
    }
`;

const ShippingInformationMobile = styled.div`
    
    display: none;

    @media (max-width: 819px) {
        
        display: block;
        padding: 15px 20px 15px 10px;
        border: 1px solid #949494;
        display: block;
        position: relative;
        cursor: pointer;
        margin-top: 20px;
        font-size: 14px;  
        margin-left: 15px;
        margin-bottom: 20px;
        padding-right: 0;       
    }
`;

export const ShippingArrow = styled(LeftArrow)`
    color:  rgb(0, 24, 66);
    float: right;
    margin-top: 2px; 
    font-size: 14px;
    font-weigth: 400;
`;

const ShippingPopup = styled(Popup)`    
   max-width: 690px!important;  
`;

const GraySubtitle = styled(Subtitle)`
    font-weight: bold;
    color: rgb(148, 148, 148);    
`;

const HeaderRow = styled.div`
    line-height: 25px;
`;

const BoldSubtitle = styled(Subtitle)`
    font-weight: 600;
    padding-left: 5px;
`;

const ShippingHeader = (props) => {
    const {
        order = {},
        type,
    } = props;

    const [showMobilePopup, setMobilePopup] = useState(false);
    
    const renderShippingContent = () => {

        return (
            <Row className="row">
                <HeaderRow className="col-11">
                    <GraySubtitle>{type === 'repair' ? 'Customer Shipping' : 'Customer Order'} Information</GraySubtitle>                        
                </HeaderRow>
                <HeaderRow className="col-11">
                    <Subtitle>Shipping Address: </Subtitle><BoldSubtitle>{order?.addressInfo}</BoldSubtitle>
                </HeaderRow>
                <HeaderRow className="col-11">
                    <Subtitle>Shipping ATTN:</Subtitle><BoldSubtitle>{order?.shippingAttention}</BoldSubtitle>
                </HeaderRow>
                <HeaderRow className="col-11">
                    <Subtitle>Carrier: </Subtitle><BoldSubtitle>{order?.shipper}</BoldSubtitle>
                </HeaderRow>
                <HeaderRow className="col-11">
                    <Subtitle>Shipping Method: </Subtitle><BoldSubtitle>{order?.shippingMethod}</BoldSubtitle>
                </HeaderRow>   
                <HeaderRow className="col-11">
                    <Subtitle>Shipping Insurance: </Subtitle><BoldSubtitle>{(order?.shippingInsurance) ? 'Yes' : 'No' }</BoldSubtitle>
                </HeaderRow> 
            </Row>
        )
    }

    return (
        <React.Fragment>
            <ShippingInformation>
                {renderShippingContent()}
            </ShippingInformation>
            <ShippingInformationMobile onClick={() => setMobilePopup(true)}>
                Shipping Information
                <ShippingArrow icon={faAngleRight} />
            </ShippingInformationMobile>

            <ShippingPopup
                onCancel={() => setMobilePopup(false)}
                show={showMobilePopup}
                hideButtons={true}>
                {renderShippingContent()}
            </ShippingPopup>

        </React.Fragment>
    );
};

export default ShippingHeader;

ShippingHeader.propTypes = {
    order: PropTypes.shape({}),
    type: PropTypes.string,
};
