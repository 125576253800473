import React from 'react';
import DownloadingDots from '../DownloadingDots';
import * as PropTypes from 'prop-types';
import {PrintAction} from 'components/CardView/StyledComponents';
import styled from 'styled-components'

export const printOptions = [
    {
        'id': null,
        'code': 'PRINT DOCUMENTS',
        'description': 'PRINT DOCUMENTS',
    },
    {
        'id': 'PACKINGSLIP',
        'code': 'Packing Slip',
        'description': 'Packing Slip',
        'sort': 10,
    }, {
        'id': 'SHIPPINGLABEL',
        'code': 'Shipping Label',
        'description': 'Shipping Label',
        'sort': 20,
    }, {
        'id': 'PURCHASEORDER',
        'code': 'Purchase Order',
        'description': 'Purchase Order',
        'sort': 30,
    }, {
        'id': 'PRINTALL',
        'code': 'Print All',
        'description': 'Print All',
        'sort': 40,
    },
];

const PrintDocuments = styled(PrintAction)`
    select { 
        font-weight: 600;
        color: rgb(74, 74, 74);
        padding: 10px;
    }
`;

const PrintingOptions = ({isPrinting, cardData, onPrintOptionChange, options, isExchangeOrReturn = false}) => {
    let printingOptions = [];
    
    if (options && options.length > 0) {
        if (options[0] !== '') {
            printingOptions = printOptions.filter(x => !x.id || options?.includes(x.id) || x.id === 'PRINTALL')
        } else {
            printingOptions = [];
        }
    } else {
        printingOptions = printOptions;
    }

    printingOptions = isExchangeOrReturn ? printingOptions.filter(x => !x.id || x.id === 'PURCHASEORDER') :
        (cardData?.hasShippingLabel ? printingOptions : printingOptions.filter(x => x.id !== 'SHIPPINGLABEL'));

    return (
        <React.Fragment>
            {isPrinting ?
                <div style={{position: 'absolute', bottom: 35}}>
                    <DownloadingDots text={'DOWNLOADING'} />
                </div> :
                printingOptions && printingOptions.length > 0 ?
                    (<PrintDocuments name={'typeId'}
                        suppressBlankOption={true}
                        options={printingOptions}
                        valueField="id" textField="description"
                        onChange={(e) => onPrintOptionChange(e.target.value, cardData,
                            e.target.value === 'PRINTALL' ? printOptions.map(x => x.id) : null)}
                    />) : null}
        </React.Fragment>
    )
}

export default PrintingOptions;

PrintingOptions.propTypes = {
    cardData: PropTypes.shape({
        dateCreated: PropTypes.string,
        estimatedDelivery: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        estimateSdhipDate: PropTypes.instanceOf(Date),
        followUpDate: PropTypes.string,
        gsaItem: PropTypes.string,
        lineItemId: PropTypes.number,
        shipper: PropTypes.string,
        addressInfo: PropTypes.string,
        shippingAttention: PropTypes.string,
        status: PropTypes.string,
        trackingNumber: PropTypes.string,
        vendorOrderNumber: PropTypes.string,
        hasShippingLabel: PropTypes.bool,
    }),
    options: PropTypes.arrayOf(PropTypes.string),
    isPrinting: PropTypes.bool,
    isExchangeOrReturn: PropTypes.bool,
    onSelectCard: PropTypes.func,
    showProcessPopup: PropTypes.func,
    onPrintOptionChange: PropTypes.func.isRequired,
}
