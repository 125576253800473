import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

const CardStatusSection = styled.div`
    overflow: hidden;
    margin: 16px -30px 0 -30px;
    padding: 27px 30px 27px 30px;
    position: relative;
    height: auto;
    font-size: 16px;
    background-color:#facbcb;
    line-height: 22px;

    @media (min-width: 1149px) {
        padding-top: 17px;
    }
`;

const CardStatusTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
`;

const CardStatusDetails = styled.div`
    font-size: 16px;
    font-weight: 400;
`;

const WarningInfoCard = (props) => {
    const {
        title,
        subText,
        additionalText,
        className,
    } = props;  

    const renderStatus = () => {    
        return (
            <div>
                <CardStatusTitle>
                    {title} 
                </CardStatusTitle>
                <CardStatusDetails>
                    {subText}
                    {renderAdditionalText()}
                </CardStatusDetails>
            </div>
        );
    }    

    const renderAdditionalText = () => {    
        if (additionalText === '')
            return '';

        return (
            <>
                <br />
                {additionalText}
            </>
        );
    }

    return (
        <CardStatusSection className={className}>
            <div>
                {renderStatus()}
            </div>
        </CardStatusSection>
    )
}

WarningInfoCard.propTypes = {  
    title: PropTypes.string,   
    subText: PropTypes.string,
    additionalText: PropTypes.string,
    className: PropTypes.string,
};

export default WarningInfoCard;
