import React from 'react';
import FormFooter from 'components/FormFooter';
import {
    RadioButtonList,
    semiBoldBrandFont,
    lightBrandFont,
    brandFont,
    TextField,
} from '@partssourceinc/react-ui-core';
import styled, {css} from 'styled-components';
import useForm from 'hooks/useForm';
import {useSelector, useDispatch} from 'react-redux';
import {saveServicesAndInventory} from 'reducers/supplier';
import {SubTitle, BackLink, LeftArrow} from 'utils/styles';
import {faAngleLeft} from '@fortawesome/pro-regular-svg-icons';

const Wrapper = styled.div`
    h1 {
        ${lightBrandFont('30px')}
        margin-top: 3rem;
    }

    h3 {
        margin-top: 3rem;
    }
`;

const Group = styled.div`
    > div {
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
    }

    > div > div {
        display: inline-block;
    }

    ${props => props.offset === 'true' && css`
        margin-left: 35px;
    `}
`;

const Title = styled.p`
    ${semiBoldBrandFont('16px')}
`;

const Info = styled.div`
    ${brandFont('16px')}
    margin-bottom: 35px;
    margin-top: 15px;
`;

const BottomGroup = styled.div`
    margin-bottom: 50px;
    margin-top: 40px;
`;

const TextBox = styled(TextField)`
    margin-bottom: 20px;

    div {
        min-height: 110px;
        max-width: 40em;
    }
`;

const SelectionList = styled(RadioButtonList)`
    > div {
        margin-right: 20px;
        label {
            min-width: auto;
        }
    }
`;

const options = [
    {value: 'true', label: 'Yes'},
    {value: 'false', label: 'No'},
];

const form = {
    providesInvPricingInExcel: {
        title: 'Can you provide your inventory with pricing in Excel format?',
        options,
        required: true,
    },
    providesDimWeights: {
        title: 'Can you provide DIM weights on all products?',
        options: [
            {
                value: 0,
                label: 'Yes, with pictures',
            },
            {
                value: 1,
                label: 'Yes, without pictures',
            },
            {
                value: 2,
                label: 'No',
            }],
        required: true,
    },
    installOrTechSupport: {
        title: 'Do you have the ability to offer installation or technical support?',
        options,
        required: true,
    },
    testBeforeShipping: {
        title: 'Do you have the ability to test products prior to shipment?',
        options,
        required: true,
    },
    providesCertOfConformance: {
        title: 'Can you provide certificate of conformance?',
        options,
        required: true,
    },
    analysisOfFailuresDOA: {
        title: 'Do you have the ability to provide root-cause analisys on failures/DOAs?',
        options,
        required: true,
    },
    sellsNewOemReplacement3rdParty: {
        title: 'Do you sell New OEM Replacement/Third Party Products?',
        options,
        required: true,
    },
    oemItem: {
        title: 'Can you provide documented proof that a:',
    },
    partMeetsOemSpecs: {
        title: 'Part meets OEM specs?',
        subOption: true,
        options,
    },
    partMeetsOemFormFitFunction: {
        title: 'Part meets OEM fit/form/function?',
        subOption: true,
        options,
    },
    provideFMEA: {
        title: 'Do you have the ability to provide FMEA (Failure Mode and Effects Analysis?',
        options,
        required: true,
    },
    provideMTBF: {
        title: 'Do you have the ability to provide MTBF (Mean Time Between Failures)?',
        options,
        required: true,
    },
};

const ServicesAndInventory = () => {
    const dispatch = useDispatch();
    const isSaving = useSelector(state => state.supplier.isSaving);
    const servicesAndInventory = useSelector(state => state.supplier.servicesAndInventory);

    const handleFormSubmit = async (values) => {
        await dispatch(saveServicesAndInventory(values));
    }

    const {
        values,
        handleOnChange,
        handleOnSubmit,
        disable,
        dirty,
    } = useForm(servicesAndInventory, form, handleFormSubmit);

    return (
        <Wrapper>
            <h1>Services And Inventory</h1>
            <div>
                <LeftArrow icon={faAngleLeft} />
                <BackLink to="/settings">Back to Company Profile</BackLink>
            </div>
            <Info>
                <SubTitle limitWidth={true}>In order to provide a great experience to our mutual customers, PartsSource partners with
                    our supplier partners to maintain highly detailed and accurate information about the Products and
                    Services available at PartsSource.com</SubTitle>
            </Info>
            <form onSubmit={handleFormSubmit}>
                {
                    Object.entries(form).map(([key, value], i) => {
                        return (
                            (key === 'oemItem' || key === 'partMeetsOemSpecs' || key === 'partMeetsOemFormFitFunction')
                            && (!values.sellsNewOemReplacement3rdParty || values.sellsNewOemReplacement3rdParty === 'false')
                                ? null
                                : <Group offset={`${value.subOption}`} key={i}>
                                    <Title>{value.title}</Title>
                                    <SelectionList
                                        name={key}
                                        tabIndex={i}
                                        selectedValue={values[key] === null ? -1 : values[key]}
                                        options={value.options}
                                        onChange={handleOnChange}
                                        label={value.title} />
                                </Group>
                        )
                    })
                }
                <BottomGroup>
                    <h3>Edits or Requests</h3>
                    <SubTitle limitWidth={true}>Please provide any additional information that needs to be denoted in your profile.</SubTitle>
                    <TextBox
                        name="servicesInventoryEdits"
                        label="Edits or Requests"
                        placeholder="Edit and/or requests to settings information"
                        value={values.servicesInventoryEdits}
                        multiLine={true}
                        onChange={handleOnChange}
                    />
                </BottomGroup>
                <FormFooter dirty={dirty} handleOnSubmit={handleOnSubmit} loading={isSaving} disabled={disable} />
            </form>
        </Wrapper>
    );
}

export default ServicesAndInventory;
