import React, {useContext} from 'react';
import FlyoutMenu from 'components/FlyoutMenu';
import {BrPageContext, BrManageMenuButton} from '@bloomreach/react-sdk';
import * as PropTypes from 'prop-types';
import useCmsMenu from 'hooks/useCmsMenu';

const CmsFlyoutMenu = (props) => {
    const {children, ...rest} = props;
    const page = useContext(BrPageContext);
    const [items, menu] = useCmsMenu();

    return (menu ?
        <FlyoutMenu menu={items} {...rest}>
            {children}
            {page.isPreview() && <BrManageMenuButton menu={menu} />}
        </FlyoutMenu> : null);
};

CmsFlyoutMenu.propTypes = {
    children: PropTypes.node,
};

export default CmsFlyoutMenu;
