import {TextField, RadioButtonList, brandFont, semiBoldBrandFont} from '@partssourceinc/react-ui-core';
import React, {useState} from 'react';
import styled from 'styled-components';
import {validateEmail} from 'utils/validation';
import FormFooter from 'components/FormFooter';
import useForm from 'hooks/useForm';
import {useSelector, useDispatch} from 'react-redux';
import {saveFeesAndPayment} from 'reducers/supplier';
import {SubTitle, BackLink, LeftArrow} from 'utils/styles';
import _ from 'lodash';
import AccordionSection from 'components/AccordionSection';
import {faAngleLeft} from '@fortawesome/pro-regular-svg-icons';

const Wrapper = styled.div`
    h3 {
        margin-top: 3rem;
    }
`;
const TextBox = styled(TextField)`
    max-width: 20em;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const MultilineTextBox = styled(TextField)`
    div {
        max-width: 40em;
        min-height: 8em;
    }
`;

const OptionsList = styled(RadioButtonList)`
    > div {
        margin-right: 20px;
        label{
            min-width: auto;
        }
    }
`;

const Group = styled.div`
    > div {
        margin-top: 0;
        margin-bottom: 0;
    }

    > div > div {
        display: inline-block;
    }
`;

const LineInfo = styled.div``;

const LineInfoName = styled.span`
    ${brandFont('16px')}
`

const LineInfoValue = styled.span`
    margin-left: 10px;
    ${semiBoldBrandFont('16px')}
`

const Title = styled.p`
    ${semiBoldBrandFont('16px')}
`;

const BoldSubtitle = styled(SubTitle) `
    font-weight: bold;
`;

const Section = {
    EDI: 'EDI',
    MOF: 'Minimum Order Fees',
    OPF: 'Order Processing Fees',
    DSF: 'Drop Ship Fees',
    OF: 'Other Fees',
};

const fields = {
    hasEdiAbility: {
        title: 'Do you have EDI ability?',
        type: 'rdb',
        value: -1,
        sectionKey: Section.EDI,
    },
    wantsEDIAssistance: {
        title: 'Would you like assistance setting up EDI?',
        dependent: 'hasEdiAbility',
        type: 'rdb',
        value: -1,
        sectionKey: Section.EDI,
    },
    contactName: {
        placeholder: 'EDI Contact Name',
        label: 'EDI Contact Name',
        type: 'txt',
        value: '',
        sectionKey: Section.EDI,
    },
    contactEmail: {
        placeholder: 'EDI Contact Email',
        label: 'EDI Contact Email',
        type: 'txt',
        value: '',
        showError: false,
        sectionKey: Section.EDI,
        validator: {
            func: value => validateEmail(value),
        },
    },
    ediAcceptPo: {
        title: 'Accept PO’s',
        type: 'text',
        sectionKey: Section.EDI,
    },
    ediAcceptInvoices: {
        title: 'Accept Invoices',
        type: 'text',
        sectionKey: Section.EDI,
    },
    ediAcceptPriorityOrders: {
        title: 'Accept Priority Orders',
        type: 'text',
        sectionKey: Section.EDI,
    },
    ediAcceptShippingMethods: {
        title: 'Accept Shipping Methods',
        type: 'text',
        sectionKey: Section.EDI,
    },
    ediAcknowledgePo: {
        title: 'Acknowledges PO’s',
        type: 'text',
        sectionKey: Section.EDI,
    },
    ediSendShipManifest: {
        title: 'Sends Shipment Manifests',
        type: 'text',
        sectionKey: Section.EDI,
    },
    ediSendInvoices: {
        title: 'Sends Invoices',
        type: 'text',
        sectionKey: Section.EDI,
    },
    feesPaymentEdits: {
        value: '',
        title: 'Edits or Requests',
        subtitle: 'Please provide any additional information that needs to be denoted in your profile',
        placeholder: 'Edits and/or requests to settings information',
        type: 'textarea',
        sectionKey: Section.EDI,
    },
    minimumOrderFee: {
        value: '',
        type: 'currency',
        placeholder: 'Fee',
        label: 'Fee',
        sectionKey: Section.MOF,
    },
    minimumOrderDetails: {
        value: '',
        type: 'txt',
        placeholder: 'Detail',
        label: 'Detail',
        limit: 250,
        sectionKey: Section.MOF,
    },
    minimumThirdParty: {
        value: -1,
        title: 'Only applies to 3rd parties?',
        type: 'rdb',
        sectionKey: Section.MOF,
    },
    orderProcessingFee: {
        value: '',
        type: 'currency',
        placeholder: 'Fee',
        label: 'Fee',
        sectionKey: Section.OPF,
    },
    orderProcessingDetails: {
        value: '',
        type: 'txt',
        placeholder: 'Detail',
        label: 'Detail',
        limit: 250,
        sectionKey: Section.OPF,
    },
    orderProcessingThirdParty: {
        value: -1,
        title: 'Only applies to 3rd parties?',
        type: 'rdb',
        sectionKey: Section.OPF,
    },
    dropShipFee: {
        value: '',
        type: 'currency',
        placeholder: 'Fee',
        label: 'Fee',
        sectionKey: Section.DSF,
    },
    dropShipDetails: {
        value: '',
        type: 'txt',
        placeholder: 'Detail',
        label: 'Detail',
        limit: 250,
        sectionKey: Section.DSF,
    },
    dropShipThirdParty: {
        value: -1,
        title: 'Only applies to 3rd parties?',
        type: 'rdb',
        sectionKey: Section.DSF,
    },
    otherFeesFee: {
        value: '',
        type: 'currency',
        placeholder: 'Fee',
        label: 'Fee',
        sectionKey: Section.OF,
    },
    otherFeesDetails: {
        value: '',
        type: 'txt',
        placeholder: 'Detail',
        label: 'Detail',
        limit: 250,
        sectionKey: Section.OF,
    },
    otherFeesThirdParty: {
        value: -1,
        title: 'Only applies to 3rd parties?',
        type: 'rdb',
        sectionKey: Section.OF,
    },
}

const FeesBilling = () => {
    const dispatch = useDispatch();
    const isSaving = useSelector(state => state.supplier.isSaving);
    const feesAndPayment = useSelector(state => state.supplier.feesAndPayment);
    const [isEdiOpen, setIsEdiOpen] = useState(false);

    const simpleOptions = [
        {label: 'Yes', value: 'true'},
        {label: 'No', value: 'false'},
    ];

    const handleFormSubmit = async (values, dirtyValues) => {
        const {
            minimumOrderFee,
            minimumOrderDetails,
            minimumThirdParty,
            orderProcessingFee,
            orderProcessingDetails,
            orderProcessingThirdParty,
            dropShipFee,
            dropShipDetails,
            dropShipThirdParty,
            otherFeesFee,
            otherFeesDetails,
            otherFeesThirdParty,
            contactEmail,
            contactName,
        } = dirtyValues;

        let updatedStatus = {
            minimumOrderFeesUpdated: minimumOrderFee || minimumOrderDetails || minimumThirdParty,
            orderProcessingFeesUpdated: orderProcessingFee || orderProcessingDetails || orderProcessingThirdParty,
            dropShipFeesUpdated: dropShipFee || dropShipDetails || dropShipThirdParty,
            otherFeesUpdated: otherFeesFee || otherFeesDetails || otherFeesThirdParty,
            updateContact: contactName || contactEmail,
        }

        await dispatch(saveFeesAndPayment(values, updatedStatus));
    }

    const {
        values,
        handleOnChange,
        handleOnSubmit,
        disable,
        errors,
        dirty,
    } = useForm(feesAndPayment, fields, handleFormSubmit);

    const onChange = (e) => {
        const {target: {name, value}} = e;
        const data = {
            value: value.replace(/[^0-9.]/g, ''),
        }
        if (name === 'minimumOrderFee' || name === 'orderProcessingFee'
            || name === 'dropShipFee' || name === 'otherFeesFee') {
            handleOnChange(e, data)
        } else {
            handleOnChange(e);
        }
    }

    const renderTextBox = (key, item, i) => {
        return (
            <TextBox
                key={`${i}-${key}`}
                label={item.label}
                name={key}
                placeholder={item.placeholder}
                text={values[key]}
                onChange={onChange}
                limit={item.limit}
                showErrorMessage={errors[key]}
                type={item.type === 'currency' ? 'number' : 'text'}
            />)
    }

    const renderRadioButton = (key, item, i) => {
        return (
            <Group>
                <Title>{item.title}</Title>
                <OptionsList
                    name={key}
                    tabIndex={i}
                    selectedValue={String(values[key])}
                    options={simpleOptions}
                    onChange={onChange}
                    label={item.title} />
            </Group>
        )
    }

    const renderText = (key, item) => {
        return (
            <React.Fragment>
                {key === 'ediAcceptPo' &&
                    <div>
                        <SubTitle>PartsSource offers EDI transaction to automate the transmission of order,
                        shipping and billing information. This ensures the accurate and efficient processing of transactions.</SubTitle>
                        <BoldSubtitle>Your Current EDI Settings</BoldSubtitle>
                    </div>}
                <LineInfo>
                    <LineInfoName>{item.title}:</LineInfoName>
                    <LineInfoValue>{values[key] ? 'Yes' : 'No'}</LineInfoValue>
                </LineInfo>
            </React.Fragment>
        )
    }

    const renderTextArea = (key, item) => (
        <React.Fragment>
            <h3>{item.title}</h3>
            <SubTitle>{item.subtitle}</SubTitle>
            <MultilineTextBox
                name={key}
                label={item.title}
                placeholder={item.placeholder}
                text={values[key]}
                onChange={onChange}
                multiLine={true}
                rows={4}
            />
        </React.Fragment>
    );

    const renderSection = (sectionTitle, section) => {
        const sectionKeys = Object.keys(section);
        const sectionErrors = _.pickBy(errors, (value, key) => {
            return sectionKeys.includes(key);
        });

        return (<AccordionSection title={sectionTitle} errors={Object.values(sectionErrors)}
            key={(sectionTitle === 'EDI') ? sectionTitle + values.hasEdiAbility : sectionTitle}
            onClick={() => (sectionTitle === 'EDI') ? setIsEdiOpen(!isEdiOpen) : ''}
            open= {(sectionTitle === 'EDI') ? isEdiOpen : ''}>
            {Object.entries(section).map(([key, value], i) => {
                switch (value.type) {
                    case 'currency':
                    case 'txt':
                        if (String(values?.hasEdiAbility) === 'true' && key === 'contactName'
                            || String(values?.hasEdiAbility) === 'true' && key === 'contactEmail') {
                            return renderTextBox(key, value, i);
                        } else if (key !== 'contactName' && key !== 'contactEmail') {
                            return renderTextBox(key, value, i);
                        }
                        break;
                    case 'rdb':
                        if (String(values?.hasEdiAbility) === 'false' && key === 'wantsEDIAssistance') {
                            return renderRadioButton(key, value, i);
                        } else if (key !== 'wantsEDIAssistance') {
                            return renderRadioButton(key, value, i);
                        }
                        break;
                    case 'text':
                    {
                        return renderText(key, value);
                    }
                    case 'textarea':
                        return renderTextArea(key, value);
                }
            })}
        </AccordionSection>)
    }

    const sections = Object.values(Section);

    return (
        <Wrapper>
            <h1>Fees & Payments</h1>
            <span>
                <LeftArrow icon={faAngleLeft} />
                <BackLink to="/settings">Back to Company Profile</BackLink>
            </span>
            <SubTitle limitWidth={true}>Accurate information regarding fees and payments for order processed on the
                PartsSource platform ensures timely payment in full for supplier partners.</SubTitle>
            <form onSubmit={handleFormSubmit}>
                {sections.map(section => renderSection(section, _.pickBy(fields, (value, key) => {
                    return value.sectionKey === section;
                })))}
                <FormFooter dirty={dirty} handleOnSubmit={handleOnSubmit} loading={isSaving} disabled={disable} />
            </form>
        </Wrapper>
    );
}

export default FeesBilling;
