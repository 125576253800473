import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {faAngleLeft, faAngleRight} from '@fortawesome/pro-regular-svg-icons';
import {Loader} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import BidHeader from 'components/BidHeader';
import BidWizzard from 'components/Bids/BidWizzard';
import BidCompleted from 'components/BidCompleted';
import {
    OrderArrow, BreadCrumbHeader, BreadCrumbSecondRow, SecondArrow, BreadCrumbLink, BlueHeader,
} from 'components/DetailsStyledComponents';

const Container = styled.div`
    width: 100%;
    top: 1em;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const Row = styled.div`
    margin-bottom: 1rem;
`;

const Subtitle = styled.div`
    font-size: 16px;
    float: left;
`;

const BidHeaderText = styled.h2`
    margin-top: 10px;
`;

const UnderlineSubtitle = styled(Subtitle)`
    width: 100%;
    border-top-style: solid;
    border-width: 2px;    
    border-color: rgb(204, 204, 204);
    font-size: 20px;    
    margin-top: 20px;
`;

const BidDetailsHeader = styled(Subtitle)`
    font-size: 20px;
    padding-left: 24px;
    min-width: 200px;
    display: inline-block;

`;

const SecurityErrorText = styled.h2`    
    font-size: 18px;
    font-weight: bold;
    color: red;
`;

const BidDetails = () => {

    const cachedBid = useSelector(state => state.bids.selectedBid);
    const [bidConditions, setBidConditions] = useState([]);
    const [selectedBid, setSelectedBid] = useState(cachedBid);
    const [isLoading, setIsLoading] = useState(false);
    const [bidAttachments, setBidAttachments] = useState([]);
    const [breadCrumbText, setBreadCrumbText] = useState('');
    const [showSecurityError, setShowSecurityError] = useState(false);

    useEffect(() => {

        loadBid();
        
        document.querySelector('html').scrollTop = 0;
        if (selectedBid.status !== 'REQUESTED' && selectedBid.status !== undefined) {
            loadConditions();
            loadAttachments(selectedBid);
        }

    }, [location]);

    const {id} = useParams();

    async function loadBid() {
        try {
            setIsLoading(true);
            const {data: bid} = await axios.get(`/bid/${id}`);
                        
            setSelectedBid(bid);

            if (bid.status !== 'REQUESTED') {
                setBreadCrumbText('MyBids');
                loadConditions();
                loadAttachments(bid);
            } else {
                setBreadCrumbText('OpenBids');
            }

            setIsLoading(false);
        } catch {
            setShowSecurityError(true);
            setIsLoading(false);
        }

    }

    async function loadAttachments(bid) {

        if (bid.lineItemId) {

            setIsLoading(true);
            const {data: attachments} = await axios.get(`/order/${bid.lineItemId}/images`);
            setBidAttachments(attachments);
            setIsLoading(false);
        }
    }

    async function loadConditions() {
        setIsLoading(true);

        const {data: conditions} = await axios.get(`/bid/${id}/conditions`);

        setIsLoading(false);

        setBidConditions([]);

        if (conditions !== '')
            setBidConditions(conditions);
    }

    return (
        <Container>
            <div>
                <BlueHeader>Bids</BlueHeader>
                <OrderArrow icon={faAngleRight} /> <BreadCrumbHeader>Bid</BreadCrumbHeader>
            </div>
            <BreadCrumbSecondRow>
                <SecondArrow icon={faAngleLeft} />
                {!isLoading &&
                    <React.Fragment>
                        {breadCrumbText === 'OpenBids' ? (
                            <BreadCrumbLink
                                to={'/bids?tab=OpenBids'}
                                exact={true}
                                activeClassName="active"
                            >
                                Open Bids
                            </BreadCrumbLink>
                        ) :
                            <BreadCrumbLink
                                to={'/bids?tab=MyBids'}
                                exact={true}
                                activeClassName="active"
                            >
                                My Bids
                            </BreadCrumbLink>
                        }
                    </React.Fragment>
                }
            </BreadCrumbSecondRow>
            {isLoading ? <Loader style={{margin: '0 auto'}} /> : !showSecurityError ?
                <React.Fragment>
                    {selectedBid.status !== 'REQUESTED' &&
                        <React.Fragment>
                            <BidHeaderText>{selectedBid.conditionDescription}</BidHeaderText>
                            <BidHeader attachments={bidAttachments} bid={selectedBid} />
                            {!isLoading &&
                                <React.Fragment>
                                    <BidDetailsHeader>Bid Details</BidDetailsHeader>
                                    <Row style={{marginBottom: '0'}}>
                                        <UnderlineSubtitle>&nbsp;</UnderlineSubtitle>
                                    </Row>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                   
                    {selectedBid.status === 'REQUESTED' &&
                        <div>
                            <BidWizzard bid={selectedBid} />
                        </div>}
                    {selectedBid.status !== 'REQUESTED' &&
                        <div>
                            {isLoading ? (
                                <Loader />
                            ) :
                                <BidCompleted description={selectedBid.description} conditions={bidConditions} />
                            }
                        </div>}
                </React.Fragment> :
                <SecurityErrorText>Sorry, you do not have access to this bid.</SecurityErrorText>
            }
        </Container>
    );
};

export default BidDetails;
