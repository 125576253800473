import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

const CardStatusSection = styled.div`
    background-color: #f1f1f1;
    overflow: hidden;
    padding: 0 29px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 95px;

    @media (max-width: 1149px) {
        margin-top: 20px;
        flex: 1 0 100%;
        position: static;
        margin-left: -30px;
        margin-right: -30px;
    }
`;

const CardStatusTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
`;

const CardStatusDetails = styled.div`
    font-size: 16px;
    font-weight: 400;
`;

const StandardInfoCard = (props) => {
    const {
        title,
        subText,
        additionalText,
        className,
    } = props;  

    const renderStatus = () => {    
        return (
            <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <CardStatusTitle>
                    {title} 
                </CardStatusTitle>
                <CardStatusDetails>
                    {subText}
                    {renderAdditionalText()}
                </CardStatusDetails>
            </div>
        );
    }    

    const renderAdditionalText = () => {    
        if (additionalText === '')
            return '';

        return (
            <>
                <br />
                {additionalText}
            </>
        );
    }

    return (
        <CardStatusSection className={className}>
            <div>
                {renderStatus()}
            </div>
        </CardStatusSection>
    )
}

StandardInfoCard.propTypes = {  
    title: PropTypes.string,   
    subText: PropTypes.string,
    additionalText: PropTypes.string,
    className: PropTypes.string,
};

export default StandardInfoCard;
