import _ from 'lodash';

const SET_CACHED_ATTACHMENTS = 'SET_CACHED_ATTACHMENTS';
const ADD_CACHED_ATTACHMENT = 'ADD_CACHED_ATTACHMENT';
const REMOVE_CACHED_ATTACHMENT = 'REMOVE_CACHED_ATTACHMENT';

const ERROR = 'ERROR';

export const defaultState = {     
    cachedAttachments: [], 
};

const reducer = (state = _.cloneDeep(defaultState), action) => {
    
    switch (action.type) {      
                                        
        case SET_CACHED_ATTACHMENTS: {
            
            const {cachedAttachments} = action;            
            return {...state, cachedAttachments};
        }

        case ADD_CACHED_ATTACHMENT: {
            
            const {attachment} = action; 
            const {cachedAttachments} = state;

            let updatedAttachments = _.merge([], cachedAttachments); 
            updatedAttachments.push(attachment);

            return {...state, cachedAttachments: updatedAttachments};
        }

        case REMOVE_CACHED_ATTACHMENT: {
            
            const {index} = action; 
            const {cachedAttachments} = state;

            let updatedAttachments = _.merge([], cachedAttachments); 
            updatedAttachments.splice(index, 1);

            return {...state, cachedAttachments: updatedAttachments};
        }
                                    
        case ERROR:
        default:
            return state;
    }
}

export function setCachedAttachments(cachedAttachments) {              
    return (dispatch) => dispatch({type: SET_CACHED_ATTACHMENTS, cachedAttachments});
}

export function addAttachmentToCache(attachment) {
    return (dispatch, getState) => {
        dispatch({type: ADD_CACHED_ATTACHMENT, attachment});
    };
}

export function removeAttachmentFromCache(index) {
    return (dispatch, getState) => {
        dispatch({type: REMOVE_CACHED_ATTACHMENT, index});
    };
}

export default reducer;
