import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Popup} from '@partssourceinc/react-ui-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import 'components/css/customGrid.css';
import styled from 'styled-components';
import {faBars} from '@fortawesome/free-solid-svg-icons';

const Icon = styled(FontAwesomeIcon)`
    float: right;
    color: black;
    margin-right: 5px;
    margin-left: 5px;
`;

const SortableItem = SortableElement(({value}) => <tr style={{cursor: 'pointer'}}><td><Icon icon={faBars} />{value.name}</td></tr>);

const SortableList = SortableContainer(({items}) => {
    return (
        <table>
            <tbody>
                {items.map((value, index) => (
                    <SortableItem key={`item-${index}`} index={index} value={value} />
                ))}
            </tbody>
        </table>
    )
});

function GenericReorderDialog({onCancel, onConfirm, items}) {
    const [listItems, setListItems] = useState(_.cloneDeep(items));
    
    const onSortEnd = ({oldIndex, newIndex}) => {
        setListItems(arrayMoveImmutable(listItems, oldIndex, newIndex));
    };

    return (
        <Popup 
            confirmText="Save"
            cancelText="Cancel"
            show={true}
            onConfirm={() => onConfirm(listItems)}
            onCancel={onCancel}
            className="formulary-rule-reorder-dialog"
            loading={false}
        >
            <h1>Display Order</h1>
            <div className="custom-grid">
                <SortableList 
                    items={listItems} 
                    onSortEnd={onSortEnd} 
                    lockAxis="y" 
                    lockToContainerEdges={true} 
                    helperClass="sortableHelper" 
                />
            </div>
        </Popup>
    );
}

GenericReorderDialog.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
};

export default GenericReorderDialog;
