import axios from 'axios';
import _ from 'lodash';

const GET_PART_ESD_REASONS_SUCCESS = 'GET_PART_ESD_REASONS_SUCCESS';
const GET_REPAIR_ESD_REASONS_SUCCESS = 'GET_REPAIR_ESD_REASONS_SUCCESS';
const GET_LOANER_ESD_REASONS_SUCCESS = 'GET_LOANER_ESD_REASONS_SUCCESS';
const GET_PART_CONDITION_CODES_SUCCESS = 'GET_PART_CONDITION_CODES_SUCCESS';
const GET_REPAIR_CONDITION_CODES_SUCCESS = 'GET_REPAIR_CONDITION_CODES_SUCCESS';
const GET_LOANER_CONDITION_CODES_SUCCESS = 'GET_LOANER_CONDITION_CODES_SUCCESS';
const SET_LOADING = 'SET_LOADING';
const ERROR = 'ERROR';

export const defaultState = {    
    esdRepairReasons: [],
    esdLoanerReasons: [],
    esdPartReasons: [],
    partConditionCodes: [],
    repairConditionCodes: [],  
    loanerConditionCodes: [],
    loading: false,
};

const reducer = (state = _.cloneDeep(defaultState), action) => {
    
    switch (action.type) {      
        case SET_LOADING: {             
            return {...state, loading: action.loading};            
        }                
        case GET_PART_ESD_REASONS_SUCCESS: {             
            return {...state, esdPartReasons: action.esdPartReasons};            
        }
        case GET_REPAIR_ESD_REASONS_SUCCESS: {             
            return {...state, esdRepairReasons: action.esdRepairReasons};            
        }
        case GET_LOANER_ESD_REASONS_SUCCESS: {           
            return {...state, esdLoanerReasons: action.esdLoanerReasons};            
        }
        case GET_PART_CONDITION_CODES_SUCCESS: {             
            return {...state, partConditionCodes: action.partConditionCodes};            
        }
        case GET_REPAIR_CONDITION_CODES_SUCCESS: {             
            return {...state, repairConditionCodes: action.repairConditionCodes};            
        }
        case GET_LOANER_CONDITION_CODES_SUCCESS: {             
            return {...state, loanerConditionCodes: action.loanerConditionCodes};            
        }
        case ERROR:
        default:
            return state;
    }
}

export function getPartESDReasons() {
    return (dispatch) => {
        return getCodesByOrderType('part','esdreasons')
            .then(({data: esdPartReasons}) => dispatch({type: GET_PART_ESD_REASONS_SUCCESS, esdPartReasons}))
            .catch(error => dispatch({type: ERROR, error}));
    };
}

export function getRepairESDReasons() {
    return (dispatch) => {
        return getCodesByOrderType('repair', 'esdreasons')
            .then(({data: esdRepairReasons}) => dispatch({type: GET_REPAIR_ESD_REASONS_SUCCESS, esdRepairReasons}))
            .catch(error => dispatch({type: ERROR, error}));
    };
}

export function getLoanerESDReasons() {
    return (dispatch) => {
        return getCodesByOrderType('loaner', 'esdreasons')
            .then(({data: esdLoanerReasons}) => dispatch({type: GET_LOANER_ESD_REASONS_SUCCESS, esdLoanerReasons}))
            .catch(error => dispatch({type: ERROR, error}));
    };
}

export function getPartConditionCodes() {
    return (dispatch) => {
        return getCodesByOrderType('part','conditioncodes')
            .then(({data: partConditionCodes}) => dispatch({type: GET_PART_CONDITION_CODES_SUCCESS, partConditionCodes}))
            .catch(error => dispatch({type: ERROR, error}));
    };
}

export function getRepairConditionCodes() {
    return (dispatch) => {
        return getCodesByOrderType('repair', 'conditioncodes')
            .then(({data: repairConditionCodes}) => dispatch({type: GET_REPAIR_CONDITION_CODES_SUCCESS, repairConditionCodes}))
            .catch(error => dispatch({type: ERROR, error}));
    };
}

export function getLoanerConditionCodes() {
    return (dispatch) => {
        return getCodesByOrderType('loaner', 'conditioncodes')
            .then(({data: loanerConditionCodes}) => dispatch({type: GET_LOANER_CONDITION_CODES_SUCCESS, loanerConditionCodes}))
            .catch(error => dispatch({type: ERROR, error}));
    };
}

export function setLoading(loading) {    
    return (dispatch) => dispatch({type: SET_LOADING, loading});
}

async function getCodesByOrderType(orderType, codePath) {
    return axios
        .get(`/common/orderType/${orderType}/${codePath}`);
}

export default reducer;
