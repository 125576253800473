import SisenseDashboardSdk from 'components/SisenseDashboardSdk';
import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {theme} from 'utils/styles';
import axios from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';

const Container = styled.div`
    width: 100%;
    top: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
`;

const ReportingPortalDashboardSisense = () => {
    if (/Trident.*rv:11\./.test(window.navigator.userAgent)) {
        return <Redirect to="/unsupported-browser" />
    }

    const {
        supplierProSisenseDashboardId,
        sproMobileSisenseDashboard,
        sProBrandedECommerceDashboard,
        sProBrandedECommerceDashMobile,
        sProMarketingInsightsDashboard,
        sProMarketingInsightsDashMobile,
    } = useSelector(state => state.settings.siteSettings);

    const [width, setWidth] = useState(window.innerWidth);
    const [isError, setIsError] = useState(false);
    const [dashboard, setDashboard] = useState({id: null, hasAccess: false});
    const originalUrl = window.location.pathname;

    useEffect(() => {
        selectDashboard();
    }, [supplierProSisenseDashboardId, width]);

    const selectDashboard = () => {
        const shouldRenderMaxScreen = width >= parseInt(theme.screenLGMin);
        const path = window.location.pathname.split('/');
        const last = _.last(path);
        switch (last) {
            case 'branded-ecommerce':
                setDashboard({
                    id: shouldRenderMaxScreen ? sProBrandedECommerceDashboard : sProBrandedECommerceDashMobile,
                });
                break;
            case 'marketing-insights':
                setDashboard({
                    id: shouldRenderMaxScreen ? sProMarketingInsightsDashboard : sProMarketingInsightsDashMobile,
                });
                break;
            default:
                setDashboard({
                    id: shouldRenderMaxScreen ? supplierProSisenseDashboardId : sproMobileSisenseDashboard,
                });
                break;
        }
    }

    useEffect(() => {
        function updateWidth() {
            setWidth(window.innerWidth);
        }

        let sisenseCookie = Cookies.get('sisense');
        if (sisenseCookie) {
            const diffTime = Math.abs(new Date() - new Date(sisenseCookie));
            const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));

            if (diffHours > 3)
                logInOnSisense();
            else {
                updateWidth();
                window.addEventListener('resize', updateWidth);
            }
        } else
            logInOnSisense();

        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    const logInOnSisense = () => {
        const orionUrl = process.env.REACT_APP_ORION_API_ROUTE_URL;
        let url = `${orionUrl}/AuthenticationService/api/v1.0/sisense/login?is_impersonated=false`;
        url += `&host=${window.location.host}&url=${window.location}`;
        url += `&userAgent=${window.navigator.userAgent}&embededSdk=true`;

        axios
            .get(url)
            .then(({data: {redirectUrl}}) => {
                // user hasn't navigated away
                if (originalUrl === window.location.pathname) {
                    Cookies.set('sisense', new Date().toString(), 1);
                    window.location.replace(redirectUrl)
                }
            }).catch(x => {
                setIsError(true);
            });
    }

    return (
        <Container>
            {dashboard.id && <SisenseDashboardSdk dashboardId={dashboard.id} clearFiltersId={0} isError={isError} />}
        </Container>
    );
};

export default ReportingPortalDashboardSisense;
