import React, {useContext} from 'react';
import {BrComponent, BrPageContext} from '@bloomreach/react-sdk';
import PageNotFound from 'pages/PageNotFound';

const CmsLandingPage = () => {
    const page = useContext(BrPageContext);
    const {template} = page.getComponent().getParameters();
    const url = page.getUrl();
    const showPageNotFound = !/^\/admin/.test(location.pathname)
        && ((template && template === 'page-not-found') && url.endsWith(window.location.pathname)) || page.isPreview();

    return (<>
        {(page.isPreview() || url.endsWith(window.location.pathname)) && <BrComponent path="main/container" />}

        {showPageNotFound ? <PageNotFound /> : null}
    </>)
}

export default CmsLandingPage;
