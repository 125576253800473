import {useSelector} from 'react-redux';

export default function useMenu() {
    const menuSettings = useSelector(state => state.settings.menuSettings);
    const facilities = useSelector(state => state.settings.facilities);
    const canSeePerformance = menuSettings?.find(c => c.id === '98' && c.name === 'Y') ? true : false;
    const canSeeBids = (menuSettings?.find(c => c.id === '122' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const canSeeOrders = (menuSettings?.find(c => c.id === '123' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const canSeeRepairs = (menuSettings?.find(c => c.id === '124' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const canSeeReturnsAndExchanges = (menuSettings?.find(c => c.id === '125' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const canSeeLoanersAndRentals = (menuSettings?.find(c => c.id === '134' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const canSeeOrdersMenu = (canSeeOrders || canSeeLoanersAndRentals || canSeeRepairs || canSeeReturnsAndExchanges || canSeeBids);

    const canEditSettings = useSelector(state => state.settings.companySettings?.vendorAccountInformation === 'Y');
    const canSeeMarketingInsights = useSelector(state => state.settings.siteSettings?.marketingInsights === 'Y');
    const canSeeBrandedEcommerce = useSelector(state => state.settings.siteSettings?.brandedEcommerce === 'Y');    
    const isRMARequired = useSelector(state => state.settings.isRMARequired);

    const myAccountMenu = [
        {
            title: 'All Orders',
            url: '/orders/history',
            visible: canSeeOrdersMenu,
        },
        {
            title: 'Company Profile',
            url: '/settings',
            visible: canEditSettings,
        },
        {
            title: 'My Profile',
            url: '/settings/profile',
            visible: true,
        },
    ];

    const ordersMenu = [
        {
            url: '/bids',
            title: 'Bids',
            visible: canSeeBids,
            items: [{
                title: 'Open Bids',
                url: '/bids?tab=OpenBids',
                visible: true,
            },
            {
                title: 'My Bids',
                url: '/bids?tab=MyBids',
                visible: true,
            }],
        },
        {
            url: '/orders',
            title: 'Orders',
            visible: canSeeOrders,
            items: [{
                title: 'Awaiting Review',
                url: '/orders?tab=AwaitingReview',
                visible: true,
            },
            {
                title: 'Pending Shipment',
                url: '/orders?tab=PendingShipment',
                visible: true,
            },
            {
                title: 'Shipped',
                url: '/orders?tab=Shipped',
                visible: true,
            },
            {
                title: 'Delivered',
                url: '/orders?tab=Delivered',
                visible: true,
            }],
        },
        {
            url: '/repairs',
            title: 'Repairs',
            visible: canSeeRepairs,
            items: [{
                title: 'RMA #',
                url: '/repairs?tab=RMA',
                visible: isRMARequired,
            },
            {
                title: 'Pending Shipment',
                url: '/repairs?tab=PendingShipment',
                visible: true,
            },
            {
                title: 'Shipped Inbound',
                url: '/repairs?tab=ShippedInbound',
                visible: true,
            },
            {
                title: 'Evaluating',
                url: '/repairs?tab=Evaluating',
                visible: true,
            },
            {
                title: 'Quoted',
                url: '/repairs?tab=Quoted',
                visible: true,
            },
            {
                title: 'Backordered',
                url: '/repairs?tab=Backordered',
                visible: true,
            },
            {
                title: 'Repairing',
                url: '/repairs?tab=Repairing',
                visible: true,
            },
            {
                title: 'Shipped Outbound',
                url: '/repairs?tab=ShippedOutbound',
                visible: true,
            },
            {
                title: 'Delivered',
                url: '/repairs?tab=Delivered',
                visible: true,
            }],
        },
        {
            url: '/loaners-rentals',
            title: 'Loaners',
            visible: canSeeLoanersAndRentals,
            items: [{
                title: 'Ordered',
                url: '/loaners-rentals?tab=Ordered',
                visible: true,
            },
            {
                title: 'Shipped Outbound',
                url: '/loaners-rentals?tab=ShippedOutbound',
                visible: true,
            },
            {
                title: 'Received & In Use',
                url: '/loaners-rentals?tab=ReceivedInUse',
                visible: true,
            },
            {
                title: 'Shipped Inbound',
                url: '/loaners-rentals?tab=ShippedInbound',
                visible: true,
            },
            {
                title: 'Returned',
                url: '/loaners-rentals?tab=Returned',
                visible: true,
            },
            {
                title: 'Inspected',
                url: '/loaners-rentals?tab=Inspected',
                visible: true,
            }],
        },
        {
            url: '/exchanges-returns',
            title: 'Exchanges & Returns',
            visible: canSeeReturnsAndExchanges,
            items: [{
                title: 'Process',
                url: '/exchanges-returns?tab=Process',
                visible: true,
            },
            {
                title: 'Pending PartsSource',
                url: '/exchanges-returns?tab=PendingPartsSource',
                visible: true,
            },
            {
                title: 'Pending',
                url: '/exchanges-returns?tab=Pending',
                visible: true,
            },
            {
                title: 'Shipped Inbound',
                url: '/exchanges-returns?tab=ShippedInbound',
                visible: true,
            },
            {
                title: 'Received',
                url: '/exchanges-returns?tab=Received',
                visible: true,
            },
            {
                title: 'Inspected',
                url: '/exchanges-returns?tab=Inspected',
                visible: true,
            },
            ],
        },
    ];

    let menu = {};

    if (canSeeOrdersMenu) {
        menu.ordersMenu = {
            menu: ordersMenu,
            title: 'Order Management',
        };
    }

    if (canSeePerformance) {
        menu.performanceMenu = {
            title: 'Performance',
            url: '/insights',
        }
    }

    if (canSeeBrandedEcommerce) {
        menu.brandedEcommerceMenu = {
            title: 'Branded eCommerce',
            url: '/branded-ecommerce',
        }
    }

    if (canSeeMarketingInsights) {
        menu.marketingInsightsMenu = {
            title: 'Marketing Insights',
            url: '/marketing-insights',
        }
    }

    menu.resourcesMenu = {
        title: 'Resources',
    }

    menu.myAccountMenu = {
        title: 'My Account',
        menu: myAccountMenu,
    }

    return menu;
}
