import React from 'react';
import * as PropTypes from 'prop-types';
import {Button} from '@partssourceinc/react-ui-core';
import {
    CardAction,
} from 'components/CardView/StyledComponents';
import ShippingInfoCardV2 from 'components/ShippingInfoCardV2';
import PrintingOptions from './PrintingOptions';

const OrdersCardFooter = (props) => {
    const {cardData,
        showProcessPopup,
        onPrintOptionChange,
        showEsdConfirmationDialogPopup,
        isPrinting,
    } = props;

    let showReviewShipping = false;
    if (cardData && !cardData?.isPastDue && !cardData?.hasMissingData) {
        showReviewShipping = true;
    }

    switch (cardData.status) {
        case 'AWAITINGREVIEW':
            return (
                <div>
                    
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true}
                        title="Estimated Ship Dates" subText={cardData.addressInfo}
                        date={cardData.estimatedShipDate}
                        trackingNumber={cardData.trackingNumber}
                        additionalText={cardData.shippingAttention}
                        trackingText={cardData?.shipper} noDateText="Pending" noTimeText="End of Day" />
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting} cardData={cardData}
                            onPrintOptionChange={onPrintOptionChange} />
                        {showReviewShipping ?                            
                            <CardAction>
                                <Button secondary={true} onClick={() => showEsdConfirmationDialogPopup(cardData)}>
                                    REVIEW SHIPPING</Button>
                            </CardAction> :
                            <CardAction>
                                <Button secondary={true} onClick={() => showProcessPopup(cardData)}>
                                    PROCESS</Button>
                            </CardAction>
                        }
                    </React.Fragment>
                </div>
            );
        case 'PENDINGSHIPMENT':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true}
                        title="Estimated Ship Date" subText={cardData.addressInfo}
                        date={cardData.estimatedShipDate}
                        trackingNumber={cardData.trackingNumber}
                        additionalText={cardData.shippingAttention}
                        trackingText={cardData?.shipper} noDateText="Pending" noTimeText="End of Day" />
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting} cardData={cardData}
                            onPrintOptionChange={onPrintOptionChange} />
                        <CardAction>
                            <Button secondary={true} onClick={() => showProcessPopup(cardData)}>
                                PROCESS</Button>
                        </CardAction>
                    </React.Fragment>
                </div>
            );
        case 'SHIPPED':
            return (
                <div>
                    
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true}
                        title="Shipped Date" subText={cardData.addressInfo}
                        date={cardData.dateShipped}
                        trackingNumber={cardData.trackingNumber}
                        additionalText={cardData.shippingAttention}
                        trackingText={cardData?.shipper} />

                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting} cardData={cardData}
                            onPrintOptionChange={onPrintOptionChange} />
                    </React.Fragment>
                </div>
            );
        case 'DELIVERED':
            return (
                <div>
                    <ShippingInfoCardV2 showDateInfo={true} showTrackingInfo={true}
                        title="Delivered" subText={cardData.addressInfo}
                        date={cardData.dateDelivered}
                        trackingNumber={cardData.trackingNumber}
                        additionalText={cardData.shippingAttention}
                        trackingText={cardData?.shipper} />
                    <React.Fragment>
                        <PrintingOptions isPrinting={isPrinting} cardData={cardData}
                            onPrintOptionChange={onPrintOptionChange} />
                    </React.Fragment>
                </div>
            );
        default:
            return null;
    }
}

OrdersCardFooter.propTypes = {
    cardData: PropTypes.shape({
        dateCreated: PropTypes.instanceOf(Date),
        dateDelivered: PropTypes.instanceOf(Date),
        dateShipped: PropTypes.string,
        estimatedShipDate: PropTypes.string,
        followUpDate: PropTypes.string,
        gsaItem: PropTypes.string,
        lineItemId: PropTypes.number,
        shipper: PropTypes.string,
        addressInfo: PropTypes.string,
        shippingAttention: PropTypes.string,
        status: PropTypes.string,
        trackingNumber: PropTypes.string,
        vendorOrderNumber: PropTypes.string,
        hasShippingLabel: PropTypes.bool,
        isPastDue: PropTypes.bool,
        hasMissingData: PropTypes.bool,
    }),
    isPrinting: PropTypes.bool,
    onSelectCard: PropTypes.func.isRequired,
    showProcessPopup: PropTypes.func.isRequired,
    onPrintOptionChange: PropTypes.func.isRequired,
    showEsdConfirmationDialogPopup: PropTypes.func.isRequired,
}

export default OrdersCardFooter;
