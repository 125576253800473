import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ShippingHeader from 'components/ShippingHeader';
import {HeaderRow} from 'components/DetailsStyledComponents';
import moment from 'moment';

const Row = styled.div`
    margin-bottom: 1rem;
`;

const Subtitle = styled.div`
    font-size: 16px;
    float: left;
`;

const LeftColumn = styled.div`
    display: inline-block;
    vertical-align: text-top;
    width: 20%;
    margin-right: 20px;

    @media (min-width: 961px) and (max-width: 1136px) {
        width: 30%;
    }

    @media (min-width: 820px) and (max-width: 960px) {
        width: 40%;
    }

    @media (max-width: 819px) {
        width: 100%;
    }
`;

const RightColumn = styled.div`
    vertical-align: text-top;
    display: inline-flex;
    flex-direction: column;
    float: right;
    width: 70%;

    @media (min-width: 961px) and (max-width: 1136px) {
        width: 40%;
    }

    @media (min-width: 820px) and (max-width: 960px) {
        width: 50%;
    }

    @media (max-width: 819px) {
        width: 100%;
    }
`;

const BoldSubtitle = styled(Subtitle)`
    font-weight: 600;
    padding-left: 5px;
`;

const GraySubtitle = styled(Subtitle)`
    font-weight: bold;
    color: rgb(148, 148, 148);    
`;

const LoanerHeader = (props) => {
    const {
        loaner = {},
    } = props;
        
    return (
        <div className="row" style={{paddingLeft: '20px'}}>               
            <LeftColumn>
                <Row className="row">                    
                    <HeaderRow className="col-11">
                        <Subtitle>Date Created: </Subtitle><BoldSubtitle>{moment(loaner?.dateCrated).format('MM/D/YYYY')}</BoldSubtitle>
                    </HeaderRow>                    
                    <HeaderRow className="col-11">
                        <Subtitle>Facility: </Subtitle><BoldSubtitle>{loaner?.facility}</BoldSubtitle>
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>Requestor: </Subtitle><BoldSubtitle>{loaner?.requestor}</BoldSubtitle>
                    </HeaderRow>   
                </Row>
            </LeftColumn>
            <RightColumn>
                <ShippingHeader order={loaner} />
                <Row className="row">
                    <HeaderRow className="col-11">
                        <GraySubtitle>Payment Information</GraySubtitle>                        
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>P.O. #: </Subtitle><BoldSubtitle>{loaner?.displayPo ?? 'Pending'}</BoldSubtitle>
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>Payment Method:</Subtitle>
                    </HeaderRow>
                    <HeaderRow className="col-11">
                        <Subtitle>Billing Address: </Subtitle>
                    </HeaderRow>                    
                </Row>
            </RightColumn>
        </div>
    );
};

export default LoanerHeader;

LoanerHeader.propTypes = {
    loaner: PropTypes.shape({}),
};
