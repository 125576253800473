import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import DatePicker from 'components/DatePicker';
import {Button, TextField, brandFont, theme, Dropdown, Popup} from '@partssourceinc/react-ui-core';
import PropTypes from 'prop-types';
import axios from 'axios';
import {useSelector} from 'react-redux';
import moment from 'moment';

const Label = styled.p`
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 10px;
`;

const TextBlock = styled(TextField)`
    margin-bottom: 15px;

    > div {
        width: 630px;
        height: 107px;
    }

    ${brandFont('16px')}
`;

const Reference = styled.div`
    font-family: "Source Sans Pro", sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 15px;

    a {
        font-size: 16px;
        text-decoration: underline;
        color: ${theme.grey1};
    }
`;

const Line = styled.hr`
    border-top: 1px solid #949494;
    height: 2px;
    width: 630px;
    float: left;
`;

const RepairsModal = styled(Popup)`
    max-width: 700px;
`;

const DropdownList = styled(Dropdown)`
    width: 280px;    
    
    div {
        span {
            font-weight: 700;
            font-size: 12px;
            color: #005DA6;
            margin: 4px 10px 4px 10px;
        }
    }
`;

const SaveButton = styled(Button)`
    float: right;
`;

const CancelButton = styled(Button)`
    color: rgb(119, 119, 119);
    background-color: white;
    float: right;

    :hover{
        background-color: unset !important;
        color: unset !important;
        color: rgb(119, 119, 119);
    }
`;

const CustomTextField = styled(TextField)`
   max-width: 20em; 
   min-width: 280px;
`;

const EsdConfirmationDialog = ({orderId, date, trackingNumber, lineItemId, shipDateReasonId, show, onCancel, onSubmit, type, poNum}) => {
    const companyId = useSelector(state => state.user.companyId);
    const esdReasons = useSelector(state => type === 'part' ? state.common?.esdPartReasons : state.common?.esdRepairReasons);
    const [note, setNote] = useState('');
    const [reason, setReason] = useState();
    const [esd, setEsd] = useState();
    const [loading, setLoading] = useState(false);
    const [updatedTrackingNumber, setUpdatedTrackingNumber] = useState(trackingNumber);
    const orderDetailsUrl = poNum ? `/order-details/po/${poNum}` : `/order-details/${orderId}`;
    useEffect(() => {
        if (date) {
            const convertedEsd = new Date(date);
            const formattedDate = new Date(convertedEsd.getFullYear(), convertedEsd.getMonth(), convertedEsd.getDate())
            setEsd(formattedDate);
        }
    }, [date]);

    const handleEsdSave = async () => {
        const request = {
            companyId: companyId,
            orderId: orderId,
            lineItems: [{
                lineItemId: lineItemId,
                shipDateReasonId: reason,
                estimatedShipDate: esd,
                trackingNumber: updatedTrackingNumber,
                note: note,
            }],
        };

        setLoading(true);
        const resp = await axios.post(`/order/esd`, request);
        setLoading(false);        
        onSubmit(resp);
    }

    const onPropertyChange = ({target: {name, value}}, index) => {
        switch (name) {
            case 'trackingNumber':
                setUpdatedTrackingNumber(value);
                break;
            case 'shipDateReasonId':
                setReason(value);
                break;
        }
    }

    const onEsdChange = (e) => {
        const newEsd = e.target.value ? moment(e.target.value).format('MM/DD/YY') : null;
        setEsd(newEsd);
    }

    const setFocusToReasons = () => {
        document.getElementById('esdReasons').focus();
    }
    
    return (
        <RepairsModal
            show={show}
            onCancel={onCancel}
            onConfirm={handleEsdSave}
            hideButtons={true}>
            <Reference>
                <span>Review Shipment for Reference #{lineItemId}</span>
                <a href={orderDetailsUrl}> View Details</a>
            </Reference>
            <Label>Shipping</Label>
            <CustomTextField
                tabIndex={0}
                label={'Tracking Number'}
                text={updatedTrackingNumber}
                name={'trackingNumber'}
                onChange={(e) => onPropertyChange(e)}
            />
            <Label>Estimated Ship Date</Label>
            <DatePicker onHide={setFocusToReasons} tabIndex={1} value={esd} onChange={(e) => onEsdChange(e)} />
            <DropdownList tabIndex={2} style={{marginTop: '10px'}} label="Reason" name={'shipDateReasonId'}
                options={esdReasons} selectedValue={reason}
                id="esdReasons"
                suppressBlankOption={true} valueField="id" textField="description"
                onChange={(e) => onPropertyChange(e)}
            />
            <Label>Note</Label>
            <TextBlock
                tabIndex={4}
                multiLine={true}
                name="note"
                type="text"
                placeholder="Note"
                maxLength={100}
                text={note}
                onBlur={e => setNote(e.target.value)}
                label="Note" />
            <Line />
            <SaveButton
                disabled={(!esd && reason !== '2')}
                primary={true}
                onClick={handleEsdSave}
                loading={loading}>
                OK
            </SaveButton>
            <CancelButton
                secondary={true}
                onClick={onCancel}>
                CANCEL
            </CancelButton>
        </RepairsModal>
    );
}

export default EsdConfirmationDialog;

EsdConfirmationDialog.propTypes = {
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    lineItemId: PropTypes.number,
    note: PropTypes.string,
    type: PropTypes.string,
    trackingNumber: PropTypes.string,
    poNum: PropTypes.string,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]) ,
    shipDateReasonId: PropTypes.number,
    show: PropTypes.bool,
};
