import axios from 'axios';
import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Button, theme, brandFont} from '@partssourceinc/react-ui-core';
import {BrComponent} from '@bloomreach/react-sdk';

import styled from 'styled-components';

const Footer = styled.div`
    margin-top: 24px;
    margin-bottom: 96px;
    padding: 16px 0px 0px 0px;
    border-top: 1px solid ${theme.grey3};
    text-align: right;
    @media (max-width: ${theme.screenXsMax}) {
        margin-top: 24px;
        padding: 16px 0px 0px 0px;
        border-top: 1px solid ${theme.grey3};
        position: relative;
        bottom: 0px;
    }
    >:not(:first-child) {
        margin-left: .25rem;
    }
    >:not(:last-child) {
        margin-right: .25rem;
    }
`;

const CancelLink = styled.a`
    ${brandFont('14px')};
    color: ${theme.grey3};
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    text-transform: uppercase;
    margin-right: 20px !important;
    outline: none;
    &:hover {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }
    &:focus {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }
    &:active:focus {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }
`;

const PrivacyPolicyContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`;

const PoliciesAcceptance = () => {
    const isLoggedIn = useSelector(state => !!state.user.tokenInfo);
    const history = useHistory();

    const acceptPolicies = () => {
        axios.post(`/user/policy/accept`, {agree: true})
            .then((response) => {
                if (response.data.success) {
                    window.location.replace('/');
                }
            });
    }

    const declinePolicies = () => history.push('/sso/sisense/logout');

    return (<div style={{marginTop: '48px'}}>
        <div style={{margin: '-16px auto', maxWidth: '768px', minWidth: '280px', marginBottom: '24px', paddingTop: '24px'}}>
            <PrivacyPolicyContainer>
                <BrComponent path={'main'} />
            </PrivacyPolicyContainer>
        </div>

        {isLoggedIn ?
            <Footer>
                <CancelLink onClick={declinePolicies} tabIndex="0">Decline</CancelLink>
                <Button onClick={acceptPolicies} className="button button-primary"
                    style={{width: '100px', marginBottom: '10px', marginRight: '20px'}}>
                    Accept
                </Button>
            </Footer> :
            null
        }
    </div>)
}

export default PoliciesAcceptance;
