export const GridKeys = [
    {
        cardAction: 'Process',
        fieldName: 'returnExchangeStatus',
        id: 'Process',
        tabDisplay: 'Process',
        value: 'PROCESS',
    },
    {
        cardAction: 'PendingPartsSource',
        fieldName: 'returnExchangeStatus',
        id: 'PendingPartsSource',
        tabDisplay: 'Pending PartsSource',
        value: 'PENDINGPARTSSOURCE',
    },
    {
        cardAction: 'Pending',
        fieldName: 'returnExchangeStatus',
        id: 'Pending',
        tabDisplay: 'Pending',
        value: 'PENDING',
    }, {
        cardAction: 'ShippedInbound',
        fieldName: 'returnExchangeStatus',
        id: 'ShippedInbound',
        tabDisplay: 'Shipped Inbound',
        value: 'SHIPPEDINBOUND',
    }, {
        cardAction: 'Received',
        fieldName: 'returnExchangeStatus',
        id: 'Received',
        tabDisplay: 'Received',
        value: 'RECEIVED',
    }, {
        cardAction: 'Inspected',
        fieldName: 'returnExchangeStatus',
        id: 'Inspected',
        tabDisplay: 'Inspected',
        value: 'INSPECTED',
    },
];
