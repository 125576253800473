import React, {useState} from 'react';
import styled from 'styled-components';
import {TextField, brandFont, theme, Popup, Button} from '@partssourceinc/react-ui-core';
import PropTypes from 'prop-types';
import axios from 'axios';
import Attachments from '../../components/AttachFile';
import {getPageAndTab, logEvent, getErrorMessage, handleOnCurrencyKeyDown} from 'utility';
import AttachmentsViewer from 'components/AttachFileViewer';
import ErrorModal from 'components/ErrorModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/pro-regular-svg-icons';
import {useSelector} from 'react-redux';
import {validateMoney} from 'utils/validation';
import {setCachedAttachments} from 'reducers/attachments';
import {useDispatch} from 'react-redux';

const Label = styled.p`
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 10px;
    clear: both;
`;

const Subtitle = styled.div`
    font-size: 16px;
    float: left;
`;

const TextBlock = styled(TextField)`
    margin-bottom: 15px;

    > div {
        width: 100%;
        height: 107px;
    }

    ${brandFont('16px')}
`;

const Reference = styled.div`
    font-family: "Source Sans Pro", sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 15px;

    a {
        font-size: 16px;
        text-decoration: underline;
        color: ${theme.grey1};
    }
`;

const Line = styled.hr`
    border-top: 1px solid #949494;
    height: 2px;
    width: 100%;
    float: left;
`;

const ModalBody = styled(Popup)`
    max-width: 700px;
    height: fit-content !important;
`;

const SaveButton = styled(Button)`
    float: right;
`;

const CustomTextField = styled(TextField)`
   max-width: 20em; 
   min-width: 280px;
   float: left;
`;

const Row = styled.div`
    margin-bottom: 10px;
`;

const Icon = styled(FontAwesomeIcon)`
    float: left;
    color: black;
    margin-top: 4px;
    margin-right: 5px;
    margin-left: 5px;
    color: #4a4a4a;
    cursor: pointer;
`;

const BlueText = styled(Subtitle)`
    color: rgb(0, 93, 166);
    padding-left: 0;
    cursor: pointer;
    font-weight: bold;
`;

const QuotedRepairModal = ({orderId, lineItemId, rgaHeaderId, isRequote, show, onCancel, rgaType, statusId, onSubmit, poNumber}) => {
    const [diagnosisNote, setDiagnosisNote] = useState('');
    const [repairNote, setRepairNote] = useState('');
    const [amount, setAmount] = useState('');
    const [leadDays, setLeadDays] = useState('');
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState({repairNote: null});
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showAttachmentEditor, setShowAttachmentEditor] = useState(false);
    const cachedAttachments = useSelector(state => state.attachments.cachedAttachments);
    const dispatch = useDispatch();

    const handleSubmit = async () => {

        handleLogEvent();

        const request = {
            lineItemId,
            rgaHeaderId,
            diagnosisNote,
            repairNote,
            amount: amount ? amount.replace('$', '') : null,
            leadDays,
            note,
            statusId,
            isRequote,
            attachments: [...cachedAttachments],
        };

        setLoading(true);
        await axios.post(`/order/quote`, request)
            .then((response) => {
                dispatch(setCachedAttachments([]));
                onSubmit(response);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
                setShowErrorPopup(true);
            });
    }
    const onAttachmentUpload = (attachment) => setShowAttachmentEditor(false);

    const handleOnCancel = () => {
        dispatch(setCachedAttachments([]));
        onCancel();
    }

    const handleLogEvent = () => {

        const {tab, page} = getPageAndTab();

        if (page === 'repairs' && (tab === 'Evaluating' || tab === 'Quoted' || tab === 'Backordered' || tab === 'Repairing')) {
            logEvent('Repair Update', {
                'Ref number': lineItemId,
                'Order Number': orderId,
                'RGA Type': rgaType,
                'Tab': tab,
                'Page': page,
            });
        } else {
            logEvent('RECEIVE ITEM', {
                'Ref number': lineItemId,
                'Order Number': orderId,
                'RGA Type': rgaType,
                'Tab': tab,
                'Page': page,
            });
        }
    }

    const onAttachmentCancel = () => setShowAttachmentEditor(false);

    const handleUpdateField = (e, callback) => {
        const content = e.target.value;
        const id = e.target.id;
        let err = {...errorMessages};

        const longText = content.length > 4000;
        callback(longText ? content.substring(0, 4000) : content);
        if (longText) {
            err[id] = `Please limit your text to max 4000 characters.`;
            setErrorMessages(err);
            return;
        } else {
            err[id] = null;
            setErrorMessages(errorMessages);
        }
    }

    const onAmountUpdate = (value) => {
        let err = {...errorMessages};

        if (Number(value) === '' || Number(value) === 0 || !validateMoney(value)) {
            err.amount = 'Invalid amount!';
            setErrorMessages(err);
        } else {
            setAmount(value);
            err.amount = null;
            setErrorMessages(err);
        }
    }

    const onLeadDaysUpdate = (value) => {
        let err = {...errorMessages};

        if (Number(value) === '' || Number(value) <= 0 || value > 999) {
            err.leadDays = 'Invalid lead days!';
            setErrorMessages(err);
        } else {
            setLeadDays(value);
            err.leadDays = null;
            setErrorMessages(err);
        }
    }

    const handleOnLeadDaysKeyDown = event => {
        const keyCode = event.keyCode;
        const allowedKeyCodes = [8, 9, 37, 39, 46];
        if ((keyCode >= 107 && keyCode <= 111) || (keyCode >= 187 && keyCode <= 190)) {
            event.preventDefault();
        }

        return allowedKeyCodes.includes(keyCode) ? true : (isNaN(Number(event.key)));
    }

    return (
        <ModalBody
            show={show}
            onCancel={handleOnCancel}
            hideButtons={true} >
            <Reference>
                <span>{isRequote ? 'Requote' : 'Quote'} for Reference #{lineItemId}</span> 
                <a href={`/repair-details/${poNumber ? `PO/${poNumber}` : orderId}`}>View Details</a>
            </Reference>

            <Label style={{marginBottom: 15}}>Diagnosis*</Label>
            <TextBlock
                name="diagnosisNote"
                id="diagnosisNote"
                type="text"
                placeholder="Diagnosis"
                text={diagnosisNote}
                value={diagnosisNote}
                maxLength={4000}
                multiLine={true}
                label="Diagnosis" 
                errorMessage={errorMessages.diagnosisNote}
                showErrorMessage={errorMessages.diagnosisNote}
                onChange={(e) => handleUpdateField(e, setDiagnosisNote)} />

            <Label style={{marginBottom: 15}}>Repair*</Label>
            <TextBlock
                name="repairNote"
                id="repairNote"
                type="text"
                placeholder="Repair"
                text={repairNote}
                value={repairNote}
                maxLength={4000}
                multiLine={true}
                label="Repair" 
                errorMessage={errorMessages.repairNote}
                showErrorMessage={errorMessages.repairNote} 
                onChange={(e) => handleUpdateField(e, setRepairNote)} />

            {!showAttachmentEditor ? (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Label style={{marginBottom: 0}}>Attachments</Label>
                    <AttachmentsViewer serverAttachments={[]} />
                    <Row style={{marginLeft: '-5px'}}>
                        <Icon aria-hidden="true" icon={faCopy} />
                        <BlueText onClick={() => setShowAttachmentEditor(!showAttachmentEditor)}>Add Attachment</BlueText>
                    </Row>
                </div>
            ) :
                <Row className="col-11">
                    <Attachments 
                        handleOnSave={(e) => onAttachmentUpload(e)}
                        handleOnCancel={() => onAttachmentCancel()}
                        memorySave={true}
                        showCancel={true}
                        allowMultipleFiles={true}
                        customSave={false}
                    />
                </Row>
            } 

            <Label style={{marginBottom: 15}}>{isRequote ? 'New Total Price' : 'Pricing'} &amp; Turn-time</Label>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <CustomTextField
                    label={'Amount'}
                    name={'amount'}
                    text={amount}
                    value={amount}
                    style={{marginBottom: 15}}
                    onKeyDown={handleOnCurrencyKeyDown}
                    onChange={(e) => onAmountUpdate(e.target.value)}
                    errorMessage="Invalid amount!"
                    showErrorMessage={errorMessages.amount} />

                <CustomTextField
                    label={'Lead Days'}
                    name={'leadDays'}
                    type={'number'}
                    min={0}
                    max={999}
                    text={leadDays}
                    value={leadDays}
                    style={{marginBottom: 15}}
                    onKeyDown={handleOnLeadDaysKeyDown}
                    onChange={(e) => onLeadDaysUpdate(e.target.value)}
                    errorMessage="Invalid value for the lead days"
                    showErrorMessage={errorMessages.leadDays} /> 
            </div>

            <TextBlock
                name="note"
                id="note"
                type="text"
                placeholder="Comment (optional)"
                text={note}
                value={note}
                maxLength={4000}
                multiLine={true}
                onChange={(e) => setNote(e.target.value)}
                label="Comment (optional)" />   

            <Line />

            <SaveButton
                disabled={(!diagnosisNote || errorMessages.diagnosisNote) || (!repairNote || errorMessages.repairNote)  
                    || (!amount || errorMessages.amount) || (!leadDays || errorMessages.leadDays) }
                primary={true}
                onClick={handleSubmit}
                loading={loading}>
                SAVE CHANGES
            </SaveButton>
            <ErrorModal
                show={showErrorPopup}
                popupTitle={'Quoted Repair'}
                popupMessage={getErrorMessage('repair')}
                onCancel={() => setShowErrorPopup(false)} />
        </ModalBody >
    );
}

export default QuotedRepairModal;

QuotedRepairModal.propTypes = {
    orderId: PropTypes.number,
    rgaHeaderId: PropTypes.number,
    rgaType: PropTypes.string,
    lineItemId: PropTypes.number,
    show: PropTypes.bool,
    isRequote: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    statusId: PropTypes.number,
    poNumber: PropTypes.string,
};
