import React from 'react';
import {
    Dropdown,
    Checkbox,
    TextField,
    RadioButtonList,
    brandFont,
    semiBoldBrandFont,
} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import {timelineLabels} from 'utils/tools';
import {validateEmail, validatePhoneNumber} from 'utils/validation';
import {timeZones} from 'utils/TimezoneData';
import {useSelector, useDispatch} from 'react-redux';
import {saveShippingAndReturns} from 'reducers/supplier';
import useForm from 'hooks/useForm';
import FormFooter from 'components/FormFooter';
import {SubTitle, BackLink, LeftArrow} from 'utils/styles';
import AccordionSection from 'components/AccordionSection';
import {useState} from 'react';
import {faAngleLeft} from '@fortawesome/pro-regular-svg-icons';

const Wrapper = styled.div`
    h3 {
        margin-top: 3rem;
    }
`;

const TextBlock = styled(TextField)`
    margin-bottom: 15px;

    > div {
        max-width: 23em;
    }

    ${brandFont('16px')}
`;

const SelectionList = styled(RadioButtonList)`
    position: absolute;

    > div {
        margin-right: 20px;
        label{
            min-width: auto;
        }
    }
`;

const Title = styled.h3`
    ${semiBoldBrandFont('16px')}
`;

const DropdownList = styled(Dropdown)`
    max-width: 28em;
`;

const Group = styled.div`
    width: 100%;
    min-height: 70px;
    left: 0px;
    top: 0px;
    position: relative;

    > div {
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
    }

    > div > div {
        display: inline-block;
    }
`;

const form = {
    shipCutoffTimeUTC: {
        required: true,
    },
    afterHoursPhone: {
        validator: {
            func: value => validatePhoneNumber(value),
        },
    },
    supplierTimeZone: {
        required: true,
    },
    rgaContactEmail: {
        validator: {
            func: value => validateEmail(value),
        },
    },
};

const ShippingAndReturns = () => {
    const isLoading = useSelector(state => state.supplier.isLoading);

    return (
        <Wrapper>
            <h1>Shipping & Returns</h1>
            <span>
                <LeftArrow icon={faAngleLeft} />
                <BackLink to="/settings">Back to Company Profile</BackLink>
            </span>
            {!isLoading && <ShippingAndReturnsForm />}
        </Wrapper>
    )
}

const ShippingAndReturnsForm = () => {
    const dispatch = useDispatch();
    const isSaving = useSelector(state => state.supplier.isSaving);
    const shippingAndReturns = useSelector(state => state.supplier.shippingAndReturns);
    const userTimeZone = useSelector(state => state.user.timeZoneId);
    const timePeriodList = timelineLabels('06:00', 30, 'm');
    const [returnsSectionOpen, setReturnsSectionOpen] = useState(false);

    const handleFormSubmit = async (values, dirtyValues) => {
        const updateAfterHoursContact = dirtyValues.afterHoursName || dirtyValues.afterHoursPhone;
        const updateRGAContact = dirtyValues.rgaContactName || dirtyValues.rgaContactEmail;

        values.returnPolicyDays = values.returnPolicyDays === 1 ? 0 : values.returnPolicyDays;
        values.shipCutoffTimeUTC = values.shipCutoffTimeUTC === timePeriodList[0].id ? 0 : values.shipCutoffTimeUTC;

        await dispatch(saveShippingAndReturns(values, updateAfterHoursContact, updateRGAContact));
    }

    const {
        values,
        errors,
        dirty,
        handleOnChange,
        handleOnSubmit,
        disable,
    } = useForm(shippingAndReturns, form, handleFormSubmit);

    const yesNoOptions = [
        {value: 'true', label: 'Yes'},
        {value: 'false', label: 'No'},
    ];

    const returnPolicyDaysCount = [
        {
            id: 1,
            count: '0',
        },
        {
            id: 5,
            count: '5',
        },
        {
            id: 10,
            count: '10',
        },
        {
            id: 15,
            count: '15',
        },
        {
            id: 20,
            count: '20',
        },
        {
            id: 25,
            count: '25',
        },
        {
            id: 30,
            count: '30',
        },
    ]

    const restockPercentage = [
        {
            id: 0,
            percentage: '0%',
        },
        {
            id: 5,
            percentage: '5%',
        },
        {
            id: 10,
            percentage: '10%',
        },
        {
            id: 15,
            percentage: '15%',
        },
        {
            id: 20,
            percentage: '20%',
        },
        {
            id: 25,
            percentage: '25%',
        },
        {
            id: 30,
            percentage: '30%',
        },
        {
            id: 35,
            percentage: '35%',
        },
        {
            id: 40,
            percentage: '40%',
        },
        {
            id: 50,
            percentage: '50%',
        },
    ]

    const exchangeCoreDaysCount = [
        {
            id: 5,
            count: '5',
        },
        {
            id: 10,
            count: '10',
        },
        {
            id: 15,
            count: '15',
        },
        {
            id: 30,
            count: '30',
        },
    ]

    const filterOutCharacters = (number) => {
        const lastChar = number.substr(number.length - 1).match(/[a-z]/i);
        return isNaN(lastChar);
    }

    const shippingErrors = [errors.shipCutoffTimeUTC, errors.afterHoursPhone, errors.supplierTimeZone];
    const returnsErrors = [errors.returnPolicyDays, errors.rgaContactEmail];
    const exchangesErrors = [errors.exchangeCoreDays];

    return (
        <form>
            <AccordionSection title="Shipping" errors={shippingErrors}>
                <SubTitle>PartsSource customers have high expectations around product deliverability and reliability of orders.
                    Up to date shipping information from supplier partners is critical to delivering on these expectations.</SubTitle>
                <Title>
                    Shipping Cut Off Time
                </Title>

                <DropdownList
                    label="Shipping Cut Off Time"
                    name="shipCutoffTimeUTC"
                    onChange={handleOnChange}
                    options={timePeriodList}
                    selectedValue={values.shipCutoffTimeUTC || timePeriodList[0].id}
                    suppressBlankOption={true}
                    valueField="id"
                    textField="timePeriod" />
                <Title>
                    After hours contact
                </Title>
                <TextBlock
                    name="afterHoursName"
                    type="text"
                    placeholder="Contact Name"
                    maxLength={100}
                    text={values.afterHoursName}
                    onBlur={handleOnChange}
                    label="Contact Name" />
                <TextBlock
                    name="afterHoursPhone"
                    type="text"
                    placeholder="Phone Number"
                    text={values.afterHoursPhone}
                    onChange={handleOnChange}
                    label="Phone Number"
                    showErrorMessage={errors.afterHoursPhone}
                    onValueChange={filterOutCharacters} />
                <Title>Time Zone</Title>
                <DropdownList
                    label="Time Zone"
                    onChange={handleOnChange}
                    options={timeZones}
                    name="supplierTimeZone"
                    selectedValue={values.supplierTimeZone || userTimeZone || ''}
                    suppressBlankOption={false}
                    valueField="zoneId"
                    textField="zoneName"
                    showErrorMessage={errors.supplierTimeZone} />
                <Title>Carriers Used</Title>
                <Checkbox
                    id="fedex"
                    label="Fedex"
                    name="useFedEx"
                    checked={values.useFedEx}
                    onChange={handleOnChange} />
                <Checkbox
                    id="ups"
                    label="UPS"
                    name="useUPS"
                    checked={values.useUPS}
                    onChange={handleOnChange} />
                <Group>
                    <Title>Do you drop ship</Title>
                    <SelectionList
                        name="dropShip"
                        selectedValue={values.dropShip}
                        options={yesNoOptions}
                        onChange={handleOnChange}
                        label="Do you drop ship" />
                </Group>
                <Group>
                    <Title>Do you blind ship</Title>
                    <SelectionList
                        name="blindShip"
                        selectedValue={values.blindShip}
                        options={yesNoOptions}
                        onChange={handleOnChange}
                        label="Do you blind ship" />
                </Group>
                <Group>
                    <Title>Do you use 3rd party account numbers?</Title>
                    <SelectionList
                        name="thirdPartyAcctNumbers"
                        selectedValue={values.thirdPartyAcctNumbers}
                        options={yesNoOptions}
                        onChange={handleOnChange}
                        label="Do you use 3rd party account numbers?" />
                </Group>
            </AccordionSection>

            <AccordionSection
                key={`returns_${values.acceptsUnopenPackageReturns}`}
                title="Returns"
                open={returnsSectionOpen}
                onClick={() => setReturnsSectionOpen(!returnsSectionOpen)}
                errors={returnsErrors}>
                <SubTitle>PartsSource processes returns for our customers and supplier partners.
                    Accurate information ensures all order returns are handled efficiently and effectively.
                </SubTitle>
                <Title>
                    Returns due by day of receipt
                </Title>
                <DropdownList
                    label="How many days after receipt are returns due?"
                    name="returnPolicyDays"
                    onChange={handleOnChange}
                    options={returnPolicyDaysCount}
                    selectedValue={values.returnPolicyDays || 1}
                    suppressBlankOption={true}
                    valueField="id"
                    textField="count"
                    showErrorMessage={false}
                />
                <Group>
                    <Title>
                        Accept returns for unopened packages?
                    </Title>
                    <SelectionList
                        name="acceptsUnopenPackageReturns"
                        selectedValue={values.acceptsUnopenPackageReturns}
                        options={yesNoOptions}
                        onChange={handleOnChange}
                        label="Accept return for unopened packages" />
                </Group>
                {values.acceptsUnopenPackageReturns === 'true' && <Title>Restock Fee %</Title>}
                {values.acceptsUnopenPackageReturns === 'true' && <DropdownList
                    label="Restock Fee %"
                    name="percentRestockFee"
                    onChange={handleOnChange}
                    options={restockPercentage}
                    selectedValue={values.percentRestockFee || ''}
                    suppressBlankOption={false}
                    valueField="id"
                    textField="percentage"
                    showErrorMessage={false} />}
                <Group>
                    <Title>
                        Accept returns for opened packages?
                    </Title>
                    <SelectionList
                        name="acceptsOpenPackageReturns"
                        selectedValue={values.acceptsOpenPackageReturns}
                        options={yesNoOptions}
                        onChange={handleOnChange}
                        label="Accept return for opened packages?" />
                </Group>
                <Group>
                    <Title>
                        Do you require RGA numbers?
                    </Title>
                    <SelectionList
                        name="rgaNumberRequired"
                        selectedValue={values.rgaNumberRequired}
                        options={yesNoOptions}
                        onChange={handleOnChange}
                        label="Do you require RGA numbers?" />
                </Group>
                <Title>Contact for RGA</Title>
                <TextBlock
                    name="rgaContactName"
                    type="text"
                    placeholder="Contact Name"
                    text={values.rgaContactName}
                    onChange={handleOnChange}
                    label="Contact Name" />
                <TextBlock
                    name="rgaContactEmail"
                    type="text"
                    placeholder="Contact Email"
                    text={values.rgaContactEmail}
                    onChange={handleOnChange}
                    label="Contact Email"
                    showErrorMessage={errors.rgaContactEmail} />
            </AccordionSection>

            <AccordionSection title="Exchanges" errors={exchangesErrors}>
                <DropdownList
                    label="How many days after receipt are exchange cores due?"
                    name="exchangeCoreDays"
                    onChange={handleOnChange}
                    options={exchangeCoreDaysCount}
                    selectedValue={values.exchangeCoreDays || 10}
                    suppressBlankOption={true}
                    valueField="id"
                    textField="count"
                    showErrorMessage={false} />
            </AccordionSection>

            <FormFooter dirty={dirty} handleOnSubmit={handleOnSubmit} loading={isSaving} disabled={disable} />
        </form>
    )
}

export default ShippingAndReturns;
