import React, {} from 'react';
import 'components/css/multiGrid.css';
import styled from 'styled-components';
import {theme} from '@partssourceinc/react-ui-core';
import PageBreadcrumbs from 'components/Breadcrumbs';

const HeaderWrapper = styled.div`
    display: flex;
    height: 287px;
    background: white;
    margin: 0px -20px;
    padding: 0px 20px;
    flex-direction: column;
    @media screen and (min-width: ${theme.screenXLMin}) {
        margin: 0 -50px;
        padding: 0 50px;
    }
    @media screen and (max-width: ${theme.screenLGMax}) {
        margin: 0 -50px;
        padding: 0 50px;
    }
    @media screen and (max-width: ${theme.screenMDMax}) {
        margin: 0 -20px;
        padding: 0 20px;
    }
    @media screen and (max-width: ${theme.screenSMMax}) {
        margin: 0 -20px;
        padding: 0 20px;
    }
    @media screen and (max-width: ${theme.screenXsMax}) {
        margin: 0 -20px;
        padding: 0 20px;
    }
    @media screen and (max-width: 320px) {
        margin: 0 -20px;
        padding: 0 20px;
    }
`

const SingleReportingPortalHeader = () => {

    return (
        <HeaderWrapper>
            <section>
                <PageBreadcrumbs style={{textAlign: 'left'}} title={`Visual Analytics`} />
            </section>
        </HeaderWrapper>
    );
}

export default SingleReportingPortalHeader;
