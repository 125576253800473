import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {setBreadCrumbs,setCachedOrders} from 'reducers/orders';
import ErrorModal from 'components/ErrorModal';
import {Loader, Button} from '@partssourceinc/react-ui-core';
import {setCachedLoanersRentals} from 'reducers/orders';
import axios from 'axios';
import _ from 'lodash'
import PageBreadcrumbs from 'components/Breadcrumbs';
import ProductImage from '@partssourceinc/react-ui-core/components/ProductImage';
import OrderLine from 'components/OrderLine';
import ReceivedDetails from 'components/Repairs/ReceivedDetails';
import LoanerFooter from 'components/Repairs/LoanerFooter';
import {faExclamation} from '@fortawesome/free-solid-svg-icons';
import {
    formatMoney,
    getErrorMessage,
    printPackingSlipPdf,
    printPurchaseOrderPdf,
    printShippingLabelPdf,
} from 'utility';
import DownloadingDots from 'components/DownloadingDots';
import LoanerHeader from 'components/LoanerHeader';
import {
    ImgContainer, Container, SecurityErrorText,
} from 'components/DetailsStyledComponents';
import {GridKeys} from 'utils/gridKeys/loanersAndRentalsGridKeys';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar} from '@fortawesome/pro-regular-svg-icons';
import NotesModal from 'components/NotesModal';
import {Context} from 'utils/enums';
import {getInstrumentationObject, logEvent} from 'utility';

const OrderHeaderText = styled.h2`
    margin-top: 14px;
    margin-bottom: 25px;
    font-size: 30px;
    font-weight: 300;

    @media (max-width: 818px) {
        
        margin-top: 20px;
    }
`;
const RedExclamation = styled(FontAwesomeIcon)`
    width: 1em !important;
    height: 1em !important;
    padding: 2px;
    color: #ff0000;
    border: 1px solid;
    border-radius: 50%;
    font-size: 14px;
    margin-right: 3px;
`;

const RedNotification = styled.span`
    color: #ff0000;
    font-weight: 600;
    margin-top: 5px;
    font-size: 15px;
`;

const Icon = styled(FontAwesomeIcon)`
    float: left;
    color: black;
    margin-top: 14px;
    margin-right: 5px;
    margin-left: 5px;
`;

const Row = styled.div`
    margin-bottom: 1rem;       
`;

const Subtitle = styled.div`
    font-size: 16px;
    float: left;
`;

const BoldSubtitle = styled(Subtitle)`
    font-weight: 600;
    padding-left: 10px;    
`;

const OrderGridHeader = styled.div`
    margin-top: 20px;    
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2px;
`;

const GraySubtitle = styled(Subtitle)`
    font-weight: 400;
    color: rgb(148, 148, 148);  
    font-size: 16px;  
    margin-top: 10px;
    width: 100%;
`;

const OrderDetailsHeader = styled(Subtitle)`
    font-size: 20px;
    padding-left: 24px;
    min-width: 200px;
    display: inline-block;
    width: calc(100% - 625px);

    @media (max-width: 1110px) {
        width: calc(100% - 525px);
    }

    @media (max-width: 1010px) {
        width: calc(100% - 425px);
    }

    @media (max-width: 910px) {
        width: calc(100% - 325px);
    }

    @media (max-width: 818px) {
        width: 100%;
        padding-bottom: 10px;
    }
`;

const OrderDetailsColumnQty = styled.div`
    display: inline-block;
    width: 140px;    
    position: relative;
    top: -6px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsColumnPrice = styled.div`
    display: inline-block;
    width: 140px;    
    position: relative;
    top: -6px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsQty = styled(OrderDetailsColumnQty)`
    color: rgb(44, 58, 71);
    font-size: 16px;
    font-weight: 400;
    width: 140px;
    max-width: 140px;
    min-width: 140px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderPrintButton = styled.div`
    display: inline-block;
    margin-right: 22px;
    width: 180px;    
    float: right;
    position: relative;

    @media (max-width: 818px) {
        display: none;
    }
`;

const OrderDetailsPrice = styled(OrderDetailsColumnPrice)`
    color: rgb(44, 58, 71);
    font-size: 16px;
    font-weight: 400;
    width: 140px;
    max-width: 140px;
    min-width: 140px;
    text-align: center;

    @media (max-width: 1110px) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }

    @media (max-width: 1010px) {
        display: none;
    }
`;

const OrderDetailsContainer = styled.div`
    display: inline-block;
    margin-top: -10px;
    width: calc(100% - 750px);
    
    @media (max-width: 1110px) {
        width: calc(100% - 650px);
    }

    @media (max-width: 1010px) {
        width: calc(100% - 550px);
    }

    @media (max-width: 910px) {
        width: calc(100% - 450px);
    }

    @media (max-width: 818px) {
        width: 100%;
    }
`;

const OrderGridContent = styled.div`
    width: 100%;    
    position: relative;
    display: flex;    
`;

const OrderDetailField = styled.div`
    line-height: 25px;
    width: 100%;
    padding-left: 15px;
`;

const SmallTitle = styled.div`    
    
    color: rgb(144, 144, 144);  
    font-size: 18px;    
`;

const BoldSubtitleNoPadding = styled(Subtitle)`
    font-weight: 700;
    font-size: 16px;
    color: rgb(0, 93, 166);
    cursor: pointer;
`;

const OrderActionContainer = styled.div`    
    width: 200px;
    position: absolute;
    right: 0;
    top: -5px;

    @media (max-width: 818px) {
        display: none;
    }    
`;

const BoldSubtitleNoLink = styled(Subtitle)`
    font-weight: 700;
    font-size: 16px;   
    color: #166BAD;    
`;

const BlueText = styled(BoldSubtitle)`
    color: #166BAD;
    padding-left: 0;
    font-weight: 400;
    margin-top: 10px;
    cursor: pointer;
`;

const PrintBlueText = styled(BlueText)`  
    margin-top: 3px;    
    font-weight: 600;
    font-size: 14px;
`;

const PartImage = styled.span`
    display: inline-block;
    vertical-align: top;
    cursor: ${props => props.isLink ? 'pointer' : null};
`;

const UnderlineSubtitle = styled(Subtitle)`
    width: 100%;
    border-top-style: solid;
    border-width: 2px;    
    border-color: rgb(204, 204, 204);
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 0;
`;

const OrderButtonContainer = styled.div`    
    margin-bottom: 15px;    
    padding-right: 0;
    float: right;
    z-index: 1000;
`;

const OrderButton = styled(Button)`
    border-radius: 0;   
    width: 200px;    
    float: right;
`;

const UnderlineSubtitleRow = styled.div`
    
    margin-top: 20px; 
    margin-bottom: 0;        
`;

const UnderlineSubtitleBottom = styled(UnderlineSubtitle)`
    height: 5px;
    margin-bottom: 0;      
`;

const FooterContainer = styled.div`
    
`;

const OrderLinkWrapper = styled.div`
    width: 100%;    
    padding-bottom: 10px;     
    padding-right: 0!Important;
    margin-left: -15px;
`;

const OrderLink = styled.a`
      
    margin-top: 10px;    

    &&& {
        text-transform: uppercase;
        cursor: ${props => props.isDisabled === true ? 'not-allowed' : 'pointer'};
        color: ${props => props.isDisabled === true ? '#B7B8BB' : '#005BA6'};
        font-weight: 600;

        &:hover {
            text-decoration: none;
            outline: none;
            color: ${props => props.isDisabled === true ? '#B7B8BB' : '#005BA6'};
        }

        &:focus {
            text-decoration: none;
            outline: none;
        }

        &:active:focus {
            text-decoration: none;
            outline: none;
        }
    }
`;

const LoanersAndRentalsDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLoaner, setSelectedLoaner] = useState(null);
    const [showSecurityError, setShowSecurityError] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [isDownloadingPO, setIsDownloadingPO] = useState(false);
    const [isDownloadingPackingSlip, setIsDownloadingPackingSlip] = useState(false);
    const [isDownloadingShippingLabel, setIsDownloadingShippingLabel] = useState(false);
    const [currentLineItemId, setCurrentLineItemId] = useState(null);
    const [showOrderLineReceivePopup, setShowOrderLineReceivePopup] = useState(false);
    const [showOrderLinePopup, setShowOrderLinePopup] = useState(false);
    const breadcrumbs = useSelector(state => state.orders.breadcrumbs);
    const history = useHistory();
    const [showNotes, setShowNotes] = useState(false);
    const [lastTab, setLastTab] = useState('');
    const isFilteredByPO = window.location.href.includes('/po');

    const dispatch = useDispatch();

    const {id} = useParams();

    useEffect(() => {
        loadLoaner();

        document.querySelector('html').scrollTop = 0;

    }, [location]);

    const hideErrorPopup = () => {
        setShowErrorPopup(false);
    }

    async function getLoaner(url) {
        await axios.get(url)
            .then(res => {
                setSelectedLoaner(res.data);
                setLastTab(GridKeys.find(x => x.value.toLowerCase() === res.data?.lineItems[0].status.toLowerCase()).id);
                if (!breadcrumbs || breadcrumbs.length === 0) {
                    const tab = getBreadcrumbText(res.data?.lineItems[0].status);
                    dispatch(setBreadCrumbs({
                        firstBreadcrumb: 'Loaners & Rentals', secondBreadcrumb: 'Loaner', returnText: tab,
                    }));
                }

                setIsLoading(false);
            })
            .catch(() => {

                dispatch(setBreadCrumbs({
                    firstBreadcrumb: 'Loaners & Rentals', secondBreadcrumb: 'Loaner', returnText: 'Ordered',
                }));

                setIsLoading(false);
                setShowSecurityError(true);
            })
    }   

    async function loadLoaner() {
        setIsLoading(true);
        const url = isFilteredByPO ? `/loaner/po/${id}` : `/loaner/${id}`;
        getLoaner(url);
    }

    function getBreadcrumbText(status) {
        return GridKeys?.find(x => x.value.toLowerCase() === status.toLowerCase())?.tabDisplay;
    }

    const printPurchaseOrder = async () => {
        if (selectedLoaner && selectedLoaner.lineItems.length > 0) {
            await printPurchaseOrderPdf(selectedLoaner.lineItems[0].lineItemId, selectedLoaner.billingPo,
                selectedLoaner.lineItems[0].gsaItem, setIsDownloadingPO);
        }
    }

    const printPackingSlip = async (lineItemId) => {
        setCurrentLineItemId(lineItemId);
        await printPackingSlipPdf(selectedLoaner.billingPo, setIsDownloadingPackingSlip);
    }

    const printShippingLabel = async (lineItemId) => {
        setCurrentLineItemId(lineItemId);
        await printShippingLabelPdf(lineItemId, setIsDownloadingShippingLabel);
    }

    const showOrderLineProcessPopup = (lineItemId) => {
        setCurrentLineItemId(lineItemId);
        setShowOrderLinePopup(true);
    }

    const showOrderLineReceivePopupHandle = (lineItemId) => {

        setCurrentLineItemId(lineItemId);
        setShowOrderLineReceivePopup(true);
    }

    const handleLogEvent = (orderLine, originalLine, attachmentsWereAdded) => {
        const page = window.location.pathname.split('/')[1];
        const isReceive = orderLine.status === 'ShippedInbound' || orderLine.status === 'Returned' || orderLine.status === 'ReceivedInUse'
            || orderLine.status === 'Inspected';
        const isProccess = orderLine.status === 'Ordered';

        if (isProccess) {
            logEvent('LOANER PROCESS', {
                'Ref Number': orderLine.lineItemId,
                'Tab': lastTab,
                'Page': page,
                ...getInstrumentationObject('Serial Number', originalLine.serialNumber, orderLine.serialNumber),
                ...getInstrumentationObject('Ship Tracking Number', originalLine.vendorToCustomerTrackingNumber,
                    orderLine.vendorToCustomerTrackingNumber),
                ...getInstrumentationObject('Estimated Ship Date', originalLine.estimatedShipDate, orderLine.estimatedShipDate),
                ...getInstrumentationObject('Ship Date Reason', originalLine.shipDateReasonId, orderLine.shipDateReasonId),
                ...(attachmentsWereAdded && {'Attachments': 'Add'}),
            });
        } else if (isReceive) {
            logEvent('RECEIVE ITEM', {
                'Ref number': orderLine.lineItemId,
                'Order Number': orderLine.id,
                'RGA ID': orderLine.rgaId,
                'Tab': lastTab,
                'Page': page,
            });
        }
    }

    const onLoanerLineSave = async (repairLine, lineItem) => {

        setIsLoading(true);
        
        let request = {
            lineItemId: lineItem.lineItemId,
            companyId: selectedLoaner.companyId,
            orderType: repairLine.orderType,
            lineItems: [],
        };

        setShowOrderLinePopup(false);
        request.lineItems.push({
            lineItemId: repairLine.lineItemId,
            serialNumber: repairLine.serialNumber,
            trackingNumber: repairLine.vendorToCustomerTrackingNumber,
            vendorOrderNumber: repairLine.vendorOrderNumber,
            vendorRmaNumber: repairLine.vendorRmaNumber,
            noteType: 'Loaner',
            note: repairLine.note,
            estimatedShipDate: repairLine.estimatedShipDate,
            shipDateReasonId: repairLine.shipDateReasonId,
        });

        await axios.post(`/order`, request)
            .then(() => {

                setIsLoading(false);
                dispatch(setCachedLoanersRentals([]));
                handleLogEvent(repairLine, lineItem, false);
                history.goBack(2);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                setShowErrorPopup(true);
            })
    }

    const onReceiveUpdate = async () => {
        setShowOrderLineReceivePopup(false);
        if (lastTab) {
            history.push(`/loaners-rentals?tab=${lastTab}`);
        } else {
            history.push('/loaners-rentals');
        }
        
    }
    const showNotePopup = (lineItemId) => {

        document.body.classList.add('modal-popup');
        document.getElementsByTagName('html')[0].className = 'modal-popup';

        setCurrentLineItemId(lineItemId)
        setShowNotes(true);
    }

    const hideNotePopup = () => {
        setShowNotes(false);
        setCurrentLineItemId(null);

        document.body.classList.remove('modal-popup');
        document.getElementsByTagName('html')[0].className = '';
    }

    const onSubmitNote = (newNote) => {
        const _currentOrder = {...selectedLoaner};
        let lineItem = _currentOrder.lineItems.find(x => x.lineItemId === newNote.lineItemId);
        lineItem.notes.unshift(newNote);
        setSelectedLoaner(_currentOrder);
        setShowNotes(false);
        dispatch(setCachedOrders([]));
      
    }

    const renderActionButtons = (lineItem, index, availableOptions) => {        
        const showReceiveButton = lineItem.status === 'ShippedInbound' || lineItem.status === 'Returned' || lineItem.status === 'ReceivedInUse';
        const showProcessButton = lineItem.status === 'Ordered';
        const showVDFButton = lineItem.status === 'Inspected';

        return (
            <React.Fragment>
                {showReceiveButton &&
                    <OrderButtonContainer className="col-11">
                        <OrderButton onClick={() => showOrderLineReceivePopupHandle(lineItem?.lineItemId)}
                            className="primary">RECEIVE</OrderButton>
                    </OrderButtonContainer>
                }
                {showProcessButton &&
                    <OrderButtonContainer className="col-11">
                        <OrderButton onClick={() => showOrderLineProcessPopup(lineItem?.lineItemId)}
                            className="primary">PROCESS</OrderButton>
                    </OrderButtonContainer>
                }
                {showVDFButton &&
                    <OrderButtonContainer className="col-11">
                        <OrderButton onClick={() => showOrderLineReceivePopupHandle(lineItem?.lineItemId)} className="primary" >VDF</OrderButton>
                    </OrderButtonContainer>
                }
                {selectedLoaner.billingPo && availableOptions && availableOptions.includes('PACKINGSLIP') &&
                    <React.Fragment>
                        {(isDownloadingPackingSlip && currentLineItemId === lineItem?.lineItemId) ?
                            <DownloadingDots />
                            : <OrderLinkWrapper style={{marginRight: '55px'}} className="col-11">
                                <OrderLink onClick={() => printPackingSlip(lineItem?.lineItemId)}>PRINT PACKING SLIP</OrderLink>
                            </OrderLinkWrapper>}
                    </React.Fragment>}

                {selectedLoaner.billingPo && availableOptions && availableOptions.includes('SHIPPINGLABEL') &&
                    <React.Fragment>
                        {(isDownloadingShippingLabel && currentLineItemId === lineItem?.lineItemId) ?
                            <DownloadingDots />
                            : <OrderLinkWrapper style={{marginRight: '36px'}} className="col-11">
                                <OrderLink isDisabled={!lineItem?.hasShippingLabel}
                                    onClick={lineItem?.hasShippingLabel ? () => printShippingLabel(lineItem?.lineItemId) : () => false}>
                                    PRINT SHIPPING LABEL
                                </OrderLink>
                            </OrderLinkWrapper>}
                    </React.Fragment>}
            </React.Fragment>
        )
    }

    const renderNotification = (lineItem) => {
        let notification = '';

        if (lineItem.status === 'Ordered' && (lineItem.urgencyDescription === 'CHD' || lineItem.urgencyDescription === 'STAT')) {
            notification += notification ? ', Urgent' : 'Urgent';
            return (
                <div>
                    <RedExclamation icon={faExclamation} />
                    <RedNotification> {notification}</RedNotification>
                </div>
            )
        
        }
    }
    const renderLineItem = (lineItem, index) => {

        let copyLineItem = _.clone(lineItem);
        copyLineItem.vendorOrderNumber = selectedLoaner.vendorOrderNumber;
        copyLineItem.displayPo = selectedLoaner.displayPo;
        copyLineItem.vendorToCustomerTrackingNumber = lineItem.vendorToCustomerTrackingNumber ?? lineItem.trackingNumber;

        let availableOptions = ['PACKINGSLIP', 'PURCHASEORDER'];

        return (
            <React.Fragment key={index}>
                <OrderGridContent style={{top: index === 0 ? '-8px' : '20px'}} >
                    <ImgContainer>
                        <PartImage isLink={lineItem.imageDefaultUrl} onClick={() => (
                            lineItem.imageDefaultUrl ?
                                window.open(`${process.env.REACT_APP_PARTSSOURCE_URL}${lineItem.imageDefaultUrl}`, '_blank') : null)}>

                            <ProductImage style={{width: '100px'}} url={lineItem.imagePath} altText={lineItem.imageAlt} />
                        </PartImage>
                    </ImgContainer>
                    <OrderDetailsContainer>
                        <Row className="row">
                            <OrderDetailField>
                                {lineItem.imageDefaultUrl ?
                                    <BoldSubtitleNoPadding
                                        onClick={() => window.open(`${process.env.REACT_APP_PARTSSOURCE_URL}${lineItem.imageDefaultUrl}`, '_blank')}>
                                        {lineItem?.description}</BoldSubtitleNoPadding>
                                    :
                                    <BoldSubtitleNoLink>
                                        {lineItem?.description}</BoldSubtitleNoLink>
                                }
                            </OrderDetailField>
                            <OrderDetailField>
                                {renderNotification(lineItem)}
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Order Type: </Subtitle><BoldSubtitle>{lineItem?.orderType}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Equipment Serial: </Subtitle><BoldSubtitle>{lineItem?.serialNumber}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Ref #: </Subtitle><BoldSubtitle>{lineItem?.lineItemId}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Subtitle>Item #: </Subtitle><BoldSubtitle>{lineItem?.partNumber}</BoldSubtitle>
                            </OrderDetailField>
                            <OrderDetailField>
                                <Icon aria-hidden="true" icon={faCalendar} />
                                <BlueText onClick={() => showNotePopup(lineItem?.lineItemId)} >
                                    Notes ({lineItem?.notes ? lineItem?.notes.length : 0}) </BlueText>
                            </OrderDetailField>
                        </Row>
                    </OrderDetailsContainer>

                    <OrderDetailsQty>
                        <SmallTitle>{lineItem?.quantity}</SmallTitle>
                    </OrderDetailsQty>
                    <OrderDetailsPrice className="col-xs-12 col-sm-2 col-lg-1">
                        <SmallTitle>{formatMoney(lineItem?.unitPrice)}</SmallTitle>
                    </OrderDetailsPrice>
                    <OrderDetailsPrice>
                        <SmallTitle>{formatMoney(lineItem?.unitPrice * lineItem?.quantity)}</SmallTitle>
                    </OrderDetailsPrice>
                    <OrderActionContainer>
                        {renderActionButtons(lineItem, index, availableOptions)}
                    </OrderActionContainer>

                    {(showOrderLinePopup && currentLineItemId === lineItem?.lineItemId) &&
                        <OrderLine
                            attachmentsLabel="Service Reports"
                            attachmentAddButton="Add Service Report"
                            showSerialNumberInput={true}
                            showVendorDetails={false}
                            rmaRequired={selectedLoaner.rmaRequired}
                            handleOnCancel={() => setShowOrderLinePopup(false)}
                            handleOnSave={(e) => onLoanerLineSave(e, lineItem)}
                            order={{id, ...copyLineItem}}
                            type="loaner" />
                    }

                    <ReceivedDetails
                        show={showOrderLineReceivePopup && currentLineItemId === lineItem?.lineItemId}
                        orderId={id}
                        type="loaner"
                        orderType={lineItem?.orderType}
                        lineItemId={lineItem?.lineItemId}
                        rgaId={lineItem?.rgaId}
                        trackingNumber={lineItem?.vendorToCustomerTrackingNumber}
                        onSubmit={(e) => onReceiveUpdate(e)}
                        onCancel={() => setShowOrderLineReceivePopup(false)}
                        rgaNumber={lineItem?.rgaNumber}
                        rgaType="LOANER"
                        tab={breadcrumbs.returnText}
                        isRgaOrder={true}
                        itemDescription={lineItem.description} 
                        partNumber={lineItem.partNumber} 
                        poNumber={selectedLoaner.displayPo} />
                    <NotesModal
                        show={showNotes && currentLineItemId === lineItem?.lineItemId}
                        context={Context.ORDER}
                        notes={lineItem?.notes}
                        lineItemId={currentLineItemId}
                        onSubmit={onSubmitNote}
                        onCancel={() => hideNotePopup()} />
                </OrderGridContent>
                <FooterContainer style={{marginTop: index === 0 ? '-5px' : '20px'}}>
                    <LoanerFooter loaner={selectedLoaner} lineItem={lineItem} />
                </FooterContainer>
                <UnderlineSubtitleRow>
                    <UnderlineSubtitleBottom>&nbsp;</UnderlineSubtitleBottom>
                </UnderlineSubtitleRow>
            </React.Fragment>
        )
    }

    return (
        <Container>
            <PageBreadcrumbs />
            {!showSecurityError && !isLoading &&
                <OrderHeaderText>PO #: {selectedLoaner?.displayPo ?? 'Pending'}</OrderHeaderText>
            }
            {showSecurityError &&
                <SecurityErrorText>Sorry, you do not have access to this loaner.</SecurityErrorText>
            }
            {(!showSecurityError && !isLoading) &&
                <LoanerHeader loaner={selectedLoaner} />
            }
            {(!showSecurityError && isLoading) &&
                <Loader style={{margin: '0 auto'}} />
            }

            {(!showSecurityError && !isLoading) &&
                <div>
                    <OrderGridHeader>
                        <OrderDetailsHeader>Order Details</OrderDetailsHeader>
                        <OrderDetailsColumnQty>
                            <GraySubtitle>Quantity</GraySubtitle>
                        </OrderDetailsColumnQty>
                        <OrderDetailsColumnPrice>
                            <GraySubtitle>Unit Price</GraySubtitle>
                        </OrderDetailsColumnPrice>
                        <OrderDetailsColumnPrice>
                            <GraySubtitle>Ext. Price</GraySubtitle>
                        </OrderDetailsColumnPrice>
                        {selectedLoaner?.billingPo &&
                            <OrderPrintButton>
                                {isDownloadingPO ? <DownloadingDots style={{marginTop: 6}} /> :
                                    <PrintBlueText onClick={printPurchaseOrder}>Print Purchase Order</PrintBlueText>}
                            </OrderPrintButton>}
                    </OrderGridHeader>
                    <Row>
                        <UnderlineSubtitle>&nbsp;</UnderlineSubtitle>
                    </Row>

                    {selectedLoaner &&
                        (selectedLoaner.lineItems).map((lineItem, index) => renderLineItem(lineItem, index))}
                </div>
            }

            <ErrorModal
                show={showErrorPopup}
                popupTitle={'Loaner Save'}
                popupMessage={getErrorMessage('loaner')}
                onCancel={() => hideErrorPopup()} />
        </Container>
    );
};

export default LoanersAndRentalsDetails;
