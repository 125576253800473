import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {faAngleDown, faAngleRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {semiBoldBrandFont} from '@partssourceinc/react-ui-core';
import * as PropTypes from 'prop-types';
import Link from 'components/Link';
import _ from 'lodash';

const Flyout = styled.div`
    background-color: white;
    z-index: 1000;
    padding: 10px 20px;
    line-height: 45px;
    border: 1px solid #BBB;
    display: flex;
    flex-direction: column;
    width: 240px;
`;

const SlideOut = styled.div`
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    width: 0;
    opacity: 0;
    cursor: auto;
    z-index: 1001;
    transition: width 0.2s ease-in-out;
    color: #000000;
    line-height: 34px;
    padding: 0px;
    height: auto;
    overflow: hidden;

    ${props => props.isOpen && css`
        width: 240px;
        border: 1px solid #BBB;
        opacity: 1;
        padding: 10px 20px;
    `}
`;

const StyledMenuItem = styled(Link)`

    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    color: #000000 !important;
    text-decoration: none !important;
    font-size: 16px;
    height: 40px;
    max-height: 60px;
    line-height: normal;

    &:hover {
        color: #005da6 !important;
        cursor: pointer;
        opacity: 1 !important;
    }
`;

const Container = styled.div`
    ${semiBoldBrandFont('16px')} !Important;
    color: #000000;
    z-index: 999;
    cursor: pointer;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    position: relative;

    &:hover {
        border-color: #005DA6;
        transition-delay: .19s;
        transition: border-color .01s;
    }

    &:not(:hover) {
        border-color: transparent;
        transition-delay: .19s;
        transition: border-color .01s;
    }

    ${props => props.isActive && css`
        border-bottom: 2px solid rgb(0, 93, 166) !important;
        color: #005DA6;
    `}
`;

const Chevron = styled(FontAwesomeIcon)`
    font-weight: 400;
    font-style: normal;
    margin: 0 0 0 8px;
    font-size: 1em;
`;

const Menus = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: calc(100% + 1px);
    left: 0px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);
`;

const FlyoutMenu = (props) => {
    const {children, onMenuItemClick, menuId, menu = [], style} = props;
    const [menuVisible, setMenuVisible] = useState(false);
    const [openSlideOutIndex, setOpenSlideOutIndex] = useState('');
    const menuItems = menu.filter(i => i.visible);

    const subMenuItems = openSlideOutIndex ? menuItems[openSlideOutIndex]?.items?.filter(i => i.visible) ?? [] : [];
    const urls = menuItems.flatMap(i => (i.items && i.items.url) || '');
    const isActive = menuItems.some(i => i.url === window.location.pathname) || urls.some(i => i.url === window.location.pathname);
    const showFlyout = (menuItems.length && menuVisible) ? true : false;

    const showMenu = () => setTimeout(() => {
        if (_.find(document.querySelectorAll(':hover'), {id: menuId})) {
            setMenuVisible(true);
        }
    }, 200);

    const hideMenu = ({type: eventType}) => setTimeout(() => {
        if (eventType === 'click' || !_.find(document.querySelectorAll(':hover'), {id: menuId})) {
            setOpenSlideOutIndex('');
            setMenuVisible(false);
        }
    }, 200);

    const handleMenuItemClick = (title, parentTitle) => {
        setOpenSlideOutIndex('');
        setMenuVisible(false);
        onMenuItemClick?.(title, parentTitle);
    };

    const renderMenuItem = (item, index) => {
        const hasSubMenu = (item?.items?.filter(i => i.visible) ?? []).length ? true : false;
        return (<div key={`${item.title}_${index}`} onMouseEnter={() => setOpenSlideOutIndex(index.toString())}>
            <StyledMenuItem
                href={item.url}
                onClick={() => handleMenuItemClick(item.title, openSlideOutIndex ? menuItems[openSlideOutIndex].title : '')}>
                {item.title}
                {hasSubMenu && <Chevron icon={faAngleRight} />}
            </StyledMenuItem>
        </div>);
    }

    return (<Container
        style={style}
        id={menuId}
        isActive={isActive}
        onMouseEnter={showMenu}
        onMouseLeave={hideMenu}>
        {children}
        <Chevron icon={faAngleDown} />
        {showFlyout && <Menus>
            <Flyout>
                {menuItems.map((i, index) => renderMenuItem(i, index))}
            </Flyout>
            <SlideOut isOpen={(subMenuItems && subMenuItems.length)}>
                <div style={{width: '240px', height: 'auto'}}>
                    {subMenuItems.map((i, index) => renderMenuItem(i, openSlideOutIndex))}</div>
            </SlideOut>
        </Menus>}
    </Container>)
}

FlyoutMenu.propTypes = {
    children: PropTypes.node.isRequired,
    menuId: PropTypes.string.isRequired,
    onMenuItemClick: PropTypes.func,
    style: PropTypes.object,
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            items: PropTypes.arrayOf(PropTypes.shape({
                url: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            })),
        })
    ),
};

export default FlyoutMenu;
