import moment from 'moment';

export const timelineLabels = (desiredStartTime, interval, period) => {
    const periodsInADay = moment.duration(1, 'day').as(period);

    const timeLabels = [];
    const startTimeMoment = moment(desiredStartTime, 'hh:mm');
    for (let i = 0; i < periodsInADay; i += interval) {
        startTimeMoment.add(i === 0 ? 0 : interval, period);
        let timeObject = {
            id: startTimeMoment.format('HH:mm'),
            timePeriod: startTimeMoment.format('hh:mm A'),
        }
        timeLabels.push(timeObject);
    }
    return timeLabels;
};
const formatConfig = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    currencyDisplay: 'symbol',
};

export const toCurrency = (number) => {
    if (!number) return;

    const formatter = new Intl.NumberFormat('en-US', formatConfig);
    return formatter.format(String(number)?.replace('$', '')?.replace(',',''));
}

export const toPhoneNumber = (number) => {
    const clearedString = number.replace(/[^0-9]/g, '');
    let group1 = clearedString.substring(0, 3);
    const group2 = clearedString.substring(3, 6);
    const group3 = clearedString.substring(6, 10);
    return `${group2 ? `(${group1}) ` : `${group1 ? group1 : ''}`}` + group2 + `${group3 ? ` - ${group3}` : ''}`
}
