import React from 'react'
import * as PropTypes from 'prop-types';
import {Popup, Button} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';

const RequestUpdatePopup = styled(Popup)`
    max-width: 600px;
`;

const PopupBody = styled.div`
    font-family: Source Sans Pro,sans-serif;
`;

const Title = styled.h1`
    font-size: 20px;
`;

const BodyContent = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const BodyText = styled.p`
    font-size: 15px;
`

const Line = styled.hr`
    border-top: 1px solid #949494;
    height: 2px;
    width: 100%;
    float: left;
`;

const SaveButton = styled(Button)`
    float: right;
`;

const RequestUpdateDialog = ({show, onClose}) => {

    return (
        <RequestUpdatePopup
            show={show}
            hideButtons={true}
            onCancel={onClose}
        >
            <PopupBody>
                <Title>Request For Update</Title>
                <BodyContent>
                    <BodyText>
                       Thank you for submitting a request for update. A member of our team will get back to you shortly.
                    </BodyText>
                </BodyContent>
                <Line />
                <SaveButton
                    primary={true}
                    onClick={() => onClose()}
                    loading={false}>
                    CLOSE
                </SaveButton>
            </PopupBody>
        </RequestUpdatePopup>);
}

export default RequestUpdateDialog;

RequestUpdateDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

