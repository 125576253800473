import CmsResources from 'components/CmsResources';
import React from 'react';
import {default as cmsComponents} from '@partssourceinc/react-ui-core/cms/componentDefinitions';
import {BrPage, BrComponent, BrPageContext} from '@bloomreach/react-sdk';
import * as PropTypes from 'prop-types';
import axios from 'axios';
import {useLocation} from 'react-router';
import PolicyContent from 'components/PolicyContent';

let configuration = {
    httpClient: axios.create({headers: {}}),
    endpoint: `${process.env.REACT_APP_CMS_URL}/site/supplier-pro/resourceapi`,
    baseUrl: process.env.REACT_APP_CMS_URL,
};

const CmsWrapper = ({children}) => {
    const {pathname, search} = useLocation();
    let cmsPathName = pathname === '/login' ? '/signin' : pathname.replace('·', '');
    cmsPathName = `${cmsPathName}${search}`;
    configuration.path = cmsPathName;

    // TODO: Explicitly import?
    const componentMappings = {
        ...cmsComponents,
        'Policy Component': PolicyContent,
    }

    return (<BrPage configuration={{...configuration}} mapping={componentMappings}>
        <BrPageContext.Consumer>
            {(page) => (<>
                <BrComponent path="resources">
                    <CmsResources />
                </BrComponent>
                {children}
            </>)}
        </BrPageContext.Consumer>
    </BrPage>);
};

CmsWrapper.propTypes = {
    children: PropTypes.node,
};

export default CmsWrapper;
