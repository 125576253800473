import React, {useEffect} from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {faCopy} from '@fortawesome/pro-regular-svg-icons';
import {theme} from '@partssourceinc/react-ui-core';

const Box = styled.div`
    position: relative;
    margin-top: 15px !important;
    padding: 15px;
    border-radius: 6px;    
    cursor: pointer;
    width: 270px;
    background: #fff;
    height: 130px; 
    z-index: 1000; 
    @media (max-width: 360px) {
        margin-left: ${props => props.isBidRequestStep ? '0px !important' : '5px !important'};
    }

    @media (min-width: 361px) and (max-width: 410px) {
        margin-left: ${props => props.isBidRequestStep ? '0px !important' : '14px !important'};
    }

    @media (min-width: 411px) and (max-width: 539px) {
        margin-left: ${props => props.isBidRequestStep ? '0px !important' : '30px !important'};

    }

    @media (min-width: 540px) and (max-width:${theme.screenXsMax}) {
        margin-left: ${props => props.isBidRequestStep ? '0px !important' : '90px !important'};
    }


    @media (min-width: 550px) and (max-width: ${theme.screenMDMin}) {
        margin-left: ${props => props.isBidRequestStep ? '0px !important' : '220px !important'};
    }
`;

const BoxCompleted = styled(Box)`
    border: 2px solid #17AB78;
`;

const BoxActive = styled(Box)`
    border: 2px solid #FF9505;
`;

const BoxInActive = styled(Box)`
    border: 2px solid #CCCCCC;
`;

const BoxInValid = styled(Box)`
    border: 2px solid red;
`;

const Title = styled.div`
    display: inline-block;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 3px;
    text-align: left;
`;

const GrayDescription = styled.div`
    padding-left: '0px'!Important;
    width: 100%;
    color: rgb(158, 158, 158);
    font-size: 14px;
    font-weight: normal;
`;

const Description = styled.div`
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    display: inline-block;
    width: 100%;
    color: #000000;
    font-family: "Source Sans Pro", sans-serif;
    line-height: 22px;
    text-align: left;
`;

const CheckedIcon = styled.span`
    background: url('/images/icn_checkmark.png');
    background-size: 22px;
    vertical-align: middle;
    position:relative;
    top: -3px;
    height: 22px;
    width: 22px;
    display: block;
    float: left;
    margin-right: 5px;
`;

const Icon = styled(FontAwesomeIcon)`
    float: left;
    color: black;    
    margin-right: 5px;    
`;

const DeleteIcon = styled.div`
    position: absolute;
    color: rgb(148, 148, 148);
    font-size: 14px;
    top: 15px;
    right: 10px;
`;

const PencilIcon = styled(FontAwesomeIcon)`
    border: 2px solid black;
    border-radius: 50%;
    margin-top: -10px;
    height: 21px;
    padding-left: 3px;
    padding-right: 3px;
    margin-right: 5px;
`;

const StepBox = (props) => {
    const {title, type, description, bid = {}, condition = {}, active, conditionCodes, loanerConditionCodes,
        index, valid, isDeletable, onSelectedStep, onDeleteStep, isBidRequestStep} = props;
    const formatMoney = (amount) => {

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return formatter.format(amount?.split(',').join('').split('$').join(''))
    }

    useEffect(() => {

    }, [valid]);

    const getConditionType = () => {
        
        if (condition != null) {
            const conditionId = parseInt(condition?.conditionId);
            let filteredCode = conditionCodes?.filter(c => c.id === conditionId);
            if (filteredCode.length > 0) {
                return conditionCodes?.filter(c => c.id === conditionId)[0].description
            } 
            filteredCode = loanerConditionCodes?.filter(c => c.id === conditionId);
            if (filteredCode.length > 0) {
                return loanerConditionCodes?.filter(c => c.id === conditionId)[0].description
            } 
        }

        return '';
    }

    const onSelectedStepLocal = (event) => {

        onSelectedStep(event, index);
    }

    const deleteStep = () => {

        onDeleteStep(index);
    }

    const renderBoxContent = () => {
        
        return (           
            <React.Fragment>
                <Title>
                    <div className="row" style={{height: '23px'}}>
                        {(type === 'start' || type === 'started') ? (
                            <div className="col-xs-12">
                                {title}
                            </div>
                        )
                            :
                            <React.Fragment>
                                {(type === 'inProgress' || type === 'upcoming' || active) &&
                                    <div className="col-xs-12">
                                        <PencilIcon icon={faPencilAlt} size="lg" />
                                        {title}
                                    </div>
                                }
                                {(type === 'completed' && !active) &&
                                    <div className="col-xs-12">
                                        <CheckedIcon />
                                        {title}
                                    </div>
                                }
                                {(type === 'attachments' && !active) &&
                                    <div className="col-xs-12">
                                        <Icon aria-hidden="true" icon={faCopy} />
                                        {title}
                                    </div>
                                }
                            </React.Fragment>
                        }
                    </div>
                </Title>
                <Description>
                    <div className="row" style={{height: '23px'}}>
                        
                        {((type === 'start' || type === 'attachments' || active) && type !== 'started') &&
                            <div className="col-md-10 align-middle" style={{paddingLeft: '0', paddingRight: '0'}}>
                                {description}
                            </div>
                        }
                        {(!valid) &&
                            <div className="col-md-10 align-middle" style={{paddingLeft: '0', paddingRight: '0'}}>
                                Additional Information Required
                            </div>
                        }
                        {(type === 'started' || type === 'upcoming') &&
                            <React.Fragment>
                                {(!bid.loaner_LineItemId && bid.orderType !== 'REPAIR') ? (
                                    <React.Fragment>
                                        <GrayDescription className="align-middle">
                                            OEM: {bid?.manufacturer}
                                        </GrayDescription>
                                        <GrayDescription className="align-middle">
                                            Item #: {bid?.partNumber}
                                        </GrayDescription>
                                        <GrayDescription className="align-middle">
                                            Qty: {bid?.quantity}
                                        </GrayDescription>
                                    </React.Fragment>
                                )
                                    :
                                    <React.Fragment>
                                        {(bid.orderType === 'REPAIR' || bid.loaner_LineItemId) &&
                                            <React.Fragment>
                                                {type === 'started' &&
                                                    <div>{description}</div>
                                                }
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>

                        }
                        {(type === 'completed' && !active) &&
                            <React.Fragment>
                                <GrayDescription className="align-middle">
                                    Condition: {getConditionType()}
                                </GrayDescription>
                                {(!bid.loaner_LineItemId && bid.orderType !== 'REPAIR') ? (
                                    <React.Fragment>
                                        <GrayDescription className="align-middle">
                                            Replacement Item #: {condition?.replacemantPartNumber}
                                        </GrayDescription>
                                        <GrayDescription className="align-middle">
                                            Outright Price: {formatMoney(condition?.outrightCost)}
                                        </GrayDescription>
                                    </React.Fragment>
                                )
                                    :
                                    <React.Fragment>
                                        {condition?.minimumFee &&
                                            <GrayDescription className="align-middle">
                                                Minimum Fee: {formatMoney(condition?.minimumFee)}
                                            </GrayDescription>
                                        }
                                        {condition?.maximumPrice &&
                                            <GrayDescription className="align-middle">
                                                Maximum Price: {formatMoney(condition?.maximumPrice)}
                                            </GrayDescription>
                                        }
                                        {condition?.replacementCost &&
                                            <GrayDescription className="align-middle">
                                                Replacement Cost: {formatMoney(condition?.replacementCost)}
                                            </GrayDescription>
                                        }
                                        {condition?.loanerCancellationFee && condition?.loanerFeeType === '1' &&
                                            <GrayDescription className="align-middle">
                                                Cancelation Fee: {formatMoney(condition?.loanerCancellationFee)}
                                            </GrayDescription>
                                        }
                                        {condition?.rentalFeeCost && condition?.loanerFeeType === '2' &&
                                            <GrayDescription className="align-middle">
                                                Rental Fee: {formatMoney(condition?.rentalFeeCost)}
                                            </GrayDescription>
                                        }
                                    </React.Fragment>                                    
                                }
                            </React.Fragment>
                        }
                    </div>
                </Description>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {active ? (
                <BoxActive isBidRequestStep={isBidRequestStep} onClick={(e) => onSelectedStepLocal(e)} >
                    {renderBoxContent()}
                    {isDeletable &&
                        <DeleteIcon onClick={() => deleteStep()} aria-hidden="true">Remove</DeleteIcon>
                    }
                </BoxActive>
            )
                :
                <React.Fragment>
                    {type === 'completed' ? (
                        <BoxCompleted onClick={(e) => onSelectedStepLocal(e)} >
                            {renderBoxContent()}
                            {isDeletable &&
                                <DeleteIcon onClick={() => deleteStep()} aria-hidden="true">Remove</DeleteIcon>
                            }
                        </BoxCompleted>
                    )
                        :
                        <React.Fragment>
                            {valid &&
                                <BoxInActive onClick={(e) => onSelectedStepLocal(e)} >
                                    {renderBoxContent()}
                                    {isDeletable &&
                                        <DeleteIcon onClick={() => deleteStep()} aria-hidden="true">Remove</DeleteIcon>
                                    }
                                </BoxInActive>
                            }
                            {!valid &&
                                <BoxInValid onClick={(e) => onSelectedStepLocal(e)} >
                                    {renderBoxContent()}
                                    {isDeletable &&
                                        <DeleteIcon onClick={() => deleteStep()} aria-hidden="true">Remove</DeleteIcon>
                                    }
                                </BoxInValid>
                            }
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    );
};

StepBox.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    bid: PropTypes.shape({}),
    condition: PropTypes.shape({}),
    active: PropTypes.bool.isRequired,
    isBidRequestStep: PropTypes.bool,
    index: PropTypes.number.isRequired,
    product: PropTypes.shape({}),
    completed: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    isDeletable: PropTypes.bool.isRequired,
    fields: PropTypes.arrayOf(PropTypes.object),
    conditionCodes: PropTypes.arrayOf(PropTypes.object),
    loanerConditionCodes: PropTypes.arrayOf(PropTypes.object),
    onSelectedStep: PropTypes.func.isRequired,
    onDeleteStep: PropTypes.func,
};

export default StepBox;
