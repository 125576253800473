import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Dropdown} from '@partssourceinc/react-ui-core';
import {NavLink} from 'react-router-dom';
import {getSupplier} from 'reducers/supplier';
import {useSelector, useDispatch} from 'react-redux';
import {setSelectedFacility} from 'reducers/user';

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const Row = styled.div`
    margin-bottom: 2rem;
`;

const Title = styled.h1`
    margin-bottom: 30px;
`;

const Heading = styled.span `
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 8px;
`;

const Subtitle = styled.div `
    font-size: 16px;
`;

const BoldSubtitle = styled(Subtitle) `
    font-weight: bold;
`;

const LinkItem = styled(NavLink) `
    color: #005da6;
    font-weight: bold;
    font-size: 16px;
`;

const DropdownList = styled(Dropdown)`
    max-width: 28em;
`;

const Settings = () => {
    const userLoggedIn = useSelector(state => state.user.tokenInfo);
    const selectedFacility = useSelector(state => state.user.selectedFacility);
    const facilities = useSelector(state => state.settings.facilities);    
    let companyId = useSelector(state => state.user.companyId);    
    const [selectedCompanyId, setSelectedCompanyId] = useState(companyId);             
    const dispatch = useDispatch();

    const loadSupplier = async() => {        
        await dispatch(getSupplier(companyId));        
    };

    useEffect(() => {
        document.querySelector('html').scrollTop = 0;

        if (userLoggedIn) {
            if (selectedFacility != null) {
                companyId = selectedFacility;                                                
            } else {
                companyId = facilities && facilities[0]?.id;                                
            }    
            setSelectedCompanyId(companyId);              
        }
        loadSupplier();
    }, []);
       
    const handleChangeDefaultCompany = async (e) => {
        companyId = e.target.value;            
        setSelectedCompanyId(companyId);  
        dispatch(setSelectedFacility(companyId));
        loadSupplier();
        e.preventDefault();        
    };

    return (
        <Container>
            <Title>Company Profile</Title>
            <div className="row" >
                <div className="col-xs-12 col-lg-7">                    
                    {facilities?.length > 1 && selectedCompanyId &&
                    <div>                         
                        <DropdownList 
                            label="Default Company" 
                            onChange={handleChangeDefaultCompany}
                            options={facilities} 
                            selectedValue={selectedCompanyId} 
                            suppressBlankOption={true} 
                            valueField="id" 
                            textField="name" />
                    </div> }
                    {facilities?.length === 1 &&
                    <div> 
                        <Heading>{facilities[0].name}</Heading>
                    </div> }
                </div>
            </div>
            <br />
            <div className="row" >
                <div className="col-xs-12 col-lg-7">
                    <Row className="row">
                        <div className="col-11">
                            <Subtitle>{`Manage your company's profile to ensure up to date information.`}</Subtitle>

                            <div>
                                <LinkItem to="/settings/general-information">
                                    General Information
                                </LinkItem>
                            </div>
                            <LinkItem to="/settings/services-inventory">
                                Services & Inventory
                            </LinkItem>
                            <div>
                                <LinkItem to="/settings/certifications">
                                    Certifications
                                </LinkItem>
                            </div>
                        </div>
                    </Row>
                    <Row className="row" >
                        <div className="col-11">
                            <Subtitle>{`Manage your company's shipping and fees to ensure proper billing.`}</Subtitle>

                            <div>
                                <LinkItem to="/settings/fees-payments">
                                    Fees & Payments
                                </LinkItem>
                            </div>
                            <div>
                                <LinkItem to="/settings/shipping-and-returns">
                                    Shipping & Returns
                                </LinkItem>
                            </div>
                        </div>
                    </Row>
                </div>
                <Row className="col-xs-12 col-lg-5" >
                    <div className="col-xs-1 d-lg-none" />
                    <div className="col-xs-11 col-lg-12" style={{paddingLeft: '0px'}}>
                        <Heading>Contact Supplier Solutions</Heading>
                        <Subtitle>Questions? Call or <a href="mailto:supplierportalsupport@partssource.com">email</a> us.</Subtitle>
                        <BoldSubtitle>877-497-6412  </BoldSubtitle>
                    </div>
                </Row>
            </div>

        </Container>
    );
};

export default Settings;
