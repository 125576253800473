import {models} from 'powerbi-client';

export const getRootCompanyConditions = (rootCompanyId) => {
    return [
        {
            operator: 'Is',
            value: Number(rootCompanyId)
        }
    ]
}

export const buildAdvancedFilter = (table, column, conditions, logicalOperator) => {
    return {
        $schema: 'http://powerbi.com/product/schema#advanced',
        target: {
            table,
            column
        },
        logicalOperator,
        conditions,
        filterType: models.FilterType.Advanced
    }
}

export const buildBasicFilter = (table, column, operator, values) => {

    return {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
            table: table,
            column: column
        },
        operator: operator,
        values: values,
        filterType: models.FilterType.Basic
    }
}

export const getFacilitiesName = (facilities) => {
    const facilityNames = [];
    facilities.forEach(facility => facilityNames.push(facility.name));
    return facilityNames;
}
