import React, {useState} from 'react';
import {Popup, TextFieldSelect} from '@partssourceinc/react-ui-core';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import {setSelectedFacility} from 'reducers/user';
import {getCompanySettings} from 'reducers/settings';
import {useDispatch, useSelector} from 'react-redux';

const PopupBody = styled.div`
    height: 450px;

    .lazy-list {
        max-height: 410px !important;
    }
`;

export default function SupplierSelectionDialog({
    show,
    onCancel,
    onSelectFacility,
}) {
    const [supplierList, setSupplierList] = useState([]);
    const user = useSelector(state => state.user);
    const selectedFacility = user?.selectedFacility;

    const dispatch = useDispatch();

    function onSearch(q) {
        axios
            .get(`/company/search?q=${q}`)
            .then(({data: suppliers}) => setSupplierList(suppliers));
    }

    function handleSelectFacility(facility) {
        if (facility && Object.keys(facility).length > 0) {
            dispatch(setSelectedFacility(facility.id ? facility : null));
            dispatch(getCompanySettings(facility.id ? facility.id : null));
            onSelectFacility();
        }
    }

    return (
        <Popup
            show={show}
            onCancel={onCancel}
            hideButtons={true}
        >
            <PopupBody>
                <h1>Select a Supplier</h1>
                <TextFieldSelect
                    keyField="id"
                    valueField="name"
                    onSelect={data => handleSelectFacility(data)}
                    listItems={supplierList}
                    selectedItem={selectedFacility}
                    label="Supplier"
                    placeHolder="Supplier"
                    onSearch={onSearch}
                />
            </PopupBody>
        </Popup>
    );
}

SupplierSelectionDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSelectFacility: PropTypes.func.isRequired,
};
