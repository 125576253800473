import OktaSignIn from '@okta/okta-signin-widget';
import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
// polyfill crypto for ie11
if (!window.crypto && window.msCrypto)
    window.crypto = window.msCrypto;

const OktaSignInWidget = ({config, onSuccess, onError}) => {

    const widgetRef = useRef();

    useEffect(() => {
        if (!widgetRef.current)
            return false;

        const loginUrl = new URLSearchParams(window.location.search);
        const otp = loginUrl.get('otp');
        const state = loginUrl.get('state');
        const activationToken = loginUrl.get('tokenInfo');

        if (otp)
            config.otp = otp;
        if (state)
            config.state = state;
        if (activationToken)
            config.recoveryToken = activationToken;

        const widget = new OktaSignIn(config);

        widget.showSignInToGetTokens({
            el: widgetRef.current,
        }).then(onSuccess).catch(onError);

        return () => widget.remove();

    }, [config]);

    return (<div ref={widgetRef} />);
};

OktaSignInWidget.propTypes = {
    onSuccess: PropTypes.func,
    config: PropTypes.object,
    onError: PropTypes.func,
};

export default OktaSignInWidget;

