import React from 'react'
import {useHistory} from 'react-router-dom';
import 'components/css/reportingportal.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faListAlt, faCreditCard} from '@fortawesome/free-solid-svg-icons';

export default function ReportingPortalManagement() {
    const history = useHistory();

    return (
        <div className="admin-settings">
            <div>
                <h1>Reporting Portal Management</h1>
                <div className="settings-section">
                    <FontAwesomeIcon icon={faListAlt} size="sm" className="settings-section-icon" />
                    <div className="section-name" onClick={() => history.push('/admin/reporting-portal/widgets')}>Widget Management</div>
                </div>
                <div className="settings-section">
                    <FontAwesomeIcon icon={faCreditCard} size="sm" className="settings-section-icon" />
                    <div className="section-name" onClick={() => history.push('/admin/reporting-portal/portals')}>Portal Management</div>
                </div>
            </div>
        </div>
    );
}
