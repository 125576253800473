import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {TextField, Dropdown, Popup, theme, brandFont, boldBrandFont, Button} from '@partssourceinc/react-ui-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/pro-regular-svg-icons';
import DatePicker from 'components/DatePicker';
import Attachments from 'components/AttachFile';
import moment from 'moment';
import AttachmentsViewer from 'components/AttachFileViewer';
import Tooltip from 'components/Tooltip';
import styled from 'styled-components';

const CustomTextField = styled(TextField)`
   max-width: 20em; 
   min-width: 280px;
   float: left;  
`;

const Subtitle = styled.div`
    font-size: 16px;
    float: left;
`;

const BlueText = styled(Subtitle)`
    color: rgb(0, 93, 166);
    padding-left: 0;
    cursor: pointer;
    font-weight: bold;
`;

const POLabel = styled(BlueText)`    
    margin-left: 10px;
    margin-top: 15px;
    ${boldBrandFont('16px')}
`;

const OrderLinePopup = styled(Popup)`    
   max-width: 690px!important;  
`;

const GraySubtitle = styled(Subtitle)`
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;
    color: rgb(119, 119, 119);  
    float: left;  
    margin-top: 10px; 
`;

const PopupContent = styled.span`
    ${brandFont('16px')}
`;

const Row = styled.div`
    margin-bottom: 10px;
`;

const Reference = styled.div`
    font-family: "Source Sans Pro", sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 15px;

    a {
        margin-left: 15px;
        font-size: 16px;
        text-decoration: underline;
        color: ${theme.grey1};
    }
`;

const Icon = styled(FontAwesomeIcon)`
    float: left;
    color: black;
    margin-top: 4px;
    margin-right: 5px;
    margin-left: 5px;
    color: #4a4a4a;
    cursor: pointer;
`;

const TextBox = styled(TextField)`
    
    width: 100%;
    max-width: 630px;

    div {
        min-height: 110px;        
    }
`;

const DropdownList = styled(Dropdown)`
    width: 280px;    

    div {
        span {
            font-weight: 700;
            font-size: 12px;
            color: #005DA6;
            margin: 4px 10px 4px 10px;
        }
    }
`;

const SaveButton = styled(Button)`
    float: right;
`;

const CancelButton = styled(Button)`
    color: rgb(119, 119, 119);
    background-color: white;
    float: right;

    :hover{
        background-color: unset !important;
        color: unset !important;
        color: rgb(119, 119, 119);
    }
`;

const Line = styled.hr`
    border-top: 1px solid #949494;
    height: 2px;
    max-width: 615px;
    width: 100%;
    margin-left:15px;
    float: left;
`;

const WarningMessageHandler = styled.p`
    color: red;
`;

const OrderLine = (props) => {
    const LOARNER_ORDER_ID = 37;
    const {
        attachmentAddButton = 'Add Attachment',
        attachmentsLabel = 'Attachments',
        handleOnCancel,
        handleOnSave,
        order = {},
        type,
        showSerialNumberInput = false,
        showVendorDetails = true,
        rmaRequired,
    } = props;

    const esdRepairReasons = useSelector(state => state.common.esdRepairReasons);
    const esdLoanerReasons = useSelector(state => state.common.esdLoanerReasons);
    const esdPartReasons = useSelector(state => state.common.esdPartReasons);
    const cachedAttachments = useSelector(state => state.attachments.cachedAttachments);
    const [showAttachmentEditor, setShowAttachmentEditor] = useState(false);
    const [orderLine, setOrderLine] = useState(order);
    const [warningMessage, setWarningMessage] = useState('');

    useEffect(() => {
        document.body.classList.add('modal-popup');
        document.getElementsByTagName('html')[0].className = 'modal-popup';

        return () => {
            document.body.classList.remove('modal-popup');
            document.getElementsByTagName('html')[0].className = '';
        }
    }, []);

    const handleShipDateChange = async (e) => {
        let currentOrderLine = {...orderLine};
        currentOrderLine.estimatedShipDate = e.target.value ? moment(e.target.value).format('MM/DD/YY') : null;
        setOrderLine(currentOrderLine);
    }

    const onPropertyChange = ({target: {name, value}}) => {
        let currentOrderLine = {...orderLine};
        if (name === 'note') {
            const isLongText = value.length >= 121;
            currentOrderLine[name] = isLongText ? value.substring(0, 120) : value;
            setWarningMessage(isLongText ? 'Please limit your note to 120 characters.' : null);
        } else {
            currentOrderLine[name] = value;
        }
        setOrderLine(currentOrderLine);
    }

    const assignPO = () => {
        let currentOrderLine = {...orderLine};
        currentOrderLine.vendorOrderNumber = orderLine?.displayPo;
        setOrderLine(currentOrderLine);
    }

    const onAttachmentUpload = (attachment) => {

        setShowAttachmentEditor(false);
    }

    const onAttachmentCancel = () => {
        setShowAttachmentEditor(false);
    }

    const onClose = () => {
        handleOnCancel();
    }

    const onConfirm = () => {
        handleOnSave(orderLine);
    }

    const setFocusToReasons = () => {
        document.getElementById('esdReasons').focus();
    }

    const renderTitle = () => {        
        return <span>Update for Reference #{orderLine?.lineItemId ?? orderLine?.rgaId}</span>
    }

    let attachmentsCount = orderLine?.attachments ? orderLine?.attachments.length : 0;
    attachmentsCount += cachedAttachments ? cachedAttachments.filter(c => c.lineItemId === orderLine?.lineItemId).length : 0;
    
    return (
        <OrderLinePopup
            onCancel={onClose}
            show={true}
            hideButtons={true}
            onConfirm={onConfirm}>
            <PopupContent>
                <div className="row" >
                    <Reference className="col-11">
                        {renderTitle()}
                        <a href={`/${type === 'repair' 
                            ? 'repair' 
                            : type === 'loaner' 
                                ? 'loaners-rentals' 
                                : 'order'}-details/${order.displayPo ? `po/${order.displayPo}` : order?.id}`}>View Details</a>
                    </Reference>
                    {rmaRequired !== undefined && <Row className="col-11">
                        <CustomTextField
                            label={'RMA Number'}
                            text={orderLine?.vendorRmaNumber}
                            name={'vendorRmaNumber'}
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>}
                    {showSerialNumberInput && <Row className="col-11">
                        <CustomTextField
                            label={'Serial Number'}
                            text={orderLine?.serialNumber}
                            name={'serialNumber'}
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>}
                    <Row className="col-11">
                        <GraySubtitle>Shipping</GraySubtitle>
                        <Tooltip message="Defaulted to PartsSource provided shipping label. 
                        If you use your own shipping label, enter your tracking number here." />
                    </Row>
                    <Row className="col-11">
                        <CustomTextField
                            label={'Tracking Number'}
                            text={orderLine?.vendorToCustomerTrackingNumber}
                            name={'vendorToCustomerTrackingNumber'}
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>
                    <Row className="col-11">
                        <GraySubtitle>Estimated Ship Date</GraySubtitle>
                    </Row>
                    <Row className="col-11">
                        <DatePicker style={{width: '285px', height: '50px'}}
                            onHide={setFocusToReasons}
                            value={orderLine?.estimatedShipDate && orderLine.estimatedShipDate !== '0001-01-01T00:00:00'
                                && new Date(orderLine?.estimatedShipDate)}
                            onChange={(e) => handleShipDateChange(e)} />
                    </Row>
                    <Row className="col-11">
                        <DropdownList style={{marginTop: '10px'}} 
                            label="Reason" 
                            name={'shipDateReasonId'}
                            id="esdReasons"
                            options={type === 'part' ?
                                esdPartReasons :
                                type === 'repair' ?
                                    esdRepairReasons :
                                    esdLoanerReasons} 
                            selectedValue={orderLine?.shipDateReasonId}
                            suppressBlankOption={false} 
                            valueField="id" 
                            textField="description"
                            onChange={(e) => onPropertyChange(e)}
                        />
                    </Row>
                    {showVendorDetails && <>
                        <Row className="col-11">
                            <GraySubtitle>Vendor Details</GraySubtitle>
                            <Tooltip message="If you do not have your own Order Number, please use PartsSource's PO #" />
                        </Row>
                        <Row className="col-11">
                            <CustomTextField
                                label={'Vendor Order #'}
                                text={orderLine?.vendorOrderNumber}
                                name={'vendorOrderNumber'}
                                onChange={(e) => onPropertyChange(e)}
                            />
                            <POLabel onClick={() => assignPO()}>Use PartsSource PO#</POLabel>
                        </Row>
                        {!showAttachmentEditor ? (
                            <React.Fragment>
                                <Row className="col-11" style={{marginBottom: '0'}}>
                                    <GraySubtitle>{attachmentsLabel} ({attachmentsCount})</GraySubtitle>
                                </Row>
                                <AttachmentsViewer lineItem={orderLine?.lineItemId} serverAttachments={orderLine?.attachments} />
                                <Row className="col-11" style={{marginLeft: '-5px'}}>
                                    <Icon aria-hidden="true" icon={faCopy} />
                                    <BlueText onClick={() => setShowAttachmentEditor(!showAttachmentEditor)}>{attachmentAddButton}</BlueText>
                                </Row>
                            </React.Fragment>
                        ) :
                            <Row className="col-11">
                                <Attachments handleOnSave={(e) => onAttachmentUpload(e)}
                                    handleOnCancel={() => onAttachmentCancel()}
                                    memorySave={true}
                                    showCancel={true}
                                    allowMultipleFiles={true}
                                    attachments={orderLine?.attachments}
                                    lineItemId={orderLine?.lineItemId}
                                    customSave={false}
                                    showDocTypes={type === 'repair'}
                                />
                            </Row>
                        }
                    </>}

                    <Row className="col-11">
                        <GraySubtitle>Order Notes</GraySubtitle>
                    </Row>
                    <Row className="col-11">
                        <TextBox
                            name="note"
                            label="Order Notes"
                            placeholder="Order Notes (max 120 characters)"
                            value={orderLine?.note}
                            multiLine={true}
                            onChange={(e) => onPropertyChange(e)}
                            limit={121}
                        />
                        {warningMessage && <WarningMessageHandler>{warningMessage}</WarningMessageHandler>}

                    </Row>
                    <Line />
                    <Row className="col-12">
                        <SaveButton
                            disabled={!(orderLine?.shipDateReasonId) ||
                                (!orderLine?.estimatedShipDate && (orderLine?.shipDateReasonId.toString() !== '2'
                                && orderLine?.shipDateReasonId.toString() !== '15' && orderLine?.shipDateReasonId.toString() !== '10'
                                && orderLine?.shipDateReasonId.toString() !== '14')) || 
                                (!orderLine.vendorRmaNumber && rmaRequired) || 
                                (orderLine.orderTypeId === LOARNER_ORDER_ID && !orderLine.serialNumber) || 
                                Boolean(warningMessage)}
                            primary={true}
                            onClick={onConfirm}>
                            OK
                        </SaveButton>
                        <CancelButton
                            secondary={true}
                            onClick={onClose}>
                            CANCEL
                        </CancelButton>
                    </Row>
                </div>
            </PopupContent>
        </OrderLinePopup>
    );
};

export default OrderLine;

OrderLine.propTypes = {
    order: PropTypes.shape({}),
    attachmentsLabel: PropTypes.string,
    type: PropTypes.string,
    attachmentAddButton: PropTypes.string,
    handleOnSave: PropTypes.func,
    handleOnCancel: PropTypes.func,
    showSerialNumberInput: PropTypes.bool,
    rmaRequired: PropTypes.bool,
    showVendorDetails: PropTypes.bool,
};
