import React, {useContext} from 'react';
import {GlobalStyle} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import {Switch, Route, useLocation} from 'react-router';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {BrPageContext} from '@bloomreach/react-sdk';
import {Redirect} from 'react-router-dom';
import CustomerHeader from 'components/CustomerHeader';
import Footer from 'components/Footer';
import renderComponent from 'utils/renderComponent';
import {theme, Loader} from '@partssourceinc/react-ui-core';
// import Loadable from 'react-loadable';
import Login from 'pages/Login';
import ForgotUsername from 'pages/ForgotUsername';
import ForgotPassword from 'pages/ForgotPassword';
import ChangePassword from 'pages/ChangePassword';
import SupplierRegistration from 'pages/SupplierRegistration';
import Insights from 'pages/Insights';
import Settings from 'pages/Settings';
import Bids from 'pages/Bids';
import BidDetails from 'pages/BidDetails';
import Orders from 'pages/Orders';
import OrderDetails from 'pages/OrderDetails';
import OrdersHistory from 'pages/OrdersHistory';
import Repairs from 'pages/Repairs';
import RepairDetails from 'pages/RepairDetails';
import ExchangesReturns from 'pages/ExchangesReturns';
import ExchangeReturnDetails from 'pages/ExchangeReturnDetails';
import LoanersAndRentals from 'pages/LoanersAndRentals';
import LoanersAndRentalsDetails from 'pages/LoanersAndRentalsDetails';
import PackingPrint from 'pages/PackingPrint';
import SettingsGeneralInformation from 'pages/SettingsGeneralInformation';
import Sso from 'pages/Sso';
import CmsLandingPage from 'pages/CmsLandingPage';
import {SupplierProStyles} from 'utils/styles';
import Certifications from 'pages/Certifications';
import Profile from 'pages/Profile';
import ShippingAndReturns from 'pages/ShippingAndReturns';
import FeesBilling from 'pages/FeesBilling';
import ServicesAndInventory from 'pages/ServicesAndInventory';
import PoliciesAcceptance from 'pages/PoliciesAcceptance';
import CookieBanner from 'components/CookieBanner';
import NonReferenceVdf from 'pages/NonReferenceVdf';
import SingleReportsDashboardPowerBi from 'pages/PowerBi/SingleReportsDashboardPowerBi';
import UnauthorizedWrapper from './UnauthorizedWrapper';
import {ErrorBoundary} from './ErrorBoundary';
import NonAuthVdf from 'pages/NonAuthVdf';
import Spinner from 'components/Spinner';
import OktaLogin from 'pages/OktaLogin';
import {LoginCallback} from '@okta/okta-react';
import * as PropTypes from 'prop-types';

function Loading(props) {
    const {error} = props;
    if (error && location.hostname !== 'localhost') {
        location.reload(true);
    }
    return null;
}

Loading.propTypes = {
    error: PropTypes.any,
};

const Backdrop = styled.div`
    opacity: .5;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    background-color: #000;
`;

const CenteredDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1999;
`;

const Main = styled.main`
    margin: 0 50px 0;
    flex: 1;

    @media (max-width: 819px) {
        margin: 0 15px 0 15px;
    }

    @media (min-width: 820px) and (max-width: 1149px) {
        margin: 0 25px 0;
    }
`;

const Container = styled.div`
    width: calc(100vw - 100px);
    margin-bottom: 50px;

    @media (min-width: ${theme.screenXLMin}) {
        /* max-width: 1630px; */
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 819px) {
        width: 100%;
    }
`;

const publicPages = [
    '/login',
    '/forgot-username',
    '/forgot-password',
    '/change-password',
    '/registration',
    '/policies-acceptance',
    '/non-auth-vdf',
];

const CustomerLayout = ({oktaSignInConfig, updateTriggerLogout}) => {
    const preview = (window.self !== window.top);
    const page = useContext(BrPageContext);
    const menuSettings = useSelector(state => state.settings.menuSettings);
    const facilities = useSelector(state => state.settings.facilities);
    const loading = useSelector(state => state.common.loading);
    const showInsights = (menuSettings?.find(c => c.id === '98' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const showOrders = (menuSettings?.find(c => c.id === '123' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const showRepairs = (menuSettings?.find(c => c.id === '124' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const showBids = (menuSettings?.find(c => c.id === '122' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const showReturnsAndExchanges = (menuSettings?.find(c => c.id === '125' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const showLoanersAndRentals = (menuSettings?.find(c => c.id === '134' && c.name === 'Y') && facilities.length > 0) ? true : false; 
    const showCompanyProfile = useSelector(state => state.settings.companySettings?.vendorAccountInformation === 'Y') && facilities.length > 0;
    const marketingInsightsEnabled = useSelector(state => state.settings.siteSettings?.marketingInsights === 'Y');
    const brandedEcommerceEnabled = useSelector(state => state.settings.siteSettings?.brandedEcommerce === 'Y');       
    const showMarketingInsights = (menuSettings?.find(c => c.id === '157' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const showBrandedEcommerce = (menuSettings?.find(c => c.id === '158' && c.name === 'Y') && facilities.length > 0) ? true : false;
    const isLoggingIn = useSelector(state => state.user.isLoggingIn);
    const isLoggingOut = useSelector(state => state.user.isLoggingOut);
    const oktaAuthentication = useSelector(state => state.settings.siteSettings?.oktaAuthentication === 'Y');

    const path = window.location.pathname.split('/');
    const last = _.last(path);
    const secondToLast = _.nth(path, path.length - 2);

    const tokenInfo = useSelector(state => state.user.tokenInfo);
    const {pathname} = useLocation();

    if (!preview && !tokenInfo && !publicPages.includes(pathname)) {
        if (!pathname.includes('/logout')) {
            localStorage.setItem('prevPath', pathname);
        }
        return <Redirect to="/login" />;
    } else if (!preview && tokenInfo && pathname === '/') {
        return <Redirect to="/overview" />;      
    } else if (isLoggingIn || isLoggingOut) {
        return (
            <CenteredDiv>
                <Spinner />
            </CenteredDiv>
        ); 
    }

    const ProtectedDashboard = ({shouldAccess, children}) => {
        if (!shouldAccess)
            return <Redirect to="/overview" />
        
        return children;
    }
    const sessionStorageAvailable = true;
    
    return (
        <>
            <GlobalStyle />
            <SupplierProStyles preview={page.isPreview()} />
            <CustomerHeader updateTriggerLogout={updateTriggerLogout} />
            <CookieBanner />
            <Main>
                <Container>
                    {loading && <>
                        <Backdrop />
                        <CenteredDiv>
                            <Loader />
                        </CenteredDiv>
                    </>}
                    <ErrorBoundary>
                        <Switch>
                            {!oktaAuthentication &&
                                <Route
                                    path="/(login|signin)"
                                    render={() => renderComponent(<Login />, 'Login')}
                                />
                            }                            
                            <Route
                                path="/non-reference-vdf"
                                exact={true}
                                render={() => renderComponent(<NonReferenceVdf />,'VDF Form')}
                            />
                            <Route
                                path="/non-auth-vdf"
                                render={() => renderComponent(<NonAuthVdf />,'VDF Form')}
                            />
                            <Route
                                path="/forgot-username"
                                render={() => renderComponent(<ForgotUsername />, 'Forgot Username')}
                            />
                            <Route
                                path="/forgot-password"
                                render={() => renderComponent(<ForgotPassword />, 'Forgot Username')}
                            />
                            <Route
                                path="/change-password"
                                render={() => renderComponent(<ChangePassword />, 'Change Password')}
                            />
                            <Route
                                path="/settings/shipping-and-returns"
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showCompanyProfile && secondToLast === 'settings'}
                                        component={<ShippingAndReturns />}
                                    />, 'Shipping and Returns')}
                            />
                            <Route
                                path="/settings/fees-payments"
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showCompanyProfile && secondToLast === 'settings'}
                                        component={<FeesBilling />}
                                    />, 'Fees & Payments')}
                            />
                            <Route
                                path="/settings/services-inventory"
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showCompanyProfile && secondToLast === 'settings'}
                                        component={<ServicesAndInventory />}
                                    />, 'Services And Inventory')}
                            />
                            <Route
                                path="/settings/profile"
                                render={() => renderComponent(<Profile />, 'My Profile')}
                            />
                            {page.isPreview() && <Route
                                exact={true}
                                path="/"
                                render={() => renderComponent(<Login />, 'Login')}
                            />}
                            <Route
                                path="/insights"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showInsights && last === 'insights'}
                                        component={<Insights />}
                                    />, 'Insights')}
                            />
                            <Route
                                path="/portal-insights/:reportId"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showInsights}
                                        component={<SingleReportsDashboardPowerBi />}
                                    />, 'Insights')}
                            />
                            {(!page.isPreview() && showBrandedEcommerce) && <Route
                                path="/branded-ecommerce"
                                exact={true}
                                render={() => renderComponent(
                                    <ProtectedDashboard shouldAccess={brandedEcommerceEnabled}>
                                        <Insights />
                                    </ProtectedDashboard>, 'Branded eCommerce')}
                            />}
                            {(!page.isPreview() && showMarketingInsights) && <Route
                                path="/marketing-insights"
                                exact={true}
                                render={() => renderComponent(
                                    <ProtectedDashboard shouldAccess={marketingInsightsEnabled}>
                                        <Insights />
                                    </ProtectedDashboard>
                                    , 'Marketing Insights')}
                            />}
                            <Route
                                path="/registration"
                                exact={true}
                                render={() => renderComponent(<SupplierRegistration />, 'Registration')}
                            />
                            <Route
                                path="/settings"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showCompanyProfile && last === 'settings'}
                                        component={<Settings />}
                                    />, 'Settings')}
                            />
                            <Route
                                path="/orders/history"
                                exact={true}
                                render={() => renderComponent(<OrdersHistory />, 'All Orders')}
                            />
                            <Route
                                path="/orders"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showOrders && last === 'orders'}
                                        component={<Orders />}
                                    />, 'Orders')}
                            />
                            <Route
                                path="/order-details/:id"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showOrders && secondToLast === 'orders'}
                                        component={<OrderDetails />}
                                    />, 'Order Details')}
                            />
                            <Route
                                path="/order-details/po/:poNum"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showOrders && secondToLast === 'orders'}
                                        component={<OrderDetails />}
                                    />, 'Order Details')}
                            />
                            <Route
                                path="/loaners-rentals"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showLoanersAndRentals && last === 'loaners-rentals'}
                                        component={<LoanersAndRentals />}
                                    />, 'Loaners')}
                            />
                            <Route
                                path="/loaners-rentals-details/:id"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showLoanersAndRentals && secondToLast === 'loaners-rentals'}
                                        component={<LoanersAndRentalsDetails />}
                                    />, 'Order Details')}
                            />
                            <Route
                                path="/loaners-rentals-details/po/:id"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showLoanersAndRentals && secondToLast === 'loaners-rentals'}
                                        component={<LoanersAndRentalsDetails />}
                                    />, 'Order Details')}
                            />
                            <Route
                                path="/packingprint"
                                exact={true}
                                render={() => renderComponent(<PackingPrint />, 'Packing Print')}
                            />

                            <Route
                                path="/repair-details/:id"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showRepairs && secondToLast === 'repair-details'}
                                        component={<RepairDetails />}
                                    />, 'Repair Details')}
                            />
                            <Route
                                path="/repairs"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showRepairs && last === 'repairs'}
                                        component={<Repairs />}
                                    />, 'Repairs')}
                            />
                            <Route
                                path="/repair-details/PO/:poNumber"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showRepairs && secondToLast === 'repair-details'}
                                        component={<RepairDetails />}
                                    />, 'Repair Details')}
                            />
                            <Route
                                path="/exchange-return-details/:id"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showReturnsAndExchanges && secondToLast === 'exchange-return-details'}
                                        component={<ExchangeReturnDetails />}
                                    />, 'Exchange-Return Details')}
                            />
                            <Route
                                path="/exchange-return-details/po/:id"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showReturnsAndExchanges && secondToLast === 'exchange-return-details'}
                                        component={<ExchangeReturnDetails />}
                                    />, 'Exchange-Return Details')}
                            />
                            <Route
                                path="/exchanges-returns"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showReturnsAndExchanges && last === 'exchanges-returns'}
                                        component={<ExchangesReturns />} />, 'Exchanges & Returns')}
                            />
                            <Route
                                path="/settings/general-information"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showCompanyProfile && secondToLast === 'settings'}
                                        component={<SettingsGeneralInformation />} />, 'General Information')}
                            />
                            <Route
                                path="/sso/sisense/:action"
                                exact={true}
                                render={() => renderComponent(<Sso updateTriggerLogout={updateTriggerLogout} />)}
                            />
                            <Route
                                path="/settings/certifications"
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showCompanyProfile && last === 'certifications'}
                                        component={<Certifications />} />, 'Certifications')}
                            />
                            <Route
                                exact={true}
                                path="/policies-acceptance"
                                render={() => renderComponent(<PoliciesAcceptance />, {forceWideScreen: true, isPublic: true})}
                            />
                            <Route
                                exact={true}
                                path="/policy-acceptance"
                                render={() => renderComponent(<PoliciesAcceptance />, {forceWideScreen: true, isPublic: true})}
                            />
                            <Route
                                exact={true}
                                path="/bids"
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showBids && last === 'bids'} component={<Bids />} />, 'Bids')}
                            />
                            <Route
                                path="/bid-details/:id"
                                exact={true}
                                render={() => renderComponent(
                                    <UnauthorizedWrapper isUnauthorized={!showBids && secondToLast === 'bid-details'}
                                        component={<BidDetails />} />, 'Bid Details')}
                            /> 
                            {oktaAuthentication && <Route
                                exact={true}
                                path="/login"
                                render={() => renderComponent(
                                    <OktaLogin config={oktaSignInConfig} sessionStorageAvailable={sessionStorageAvailable} />, 'Okta Login')}
                            />}
                            <Route
                                exact={true}
                                path="/login/callback"
                                render={() => renderComponent(
                                    <LoginCallback />, 'Login Callback')}
                            />
                            <Route
                                exact={true}
                                path="/logout"
                                render={() => renderComponent(
                                    <Spinner />, 'Spinner')}
                            />
                            <Route
                                path="/"
                                render={() => renderComponent(
                                    <CmsLandingPage />,
                                    _.startCase(window.location.pathname.replace('/', ''))
                                )}
                            />

                        </Switch>
                    </ErrorBoundary>
                </Container>
            </Main>
            <Footer />
        </>
    )
};
CustomerLayout.propTypes = {
    oktaSignInConfig: PropTypes.object,
    updateTriggerLogout: PropTypes.func,
};

export default CustomerLayout;
