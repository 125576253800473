import React from 'react';
import styled from 'styled-components'
import {BrComponent} from '@bloomreach/react-sdk';

const Container = styled.div`
    display: flex;
    justify-content: center;
    max-height: 470px;
    margin-top: 30px;
    text-align: center;
    h2 { font-weight: 400;
         font-size: 24px;
    }
`;

const PageNotFound = () => {
    return (
        <Container>
            <BrComponent path={'main/content'} />
        </Container>)
}

export default PageNotFound;
